export class APISettings {
  public static readonly API_BASE_PATH = 'https://clientportal.medcor.com/CPAPI';
  public static readonly API_FILES_PATH = 'https://clientportal.medcor.com/CPAPI/api/PublicFile/DownloadFile';
}

export class AppSettings {
  public static readonly SHOW_CHANGE_LOG = false;
  public static readonly UI_ORIGIN_PATH = 'https://clientportal.medcor.com';
  public static readonly MAS_URL = 'https://mas.medcor.com/auth';
  public static readonly RELEASE_LABEL = "Y18W51";
  public static readonly ERROR_REPORTING_ADMIN_EMAIL = 'support@medcor.com';
  public static readonly MAX_FILE_SIZE = 100 * 1024 * 1024; // 100 MB
  public static readonly AZURE_AD_CLIENT_ID = '21c5ea6a-9214-445b-9468-0e0c9e99c245';
  public static readonly ZENDESK_SCRIPT_SRC_URL = 'https://static.zdassets.com/ekr/snippet.js?key=dc919891-40f3-490e-8cac-475476f7b37e';
}

export class ResourcesConstants {
  public static readonly HOME_IMAGES_PATH = [
    { "path": "assets/images/homeimages/Construction.png" },
    { "path": "assets/images/homeimages/group-people.png" },
    { "path": "assets/images/homeimages/AttestationTesting.png" },
    { "path": "assets/images/homeimages/Medcor-Advocates.png" },
    { "path": "assets/images/homeimages/SingleNurse.png" },
    { "path": "assets/images/homeimages/Telemedicine.png" },
    { "path": "assets/images/homeimages/workplace-safety.png" },
    { "path": "assets/images/homeimages/ConstructionWorker.png" },
    { "path": "assets/images/homeimages/Injury.png" }
  ]
}

export class SessionSettings {
  public static readonly SESSION_DURATION = 30;
  public static readonly SHOW_ALERT_MINS_BEFORE_TIMEOUT = 3;
  public static readonly SESSION_EXPIRATION_TITLE = "Session Expired";
  public static readonly SESSION_EXPIRATION_MESSAGE = "Your session has expired. Please login";
}

export class ValidationMessages {
  public static readonly INVALID_FORM = "Please check all validation messages";
  public static readonly FILE_SIZE_LIMIT_EXCEEDED = `File size cannot be larger than ${AppSettings.MAX_FILE_SIZE / (1024 * 1024)} MB`;
  public static readonly NOT_IMAGE_FILETYPE = "Selected file(s) is not an image, only image file types allowed";
  public static readonly INVALID_FILETYPE = "This file type is not allowed";
  public static readonly INVALID_LINK = "Link is invalid!";
  public static readonly INVALID_EXPIRED_LINK = "Link is invalid or expired!";
  public static readonly NO_RECIPIENT = "You haven't selected a recipient!";
  public static readonly DATA_ACCESS_CHANGES = "Data access changes will take effect on dashboard at #hour#";
  public static readonly WAIT_DASHBOARD_FULL_LOAD = "Please wait till dashboard and parameters are loaded.";
  public static readonly NO_HIERARCHY_SELECTED = "Please select at least one Hierarchy Client!";
}

export class ReportingSettings {
  // General Parameters
  public static readonly START_DATE_PARAM_NAME = 'StartDate';
  public static readonly END_DATE_PARAM_NAME = 'EndDate';
  public static readonly CLIENT_REPORT_TITLE_PARAM_NAME = 'ClientReportTitle';

  // Triage Parameters
  public static readonly BASE_PARENTS_PARAM_NAME = 'BaseParents';
  public static readonly INCLUDED_PARENTS_PARAM_NAME = 'IncludedParents';
  public static readonly EXCLUDED_PARENTS_PARAM_NAME = 'ExcludedParents';
  public static readonly TRIAGE_REPORT_GENERATION_ID = 'TriageReportGenerationId';

  // Occupational Health Parameters
  public static readonly INST_ID_PARAM_NAME = 'InstId';
  public static readonly COMPANY_ID_PARAM_NAME = 'CompanyId';
  public static readonly SITE_ID_PARAM_NAME = 'SiteId';

  // Advanced Practice Parameters
  public static readonly PRACTICE_ID_PARAM_NAME = 'practice_id';
  public static readonly PRACTICE_LOCATION_ID_PARAM_NAME = 'PracticeLocationId';

  // Screening Parameters
  public static readonly SCREENING_CLIENT_ID_PARAM_NAME = 'ClientId';
  public static readonly SCREENING_LOCATION_ID_PARAM_NAME = 'LocationId';
  public static readonly SCREENING_LOCATION_SUB_LEVEL_ID_PARAM_NAME = 'LocationSubLevelId';

  // OSHA Reportable Parameters
  public static readonly OSHA_COMPANY_ID_PARAM_NAME = 'OshaCompanyId';
  public static readonly OSHA_LOCATION_ID_PARAM_NAME = 'OshaLocationId';

  // Reporting Validation Messages
  public static readonly INVALID_REPORT_FORMAT = 'Invalid Report Format';
  public static readonly MISSING_REPORT_PARAMETERS = 'Report Has No Parameters';
  public static readonly MISSING_START_DATE_PARAMETER = 'Missing StartDate Parameter';
  public static readonly MISSING_END_DATE_PARAMETER = 'Missing EndDate Parameter';
  public static readonly MISSING_COMPANY_ID_PARAMETER = 'Missing CompanyId Parameter';
  public static readonly MISSING_SITE_ID_PARAMETER = 'Missing SiteId Parameter';
  public static readonly MISSING_CLIENT_ID_PARAMETER = 'Missing ClientId Parameter';
  public static readonly MISSING_LOCATION_ID_PARAMETER = 'Missing LocationId Parameter';
  public static readonly MISSING_PRACTICE_ID_PARAMETER = 'Missing practice_id Parameter';
  public static readonly MISSING_OSHA_COMPANY_ID_PARAMETER = 'Missing OshaCompanyId Parameter';
  public static readonly MISSING_OSHA_LOCATION_ID_PARAMETER = 'Missing OshaLocationId Parameter';
}

export class DashboardSettings {
  public static readonly POWER_BI_EMBED_PATH = 'https://app.powerbi.com/reportEmbed?groupId=';
  public static readonly MEDCOR_KPI_THEME = {
    "name": "Medcor's Theme",
    "dataColors": ["#6a5a7f", "#4ca7ac", "#94bd6a", "#e5e55b", "#93cacd", "#aca0bb", "#c3ce90", "#f0f099"],
    "visualStyles": { "*": { "*": { "*": [{ "color": { "solid": {} } }] } } }
  };
  public static readonly DASHBOARD_EXPORT_FILTER_SPACE_ESCAPE = '_x0020_';
}

export class SignalRSettings {
  public static readonly SIGNALR_BASE_PATH = 'https://clientportal.medcor.com/CPAPI';
  public static readonly ACTIVE_USERS_PATH = '/SignalR/ActiveUsers'

  public static readonly EMAIL_RECEIVED_EVENT = 'EmailReceived';
  public static readonly MESSAGE_RECEIVED_EVENT = 'MessageReceived';
  public static readonly ACTIVE_USERS_CHANGED_EVENT = 'ActiveUsersChanged';
  public static readonly CHAT_MESSAGE_RECEIVED_EVENT = "ChatMessageReceived";
  public static readonly CHAT_MESSAGE_NOT_DELIVERED = "ChatMessageNotDelivered";
  public static readonly USER_ID_RECEIVED_EVENT = "UserIdReceived";
  public static readonly NOTIFICATION_ADDED_EVENT = "NotificationAdded";

  public static readonly GET_ACTIVE_USERS_METHOD = 'GetActiveUsers';
  public static readonly SEND_MESSAGE_METHOD = 'SendMessage';
  public static readonly SEND_CHAT_MESSAGE_METHOD = 'SendChatMessage';
  public static readonly GO_ONLINE = "GoOnline";
  public static readonly GO_OFFLINE = "GoOffline";

  public static readonly UPDATE_SYSTEM_COUNTERS = "UpdateSystemCounters";
  public static readonly NEW_RELEASE_NOTES_ADDED_EVENT = "NewReleaseNotesAdded";
}

export class UserSettingsConstants {
  public static readonly ReportView = {
    Key: 'ReportView', Values: { Tiles: 'Tiles', List: 'List' }
  };

  public static readonly KpiTheme = {
    Key: 'KpiTheme'
  };
}

export class LineOfBusiness {
  public static readonly TRIAGE = "Triage";
  public static readonly OCCUPATIONAL_HEALTH = "Occupational Health";
  public static readonly ADVANCED_PRACTICE = "Advanced Practice";
  public static readonly SCREENING = "Screening";
  public static readonly OSHA_REPORTABLE = "OSHA Reportable";
}

export class FileTypesEnum {
  public static readonly PHI = "PHI";
  public static readonly NON_PHI = "NON-PHI";
  public static readonly CALL_RECORDING = "CALL_RECORDING";
}

export class MfaConstants {
  public static readonly MFA_EMAIL_ROLE = "Client User - Triage Portal";
}
