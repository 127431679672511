import { Component, ViewChild, Input, OnInit, Output, EventEmitter, ElementRef, AfterViewInit } from '@angular/core';
import { NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlBase } from '../control-base';


@Component({
  selector: 'ichs-text-box',
  templateUrl: './ichs-text-box.component.html',
  styleUrls: ['./ichs-text-box.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: IchsTextBoxComponent,
    multi: true,
  }],
})
// Custom Textbx control.
export class IchsTextBoxComponent extends ControlBase<string> implements OnInit, AfterViewInit {
  @Input('inputMask') inputMask: string;
  @Input('label') label: string;
  @Input("disabled") isDisabled: boolean;
  @Input("placeholder") textboxPlaceHolder: string = "";
  @Input("buttonIcon") icon: string;
  @Input("iconTitle") iconTitle: string;
  @Input("hasButton") showButton: boolean;
  @Input("initialFocus") hasInitialFocus: boolean = false;
  @Output() textBoxChanged: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(NgModel, { static: true }) model: NgModel;
  @ViewChild('inputController') inputEl: ElementRef;

  // this attribute to give a unique identifier for the textboxs.
  public identifier = `ichs-text-boxt-${identifier++}`;
  public textboxMask: Array<string | RegExp> | boolean = [];

  // raise an event to the outer component.
  textBoxChangedEvent(event: Event) {
    this.textBoxChanged.emit(event);
  }

  ngOnInit() {
    super.ngOnInit();

    //set the input mask expression.
    if (this.inputMask) {
      switch (this.inputMask.toLowerCase()) {
        case 'phone':
          this.textboxMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]; break;
        case 'phone-with-ext':
          this.textboxMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, ' ', 'e', 'x', 't', '.', ' ', /\d/, /\d/, /\d/, /\d/]; break;
        case 'ssn':
          this.textboxMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]; break;
        case 'zipcode5':
          this.textboxMask = [/\d/, /\d/, /\d/, /\d/, /\d/]; break;
        case 'zipcode4':
          this.textboxMask = [/\d/, /\d/, /\d/, /\d/]; break;
        case 'id15':
          this.textboxMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]; break;
        default:
          this.textboxMask = false;

      }
    }
  }

  ngAfterViewInit() {
    if (this.hasInitialFocus) {
      this.focus();
    }
  }

  //fire event when clicking on the button
  buttonClickedAction() {
    this.buttonClicked.emit(this.model.value);
  }

  focus() {
    this.inputEl.nativeElement.focus();
  }
}

let identifier = 0;
