<h2 mat-dialog-title>{{title}}</h2>
<mat-dialog-content>
  <p *ngFor="let item of files"><button class="cp-btn cp-mail-btn" (click)="downloadFile(item.id)">{{item.fileName}}</button></p> 
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-basis-0 flex-grow-1 text-right">
    <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">Close</button>
  </div>

</mat-dialog-actions>
