<form #form="ngForm" novalidate>
    <div class="login-container">
        <div>
            <div class="label-cont">
                <label>For your security, Please type in your e-mail to verify your identity.</label>
            </div>
            <div class="input-cont flex-basis-0 flex-grow-1">
                <ichs-text-box required email name="resetEmail" [(ngModel)]="resetEmail"
                    [initialFocus]="true"></ichs-text-box>
            </div>
        </div>
        <div class="display-flex margin-bottom-def" style="justify-content:flex-end;">
            <div class="flex-child submit-btn">
                <button class="cp-btn cp-main-btn" (click)="submitForgetPasswordRequest()">Submit</button>
            </div>

            <div class="flex-child">
                <button class="cp-btn cp-mail-btn" mat-dialog-close>Cancel</button>
            </div>
        </div>
    </div>
</form>
