import { Component, OnInit, Inject, Optional, ViewChild, Input, EventEmitter, Output, ElementRef, Renderer2, Injector, AfterViewInit } from '@angular/core';
import { ControlBase } from '../control-base';
import { NG_VALIDATORS, NgModel, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { MatDatepickerInputEvent, MatDatepicker } from '@angular/material/datepicker';

@Component({
    selector: 'ichs-date-picker',
    templateUrl: './ichs-date-picker.component.html',
    styleUrls: ['./ichs-date-picker.component.css'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: IchsDatePickerComponent,
        multi: true,
    }],
})
//a DatePicker contrl.
export class IchsDatePickerComponent extends ControlBase<Date> implements OnInit {
    @Input('label') label: string;
    @Input("disabled") isDisabled: boolean;
    @Input("placeholder") datePlaceHolder: string = "mm/dd/yyyy";

    @ViewChild(NgModel, { static: true }) model: NgModel;
    @ViewChild(MatDatepicker) matPicker: MatDatepicker<Date>;

    @Output() dateChanged: EventEmitter<MatDatepickerInputEvent<Date>> = new EventEmitter<MatDatepickerInputEvent<Date>>();

    public identifier = `ichs-date-picker-${identifier++}`;    // this attribute to give a unique identifier for the textboxs.
    private matPickerPopupUnListenFn: Function;

    constructor(
        @Optional() @Inject(NG_VALIDATORS) protected _validators: Array<Validator>,
        @Inject(Injector) private _injector: Injector,
        private renderer: Renderer2,
    ) {
        super(_validators, _injector);
    }

    ngOnInit() {
        super.ngOnInit();
    }

    // raise an event to the outer component.
    dateChangedEvent(event: MatDatepickerInputEvent<Date>) {
        if (this.model.control.status == "VALID") {
            this.dateChanged.emit(event);
        }
        else if (this.model.control.errors.matDatepickerParse) {
            this.model.control.setValue(null); // if the value is not valid date, clear it.
        }
    }

    keydownEventHandler(event: any) {
        //prevent of typing a text.
        var key = event.keyCode;

        if (key >= 65 && key <= 90) {
            event.preventDefault();
        }
    }

    onMatDatePickerOpened() {
        // this.matPickerPopupUnListenFn = this.renderer.listen(this.matPicker._popupRef.hostElement, 'click', (event: Event) => {
        //     event.stopPropagation();
        // });
    }

    onMatDatePickerClosed() {
        if (this.matPickerPopupUnListenFn) {
            this.matPickerPopupUnListenFn();
        }
    }
}

let identifier = 0;
