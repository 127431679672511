import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { ReportItemService, ReportingService, SelectionItemViewModel, SelectionTreeViewModel, AfkamCubeService, ReportSchedulerService, ReportSchedulerViewModel, ReportItemViewModel, MedcorResponse, ReportParameterValueViewModel, HierarchyTreeValues, AppUserService, OshaReportableService, CubeHierarchyParameterViewModel } from '../../rest/index';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AlertMessageType, AlertDismissType, MedcorAlertService } from '../../services/medcor-alert.service';
import { first } from 'rxjs/operators';
import { LineOfBusiness, ValidationMessages, ReportingSettings } from 'src/app/app.general.constants';
import { ReportParametersDialogComponent, ReportParametersDialogResult, ReportParametersDialogConfig } from '../report-parameters-dialog/report-parameters-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { AppGenericLists } from '../../app.general.lists';
import { Observable } from 'rxjs';
import { LoggedUserViewModel } from '../../rest/model/loggedUserViewModel';
import { MedcorUserInfoService } from '../../services/medcor-user-info.service';

export interface ReportScheduleReceiver {
  receiverEmail: string;
  recieverHasAccount: boolean;
}

@Component({
  selector: 'reporting-scheduler',
  templateUrl: './reporting-scheduler.component.html',
  styleUrls: ['./reporting-scheduler.component.css']
})
export class ReportingSchedulerComponent implements OnInit {

  config: ReportSchedulerConfig;
  @ViewChild("form") ngForm: NgForm;
  lineOfBusiness: string;

  reportTitle: string = "";
  enteredEmail: string = "";

  isLoadingParameters: boolean = false;

  model: ReportSchedulerViewModel = <ReportSchedulerViewModel>{
    receivers: [],
  };

  ScheduleModeItems: SelectionItemViewModel[] = AppGenericLists.ReportScheduleMode;
  ReportFormatItems: SelectionItemViewModel[] = AppGenericLists.ReportFormat;

  userHasScheduleReportFullAccess: boolean;
  isNew: boolean;
  reportParamsSet: boolean = false;

  showSaveAsScreen: boolean = false;

  get loggedInUserObs(): Observable<LoggedUserViewModel> { return this.medcorUserInfoService.loggedInUserObs }

  scheduleReceivers: ReportScheduleReceiver[] = [];

  constructor(private reportItemService: ReportItemService,
    private reportingService: ReportingService,
    private reportSchedulerService: ReportSchedulerService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ReportSchedulerConfig,
    public dialogRef: MatDialogRef<ReportingSchedulerComponent>,
    private alertService: MedcorAlertService,
    private afkamCubeService: AfkamCubeService,
    public generalVariables: GeneralVariablesService,
    private medcorUserInfoService: MedcorUserInfoService,
    private appuserService: AppUserService,
    private oshaReportableService: OshaReportableService,
  ) {
    this.config = data;
  }

  ngOnInit() {
    this.loggedInUserObs.pipe(first()).subscribe(loggedInUser => {
      this.userHasScheduleReportFullAccess = loggedInUser.allowScheduleReports;

      // if user is trying to create a new schedule, but he doesn't have userHasScheduleReportFullAccess, then add his email only to receivers list
      if ((this.config.report || this.config.copySchedule) && !this.userHasScheduleReportFullAccess) {
        this.scheduleReceivers.push({ receiverEmail: loggedInUser.userEmail, recieverHasAccount: true });
      }
    });

    this.isLoadingParameters = true;

    // if create new
    if (this.config.report) {
      this.isNew = true;
      this.loadReport(this.config.report.id)
      this.model.reportId = this.config.report.id;
      this.model.reportParam = { reportItemId: this.config.report.id };
      this.reportTitle = this.config.report.name;
    } else {
      this.isNew = false;

      if (this.config.copySchedule) {
        this.showSaveAsScreen = true;
      }
      else {
        this.reportParamsSet = true;
      }

      this.reportSchedulerService.getById(this.config.scheduleId).pipe(first()).subscribe(resp => {
        this.model = resp.result;

        if (this.config.copySchedule) {
          this.model.clientReportTitle = null;
        }
        else {
          this.scheduleReceivers = this.model.receivers.map(email =>
            <ReportScheduleReceiver>{
              receiverEmail: email,
              recieverHasAccount: true
            });
        }

        this.reportTitle = this.model.reportName;
        this.loadReport(this.model.reportId, false);
      });
    }
  }

  loadReport(reportId: number, populateStoredReportParameters: boolean = true) {
    this.reportItemService.getReportItemDetails(reportId)
      .pipe(first())
      .subscribe(
        resp => {
          if (!resp || !resp.result) {
            return;
          }

          this.lineOfBusiness = resp.result.lineOfBusiness;
          this.model.reportParam.path = resp.result.reportPath;
          this.loadReportParameters(populateStoredReportParameters);
        },
        () => {
          this.isLoadingParameters = true;
        }
      );
  }

  nextOrBack() {
    this.showSaveAsScreen = !this.showSaveAsScreen;
  }

  submit() {
    this.validateAllFormFields(this.ngForm.form); // validate the form and show errors
    if (!this.ngForm.valid) {// check if form is valid 
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    if (!this.reportParamsSet) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please set report parameters."]
      });
      return;
    }

    if (!this.model.reportParam || this.model.reportParam.parametersRequired) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Invalid report parameters!"]
      });
      return;
    }

    if (this.scheduleReceivers.length == 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["No recipients!"]
      });
      return;
    }

    let receiversWithoutAccount = this.scheduleReceivers
      .filter(contact => !contact.recieverHasAccount)
      .map(contact => contact.receiverEmail);

    if (receiversWithoutAccount.length > 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [`No account was found for '${receiversWithoutAccount.join(", ")}' in Client Portal`]
      });
      return;
    }

    if (this.model.startPeriod) {
      this.model.startPeriod = moment(this.model.startPeriod).hour(12).toDate();
    }

    if (this.model.endPeriod) {
      this.model.endPeriod = moment(this.model.endPeriod).hour(12).toDate();
    }

    if (this.model.runMode == 'Once') {
      this.model.endPeriod = null;
    }

    this.model.receivers = this.scheduleReceivers.map(obj => obj.receiverEmail);

    if (this.config.copySchedule) {
      this.model.id = 0;
      this.model.lastRunTime = null;
      this.model.isScheduleCopy = true;
    }

    this.reportSchedulerService.post(this.model).pipe(first()).subscribe(result => {
      if (result && result.result) {
        this.dialogRef.close(true);
      }
    });
  }

  private loadReportParameters(populateStoredReportParameters: boolean) {
    if (populateStoredReportParameters) {
      this.populateStoredReportParameters();
    }

    this.model.reportParam.fromReportScheduler = true;
    this.reportingService.loadReportParameters(this.model.reportParam).pipe(first()).subscribe(
      returnValue => {
        if (returnValue.result) {
          this.model.reportParam = returnValue.result;
          this.isLoadingParameters = false;
        }
      },
      (err: HttpErrorResponse) => {
        let error = err.error as MedcorResponse<null>;
        if (error.statusCode == 400 && !error.notify) {
          this.alertService.addAlert({
            type: AlertMessageType.warning,
            title: 'Warning!',
            dismiss: AlertDismissType.controlled,
            messages: error.messages,
          });
        }
      });
  }

  private populateStoredReportParameters() {

    // local storage is disabled in case of impersonation
    if (this.generalVariables.userImpersonationInfo.isImpersonatedInd) {
      return;
    }

    var storedReportParams = localStorage.getItem('reportParams');
    if (storedReportParams) {
      this.model.reportParam.reportParametersValues = JSON.parse(storedReportParams) as ReportParameterValueViewModel[];
    }

    let storedDateRange = localStorage.getItem("reportDateRangeParam");
    if (storedDateRange) {
      this.model.dateRange = storedDateRange;
    }

    let storedTriageReportHierarchyDisplayParam = localStorage.getItem("triageReportHierarchyDisplayParam");
    if (storedTriageReportHierarchyDisplayParam) {
      this.model.reportParam.triageReportHierarchyDisplayTreeValues = JSON.parse(storedTriageReportHierarchyDisplayParam) as HierarchyTreeValues;
    }

    let oshaHierarchyTreeValues = localStorage.getItem("oshaHierarchyTreeValues");
    if (oshaHierarchyTreeValues) {
      this.model.reportParam.oshaHierarchyTreeValues = JSON.parse(oshaHierarchyTreeValues) as HierarchyTreeValues;
    }
  }

  addToContact(_receiverEmail: string, _recieverHasAccount: boolean) {
    if (this.scheduleReceivers.map(obj => obj.receiverEmail).indexOf(_receiverEmail) == -1) {
      this.scheduleReceivers.push({ receiverEmail: _receiverEmail, recieverHasAccount: _recieverHasAccount });
    }
  }

  removeContact(contact: ReportScheduleReceiver) {
    let index = this.scheduleReceivers.indexOf(contact);
    if (index > -1) {
      this.scheduleReceivers.splice(index, 1);
    }
  }

  validateNewEmail() {
    if (this.enteredEmail.trim().length == 0 || this.ngForm.form.controls["contact"].invalid) {
      this.enteredEmail = ""
    } else {
      this.appuserService.isValidUser(this.enteredEmail).pipe(first()).subscribe(resp => {
        this.addToContact(this.enteredEmail, resp.result);
        this.enteredEmail = "";
      });
    }
  }

  fillParameters() {
    if (this.lineOfBusiness == LineOfBusiness.TRIAGE) {
      this.afkamCubeService.getHierarchyLocations([]).pipe(first()).subscribe(
        returnValue => {
          if (returnValue.result) {
            this.showReportParametersDialog(returnValue.result.selectionTrees)
          }
        }
      );
    }
    else if (this.lineOfBusiness == LineOfBusiness.OSHA_REPORTABLE) {
      this.oshaReportableService.getCompanyLocationTree().pipe(first()).subscribe(resp => {
        if (resp.result) {
          this.showReportParametersDialog(null, resp.result)
        }
      });
    }
    else {
      this.showReportParametersDialog();
    }
  }

  private showReportParametersDialog(triageHierarchies: SelectionTreeViewModel[] = null, oshaHierarchies: SelectionTreeViewModel[] = null) {
    if (this.config.copySchedule) {
      this.syncReportTitleParam(true);
    }

    let dialogConfig: MatDialogConfig<ReportParametersDialogConfig> = {
      data: {
        title: "Report Parameters",
        message: "Please fill the following Parameters:",
        dateRange: this.model.dateRange,
        lineOfBusiness: this.lineOfBusiness,
        reportingParametersViewModel: this.model.reportParam,
        hierarchies: triageHierarchies,
        oshaHierarchies: oshaHierarchies,
        fromReportScheduler: true,
      },
      width: '800px',
      disableClose: true
    };

    let dialogRef: MatDialogRef<ReportParametersDialogComponent, ReportParametersDialogResult> = this.dialog.open(ReportParametersDialogComponent, dialogConfig);
    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.model.dateRange = result.dateRange;
        this.model.reportParam = result.reportingParametersViewModel;
        this.model.usedReportParam = result.usedParams;
        this.reportParamsSet = true;

        if (this.config.copySchedule) {
          this.syncReportTitleParam(false);
        }
      }
    });
  }

  getReportRunModePeriod(runMode: string) {
    if (runMode == 'Daily') {
      return 'day';
    }
    else if (runMode == 'Weekly') {
      return 'week';
    }
    else if (runMode == 'Monthly') {
      return 'month';
    }
    else if (runMode == 'Quarterly') {
      return '3 months';
    }
    else {
      return '';
    }
  }

  private syncReportTitleParam(beforeOpenningParamsDialog: boolean) {
    if (beforeOpenningParamsDialog) {
      let clientReportTitleParamVal = this.model.reportParam.reportParametersValues.find(param => param.name == ReportingSettings.CLIENT_REPORT_TITLE_PARAM_NAME);
      if (clientReportTitleParamVal) {
        clientReportTitleParamVal.value = this.model.clientReportTitle;
      }

      let clientReportTitleParameter = this.model.reportParam.reportParameters.find(param => param.name == ReportingSettings.CLIENT_REPORT_TITLE_PARAM_NAME);
      if (clientReportTitleParameter) {
        clientReportTitleParameter.defaultValues = [this.model.clientReportTitle];
      }

      let clientReportTitleUsedParam = this.model.usedReportParam.find(param => param.name == "Client Report Title");
      if (clientReportTitleUsedParam) {
        clientReportTitleUsedParam.value = this.model.clientReportTitle;
      }
    }
    else {
      let clientReportTitleParamVal = this.model.reportParam.reportParametersValues.find(param => param.name == ReportingSettings.CLIENT_REPORT_TITLE_PARAM_NAME);
      if (clientReportTitleParamVal) {
        this.model.clientReportTitle = clientReportTitleParamVal.value;
      }
    }
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}


export class ReportSchedulerConfig {
  report?: ReportItemViewModel;
  scheduleId?: number;
  copySchedule?: boolean = false;
}
