
<div class="cp-dialog">
  <h2 *ngIf="data.title" mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content style="height:60vh">
    <div class="display-flex flex-basis-0 flex-grow-1 border-bottom" style="height:100%">
      <ichs-grid [grid-config]="gridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex-basis-0 flex-grow-1 text-right">
      <button *ngIf="!data.singleSelect" mat-button (click)="returnData()" class="cp-btn cp-main-btn">{{data.okText}}</button>
      <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">{{data.cancelText}}</button>
    </div>
  </mat-dialog-actions>
</div>
