<h2 mat-dialog-title>Please double click on the folder that you want to place the file in</h2>
<mat-dialog-content>

  <div class="secured-files-tools display-flex flex-items-centered">
    <div><a style="cursor: pointer" (click)="loadRoot()">My Files</a>&nbsp;</div>
    <div class="flex-grow-1 flex-basis-0">
      <ng-container *ngIf="inReceivedFilesFolder">/ <a style="cursor: pointer;">Received Files</a></ng-container>
      <ng-container *ngIf="inRecordedCallsFolder">/ <a style="cursor: pointer;">Recorded Calls</a></ng-container>
      <ng-container *ngIf="inSecureReportsFolder">/ <a style="cursor: pointer;">Reports Folder</a></ng-container>
      <ng-container *ngFor="let item of path; index as i">
        / <a style="cursor: pointer" (click)="navigateTofolder(item,i)">{{item.name}}</a>
      </ng-container>
    </div>
  </div>

  <div class="flex-grow-1  secured-files" style="min-height:400px;">
    <div class="main-folder" id="mainFolder">

      <div class="user-folder" (dblclick)="loadReceivedFilesFolder()" *ngIf="inRootFolder">
        <div class="folder-visuals">
          <img src="assets/images/newIcons/receivedFiles.svg" title="Received Files" class="folder-icon" />
          <span style="display:block">Received Files</span>
        </div>
      </div>

      <div class="user-folder" (dblclick)="loadRecordedCallsFolder()" *ngIf="inRootFolder">
        <div class="folder-visuals">
          <img src="assets/images/newIcons/recordedCalls.svg" title="Recorded Calls" class="folder-icon" />
          <span style="display:block">Recorded Calls</span>
        </div>
      </div>

      <div class="user-folder" (dblclick)="loadSecureReportsFolder()" *ngIf="inRootFolder">
        <div class="folder-visuals">
          <img src="assets/images/newIcons/receivedFiles.svg" title="Reports Folder" class="folder-icon" />
          <span style="display:block">Reports Folder</span>
        </div>
      </div>

      <ng-container *ngIf="!inReceivedFilesFolder && !inRecordedCallsFolder && !inSecureReportsFolder">
        <div class="user-folder" (dblclick)="loadFolders(item)" *ngFor="let item of folders">
          <div class="folder-visuals">
            <img src="assets/images/newIcons/folder.svg" [title]="item.name" class="folder-icon" />
            <span style="display:block">{{item.name}}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
    <div class="flex-basis-0 flex-grow-1 text-right">
        <button mat-button (click)="moveFileTo()" class="cp-btn cp-main-btn">Move Here</button>
        <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">Cancel</button>
    </div>
</mat-dialog-actions>
