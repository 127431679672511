export * from './accounts.service';
import { AccountsService } from './accounts.service';
export * from './advancedPractice.service';
import { AdvancedPracticeService } from './advancedPractice.service';
export * from './afkam.service';
import { AfkamService } from './afkam.service';
export * from './afkamCube.service';
import { AfkamCubeService } from './afkamCube.service';
export * from './appUser.service';
import { AppUserService } from './appUser.service';
export * from './changeLog.service';
import { ChangeLogService } from './changeLog.service';
export * from './commonQuestion.service';
import { CommonQuestionService } from './commonQuestion.service';
export * from './dashboard.service';
import { DashboardService } from './dashboard.service';
export * from './dashboardItem.service';
import { DashboardItemService } from './dashboardItem.service';
export * from './dashboardScheduler.service';
import { DashboardSchedulerService } from './dashboardScheduler.service';
export * from './executiveCube.service';
import { ExecutiveCubeService } from './executiveCube.service';
export * from './fileRequest.service';
import { FileRequestService } from './fileRequest.service';
export * from './group.service';
import { GroupService } from './group.service';
export * from './groupAppUserAssociation.service';
import { GroupAppUserAssociationService } from './groupAppUserAssociation.service';
export * from './groupDashboardItemAssociation.service';
import { GroupDashboardItemAssociationService } from './groupDashboardItemAssociation.service';
export * from './groupReportItemAssociation.service';
import { GroupReportItemAssociationService } from './groupReportItemAssociation.service';
export * from './helpCategory.service';
import { HelpCategoryService } from './helpCategory.service';
export * from './lineOfBusiness.service';
import { LineOfBusinessService } from './lineOfBusiness.service';
export * from './lineOfBusinessProperty.service';
import { LineOfBusinessPropertyService } from './lineOfBusinessProperty.service';
export * from './medFiles.service';
import { MedFilesService } from './medFiles.service';
export * from './medfilesAfterCareInstructions.service';
import { MedfilesAfterCareInstructionsService } from './medfilesAfterCareInstructions.service';
export * from './medfilesVirtualCompany.service';
import { MedfilesVirtualCompanyService } from './medfilesVirtualCompany.service';
export * from './nextgen.service';
import { NextgenService } from './nextgen.service';
export * from './notification.service';
import { NotificationService } from './notification.service';
export * from './oshaReportable.service';
import { OshaReportableService } from './oshaReportable.service';
export * from './pageDesign.service';
import { PageDesignService } from './pageDesign.service';
export * from './publicFile.service';
import { PublicFileService } from './publicFile.service';
export * from './reportItem.service';
import { ReportItemService } from './reportItem.service';
export * from './reportScheduler.service';
import { ReportSchedulerService } from './reportScheduler.service';
export * from './reporting.service';
import { ReportingService } from './reporting.service';
export * from './screening.service';
import { ScreeningService } from './screening.service';
export * from './secureFile.service';
import { SecureFileService } from './secureFile.service';
export * from './securedEmails.service';
import { SecuredEmailsService } from './securedEmails.service';
export * from './selectionList.service';
import { SelectionListService } from './selectionList.service';
export * from './serviceRequest.service';
import { ServiceRequestService } from './serviceRequest.service';
export * from './sharedFile.service';
import { SharedFileService } from './sharedFile.service';
export * from './sharedFolder.service';
import { SharedFolderService } from './sharedFolder.service';
export * from './sharedFolderUserPermission.service';
import { SharedFolderUserPermissionService } from './sharedFolderUserPermission.service';
export * from './teleHealth.service';
import { TeleHealthService } from './teleHealth.service';
export * from './triagePortalUser.service';
import { TriagePortalUserService } from './triagePortalUser.service';
export * from './triageReportCustomHierarchySelection.service';
import { TriageReportCustomHierarchySelectionService } from './triageReportCustomHierarchySelection.service';
export * from './triageSecuredFile.service';
import { TriageSecuredFileService } from './triageSecuredFile.service';
export * from './userApplicationAccess.service';
import { UserApplicationAccessService } from './userApplicationAccess.service';
export * from './userContact.service';
import { UserContactService } from './userContact.service';
export * from './userSecuredFile.service';
import { UserSecuredFileService } from './userSecuredFile.service';
export const APIS = [AccountsService, AdvancedPracticeService, AfkamService, AfkamCubeService, AppUserService, ChangeLogService, CommonQuestionService, DashboardService, DashboardItemService, DashboardSchedulerService, ExecutiveCubeService, FileRequestService, GroupService, GroupAppUserAssociationService, GroupDashboardItemAssociationService, GroupReportItemAssociationService, HelpCategoryService, LineOfBusinessService, LineOfBusinessPropertyService, MedFilesService, MedfilesAfterCareInstructionsService, MedfilesVirtualCompanyService, NextgenService, NotificationService, OshaReportableService, PageDesignService, PublicFileService, ReportItemService, ReportSchedulerService, ReportingService, ScreeningService, SecureFileService, SecuredEmailsService, SelectionListService, ServiceRequestService, SharedFileService, SharedFolderService, SharedFolderUserPermissionService, TeleHealthService, TriagePortalUserService, TriageReportCustomHierarchySelectionService, TriageSecuredFileService, UserApplicationAccessService, UserContactService, UserSecuredFileService];
