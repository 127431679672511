<p>
    This browser is not supported, Please try one of the following supported browsers:
</p>

<br/>

<ul>
    <li>
        <b>Google Chrome</b>
    </li>

    <li>
        <b>Mozilla Firefox</b>
    </li>

    <li>
        <b>Apple Safari</b>
    </li>

    <li>
        <b>Microsoft Edge (chromium)</b>
    </li>
</ul>