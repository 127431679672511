import { Component, OnInit, Inject } from '@angular/core';
import { VoidInfoViewModel } from '../../../rest/model/voidInfoViewModel';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.css']
})
//Void component to be used with Mat-Dialog service to get void info (reason and comment)
export class IframeDialogComponent implements OnInit {
  isYouTube: boolean = false;

  constructor(public dialogRef: MatDialogRef<IframeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.isYouTube = data.link.indexOf("www.youtube.com") >= 0
  }

  ngOnInit() {
  }

}
