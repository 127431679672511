<div [ngSwitch]="config.controlType">
  <div *ngSwitchCase="1">
    <ichs-text-box [(ngModel)]="config.filterVal" (keyup.enter)="enterClicked()" (ngModelChange)='filterValueChanged.next($event)'
      [attr.placeholder]="config.searchLabel">
    </ichs-text-box>
  </div>

  <div *ngSwitchCase="2">
    <ichs-select [(ngModel)]="config.filterVal" [items]="yesNoList" (ngModelChange)='filterValueChanged.next($event)'
      [attr.placeholder]="config.searchLabel">
    </ichs-select>
  </div>

  <div *ngSwitchCase="3">
    <ichs-date-picker [(ngModel)]="config.filterVal" type="date" (ngModelChange)='filterValueChanged.next($event)'
      [attr.placeholder]="config.searchLabel">
    </ichs-date-picker>
  </div>

  <div *ngSwitchCase="4">
    <ichs-select [(ngModel)]="config.filterVal" [listId]="config.listId" (ngModelChange)='filterValueChanged.next($event)'
      [hasSearch]="config.hasSearch" [attr.placeholder]="config.searchLabel">
    </ichs-select>
  </div>

  <div *ngSwitchCase="5">
    <ichs-select [(ngModel)]="config.filterVal" [items]="config.listData" (ngModelChange)='filterValueChanged.next($event)'
      [hasSearch]="config.hasSearch" [attr.placeholder]="config.searchLabel">
    </ichs-select>
  </div>

  <div *ngSwitchCase="6">
    <ichs-text-box (keyup.enter)="enterClicked()" [(ngModel)]="config.filterVal" (ngModelChange)='filterValueChanged.next($event)'
      [attr.placeholder]="config.searchLabel">
    </ichs-text-box>
  </div>
</div>