import { Component } from '@angular/core';
import { NotificationViewModel, NotificationService, VoidInfoViewModel, SearchViewModel } from '../../../rest/index';
import { IchsGridComponentConfig, IchsControlType } from '../../../controls/ichs-grid/grid-configs';

@Component({
  selector: 'notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.css'],
  host: {
    class: "data-list"
  }
})
/** NotificationList component*/
export class NotificationListComponent {
  gridConfig: IchsGridComponentConfig<NotificationViewModel>;
  /** NotificationList ctor */
  constructor(
    private NotificationService: NotificationService,
  ) {
    this.gridConfig = this.defineGrid();
  }
  private defineGrid(): IchsGridComponentConfig<NotificationViewModel> {
    let configs: IchsGridComponentConfig<NotificationViewModel> = {
      primaryId: "id",
      defaultOrder: "startTime",
      title: "Announcements",
      entityController: "announcements",
      voidAction: (reason: VoidInfoViewModel) => this.NotificationService._void(reason),
      recoverAction: (id: number) => this.NotificationService.recover(id),
      entityDataSource: (filter: SearchViewModel) => this.NotificationService.searchNotifications(filter),
      hasDetails: true,
      headers: [
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Start Time",
          propertyName: "startTime",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
          dateFormat: 'medium'
        },
        {
          headerTitle: "End Time",
          propertyName: "endTime",
          searchable: true,
          sortable: true,
          nullable: true,
          controlType: IchsControlType.Date,
          dateFormat: 'medium'
        }
      ],
    }
    return configs;
  }
}
