<div class="fill-view main-container first-login">
  <div class="display-flex flex-items-centered">
    <div class="logo-cont"><img src="assets/images/medcorLogo.svg" class="main-logo" /></div>
    <div class="flex-grow-1 flex-basis-0"></div>
    <div style="margin-right: 8px" class="user-info-cont"><button class="cp-head-btn" (click)="logout()">Logout</button></div>
  </div>
  <hr class="main-hr" />
  <div class="">
    <div>
      <form #form="ngForm" novalidate>
        <ichs-collapsable-panel panelTitle="Add First Name & Last Name" *ngIf="changeFirstAndLastName">
          <div class="collapsableBody">
            <div class='display-flex break-la'>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box name="firstName" [(ngModel)]="model.firstName" required label="First Name"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box name="lastName" [(ngModel)]="model.lastName" required label="Last Name"></ichs-text-box>
              </div>
            </div>
          </div>
        </ichs-collapsable-panel>

        <ichs-collapsable-panel panelTitle="Change Assigned Password" *ngIf="loggedInUserInfo.changePasswordInd">
          <div class="collapsableBody">
            <div class='display-flex break-la'>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-password name="currentPassword" [(ngModel)]="model.currentPassword" required label="Current Password">
                </ichs-password>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              </div>
            </div>
            <div class='display-flex break-la'>
              <div class="flex-child flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-password #passwordComponent name="newPassword" [(ngModel)]="model.newPassword" required label="New Password"
                  passwordStrength>
                </ichs-password>
                <div class="position-relative">
                  <div class="password-rules">
                    (At least 12 characters including: capital character, small character, number or symbol.)
                  </div>
                </div>
              </div>

              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              </div>
            </div>
            <div class='display-flex break-la'>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-password name="confirmPassword" [(ngModel)]="confirmPassword" label="Confirm Password"
                  validateEqual [relatedControl]="passwordComponent.model.control" required>
                </ichs-password>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              </div>
            </div>
          </div>
        </ichs-collapsable-panel>
        <ichs-collapsable-panel panelTitle="Add Security Questions" *ngIf="loggedInUserInfo.firstLoginInd">
          <div class="collapsableBody">
            <div class='display-flex break-la' *ngFor="let secQuestion of model.secQuestionAssociationList,let i = index">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-select label="Security Question" name="securityQuestionSelect{{i}}" placeholder="Select Question"
                  [items]="getFilteredQuestionsList(secQuestion.question)" [(ngModel)]="secQuestion.question" required>
                </ichs-select>
              </div>

              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex sec-ques-answer break-sm flex-items-centered">
                <ichs-text-box label="Answer" name="securityQuestionAnswer{{i}}" [(ngModel)]="secQuestion.answer"
                  required></ichs-text-box>
              </div>

            </div>

          </div>
        </ichs-collapsable-panel>

        <button style="margin-left: 21px" class="cp-btn cp-command-btn" (click)="submit()">Save</button>
      </form>

    </div>

  </div>
</div>