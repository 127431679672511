import { Component } from '@angular/core';

@Component({
  selector: 'care-instruction',
  templateUrl: './care-instruction.component.html',
  styleUrls: ['./care-instruction.component.scss']
})
/** care-instruction component*/
export class CareInstructionComponent {

}