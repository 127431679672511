import { Component, Input, OnInit } from '@angular/core';
import { MedcorPopUpService, MedcorPopupConfig } from '../../services/medcor-popup.service';
import { ReportItemViewModel, ReportItemService } from '../../rest/index';
import { IchsImage, InputDialogConfig, IchsInputDialogComponent } from '../../controls/ichs-dialog/ichs-input-dialog/ichs-input-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReportViewHistoryComponent } from '../report-view-history/report-view-history.component';
import { ReportSchedulerConfig, ReportingSchedulerComponent } from '../reporting-scheduler/reporting-scheduler.component';
import { first } from 'rxjs/operators';
import { MedcorUserInfoService } from '../../services/medcor-user-info.service';
import { APISettings } from '../../app.general.constants';
import { ReportingService } from '../../rest/index'
import { HttpErrorResponse } from '@angular/common/http';
import { SharedFunctionsService } from '../../services/shared-functions.service';

@Component({
  selector: 'report-item-card',
  templateUrl: './report-item-card.component.html',
  styleUrls: ['./report-item-card.component.css'],
  providers: [MedcorPopUpService]
})
export class ReportItemCardComponent implements OnInit {
  @Input() public reportViewModel: ReportItemViewModel;
  @Input("defaultView") defaultView: boolean;

  thumbImage: string;
  snapshotImage: string;

  constructor(
    private popupservice: MedcorPopUpService,
    private reportItemService: ReportItemService,
    private dialog: MatDialog,
    public medcorUserInfoService: MedcorUserInfoService,
    private _reportingService: ReportingService,
    private _sharedFunctionsService: SharedFunctionsService
  ) {

  }

  ngOnInit(): void {
    if (this.reportViewModel.thumbnailId) {
      this.thumbImage = APISettings.API_FILES_PATH + '/' + this.reportViewModel.thumbnailId;
    }
    if (this.reportViewModel.snapshotId) {
      this.snapshotImage = APISettings.API_FILES_PATH + '/' + this.reportViewModel.snapshotId;
    }
  }

  toggle() {
    if (!this.reportViewModel.id) return;
    this.reportItemService.favorite(this.reportViewModel.id, !this.reportViewModel.isFavorite).pipe(first()).subscribe(medcorResult => {
      if (!medcorResult || medcorResult.result == undefined) return;
      this.reportViewModel.isFavorite = medcorResult.result;
    });
  }
  //Show report in popup
  showReport() {
    this._reportingService.reportingStatus(this.reportViewModel.lineOfBusiness).pipe(first()).subscribe(response => {

      var reportConfig: MedcorPopupConfig = <MedcorPopupConfig>{};
      reportConfig.inTab = true;
      reportConfig.title = this.reportViewModel.name;
      this.popupservice.openPopup("app-reporting-viewer/" + this.reportViewModel.id, "app-reporting-viewer/" + this.reportViewModel.id, reportConfig);
    },
      (err: HttpErrorResponse) => {
        this._sharedFunctionsService.showReportStatusDialog(err);
      });
  }

  showSnapshot() {
    let dialogConfig = new InputDialogConfig({
      controls: [
        new IchsImage({
          label: "Snapshot",
          src: this.snapshotImage,
        })
      ],
      hasActions: false,
      hasTitle: false,
      hasXButton: true,
    });

    this.dialog.open(IchsInputDialogComponent, {
      data: dialogConfig
    });
  }

  openHistory() {
    this.dialog.open(ReportViewHistoryComponent, {
      width: '1024px',
      data: this.reportViewModel
    });
  }

  scheduleReport() {
    this._reportingService.reportingStatus(this.reportViewModel.lineOfBusiness).pipe(first()).subscribe(response => {

      let dialogConfig: MatDialogConfig<ReportSchedulerConfig> = {
        data: {
          report: this.reportViewModel
        },
        width: '800px',
        disableClose: true
      };
      this.dialog.open(ReportingSchedulerComponent, dialogConfig);
    },
      (err: HttpErrorResponse) => {
        this._sharedFunctionsService.showReportStatusDialog(err);
      });
  }
}
