import { Component, OnInit, ViewChild } from '@angular/core';
import { CreateSecureMessagingAccountViewModel, SecuredEmailsService, SelectionItemViewModel, SelectionListService } from '../../rest';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';
import { first } from 'rxjs/operators';
import { UntypedFormGroup, NgForm, UntypedFormControl } from '@angular/forms';
import { AppSettings, ValidationMessages } from '../../app.general.constants';
import { Router } from '@angular/router';

@Component({
  selector: 'create-secure-messaging-account',
  templateUrl: './create-secure-messaging-account.component.html',
  styleUrls: ['./create-secure-messaging-account.component.scss']
})
export class CreateSecureMessagingAccountComponent implements OnInit {

  @ViewChild("registrationForm") registrationForm: NgForm;

  registrationModel: CreateSecureMessagingAccountViewModel = <CreateSecureMessagingAccountViewModel>{};
  confirmPassword: string;

  questionsList: SelectionItemViewModel[];

  get selectedQuestionsList(): string[] {
    return this.registrationModel.secQuestionAssociationList.map(item => item.question);
  }

  constructor(
    private alertService: MedcorAlertService,
    private selectionService: SelectionListService,
    private emailService: SecuredEmailsService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.initializeRegistrationForm();

    let url = location.href.replace(AppSettings.UI_ORIGIN_PATH, "");
    let pathParts: string[] = url.split("/");

    if (pathParts.length != 5) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Invalid External Link!"]
      });
      return;
    }

    this.registrationModel.email = pathParts[2];
    this.registrationModel.internalEmailId = pathParts[3];
    this.registrationModel.pin = pathParts[4];

    this.selectionService.getSecurityQuestions().pipe(first()).subscribe(lst => {
      this.questionsList = lst.result![0].items;
    });
  }

  createAccount() {
    if (this.registrationForm.invalid) {   // check if form is valid 
      this.validateAllFormFields(this.registrationForm.form);
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    this.emailService.createAccountForSecureMessaging(this.registrationModel)
      .pipe(first())
      .subscribe(() => {
        this.alertService.addAlert({
          type: AlertMessageType.success,
          title: 'Success!',
          dismiss: AlertDismissType.auto,
          messages: ["Account created successfully, you will be redirected to login page in a moment."],
        });

        setTimeout(() => {
          this.router.navigate(['/']);
        }, 3000);
      });
  }

  getFilteredQuestionsList(selected: string): SelectionItemViewModel[] {
    if (this.questionsList == null) return [];
    return this.questionsList.filter((question) => {
      if (this.selectedQuestionsList.indexOf(question.selectionValue) == -1 || question.selectionValue == selected)
        return true;
    });
  }

  private initializeRegistrationForm() {
    this.confirmPassword = "";

    //initiate an object.
    this.registrationModel = {
      firstName: "",
      lastName: "",
      email: "",
      clientName: "",
      password: "",
      pin: "",
      internalEmailId: "",
      secQuestionAssociationList: [
        { answer: "", question: "" },
        { answer: "", question: "" },
        { answer: "", question: "" }
      ],
    };
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
