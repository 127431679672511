<div class="upload-img">
  <div class="uploaded-img-cont">
    <div (click)="openImageCropper()">
      <img [src]="inputImage"/>
      <div class="position-relative">
        <div class="change-img position-absolute">
          <span>Change Image</span>
        </div>
      </div>
    </div>
  </div>
</div>