import { Component, Inject, ViewChild } from '@angular/core';
import { SelectionItemViewModel, AfkamCubeService } from '../../../rest/index';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';
import { IFilter, FilterType } from 'powerbi-models';
import { first } from 'rxjs/operators';

@Component({
  selector: 'dashboard-advance-filters-dialog',
  templateUrl: './dashboard-advance-filters-dialog.component.html',
  styleUrls: ['./dashboard-advance-filters-dialog.component.css']
})
// Dashboard Advanced Filters Dialog component
export class DashboardAdvanceFiltersDialogComponent {
  //Model data will be sent and received
  model: DashboardAdvanceFiltersDialogModel = new DashboardAdvanceFiltersDialogModel();
  controlsData: DashboardCustomFilterModel = new DashboardCustomFilterModel();
  //bind the Form From view 
  @ViewChild('form', { static: true }) ngForm: NgForm;

  structureLevelsIds: SelectionItemViewModel[] = [
    { selectionText: 'Enrollment', selectionValue: '1' },
    { selectionText: 'Department', selectionValue: '1' },
    { selectionText: 'Employer', selectionValue: '2' },
    { selectionText: 'Division', selectionValue: '2' },
    { selectionText: 'Occupation', selectionValue: '3' },
    { selectionText: 'Classification', selectionValue: '4' },
    { selectionText: 'Location', selectionValue: '4' },
    { selectionText: 'General Foreman', selectionValue: '5' },
    { selectionText: 'Supervisor', selectionValue: '5' },
    { selectionText: 'Foreman', selectionValue: '6' },
    { selectionText: 'Month On Site', selectionValue: '7' },
    { selectionText: 'Years In Occupation', selectionValue: '8' },
  ];

  constructor(
    public dialogRef: MatDialogRef<DashboardAdvanceFiltersDialogComponent, DashboardAdvanceFiltersDialogModel>,
    @Inject(MAT_DIALOG_DATA) public data: DashboardAdvanceFiltersDialogModel,
    private afkamCubeService: AfkamCubeService,
  ) {
    this.model = data;
    this.loadDashboardAdvanceFilterModel();
  }

  //Ok button: populate power bi filters and close dialog
  submit(): void {
    this.populateFilters();
    this.dialogRef.close(this.model);
  }

  //Cancel button: no changes, just close the dialog
  cancel(): void {
    this.dialogRef.close(this.model);
  }

  //Build filters data
  private loadDashboardAdvanceFilterModel() {
    this.afkamCubeService.getCubeDimensionsWithAttributes(this.model.lineOfBusiness).pipe(first()).subscribe(returnValue => {
      if (returnValue.result) {
        this.controlsData.cubeStructure = returnValue.result!;
        this.controlsData.dimensions = this.model.userDataGroups;
      }
    });
  }

  //Populated the power bi dashboard filter
  private populateFilters() {
    this.model.filters.splice(0);
    //Advanced custom filters tab
    if (this.controlsData.gridFilter.attributeValues != undefined && this.controlsData.gridFilter.attributeValues.length > 0) {
      this.addFilter();
    }
    //Get filter from grid filter list and build the power bi filters
    this.model.gridFilters.forEach(gridFilter => {

      if (
        gridFilter.dimensionName == "Structure Levels - Construction" ||
        gridFilter.dimensionName == "Structure Levels - Non-Construction"
      ) {

        let structureLevelId = this.structureLevelsIds.find(obj => obj.selectionText == gridFilter.attributeName).selectionValue;

        let SLNameFilter = {
          $schema: 'http://powerbi.com/product/schema#basic',
          target:
          {
            table: 'Dim Structure Level',
            column: 'SL' + structureLevelId + ' Name',
          },
          filterType: FilterType.Basic,
          operator: 'In',
          values: [gridFilter.attributeName]
        };
        let SLValueFilter = {
          $schema: 'http://powerbi.com/product/schema#basic',
          target:
          {
            table: 'Dim Structure Level',
            column: 'SL' + structureLevelId + ' Value',
          },
          filterType: FilterType.Basic,
          operator: 'In',
          values: gridFilter.attributeValues
        };
        this.model.filters.push(SLNameFilter);
        this.model.filters.push(SLValueFilter);
      }
      else {
        let filter = {
          $schema: 'http://powerbi.com/product/schema#basic',
          target:
          {
            table: gridFilter.dimensionName,
            column: gridFilter.attributeName
          },
          filterType: FilterType.Basic,
          operator: 'In',
          values: gridFilter.attributeValues
        };
        this.model.filters.push(filter);
      }
    });
  }

  //Get dimension attributes from the cube structure
  buildAttributes(isCheck: boolean) {
    if (isCheck && this.controlsData.gridFilter.dimensionName) {
      this.controlsData.attributes = [];
      this.controlsData.values = [];
      this.controlsData.cubeStructure.forEach(item => {
        if (item.selectionText == this.controlsData.gridFilter.dimensionName) {
          this.controlsData.attributes.push({ selectionText: item.selectionValue, selectionValue: item.selectionValue })
        }
      });
    }
  }

  //Get attribute values from cube
  buildAttributeValues(isCheck: boolean) {
    if (isCheck && this.controlsData.gridFilter.attributeName) {
      this.controlsData.gridFilter.attributeValues = undefined;
      this.controlsData.values = [];

      if (
        this.controlsData.gridFilter.dimensionName == "Structure Levels - Construction" ||
        this.controlsData.gridFilter.dimensionName == "Structure Levels - Non-Construction"
      ) {
        let structureLevelId = this.structureLevelsIds.find(obj => obj.selectionText == this.controlsData.gridFilter.attributeName).selectionValue;
        this.afkamCubeService.getMedfilesStructureLevelValues(structureLevelId, this.controlsData.gridFilter.attributeName, this.model.baseIds)
          .pipe(first())
          .subscribe(resp => {
            this.controlsData.values = resp.result;
          });
      }
      else {
        this.afkamCubeService.getCubeDataByDimensionNameAndBaseIds(
          '[' + this.controlsData.gridFilter.dimensionName + '].['
          + this.controlsData.gridFilter.attributeName + '].['
          + this.controlsData.gridFilter.attributeName + '].ALLMEMBERS',
          this.model.lineOfBusiness,
          this.model.baseIds)
          .pipe(first()).subscribe(
            resp => {
              this.controlsData.values = resp.result;
            }
          );
      }
    }
  }

  //Add filter to the grid filter list
  addFilter(): void {
    this.model.gridFilters.push(this.controlsData.gridFilter);
    this.controlsData.gridFilter = new FilterViewModel();
  }

  //Delete filter from the grid filter list
  deleteFieldValue(index) {
    this.model.gridFilters.splice(index, 1);
  }
}

export class DashboardAdvanceFiltersDialogModel {
  title: string = "";
  baseIds: string[];
  filters: Array<IFilter>;
  gridFilters: FilterViewModel[];
  userDataGroups: SelectionItemViewModel[];
  lineOfBusiness: string;
}

export class DashboardCustomFilterModel {
  cubeStructure: Array<SelectionItemViewModel> = [];
  dimensions?: Array<SelectionItemViewModel> = [];
  attributes?: Array<SelectionItemViewModel> = [];
  values?: Array<SelectionItemViewModel> = [];
  gridFilter?: FilterViewModel = new FilterViewModel();
}

export class FilterViewModel {
  dimensionName: string;
  attributeName: string;
  attributeValues: string[];
}
