<h1 class="main-title">Update Release Notes</h1>
<div class="cont-padding">
  <div>
    Upload a new PDF file to update Client Portal release notes
  </div>

  <div class="display-flex flex-items-centered">
    <div class="flex-child flex-basis-0 flex-grow-1">
      <input #fileInput type="file" (change)="onFileSelected($event)" style="display:none">
      <button class="cp-btn cp-main-btn" (click)="fileInput.click()">Select File</button>
    </div>
  </div>
</div>

<mat-progress-spinner *ngIf="uploader.isUploading" class="upload-spinner" [value]="uploader.progress"
  mode="determinate">
</mat-progress-spinner>