/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';












import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {MedcorResponse,PaginatedListViewModel,CubeHierarchyParameterViewModel,FileRequestValidationViewModel,FileRequestViewModel,SearchViewModel,VoidInfoViewModel,UserSecuredFileViewModel} from '../index';


@Injectable()
export class FileRequestService {

    protected basePath = 'https://localhost/ClientPortalAPI';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Delete FileRequest
     * 
     * @param id Search Id
     * @param version object Version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(id?: number, version?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<FileRequestViewModel>>;
    public _delete(id?: number, version?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<FileRequestViewModel>>>;
    public _delete(id?: number, version?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<FileRequestViewModel>>>;
    public _delete(id?: number, version?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (version !== undefined && version !== null) {
            queryParameters = queryParameters.set('version', <any>version);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<MedcorResponse<FileRequestViewModel>>(`${this.basePath}/api/FileRequest`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Void FileRequest
     * 
     * @param objVoid Void object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _void(objVoid?: VoidInfoViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<FileRequestViewModel>>;
    public _void(objVoid?: VoidInfoViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<FileRequestViewModel>>>;
    public _void(objVoid?: VoidInfoViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<FileRequestViewModel>>>;
    public _void(objVoid?: VoidInfoViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<FileRequestViewModel>>(`${this.basePath}/api/FileRequest/Void`,
            objVoid,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All FileRequests
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<FileRequestViewModel>>>;
    public get(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<FileRequestViewModel>>>>;
    public get(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<FileRequestViewModel>>>>;
    public get(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<FileRequestViewModel>>>(`${this.basePath}/api/FileRequest`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get FileRequest By Id
     * 
     * @param id Search Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(id: number, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<FileRequestViewModel>>;
    public getById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<FileRequestViewModel>>>;
    public getById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<FileRequestViewModel>>>;
    public getById(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getById.');
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<FileRequestViewModel>>(`${this.basePath}/api/FileRequest/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param requestId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getListOfUploadedFiles(requestId: number, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<UserSecuredFileViewModel>>>;
    public getListOfUploadedFiles(requestId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<UserSecuredFileViewModel>>>>;
    public getListOfUploadedFiles(requestId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<UserSecuredFileViewModel>>>>;
    public getListOfUploadedFiles(requestId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (requestId === null || requestId === undefined) {
            throw new Error('Required parameter requestId was null or undefined when calling getListOfUploadedFiles.');
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<UserSecuredFileViewModel>>>(`${this.basePath}/api/FileRequest/GetListOfUploadedFiles/${encodeURIComponent(String(requestId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add Or Update FileRequest
     * If Id is 0 Then Add Else Update
     * @param objPost FileRequest To Add or Update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public post(objPost?: FileRequestViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<FileRequestViewModel>>;
    public post(objPost?: FileRequestViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<FileRequestViewModel>>>;
    public post(objPost?: FileRequestViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<FileRequestViewModel>>>;
    public post(objPost?: FileRequestViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<FileRequestViewModel>>(`${this.basePath}/api/FileRequest`,
            objPost,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recover FileRequest
     * 
     * @param id Search Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recover(id: number, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<FileRequestViewModel>>;
    public recover(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<FileRequestViewModel>>>;
    public recover(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<FileRequestViewModel>>>;
    public recover(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling recover.');
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<FileRequestViewModel>>(`${this.basePath}/api/FileRequest/Recover/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search FileRequest
     * &#x60;&#x60;&#x60;               {                  \&quot;filterExpression\&quot;: \&quot;userName &#x3D;&#x3D; \\\&quot;Medcor\\\&quot;\&quot;,                  \&quot;pageIndex\&quot;: 1,                  \&quot;pageSize\&quot;: 10,                  \&quot;sortExpressions\&quot;: [                   \&quot;userName\&quot;, \&quot;Age Desc\&quot;                  ]               }              &#x60;&#x60;&#x60;
     * @param objSearch Searh object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(objSearch?: SearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<FileRequestViewModel>>>;
    public search(objSearch?: SearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<FileRequestViewModel>>>>;
    public search(objSearch?: SearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<FileRequestViewModel>>>>;
    public search(objSearch?: SearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<FileRequestViewModel>>>(`${this.basePath}/api/FileRequest/Search`,
            objSearch,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * upload a single file to the file request
     * 
     * @param modelEncryptedId 
     * @param modelPinCode 
     * @param modelEmail 
     * @param files 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public uploadRequestedFile(modelEncryptedId: string, modelPinCode: string, modelEmail: string, files?: Array<Blob>, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<string>>;
    public uploadRequestedFile(modelEncryptedId: string, modelPinCode: string, modelEmail: string, files?: Array<Blob>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<string>>>;
    public uploadRequestedFile(modelEncryptedId: string, modelPinCode: string, modelEmail: string, files?: Array<Blob>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<string>>>;
    public uploadRequestedFile(modelEncryptedId: string, modelPinCode: string, modelEmail: string, files?: Array<Blob>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (modelEncryptedId === null || modelEncryptedId === undefined) {
            throw new Error('Required parameter modelEncryptedId was null or undefined when calling uploadRequestedFile.');
        }

        if (modelPinCode === null || modelPinCode === undefined) {
            throw new Error('Required parameter modelPinCode was null or undefined when calling uploadRequestedFile.');
        }

        if (modelEmail === null || modelEmail === undefined) {
            throw new Error('Required parameter modelEmail was null or undefined when calling uploadRequestedFile.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = true;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (modelEncryptedId !== undefined) {
            formParams = formParams.append('Model.EncryptedId', <any>modelEncryptedId) || formParams;
        }
        if (modelPinCode !== undefined) {
            formParams = formParams.append('Model.PinCode', <any>modelPinCode) || formParams;
        }
        if (modelEmail !== undefined) {
            formParams = formParams.append('Model.Email', <any>modelEmail) || formParams;
        }
        if (files) {
            files.forEach((element) => {
                formParams = formParams.append('Files', <any>element) || formParams;
            })
        }

        return this.httpClient.post<MedcorResponse<string>>(`${this.basePath}/api/FileRequest/UploadRequestedFile`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * validate the pin code associated with the request and return the description of the file to be uploaded.
     * 
     * @param fileRequestVM 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validatePinCode(fileRequestVM?: FileRequestValidationViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<string>>;
    public validatePinCode(fileRequestVM?: FileRequestValidationViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<string>>>;
    public validatePinCode(fileRequestVM?: FileRequestValidationViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<string>>>;
    public validatePinCode(fileRequestVM?: FileRequestValidationViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<string>>(`${this.basePath}/api/FileRequest/ValidatePinCode`,
            fileRequestVM,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
