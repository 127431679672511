<div class="app-access-cont" >
    <div class="access-login-form">
        <div>
            <div class="display-flex flex-items-centered">
                <div class="logo-cont">
                    <img src="assets/images/medcorLogo.svg" class="main-logo" />
                </div>
            </div>
            <hr class="main-hr" />
        </div>
        <ng-container *ngIf="!loggedIn">

            <div class="flex-grow-1 external-page-cont">
                <div class="cont-padding">
                <div class="access-text margin-m">Please login to get access on <span class="application-name" [style.font-style]="appName ? 'italic' : ''">{{appName ? appName : "application"}}</span></div>

                    <div class="main-container">
                        <div class="">
                            <div>
                                <form #loginForm="ngForm" novalidate>

                                    <div class="collapsableBody">
                                        <div class='display-flex break-la'>
                                            <div
                                                class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                                                <ichs-text-box name="email" [(ngModel)]="email" label="Email"
                                                    required></ichs-text-box>
                                            </div>
                                        </div>

                                        <div class='display-flex break-la'>
                                            <div
                                                class="flex-child flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                                                <ichs-password name="password" [(ngModel)]="password" required
                                                    label="Password">
                                                </ichs-password>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="flex-child text-center">
                                        <button class="cp-btn cp-main-btn access-login-btn" (click)="login()">Login</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="loggedIn">
            <div class="login-success">
                <div >Thank You!</div>
                <div class="success-message">Access to <span class="application-name"><i>{{appName}}</i> </span> has been granted successfully</div>
            </div>
        </ng-container>

        <div class="access-grant-footer">
            <app-footer></app-footer>
        </div>
    </div>
</div>
