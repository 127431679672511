<div class='label-cont' *ngIf="label">
  <label [attr.for]="passIdentifier">{{label}}</label>
</div>
<div class="display-flex flex-items-centered">
  <div class="display-flex flex-items-centered flex-grow-1 position-relative">
    
    <div [hidden]="showPassword" class='flex-cell input-cont flex-basis-0 flex-grow-1'>
      <input type="password" 
        [(ngModel)]="value" 
        id="{{passIdentifier}}" 
        [ngClass]="{'invalid': (invalid)}"
        [disabled]="isDisabled" 
        [placeholder]="placeholder"
        [autocomplete]="autoComplete" />
    </div>

    <div [hidden]="!showPassword" class='flex-cell input-cont flex-basis-0 flex-grow-1 '>
      <input type="text" 
        [(ngModel)]="value" 
        id="{{passIdentifier}}_2" 
        [disabled]="isDisabled"
        [placeholder]="placeholder"/>
    </div>

    <div class="flex-cell position-absolute show-password" (mousedown)="showPassword = true"
      (mouseup)="showPassword = false" (mouseout)="showPassword = false"
      [title]="showPassword ? 'hide password' : 'show password'">

      <img draggable="false"
        [src]="showPassword ? 'assets/images/newIcons/hide-password.svg' : 'assets/images/newIcons/show-password.svg'"
        class="field-icon" />
    </div>

  </div>

  <div class="flex-cell">
    <img *ngIf="showButton" [src]="icon" class="field-icon" (click)="buttonClickedAction()" [title]="iconTitle" />
  </div>
  <div class="flex-cell">
    <validation-message *ngIf="invalid && model.control.touched" [messages]="failures"></validation-message>
  </div>
</div>