import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TriageCallReportListComponent } from './triage-call-report-list/triage-call-report-list.component';
import { AppModuleShared } from '../app.shared.module';
import { RouterModule } from '@angular/router';



@NgModule({
  imports: [
    CommonModule,
    AppModuleShared,
    RouterModule.forChild([
      { path: 'triage-call-report', component: TriageCallReportListComponent },
    ]),
  ],
  declarations: [TriageCallReportListComponent]
})
export class TriageCallReportModule { }
