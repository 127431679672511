import { Component, Inject, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { NgForm, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Subject, Observable, forkJoin, of } from 'rxjs';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { IchsDropDownTreeComponent, DROP_DOWN_TREE_LABEL_LOADING } from '../../controls/ichs-dropdown-tree/ichs-dropdown-tree.component';
import { IchsGridComponentConfig, IchsControlType, IchsGridActionsConfig } from '../../controls/ichs-grid/grid-configs';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';
import { SharedFiltersHelper } from '../../controls/ichs-dialog/shared-filters-helper.component';
import { LineOfBusiness, ReportingSettings, ValidationMessages } from '../../app.general.constants';
import { OshaReportableService, ScreeningService, SelectionItemViewModel, ReportingParametersViewModel, DynamicInstanceViewModel, AfkamCubeService, MedFilesService, SelectionTreeViewModel, ReportParameterValueViewModel, PaginatedListViewModel, MedcorResponse, SearchViewModel, AfkamService, NextgenService, ParameterStateEnum, TriageReportCustomHierarchySelectionViewModel, ParameterTypeEnum, AfkamHierarchyNode, ReportingService, TriageReportCustomHierarchySelectionService } from 'src/app/rest';
import { SharedFunctionsService } from '../../services/shared-functions.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IchsGridDialogComponent } from '../../controls/ichs-grid-dialog/ichs-grid-dialog.component';
import { ManageFutureUseHierarchyComponent, ManageFutureUseHierarchyDialogData } from './manage-future-use-hierarchy/manage-future-use-hierarchy.component';
import { IchsDialogComponent } from 'src/app/controls/ichs-dialog/ichs-dialog.component';

@Component({
  selector: 'report-parameters-dialog',
  templateUrl: './report-parameters-dialog.component.html',
  styleUrls: ['./report-parameters-dialog.component.css']
})
/** ReportParametersDialog component*/
export class ReportParametersDialogComponent implements AfterViewInit {
  reportingParametersViewModel: ReportingParametersViewModel = <ReportingParametersViewModel>{};
  model: ReportParametersDialogModel = new ReportParametersDialogModel();
  config: ReportParametersDialogConfig = new ReportParametersDialogConfig();

  @ViewChild('triageHierarchyDropDown') triageHierarchyDropDown: IchsDropDownTreeComponent;
  @ViewChild('triageHierarchyDisplayDropDown') triageHierarchyDisplayDropDown: IchsDropDownTreeComponent;
  @ViewChild('oshaHierarchyDropDown') oshaHierarchyDropDown: IchsDropDownTreeComponent;

  startDateLabel: string = "Start Date";
  endDateLabel: string = "End Date";
  startDateParamHidden: boolean = false;
  endDateParamHidden: boolean = false;

  haveTriageHierarchyDisplayParam: boolean = false;
  hierarchyDisplayTree: SelectionTreeViewModel[];

  companiesGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;
  oshaLocationsGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;
  viewPreviuosHierarchySelectionGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;
  viewSavedHierarchySelectionGridConfig: IchsGridComponentConfig<TriageReportCustomHierarchySelectionViewModel>;

  workingDate: Date = new Date;

  @ViewChild('form') ngForm: NgForm;  // 1) bind the Form From view 

  hasPreviousSelection: boolean;
  manageHierarchySelection: string;
  previuosHierarchy: AfkamHierarchyNode[];
  newHierarchySelected: boolean = false;

  hierarchiesInitialized: boolean = false;

  readonly USE_PREVIOUS_SELECTION: string = "Use Previous Selection";
  readonly EDIT_PREVIOUS_SELECTION: string = "Edit Previous Selection";
  readonly CREATE_NEW_SELECTION: string = "Create New Selection";
  readonly VIEW_SAVED_SELECTION: string = "View Saved Selections";

  hierarchySelectionOptions: SelectionItemViewModel[] = [];

  savedSelectionsIds: string[] = [];

  get parameterType() {
    return ParameterTypeEnum;
  }

  /** ReportParametersDialog ctor */
  constructor(
    public dialogRef: MatDialogRef<ReportParametersDialogComponent, ReportParametersDialogResult>,
    private _dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: ReportParametersDialogConfig,
    private reportingService: ReportingService,
    private triageReportCustomHierarchySelectionService: TriageReportCustomHierarchySelectionService,
    private alertService: MedcorAlertService, // to send Validation Messages 
    private sharedFilterHelper: SharedFiltersHelper,
    private afkamCubeService: AfkamCubeService,
    private medfilesService: MedFilesService,
    private nextgenService: NextgenService,
    private screeningService: ScreeningService,
    private oshaReportableService: OshaReportableService,
    private sharedFunctions: SharedFunctionsService,
    private afkamService: AfkamService,
    private changeDetector: ChangeDetectorRef,
  ) {
    this.config = data;
    this.model.dateRange = this.config.dateRange;

    if (this.config.workingDate) {
      this.workingDate = this.config.workingDate;
    }

    this.hasPreviousSelection = this.config.reportingParametersViewModel.reportParametersValues != null &&
      this.config.reportingParametersViewModel.reportParametersValues.some(param => param.name == ReportingSettings.INCLUDED_PARENTS_PARAM_NAME);

    this.manageHierarchySelection = this.hasPreviousSelection ? this.USE_PREVIOUS_SELECTION : this.CREATE_NEW_SELECTION;

    this.initializeManageHierarchyItems();
    this.loadReportParameterModel();

    this.model.hierarchies = this.config.hierarchies;
    this.model.oshaHierarchies = this.config.oshaHierarchies;

    let getPayorEntityTypesObs: Observable<MedcorResponse<Array<SelectionTreeViewModel>>> = of(null);
    let searchHierarchyObs: Observable<MedcorResponse<PaginatedListViewModel<AfkamHierarchyNode>>> = of(null);

    if (this.config.lineOfBusiness == LineOfBusiness.TRIAGE) {
      if (this.manageHierarchySelection == this.USE_PREVIOUS_SELECTION) {
        let included = this.reportingParametersViewModel.reportParametersValues.find(param => param.name == ReportingSettings.INCLUDED_PARENTS_PARAM_NAME).value;
        let excluded = this.reportingParametersViewModel.reportParametersValues.find(param => param.name == ReportingSettings.EXCLUDED_PARENTS_PARAM_NAME).value;

        // do not allow user to use previous selection if excluded parents where cached in local storage (we are using only included parents from now on)
        if (excluded && excluded.length > 0) {
          this.hasPreviousSelection = false;
          this.manageHierarchySelection = this.EDIT_PREVIOUS_SELECTION;
        }
        else {
          searchHierarchyObs = this.reportingService.searchHierarchyInstances({ includedParents: included, objSearch: {} }).pipe(first());
          this.viewPreviuosHierarchySelectionGridConfig = this.definePreviousHierarchySelectionGrid();
        }
      }

      this.haveTriageHierarchyDisplayParam = this.config.reportingParametersViewModel.reportParameters.findIndex(param => param.name == ReportingSettings.TRIAGE_REPORT_GENERATION_ID) > -1;
      if (this.haveTriageHierarchyDisplayParam) {
        getPayorEntityTypesObs = this.afkamService.getPayorEntityTypesTree().pipe(first());
      }

      forkJoin([
        searchHierarchyObs,
        getPayorEntityTypesObs,
      ]).subscribe(([previuosHierarchyResp, entityTypesTreeResp]) => {
        if (previuosHierarchyResp) {
          this.previuosHierarchy = previuosHierarchyResp.result.entities
        }

        if (entityTypesTreeResp) {
          this.hierarchyDisplayTree = entityTypesTreeResp.result;
          this.initializeHierarchyDisplay();
          setTimeout(() => this.onHierarchyDropDownChange(), 100);
        }

        this.hierarchiesInitialized = true;
      });
    }

    if (this.config.lineOfBusiness == LineOfBusiness.OSHA_REPORTABLE) {
      this.correctOshaHierarchySelections();
    }
  }

  ngAfterViewInit() {
    this.changeDetector.detectChanges();
  }

  /*
  to validate the form and show errors 
  */
  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  submit(): void {
    if (this.model.errorMessage) {
      this.dialogRef.close();
      return;
    }

    this.validateAllFormFields(this.ngForm.form); // validate the form and show errors
    if (!this.ngForm.valid) {// check if form is valid 
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: [ValidationMessages.INVALID_FORM],
      });
      return;
    }

    if (this.config.lineOfBusiness == LineOfBusiness.TRIAGE) {
      if (
        (this.manageHierarchySelection != this.USE_PREVIOUS_SELECTION) &&
        this.triageHierarchyDropDown.isAllExcluded()
      ) {
        this.showNoHierarchySelectedAlert();
        return;
      }
    }

    if (this.config.lineOfBusiness == LineOfBusiness.OSHA_REPORTABLE && this.oshaHierarchyDropDown.isAllExcluded()) {
      this.showNoHierarchySelectedAlert();
      return;
    }

    this.loadDependentParameters().pipe(first()).subscribe(result => {
      let _usedParams = this.getUsedParameters(result);
      let reportParametersDialogResult: ReportParametersDialogResult = {
        workingDate: this.workingDate,
        dateRange: this.model.dateRange,
        reportingParametersViewModel: result,
        usedParams: _usedParams,
      }
      this.dialogRef.close(reportParametersDialogResult);
    });
  }

  getUsedParameters(reportingParametersViewModel: ReportingParametersViewModel): ReportParameterValueViewModel[] {

    let usedParams: ReportParameterValueViewModel[] = [];

    let excludedParams: string[] = [
      "whereclause",
      "baseparents",
      "includedparents",
      "excludedparents",
    ];

    let neededParameters = reportingParametersViewModel.reportParameters
      .filter(param => param.type != ParameterTypeEnum.Hidden && excludedParams.indexOf(param.name.toLowerCase()) == -1);

    neededParameters.forEach(param => {

      let paramName = param.prompt.replace('Select', '').replace('Enter', '').trim();

      // advance search is only for companies (when user has full data access)
      if (param.type == ParameterTypeEnum.AdvanceSearch && param.name == ReportingSettings.COMPANY_ID_PARAM_NAME) {
        this.medfilesService.getSelectedCompanies(param.defaultValues).pipe(first()).subscribe(
          resp => {
            usedParams.push({
              name: paramName,
              value: resp.result.map(item => item.selectionText).join(', '),
            });
          });
      }
      else if (param.type != ParameterTypeEnum.AdvanceSearch) {

        if (param.type == ParameterTypeEnum.DateTime) {
          usedParams.push({
            name: paramName,
            value: param.defaultValues[0] ? moment(param.defaultValues[0], 'MM/DD/YYYY hh:mm:ss A').format('MM/DD/YYYY') : "N/A",
          });
        }
        else if (!param.validValues || param.validValues.length == 0) {
          usedParams.push({
            name: paramName,
            value: param.defaultValues[0] ? param.defaultValues[0] : "N/A",
          });
        }
        else {
          let _value = "N/A";

          if (param.defaultValues && param.defaultValues[0]) {
            _value = param.validValues.filter(vv => param.defaultValues.indexOf(vv.value) > -1).map(vv => vv.label).join(", ");
          }

          usedParams.push({
            name: paramName,
            value: _value,
          });
        }
      }
    });

    if (this.config.lineOfBusiness == LineOfBusiness.TRIAGE) {
      let selectedHierarchyItems: string[];
      if (this.manageHierarchySelection == this.USE_PREVIOUS_SELECTION) {
        selectedHierarchyItems = this.previuosHierarchy.map(node => node.namedPath.replace(new RegExp(' / ', 'ig'), '>'));
      }
      else {
        selectedHierarchyItems = this.triageHierarchyDropDown.getSelectedItemsNamedPaths(true);
      }

      usedParams.push({
        name: "Hierarchy",
        value: selectedHierarchyItems.join(' / '),
      });

      if (this.haveTriageHierarchyDisplayParam) {
        usedParams.push({
          name: "Hierarchy Display",
          value: this.getUsedHierarchyDisplayParamValues(),
        });
      }
    }

    if (this.config.lineOfBusiness == LineOfBusiness.OSHA_REPORTABLE) {
      let selectedHierarchyItems = this.oshaHierarchyDropDown.getSelectedItemsNamedPaths();
      usedParams.push({
        name: "Hierarchy",
        value: selectedHierarchyItems.join(' / '),
      });
    }

    return usedParams;
  }

  resetWorkingDate() {
    this.workingDate = new Date;
  }

  private getUsedHierarchyDisplayParamValues() {
    let selectedHierarchyDisplayItems = this.triageHierarchyDisplayDropDown.getSelectedItemsNamedPaths();

    let hierarchyDisplayDictionary: { [payorId: string]: string[] } = {};
    selectedHierarchyDisplayItems.forEach(item => {
      let parts = item.split('>');

      // don't return payors that have all hierarchy display options selected, they are NOT used
      if (parts.length == 2) {
        let payorName = parts[0];
        let hierarchyLevel = parts[1];
        if (!hierarchyDisplayDictionary[payorName]) {
          hierarchyDisplayDictionary[payorName] = [];
        }
        hierarchyDisplayDictionary[payorName].push(hierarchyLevel);
      }
    });

    let hierarchyDisplayParamValues: string[] = [];
    for (let payorName in hierarchyDisplayDictionary) {
      let payorWithHierarchy = payorName + ': ' + hierarchyDisplayDictionary[payorName].join(', ');
      hierarchyDisplayParamValues.push(payorWithHierarchy);
    }

    return hierarchyDisplayParamValues.join(' / ');
  }

  viewPreviousHierarchySelectionsGrid() {
    this._dialog.open(IchsGridDialogComponent, {
      width: '1024px',
      height: '800px',
      data: {
        config: this.viewPreviuosHierarchySelectionGridConfig,
        title: "Selected Clients",
      }
    });
  }

  viewSavedHierarchySelectionsGrid() {
    this.viewSavedHierarchySelectionGridConfig = this.defineSavedHierarchySelectionGrid();

    let dialogRef = this._dialog.open(IchsGridDialogComponent, {
      width: '1024px',
      height: '800px',
      data: {
        config: this.viewSavedHierarchySelectionGridConfig,
        title: "Saved Selections",
        hasAddExisting: true,
        okText: "Apply",
        cancelText: "Cancel",
      }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result && result.addSelectedInd) {
        // disable triageHierarchyDropDown while loading
        this.triageHierarchyDropDown.disabled = true;
        this.triageHierarchyDropDown.selectionModel.selectedText = DROP_DOWN_TREE_LABEL_LOADING;

        // disable haveTriageHierarchyDisplayParam while loading
        if (this.haveTriageHierarchyDisplayParam) {
          this.triageHierarchyDisplayDropDown.disabled = true;
          this.triageHierarchyDisplayDropDown.selectionModel.selectedText = DROP_DOWN_TREE_LABEL_LOADING;
        }

        this.newHierarchySelected = true;

        let selectedObjects: TriageReportCustomHierarchySelectionViewModel[] = this.viewSavedHierarchySelectionGridConfig.multiSelectConfig.getSelectedObjects();

        // join multiple included parents in one string then split them all
        let allInstanceIds = selectedObjects.map(obj => obj.includedParents).join(',').split(',');
        // get distinct Ids
        allInstanceIds = [...new Set(allInstanceIds)];

        this.savedSelectionsIds = this.viewSavedHierarchySelectionGridConfig.multiSelectConfig.getSelected();

        this.afkamCubeService.getHierarchyLocations(allInstanceIds).pipe(first()).subscribe(resp => {
          let tree = resp.result.selectionTrees;
          this.sharedFunctions.correctTreeNodes(tree[0], false, allInstanceIds, []);
          this.model.hierarchies = tree;

          // re-enable triageHierarchyDropDown once loading done
          this.triageHierarchyDropDown.disabled = false;

          if (this.haveTriageHierarchyDisplayParam) {
            this.onHierarchyDropDownChange();

            // re-enable triageHierarchyDisplayDropDown once loading done
            this.triageHierarchyDisplayDropDown.disabled = false;
          }
        });
      }
      else {
        this.onHierarchyDropDownCancel();
      }
    });
  }

  selectCompaniesGrid() {
    this.ngForm.controls.hiddenCompanySelect.markAsTouched();
    this.companiesGridConfig = this.defineSelectCompaniesGrid();
    let dialogRef = this._dialog.open(IchsGridDialogComponent, {
      width: '1024px',
      height: '800px',
      data: {
        config: this.companiesGridConfig,
        title: "Selected Companies"
      }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(() => {
      let compIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.COMPANY_ID_PARAM_NAME);
      this.companyChanged(compIdParam);
    });
  }

  defineSelectCompaniesGrid() {
    let compIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.COMPANY_ID_PARAM_NAME);
    let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      primaryId: "instanceId",
      defaultOrder: "name",
      title: "",
      hasVoid: false,
      hasActionColumn: true,
      hasNew: false,
      pageSize: 1000,
      entityController: "MedFiles",
      multiSelectConfig: { allowSelectObjects: true },
      entityDataSource: (filter: SearchViewModel) => this.getMedfilesCompAdvSearchFunction(compIdParam.defaultValues, true, filter),
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Remove",
        function: (deletedObj: DynamicInstanceViewModel) => {
          let compIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.COMPANY_ID_PARAM_NAME);
          compIdParam.defaultValues = compIdParam.defaultValues.filter(compId => compId != deletedObj.instanceId);
          this.companiesGridConfig.refreshGrid(this.companiesGridConfig.getPage());
        }
      },
      customNewAction2: {
        title: "Remove Selected",
        function: () => {
          let removedObjects = this.companiesGridConfig.multiSelectConfig.getSelectedObjects();
          if (removedObjects && removedObjects.length > 0) {
            let compIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.COMPANY_ID_PARAM_NAME);
            compIdParam.defaultValues = compIdParam.defaultValues.filter(compId => removedObjects.map(obj => obj.instanceId).indexOf(compId) == -1);
            this.companiesGridConfig.refreshGrid(this.companiesGridConfig.getPage());
          }
        }
      },
      hasDetails: false,
      addExistingConfig: {
        buttonText: "Add Existing",
        title: "Add Companies",
        gridConfig: this.defineMedfilesCompAddExistingGrid(),
        customAddExisting: (newIds: Array<string>) => {
          let compIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.COMPANY_ID_PARAM_NAME);
          compIdParam.defaultValues.push(...newIds);
          return true;
        }
      },
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        }
      ]
    }

    return configs;
  }

  private defineMedfilesCompAddExistingGrid(): IchsGridComponentConfig<DynamicInstanceViewModel> {
    let compIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.COMPANY_ID_PARAM_NAME);
    let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      primaryId: "instanceId",
      defaultOrder: "name",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      pageSize: 1000,
      multiSelectConfig: { allowSelectObjects: true },
      entityDataSource: (filter: SearchViewModel) => this.getMedfilesCompAdvSearchFunction(compIdParam.defaultValues, false, filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ]
    }
    return configs;
  }

  getMedfilesCompAdvSearchFunction(selectedCompaniesIds: string[], searchSelected: boolean, filter: SearchViewModel): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>> | PaginatedListViewModel<DynamicInstanceViewModel> {
    return this.medfilesService.searchCompanies(searchSelected, filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, selectedCompaniesIds);
  }

  selectOshaLocationsGrid() {
    this.ngForm.controls.hiddenOshaLocationSelect.markAsTouched();
    this.oshaLocationsGridConfig = this.defineSelectOshaLocationsGrid();
    this._dialog.open(IchsGridDialogComponent, {
      width: '1024px',
      height: '800px',
      data: {
        config: this.oshaLocationsGridConfig,
        title: "Selected Locations"
      }
    });
  }

  defineSelectOshaLocationsGrid() {
    let companyIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.OSHA_COMPANY_ID_PARAM_NAME);
    let locationIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.OSHA_LOCATION_ID_PARAM_NAME);
    let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      primaryId: "instanceId",
      defaultOrder: "name",
      hasVoid: false,
      hasActionColumn: true,
      hasNew: false,
      pageSize: 5000,
      pageSizeOptions: [5, 10, 20, 50, 100, 1000, 5000],
      multiSelectConfig: { allowSelectObjects: true },
      entityDataSource: (filter: SearchViewModel) => this.getOshaLocationsAdvSearchFunction(companyIdParam.defaultValues, locationIdParam.defaultValues, true, filter),
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Remove",
        function: (deletedObj: DynamicInstanceViewModel) => {
          let locationIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.OSHA_LOCATION_ID_PARAM_NAME);
          locationIdParam.defaultValues = locationIdParam.defaultValues.filter(locationId => locationId != deletedObj.instanceId);
          this.oshaLocationsGridConfig.refreshGrid(this.oshaLocationsGridConfig.getPage());
        }
      },
      customNewAction2: {
        title: "Remove Selected",
        function: () => {
          let removedObjects = this.oshaLocationsGridConfig.multiSelectConfig.getSelectedObjects();
          if (removedObjects && removedObjects.length > 0) {
            let locationIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.OSHA_LOCATION_ID_PARAM_NAME);
            locationIdParam.defaultValues = locationIdParam.defaultValues.filter(locationId => removedObjects.map(obj => obj.instanceId).indexOf(locationId) == -1);
            this.oshaLocationsGridConfig.refreshGrid(this.oshaLocationsGridConfig.getPage());
          }
        }
      },
      hasDetails: false,
      addExistingConfig: {
        buttonText: "Add Existing",
        title: "Add Locations",
        gridConfig: this.defineOshaLocationsAddExistingGrid(),
        customAddExisting: (newIds: Array<string>) => {
          let locationIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.OSHA_LOCATION_ID_PARAM_NAME);
          locationIdParam.defaultValues.push(...newIds);
          return true;
        }
      },
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        }
      ]
    }

    return configs;
  }

  private defineOshaLocationsAddExistingGrid(): IchsGridComponentConfig<DynamicInstanceViewModel> {
    let companyIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.OSHA_COMPANY_ID_PARAM_NAME);
    let locationIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.OSHA_LOCATION_ID_PARAM_NAME);
    let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      primaryId: "instanceId",
      defaultOrder: "name",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      pageSize: 5000,
      pageSizeOptions: [5, 10, 20, 50, 100, 1000, 5000],
      multiSelectConfig: { allowSelectObjects: true },
      entityDataSource: (filter: SearchViewModel) => this.getOshaLocationsAdvSearchFunction(companyIdParam.defaultValues, locationIdParam.defaultValues, false, filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ]
    }
    return configs;
  }

  getOshaLocationsAdvSearchFunction(companyIds: string[], locationIds: string[], searchSelectedLocations: boolean, filter: SearchViewModel): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>> | PaginatedListViewModel<DynamicInstanceViewModel> {
    return this.oshaReportableService.searchLocations({
      objSearch: filter,
      searchSelectedLocations: searchSelectedLocations,
      selectedCompanyIds: companyIds,
      selectedLocationIds: locationIds,
    });
  }

  loadNodeChildren = (node: SelectionTreeViewModel) => {
    return this.afkamCubeService.getHierarchyNodeChildren(node.level, node.selected);
  }

  onChange(dropDownClosed: boolean, reportParamObj: ReportParameterModel) {
    if (!dropDownClosed) {
      return;
    }

    if (this.data.lineOfBusiness == LineOfBusiness.OCCUPATIONAL_HEALTH) {
      if (reportParamObj.name == ReportingSettings.INST_ID_PARAM_NAME && reportParamObj.defaultValues.length != 0) {
        this.model.reportParameters.forEach(item => {
          if (item.name == ReportingSettings.COMPANY_ID_PARAM_NAME) {
            item.defaultValues = [];
            if (reportParamObj.defaultValues[0] != null && reportParamObj.defaultValues.length > 0) {
              this.medfilesService.getUserCompaniesByInst(reportParamObj.defaultValues[0]).pipe(first()).subscribe(response => {
                item.validValues = response.result;
              });
            }
            else
              item.validValues = [{}];
          }
          else if (item.name == ReportingSettings.SITE_ID_PARAM_NAME) {
            item.defaultValues = [];
            item.validValues = [{}];
          }
        });
      }
      else if (reportParamObj.name == ReportingSettings.COMPANY_ID_PARAM_NAME) {
        if (reportParamObj.defaultValues.length != 0) {
          this.model.reportParameters.forEach(item => {
            if (item.name == ReportingSettings.SITE_ID_PARAM_NAME) {
              if (reportParamObj.defaultValues[0] != null && reportParamObj.defaultValues.length > 0) {
                this.medfilesService.getUserSitesByCompanies(reportParamObj.defaultValues).pipe(first()).subscribe(response => {
                  item.disabled = false;
                  item.validValues = response.result;
                  let validValuesSelectionValues = item.validValues.map(obj => obj.selectionValue);
                  item.defaultValues = item.defaultValues.filter(obj => validValuesSelectionValues.indexOf(obj) > -1);
                });
              }
              else {
                item.validValues = [{}];
              }
            }
          });
        }
        else {
          let siteParam = this.model.reportParameters.find(param => param.name == ReportingSettings.SITE_ID_PARAM_NAME);
          siteParam.disabled = true;
          siteParam.defaultValues = [];
          siteParam.validValues = [{}];
        }
      }
    }
    else if (this.data.lineOfBusiness == LineOfBusiness.ADVANCED_PRACTICE) {
      if (reportParamObj.name == ReportingSettings.PRACTICE_ID_PARAM_NAME) {
        if (reportParamObj.defaultValues.length != 0) {
          let locationIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.PRACTICE_LOCATION_ID_PARAM_NAME);
          if (locationIdParam) {
            if (reportParamObj.defaultValues[0] != null && reportParamObj.defaultValues.length > 0) {
              this.nextgenService.getUserPracticeLocationsByPractices(reportParamObj.defaultValues).pipe(first()).subscribe(response => {
                locationIdParam.disabled = false;
                locationIdParam.validValues = response.result;
                let validValuesSelectionValues = locationIdParam.validValues.map(obj => obj.selectionValue);
                locationIdParam.defaultValues = locationIdParam.defaultValues.filter(obj => validValuesSelectionValues.indexOf(obj) > -1);
              });
            }
            else {
              locationIdParam.validValues = [{}];
            }
          }
        }
        else {
          let locationIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.PRACTICE_LOCATION_ID_PARAM_NAME);
          locationIdParam.disabled = true;
          locationIdParam.defaultValues = [];
          locationIdParam.validValues = [{}];
        }
      }
    }
    else if (this.data.lineOfBusiness == LineOfBusiness.SCREENING) {
      if (reportParamObj.name == ReportingSettings.SCREENING_CLIENT_ID_PARAM_NAME) {
        let locationIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.SCREENING_LOCATION_ID_PARAM_NAME);
        if (reportParamObj.defaultValues.length != 0) {
          this.screeningService.getUserLocationsByClients(reportParamObj.defaultValues).pipe(first()).subscribe(response => {
            if (response.result.length == 0) {
              locationIdParam.disabled = true;
              locationIdParam.validValues = [{}];
              locationIdParam.defaultValues = [];

              let locationSubLevelIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.SCREENING_LOCATION_SUB_LEVEL_ID_PARAM_NAME);
              if (locationSubLevelIdParam) {
                locationSubLevelIdParam.hidden = true;
                locationSubLevelIdParam.validValues = [{}];
                locationSubLevelIdParam.defaultValues = [];
              }
            }
            else {
              locationIdParam.disabled = false;
              locationIdParam.validValues = response.result;
              let validValuesSelectionValues = locationIdParam.validValues.map(obj => obj.selectionValue);
              locationIdParam.defaultValues = locationIdParam.defaultValues.filter(obj => validValuesSelectionValues.indexOf(obj) > -1);

              let locationSubLevelIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.SCREENING_LOCATION_SUB_LEVEL_ID_PARAM_NAME);
              if (locationSubLevelIdParam) {
                this.screeningService.getUserLocationSubLevelsByLocations(locationIdParam.defaultValues).pipe(first()).subscribe(response => {
                  if (response.result.length == 0) {
                    locationSubLevelIdParam.hidden = true;
                    locationSubLevelIdParam.validValues = [{}];
                    locationSubLevelIdParam.defaultValues = [];
                  }
                  else {
                    locationSubLevelIdParam.hidden = false;
                    locationSubLevelIdParam.validValues = response.result;
                    let validValuesSelectionValues = locationSubLevelIdParam.validValues.map(obj => obj.selectionValue);
                    locationSubLevelIdParam.defaultValues = locationSubLevelIdParam.defaultValues.filter(obj => validValuesSelectionValues.indexOf(obj) > -1);
                  }
                });
              }
            }
          });
        }
        else {
          locationIdParam.disabled = true;
          locationIdParam.validValues = [{}];
          locationIdParam.defaultValues = [];

          let locationSubLevelIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.SCREENING_LOCATION_SUB_LEVEL_ID_PARAM_NAME);
          if (locationSubLevelIdParam) {
            locationSubLevelIdParam.hidden = true;
            locationSubLevelIdParam.validValues = [{}];
            locationSubLevelIdParam.defaultValues = [];
          }
        }
      }
      else if (reportParamObj.name == ReportingSettings.SCREENING_LOCATION_ID_PARAM_NAME) {
        let locationSubLevelIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.SCREENING_LOCATION_SUB_LEVEL_ID_PARAM_NAME);
        if (locationSubLevelIdParam) {
          if (reportParamObj.defaultValues.length != 0) {
            this.screeningService.getUserLocationSubLevelsByLocations(reportParamObj.defaultValues).pipe(first()).subscribe(response => {
              if (response.result.length == 0) {
                locationSubLevelIdParam.hidden = true;
                locationSubLevelIdParam.validValues = [{}];
                locationSubLevelIdParam.defaultValues = [];
              }
              else {
                locationSubLevelIdParam.hidden = false;
                locationSubLevelIdParam.validValues = response.result;
                let validValuesSelectionValues = locationSubLevelIdParam.validValues.map(obj => obj.selectionValue);
                locationSubLevelIdParam.defaultValues = locationSubLevelIdParam.defaultValues.filter(obj => validValuesSelectionValues.indexOf(obj) > -1);
              }
            });
          }
          else {
            locationSubLevelIdParam.hidden = true;
            locationSubLevelIdParam.validValues = [{}];
            locationSubLevelIdParam.defaultValues = [];
          }
        }
      }
    }
    else if (this.data.lineOfBusiness == LineOfBusiness.OSHA_REPORTABLE) {
      if (reportParamObj.name == ReportingSettings.OSHA_COMPANY_ID_PARAM_NAME) {
        if (reportParamObj.defaultValues.length != 0) {
          let locationIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.OSHA_LOCATION_ID_PARAM_NAME);
          if (locationIdParam) {
            if (reportParamObj.defaultValues[0] && reportParamObj.defaultValues.length > 0) {
              this.oshaReportableService.getUserLocationsByCompanies(reportParamObj.defaultValues).pipe(first()).subscribe(response => {
                locationIdParam.disabled = false;
                let validValues = response.result.map(obj => obj.selectionValue);
                locationIdParam.defaultValues = locationIdParam.defaultValues.filter(obj => validValues.indexOf(obj) > -1);
              });
            }
            else {
              locationIdParam.defaultValues = [];
            }
          }
        }
        else {
          let locationIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.OSHA_LOCATION_ID_PARAM_NAME);
          locationIdParam.disabled = true;
          locationIdParam.defaultValues = [];
        }
      }
    }

    if (this.reportingParametersViewModel.reportParameters) {
      let disabledValue = false;
      if (!reportParamObj.defaultValues || reportParamObj.defaultValues.length == 0) {
        disabledValue = true;
      }
      for (let item of this.model.reportParameters) {
        if (item.dependencies && item.dependencies.indexOf(reportParamObj.name) >= 0) {
          item.disabled = disabledValue;
          this.loadDependentParameters();
          break;
        }
      }
    }
  }

  private loadReportParameterModel() {
    this.reportingParametersViewModel = this.config.reportingParametersViewModel;

    if (this.reportingParametersViewModel.reportParameters && this.reportingParametersViewModel.reportParameters.length > 0) {
      this.model.dateRanges = this.sharedFilterHelper.BuildDateRanges();
      let _reportParameters: ReportParameterModel[] = [];
      let accessControlParameters: ReportParameterModel[] = [];
      var startDateExist: boolean = false;
      var endDateExist: boolean = false;
      var baseParentsExist: boolean = false;
      var includedParentsExist: boolean = false;
      var excludedParentsExist: boolean = false;
      var compIdExist: boolean = false;
      var siteIdExist: boolean = false;
      var practiceIdExist: boolean = false;
      var screeningClientIdExist: boolean = false;
      var screeningLocationIdExist: boolean = false;
      var oshaCompanyIdExist: boolean = false;
      var oshaLocationIdExist: boolean = false;

      this.reportingParametersViewModel.reportParameters.forEach(item => {
        let parameterDisabled: boolean = false;
        let parameterHidden: boolean = false;
        if (item.state == ParameterStateEnum.HasOutstandingDependencies) {
          parameterDisabled = true;
        }

        if (item.name == ReportingSettings.START_DATE_PARAM_NAME) {
          this.startDateLabel = item.prompt;

          if (item.type == ParameterTypeEnum.Hidden) {
            this.startDateParamHidden = true;
          }
          else if (item.defaultValues && item.defaultValues.length > 0) {
            this.model.startDate = moment(item.defaultValues[0], 'MM/DD/YYYY hh:mm:ss A').toDate();
          }

          startDateExist = true;
        }
        else if (item.name == ReportingSettings.END_DATE_PARAM_NAME) {
          this.endDateLabel = item.prompt;

          if (item.type == ParameterTypeEnum.Hidden) {
            this.endDateParamHidden = true;
          }
          else if (item.defaultValues && item.defaultValues.length > 0) {
            this.model.endDate = moment(item.defaultValues[0], 'MM/DD/YYYY hh:mm:ss A').toDate();
          }

          endDateExist = true;
        }
        else if (item.name == ReportingSettings.BASE_PARENTS_PARAM_NAME) {
          if (item.defaultValues && item.defaultValues.length > 0) {
            this.model.baseParents = item.defaultValues[0];
          }
          baseParentsExist = true;
        }
        else if (item.name == ReportingSettings.INCLUDED_PARENTS_PARAM_NAME) {
          if (item.defaultValues && item.defaultValues.length > 0) {
            this.model.includedParents = item.defaultValues[0];
          }
          includedParentsExist = true;
        }
        else if (item.name == ReportingSettings.EXCLUDED_PARENTS_PARAM_NAME) {
          if (item.defaultValues && item.defaultValues.length > 0) {
            this.model.excludedParents = item.defaultValues[0];
          }
          excludedParentsExist = true;
        }
        else {
          let paramTempValue: any = null;

          if (item.name == ReportingSettings.COMPANY_ID_PARAM_NAME) {
            compIdExist = true;
          }
          else if (item.name == ReportingSettings.SITE_ID_PARAM_NAME) {
            siteIdExist = true;

            if (item.validValues.length == 0 || (!item.validValues[0].label && !item.validValues[0].value)) {
              item.validValues = [{}];
              parameterDisabled = true;
            }
          }
          else if (item.name == ReportingSettings.PRACTICE_ID_PARAM_NAME) {
            practiceIdExist = true;
          }
          else if (item.name == ReportingSettings.SCREENING_CLIENT_ID_PARAM_NAME) {
            screeningClientIdExist = true;
          }
          else if (item.name == ReportingSettings.SCREENING_LOCATION_ID_PARAM_NAME) {
            screeningLocationIdExist = true;

            if (item.validValues.length == 0 || (!item.validValues[0].label && !item.validValues[0].value)) {
              item.validValues = [{}];
              parameterDisabled = true;
            }
          }
          else if (item.name == ReportingSettings.SCREENING_LOCATION_SUB_LEVEL_ID_PARAM_NAME) {
            if (item.validValues.length == 0 || (!item.validValues[0].label && !item.validValues[0].value)) {
              item.validValues = [{}];
              parameterHidden = true;
            }
          }
          else if (item.name == ReportingSettings.OSHA_COMPANY_ID_PARAM_NAME) {
            oshaCompanyIdExist = true;
          }
          else if (item.name == ReportingSettings.OSHA_LOCATION_ID_PARAM_NAME) {
            oshaLocationIdExist = true;

            let companyIdParam = this.reportingParametersViewModel.reportParameters.find(param => param.name == ReportingSettings.OSHA_COMPANY_ID_PARAM_NAME);
            if (!companyIdParam.defaultValues || companyIdParam.defaultValues.length == 0) {
              parameterDisabled = true;
            }
          }
          else if (item.type == ParameterTypeEnum.DateTime && item.defaultValues[0]) { // if param type is Date
            paramTempValue = moment(item.defaultValues[0], 'MM/DD/YYYY hh:mm:ss A').toDate();
          }

          let validValues: SelectionItemViewModel[] = [];
          if (item.validValues) {
            validValues = item.validValues.map<SelectionItemViewModel>(si => {
              return {
                selectionText: si.label,
                selectionValue: si.value
              }
            });
          }

          let reportParameterModel = new ReportParameterModel({
            name: item.name,
            type: item.type,
            prompt: item.prompt,
            dependencies: item.dependencies,
            validValues: validValues,
            defaultValues: validValues.length > 0 ? item.defaultValues.filter(value => validValues.map(vv => vv.selectionValue).includes(value)) : item.defaultValues,
            multiValue: item.multiValue,
            state: item.state,
            validValuesQueryBased: item.validValuesQueryBased,
            required: item.required,
            disabled: parameterDisabled,
            hidden: parameterHidden,
            tempValue: paramTempValue,
          });

          if (item.isAccessControl) {
            accessControlParameters.push(reportParameterModel);
          } else {
            _reportParameters.push(reportParameterModel);
          }
        }
      });

      if (baseParentsExist && includedParentsExist && excludedParentsExist) {
        let included = ['0'];
        let excluded = [];

        if (this.model.includedParents) {
          included = this.model.includedParents.split(',').filter(obj => obj != '');
        }
        if (this.model.excludedParents) {
          excluded = this.model.excludedParents.split(',').filter(obj => obj != '');
        }

        let rootHierarchyNodeSelected = this.config.hierarchies[0].children.findIndex(node => included.indexOf(node.id) > -1) > -1;

        // if one of the root nodes in hierarchy is selected, then populate it and invoke correctTreeNodes, else don't (or the result will be "All Excluded")
        if (rootHierarchyNodeSelected) {
          this.sharedFunctions.correctTreeNodes(this.config.hierarchies[0], false, included, excluded);
        }
      }

      if (startDateExist && endDateExist) {
        this.model.title = this.config.title;
        this.model.message = this.config.message;
        this.model.reportParameters = _reportParameters;
        this.model.accessControlParameters = accessControlParameters;
      } else {
        this.model.errorMessage = ReportingSettings.INVALID_REPORT_FORMAT
        if (!startDateExist) {
          this.model.errorMessage += "\n" + ReportingSettings.MISSING_START_DATE_PARAMETER;
        }
        if (!endDateExist) {
          this.model.errorMessage += "\n" + ReportingSettings.MISSING_END_DATE_PARAMETER;
        }
      }

      if (this.data.lineOfBusiness == LineOfBusiness.OCCUPATIONAL_HEALTH && !(compIdExist && siteIdExist)) {
        this.model.errorMessage = ReportingSettings.INVALID_REPORT_FORMAT;
        if (!compIdExist) {
          this.model.errorMessage += "\n" + ReportingSettings.MISSING_COMPANY_ID_PARAMETER;
        }
        if (!siteIdExist) {
          this.model.errorMessage += "\n" + ReportingSettings.MISSING_SITE_ID_PARAMETER;
        }
      }
      else if (this.data.lineOfBusiness == LineOfBusiness.ADVANCED_PRACTICE && !practiceIdExist) {
        this.model.errorMessage = ReportingSettings.INVALID_REPORT_FORMAT;
        this.model.errorMessage += "\n" + ReportingSettings.MISSING_PRACTICE_ID_PARAMETER;
      }
      else if (this.data.lineOfBusiness == LineOfBusiness.SCREENING && !(screeningClientIdExist && screeningLocationIdExist)) {
        this.model.errorMessage = ReportingSettings.INVALID_REPORT_FORMAT;
        if (!screeningClientIdExist) {
          this.model.errorMessage += "\n" + ReportingSettings.MISSING_CLIENT_ID_PARAMETER;
        }
        if (!screeningLocationIdExist) {
          this.model.errorMessage += "\n" + ReportingSettings.MISSING_LOCATION_ID_PARAMETER;
        }
      }
      else if (this.data.lineOfBusiness == LineOfBusiness.OSHA_REPORTABLE && !(oshaCompanyIdExist && oshaLocationIdExist)) {
        this.model.errorMessage = ReportingSettings.INVALID_REPORT_FORMAT;
        if (!oshaCompanyIdExist) {
          this.model.errorMessage += "\n" + ReportingSettings.MISSING_OSHA_COMPANY_ID_PARAMETER;
        }
        if (!oshaLocationIdExist) {
          this.model.errorMessage += "\n" + ReportingSettings.MISSING_OSHA_LOCATION_ID_PARAMETER;
        }
      }
    } else {
      this.model.errorMessage = ReportingSettings.INVALID_REPORT_FORMAT;
      this.model.errorMessage += "\n" + ReportingSettings.MISSING_REPORT_PARAMETERS;
    }
  }

  /**
   Load the report parameters
   */
  private loadDependentParameters(): Observable<ReportingParametersViewModel> {
    let completed = new Subject<ReportingParametersViewModel>();
    this.populateParameters();
    this.reportingParametersViewModel.fromReportScheduler = this.data.fromReportScheduler;
    this.reportingService.loadReportParameters(this.reportingParametersViewModel).pipe(first()).subscribe(
      returnValue => {
        if (!returnValue.result) {
          return;
        }

        completed.next(returnValue.result);
        this.config.reportingParametersViewModel = returnValue.result;
        this.loadReportParameterModel();
      },
      (err: HttpErrorResponse) => {
        let error = err.error as MedcorResponse<null>;
        if (error.statusCode == 400 && !error.notify) {
          this.alertService.addAlert({
            type: AlertMessageType.warning,
            title: 'Warning!',
            dismiss: AlertDismissType.controlled,
            messages: error.messages,
          });
        }
      });
    return completed.asObservable();
  }

  private populateParameters() {
    var _reportParametersValues: ReportParameterValueViewModel[] = [];
    this.model.reportParameters.forEach(rp => {

      // if parameter of type Date
      if (rp.type == ParameterTypeEnum.DateTime && rp.tempValue) {
        rp.defaultValues[0] = moment(rp.tempValue).format('YYYY-MM-DD');
      }

      let paramValidValues = null;
      if (rp.validValues && rp.validValues.length > 0) {
        paramValidValues = rp.validValues.map(obj => obj.selectionValue);
      }

      // has defaultValues & not hidden
      if (rp.defaultValues && rp.type != ParameterTypeEnum.Hidden && !rp.hidden) {
        rp.defaultValues.forEach(dv => {
          if (!paramValidValues) {
            _reportParametersValues.push({
              name: rp.name,
              value: dv
            });
          }
          else {
            if (paramValidValues.indexOf(dv) > -1) {
              _reportParametersValues.push({
                name: rp.name,
                value: dv
              });
            } else {

              this.alertService.addAlert({
                type: AlertMessageType.warning,
                title: 'Warning!',
                dismiss: AlertDismissType.controlled,
                messages: ["Value [" + dv + "] is not a valid value for parameter [" + rp.name + "]"]
              });
            }
          }
        });
      }
    });
    this.model.accessControlParameters.forEach(rp => {
      if (rp.defaultValues) {
        rp.defaultValues.forEach(dv => {
          _reportParametersValues.push({
            name: rp.name,
            value: dv
          })
        })
      }
    });

    if (this.data.lineOfBusiness == LineOfBusiness.TRIAGE) {

      /* prepare hierarchy parameter values */

      let included = '';
      let excluded = '';
      if (this.manageHierarchySelection == this.USE_PREVIOUS_SELECTION) {
        included = this.reportingParametersViewModel.reportParametersValues.find(param => param.name == ReportingSettings.INCLUDED_PARENTS_PARAM_NAME).value;
      }
      else {
        const selection = this.triageHierarchyDropDown.getLeafSelection();

        if (selection.included.length === 1 && selection.included[0] === '0') {
          included = '0';
        }
        else {
          included = selection.included.join(',');
        }
      }

      _reportParametersValues.push({
        name: ReportingSettings.INCLUDED_PARENTS_PARAM_NAME,
        value: included
      });
      _reportParametersValues.push({
        name: ReportingSettings.EXCLUDED_PARENTS_PARAM_NAME,
        value: excluded
      });
      _reportParametersValues.push({
        name: ReportingSettings.BASE_PARENTS_PARAM_NAME,
        value: this.model.baseParents
      });

      /* Prepare hierarchy display parameter values, if parameter exists */
      if (this.haveTriageHierarchyDisplayParam) {
        const displaySelection = this.triageHierarchyDisplayDropDown.getSelection();

        let hierarchyDisplayDictionary: { [payorId: string]: string[] } = {};
        displaySelection.included.forEach(item => {
          let parts = item.split('>');
          if (parts.length == 3) {
            let payorId = parts[1];
            let hierarchyLevel = parts[2];
            if (!hierarchyDisplayDictionary[payorId]) {
              hierarchyDisplayDictionary[payorId] = [];
            }
            hierarchyDisplayDictionary[payorId].push(hierarchyLevel);
          }
        });

        this.reportingParametersViewModel.triageReportHierarchyDisplay = [];
        for (let payorId in hierarchyDisplayDictionary) {
          this.reportingParametersViewModel.triageReportHierarchyDisplay.push({
            payorId: payorId,
            hierarchyDisplay: hierarchyDisplayDictionary[payorId].join('|'),
          });
        }

        // send included & excluded values to save them with other report param for report scheduler
        // so that we can populate them when user attempt to update report schedule params
        this.reportingParametersViewModel.triageReportHierarchyDisplayTreeValues = {
          included: displaySelection.included,
          excluded: displaySelection.excluded,
        };
      }
      else {
        this.reportingParametersViewModel.triageReportHierarchyDisplay = null;
        this.reportingParametersViewModel.triageReportHierarchyDisplayTreeValues = null;
      }
    }

    if (this.data.lineOfBusiness == LineOfBusiness.OSHA_REPORTABLE) {
      const selection = this.oshaHierarchyDropDown.getSelection();

      this.reportingParametersViewModel.oshaHierarchyTreeValues = {
        included: selection.included,
        excluded: selection.excluded,
      };

      let companies: string[] = this.oshaHierarchyDropDown.data[0].children.filter(node => node.selected || node.partialSelection).map(node => node.id);
      let locations: string[] = [];

      // if all selected, put all companies in locations variable
      if (selection.included.length === 1 && selection.included[0] === '0') {
        locations = this.oshaHierarchyDropDown.data[0].children.map(node => node.id);
      }
      else {
        locations = selection.included;
      }

      companies.forEach(companyId => {
        _reportParametersValues.push({
          name: ReportingSettings.OSHA_COMPANY_ID_PARAM_NAME,
          value: companyId,
        });
      });

      locations.forEach(locationId => {
        _reportParametersValues.push({
          name: ReportingSettings.OSHA_LOCATION_ID_PARAM_NAME,
          value: locationId,
        });
      });
    }

    if (this.model.dateRange && !this.startDateParamHidden && !this.endDateParamHidden) {
      var dates = null;
      if (this.workingDate) {
        dates = this.sharedFilterHelper.populateDateFilters(this.model.dateRange, this.workingDate);
      }
      else {
        dates = this.sharedFilterHelper.populateDateFilters(this.model.dateRange);
      }

      this.model.startDate = dates.startDate;
      this.model.endDate = dates.endDate;
      _reportParametersValues.push({
        name: ReportingSettings.START_DATE_PARAM_NAME,
        value: dates.startDate
      });
      _reportParametersValues.push({
        name: ReportingSettings.END_DATE_PARAM_NAME,
        value: dates.endDate
      });
    } else {
      // this value should be clear manually (to remove it from cache), in case report doesn't have date range param (reports with start or end date hidden)
      this.model.dateRange = null;

      if (this.model.startDate) {
        _reportParametersValues.push({
          name: ReportingSettings.START_DATE_PARAM_NAME,
          value: moment(this.model.startDate).format('YYYY-MM-DD')
        });
      }
      if (this.model.endDate) {
        _reportParametersValues.push({
          name: ReportingSettings.END_DATE_PARAM_NAME,
          value: moment(this.model.endDate).format('YYYY-MM-DD')
        });
      }
    }

    this.reportingParametersViewModel.reportParametersValues = _reportParametersValues;
  }

  companyChanged(companyIdParam: ReportParameterModel) {
    let siteIdParam = this.model.reportParameters.find(param => param.name == ReportingSettings.SITE_ID_PARAM_NAME);
    if (siteIdParam) {
      if (companyIdParam.defaultValues[0] != null && companyIdParam.defaultValues.length > 0) {
        this.medfilesService.getUserSitesByCompanies(companyIdParam.defaultValues).pipe(first()).subscribe(response => {
          if (response.result && response.result.length > 0) {
            siteIdParam.validValues = response.result;
            let validValuesSelectionValues = siteIdParam.validValues.map(obj => obj.selectionValue);
            siteIdParam.defaultValues = siteIdParam.defaultValues.filter(obj => validValuesSelectionValues.indexOf(obj) > -1);
            siteIdParam.disabled = false;
          }
          else {
            siteIdParam.defaultValues = [];
            siteIdParam.validValues = [{}];
            siteIdParam.disabled = true;
          }
        });
      }
      else {
        siteIdParam.defaultValues = [];
        siteIdParam.validValues = [{}];
        siteIdParam.disabled = true;
      }
    }
  }

  onManageHierarchyChanged() {
    if (this.manageHierarchySelection == this.USE_PREVIOUS_SELECTION) {
      this.newHierarchySelected = false;

      if (this.haveTriageHierarchyDisplayParam) {
        this.onHierarchyDropDownChange();
      }
    }
    else if (this.manageHierarchySelection == this.VIEW_SAVED_SELECTION) {
      this.viewSavedHierarchySelectionsGrid();
    }
    else {
      if (this.manageHierarchySelection == this.CREATE_NEW_SELECTION) {
        this.clearHierarchy();
      }
      else {
        this.model.hierarchies = this.config.hierarchies;
      }

      setTimeout(() => {
        this.triageHierarchyDropDown.openTreePopup();
      }, 0);
    }
  }

  onHierarchyDropDownChange() {
    if (this.manageHierarchySelection == this.USE_PREVIOUS_SELECTION) {
      // filter to keep only selected payors from hierarchy dropdown tree
      let selectedPayorIds = this.previuosHierarchy.map(node => node.nodePath.split('>')[1]);
      // get Distinct
      selectedPayorIds = [...new Set(selectedPayorIds)];

      // if all selected payors are in hierarchy display, no need to initilize it again, 
      // but if that was not the case, then re-initialize it
      // (user may had deleted some of them while playing around with "Manage Hierarchy" dropdown)
      let hierarchyDisplayPayorIds = this.model.hierarchyDisplay[0].children.map(node => node.id);
      if (!selectedPayorIds.every(payorId => hierarchyDisplayPayorIds.includes(payorId))) {
        this.initializeHierarchyDisplay();
      }

      this.model.hierarchyDisplay[0].children = this.model.hierarchyDisplay[0].children.filter(node => selectedPayorIds.indexOf(node.id) > -1);
      this.triageHierarchyDisplayDropDown.selectionChanged();
    }
    else {
      // get selected payor ids from hierarchy dropdown tree
      let selectedPayorIds = this.model.hierarchies[0].children
        .filter(payorItem => payorItem.selected || payorItem.partialSelection)
        .map(payorItem => payorItem.id);

      // get a new deep copy of the original tree
      let tree = JSON.parse(JSON.stringify(this.hierarchyDisplayTree)) as SelectionTreeViewModel[];
      // filter to keep only selected payors from hierarchy dropdown tree
      tree[0].children = tree[0].children.filter(node => selectedPayorIds.indexOf(node.id) > -1);

      // return old selections to hierarchy display dropdown tree
      let selectionObj = this.triageHierarchyDisplayDropDown.getSelection();
      this.sharedFunctions.correctTreeNodes(tree[0], true, selectionObj.included, selectionObj.excluded);

      // set new hierarchy
      this.model.hierarchyDisplay = tree;
    }
  }

  onHierarchyDropDownSubmit() {
    this.newHierarchySelected = true;

    if (this.triageHierarchyDropDown.saveSelectionForFutureUse) {
      let dialogConfig: MatDialogConfig<ManageFutureUseHierarchyDialogData> = {
        data: <ManageFutureUseHierarchyDialogData>{
          node: this.triageHierarchyDropDown.data[0],
          hash: this.triageHierarchyDropDown.hash,
          loadChildren: this.loadNodeChildren,
          isLevel: false,
        },
        width: '800px',
        disableClose: true,
      };
      let dialogRef = this._dialog.open(ManageFutureUseHierarchyComponent, dialogConfig);
      dialogRef.afterClosed().pipe(first()).subscribe(result => {
        if (result) {
          // re-trigger hierarchyDropDown.selectionChanged() and onHierarchyDropDownChange(), 
          // so that hierarchyDropDown label will get updated in case user updated selection inside ManageFutureUseHierarchyComponent
          this.triageHierarchyDropDown.selectionChanged();
          if (this.haveTriageHierarchyDisplayParam) {
            this.onHierarchyDropDownChange();
          }
        }
      });
    }
  }

  onHierarchyDropDownCancel() {
    if (this.hasPreviousSelection && !this.newHierarchySelected) {
      this.manageHierarchySelection = this.USE_PREVIOUS_SELECTION;
    }

    if (this.haveTriageHierarchyDisplayParam) {
      this.onHierarchyDropDownChange();
    }
  }

  private definePreviousHierarchySelectionGrid(): IchsGridComponentConfig<AfkamHierarchyNode> {
    let included = this.reportingParametersViewModel.reportParametersValues.find(param => param.name == ReportingSettings.INCLUDED_PARENTS_PARAM_NAME).value;

    let configs: IchsGridComponentConfig<AfkamHierarchyNode> = {
      primaryId: "pk",
      defaultOrder: "name",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      entityDataSource: (filter: SearchViewModel) => this.reportingService.searchHierarchyInstances({ includedParents: included, objSearch: filter }),
      headers: [
        {
          headerTitle: "Client Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Sub Client",
          propertyName: "namedPath",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
      ]
    }
    return configs;
  }

  private defineSavedHierarchySelectionGrid(): IchsGridComponentConfig<TriageReportCustomHierarchySelectionViewModel> {
    let configs: IchsGridComponentConfig<TriageReportCustomHierarchySelectionViewModel> = {
      primaryId: "id",
      defaultOrder: "selectionName",
      hasVoid: false,
      hasDetails: false,
      hasNew: false,
      pageSize: 5,
      multiSelectConfig: { allowSelectObjects: true, initialSelection: this.savedSelectionsIds },
      entityDataSource: (filter: SearchViewModel) => this.triageReportCustomHierarchySelectionService.search(filter),
      secondAction: new IchsGridActionsConfig({
        function: (record: TriageReportCustomHierarchySelectionViewModel) => {
          let dialogRef = this._dialog.open(IchsDialogComponent, {
            width: '450px',
            data: { title: "Delete Saved Selection", message: "Are you sure about deleting this record?" }
          });

          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.triageReportCustomHierarchySelectionService._delete(record.id).pipe(first()).subscribe(obj => {
                if (obj.result) {
                  this.viewSavedHierarchySelectionGridConfig.refreshGrid(0);
                }
              });
            }
          });
        },
        icon: "./assets/images/delete.svg",
        title: "Delete"
      }),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "selectionName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
      ]
    }
    return configs;
  }

  private initializeManageHierarchyItems() {
    if (this.hasPreviousSelection) {
      this.hierarchySelectionOptions.push({
        selectionText: this.USE_PREVIOUS_SELECTION,
        selectionValue: this.USE_PREVIOUS_SELECTION,
      });
      this.hierarchySelectionOptions.push({
        selectionText: this.EDIT_PREVIOUS_SELECTION,
        selectionValue: this.EDIT_PREVIOUS_SELECTION,
      });
    }

    this.hierarchySelectionOptions.push({
      selectionText: this.CREATE_NEW_SELECTION,
      selectionValue: this.CREATE_NEW_SELECTION,
    });

    if (!this.data.fromReportScheduler) {
      this.hierarchySelectionOptions.push({
        selectionText: this.VIEW_SAVED_SELECTION,
        selectionValue: this.VIEW_SAVED_SELECTION,
      });
    }
  }

  private initializeHierarchyDisplay() {
    this.model.hierarchyDisplay = JSON.parse(JSON.stringify(this.hierarchyDisplayTree));
    let storedDisplaySelection = this.config.reportingParametersViewModel.triageReportHierarchyDisplayTreeValues;
    if (storedDisplaySelection) {
      this.sharedFunctions.correctTreeNodes(this.model.hierarchyDisplay[0], true, storedDisplaySelection.included, storedDisplaySelection.excluded);
    }
  }

  private correctOshaHierarchySelections() {
    let storedOshaHierarchySelection = this.config.reportingParametersViewModel.oshaHierarchyTreeValues;
    if (storedOshaHierarchySelection) {
      this.sharedFunctions.correctTreeNodes(this.model.oshaHierarchies[0], false, storedOshaHierarchySelection.included, storedOshaHierarchySelection.excluded);
    }
  }

  private clearHierarchy() {
    let _hierarchies = JSON.parse(JSON.stringify(this.config.hierarchies));
    this.sharedFunctions.correctTreeNodes(_hierarchies[0], false, [], ['0']);
    this.model.hierarchies = _hierarchies;
  }

  private showNoHierarchySelectedAlert() {
    this.alertService.addAlert({
      type: AlertMessageType.warning,
      title: 'Warning!',
      dismiss: AlertDismissType.auto,
      messages: [ValidationMessages.NO_HIERARCHY_SELECTED],
    });
  }
}

export class ReportParametersDialogConfig {
  title: string = "";
  message: string = "";
  okText?: string;
  dateRange: string;
  workingDate?: Date;
  lineOfBusiness: string;
  reportingParametersViewModel: ReportingParametersViewModel;
  hierarchies?: SelectionTreeViewModel[];
  oshaHierarchies?: SelectionTreeViewModel[];
  fromReportScheduler?: boolean = false;
}

export class ReportParametersDialogResult {
  workingDate: Date;
  dateRange: string;
  reportingParametersViewModel: ReportingParametersViewModel;
  usedParams: ReportParameterValueViewModel[];
}

export class ReportParametersDialogModel {
  title: string = "";
  message: string = "";
  errorMessage?: string;
  dateRanges?: Array<SelectionItemViewModel>;
  dateRange?: string;
  startDate?: Date;
  endDate?: Date;
  baseParents?: string;
  includedParents?: string;
  excludedParents?: string;
  reportParameters: ReportParameterModel[] = [];
  accessControlParameters: ReportParameterModel[] = [];
  showAccessControl: boolean = false;
  hierarchies: SelectionTreeViewModel[] = [];
  hierarchyDisplay: SelectionTreeViewModel[] = [];
  oshaHierarchies: SelectionTreeViewModel[] = [];
}

export class ReportParameterModel {
  public constructor(init?: Partial<ReportParameterModel>) {
    Object.assign(this, init);
  }
  name?: string;
  type?: ParameterTypeEnum;
  prompt?: string;
  dependencies?: Array<string>;
  validValues?: Array<SelectionItemViewModel>;
  defaultValues?: Array<string>
  multiValue?: boolean;
  state?: ParameterStateEnum;
  validValuesQueryBased?: boolean;
  required?: boolean;
  disabled?: boolean = false;
  hidden?: boolean = false;
  tempValue?: any;
}
