
<div class="label-cont" *ngIf="label">
    <label [attr.for]="identifier">{{label}}</label>
</div>
<div class="display-flex flex-items-centered">


  <div class="flex-cell input-cont flex-basis-0 flex-grow-1">
    <input matInput
           [(ngModel)]="value"
           [matDatepicker]="picker"
           [id]="identifier"
           [ngClass]="{invalid: (invalid)}"
           (dateChange)="dateChangedEvent($event)"
           (keydown)="keydownEventHandler($event)"
           [disabled]="isDisabled"
           [placeholder]="datePlaceHolder"
           class="flex-basis-0 flex-grow-1">
  </div>
  <div class="flex-cell">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker (opened)="onMatDatePickerOpened()" (closed)="onMatDatePickerClosed()"></mat-datepicker>
  </div>
  <div class="flex-cell">
    <validation-message *ngIf="invalid && model.control.touched"
                        [messages]="failures"></validation-message>
  </div>
</div>
