import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabGroup } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IchsCommandToolbarDelegate, IchsCommandToolbarModel } from 'src/app/controls/ichs-command-toolbar/ichs-command-toolbar.component';
import { AppUserService, AppUserViewModel, UserLookupDetailsViewModel, VoidInfoViewModel } from 'src/app/rest';
import { MedcorResponse } from 'src/app/rest/model/medcorResponse';
import { MedcorAlertService } from 'src/app/services/medcor-alert.service';


@Component({
  selector: 'user-management-details',
  templateUrl: './user-management-details.component.html',
  styleUrl: './user-management-details.component.scss'
})
export class UserManagementDetailsComponent implements OnInit, IchsCommandToolbarDelegate<UserLookupDetailsViewModel> {
  @ViewChild('form', { static: true }) ngForm: NgForm;
  @ViewChild('innerMatTabGroup') innerMatTabGroup: MatTabGroup;

  model: IchsCommandToolbarModel<UserLookupDetailsViewModel>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appUserService: AppUserService,
    private location: Location,
    private alertService: MedcorAlertService,
    private dialog: MatDialog,
  ) {
    this.model = new IchsCommandToolbarModel<UserLookupDetailsViewModel>(this.location, this.router, "/user-management", "User Management", this, this.dialog, this.alertService);
    this.model.hasSave = false;
  }

  ngOnInit() {
    this.model.init(this.route);
  }

  getLoadObjModelObservable(id: number) {
    return this.appUserService.getManagedUserDetails(id);
  }

  getVoidObservable(objVoidModel: VoidInfoViewModel): Observable<MedcorResponse<AppUserViewModel>> {
    return this.appUserService.voidManagedUser(objVoidModel)
  }

  getRecoverObservable(id: number): Observable<MedcorResponse<AppUserViewModel>> {
    return this.appUserService.recoverManagedUser(id);
  }

  canDeactivate() {
    return true;
  }
}