/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Temporary added and need to be replaced with database dashboardItem
 */
export interface PowerBIDashboardItemViewModel { 
    userDashboardItemAssociationId?: number;
    dashboardItemId?: number;
    name?: string;
    powerBIGroupId?: string;
    powerBIReportId?: string;
    tag?: string;
    tagTitle?: string;
    defaultBookmarkData?: string;
    hasExport?: boolean;
}
