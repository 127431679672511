import { NgModule } from '@angular/core';
import { InjuryTriageInfoComponent } from './injury-triage-info/injury-triage-info.component';
import { RouterModule } from '@angular/router';
import { OnsiteClincsInfoComponent } from './onsite-clincs-info/onsite-clincs-info.component';
import { ConstructionHealthSafetyInfoComponent } from './construction-health-safety-info/construction-health-safety-info.component';
import { HelpCenterComponent } from './help-center/help-center.component';
import { AppModuleShared } from '../app.shared.module';
import { HelpCenterCategoryComponent } from './help-center-category/help-center-category.component';
import { HelpCenterCategoryListComponent } from './help-center-category-list/help-center-category-list.component';
import { HelpCenterQuestionComponent } from './help-center-question/help-center-question.component';
import { HelpCenterQuestionListComponent } from './help-center-question-list/help-center-question-list.component';
import { CanActivateGuard, CanDeactivateGuard } from '../services/routing-guard.service';
import { AboutClientPortalComponent } from './about-client-portal/about-client-portal.component';
import { ReleaseNotesComponent } from './info-components/release-notes/release-notes.component';

@NgModule({
  exports: [

  ],
  imports: [
    AppModuleShared,
    RouterModule.forChild([
      { path: 'about', component: AboutClientPortalComponent },
      { path: 'release-notes', component: ReleaseNotesComponent },
      { path: 'injury-triage', component: InjuryTriageInfoComponent },
      { path: 'onsite-clinics', component: OnsiteClincsInfoComponent },
      { path: 'help-center', component: HelpCenterComponent },
      { path: 'construction-health-safety', component: ConstructionHealthSafetyInfoComponent },
      { path: 'help-category', component: HelpCenterCategoryListComponent },
      { path: 'help-category/:id', canActivate: [CanActivateGuard], canDeactivate: [CanDeactivateGuard], component: HelpCenterCategoryComponent },
      { path: 'help-question', component: HelpCenterQuestionListComponent },
      { path: 'help-question/:id', component: HelpCenterQuestionComponent },
    ]),
  ],
  declarations: [
    InjuryTriageInfoComponent,
    OnsiteClincsInfoComponent,
    ConstructionHealthSafetyInfoComponent,
    HelpCenterComponent,
    HelpCenterCategoryComponent,
    HelpCenterCategoryListComponent,
    HelpCenterQuestionComponent,
    HelpCenterQuestionListComponent,
    AboutClientPortalComponent,
    ReleaseNotesComponent,
  ]
})
export class InfoModule {
}
