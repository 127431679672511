import { Component, OnInit } from '@angular/core';
import { AfkamService, SelectionItemViewModel, TriageAfterCareFileViewModel } from 'src/app/rest';
import { first } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { AppSettings, ValidationMessages } from 'src/app/app.general.constants';
import { AlertMessageType, AlertDismissType, MedcorAlertService } from 'src/app/services/medcor-alert.service';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { MedfilesAfterCareInstructionsService } from '../../rest/api/medfilesAfterCareInstructions.service';
import { MedcorResponse } from '../../rest/model/medcorResponse';
import { Observable } from 'rxjs';
import { MedfilesAfterCareFileViewModel } from '../../rest/model/medfilesAfterCareFileViewModel';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'external-care-instruction',
  templateUrl: './external-care-instruction.component.html',
  styleUrls: ['./external-care-instruction.component.scss']
})
/** external-care-instruction component*/
export class ExternalCareInstructionComponent implements OnInit {
  public triageAfterCareList: TriageAfterCareFileViewModel[] | MedfilesAfterCareFileViewModel[] = [];
  key: string;
  isMedfilesCareInstruction: boolean = false;

  /** external-care-instruction ctor */
  constructor(
    private afkamService: AfkamService,
    private alertService: MedcorAlertService,
    private medfilesAfterCareInstructionsService: MedfilesAfterCareInstructionsService,
    private sharedFunctions: SharedFunctionsService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    this.isMedfilesCareInstruction = location.pathname.indexOf("/medfiles-care-instruction") > -1;

    if (this.isMedfilesCareInstruction) {
      this.key = location.href.replace(AppSettings.UI_ORIGIN_PATH + "/medfiles-care-instruction/", "").replace(location.search, "");
    }
    else {
      this.key = location.href.replace(AppSettings.UI_ORIGIN_PATH + "/care-instruction/", "").replace(location.search, "");
    }

    if (this.key == "" || this.key.indexOf("/care-instruction") != -1 || this.key.indexOf("/medfiles-care-instruction") != -1) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Invalid Link!"]
      });
      return;
    }

    let obs: Observable<MedcorResponse<TriageAfterCareFileViewModel[] | MedfilesAfterCareFileViewModel[]>> = this.isMedfilesCareInstruction ?
      this.medfilesAfterCareInstructionsService.getTriageAfterCareFilesByEncryptedParam(this.key) :
      this.afkamService.getTriageAfterCareFilesByEncryptedParam(this.key);

    obs.pipe(first()).subscribe(res => {
      this.triageAfterCareList = res.result;

      if (this.isMedfilesCareInstruction) {
        let isSpanish: boolean = this.route.snapshot.queryParams['isSpanish'] == "true";
        
        for (let file of (this.triageAfterCareList as MedfilesAfterCareFileViewModel[])) {
          let isSpanishAvailable: boolean = file.languages.some(obj => obj.selectionValue == "Spanish");
          file.language = isSpanish && isSpanishAvailable ? "Spanish" : file.languages[0].selectionValue;
        }
      }
    });
  }

  downloadFile(file: TriageAfterCareFileViewModel | MedfilesAfterCareFileViewModel) {
    if (this.key == "" || this.key.indexOf("/care-instruction") != -1 || this.key.indexOf("/medfiles-care-instruction") != -1) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    let obs: Observable<HttpEvent<Blob>> = this.isMedfilesCareInstruction ?
      this.medfilesAfterCareInstructionsService.downloadTriageAfterCareFileByEnc(file.triageAfterCareFileId, this.key, file.language, 'events', true) :
      this.afkamService.downloadTriageAfterCareFileByEnc(file.triageAfterCareFileId, this.key, 'events', true);

    let sub = obs.subscribe({
      next: event => {
        if (event.type == HttpEventType.Response) {
          this.sharedFunctions.saveToFileSystem(event);
        }
      },
      error: () => sub.unsubscribe(),
      complete: () => sub.unsubscribe(),
    });
  }
}
