import { Component, HostListener, isDevMode, QueryList, Renderer2, ViewChild, ViewChildren, ElementRef, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';
import { AppSettings, MfaConstants } from '../../app.general.constants';
import { AppuserCardComponent } from '../../appuser/appuser-card/appuser-card.component';
import { ImpersonateComponent } from '../../appuser/impersonate/impersonate.component';
import { IchsComponentDialogComponent } from '../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { UserStatus } from '../../ng-chat/ng-chat';
import { NgChat } from '../../ng-chat/ng-chat.component';
import { AccountsService, AppUserService, AppUserViewModel, MedcorResponse, NotificationModel, NotificationService, UserContactService, UserPermissionsViewModel, TriageSecuredFileService, SecureFileService } from '../../rest/index';
import { LoggedUserViewModel } from '../../rest/model/loggedUserViewModel';
import { SendCallRecordComponent } from '../../secured-email/send-call-record/send-call-record.component';
import { GeneralVariablesService, UserImpersonationInfo } from '../../services/general-variables.service';
import { AlertDismissType, AlertMessageType, AlertPositionClassType, MedcorAlertService, MedcorAlertServiceMessage } from '../../services/medcor-alert.service';
import { MedcorAuthenticationService } from '../../services/medcor-authentication.service';
import { MedcorPopUpService, MedcorPopupConfig } from '../../services/medcor-popup.service';
import { MedcorSignalRService, ActiveUserModel } from '../../services/medcor-signalr.service';
import { MedcorUserInfoService } from '../../services/medcor-user-info.service';
import { UserAgreementComponent } from '../user-agreement/user-agreement.component';
import { SignalRChatAdapter } from './app.signalr.chat.adapter';
import { HttpEventType } from '@angular/common/http';
import { SendFileComponent } from 'src/app/file-management/send-file/send-file.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NotSupportedBrowserPopupComponent } from '../not-supported-browser-popup/not-supported-browser-popup.component';
import { SharedFunctionsService } from '../../services/shared-functions.service';
import { MfaSetupComponent } from '../mfa-setup/mfa-setup.component';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [MedcorPopUpService, SignalRChatAdapter]
})

export class AppComponent {
  private _messageRecivedSubs?: Subscription;
  private _newNotificationSubs?: Subscription;
  popupMode: boolean = false;
  forgetPassword: boolean = false;
  notificationsCount: number = 0;
  chatAdapter: SignalRChatAdapter;
  userPermissions: UserPermissionsViewModel = <UserPermissionsViewModel>{};
  private unRegisterNotificationsListHandler: Function;
  allowSelectContacts: boolean = false;
  allowContactsDrag: boolean = false;
  @ViewChildren(AppuserCardComponent) appuserCards: QueryList<AppuserCardComponent>;
  mainActivePage: number = 0;
  countersSubscription: Subscription | undefined = undefined;
  showChangeLog: boolean = false;
  isUserActive: boolean = null;
  @ViewChild("addContactForm") addContactForm: NgForm;
  contactEmail: string;
  hideAddContactForm: boolean = true;
  adminRoleInd: boolean = false;
  hasTriageCallReportsAccess: boolean = false;

  // loggedInUserObs.isImpersonated flag will be true even if the impersonator has 'Admin' role, so we need another indicator for non-admin impersonator
  impersonatorRoleInd: boolean = false;
  clientUserScheduledReportsOnlyRoleInd: boolean = false;

  userImage: string;
  impersonatorUserImage: string;

  showTriagePart: boolean = false;
  showOnsitePart: boolean = false;
  notifications: NotificationModel[] = [];
  @ViewChild(NgChat) ngChat: NgChat;
  @ViewChild('contactsSearchBox') contactsSearchBox: ElementRef;

  emailModelChanged: Subject<string> = new Subject<string>();
  userEmails: string[] = [];
  isContactsAutoCompleteOn: boolean = false;  // available for medcor users only
  activeUsers: ActiveUserModel[] = [];

  showTriageSecuredFilePage: boolean = false;

  userEmail: string;
  userHasAccount: boolean = null;
  isTriageSecuredFileLinkInvalidOrExpired: boolean = false;
  triageSecuredFileAccessedUsingPinCode: boolean;
  triageSecuredFileEncryptedData: string;
  triageSecuredFilePinCode: string;
  triageSecuredFileValidationMessage: string;

  helpCenterSearchTerm: string;
  showMfa: boolean = false;

  masPinSetWindow: Window;

  get authenticatedObs(): Observable<boolean> { return this.authenticationService.authenticatedObs }
  get loggedInUserObs(): Observable<LoggedUserViewModel> { return this.medcorUserInfoService.loggedInUserObs }

  constructor(
    private popupservice: MedcorPopUpService,
    private alertService: MedcorAlertService,
    private toastr: ToastrService,
    private medcorUserInfoService: MedcorUserInfoService,
    private signalRService: MedcorSignalRService,
    private accountsService: AccountsService,
    private router: Router,
    private signalRChatAdapter: SignalRChatAdapter,
    private authenticationService: MedcorAuthenticationService,
    private renderer: Renderer2,
    private appUserService: AppUserService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    public generalVariables: GeneralVariablesService,
    private userContactService: UserContactService,
    private appuserService: AppUserService,
    private triageSecuredFileService: TriageSecuredFileService,
    private secureFileService: SecureFileService,
    private changeDetector: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private sharedFunctions: SharedFunctionsService,
  ) {
    this.alertService.getAlert.subscribe(alert => this.handleAlert(alert));

    this.chatAdapter = signalRChatAdapter;

    if (!this.generalVariables.clientBrowserInfo.isChromeBase) {
      this.dialog.open(IchsComponentDialogComponent, {
        disableClose: true,
        width: '700px',
        data: { component: NotSupportedBrowserPopupComponent, title: "Browser is not Supported!", hideCloseButton: false }
      });
    }

    if (location.pathname.indexOf("/triageSecuredFile") >= 0) {
      this.showTriageSecuredFilePage = true;
      this.initializeTraigeSecuredFileRequest();
    }

    this.loggedInUserObs.subscribe(loggedInUser => {
      if (loggedInUser.appUserId > 0) {
        this.adminRoleInd = loggedInUser.roleName == "Admin";
        this.impersonatorRoleInd = loggedInUser.roleName == "Impersonator";
        this.clientUserScheduledReportsOnlyRoleInd = loggedInUser.roleName == "Client User - Scheduled Reports Only";

        // in case of mobile or tablet, collapse left menu, and completely disable right menu (per CP-1859)
        if (this.generalVariables.clientDeviceInfo.isMobile || this.generalVariables.clientDeviceInfo.isTablet) {
          this.generalVariables.appViewModel.rightIsDisabled = true;
        }
        else {
          // always start with contacts hidden for all users (per CP-1856)
          this.generalVariables.appViewModel.contactsIsHidden = true;

          // show admin menu in case of admins only (per CP-1856)
          this.generalVariables.appViewModel.adminMenuIsHidden = !this.adminRoleInd;
        }

        this.onWindowResize();

        this.generalVariables.userImpersonationInfo = <UserImpersonationInfo>{
          isImpersonatedInd: loggedInUser.isImpersonated,
          impersonaterUserRoleName: loggedInUser.roleName,
          impersonatedUserRoleName: loggedInUser.impersonatedUserRoleName,
          impersonatorRoleInd: this.impersonatorRoleInd,
        };

        let url = location.href.replace(AppSettings.UI_ORIGIN_PATH, "");

        if (loggedInUser.roleName == "Client User - Triage Portal") {
          if (url.indexOf("/home-page") >= 0 || url.length == 0 || url.length == 1) {
            this.router.navigate(['/securedfiles']);
          }
        }

        if (!this.impersonatorRoleInd && !this.clientUserScheduledReportsOnlyRoleInd) {
          this.userContactService.getRelatedContacts().pipe(first()).subscribe(res => {
            this.medcorUserInfoService.setRelatedAppUsers(res.result);
          });

          this.notificationService.getNotificationsCount().pipe(first()).subscribe(res => {
            this.notificationsCount = res.result;
          });
        }

        this.appUserService.getUserPermissions().pipe(first()).subscribe(medcorResult => {
          this.userPermissions = medcorResult.result;
        });

        this.appUserService.isActiveTriageRecipient().pipe(first()).subscribe(medcorResult => {
          this.hasTriageCallReportsAccess = medcorResult.result;
        });

        if (loggedInUser.userImageId) {
          this.secureFileService.downloadFile(loggedInUser.userImageId, 'events', true).subscribe(event => {
            if (event.type == HttpEventType.Response) {
              this.userImage = URL.createObjectURL(event.body)
            }
          });
        }

        if (loggedInUser.impersonaterImageId) {
          this.secureFileService.downloadFile(loggedInUser.impersonaterImageId, 'events', true).subscribe(event => {
            if (event.type == HttpEventType.Response) {
              this.impersonatorUserImage = URL.createObjectURL(event.body);
            }
          });
        }
      }
    });

    this.authenticatedObs.subscribe(value => {
      this.authenticationChanged(value);
    });
  }

  ngOnInit() {
    this.showChangeLog = AppSettings.SHOW_CHANGE_LOG;
    this.generalVariables.showInjuryTriageObs.subscribe(async (val: boolean) => {
      this.showTriagePart = await val;
    });

    this.generalVariables.showOnSiteClinicObs.subscribe(async (val: boolean) => {
      this.showOnsitePart = await val;
    });

    this.popupMode = location.search.indexOf("popupmode=true") > -1;
    this.loggedInUserObs.subscribe(loggedInUser => {
      if (loggedInUser.appUserId > 0) {
        this.appUserService.getUserBusinessLines().pipe(first()).subscribe(resp => {
          this.generalVariables.userLineOfBusinessList = resp.result.lineOfBusinessList;
        });

        // show user agreement
        if (!loggedInUser.firstLoginInd && !loggedInUser.changePasswordInd && !loggedInUser.acceptedUserAgreement) {
          this.showUserAgreement(false);
        }

        // show mfa setup screen
        this.showMfa = false;
        let mfaEmailVerificationNeeded = (loggedInUser.roleName == MfaConstants.MFA_EMAIL_ROLE && loggedInUser.sharedInboxEmail);
        if (!loggedInUser.isActiveDirectoryUser && !loggedInUser.isImpersonated) {
          if (!loggedInUser.firstLoginInd && !loggedInUser.changePasswordInd && loggedInUser.acceptedUserAgreement) {
            if ((mfaEmailVerificationNeeded && !loggedInUser.mfaSharedEmailEnabled) || (!mfaEmailVerificationNeeded && !loggedInUser.mfaAppEnabled && !loggedInUser.mfaTextEnabled)) {
              this.showMfaSetupScreen();
              this.showMfa = true;
            }
          }
        }

        if (!this.impersonatorRoleInd && !this.popupMode) {
          this.appuserService.getUserCountersSummary().pipe(first()).subscribe(result => {
            this.generalVariables.updateSystemCounters(result.result);
          });
        }

        // get current user status
        this.signalRService.activeUsersObs.subscribe(activeUsers => {
          this.isUserActive = activeUsers.findIndex(user => user.id == loggedInUser.appUserId) >= 0;
          this.activeUsers = activeUsers;
        });

        this.generalVariables.isActiveDirectoryUser = loggedInUser.isActiveDirectoryUser;
        this.isContactsAutoCompleteOn = loggedInUser.isActiveDirectoryUser;

        if (this.isContactsAutoCompleteOn) {
          this.emailModelChanged
            .pipe(
              debounceTime(400),
              distinctUntilChanged(),
              switchMap(value => value.length > 2 ? this.appuserService.searchUserEmails(value) : of(null)),
            )
            .subscribe(res => this.userEmails = res ? res.result : []);
        }
      }
    });

    this.router.events.pipe(filter(obj => obj instanceof NavigationEnd)).subscribe(routeEvent => {
      let navEvent = routeEvent as NavigationEnd;

      if (location.pathname.indexOf("/securedEmailAccess") >= 0) {
        this.mainActivePage = 1;
      } else if (location.pathname.indexOf("/fileRequests") >= 0) {
        this.mainActivePage = 2;
      }
      else if (location.pathname.endsWith("/care-instruction") || location.pathname.endsWith("/medfiles-care-instruction")) {
        this.mainActivePage = 4;
      }
      else if (location.pathname.indexOf("/care-instruction") >= 0 || location.pathname.indexOf("/medfiles-care-instruction") >= 0) {
        this.mainActivePage = 7;
      }
      else if (location.pathname.indexOf("/change-password") >= 0) {
        this.mainActivePage = 5;
      }
      else if (location.pathname.indexOf("/recover-password") >= 0) {
        this.mainActivePage = 6;
      }
      else if (location.pathname.indexOf("/triage-portal-user-pincode-access") >= 0) {
        this.mainActivePage = 8;
      }
      else if (location.pathname.indexOf("/external-triage-secured-file") >= 0) {
        this.mainActivePage = 9;
      }
      else if (location.pathname.indexOf("/signatureRequests") >= 0) {
        this.mainActivePage = 10;
      }
      else if (location.pathname.indexOf("/create-secure-messaging-account") >= 0) {
        this.mainActivePage = 11;
      }
      else if (location.pathname.indexOf("/grant-app-access") >= 0) {
        this.mainActivePage = 12;
      }
      else if (location.pathname.indexOf("/responsive") >= 0) {
        this.mainActivePage = 13;
      }
      else if (location.pathname.indexOf("/mfa-reset") >= 0) {
        this.mainActivePage = 14;
      }
      else {
        this.mainActivePage = 0;
      }

      this.showTriageSecuredFilePage = navEvent.urlAfterRedirects.indexOf("/triageSecuredFile") >= 0;

      if (navEvent.urlAfterRedirects.indexOf("/secureMessaging/new") >= 0 || navEvent.urlAfterRedirects.indexOf("secureMessaging/forward") >= 0) {
        this.appuserCards.forEach(obj => obj.selected = false);
        this.allowSelectContacts = true;
        this.allowContactsDrag = true;
      }
      else {
        this.allowSelectContacts = false;
        this.allowContactsDrag = false;
      }

      if (!this.generalVariables.clientDeviceInfo.isDesktop) {
        this.generalVariables.appViewModel.leftIsHidden = true;
      }
    });

  }

  @HostListener('window:resize')
  onWindowResize() {
    // in case of mobile or tablet, collapse left menu, and completely disable right menu (per CP-1859)
    this.generalVariables.appViewModel.rightIsDisabled = !this.generalVariables.clientDeviceInfo.isDesktop;
  }

  showTriageSecuredFilePinCodeForm(event: Event) {
    if (event) {
      event.stopPropagation();
    }

    if (this.isTriageSecuredFileLinkInvalidOrExpired) {
      this.showTriageSecuredFileValidationMessage();
      return;
    }
  }

  showTriageSecuredFileLoginForm(event: Event) {
    if (event) {
      event.stopPropagation();
    }

    if (this.isTriageSecuredFileLinkInvalidOrExpired) {
      this.showTriageSecuredFileValidationMessage();
      return;
    }

    this.authenticatedObs.pipe(first(authenticated => authenticated)).subscribe(() => {
      this.triageSecuredFileService.getTriageSecuredFile(this.triageSecuredFileEncryptedData).pipe(first()).subscribe(res => {
        let triageSecuredFileInfo = res.result;
        this.router.navigate(['/triage-call-report'], {
          replaceUrl: true,
          queryParams: {
            "ichs-grid-refNbr": triageSecuredFileInfo.referenceNumber,
            "loadOnInit": true,
          }
        });
      });
    });

    this.showLogin();
  }

  onTriageSecuredFilePinCodeSubmit(pinCode: string) {
    this.triageSecuredFileAccessedUsingPinCode = true;
    this.triageSecuredFilePinCode = pinCode;
  }

  onTriageUserRegisterOrLogout() {
    this.triageSecuredFileAccessedUsingPinCode = false;
  }

  changeUserStatus() {
    if (this.isUserActive) {
      this.signalRService.goOffline();
    }
    else {
      this.signalRService.goOnline();
    }
  }

  showMfaSetupScreen() {
    let dialogRef = this.dialog.open(IchsComponentDialogComponent, {
      disableClose: true,
      width: '600px',
      data: { component: MfaSetupComponent, hideCloseButton: true }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      // create new object so that loggedInUser Observable can recognize the change
      let newObj = Object.assign({}, this.medcorUserInfoService.getLoggedInUserInfo());
      newObj.mfaAppEnabled = true;
      newObj.mfaTextEnabled = true;
      this.medcorUserInfoService.setLoggedInUserObs(newObj);
      this.authenticationService.unAuthenticate();
    });
  }

  showUserAgreement(acceptedUserAgreement: boolean) {
    let dialogRef = this.dialog.open(IchsComponentDialogComponent, {
      disableClose: true,
      width: '800px',
      data: { component: UserAgreementComponent, title: "User Agreement", hideCloseButton: !acceptedUserAgreement, extraData: acceptedUserAgreement }
    });

    if (!acceptedUserAgreement) {
      dialogRef.afterClosed().pipe(first()).subscribe(result => {
        if (result) {
          this.acceptUserAgreement();
        } else {
          this.logout();
        }
      });
    }
  }

  acceptUserAgreement() {
    this.appUserService.acceptUserAgreement().pipe(first()).subscribe(() => {
      // create new object so that loggedInUser Observable can recognize the change
      let newObj = Object.assign({}, this.medcorUserInfoService.getLoggedInUserInfo());
      newObj.acceptedUserAgreement = true;
      this.medcorUserInfoService.setLoggedInUserObs(newObj);
    });
  }

  redirectToLogin() {
    this.forgetPassword = false;
  }

  redirectToLoginFromReset() {
    this.mainActivePage = 0;
    this.router.navigate(['/home']);
  }

  toggleContactAdd() {
    setTimeout(() => this.contactsSearchBox.nativeElement.focus(), 0);  // set focus to add-contacts text box when openning it
    this.hideAddContactForm = !this.hideAddContactForm;
    this.contactEmail = null;
    this.userEmails = [];
  }

  addContact() {
    if (this.addContactForm.invalid) {
      this.addContactForm.controls.contactEmail.markAsTouched();
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        messages: ["Invalid email address!"],
        positionClass: AlertPositionClassType.topLeft,
      });
      return;
    }

    this.userContactService.addContact(this.contactEmail).pipe(first()).subscribe(
      res => {
        if (res.result) {
          this.medcorUserInfoService.addRelatedAppUsers(res.result);
          this.hideAddContactForm = true;
          this.contactEmail = null;
          this.userEmails = [];
        }
      },
      err => {
        let errResponse = err.error as MedcorResponse<any>;
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: errResponse.messages,
          positionClass: AlertPositionClassType.topLeft,
        });
      });
  }

  openChat(userContact: AppUserViewModel) {
    this.signalRChatAdapter.chatWithUser(userContact);
    this.ngChat.openUserChatWindow(userContact.id);
  }

  getUserStatus(userContact: AppUserViewModel): UserStatus {
    let isActive = this.activeUsers.findIndex(user => user.id == userContact.id) > -1;
    return isActive ? UserStatus.Online : UserStatus.Offline;
  }

  //subscribe to closing the browser event
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any) {
    this.popupservice.closeAll();

    // don't show "Leave site?" confimation popup in case of Dev Environment or popup-mode or in case of page redirection
    if (isDevMode() || this.popupMode || location.search.indexOf("?redirectTo=") > -1)
      return true;
    else
      return false;
  }

  logout() {
    this.sharedFunctions.logout();
  }

  personProfile() {
    this.router.navigate(['/appuser/manageProfile']);
  }

  setLoginPin() {
    let _config = <MedcorPopupConfig>{
      fullscreen: false,
      width: 500,
      height: 700,
      additionalQueryParams: <any>{
        mode: "pincodereset"
      }
    };

    this.masPinSetWindow = this.popupservice.openPopup(`SetMedcorLoginPinPopup`, AppSettings.MAS_URL, _config);
    window.addEventListener('message', this.handleSetMedcorLoginPinPopupResponse.bind(this));
  }

  unImpersonate() {
    this.accountsService.unImpersonate().pipe(first()).subscribe(() => {
      this.authenticationService.unImpersonate();
      location.assign(AppSettings.UI_ORIGIN_PATH); // navigate & refresh
    });
  }

  Impersonate() {
    this.dialog.open(ImpersonateComponent, {
      width: "800px",
    });
  }

  sendCallRecording() {
    this.dialog.open(IchsComponentDialogComponent, {
      disableClose: true,
      width: '800px',
      data: { component: SendCallRecordComponent, title: "Send Telehealth Call Recording", hideCloseButton: true }
    });
  }

  sendFile() {
    this.dialog.open(IchsComponentDialogComponent, {
      disableClose: true,
      width: '800px',
      data: { component: SendFileComponent, title: "Send File", hideCloseButton: true }
    });
  }

  //dragdrop contacts:
  drag(ev: DragEvent, userCard: AppUserViewModel) {
    var cards = this.appuserCards.filter(obj => obj.selected).map(email => email.userContact.email);
    if (cards.length == 0) {
      cards = [userCard.email]
    }

    ev.dataTransfer.setData("text", JSON.stringify({ type: "addContacts", emails: cards }));
  }

  onCardOpened(event: any) {
    if (this.generalVariables.appViewModel.selectedCard) {
      if (this.generalVariables.appViewModel.selectedCard != event) {
        this.generalVariables.appViewModel.selectedCard.isCardVisible = false;
      }
      this.generalVariables.appViewModel.selectedCard = event;
    }
    else {
      this.generalVariables.appViewModel.selectedCard = event;
    }
    this.generalVariables.appViewModel.isUserCardOpen = !this.generalVariables.appViewModel.isUserCardOpen;
  }

  hideUserCard() {
    this.generalVariables.appViewModel.isUserCardOpen = false;
  }

  showLogin(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
  }

  showNotificationsList(event?: Event) {
    if (event) {
      event.stopPropagation();
    }

    this.notificationService.get().pipe(first()).subscribe(medcorResult => {
      if (medcorResult.result) {
        this.notifications = medcorResult.result;
      }
    });

    this.generalVariables.appViewModel.toggleNotificationsList();
    this.unRegisterNotificationsListHandler = this.renderer.listen('document', 'click', () => {
      this.generalVariables.appViewModel.isNotificationsListVisible = false;
      this.unRegisterNotificationsListHandler();
    });
  }

  onNotificationOpen(notification: NotificationModel) {
    this.generalVariables.appViewModel.isNotificationsListVisible = false;
    if (!notification.isRead) {
      this.notificationsCount--;
    }
  }

  searchHelpCenter() {
    this.router.navigate(['/help-center'], {
      queryParams: {
        searchText: this.helpCenterSearchTerm
      }
    }).then(() => {
      this.helpCenterSearchTerm = null;
      this.changeDetector.detectChanges();
    });
  }

  private initializeTraigeSecuredFileRequest() {
    this.triageSecuredFileEncryptedData = location.search.replace("?data=", "");
    if (!this.triageSecuredFileEncryptedData) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Invalid File Link!"]
      });
      return;
    }

    this.triageSecuredFileService.initializeRequest(this.triageSecuredFileEncryptedData)
      .pipe(first())
      .subscribe({
        next: resp => {
          if (resp.result) {
            this.userEmail = resp.result.userEmail;
            this.userHasAccount = resp.result.userHasAccount;

            if (this.userHasAccount) {
              this.showTriageSecuredFileLoginForm(null);
            }
            else {
              this.showTriageSecuredFilePinCodeForm(null);
            }
          }
        },
        error: err => {
          this.isTriageSecuredFileLinkInvalidOrExpired = true;
          let errResponse = err.error as MedcorResponse<any>;
          this.triageSecuredFileValidationMessage = errResponse.messages[0];
          if (!errResponse.notify) {
            this.showTriageSecuredFileValidationMessage();
          }
        }
      });
  }

  private showTriageSecuredFileValidationMessage() {
    this.alertService.addAlert({
      type: AlertMessageType.warning,
      title: 'Warning!',
      dismiss: AlertDismissType.controlled,
      messages: [this.triageSecuredFileValidationMessage]
    });
  }

  private authenticationChanged(value: boolean) {
    if (value && !this.impersonatorRoleInd) {
      this.startSignalR();
    } else {
      this.stopSignalR();
    }
  }

  /**
   * Connect to SignalR Hub
   */
  private startSignalR() {
    this._messageRecivedSubs = this.signalRService.messageRecivedObs.subscribe(message => { // Subscribe ro Recived Messages and save subscribtion
      this.handleAlert({ // If message recived show toastr
        type: AlertMessageType.info,
        messages: [message],
        title: "Admin:",
        positionClass: AlertPositionClassType.bottomRight,
        dismiss: AlertDismissType.controlled,
      });
    });

    if (!this.clientUserScheduledReportsOnlyRoleInd) {
      this._newNotificationSubs = this.signalRService.newNotificationObs
        .pipe(
          switchMap(() => this.notificationService.getNotificationsCount()),
          map(mObj => (mObj.result == undefined) ? 0 : mObj.result)
        )
        .subscribe(value => {
          this.notificationsCount = value;
        });
    }
  }

  /**
   * Disconnect from SignalR
   */
  private stopSignalR() {
    if (this._messageRecivedSubs) {
      this._messageRecivedSubs.unsubscribe(); // remove Subscription to recived messages from SignalR
    }

    if (this._newNotificationSubs) {
      this._newNotificationSubs.unsubscribe(); // remove Subscription to new notifications count from SignalR
    }
  }

  private handleAlert(alert: MedcorAlertServiceMessage) {
    if (alert.messages != undefined && alert.messages.length > 0) {
      let alertDismiss = alert.dismiss || AlertDismissType.auto; // if dismiss is not set then auto by default
      let alertShowCloseButton = (alertDismiss == AlertDismissType.controlled); // if dismiss type is controlled then show close button
      let alertPositionClass = alert.positionClass || AlertPositionClassType.topRight; // default position if not set is topRight
      let alertTitle = alert.title // No Default Title Just show the content
      switch (alert.type) {
        case AlertMessageType.success:
          for (let msg of alert.messages) {
            this.toastr.success(msg, alertTitle, {
              closeButton: alertShowCloseButton,
              enableHtml: true,
              disableTimeOut: alertDismiss == AlertDismissType.click || alertDismiss == AlertDismissType.controlled,
              positionClass: alertPositionClass
            });
          }
          break;
        case AlertMessageType.error:
          for (let msg of alert.messages) {
            this.toastr.error(msg, alertTitle, {
              closeButton: alertShowCloseButton,
              enableHtml: true,
              disableTimeOut: alertDismiss == AlertDismissType.click || alertDismiss == AlertDismissType.controlled,
              positionClass: alertPositionClass
            });
          }
          break;
        case AlertMessageType.warning:
          for (let msg of alert.messages) {
            this.toastr.warning(msg, alertTitle, {
              closeButton: alertShowCloseButton,
              enableHtml: true,
              disableTimeOut: alertDismiss == AlertDismissType.click || alertDismiss == AlertDismissType.controlled,
              positionClass: alertPositionClass
            });
          }
          break;
        case AlertMessageType.info:
          for (let msg of alert.messages) {
            this.toastr.info(msg, alertTitle, {
              closeButton: alertShowCloseButton,
              enableHtml: true,
              disableTimeOut: alertDismiss == AlertDismissType.click || alertDismiss == AlertDismissType.controlled,
              positionClass: alertPositionClass,
            });
          }
          break;
        default:
      }
    }
  }

  private handleSetMedcorLoginPinPopupResponse(event: MessageEvent) {
    if (event.source === this.masPinSetWindow && event.data.startsWith("pincode:")) {
      let dataParts = (<string>(event.data)).split('&');
      let pinCode = dataParts[0].split(':')[1];
      let optPin = <boolean>(dataParts[1].split('=')[1] as unknown);

      this.accountsService.setMasPinCode(pinCode, optPin)
        .pipe(first())
        .subscribe();
    }

    // Popup window closed
    window.removeEventListener('message', this.handleSetMedcorLoginPinPopupResponse.bind(this));
    this.masPinSetWindow = null;
  }
}
