import { Component, Input, OnInit } from '@angular/core';
import { GeneralVariablesService } from '../../services/general-variables.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @Input() termsOfService: boolean = false;

  constructor(public generalVariables: GeneralVariablesService) { }

  ngOnInit() {
  }
}
