<div class="cont-padding">
  <div class="display-flex flex-items-centered">
    <div class="email-cont-title flex-basis-0 flex-grow-1">
      {{mailBox | titlecase}}
    </div>
    <div (click)="toggleSearchForm()" class="email-search-icon">
      <img src="assets/images/search.svg" />
    </div>
  </div>
  <div class="search-form" *ngIf="searchFormToggle">
    <div class="">
      <form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
        <div class="display-flex break-la">
          <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            <ichs-text-box [(ngModel)]="emailFilter.senderName" name="emailFromOrTo"
              [label]="mailBox == 'sent' ? 'To' : 'From'"></ichs-text-box>
          </div>
          <div class="flex-col-sep"></div>
          <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            <ichs-text-box [(ngModel)]="emailFilter.title" name="emailTitle" label="Title"></ichs-text-box>
          </div>
          <div class="flex-col-sep"></div>
          <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            <ichs-date-picker [(ngModel)]="emailFilter.sentReceiveDate" name="ReceiveDate" label="Date">
            </ichs-date-picker>
          </div>
          <div class="flex-col-sep"></div>
          <div style="align-self: flex-end"
            class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            <ichs-check-box [(ngModel)]="emailFilter.hasAttachments" name="hasAttachment" label="Has Attachment"
              [indeterminate]="emailFilter.hasAttachments == undefined"></ichs-check-box>
          </div>
          <ng-container *ngIf="mailBox !='sent'">
            <div class="flex-col-sep"></div>
            <div style="align-self: flex-end"
              class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              <ichs-check-box [(ngModel)]="emailFilter.isSharedInboxMessage" name="isSharedInboxMessage" label="Shared Inbox Email"
                [indeterminate]="emailFilter.isSharedInboxMessage == undefined"></ichs-check-box>
            </div>
          </ng-container>
        </div>

      </form>
      <div class="search-acitons text-center">
        <button class="cp-btn cp-mail-btn cp-mail-btn-with-icon" (click)="loadData(0)">
          <div class="display-flex align-items-center">
            <img class="email-action-icon" src="assets/images/search.svg" />
            <span class="email-action-text">Search Emails</span>
          </div>
        </button>
        <button class="cp-btn cp-mail-btn cp-mail-btn-with-icon" (click)="clearFilters()">
          <div class="display-flex align-items-center">
            <img class="email-action-icon" src="assets/images/eraser.svg" />
            <span class="email-action-text">Clear Search</span>
          </div>
        </button>
      </div>
    </div>
    <hr class="main-hr" />
  </div>
  <div class="email-list">
    <div class="email-header display-flex flex-items-centered">
      <div style="padding-left: 5px;" class="flex-basis-0 flex-grow-1 margin-right-def" (click)="sortBy('senderName')"
        [ngClass]="{'sortedColumn':sortColumn=='senderName'}"><span
          class="email-col-head">{{mailBox=='sent'?'To':'From'}}</span><img class="sorted-icon"
          *ngIf="sortColumn=='senderName'"
          [src]="sortMode=='Desc'?'assets/images/descending.svg':'assets/images/ascending.svg'" />
      </div>
      <div (click)="sortBy('title')" [ngClass]="{'sortedColumn':sortColumn=='title'}"
        class="flex-basis-0 flex-grow-1 margin-right-def"><span class="email-col-head">Title</span><img
          class="sorted-icon" *ngIf="sortColumn=='title'"
          [src]="sortMode=='Desc'?'assets/images/descending.svg':'assets/images/ascending.svg'" />
      </div>
      <div style="flex-basis:20px;" class="margin-right-def"></div>
      <div (click)="sortBy('sentReceiveDate')" [ngClass]="{'sortedColumn': sortColumn=='sentReceiveDate'}" class="flex-basis-0 flex-grow-1 margin-right-def">
          <span class="email-col-head">Date and Time</span>
          <img class="sorted-icon" *ngIf="sortColumn=='sentReceiveDate'" [src]="sortMode=='Desc'?'assets/images/descending.svg':'assets/images/ascending.svg'" />
      </div>
      <ng-container *ngIf="mailBox !='sent'">
        <div (click)="sortBy('isSharedInboxMessage')" [ngClass]="{'sortedColumn': sortColumn=='isSharedInboxMessage'}" class="flex-basis-0 flex-grow-1 margin-right-def">
          <span class="email-col-head">Shared Inbox</span>
          <img class="sorted-icon" *ngIf="sortColumn=='isSharedInboxMessage'" [src]="sortMode=='Desc'?'assets/images/descending.svg':'assets/images/ascending.svg'" />
        </div>
      </ng-container>
      <div *ngIf="mailBox != 'archive'" class="actions-div actions-column margin-right-def"></div>
      <div *ngIf="mailBox == 'archive' && this.emails?.length > 0" class="actions-column margin-right-def">
        <a class="cp-grid-btn">
          <img mat-button [matMenuTriggerFor]="additionalActionsMenu" src="assets/images/menu-dots-horizontal.svg" title="Additional Actions" />
        </a>
        <mat-menu #additionalActionsMenu="matMenu">
          <button (click)="deleteAllArchive()" mat-menu-item>Delete All</button>
        </mat-menu>
      </div>
    </div>
    <div class="emails-cont">
      <email-item (emailChanged)="onEmailChanged()" (viewEmailEvent) ="openEmail(email)" (click) ="openEmail(email)" *ngFor="let email of emails"
        [email]="email" [mailBox]="mailBox"></email-item>
    </div>
    <mat-paginator [pageSize]="pageSize"
                   [pageIndex]="pageIndex"
                   [length]="pageLength"
                   (page)="loadData($event.pageIndex)">
    </mat-paginator>
  </div>

</div>
