<div class="change-password" style="position:relative;top:50px;">
  <div class="display-flex flex-items-centered">
    <div class="logo-cont"><img src="assets/images/medcorLogo.svg" class="main-logo" /></div>
  </div>
  <ichs-collapsable-panel panelTitle="Care Instructions Files">
    <form class="collapsableBody" novalidate>
      <div class="collapsableBody margin-bottom-def">
        <div *ngFor="let triageAfterCareFile of triageAfterCareList,let i = index">
          <div class="display-flex flex-items-centered">
            <div class="flex-child flex-basis-0 flex-grow-1">
              <ichs-text-box name="name_{{i}}" [ngModel]="triageAfterCareFile.name" [title]="triageAfterCareFile.name"
                label="File Name" disabled>
              </ichs-text-box>
            </div>
            <div class="flex-col-sep"></div>
            <div class="flex-child language-field">
              @if(isMedfilesCareInstruction){
              <ichs-select [hideResetLabel]="true" name="language_{{i}}" label="Language"
                [(ngModel)]="triageAfterCareFile.language" [items]="triageAfterCareFile.languages">
              </ichs-select>
              }
              @else {
              <ichs-text-box name="language_{{i}}" [ngModel]="triageAfterCareFile.language"
                [title]="triageAfterCareFile.language" label="Language" disabled>
              </ichs-text-box>
              }
            </div>
            <div class="flex-col-sep"></div>
            <div class="flex-child align-self-end">
              <button (click)="downloadFile(triageAfterCareFile)" class="cp-btn cp-main-btn">
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ichs-collapsable-panel>

  <div>
    <app-footer></app-footer>
  </div>
</div>