<div class="secured-files">
  <div class="secured-files-tools display-flex flex-items-centered">
    <div><a (click)="routeToFolder(1)" style="cursor: pointer;">My Files</a>&nbsp;</div>
    <div class="flex-grow-1 flex-basis-0">
      <ng-container *ngFor="let item of pathName; index as i">
        / <a (click)="routeToFolder(+pathId[i])" style="cursor: pointer;">{{item}}</a>
      </ng-container>
      <ng-container *ngIf="folderId == 2">/ <a style="cursor: pointer;">Favorites</a></ng-container>
      <ng-container *ngIf="folderId == 3">/ <a style="cursor: pointer;">Received Files</a></ng-container>
      <ng-container *ngIf="folderId == 4">/ <a style="cursor: pointer;">Recorded Calls</a></ng-container>
      <ng-container *ngIf="folderId == 5">/ <a style="cursor: pointer;">Reports Folder</a></ng-container>
    </div>
  </div>
  <div>
    <hr class="main-hr" />
  </div>

  <div *ngIf="folderId == 3" class="display-flex flex-basis-0 flex-grow-1">
    <ichs-grid [grid-config]="gridConfigReceivedFilesFolder"
      class="flex-basis-0 flex-grow-1 display-flex flex-col folder-grid" ></ichs-grid>
  </div>

  <div *ngIf="folderId == 5" class="display-flex flex-basis-0 flex-grow-1">
    <ichs-grid [grid-config]="gridConfigReportsFolder"
      class="flex-basis-0 flex-grow-1 display-flex flex-col folder-grid"></ichs-grid>
  </div>

  <ng-scrollbar *ngIf="folderId != 3 && folderId != 5" class="main-content-scroll" [appearance]="'standard'" [style]="folderId == 3 || folderId == 5 ? '' : ''">
    <div class="files-attach-cont">
    <div class="user-folder" *ngIf="folderId == 1" title="Favorites" (dblclick)="routeToFolder(2)">
      <div class="folder-visuals">
        <img src="assets/images/newIcons/favorites.svg" class="folder-icon" />
        <span class="file-item-name">Favorites</span>
      </div>
    </div>

    <ng-container *ngIf="!clientUserScheduledReportsOnlyRoleInd">
      <div class="user-folder" *ngIf="folderId == 1" title="Received Files" (dblclick)="routeToFolder(3)">
        <div class="folder-visuals">
          <img src="assets/images/newIcons/receivedFiles.svg" class="folder-icon" />
          <div class="position-absolute not-seen-noti" *ngIf="newReceivedFilesCount > 0">
            {{newReceivedFilesCount}}
          </div>
          <span class="file-item-name">Received Files</span>
        </div>
      </div>

      <div class="user-folder" *ngIf="folderId == 1 && callRecordingFilesCount > 0" title="Recorded Calls"
        (dblclick)="routeToFolder(4)">
        <div class="folder-visuals">
          <img src="assets/images/newIcons/recordedCalls.svg" class="folder-icon" />
          <div class="position-absolute not-seen-noti" *ngIf="newCallRecordingFilesCount > 0">
            {{newCallRecordingFilesCount}}
          </div>
          <span class="file-item-name">Recorded Calls</span>
        </div>
      </div>
    </ng-container>

    <div class="user-folder" *ngIf="folderId == 1" title="Reports Folder" (dblclick)="routeToFolder(5)">
      <div class="folder-visuals">
        <img src="assets/images/newIcons/securedFiles.svg" class="folder-icon" />
        <div class="position-absolute not-seen-noti" *ngIf="newSecureReportFilesCount > 0">
          {{newSecureReportFilesCount}}
        </div>
        <span class="file-item-name">Reports Folder</span>
      </div>
    </div>

    <ng-container *ngIf="!clientUserScheduledReportsOnlyRoleInd || folderId == 2">
      <ng-container *ngIf="folderId != 3 && folderId != 5">
        <div class="user-folder" (dblclick)="routeToFolder(item.id)" [title]="item.fileName"
          *ngFor="let item of getFolders()">

          <div class="folder-visuals">
            <img src="assets/images/newIcons/folder.svg" class="folder-icon" />
            <div class="position-absolute not-seen-noti" *ngIf="item.notSeenCount > 0">
              {{item.notSeenCount}}
            </div>
            <span class="file-item-name">{{item.fileName}}</span>
          </div>
        </div>
      </ng-container>

      <div class="user-file"
        [title]="item.expirationDate ? item.fileName + ' (will be expired in ' + durationFromNow(item.expirationDate) + ')' : item.fileName"
        *ngFor="let item of getFiles()"
        (click)="toggleFileSelection(item)"
        [ngClass]="{'file-over-style': item.selected}">

        <div class="file-visuals">
          <img src="assets/images/newIcons/file.svg" class="file-icon" />
          <div class="position-absolute new-file-noti" *ngIf="item.isNew">
            New
          </div>
          <div class="position-absolute file-type-tag" *ngIf="isCallRecording(item)">
            Call Recording
          </div>
          <div class="position-absolute file-type-tag" *ngIf="isPhiFile(item)">
            PHI
          </div>
          <span class="file-item-name">{{item.fileName}}</span>

          <div class="position-absolute fav-file">
            <img *ngIf="item.isFavorite" class="control-image"
              [src]="item.isFavorite ? 'assets/images/star-filled.svg' : 'assets/images/star-empty.svg'"/>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  </ng-scrollbar>
</div>

<div class="flex-basis-0 flex-grow-1 text-right">
  <button mat-button class="cp-btn cp-main-btn" (click)="addAttachments()">Add Attachments</button>
  <button mat-button class="cp-btn cp-mail-btn" mat-dialog-close>Cancel</button>
</div>
