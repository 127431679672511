import { Component } from '@angular/core';

@Component({
    selector: 'construction-health-safety-info',
    templateUrl: './construction-health-safety-info.component.html',
    styleUrls: ['./construction-health-safety-info.component.css']
})
/** construction-health-safety-info component*/
export class ConstructionHealthSafetyInfoComponent {
    /** construction-health-safety-info ctor */
    constructor() {

    }
}