import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { IchsControlType, IchsGridComponentConfig } from 'src/app/controls/ichs-grid/grid-configs';
import { AppUserService, SearchViewModel, UserSearchViewModel, VoidInfoViewModel } from 'src/app/rest';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from 'src/app/services/medcor-alert.service';

@Component({
  selector: 'user-management',
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.scss',
  host: {
    class: "data-list"
  }
})
export class UserManagementComponent {

  gridConfig: IchsGridComponentConfig<UserSearchViewModel>;

  constructor(
    private appUserService: AppUserService,
    private alertService: MedcorAlertService,
  ) {
    this.gridConfig = this.defineGrid();
    this.appUserService.getRolesAsSelectionList().pipe(first()).subscribe(response => {
      this.gridConfig.headers[3].listData = response.result!;
    });
  }

  private defineGrid(): IchsGridComponentConfig<UserSearchViewModel> {
    let configs: IchsGridComponentConfig<UserSearchViewModel> = {
      primaryId: "id",
      defaultOrder: "lastName",
      title: "User Management",
      entityController: "user-management",
      hasNew: false,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchManagedUsers(filter),
      secondAction: {
        icon: "assets/images/passwordStatus.svg",
        title: "Password Status",
        function: (user: UserSearchViewModel) => {
          this.appUserService.getUserPasswordStatus(user.id)
            .pipe(first())
            .subscribe(resp => {
              this.alertService.addAlert({
                type: AlertMessageType.info,
                title: 'Password Status:',
                dismiss: AlertDismissType.auto,
                messages: [resp.result],
              });
            });
        }
      },
      voidAction: (reason: VoidInfoViewModel) => this.appUserService.voidManagedUser(reason),
      recoverAction: (id: number) => this.appUserService.recoverManagedUser(id),
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
          width: 250,
          wordWrap: true,
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "First Name",
          propertyName: "firstName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "User Role",
          propertyName: "roleName",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.StaticSelect,
          width: 202,
        },
        {
          headerTitle: "Dashboard and Report Access",
          propertyName: "hasDataAccess",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Boolean,
          width: 85,
        },
        {
          headerTitle: "Password Expired",
          propertyName: "passwordExpired",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Boolean,
          width: 115,
        },
        {
          headerTitle: "Last Login",
          propertyName: "lastLoginTime",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
          nullable: true,
          width: 120,
        }
      ]
    }
    return configs;
  }
}
