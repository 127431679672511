
/**
 * for more details about 'ngx-image-cropper', please refer to Docs at: https://www.npmjs.com/package/ngx-image-cropper
 * 
 * cropperDialogTitle?: string; // title to be shown when cropper dialog opened (dialog title)
 * maintainAspectRatio?: boolean = true;  // Keep width and height of cropped image equal according to the aspectRatio
 * aspectRatio?: number = 1 / 1;  // The width / height ratio (e.g. 1 / 1 for a square, 4 / 3, 16 / 9 ...)
 * resizeToWidth?: number = 150;  // Cropped image will be resized to this width (in px)
 * onlyScaleDown?: boolean = true;  // When the resizeToWidth is set, enabling this option will make sure smaller images are not scaled up
 * roundCropper?: boolean = true; // Set this to true for a round cropper. Resulting image will still be square, use border-radius: 100% on resulting image to show it as round.
 * outputType?: string = "both";  // Output type ('base64', 'file' or 'both'). Converting the image to a Blob can be quite a heavy operation. With this option, you could choose to only get the base64 which will improve the speed of cropping significantly
 * 
 */

export class IchsImageCropperConfig {
  cropperDialogTitle?: string;
  maintainAspectRatio?: boolean = true;
  aspectRatio?: number = 1 / 1;
  resizeToWidth?: number = 150;
  onlyScaleDown?: boolean = true;
  roundCropper?: boolean = true;
  outputType?: string = "blob";

  constructor(init?: Partial<IchsImageCropperConfig>) {
    Object.assign(this, init);
  }
}