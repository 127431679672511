import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { GroupSelectionItemViewModel, SelectionItemViewModel } from '../../../rest/index';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../../services/medcor-alert.service';
import { NgForm, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { ValidationMessages } from '../../../app.general.constants';

@Component({
    selector: 'ichs-input-dialog',
    templateUrl: './ichs-input-dialog.component.html',
    styleUrls: ['./ichs-input-dialog.component.css']
})
export class IchsInputDialogComponent implements OnInit {

    inputDialogConfig: InputDialogConfig = <InputDialogConfig>{};
    controls: IchsControl[];
    allowClose: (controls: IchsControl[]) => boolean | Observable<boolean>;

    selectType: string = IchsDialogControlType.IchsSelect;
    textType: string = IchsDialogControlType.IchsTextBox;
    datePickerType: string = IchsDialogControlType.IchsDatePicker;
    imageType: string = IchsDialogControlType.IchsImage;
    textArea: string = IchsDialogControlType.ichsTextArea;

    @ViewChild('form') ngForm: NgForm;

    constructor(
        private dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]>,
        private alertService: MedcorAlertService,
        @Inject(MAT_DIALOG_DATA) private data: InputDialogConfig,
    ) {
        this.inputDialogConfig = data;
        this.controls = this.inputDialogConfig.controls;
        this.allowClose = this.inputDialogConfig.allowClose;
    }

    ngOnInit() {
    }

    async submit() {
        this.validateAllFormFields(this.ngForm.form); // validate the form and show errors

        if (this.ngForm.invalid) {  // check if form is valid 
            this.alertService.addAlert({
                type: AlertMessageType.warning,
                title: 'Warning!',
                dismiss: AlertDismissType.controlled,
                messages: [ValidationMessages.INVALID_FORM]
            });
            return;
        }

        if (this.allowClose) {
            let closeAllowed = this.allowClose(this.controls);

            if (closeAllowed instanceof Observable) {
                let closeAllowedResult = await closeAllowed.pipe(first()).toPromise();
                if (!closeAllowedResult) {
                    return;
                }
            }
            else {
                if (!closeAllowed) {
                    return;
                }
            }
        }

        this.dialogRef.close(this.controls);
    }

    /*
    to validate the form and show errors 
    */
    private validateAllFormFields(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched();
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }
}

export class InputDialogConfig {
    title: string;
    message?: string;
    okText?: string = "OK";
    cancelText?: string = "Cancel";
    controls: IchsControl[];
    hasActions?: boolean = true;
    hasTitle?: boolean = true;
    hasXButton?: boolean = false;
    allowClose: (controls: IchsControl[]) => boolean | Observable<boolean>;
    constructor(init?: Partial<InputDialogConfig>) {
        Object.assign(this, init);
    }
}

export class IchsControl {
    type: IchsDialogControlType;
    label: string;
    value?: any;
    required?: boolean = false;
    disabled?: boolean = false;
    constructor(type: IchsDialogControlType) {
        this.type = type;
    }
}

export class IchsTextBox extends IchsControl {
    inputMask?: string;
    placeholder?: string;
    buttonIcon?: string;
    showButton?: boolean = false;
    isEmail?: boolean = false;
    maxLength?: number = Number.MAX_SAFE_INTEGER;
    constructor(init?: Partial<IchsTextBox>) {
        super(IchsDialogControlType.IchsTextBox);
        Object.assign(this, init);
    }
}

export class IchsSelect extends IchsControl {
    placeholder?: string = "";
    useGroups: boolean;
    listId?: number;
    resetLabel?: string = "None";
    hideResetLabel?: boolean = false;
    multiple?: boolean = false;
    groupItems?: GroupSelectionItemViewModel[];
    items?: SelectionItemViewModel[];
    constructor(init?: Partial<IchsSelect>) {
        super(IchsDialogControlType.IchsSelect);
        Object.assign(this, init);
    }
}

export class IchsDatePicker extends IchsControl {
    startDate?: Date;
    endDate?: Date;
    datePlaceholder?: string = "mm/dd/yyyy";

    constructor(init?: Partial<IchsDatePicker>) {
        super(IchsDialogControlType.IchsDatePicker);
        Object.assign(this, init);
    }

}

export class IchsImage extends IchsControl {
    src?: string;
    hasCloseButton?: boolean = false;
    constructor(init?: Partial<IchsImage>) {
        super(IchsDialogControlType.IchsImage);
        Object.assign(this, init);
    }
}

export class IchsTextArea extends IchsControl {

    placeholder?: string = "";
    rows?: number = 3;
    maxLength?: number = Number.MAX_SAFE_INTEGER;
    constructor(init?: Partial<IchsTextArea>) {
        super(IchsDialogControlType.ichsTextArea);
        Object.assign(this, init);
    }
}

export type IchsDialogControlType = 'Text Box' | 'Select' | 'Date Picker' | 'Image' | 'TextArea';
export const IchsDialogControlType = {
    IchsTextBox: 'Text Box' as IchsDialogControlType,
    IchsSelect: 'Select' as IchsDialogControlType,
    IchsDatePicker: 'Date Picker' as IchsDialogControlType,
    IchsImage: 'Image' as IchsDialogControlType,
    ichsTextArea: 'TextArea' as IchsDialogControlType
}
