import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { FileRequestService } from '../../rest';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from '../../services/medcor-alert.service';
import { AppSettings, ValidationMessages } from '../../app.general.constants';

@Component({
  selector: 'external-file-request',
  templateUrl: './external-file-request.component.html',
  styleUrls: ['./external-file-request.component.css']
})
export class ExternalFileRequestComponent implements OnInit {

  filePinCode: string = "";
  requestId: string = "";
  pinCodeValidated: boolean = false;
  requestTitle: string = "";
  email: string = "";
  files: File[] = [];
  filesUploaded: boolean = false;

  constructor(
    private fileRequestService: FileRequestService,
    private alertService: MedcorAlertService, ) {
  }

  ngOnInit() {
    let pathParts: string[] = location.pathname.split("/");
    if (pathParts.length < 3) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Invalid File Request Link!"]
      });
      return;
    }

    this.requestId = pathParts[pathParts.length - 1];
    this.email = location.search.replace("?email=", "");
  }

  validatePinCode() {
    if (this.filePinCode.trim().length == 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please provide a valid Pin Code!"]
      });
      return;
    }
    this.fileRequestService.validatePinCode({ encryptedId: this.requestId, pinCode: this.filePinCode.trim(), email: this.email.trim() }).pipe(first()).subscribe(result => {
      if (result && result.statusCode == 200) {
        this.requestTitle = result.result!;
        this.pinCodeValidated = true;
      }
    });
  }

  onFileSelected(event: any) {
    var files = event.srcElement.files as File[];

    Array.prototype.forEach.call(files, file => {
      if (file.size > AppSettings.MAX_FILE_SIZE) {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: [ValidationMessages.FILE_SIZE_LIMIT_EXCEEDED]
        });
      }
      else {
        this.files.push(file);
      }
    });
  }

  uploadFiles() {
    if (this.files.length == 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please select a file!"]
      });
      return;
    }

    let sub = this.fileRequestService.uploadRequestedFile(this.requestId, this.filePinCode, this.email, this.files, 'events', true)
      .subscribe(
        event => {
          if (event.type == HttpEventType.Response) {
            this.alertService.addAlert({
              type: AlertMessageType.success,
              title: 'Success!',
              dismiss: AlertDismissType.controlled,
              messages: ["Files uploaded successfully!"]
            });
          } else if (event.type == HttpEventType.UploadProgress) {

          } else if (event.type == HttpEventType.DownloadProgress) {

          }
        },
        () => {
          sub.unsubscribe();
        },
        () => {
          sub.unsubscribe();
          this.filesUploaded = true;
        });
  }

  removeFile(fileIdx: number) {
    this.files.splice(fileIdx, 1);
  }
}
