import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ReportItemViewModel } from '../../rest/index';
import { IchsImage, InputDialogConfig, IchsInputDialogComponent } from '../../controls/ichs-dialog/ichs-input-dialog/ichs-input-dialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ReportSchedulerConfig, ReportingSchedulerComponent } from '../reporting-scheduler/reporting-scheduler.component';
import { first } from 'rxjs/operators';
import { MedcorUserInfoService } from '../../services/medcor-user-info.service';
import { APISettings } from '../../app.general.constants';
import { ReportingService } from '../../rest/index'
import { HttpErrorResponse } from '@angular/common/http';
import { SharedFunctionsService } from '../../services/shared-functions.service';

@Component({
  selector: 'dialog-report-item-card',
  templateUrl: './dialog-report-item-card.component.html',
  styleUrls: ['./dialog-report-item-card.component.css']
})
export class DialogReportItemCardComponent implements OnInit {

  @Input() public reportViewModel: ReportItemViewModel;
  @Output() public onNewScheduleCreated: EventEmitter<boolean> = new EventEmitter<boolean>();
  snapshotImage: string;

  constructor(
    private dialog: MatDialog,
    public medcorUserInfoService: MedcorUserInfoService,
    private _reportingService: ReportingService,
    private _sharedFunctionsService: SharedFunctionsService
  ) {

  }

  ngOnInit(): void {
    if (this.reportViewModel.snapshotId) {
      this.snapshotImage = APISettings.API_FILES_PATH + '/' + this.reportViewModel.snapshotId;
    }
  }

  showSnapshot() {
    let dialogConfig = new InputDialogConfig({
      controls: [
        new IchsImage({
          label: "Snapshot",
          src: this.snapshotImage,
        })
      ],
      hasActions: false,
      hasTitle: false,
      hasXButton: true,
    });

    this.dialog.open(IchsInputDialogComponent, {
      data: dialogConfig
    });
  }

  scheduleReport() {
    this._reportingService.reportingStatus(this.reportViewModel.lineOfBusiness).pipe(first()).subscribe(() => {

      let dialogConfig: MatDialogConfig<ReportSchedulerConfig> = {
        data: {
          report: this.reportViewModel
        },
        width: '800px',
        disableClose: true
      };

      let dialogRef = this.dialog.open(ReportingSchedulerComponent, dialogConfig);

      dialogRef.afterClosed().pipe(first()).subscribe(result => {
        if (result) {
          this.onNewScheduleCreated.emit(true);
        }
      });
    },
      (err: HttpErrorResponse) => {
        this._sharedFunctionsService.showReportStatusDialog(err);
      });
  }
}
