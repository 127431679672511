import { Component, OnInit, ViewChild } from '@angular/core';
import { IchsCommandToolbarDelegate, IchsCommandToolbarModel } from '../../controls/ichs-command-toolbar/ichs-command-toolbar.component';
import { TriagePortalUserViewModel, SelectionItemViewModel, AppLogSearchModel, AppUserService, GroupAppUserAssociationService, PublicFileService, GroupService, UserContactService, MedcorResponse, TriagePortalUserService, SearchViewModel, TriagePortalUserPinCodeSearchModel } from '../../rest';
import { IchsControlType, IchsGridComponentConfig, IchsGridActionsConfig } from '../../controls/ichs-grid/grid-configs';
import { AppGenericLists } from '../../app.general.lists';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MedcorAlertService } from '../../services/medcor-alert.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MedcorUserInfoService } from '../../services/medcor-user-info.service';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { IchsComponentDialogComponent } from '../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { AppLogDialogComponent } from '../../app-log/app-log-dialog/app-log-dialog.component';
import { ShowTriageUserPinCodeComponent } from '../show-triage-user-pin-code/show-triage-user-pin-code.component';

@Component({
  selector: 'app-triage-portal-user',
  templateUrl: './triage-portal-user.component.html',
  styleUrls: ['./triage-portal-user.component.css']
})
export class TriagePortalUserComponent implements OnInit, IchsCommandToolbarDelegate<TriagePortalUserViewModel> {
  confirmPassword: string = "";
  @ViewChild('form', { static: true }) ngForm: NgForm;
  model: IchsCommandToolbarModel<TriagePortalUserViewModel>;
  get appuserId(): number { return this.model.objModelId }
  public statusList: SelectionItemViewModel[] = AppGenericLists.UserStatus;

  appLogGridConfig: IchsGridComponentConfig<AppLogSearchModel>;
  userPinCodesGridConfig: IchsGridComponentConfig<TriagePortalUserPinCodeSearchModel>;

  logsType: string = "All";

  readonly logsTypeSelectionListItems: SelectionItemViewModel[] =
    [
      { selectionText: "All", selectionValue: "All" },
      { selectionText: "Exceptions Only", selectionValue: "Exceptions Only" },
      { selectionText: "Non-Exceptions Only", selectionValue: "Non-Exceptions Only" },
    ];

  /** Appuser constructor*/
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private alertService: MedcorAlertService,
    private dialog: MatDialog,
    private triagePortalUserService: TriagePortalUserService,
  ) {
    this.model = new IchsCommandToolbarModel<TriagePortalUserViewModel>(location, router, "/triage-portal-user", "Client Portal User", this, dialog, alertService);
    this.model.hasVoidRecover = false;
  }

  /** Appuser OnInit*/
  ngOnInit() {
    this.model.init(this.route);
    this.appLogGridConfig = this.defineAppLogGridConfig();
    this.userPinCodesGridConfig = this.defineUserPinCodesGridConfig();
  }

  getLoadObjModelObservable(id: number) {
    return this.triagePortalUserService.getById(id);
  }

  getSaveObservable(objModel: TriagePortalUserViewModel): Observable<MedcorResponse<TriagePortalUserViewModel>> {
    return this.triagePortalUserService.updateUser(objModel);
  }

  getEmptyObject(): TriagePortalUserViewModel {
    var emptyObj: TriagePortalUserViewModel = {
      email: "",
      firstName: "",
      lastName: "",
      active: true,
      status: "Active",
      clientName: "",
    };

    return emptyObj;
  }

  statusChanged(event: any) {
    this.model.objModel.active = event.value == "Active";
  }

  // implementing the canDeactivate function.
  canDeactivate() {
    return this.model.confirmCanDeactivate();
  }

  private defineAppLogGridConfig(): IchsGridComponentConfig<AppLogSearchModel> {
    let configs: IchsGridComponentConfig<AppLogSearchModel> = {
      primaryId: "id",
      defaultOrder: "logTime",
      defaultOrderType: "desc",
      title: null,
      entityController: null,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => {
        if (this.logsType == "Exceptions Only") {
          filter.filterExpression = filter.filterExpression.length > 0 ?
            "isException == true && " + filter.filterExpression : "isException == true";
        }
        else if (this.logsType == "Non-Exceptions Only") {
          filter.filterExpression = filter.filterExpression.length > 0 ?
            "isException == false && " + filter.filterExpression : "isException == false";
        }

        return this.triagePortalUserService.searchUserAppLog(this.appuserId, filter)
      },
      beforeResetFunction: () => {
        this.logsType = "All";
      },
      detailAction: new IchsGridActionsConfig({
        title: "Show Log Details",
        function: (logObj: AppLogSearchModel) => {
          this.dialog.open(IchsComponentDialogComponent, {
            data: {
              title: "Log Details",
              component: AppLogDialogComponent,
              extraData: logObj,
            },
            width: "1000px"
          });
        },
      }),
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: true,
      hasVoid: false,
      headers: [
        {
          headerTitle: "Log ID",
          propertyName: "logId",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
          width: 300,
        },
        {
          headerTitle: "Log Time",
          propertyName: "logTime",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
          dateFormat: "MM/dd/yyyy h:mm:ss a",
          width: 180,
        },
        {
          headerTitle: "User Email",
          propertyName: "userEmail",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Impersonator Email",
          propertyName: "impersonatorUserEmail",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Level",
          propertyName: "level",
          searchable: true,
          sortable: true,
          filterVal: "Info",  // default value
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.LogLevels,
          width: 80,
        },
        {
          headerTitle: "Action",
          propertyName: "action",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Entity Name",
          propertyName: "entityName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }

  private defineUserPinCodesGridConfig(): IchsGridComponentConfig<TriagePortalUserPinCodeSearchModel> {
    let configs: IchsGridComponentConfig<TriagePortalUserPinCodeSearchModel> = {
      primaryId: "id",
      defaultOrder: "expirationDate",
      defaultOrderType: "desc",
      title: null,
      entityController: null,
      entityDataSource: (filter: SearchViewModel) => this.triagePortalUserService.searchUserPinCodes(this.appuserId, filter),
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: true,
      hasVoid: false,
      detailAction: new IchsGridActionsConfig({
        title: "Show User PIN Code",
        function: (obj: TriagePortalUserPinCodeSearchModel) => {
          this.dialog.open(IchsComponentDialogComponent, {
            data: {
              component: ShowTriageUserPinCodeComponent,
              title: "Show User PIN Code",
              hideCloseButton: true,
              extraData: obj.id,
            },
            width: "500px"
          });
        },
      }),
      headers: [
        {
          headerTitle: "Status",
          propertyName: "status",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.TriagePortalUserPinCodeStatus,
        },
        {
          headerTitle: "Date Generated",
          propertyName: "dateGenerated",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
          dateFormat: "MM/dd/yyyy",
        },
        {
          headerTitle: "Expiration Date",
          propertyName: "expirationDate",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
          dateFormat: "MM/dd/yyyy",
        },
      ],
    }
    return configs;
  }
}
