/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface SearchViewModel { 
    /**
     * Linq Expression Ex.: userName == \\\"Medcor\\\" and  Age > 0
     */
    filterExpression?: string;
    /**
     * Linq Expression And Direction Ex. userName, userName Asc, userName Desc
     */
    pageIndex?: number;
    /**
     * 0 is not Allowed , Null or greater than 0
     */
    pageSize?: number;
    /**
     * 0 is not allowed , Null or greated than 0
     */
    sortExpressions?: Array<string>;
}
