/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * class that represents a reference to a secured file attached to a user
 */
export interface UserSecuredFileViewModel { 
    fileName?: string;
    /**
     * the type of the file or Folder if it is a Main folder attribute or a sub folder attribute
     */
    fileType?: string;
    type?: string;
    isFavorite?: boolean;
    isReceivedFile?: boolean;
    isCallRecording?: boolean;
    isSecureReport?: boolean;
    isSharedInbox?: boolean;
    notSeenCount?: number;
    source?: string;
    seenDate?: Date;
    expirationDate?: Date;
    isNew?: boolean;
    path?: string;
    pathUnique?: string;
    folderId?: number;
    clientReportTitle?: string;
    timeStampCreated?: Date;
    selected?: boolean;
    id?: number;
    voidId?: number;
    version?: string;
}
