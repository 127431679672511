import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ElementRef } from '@angular/core';
import { NgForm, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';
import { SecuredEmailsService, EmailViewModel, SearchViewModel, UserSecuredFileViewModel, EmailAttachmentViewModel, MedcorResponse, ExternalEmailPinViewModel, UserSecuredFileService, LoggedUserViewModel, SendExternalEmailViewModel, AppUserService } from '../../rest/index';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ActivatedRoute, Router } from '@angular/router';
import { Location, formatDate } from '@angular/common'
import { Observable } from 'rxjs';
import { of, Subject } from 'rxjs';
import { InputDialogConfig, IchsControl, IchsTextBox, IchsInputDialogComponent } from '../../controls/ichs-dialog/ichs-input-dialog/ichs-input-dialog.component';
import { IchsDialogComponent } from '../../controls/ichs-dialog/ichs-dialog.component';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';
import { IchsGridComponentConfig, IchsControlType } from '../../controls/ichs-grid/grid-configs';
import { MedcorUserInfoService } from '../../services/medcor-user-info.service';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { SharedFunctionsService } from '../../services/shared-functions.service';
import { APISettings, AppSettings, ValidationMessages } from 'src/app/app.general.constants';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';
import { ExternalReceiverViewModel } from '../../rest/model/externalReceiverViewModel';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { IchsComponentDialogComponent } from 'src/app/controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { SecuredFileAttachmentComponent } from '../secured-file-attachment/secured-file-attachment.component';

@Component({
  selector: 'email-composer',
  templateUrl: './email-composer.component.html',
  styleUrls: ['./email-composer.component.css']
})
export class EmailComposerComponent implements OnInit, AfterViewInit, OnDestroy {

  emailBody: EmailViewModel = { title: "", body: "", emailId: 0, attachments: [], to: [], sender: {}, notifyWhenOpen: false };
  @ViewChild('form') ngForm: NgForm;
  @ViewChild('fileInput') uploaderInputRef: ElementRef;
  @ViewChild('emailInput') emailInputRef: ElementRef;
  attachmentsGridConfig: IchsGridComponentConfig<UserSecuredFileViewModel>;
  uploader: FileUploader;
  fileOver: boolean = false;
  sendAfterUpload: boolean = false;
  enteredEmails: string = "";
  showExternalPanel: boolean = false;
  isForwardMessage: boolean = false;

  externalEmails: string[] = [];
  externalEmailsWithInvitationValues: ExternalReceiverViewModel[] = [];
  pins: ExternalEmailPinViewModel[] = [];

  dialogConfig: InputDialogConfig;
  controls: IchsControl[] = [];
  sub: Subscription;

  userEmails: string[] = [];
  emailModelChanged: Subject<string> = new Subject<string>();

  isSending: boolean = false;
  emailSent: boolean = false;

  currentUserName: string;
  isActiveDirectoryUser: boolean;

  mailBox: string;

  contactsIsHiddenOriginalValue: boolean;

  get loggedInUserObs(): Observable<LoggedUserViewModel> { return this.medcorUserInfoService.loggedInUserObs }

  constructor(
    private alertService: MedcorAlertService,
    private emailService: SecuredEmailsService,
    private userFiles: UserSecuredFileService,
    private appuserService: AppUserService,
    private medcorUserInfoService: MedcorUserInfoService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private generalVariables: GeneralVariablesService,
    private sharedFunctions: SharedFunctionsService,
    private sanitizer: DomSanitizer,
  ) {
    this.loggedInUserObs.pipe(first()).subscribe(obj => {
      this.currentUserName = obj.fullUserName;
      this.isActiveDirectoryUser = obj.isActiveDirectoryUser;
    });

    var URL: string = APISettings.API_BASE_PATH + "/api/SecuredEmails/AttachFile";
    this.uploader = new FileUploader({
      url: URL, authTokenHeader: "Authorization", isHTML5: true, itemAlias: "FileData", autoUpload: false, maxFileSize: AppSettings.MAX_FILE_SIZE,
    });

    this.uploader.onBuildItemForm = (item, form) => {
      form.append("EmailId", this.emailBody.emailId);
    }

    this.uploader.onAfterAddingFile = ((fileItem: FileItem): any => {
      if (!this.sharedFunctions.isSupportedFileType(fileItem._file)) {
        this.uploader.removeFromQueue(fileItem);

        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.auto,
          messages: [ValidationMessages.INVALID_FILETYPE]
        });
      }
    });

    this.uploader.onCompleteItem = (item, response, status, headers) => {

      try {
        let resp: MedcorResponse<any> = JSON.parse(response);

        if (resp.statusCode == 200) {
          let emailAttachment: EmailAttachmentViewModel = resp.result as EmailAttachmentViewModel;
          this.emailBody.attachments!.push(emailAttachment);
        }
        else {
          this.alertService.handleMedcorResponse(resp);
        }
      } catch { }
    }

    this.uploader.onCompleteAll = () => {
      this.uploader.clearQueue();
      if (this.sendAfterUpload) {
        this.sendEmail();
      }
    }

    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      if (filter.name == "fileSize") {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: [ValidationMessages.FILE_SIZE_LIMIT_EXCEEDED]
        });
      }
    }
  }

  ngOnInit() {
    setTimeout(() => {
      this.contactsIsHiddenOriginalValue = this.generalVariables.appViewModel.contactsIsHidden;
      this.generalVariables.appViewModel.contactsIsHidden = false;
    }, 0);

    if (this.route.snapshot.routeConfig) {
      this.isForwardMessage = this.route.snapshot.routeConfig.path!.startsWith("forward");
    }

    // add username as signature to the bottom of the email message
    this.emailBody.body = "<br/><br/>" + this.currentUserName;

    let id = this.route.snapshot.paramMap.get('id');

    this.sub = this.route.queryParams.subscribe(params => {
      this.mailBox = params['mailBox'];

      if (id) {
        let intId = parseInt(id);
        if (this.isForwardMessage) {
          this.loadForwardEmail(intId);
        }
        else {  // if reply
          this.loadReplyEmail(intId);
        }
      }

      // Defaults to 0 if no query param provided.
      let recipient = params['recipient'];
      if (recipient) {
        let email = JSON.parse(recipient).method as string;
        if (this.emailBody.to.indexOf(email) == -1) {
          this.emailBody.to!.push(email);
        }
      }
    });

    let folderName: IchsTextBox = new IchsTextBox({
      label: "User Mobile",
      placeholder: "Enter a valid Mobile Number",
      required: true,
      inputMask: "phone"
    });

    this.controls.push(folderName);
    this.dialogConfig = new InputDialogConfig({
      title: 'Enter User Mobile',
      controls: this.controls
    });

    this.emailModelChanged
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(value => value && value.length > 2 ? this.appuserService.searchAdUserEmails(value) : of(null)),
      )
      .subscribe(res => this.userEmails = res ? res.result : []);
  }

  ngAfterViewInit() {
    Promise.resolve().then(() => {
      if (this.ngForm.form.controls.richBody) {
        this.ngForm.form.controls.richBody.markAsPristine();
      }
    });
  }

  ngOnDestroy() {
    // if contacts are not hidden, i.e. user didn't change their status, return their status to the original status
    if (!this.generalVariables.appViewModel.contactsIsHidden) {
      this.generalVariables.appViewModel.contactsIsHidden = this.contactsIsHiddenOriginalValue;
    }

    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  allowDrop(ev) {
    ev.preventDefault();  //prevent the default action of the browser when drag over.
  }

  drop(ev: DragEvent) {
    ev.preventDefault();

    let strData = ev.dataTransfer.getData("text");
    let data = JSON.parse(strData);

    if (data && data.type == "addContacts") {
      let emails = data.emails as string[];
      emails.forEach(email => this.addToContact(email));
    }
  }

  loadReplyEmail(emailId: number) {
    this.emailService.viewInboxEmail(emailId.toString(), false).pipe(first()).subscribe(res => {
      let email = res.result!;
      this.emailBody.to!.push(email.sender.method);

      let isReplyAll: boolean = this.route.snapshot.queryParams['isReplyAll'];
      if (isReplyAll) {
        let recipientEmails = email.recipientsList
          .filter(rec => !rec.isExternal && rec.email != email.sender.method)
          .map(rec => rec.email);

        this.emailBody.to!.push(...recipientEmails);

        this.externalEmails = email.recipientsList
          .filter(rec => rec.isExternal)
          .map(rec => rec.email);
      }

      this.emailBody.title = email.title.toLowerCase().startsWith('re:') ? email.title : "Re: " + email.title;
      this.emailBody.body += this.getEmailBodyWithHeaders(email);
    });
  }

  loadForwardEmail(emailId: number) {
    let obv: Observable<MedcorResponse<EmailViewModel>> = null;

    if (this.mailBox == 'inbox') {
      obv = this.emailService.viewInboxEmail(emailId.toString(), false);
    }
    else {  // if mailBox = 'sent'
      obv = this.emailService.viewSentEmail(emailId);
    }

    obv.pipe(first()).subscribe(
      res => {
        let email = res.result!;

        // send id of the email to be forwarded
        this.emailBody.emailId = emailId;

        if (!email.forwardedEmailId) {
          this.emailBody.forwardedEmailId = emailId;
        }
        else {
          this.emailBody.forwardedEmailId = email.forwardedEmailId;
        }

        this.emailBody.title = email.title.toLowerCase().startsWith('fw:') ? email.title : "FW: " + email.title;
        this.emailBody.body += this.getEmailBodyWithHeaders(email);
      },
      undefined,
      () => {
        this.saveDraft().pipe(first()).subscribe();
      });
  }

  addToContact(obj: string) {
    if (this.emailBody.to == undefined) {
      return;
    }
    if (this.emailBody.to.indexOf(obj) == -1) {
      this.emailBody.to!.push(obj);
    }
  }

  validateNewEmail() {
    this.enteredEmails
      .split(";")
      .forEach(email => {
        if (email.trim().length == 0 || !this.sharedFunctions.isValidEmail(email)) {
          this.enteredEmails = ""
        } else {
          this.addToContact(email);
          this.enteredEmails = "";
        }
      });
  }

  onOptionSelected(event: MatAutocompleteSelectedEvent) {
    this.emailModelChanged.next("");
    this.enteredEmails = "";
    this.emailInputRef.nativeElement.value = "";
    this.userEmails = [];
    let email = event.option.value;
    this.addToContact(email);
  }

  canDeactivate() {
    if (this.emailSent && !this.showExternalPanel) {
      return true;
    }

    if (
      (this.showExternalPanel && this.pins.length == 0) ||
      (!this.showExternalPanel && (this.ngForm.dirty || this.emailBody.attachments!.length > 0 || this.emailBody.to!.length > 0))
    ) {
      let dialogRef: MatDialogRef<IchsDialogComponent, boolean> = this.dialog.open<IchsDialogComponent, any>(IchsDialogComponent, {
        width: '450px',
        data: { title: "Discard Changes ", message: "You will lose all changes in this page." }
      });
      return dialogRef.afterClosed().pipe(first(), map(result => {
        if (result && result == true) {
          return result;
        } else {
          return false;
        }
      }));
    }
    return true;
  }


  sendEmail(checkBody: boolean = true): void {

    this.validateAllFormFields(this.ngForm.form); // validate the form and show errors
    if (!this.ngForm.valid) {// check if form is valid
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    if (!this.emailBody.to || this.emailBody.to.length == 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please select recipients!"]
      });
      return;
    }

    if (this.uploader.isUploading) {
      this.sendAfterUpload = true;
      this.alertService.addAlert({
        dismiss: AlertDismissType.auto,
        messages: ["Waiting to finish uploading"],
        title: "Could not send email",
        type: AlertMessageType.warning,
      });
      return;
    }

    if (checkBody && (!this.emailBody.body || this.emailBody.body.replace('<p>', '').replace('</p>', '').trim().length == 0)) {
      let dialogRef = this.dialog.open(IchsDialogComponent, {
        width: '450px',
        data: { title: "Warning", message: "Send this message without a body?" }
      });

      let sub = dialogRef.afterClosed().subscribe(result => {
        sub.unsubscribe();
        if (result) {
          this.sendEmail(false);
        }
      });

      return;
    }

    this.isSending = true;
    this.emailService.sendEmail(this.emailBody).pipe(first()).subscribe(result => {
      if (result.statusCode == 200 && result.result!.emails!.length == 0) {
        this.emailSent = true;
        this.router.navigate(['/secureMessaging/inbox']);
      } else {
        let externalEmails = result.result!.emails
        this.externalEmailsWithInvitationValues = externalEmails.map(_email => <ExternalReceiverViewModel>{
          email: _email,
          inviteForPermanentAccount: false,
        });

        this.emailBody.emailId = result.result!.emailId;
        this.showExternalPanel = true;
      }

    }, () => { this.isSending = false; }, () => { this.isSending = false; });

  }

  cancelSending() {
    this.router.navigate(['/secureMessaging/inbox']);
  }

  forwardEmail() {
    if (!this.emailBody.to || this.emailBody.to.length == 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please select recipients!"]
      });
      return;
    }

    this.emailService.forwardEmail(this.emailBody).pipe(first()).subscribe(result => {
      if (result.statusCode == 200 && result.result!.emails!.length == 0) {
        this.emailSent = true;
        this.router.navigate(['/secureMessaging/inbox']);
      } else {
        let externalEmails = result.result!.emails
        this.externalEmailsWithInvitationValues = externalEmails.map(_email => <ExternalReceiverViewModel>{
          email: _email,
          inviteForPermanentAccount: false,
        });

        this.emailBody.emailId = result.result!.emailId;
        this.showExternalPanel = true;
      }
    });
  }

  dropFiles() {
    if (!this.emailBody.emailId || this.emailBody.emailId <= 0) {
      this.saveDraft().subscribe(result => {
        if (result) {
          this.uploader.uploadAll();
        }
      });
    } else {
      this.uploader.uploadAll();
    }
  }

  uploadSelectedFiles() {
    // this method is used temporarily to prevent the code from being executed twice,
    // due to a bug in "ng2-file-upload" library that occurs on IE only
    // check github issue: https://github.com/valor-software/ng2-file-upload/issues/1069

    if (this.uploaderInputRef.nativeElement.files.length > 0) {
      if (!this.emailBody.emailId || this.emailBody.emailId <= 0) {
        this.saveDraft().subscribe(result => {
          if (result) {
            this.uploader.uploadAll();
          }
        });
      } else {
        this.uploader.uploadAll();
      }
    }
  }

  removeFile(file: EmailAttachmentViewModel) {
    let index = this.emailBody.attachments!.indexOf(file);
    if (index > -1) {
      this.emailService.deleteEmailAttachment(file.emailId!, file.id!, file.version!).pipe(first()).subscribe(() => {
        this.emailBody.attachments!.splice(index, 1);
      });
    }
  }

  saveDraft(): Observable<boolean> {
    let completed: Subject<boolean> = new Subject();

    let sub = this.emailService.saveDraftEmail(this.emailBody).subscribe(result => {

      let email = result.result!;
      this.emailBody.emailId = email.emailId;
      if (email.forwardedEmailId) {
        this.emailBody.attachments = email.attachments;
      }

      completed.next(true);

    }, () => { completed.next(false); }, () => { sub.unsubscribe() });

    return completed.asObservable();
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  public showUserFiles() {
    let dialogRef: MatDialogRef<IchsComponentDialogComponent, UserSecuredFileViewModel[]> = this.dialog.open(IchsComponentDialogComponent, {
      disableClose: true,
      width: '1000px',

      data: { component: SecuredFileAttachmentComponent, hideCloseButton: true },
      autoFocus: false,
    });

    dialogRef.afterClosed().pipe(first()).subscribe((result: UserSecuredFileViewModel[]) => {
      if (result) {
        if (!this.emailBody.emailId || this.emailBody.emailId <= 0) {
          this.saveDraft().pipe(first()).subscribe(isTrue => {
            if (isTrue) {
              this.uploadSecuredFiles(result);
            }
          });
        }
        else {
          this.uploadSecuredFiles(result);
        }
      }
    });
  }

  removeExternalEmail(extEmail: ExternalReceiverViewModel) {
    if (this.externalEmailsWithInvitationValues.length == 1) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["You must keep at least one recipient!"]
      });
      return;
    }

    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Delete External Email", message: "Are you sure you want to delete this email?" }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        let index = this.externalEmailsWithInvitationValues.indexOf(extEmail);
        this.externalEmailsWithInvitationValues.splice(index, 1);
      }
    });
  }

  sendExternalEmails() {
    if (this.externalEmailsWithInvitationValues.length == 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please select external emails!"]
      });
      return;
    }

    if (this.emailBody.emailId == null || this.emailBody.emailId == 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please try sending the email through the system before trying the external service."]
      });
      return;
    }

    let objPost: SendExternalEmailViewModel = {
      emailId: this.emailBody.emailId,
      notifyWhenOpen: this.emailBody.notifyWhenOpen!,
      recipients: this.externalEmailsWithInvitationValues
    };

    this.emailService.sendExternalSecuredEmail(objPost).pipe(first()).subscribe(result => {
      if (result) {
        this.pins = result.result!;
      }
    });
  }

  cancel() {
    this.router.navigate(['secureMessaging', 'inbox']);
  }

  copySucceeded() {
    this.alertService.addAlert({
      type: AlertMessageType.success,
      title: 'Warning!',
      dismiss: AlertDismissType.auto,
      messages: ["PIN coppied to Clipboard"]
    });
  }

  sendSMS(email: ExternalEmailPinViewModel) {
    this.dialogConfig.controls![0].value = "";//reset the old name
    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: this.dialogConfig,
      width: "600px",
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {

      if (result && result.length > 0) {
        let mobile: string = result[0].value!;
        mobile = mobile.replace("(", "").replace(")", "").replace(" ", "").replace("-", "");
        var reg = RegExp("^([0-9]{3}|[0-9]{3})[0-9]{3}[0-9]{4}$");
        if (!reg.test(mobile)) {
          this.alertService.addAlert({
            type: AlertMessageType.warning,
            title: 'Warning!',
            dismiss: AlertDismissType.auto,
            messages: ["Please enter a valid Mobile!"]
          });
          return;
        }
        email.mobileNumber = "+1" + mobile;
        this.sendSMSWithMobile(email);
      }
    });
  }

  private getEmailBodyWithHeaders(email: EmailViewModel) {
    var bodyWithHeaders =
      "<hr/>" +
      "<b>From:</b> " + email.sender.name + "<br/>" +
      "<b>Sent:</b> " + formatDate(email.sentReceivedDate, 'medium', 'en-US') + "<br/>";

    // add "To" header, only in case the email was sent to me (not sent by me to others)
    if (this.mailBox == 'inbox') {
      bodyWithHeaders += "<b>To:</b> " + this.currentUserName + "<br/>";
    }

    bodyWithHeaders +=
      "<b>Subject:</b> " + email.title +
      "<br/><br/>" +
      email.body;

    // santize it to prevent XSS Attacks
    return this.sanitizer.sanitize(SecurityContext.HTML, bodyWithHeaders);
  }

  private sendSMSWithMobile(email: ExternalEmailPinViewModel) {
    this.emailService.sendPINViaSMS(email)
      .pipe(first())
      .subscribe(() => {
        this.location.back();
      });
  }

  private uploadSecuredFiles(result: UserSecuredFileViewModel[]) {
    var emailVMArray = result.map(obj => {
      let emailVM: EmailAttachmentViewModel = { emailId: this.emailBody.emailId!, securedFileId: obj.id!, fileName: obj.fileName!, fileType: obj.fileType! };
      return emailVM;
    });
    this.emailService.attachSecuredFiles(emailVMArray).pipe(first()).subscribe(result => {
      this.emailBody.attachments!.push(...result.result!);
    });
  }

  public removeContact(contact: string) {
    let index = this.emailBody.to!.indexOf(contact);
    if (index > -1) {
      this.emailBody.to!.splice(index, 1);
    }
  }

  private defineAttachmentsGrid(): IchsGridComponentConfig<UserSecuredFileViewModel> {
    let configs: IchsGridComponentConfig<UserSecuredFileViewModel> = {
      primaryId: "id",
      defaultOrder: "fileName",
      title: "",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      entityController: "appuser",
      multiSelectConfig: {
        allowSelectObjects: true,
        hasSelectAllCheckbox: false,
      },
      entityDataSource: (filter: SearchViewModel) => this.userFiles.securedFilesAdvanceSearch(filter),
      headers: [
        {
          headerTitle: "File Name",
          propertyName: "fileName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        }
      ]

    }

    return configs;
  }
}
