import { Component, OnInit, Input, EventEmitter, Output, ViewChild, Optional, Inject, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel, NG_VALIDATORS, Validator } from '@angular/forms';
import { ControlBase } from '../control-base';
import { GeneralVariablesService } from '../../services/general-variables.service';

@Component({
  selector: 'ichs-password',
  templateUrl: './ichs-password.component.html',
  styleUrls: ['./ichs-password.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: IchsPasswordComponent,
    multi: true,
  }]
})
export class IchsPasswordComponent extends ControlBase<string> implements OnInit {
  @Input('label') label: string;
  @Input("disabled") isDisabled: boolean;
  @Input("buttonIcon") icon: string;
  @Input("iconTitle") iconTitle: string;
  @Input("hasButton") showButton: boolean;
  @Input("placeholder") placeholder: string = "";
  @Input("autoComplete") autoComplete: string = 'on';
  @Output() passBoxChanged: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(NgModel, { static: true }) model: NgModel;

  // this attribute to give a unique identifier for the textboxes.
  public passIdentifier = `pass-box-${passIdentifier++}`;

  public stars: string = "";
  public showPassword: boolean = false;

  constructor(
    @Optional() @Inject(NG_VALIDATORS) protected _validators: Array<Validator>,
    @Inject(Injector) private _injector: Injector,
    public generalVariables: GeneralVariablesService,
  ) {
    super(_validators, _injector);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  //fire event when clicking on the button
  buttonClickedAction() {
    this.buttonClicked.emit(this.model.value);
  }
}

let passIdentifier = 0;
