<div style="width:100%" class="dashboard-cont cont-padding">
  <div class="position-relative" style="z-index:10;">
    <!--<button (click)="openFullScreenDashboard()" class="cp-btn position-absolute" style="left:330px; top:10px;z-index: 980;">Interactive Dashboard</button>-->
    <div *ngIf="userLineOfBusinessList.length > 1 && !isMaximized" class="position-absolute" style="right: 8px;top: 6px;">
      <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="selectedLineOfBusiness">
        <mat-button-toggle *ngFor="let userLine of userLineOfBusinessList" [value]="userLine" (change)="changeLineOfBusiness(userLine)">
          <ng-container
            *ngIf="(userLine == 'Occupational Health' && userLineOfBusinessList.indexOf('Advanced Practice') == -1) ||
                  (userLine == 'Advanced Practice' && userLineOfBusinessList.indexOf('Occupational Health') == -1); else elseBlock">
            {{ oshaRecordKeepingInstallationAccessOnly ? 'OSHA' : 'Onsite Care' }}
          </ng-container>
          <ng-template #elseBlock>
            {{
              userLine.replace('Advanced Practice', 'Advanced Care')
                      .replace('Triage', 'Telehealth')
                      .replace('Occupational Health', 'Essential Care')
            }}
          </ng-template>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <mat-tab-group mat-align-tabs="start"  mat-stretch-tabs="false" [(selectedIndex)]="selectedTabIndex">
    <mat-tab label="Dashboard">
      <ng-template matTabContent>
        <triage-dashboard *ngIf="selectedLineOfBusiness=='Triage'" (maximized)="isMaximized = $event"
          [dashboardScheduler]="dashboardScheduler">
        </triage-dashboard>
        <occupational-health-dashboard *ngIf="selectedLineOfBusiness == 'Occupational Health'"
          (maximized)="isMaximized = $event" [dashboardScheduler]="dashboardScheduler">
        </occupational-health-dashboard>
        <advanced-practice-dashboard *ngIf="selectedLineOfBusiness=='Advanced Practice'" (maximized)="isMaximized = $event"
          [dashboardScheduler]="dashboardScheduler">
        </advanced-practice-dashboard>
        <osha-reportable-dashboard *ngIf="selectedLineOfBusiness=='OSHA Reportable'" (maximized)="isMaximized = $event"
          [dashboardScheduler]="dashboardScheduler">
        </osha-reportable-dashboard>
      </ng-template>
    </mat-tab>
    <mat-tab label="Manage Items">
      <ng-template matTabContent>
        <div class="display-flex fill-width">
          <div class="flex-basis-0 flex-grow-1 margin-right-def">
            <div class="dash-settings-title dashboard-item-title">Dashboard KPIs</div>
            <div><hr class="main-hr" /></div>
            <div class="margin-top-def margin-bottom-def">
              <dashboard-item-card-list [lineOfBusiness]="selectedLineOfBusiness"></dashboard-item-card-list>
            </div>
          </div>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
