import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionTreeViewModel, SelectionItemViewModel, MedcorResponse, TriageReportCustomHierarchySelectionViewModel } from 'src/app/rest';
import { first } from 'rxjs/operators';
import { IchsTreeDialogReturnData } from 'src/app/controls/ichs-dropdown-tree/ichs-tree-dialog/ichs-tree-dialog.component';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { Observable } from 'rxjs';
import { NodeSelectionModel, ObjNodeSelectionModel } from 'src/app/controls/ichs-dropdown-tree/ichs-dropdown-tree.component';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from 'src/app/services/medcor-alert.service';
import { ValidationMessages } from 'src/app/app.general.constants';
import { NgForm } from '@angular/forms';
import { TriageReportCustomHierarchySelectionService } from 'src/app/rest/api/triageReportCustomHierarchySelection.service';

@Component({
  selector: 'manage-future-use-hierarchy',
  templateUrl: './manage-future-use-hierarchy.component.html',
  styleUrls: ['./manage-future-use-hierarchy.component.css']
})
export class ManageFutureUseHierarchyComponent implements OnInit {

  private _selectedNode: SelectionTreeViewModel;
  path: SelectionTreeViewModel[] = [];
  selectionName: string;
  loadChildren: boolean = true;
  dateRangesItems: SelectionItemViewModel[];
  hideDateRangeSelectCustomLabel: boolean = true;

  returnData: IchsTreeDialogReturnData = new IchsTreeDialogReturnData();
  private originalSelections: NodeSelectionModel;

  @ViewChild("form") ngForm: NgForm;

  get selectedNode(): SelectionTreeViewModel {
    return this._selectedNode;
  }

  set selectedNode(node: SelectionTreeViewModel) {
    this._selectedNode = node;
    this.updatePath();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ManageFutureUseHierarchyDialogData,
    private dialogRef: MatDialogRef<ManageFutureUseHierarchyComponent>,
    private sharedFunctionsService: SharedFunctionsService,
    private alertService: MedcorAlertService,
    private triageReportCustomHierarchySelectionService: TriageReportCustomHierarchySelectionService,
  ) {
    this.loadChildren = data.loadChildren != undefined;
  }

  ngOnInit(): void {
    this.originalSelections = this.getSelection();
    this.data.node.children.forEach(node => node.visible = node.selected || node.partialSelection);
    this.selectedNode = this.data.node;
    this.loadNodeChildren(this.selectedNode);
  }

  updatePath() {
    let mainNode = this.selectedNode;
    this.path = [];
    while (mainNode.parentId) {
      mainNode = this.data.hash[mainNode.level.substring(0, mainNode.level.lastIndexOf('>')).trim()];
      this.path.splice(0, 0, mainNode);
    }
  }

  loadNodeChildren(node: SelectionTreeViewModel) {
    if (this.loadChildren && (node.children == undefined || node.children.length == 0)) {
      this.data.loadChildren(node).pipe(first()).subscribe(result => {
        node.children = result.result;
        this.buildDataHierarchy(result.result);
      });
    }
  }

  rootNodeChecked(event: MatCheckboxChange) {
    let visibleChildren = this.selectedNode.children.filter(child => child.visible);

    if (visibleChildren.length > 0) {
      for (let child of visibleChildren) {
        child.selected = event.checked;
        child.partialSelection = false;
        this.toggleChildren(child);
      }

      this.updateParent(visibleChildren[0]);
    }
  }

  nodeChecked(node: SelectionTreeViewModel) {
    this.toggleChildren(node);
    this.updateParent(node);
  }

  selectParentNode() {
    if (this.selectedNode.parentId) {
      this.selectedNode = this.data.hash[this.selectedNode.level.substring(0, this.selectedNode.level.lastIndexOf('>')).trim()];
    }
  }

  childNodeOpened(node: SelectionTreeViewModel) {
    this.selectedNode = node;
    this.loadNodeChildren(this.selectedNode);
  }

  updateParent(node: SelectionTreeViewModel) {
    if (node.parentId) {
      const parentNode: SelectionTreeViewModel = this.data.hash[node.level.substring(0, node.level.lastIndexOf('>')).trim()];

      const siblings = parentNode.children;
      let equalSiblings = siblings.findIndex(sibling => sibling.partialSelection || sibling.selected != node.selected) == -1;

      if (equalSiblings) {
        parentNode.selected = node.selected;
        parentNode.partialSelection = false;
      } else {
        parentNode.partialSelection = true;
        parentNode.selected = false;
      }

      this.updateParent(parentNode);
    }
  }

  toggleChildren(node: SelectionTreeViewModel) {
    if (!node || !node.children || node.children.length == 0) {
      return;
    }
    node.children.forEach(child => {
      if (child.visible) {
        child.partialSelection = false;
        child.selected = node.selected;
        this.toggleChildren(child);
      }
    });
  }

  isAllSelected() {
    if (!this.selectedNode.children || this.selectedNode.children.length == 0) {
      return false;
    }

    let visibleChildren = this.selectedNode.children.filter(child => child.visible);

    if (visibleChildren.length == 0) {
      return false;
    }

    let indeterminate = visibleChildren.findIndex(child => child.partialSelection || child.selected != visibleChildren[0].selected) > -1;
    if (indeterminate) {
      return null;
    }

    return visibleChildren[0].selected;
  }

  onClose(submited: boolean) {
    this.dialogRef.afterClosed().pipe(first()).subscribe(() => {
      // make all nodes visible upon close
      this.data.node.children.forEach(node => node.visible = true);
    });

    if (submited) {
      this.sharedFunctionsService.validateAllFormFields(this.ngForm.form); // validate the form and show errors
      if (this.ngForm.invalid) {   // check if form is valid 
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: [ValidationMessages.INVALID_FORM]
        });
        return;
      }

      let selections = this.getSelection();

      if (selections.included.length == 0) {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: [ValidationMessages.NO_HIERARCHY_SELECTED]
        });
        return;
      }

      let objPost: TriageReportCustomHierarchySelectionViewModel = {
        selectionName: this.selectionName,
        includedParents: selections.included.join(','),
      };

      this.triageReportCustomHierarchySelectionService.post(objPost).pipe(first()).subscribe(() => {
        this.dialogRef.close(true);
      });
    }
    else { // reset all selections to their original value
      this.sharedFunctionsService.correctTreeNodes(this.data.node, this.data.isLevel, this.originalSelections.included, this.originalSelections.excluded);
      this.dialogRef.close(false);
    }
  }

  private buildDataHierarchy(data: SelectionTreeViewModel[]) {
    if (data) {
      data.forEach(obj => {
        this.data.hash[obj.level] = obj;
        this.buildDataHierarchy(obj.children);
      });
    }
  }

  private getSelection(): NodeSelectionModel {
    const included: string[] = [];
    const excluded: string[] = [];

    if (this.data.node) {
      this.data.node.children.forEach(child => {
        const childSelection = this.getSelectionTree(child);
        let _included = childSelection.included.map(obj => this.data.isLevel || obj.level == "0" ? obj.level : obj.id);
        let _excluded = childSelection.excluded.map(obj => this.data.isLevel || obj.level == "0" ? obj.level : obj.id);

        included.push(..._included);
        excluded.push(..._excluded);
      });
    }

    return { included: included, excluded: excluded };
  }

  private getSelectionTree(node: SelectionTreeViewModel): ObjNodeSelectionModel {
    if (node.partialSelection) {
      const included: SelectionTreeViewModel[] = [];
      const excluded: SelectionTreeViewModel[] = [];
      node.children.forEach(child => {
        const childSelection = this.getSelectionTree(child);
        included.push(...childSelection.included);
        excluded.push(...childSelection.excluded);
      })
      return { included: included, excluded: excluded };
    } else if (node.selected) {
      return { included: [node], excluded: [] };
    } else {
      return { included: [], excluded: [node] };
    }
  }
}

export class ManageFutureUseHierarchyDialogData {
  node: SelectionTreeViewModel;
  loadChildren: (node: SelectionTreeViewModel) => Observable<MedcorResponse<Array<SelectionTreeViewModel>>>;
  hash: any;
  isLevel: boolean;
}