import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { IchsCommandToolbarDelegate, IchsCommandToolbarModel } from '../../controls/ichs-command-toolbar/ichs-command-toolbar.component';
import { AppUserService, SearchViewModel, UserContactService, ContactGridViewModel, SiteCompanyInstViewModel, UserLookupDetailsViewModel } from '../../rest/index';
import { MedcorAlertService } from '../../services/medcor-alert.service';
import { IchsGridComponentConfig, IchsControlType } from '../../controls/ichs-grid/grid-configs';
import { IchsGridDialogComponent } from 'src/app/controls/ichs-grid-dialog/ichs-grid-dialog.component';
import { GroupViewModel } from '../../rest/model/groupViewModel';
import { GroupAppUserAssociationService } from '../../rest/api/groupAppUserAssociation.service';
import { MedcorResponse } from 'src/app/rest/model/medcorResponse';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { AfkamIntanceViewModel } from 'src/app/rest/model/afkamIntanceViewModel';
import { PracticeLocationViewModel } from 'src/app/rest/model/practiceLocationViewModel';
import { ScreeningInstanceCollectionViewModel } from 'src/app/rest/model/screeningInstanceCollectionViewModel';
import { OshaCompanyLocationViewModel } from 'src/app/rest/model/oshaCompanyLocationViewModel';
import { LineOfBusiness } from 'src/app/app.general.constants';

@Component({
  selector: 'user-lookup-details',
  templateUrl: './user-lookup-details.component.html',
  styleUrls: ['./user-lookup-details.component.scss']
})
export class UserLookupDetailsComponent implements OnInit, IchsCommandToolbarDelegate<UserLookupDetailsViewModel> {
  @ViewChild('form', { static: true }) ngForm: NgForm;
  @ViewChild('innerMatTabGroup') innerMatTabGroup: MatTabGroup;

  model: IchsCommandToolbarModel<UserLookupDetailsViewModel>;
  userContactsGridConfig: IchsGridComponentConfig<ContactGridViewModel>;

  userDataAccessTabLabel: string = "User Data Access (From Groups)";
  innerMatTabGroupUpdated: boolean = false;
  userGroupsDataAccessGrids: UserGroupsDataAccessGrid[] = [];

  get appuserId(): number { return this.model.objModelId }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appUserService: AppUserService,
    private location: Location,
    private alertService: MedcorAlertService,
    private dialog: MatDialog,
    private userContactService: UserContactService,
    private groupAppUserAssociationService: GroupAppUserAssociationService,
  ) {
    this.model = new IchsCommandToolbarModel<UserLookupDetailsViewModel>(location, router, "/user-lookup", "User Lookup", this, dialog, alertService);
    this.model.hasVoidRecover = false;
    this.model.hasSave = false;
  }

  ngOnInit() {
    this.model.init(this.route);
    this.userContactsGridConfig = this.defineUserContactsGridConfig();
  }

  getLoadObjModelObservable(id: number) {
    return this.appUserService.getWithExtension(id);
  }

  didLoad(objModel: UserLookupDetailsViewModel) {
    this.userGroupsDataAccessGrids = objModel.groupsLineOfBusinessList.map(lob => <UserGroupsDataAccessGrid>{
      lineOfBusiness: lob,
      gridConfig: this.getUserGroupsDataAccessGridConfig(lob),
      isGridLoaded: false,
    });
  }

  canDeactivate() {
    return true;
  }

  onSelectedTabChange(matTabChangeEvent: MatTabChangeEvent) {
    // if innerMatTabGroup (Groups Data Access) is not updated yet, update it, if and only if it is currently selected
    if (!this.innerMatTabGroupUpdated && matTabChangeEvent.tab.textLabel == this.userDataAccessTabLabel) {
      this.innerMatTabGroup.realignInkBar();  // re-align ink bar (underline selected tab in the inner mat-tab-group)
      this.innerMatTabGroupUpdated = true;    // update flag so this won't get updated again

      if (this.userGroupsDataAccessGrids.length > 0 && !this.userGroupsDataAccessGrids[0].isGridLoaded) {
        this.userGroupsDataAccessGrids[0].gridConfig.refreshGrid(0);
        this.userGroupsDataAccessGrids[0].isGridLoaded = true;
      }
    }
  }

  onSelectedIndexChange(newTabIndex: number) {
    if (!this.userGroupsDataAccessGrids[newTabIndex].isGridLoaded) {
      this.userGroupsDataAccessGrids[newTabIndex].gridConfig.refreshGrid(0);
      this.userGroupsDataAccessGrids[newTabIndex].isGridLoaded = true;
    }
  }

  viewUserGroupsGrid() {
    this.dialog.open(IchsGridDialogComponent, {
      width: '1024px',
      height: '800px',
      data: {
        config: this.defineUserGroupsGrid(),
        title: "User Groups"
      }
    });
  }

  private defineUserGroupsGrid(): IchsGridComponentConfig<GroupViewModel> {
    let configs: IchsGridComponentConfig<GroupViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      entityDataSource: (filter: SearchViewModel) => this.groupAppUserAssociationService.searchGroupsByAppUserId(this.model.objModelId, filter),
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }

  private defineUserContactsGridConfig(): IchsGridComponentConfig<ContactGridViewModel> {
    let configs: IchsGridComponentConfig<ContactGridViewModel> = {
      primaryId: "id",
      defaultOrder: "lastName",
      title: null,
      entityController: null,
      entityDataSource: (filter: SearchViewModel) => {
        return this.userContactService.searchRelatedContacts(this.model.objModelId, false, filter);
      },
      addExistingConfig: {
        buttonText: "Add Existing",
        gridConfig: this.addExistingContactGridConfig(),
        addExistingService: (ids: string[]) => {
          let close = new Subject<MedcorResponse<boolean>>();
          this.userContactService.postList(this.model.objModelId, ids.map(obj => +obj))
            .pipe(first())
            .subscribe(result => {
              if (result) {
                close.next({ result: true, statusCode: 200 });
              } else {
                close.next({ result: false });
              }
            });
          return close.asObservable();
        },
        title: "Add Contacts",
      },
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: ContactGridViewModel) => {
          if (!deletedObj.id) return;
          this.userContactService.removeContact(deletedObj.id, this.model.objModelId)
            .pipe(first())
            .subscribe(result => {
              if (result) {
                this.userContactsGridConfig.refreshGrid!(this.userContactsGridConfig.getPage!());
              }
            });
        }
      },
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
          width: 300,
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "First Name",
          propertyName: "firstName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }

  private addExistingContactGridConfig(): IchsGridComponentConfig<ContactGridViewModel> {
    let addExistingGridConfig: IchsGridComponentConfig<ContactGridViewModel> = {
      primaryId: "id",
      defaultOrder: "lastName",
      title: "Users",
      entityController: null,
      entityDataSource: (filter: SearchViewModel) => this.userContactService.searchRelatedContacts(this.model.objModelId, true, filter),
      multiSelectConfig: {
      },
      hasVoid: false,
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
          width: 300,
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "First Name",
          propertyName: "firstName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    };
    return addExistingGridConfig;
  }


  private getUserGroupsDataAccessGridConfig(lineOfBusiness: string) {
    switch (lineOfBusiness) {
      case LineOfBusiness.TRIAGE:
        return this.defineAfkamGroupsAccessGrid();
      case LineOfBusiness.OCCUPATIONAL_HEALTH:
        return this.defineMedfilesGroupsAccessGrid();
      case LineOfBusiness.ADVANCED_PRACTICE:
        return this.defineNextGenGroupsAccessGrid();
      case LineOfBusiness.SCREENING:
        return this.defineScreeningGroupsAccessGrid();
      case LineOfBusiness.OSHA_REPORTABLE:
        return this.defineOshaReportableGroupsAccessGrid();
      default:
        return null;
    }
  }

  private defineAfkamGroupsAccessGrid(): IchsGridComponentConfig<AfkamIntanceViewModel> {
    let configs: IchsGridComponentConfig<AfkamIntanceViewModel> = {
      primaryId: "name",
      defaultOrder: "name",
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: false,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUserAfkamDataAccess(this.appuserId, filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Sub Level",
          propertyName: "namedPath",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }

  private defineMedfilesGroupsAccessGrid(): IchsGridComponentConfig<SiteCompanyInstViewModel> {
    let configs: IchsGridComponentConfig<SiteCompanyInstViewModel> = {
      primaryId: "siteName",
      defaultOrder: "siteName",
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: false,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUserMedfilesDataAccess(this.appuserId, filter),
      headers: [
        {
          headerTitle: "Site",
          propertyName: "siteName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Company",
          propertyName: "companyName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Installation",
          propertyName: "installationName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        }
      ],
    }
    return configs;
  }

  private defineNextGenGroupsAccessGrid(): IchsGridComponentConfig<PracticeLocationViewModel> {
    let configs: IchsGridComponentConfig<PracticeLocationViewModel> = {
      primaryId: "locationName",
      defaultOrder: "locationName",
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: false,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUserNextGenDataAccess(this.appuserId, filter),
      headers: [
        {
          headerTitle: "Location",
          propertyName: "locationName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Practice",
          propertyName: "practiceName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }

  private defineScreeningGroupsAccessGrid(): IchsGridComponentConfig<ScreeningInstanceCollectionViewModel> {
    let configs: IchsGridComponentConfig<ScreeningInstanceCollectionViewModel> = {
      primaryId: "locationSubLevelName",
      defaultOrder: "locationSubLevelName",
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: false,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUserScreeningDataAccess(this.appuserId, filter),
      headers: [
        {
          headerTitle: "Location Sub-Level",
          propertyName: "locationSubLevelName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Location",
          propertyName: "locationName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Client",
          propertyName: "clientName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }

  private defineOshaReportableGroupsAccessGrid(): IchsGridComponentConfig<OshaCompanyLocationViewModel> {
    let configs: IchsGridComponentConfig<OshaCompanyLocationViewModel> = {
      primaryId: "locationName",
      defaultOrder: "locationName",
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: false,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUserOshaReportableDataAccess(this.appuserId, filter),
      headers: [
        {
          headerTitle: "Location",
          propertyName: "locationName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Company",
          propertyName: "companyName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }
}

export class UserGroupsDataAccessGrid {
  lineOfBusiness: string;
  gridConfig: IchsGridComponentConfig<any>;
  isGridLoaded: boolean;
}
