import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SelectionItemViewModel } from '../../../rest/index';
import { IchsGridColumnConfig } from '../grid-configs';
import { filter, debounceTime, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ichs-grid-header-field',
  templateUrl: './ichs-grid-header-field.component.html',
  styleUrls: ['./ichs-grid-header-field.component.css']
})
    //Define a custom form element to be positioned in the header of the grid field.
    //this component will be used in ichs-Grid and depending on the type of the header
    // passed in the configuration file, the filter will be rendered.
export class IchsGridHeaderFieldComponent implements OnInit {
  filterValueChanged: Subject<string> = new Subject<string>();
    @Input("field-config") public config: IchsGridColumnConfig;
    @Output() enterEvent: EventEmitter<any> = new EventEmitter(); //fire when the enter button clicked in the text or number fields
    yesNoList: SelectionItemViewModel[] = [{ selectionText: "Yes", selectionValue: "true" }, { selectionText: "No", selectionValue: "false" }];
    constructor() { }
    

  ngOnInit() {

    this.filterValueChanged.pipe(filter(value => this.config.autoSearch), debounceTime(400), distinctUntilChanged()).subscribe(result => {
        this.enterEvent.emit(null);
    });
  }

    //handler for keyup.enter event on text and number fields
  enterClicked() {
      this.enterEvent.emit(null);
  }

}


