/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';













import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {MedcorResponse,PaginatedListViewModel,ReportItemViewModel,ReportSchedulerViewModel,SearchViewModel,VoidInfoViewModel,SelectionItemViewModel,AfkamHierarchyNode} from '../index';


@Injectable()
export class ReportSchedulerService {

    protected basePath = 'https://localhost/ClientPortalAPI';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Delete ReportScheduler
     * 
     * @param id Search Id
     * @param version object Version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(id?: number, version?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<ReportSchedulerViewModel>>;
    public _delete(id?: number, version?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<ReportSchedulerViewModel>>>;
    public _delete(id?: number, version?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<ReportSchedulerViewModel>>>;
    public _delete(id?: number, version?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (version !== undefined && version !== null) {
            queryParameters = queryParameters.set('version', <any>version);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<MedcorResponse<ReportSchedulerViewModel>>(`${this.basePath}/api/ReportScheduler`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Void ReportScheduler
     * 
     * @param objVoid Void object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _void(objVoid?: VoidInfoViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<ReportSchedulerViewModel>>;
    public _void(objVoid?: VoidInfoViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<ReportSchedulerViewModel>>>;
    public _void(objVoid?: VoidInfoViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<ReportSchedulerViewModel>>>;
    public _void(objVoid?: VoidInfoViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<ReportSchedulerViewModel>>(`${this.basePath}/api/ReportScheduler/Void`,
            objVoid,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All ReportSchedulers
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<ReportSchedulerViewModel>>>;
    public get(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<ReportSchedulerViewModel>>>>;
    public get(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<ReportSchedulerViewModel>>>>;
    public get(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<ReportSchedulerViewModel>>>(`${this.basePath}/api/ReportScheduler`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get ReportScheduler By Id
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(id: number, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<ReportSchedulerViewModel>>;
    public getById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<ReportSchedulerViewModel>>>;
    public getById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<ReportSchedulerViewModel>>>;
    public getById(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getById.');
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<ReportSchedulerViewModel>>(`${this.basePath}/api/ReportScheduler/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Report Schedules Recipients As SelectionList
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReportSchedulesRecipientsAsSelectionList(observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<SelectionItemViewModel>>>;
    public getReportSchedulesRecipientsAsSelectionList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<SelectionItemViewModel>>>>;
    public getReportSchedulesRecipientsAsSelectionList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<SelectionItemViewModel>>>>;
    public getReportSchedulesRecipientsAsSelectionList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<SelectionItemViewModel>>>(`${this.basePath}/api/ReportScheduler/GetReportSchedulesRecipientsAsSelectionList`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add Or Update ReportScheduler
     * If Id is 0 Then Add Else Update
     * @param objPost ReportScheduler To Add or Update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public post(objPost?: ReportSchedulerViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<ReportSchedulerViewModel>>;
    public post(objPost?: ReportSchedulerViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<ReportSchedulerViewModel>>>;
    public post(objPost?: ReportSchedulerViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<ReportSchedulerViewModel>>>;
    public post(objPost?: ReportSchedulerViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<ReportSchedulerViewModel>>(`${this.basePath}/api/ReportScheduler`,
            objPost,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recover ReportScheduler
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recover(id: number, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<ReportSchedulerViewModel>>;
    public recover(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<ReportSchedulerViewModel>>>;
    public recover(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<ReportSchedulerViewModel>>>;
    public recover(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling recover.');
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<ReportSchedulerViewModel>>(`${this.basePath}/api/ReportScheduler/Recover/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Remove Current UserId from Recipients of Report Scheduler
     * 
     * @param reportScheduleId Report Schedule Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public removeSelfFromRecipients(reportScheduleId?: number, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<boolean>>;
    public removeSelfFromRecipients(reportScheduleId?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<boolean>>>;
    public removeSelfFromRecipients(reportScheduleId?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<boolean>>>;
    public removeSelfFromRecipients(reportScheduleId?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reportScheduleId !== undefined && reportScheduleId !== null) {
            queryParameters = queryParameters.set('reportScheduleId', <any>reportScheduleId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<MedcorResponse<boolean>>(`${this.basePath}/api/ReportScheduler/RemoveSelfFromRecipients`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param objSearch 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(objSearch?: SearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<ReportSchedulerViewModel>>>;
    public search(objSearch?: SearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<ReportSchedulerViewModel>>>>;
    public search(objSearch?: SearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<ReportSchedulerViewModel>>>>;
    public search(objSearch?: SearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<ReportSchedulerViewModel>>>(`${this.basePath}/api/ReportScheduler/Search`,
            objSearch,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Hierarchy Instances
     * 
     * @param reportScheduleId 
     * @param objSearch 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchReportScheduleHierarchyInstances(reportScheduleId?: number, objSearch?: SearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<AfkamHierarchyNode>>>;
    public searchReportScheduleHierarchyInstances(reportScheduleId?: number, objSearch?: SearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<AfkamHierarchyNode>>>>;
    public searchReportScheduleHierarchyInstances(reportScheduleId?: number, objSearch?: SearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<AfkamHierarchyNode>>>>;
    public searchReportScheduleHierarchyInstances(reportScheduleId?: number, objSearch?: SearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reportScheduleId !== undefined && reportScheduleId !== null) {
            queryParameters = queryParameters.set('reportScheduleId', <any>reportScheduleId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<AfkamHierarchyNode>>>(`${this.basePath}/api/ReportScheduler/SearchReportScheduleHierarchyInstances`,
            objSearch,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search ReportScheduler
     * &#x60;&#x60;&#x60;               {                  \&quot;filterExpression\&quot;: \&quot;userName &#x3D;&#x3D; \\\&quot;Medcor\\\&quot;\&quot;,                  \&quot;pageIndex\&quot;: 1,                  \&quot;pageSize\&quot;: 10,                  \&quot;sortExpressions\&quot;: [                   \&quot;userName\&quot;, \&quot;Age Desc\&quot;                  ]               }              &#x60;&#x60;&#x60;
     * @param reportSchedulerId Recipient Id
     * @param objSearch Search object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchReportSchedulerHistory(reportSchedulerId?: number, objSearch?: SearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<ReportSchedulerViewModel>>>;
    public searchReportSchedulerHistory(reportSchedulerId?: number, objSearch?: SearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<ReportSchedulerViewModel>>>>;
    public searchReportSchedulerHistory(reportSchedulerId?: number, objSearch?: SearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<ReportSchedulerViewModel>>>>;
    public searchReportSchedulerHistory(reportSchedulerId?: number, objSearch?: SearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reportSchedulerId !== undefined && reportSchedulerId !== null) {
            queryParameters = queryParameters.set('reportSchedulerId', <any>reportSchedulerId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<ReportSchedulerViewModel>>>(`${this.basePath}/api/ReportScheduler/SearchReportSchedulerHistory`,
            objSearch,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search ReportScheduler
     * &#x60;&#x60;&#x60;               {                  \&quot;filterExpression\&quot;: \&quot;userName &#x3D;&#x3D; \\\&quot;Medcor\\\&quot;\&quot;,                  \&quot;pageIndex\&quot;: 1,                  \&quot;pageSize\&quot;: 10,                  \&quot;sortExpressions\&quot;: [                   \&quot;userName\&quot;, \&quot;Age Desc\&quot;                  ]               }              &#x60;&#x60;&#x60;
     * @param recipientId Recipient Id
     * @param objSearch Search object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchReportSchedulesByRecipient(recipientId?: number, objSearch?: SearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<ReportSchedulerViewModel>>>;
    public searchReportSchedulesByRecipient(recipientId?: number, objSearch?: SearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<ReportSchedulerViewModel>>>>;
    public searchReportSchedulesByRecipient(recipientId?: number, objSearch?: SearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<ReportSchedulerViewModel>>>>;
    public searchReportSchedulesByRecipient(recipientId?: number, objSearch?: SearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (recipientId !== undefined && recipientId !== null) {
            queryParameters = queryParameters.set('recipientId', <any>recipientId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<ReportSchedulerViewModel>>>(`${this.basePath}/api/ReportScheduler/SearchReportSchedulesByRecipient`,
            objSearch,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update Scheduled Reports Job Times
     * 
     * @param apiPassword Password to use API
     * @param newJobTimeHour New Job Time Hour (24h - UTC timezone), if not specified, value in appsettings will be used
     * @param newJobTimeMinute New Job Time Minute, if not specified, value in appsettings will be used
     * @param updateGivenScheduleIdsOnly 
     * @param scheduleIds Ids of Schedules to update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateScheduledReportsJobTimes(apiPassword?: string, newJobTimeHour?: number, newJobTimeMinute?: number, updateGivenScheduleIdsOnly?: boolean, scheduleIds?: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<string>>;
    public updateScheduledReportsJobTimes(apiPassword?: string, newJobTimeHour?: number, newJobTimeMinute?: number, updateGivenScheduleIdsOnly?: boolean, scheduleIds?: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<string>>>;
    public updateScheduledReportsJobTimes(apiPassword?: string, newJobTimeHour?: number, newJobTimeMinute?: number, updateGivenScheduleIdsOnly?: boolean, scheduleIds?: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<string>>>;
    public updateScheduledReportsJobTimes(apiPassword?: string, newJobTimeHour?: number, newJobTimeMinute?: number, updateGivenScheduleIdsOnly?: boolean, scheduleIds?: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (apiPassword !== undefined && apiPassword !== null) {
            queryParameters = queryParameters.set('apiPassword', <any>apiPassword);
        }
        if (newJobTimeHour !== undefined && newJobTimeHour !== null) {
            queryParameters = queryParameters.set('newJobTime_Hour', <any>newJobTimeHour);
        }
        if (newJobTimeMinute !== undefined && newJobTimeMinute !== null) {
            queryParameters = queryParameters.set('newJobTime_Minute', <any>newJobTimeMinute);
        }
        if (updateGivenScheduleIdsOnly !== undefined && updateGivenScheduleIdsOnly !== null) {
            queryParameters = queryParameters.set('updateGivenScheduleIdsOnly', <any>updateGivenScheduleIdsOnly);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<string>>(`${this.basePath}/api/ReportScheduler/UpdateScheduledReportsJobTimes`,
            scheduleIds,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
