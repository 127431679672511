<form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
  <div class="display-flex flex-col gap-m">
    <div class="flex-child flex-basis-0 flex-grow-1">
      <ichs-text-box [initialFocus]="true" required name="lastName" [(ngModel)]="lastName" label="Last Name">
      </ichs-text-box>
    </div>
    <div class="flex-child flex-basis-0 flex-grow-1">
      <ichs-text-box name="refNumber" [(ngModel)]="refNumber" required
        [label]="isMedfilesAfterCare ? 'Reference Nbr' : 'Telehealth Call Reference Nbr'">
      </ichs-text-box>
    </div>
    <div class="flex-child align-self-center">
      <button (click)="getInfo()" class="cp-btn cp-grid-head-btn care-search-submit">
        Submit
      </button>
    </div>
  </div>
</form>

<ng-container *ngIf="isSearchDone">
  <hr/>
  <div>
    <h3 class="file-list-title">Care Instructions files</h3>
  </div>
  <div *ngFor="let triageAfterCareFile of triageAfterCareList,let i = index">
    <div class="display-flex flex-items-centered">
      <div class="flex-child flex-basis-0 flex-grow-1">
        <ichs-text-box name="name" [ngModel]="triageAfterCareFile.name" [title]="triageAfterCareFile.name"
          label="File Name" disabled>
        </ichs-text-box>
      </div>
      <div class="flex-col-sep"></div>
      <div class="flex-child flex-basis-0 flex-grow-1">
        <ichs-text-box name="language" [ngModel]="triageAfterCareFile.language" [title]="triageAfterCareFile.language"
          label="Language" disabled>
        </ichs-text-box>
      </div>
      <div class="flex-col-sep"></div>
      <div class="flex-child align-self-end">
        <button (click)="downloadFile(triageAfterCareFile)" class="cp-btn cp-main-btn">
          Download
        </button>
      </div>
    </div>
  </div>
</ng-container>
