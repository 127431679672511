<h5 *ngIf="!hideAcceptDeclineOptions">BY CLICKING “I AGREE” BELOW, I AGREE TO THE FOLLOWING:</h5>

<ol>
    <li>I am authorized by law to access this data, including applicable laws relating to the confidentiality of
        medical information.</li>
    <br />
    <li>If I am accessing this data for worker’s compensation purposes, I certify that it is necessary for me to access
        this data to comply with laws relating to workers compensation or other similar programs, established by law,
        that provide benefits for work-related injuries or illnesses without regard to fault.</li>
    <br />
    <li>I agree to view the data before downloading the data and if the data does not meet the requirements of 1-2
        above, I agree not to download the data. By downloading the data I affirmatively state that I have viewed the
        data and it meets the requirements of 1-2 above.</li>
    <br />
    <li>I agree to follow all applicable State and Federal laws regulating the confidentiality of the data in this
        database, and I will not improperly disclose or distribute the data.</li>
</ol>

<ng-container *ngIf="!hideAcceptDeclineOptions">
    <br />
    <h5>IF I DO NOT AGREE I WILL CLICK “CANCEL” BELOW.</h5>

    <div class="text-right fill-width">
        <button [mat-dialog-close]="true" class="cp-btn cp-main-btn">I Agree</button>
        <button [mat-dialog-close]="false" class="cp-btn cp-mail-btn">Cancel</button>
    </div>
</ng-container>