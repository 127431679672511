<div class="display-flex flex-basis-0 flex-grow-1 flex-col">
  <div class="cont-padding border-bottom">
    <ichs-command-toolbar [model]="model" class="display-flex flex-col"></ichs-command-toolbar>
  </div>

  <mat-tab-group mat-align-tabs="start"  mat-stretch-tabs="false" [(selectedIndex)]="model.selectedTab" class="flex-grow-1">
    <mat-tab label="Category Details">
      <form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
        <ichs-collapsable-panel panelTitle="Category Details" style="padding-top:0px">
          <div class="collapsableBody">
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box type="text" name="name" [(ngModel)]="model.objModel.name" required label="Name" maxlength="250"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box type="text" name="iconPath" [(ngModel)]="model.objModel.iconPath" label="Icon Path" maxlength="250" required></ichs-text-box>
              </div>
            </div>
          </div>
        </ichs-collapsable-panel>
      </form>
    </mat-tab>
    <mat-tab label="Category Questions" *ngIf="model.objModelId > -1">
      <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab has-command">
        <ichs-grid [grid-config]="gridConfigQuestions" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
