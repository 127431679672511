<div class="flex-basis-0 flex-grow-1">
  <div class="display-flex flex-items-centered">
    <div class="flex-child flex-basis-0 flex-grow-1">
      Email(s)
      <div class="display-flex flex-items-centered">
        <a class="btn btn-tree flex-cell" (click)="openUsersGrid()">
          {{userIds.length}} Selected. Click here to add e-mail(s)
        </a>
      </div>
    </div>
    <div class="flex-col-sep"></div>
    <div class="flex-child flex-basis-0 flex-grow-1" style="align-self:flex-end">
      <div style="width:200px">
        <ichs-select required label="File Type" name="fileType" [items]="fileTypesListItem" [(ngModel)]="fileType"></ichs-select>
      </div>
    </div>
  </div>
</div>

<div class="text-right fill-width margin-top-def margin-bottom-def" style="padding-top:20px;">
  <button *ngIf="!generalVariables.userImpersonationInfo.impersonatorRoleInd" mat-button class="cp-btn cp-main-btn"
          (click)="shareFile()">
    Share
  </button>
  <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">Close</button>
</div>
