<div class="flex-basis-0 flex-grow-1 display-flex flex-col">
  <div>
    <h1 class="main-title">Change Log</h1>
    <div class="display-flex break-la">
      <div class="cont-padding flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
        <ichs-select name="releaseLabel" label="Release label" hideResetLabel="true" [(ngModel)]="releaseLabel" listId="108000000000006"
          (selectionChanged)="loadData()">
        </ichs-select>
      </div>
    </div>
  </div>

  <div class="display-flex flex-grow-1" style="align-items:flex-start; flex-wrap:wrap;" *ngIf="changeLog">
    <ul>
      <li *ngFor="let item of changeLog.issues">
        <h4><a [href]="'https://medcor.atlassian.net/browse/'+item.key" target="_blank">{{item.fields.summary}}</a>
        </h4>
        {{item.fields.description}}
      </li>
    </ul>
  </div>
</div>