<div class='flex-cell input-cont'>
    <mat-slide-toggle [(ngModel)]="value" 
                      [id]="slideToggleIdentifier" 
                      [disabled]="isDisabled" 
                      (change)="slideToggleChangedEvent($event)">
    </mat-slide-toggle>
</div>

<div class='flex-cell label-cont' *ngIf="label">
    <label [attr.for]="slideToggleIdentifier">{{label}}</label>
</div>

<div class="flex-cell">
    <validation-message *ngIf="invalid && model.control.touched" [messages]="failures"></validation-message>
</div>
