import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IframeDialogComponent } from '../ichs-dialog/iframe-dialog/iframe-dialog.component';


@Component({
  selector: 'ichs-home-tile',
  templateUrl: './ichs-home-tile.component.html',
  styleUrls: ['./ichs-home-tile.component.css'],
  host: { class: "flex-basis-0 flex-grow-1 tile-item" }
})

//Selection list controller that allows multi select, grouping and dynamic loading of list
export class IchsHomeTileComponent implements OnInit {
  public identifier = `ichs-home-tile-${homeTileIdentifier++}`;
  @Input("description") description: string = "";
  @Input("label") label: string = "";
  @Input("titleLink") titleLink: string = "";
  @Input("imgSrc") imgSrc: string = "";
  @Input("imgLink") imageLink: string = "";
  @Input("tabMode") tabMode: boolean = false;
  @Input("dialogWidth") dialogWidth: string;

  constructor(public dialog: MatDialog) { }

  ngOnInit() { }

  linkClicked(event, link) {
    event.stopPropagation();
    this.dialog.open(IframeDialogComponent, {
      data: {
        title: this.label,
        link: link,
      },
      height: "780px",
      width: this.dialogWidth,
      panelClass: "main-page-link"
    })
  }
}

let homeTileIdentifier = 0;
