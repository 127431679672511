import { Injectable } from '@angular/core';
import { LineOfBusiness, ValidationMessages } from '../app.general.constants';
import { SelectionTreeViewModel, AccountsService, MedcorResponse } from '../rest';
import { first, switchMap } from 'rxjs/operators';
import { MedcorAuthenticationService } from './medcor-authentication.service';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from './medcor-alert.service';
import { LineOfBusinessTreeComponent } from '../appuser/line-of-business-tree/line-of-business-tree.component';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { IchsDialogComponent } from '../controls/ichs-dialog/ichs-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { saveAs } from 'file-saver';

@Injectable()
export class SharedFunctionsService {

  constructor(
    private accountsService: AccountsService,
    private authenticationService: MedcorAuthenticationService,
    private alertService: MedcorAlertService,
    private dialog: MatDialog,
    private msalService: MsalService,
  ) { }

  // shared function to translate line of business old names to new names and vice versa.
  public translateLineOfBusinessName(name: string): string {

    // if input is new name, translate it to old name
    if (name == LineOfBusiness.TRIAGE) {
      return "Afkam";
    }
    else if (name == LineOfBusiness.OCCUPATIONAL_HEALTH) {
      return "Medfiles";
    }
    else if (name == LineOfBusiness.ADVANCED_PRACTICE) {
      return "NextGen";
    }
    else if (name == LineOfBusiness.OSHA_REPORTABLE) {
      return "ClientPortalApps";
    }

    // if input is old name, translate it to new name
    if (name == "Afkam") {
      return LineOfBusiness.TRIAGE;
    }
    else if (name == "Medfiles") {
      return LineOfBusiness.OCCUPATIONAL_HEALTH;
    }
    else if (name == "NextGen") {
      return LineOfBusiness.ADVANCED_PRACTICE;
    }
    else if (name == "ClientPortalApps") {
      return LineOfBusiness.OSHA_REPORTABLE;
    }

    // else, return null
    return null;
  }

  public correctTreeNodes(tree: SelectionTreeViewModel, isLevel: boolean, included: string[], excluded: string[]): SelectionTreeViewModel {
    tree.partialSelection = false;
    if (included.length > 0) {
      if (included.indexOf('0') >= 0 || included.indexOf(isLevel ? tree.level : tree.id) >= 0) {
        tree.selected = true;
        if (tree.children) {
          tree.children.forEach(obj => {
            this.correctTreeNodes(obj, isLevel, ['0'], []);
          });
        }
        return tree;
      } else {
        if (!tree.children || tree.children.length == 0) {
          tree.selected = false
          return tree;
        }
      }
    } else if (excluded.length > 0) {
      if (excluded.indexOf('0') >= 0 || excluded.indexOf(isLevel ? tree.level : tree.id) >= 0) {
        tree.selected = false;
        if (tree.children) {
          tree.children.forEach(obj => {
            this.correctTreeNodes(obj, isLevel, [], ['0']);
          });
        }
        return tree;
      } else {
        if (!tree.children || tree.children.length == 0) {
          tree.selected = true
          return tree;
        }
      }
    } else {
      return tree;
    }
    const childMap = tree.children.map(obj => this.correctTreeNodes(obj, isLevel, included, excluded));
    const intermediate = (childMap.findIndex(obj => obj.partialSelection) >= 0) ||
      (childMap.findIndex(obj => obj.selected) >= 0 && childMap.findIndex(obj => !obj.selected) >= 0)
    tree.partialSelection = intermediate;
    if (intermediate) {
      tree.selected = true
    } else {
      tree.selected = (childMap.findIndex(obj => obj.selected) >= 0)
    }
    return tree;
  }

  public isValidEmail(email: string) {
    let emailValidationExpression = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i;
    return emailValidationExpression.test(email);
  }

  public logout() {
    // if user logged in via azure ad, log him/her out from it too
    if (this.msalService.instance.getAllAccounts().length > 0) {
      this.msalService.initialize()
        .pipe(first(), switchMap(() => this.msalService.logoutPopup({ account: this.msalService.instance.getActiveAccount() })))
        .pipe(first(), switchMap(() => this.accountsService.logout().pipe(first())))
        .subscribe(() => {
          this.authenticationService.unAuthenticate();
        });
    }
    else {
      this.accountsService.logout().pipe(first()).subscribe(() => {
        this.authenticationService.unAuthenticate();
      });
    }
  }

  public showDashboardDataAccessChangesReminder() {
    //get the next hour
    let date = new Date();
    date.setHours(date.getHours() + 1);
    let nextHour = date.getHours();

    this.alertService.addAlert({
      type: AlertMessageType.info,
      title: 'Data Access Changes!',
      dismiss: AlertDismissType.auto,
      messages: [ValidationMessages.DATA_ACCESS_CHANGES.replace("#hour#", nextHour + ":10")],
    });
  }

  public validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }


  // validate the Line of business controls
  public validateLineOfBusinessFields(childComp: LineOfBusinessTreeComponent): boolean {
    var validComponent: boolean = true;
    if (validComponent)
      validComponent = childComp.ngForm.form.valid;
    Object.keys(childComp.ngForm.form.controls).forEach(field => {
      const control = childComp.ngForm.form.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      }
    });

    if (childComp.LineOfBusinessChildren.length > 0) {
      childComp.LineOfBusinessChildren.forEach(bChild => {
        var childValid = this.validateLineOfBusinessFields(bChild);
        if (validComponent)
          validComponent = childValid;
      });
    }

    if (
      childComp.treeData &&
      childComp.treeData.length > 0 &&
      childComp.treeData[0].multiple &&
      childComp.treeData[0].nodeValues!.length == 0 &&
      !childComp.treeData[0].allSelected
    ) {
      validComponent = false;
    }

    return validComponent;
  }

  public isSupportedFileType(file: File) {
    let notSupportedMimeTypes: string[] =
      [
        "image/svg+xml"
      ];

    if (notSupportedMimeTypes.includes(file.type)) {
      return false;
    }

    let isSupportedFileMimeType: boolean = false;

    let supportedMimeTypeStartingWords: string[] =
      [
        "image",
        "audio",
        "video",
      ];

    for (let mimeType of supportedMimeTypeStartingWords) {
      if (isSupportedFileMimeType = file.type.startsWith(mimeType)) {
        break;
      }
    }

    if (isSupportedFileMimeType) {
      return true;
    }

    let supportedFileTypes: string[] =
      [
        "pdf",
        "doc",
        "docx",
        "dot",
        "dotx",
        "rtf",
        "xls",
        "xlsx",
        "csv",
        "ppt",
        "pptx",
        "txt",
        "zip",
        "rar",
        "edi",
      ];

    let indexOfLastDot = file.name.lastIndexOf(".");
    let extension = indexOfLastDot > -1 ? file.name.substring(indexOfLastDot + 1, file.name.length).toLowerCase() : null;

    return supportedFileTypes.includes(extension);
  }

  isViewableFile(name: string) {
    const ViewableFileTypes = [
      "audio",
      "video",
      "text/plain",
      "image",
      "pdf"
    ];
    return ViewableFileTypes.some(x => name.includes(x));
  }

  //Function to show a dialog regarding the reporting service 
  public showReportStatusDialog(err: HttpErrorResponse) {
    let error = err.error as MedcorResponse<null>;
    if (error.statusCode == 400) {
      this.dialog.open(IchsDialogComponent, {
        width: '450px',
        data: {
          title: "Reporting Services Unavailable",
          message: error.messages,
          hideCancel: true
        }
      });
    }
  }


  public saveToFileSystem(response: HttpResponse<Blob>) {
    if (!response || !response.body) return;
    let contentDispositionHeader = response.headers.get('Content-Disposition');
    if (contentDispositionHeader) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matchArray = filenameRegex.exec(contentDispositionHeader);
      const filename = matchArray[1].replace(/"/g, '');
      saveAs(response.body, filename);
    }
  }
}
