import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  public transform(items: any[], key: string, filterCriteria: Function, enabled: boolean) {

    if (!enabled || !filterCriteria) {
      return items;
    }

    return (items || [])
      .filter(
        item => item.hasOwnProperty(key) && filterCriteria(item[key])
      );
  }
}
