import { Component, OnInit, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { MedcorResponse } from '../../../rest/index';
import { IchsGridComponentConfig, GridMultiSelectConfig } from '../grid-configs';

@Component({
    selector: 'app-add-existing',
    templateUrl: './add-existing.component.html',
    styleUrls: ['./add-existing.component.css']
})
export class AddExistingComponent implements OnInit {
    gridConfig: IchsGridComponentConfig<any>;
    multiSelectConfig: GridMultiSelectConfig;
    service: ((Ids: string[]) => Observable<MedcorResponse<boolean>>) | undefined;
    customService: ((Ids: string[]) => boolean) | undefined;
    title: string;

    constructor(public dialogRef: MatDialogRef<AddExistingComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        this.gridConfig = data.config;
        this.service = data.service;
        this.customService = data.customService;
        //make sure that the add existing grid does not have those properties
        this.gridConfig.hasNew = false;
        this.gridConfig.hasExport = false;
        this.gridConfig.addExistingConfig = undefined;
        this.gridConfig.hasDetails = false;
        this.gridConfig.hasVoid = false;
        this.gridConfig.secondAction = undefined;
        this.gridConfig.thirdAction = undefined;
        this.gridConfig.doubleClickAction = undefined;
        this.gridConfig.voidAction = undefined;
        this.gridConfig.hasActionColumn = false;
        this.multiSelectConfig = this.gridConfig.multiSelectConfig!;
        this.title = data.title;
    }

  ngOnInit() {
  }

    //CALL the add existing service to store the IDs in the database
    // if the response s OK then close the popup, otherwise keep it open
  AddExisting() {
      //if nothing is selected then just close the popup and do not refresh the grid
      if (this.multiSelectConfig) {
          if (this.multiSelectConfig.getSelected!().length <= 0) {
              this.dialogRef.close(false);
          }

          if (this.customService) {
              if (this.customService(this.multiSelectConfig.getSelected!())) {
                  this.dialogRef.close(true);
              }
              return;
          }
          if (this.service) {
              let subs = this.service(this.multiSelectConfig.getSelected!()).subscribe(result => {
                  subs.unsubscribe();
                  if (result.statusCode == 200 && result.result == true) {
                      this.dialogRef.close(true);
                  }
              });
          }
          
      }
      
  }
}
