<h2 mat-dialog-title>{{model.title}}</h2>
<form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
  <div class="display-flex" style="padding:10px;">
    <div class="flex-basis-0 flex-grow-1 bold">Data Group</div>
    <ichs-select class="flex-basis-0 flex-grow-2" name="dimesionName" [(ngModel)]="controlsData.gridFilter.dimensionName"
      [items]="controlsData.dimensions" (openedChange)="buildAttributes(!$event)" hasSearch="true">
    </ichs-select>
  </div>
  <div class="display-flex" style="padding:10px;">
    <div class="flex-basis-0 flex-grow-1 bold">Data Item</div>
    <ichs-select [disabled]="!controlsData.gridFilter.dimensionName" class="flex-basis-0 flex-grow-2" name="attributeName"
      [(ngModel)]="controlsData.gridFilter.attributeName" [items]="controlsData.attributes" (openedChange)="buildAttributeValues(!$event)"
      hasSearch="true">
    </ichs-select>
  </div>
  <div class="display-flex" style="padding:10px;">
    <div class="flex-basis-0 flex-grow-1 bold">Data Values</div>
    <ichs-select [disabled]="!controlsData.gridFilter.attributeName" class="flex-basis-0 flex-grow-2" name="attributeValue"
      [(ngModel)]="controlsData.gridFilter.attributeValues" [items]="controlsData.values" multiple="true" hasSelectAll="true"
      hasSearch="true">
    </ichs-select>
  </div>
  <div class="text-right" style="padding:10px; text-align:right;" *ngIf="controlsData.gridFilter.attributeValues">
    <button mat-button class="cp-btn cp-main-btn" (click)="addFilter()" style="width:100px;">
      Add
    </button>
  </div>
  <div style="padding:10px">
    <div style="max-height: 430px; overflow: auto;">
      <div class="display-flex filter-header">
        <div style="width:100px; background-color:Highlight;" class="filter-cell display-flex flex-items-centered">
          Data Object
        </div>
        <div style="width:110px; background-color:Highlight;" class="filter-cell display-flex flex-items-centered">
          Data Item
        </div>
        <div style="background-color:Highlight;" class="flex-basis-0 flex-grow-1 filter-values display-flex flex-items-centered">
          Data Values
        </div>
        <div style="width:85px;background-color:Highlight;" class="filter-cell display-flex flex-items-centered">
          Action
        </div>
      </div>
      <div class="display-flex filter-row" *ngFor="let field of model.gridFilters; let i = index">
        <div style="width:100px" class="filter-cell display-flex flex-items-centered">
          {{field.dimensionName}}
        </div>
        <div style="width:110px" class="filter-cell display-flex flex-items-centered">{{field.attributeName}}</div>
        <div class="flex-basis-0 flex-grow-1 filter-values display-flex flex-items-centered">{{field.attributeValues}}</div>
        <div style="width:85px" class="filter-cell display-flex flex-items-centered">
          <button class="cp-btn cp-mail-btn" type="button" (click)="deleteFieldValue(i)" style="color:red;">Delete</button>
        </div>
      </div>
    </div>
  </div>
</form>

<mat-dialog-actions>
  <div class="flex-basis-0 flex-grow-1 text-right">
    <button mat-button class="cp-btn cp-main-btn" (click)="submit()" cdkFocusInitial>OK</button>
    <button mat-button class="cp-btn cp-mail-btn" (click)="cancel()">Cancel</button>
  </div>
</mat-dialog-actions>