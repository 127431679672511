<div class="display-flex flex-basis-0 flex-grow-1 flex-col">
  <div class="cont-padding border-bottom">
    <ichs-command-toolbar [model]="model" class="display-flex flex-col"></ichs-command-toolbar>
  </div>

  <mat-tab-group mat-align-tabs="start"  mat-stretch-tabs="false" [(selectedIndex)]="model.selectedTab" class="flex-grow-1">
    <mat-tab label="Question Details">
      <form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
        <ichs-collapsable-panel panelTitle="Question Details" style="padding-top:0px">
          <div class="collapsableBody">
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-area type="text" name="name" [(ngModel)]="model.objModel.question" required label="Question" maxlength="2000" rows="5"></ichs-text-area>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-area type="text" name="iconPath" [(ngModel)]="model.objModel.answer" required label="Answer" maxlength="2000" rows="5"></ichs-text-area>
              </div>
            </div>
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box type="text" name="hyperlink" [(ngModel)]="model.objModel.hyperlink" label="Hyperlink" maxlength="400"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child flex-basis-0 flex-grow-1 flex-end">
              </div>
            </div>
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-select type="text" name="lineOfBusiness" [(ngModel)]="model.objModel.lineOfBusiness" required label="Line Of Business"
                             [items]="lineOfBusinessList"></ichs-select>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child flex-basis-0 flex-grow-1 flex-end">
                <ichs-check-box name="internalInd" [(ngModel)]="model.objModel.internalInd" label="Internal Question"></ichs-check-box>
              </div>
            </div>
          </div>
        </ichs-collapsable-panel>
      </form>
    </mat-tab>
  </mat-tab-group>
</div>
