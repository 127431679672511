import { SelectionItemViewModel } from "./rest";
import { LineOfBusiness } from "./app.general.constants";

export class AppGenericLists {
  public static readonly LineOfBusiness: SelectionItemViewModel[] = [
    {
      selectionText: LineOfBusiness.TRIAGE, selectionValue: LineOfBusiness.TRIAGE
    },
    {
      selectionText: LineOfBusiness.OCCUPATIONAL_HEALTH, selectionValue: LineOfBusiness.OCCUPATIONAL_HEALTH
    },
    {
      selectionText: LineOfBusiness.ADVANCED_PRACTICE, selectionValue: LineOfBusiness.ADVANCED_PRACTICE
    },
    {
      selectionText: LineOfBusiness.SCREENING, selectionValue: LineOfBusiness.SCREENING
    },
    {
      selectionText: LineOfBusiness.OSHA_REPORTABLE, selectionValue: LineOfBusiness.OSHA_REPORTABLE
    },
  ];

  public static readonly LineOfBusinessCategory: SelectionItemViewModel[] = [
    {
      selectionText: "All", selectionValue: "All"
    },
    {
      selectionText: "Telehealth", selectionValue: "Telehealth"
    },
    {
      selectionText: "On Site", selectionValue: "OnSite"
    },
  ];

  public static readonly UserStatus: SelectionItemViewModel[] = [
    {
      selectionText: "Active", selectionValue: "Active"
    },
    {
      selectionText: "Inactive", selectionValue: "Inactive"
    },
  ];

  public static readonly SharedFolderPermission: SelectionItemViewModel[] = [
    {
      selectionText: "View", selectionValue: "View"
    },
    {
      selectionText: "Edit", selectionValue: "Edit"
    },
  ]

  public static readonly ReportScheduleMode: SelectionItemViewModel[] = [
    {
      selectionText: "Once", selectionValue: "Once"
    },
    {
      selectionText: "Daily", selectionValue: "Daily"
    },
    {
      selectionText: "Weekly", selectionValue: "Weekly"
    },
    {
      selectionText: "Monthly", selectionValue: "Monthly"
    },
    {
      selectionText: "Quarterly", selectionValue: "Quarterly"
    },
    {
      selectionText: "Yearly", selectionValue: "Yearly"
    }
  ];

  public static readonly ReportFormat: SelectionItemViewModel[] = [
    {
      selectionText: "PDF", selectionValue: "PDF"
    },
    {
      selectionText: "CSV", selectionValue: "CSV"
    },
    {
      selectionText: "EXCEL", selectionValue: "XLSX"
    }
  ];

  public static readonly DashboardExportFormat: SelectionItemViewModel[] = [
    {
      selectionText: "PDF", selectionValue: "PDF"
    },
    {
      selectionText: "PowerPoint", selectionValue: "PPTX"
    },
  ];

  public static readonly ReportScheduleStatus: SelectionItemViewModel[] = [
    {
      selectionText: "Approved", selectionValue: "Approved"
    },
    {
      selectionText: "Pending", selectionValue: "Pending"
    },
    {
      selectionText: "Rejected", selectionValue: "Rejected"
    },
  ]

  public static readonly LogLevels: SelectionItemViewModel[] = [
    {
      selectionText: "Trace",
      selectionValue: "Trace",
    },
    {
      selectionText: "Debug",
      selectionValue: "Debug",
    },
    {
      selectionText: "Info",
      selectionValue: "Info",
    },
    {
      selectionText: "Warn",
      selectionValue: "Warn",
    },
    {
      selectionText: "Error",
      selectionValue: "Error",
    },
    {
      selectionText: "Fatal",
      selectionValue: "Fatal",
    },
  ]

  public static readonly TriagePortalUserPinCodeStatus: SelectionItemViewModel[] = [
    {
      selectionText: "Valid", selectionValue: "Valid"
    },
    {
      selectionText: "Used", selectionValue: "Used"
    },
    {
      selectionText: "Expired", selectionValue: "Expired"
    },
  ]

  public static readonly FileType: SelectionItemViewModel[] = [
    {
      selectionText: "PHI",
      selectionValue: "PHI"
    },
    {
      selectionText: "NON-PHI",
      selectionValue: "NON-PHI"
    },
    {
      selectionText: "Call Recording",
      selectionValue: "CALL_RECORDING"
    },
  ];

  public static readonly TypeOfReport: SelectionItemViewModel[] = [
    {
      selectionText: "N/A",
      selectionValue: "N/A"
    },
    {
      selectionText: "Scheduled",
      selectionValue: "Scheduled"
    },
    {
      selectionText: "On-Demand",
      selectionValue: "On-Demand"
    },
  ];

  public static readonly CountryCode: SelectionItemViewModel[] = [
    {
      selectionText: "United States (+1)",
      selectionValue: "+1"
    },
  ];
}
