import { NgModule } from '@angular/core';
import { ReportingViewerComponent } from './reporting-viewer/reporting-viewer.component';
import { ReportingListComponent } from './reporting-list/reporting-list.component';
import { RouterModule } from '@angular/router';
import { AppModuleShared } from '../app.shared.module';
import { ReportItemCardComponent } from './report-item-card/report-item-card.component';
import { DashboardItemComponent } from './dashboard-item/dashboard-item.component';
import { DashboardItemListComponent } from './dashboard-item-list/dashboard-item-list.component';
import { ReportViewHistoryComponent } from './report-view-history/report-view-history.component';
import { DashboardItemCardComponent } from './dashboard-item-card/dashboard-item-card.component';
import { DashboardItemCardListComponent } from './dashboard-item-card-list/dashboard-item-card-list.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportingSchedulerComponent } from './reporting-scheduler/reporting-scheduler.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ReportingSchedulerListComponent } from './reporting-scheduler-list/reporting-scheduler-list.component';
import { TriageDashboardComponent } from './dashboard/triage-dashboard/triage-dashboard.component';
import { OccupationalHealthDashboardComponent } from './dashboard/occupational-health-dashboard/occupational-health-dashboard.component';
import { AdvancedPracticeDashboardComponent } from './dashboard/advanced-practice-dashboard/advanced-practice-dashboard.component';
import { ReportParametersDialogComponent } from './report-parameters-dialog/report-parameters-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ExecutiveDashboardComponent } from './executive-dashboard/executive-dashboard.component';
import { OshaReportableDashboardComponent } from './dashboard/osha-reportable-dashboard/osha-reportable-dashboard.component';
import { DashboardParametersDialogComponent } from './dashboard-parameters-dialog/dashboard-parameters-dialog.component';
import { DashboardSchedulerComponent } from './dashboard-scheduler/dashboard-scheduler.component';
import { DashboardSchedulerListComponent } from './dashboard-scheduler-list/dashboard-scheduler-list.component';
import { ReportingListDialogComponent } from './reporting-list-dialog/reporting-list-dialog.component';
import { DialogReportItemCardComponent } from './dialog-report-item-card/dialog-report-item-card.component';
import { ManageFutureUseHierarchyComponent } from './report-parameters-dialog/manage-future-use-hierarchy/manage-future-use-hierarchy.component';

@NgModule({
    imports: [
        RouterModule.forChild([
            { path: 'app-reporting', component: ReportingListComponent },
            { path: 'dashboard-item', component: DashboardItemListComponent },
            { path: 'app-reporting-viewer/:id', component: ReportingViewerComponent },
            { path: 'dashboard-item/:id', component: DashboardItemComponent },
            { path: 'report-schedule', component: ReportingSchedulerListComponent },
            { path: 'dashboard-schedule', component: DashboardSchedulerListComponent },
            { path: 'dashboard', component: DashboardComponent },
            { path: 'executive-dashboard', component: ExecutiveDashboardComponent },
        ]),
        AppModuleShared,
        MatStepperModule,
        DragDropModule,
    ],
    declarations: [
        ReportingViewerComponent,
        ReportingListComponent,
        ReportItemCardComponent,
        DashboardItemComponent,
        DashboardItemListComponent,
        ReportViewHistoryComponent,
        DashboardItemCardComponent,
        DashboardItemCardListComponent,
        ReportingSchedulerListComponent,
        DashboardComponent,
        ReportingSchedulerComponent,
        ReportParametersDialogComponent,
        TriageDashboardComponent,
        OccupationalHealthDashboardComponent,
        AdvancedPracticeDashboardComponent,
        OshaReportableDashboardComponent,
        ExecutiveDashboardComponent,
        DashboardParametersDialogComponent,
        DashboardSchedulerComponent,
        DashboardSchedulerListComponent,
        ReportingListDialogComponent,
        DialogReportItemCardComponent,
        ManageFutureUseHierarchyComponent,
    ],
    exports: [
        ReportParametersDialogComponent
    ]
})
export class ReportingModule { }
