import { Component, OnInit } from '@angular/core';
import { AppLogSearchModel, SelectionItemViewModel, SearchViewModel, AppUserService } from '../../rest';
import { IchsGridComponentConfig, IchsControlType, IchsGridActionsConfig } from '../../controls/ichs-grid/grid-configs';
import { AppLogDialogComponent } from '../app-log-dialog/app-log-dialog.component';
import { IchsComponentDialogComponent } from '../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AppGenericLists } from '../../app.general.lists';

@Component({
  selector: 'app-log-list',
  templateUrl: './app-log-list.component.html',
  styleUrls: ['./app-log-list.component.css']
})
export class AppLogListComponent implements OnInit {

  appLogGridConfig: IchsGridComponentConfig<AppLogSearchModel>;
  logsType: string = "All";

  readonly logsTypeSelectionListItems: SelectionItemViewModel[] =
    [
      { selectionText: "All", selectionValue: "All" },
      { selectionText: "Exceptions Only", selectionValue: "Exceptions Only" },
      { selectionText: "Non-Exceptions Only", selectionValue: "Non-Exceptions Only" },
    ];

  constructor(
    private appUserService: AppUserService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.appLogGridConfig = this.defineAppLogGridConfig();
  }

  private defineAppLogGridConfig(): IchsGridComponentConfig<AppLogSearchModel> {
    let configs: IchsGridComponentConfig<AppLogSearchModel> = {
      primaryId: "id",
      defaultOrder: "logTime",
      defaultOrderType: "desc",
      title: null,
      entityController: null,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => {
        if (this.logsType == "Exceptions Only") {
          filter.filterExpression = filter.filterExpression.length > 0 ?
            "isException == true && " + filter.filterExpression : "isException == true";
        }
        else if (this.logsType == "Non-Exceptions Only") {
          filter.filterExpression = filter.filterExpression.length > 0 ?
            "isException == false && " + filter.filterExpression : "isException == false";
        }

        return this.appUserService.searchAllUsersAppLog(filter);
      },
      beforeResetFunction: () => {
        this.logsType = "All";
      },
      detailAction: new IchsGridActionsConfig({
        title: "Show Log Details",
        function: (logObj: AppLogSearchModel) => {
          this.dialog.open(IchsComponentDialogComponent, {
            data: {
              title: "Log Details",
              component: AppLogDialogComponent,
              extraData: logObj,
            },
            width: "1000px"
          });
        },
      }),
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: true,
      hasVoid: false,
      headers: [
        {
          headerTitle: "Log ID",
          propertyName: "logId",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
          width: 300,
        },
        {
          headerTitle: "Log Time",
          propertyName: "logTime",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
          dateFormat: "MM/dd/yyyy h:mm:ss a",
          width: 180,
        },
        {
          headerTitle: "User Email",
          propertyName: "userEmail",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Impersonator Email",
          propertyName: "impersonatorUserEmail",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Level",
          propertyName: "level",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.LogLevels,
          width: 80,
        },
        {
          headerTitle: "Action",
          propertyName: "action",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Entity Name",
          propertyName: "entityName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }
}
