import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AlertMessageType, MedcorAlertService, AlertDismissType } from '../../../services/medcor-alert.service';
import { TriageSecuredFileService } from '../../../rest';
import { first } from 'rxjs/operators';

@Component({
  selector: 'triage-secured-file-pincode-form',
  templateUrl: './triage-secured-file-pincode-form.component.html',
  styleUrls: ['./triage-secured-file-pincode-form.component.css']
})
export class TriageSecuredFilePincodeFormComponent implements OnInit {

  email: string;
  pinCode: string;
  @Input() encryptedData: string;
  @Output() pinCodeSubmitted: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('form') ngForm: NgForm;

  constructor(
    private alertService: MedcorAlertService,
    private triageSecuredFileService: TriageSecuredFileService,
  ) { }

  ngOnInit() {
  }

  submit() {
    if (this.ngForm.controls.email.invalid || this.ngForm.controls.pinCode.invalid) {
      this.ngForm.controls.email.markAsTouched();
      this.ngForm.controls.pinCode.markAsTouched();

      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please enter your email and pin code"],
      });

      return;
    }

    this.triageSecuredFileService.submitPinCode(this.encryptedData, this.email, this.pinCode)
      .pipe(first())
      .subscribe(() => {
        this.pinCodeSubmitted.emit(this.pinCode);
      });
  }
}
