/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface SharedFileViewModel { 
    name?: string;
    /**
     * the type of the file or folder if it is a Main folder attribute or a sub folder attribute
     */
    fileType?: string;
    type?: string;
    path?: string;
    pathUnique?: string;
    folderId?: number;
    mainFolderId?: number;
    expirationDate?: Date;
    id?: number;
    voidId?: number;
    version?: string;
}
