import { NgModule } from "@angular/core";
import { AppModuleShared } from "../app.shared.module";
import { RouterModule } from "@angular/router";
import { ApplicationsAccessComponent } from "./applications-access/applications-access.component";
import { ApplicationsAccessListComponent } from './applications-access-list/applications-access-list.component';
import { UserApplicationAccessComponent } from "./user-application-access/user-application-access.component";

@NgModule({
    exports: [],
    imports: [
        AppModuleShared,
        RouterModule.forChild([
            { path: 'applications-access', component: ApplicationsAccessListComponent },
            { path: 'applications-access/:applicationId', component: ApplicationsAccessComponent },
        ]),
    ],
    declarations: [
        ApplicationsAccessComponent,
        ApplicationsAccessListComponent,
        UserApplicationAccessComponent,
    ],
    providers: []
})
export class ApplicationsAccessModule { }
