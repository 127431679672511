import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ActiveUsersListComponent } from './active-users-list/active-users-list.component';
import { AppModuleShared } from '../app.shared.module';

@NgModule({
    imports: [
        RouterModule.forChild([
            { path: 'active-users', component: ActiveUsersListComponent },
        ]),
        AppModuleShared,
    ],
    declarations: [
        ActiveUsersListComponent,
    ]})
export class ActiveUsersModule {
}
