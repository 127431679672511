import { Component, Inject, Injector, InjectionToken } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export const EXTRA_DATA = new InjectionToken<any>("extraData");
export const DIALOG_REF = new InjectionToken<any>("dialogRef");

@Component({
  selector: 'ichs-component-dialog',
  templateUrl: './ichs-component-dialog.component.html',
  host: { class: "main-theme" },
  styleUrls: ['./ichs-component-dialog.component.css']
})
/** ichs-component-dialog component*/
export class IchsComponentDialogComponent {

  ichsInjector: Injector;

  /** ichs-component-dialog ctor */
  constructor(
    public dialogRef: MatDialogRef<IchsComponentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IchsComponentDialogData,
    injector: Injector,
  ) {
    this.ichsInjector = Injector.create({
      providers: [
        { provide: EXTRA_DATA, useValue: data.extraData },
        { provide: DIALOG_REF, useValue: dialogRef },
      ],
      parent: injector,
    });
  }
}

export class IchsComponentDialogData {
  title?: string;
  component: Component;
  hideCloseButton?: boolean = false;
  extraData?: any;
}