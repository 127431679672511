<div class="label-cont" *ngIf="label">
    <label [attr.for]="identifier">{{label}}</label>
</div>

<div class="display-flex flex-items-centered">
  <div class="flex-cell input-cont flex-basis-0 flex-grow-1">
    <input matInput
           [(ngModel)]="value"
           [owlDateTime]="dt2"
           [owlDateTimeTrigger]="dt2"
           (dateTimeChange)="dateChangedEvent($event)"
           (keydown)="keydownEventHandler($event)"
           [disabled]="isDisabled"
           [placeholder]="datePlaceHolder"
           class="flex-basis-0 flex-grow-1">
  </div>

  <div class="flex-cell">
    <img style="width:25px; height:25px;" class="margin-left-def"  src="assets/images/calendar.svg" [owlDateTimeTrigger]="dt2">
    <owl-date-time [pickerType]="pickerType" [pickerMode]="pickerMode" #dt2></owl-date-time>
  </div>

  <div class="flex-cell">
    <validation-message *ngIf="invalid && model.control.touched" [messages]="failures"></validation-message>
  </div>
</div>

