<div class="change-password">
  <div class="display-flex flex-items-centered">
    <div class="logo-cont"><img src="assets/images/medcorLogo.svg" class="main-logo" /></div>
  </div>
  <div>
    <form #form="ngForm" novalidate>
      <div class="panel-footer">

        <a class="btn btn-default margin-right-def" (click)='redirectToLogin.emit()'>
          <i></i> Go To Login
        </a>
        <a class="btn btn-default" (click)="saveNewPassword()">
          <i></i> Save New Password
        </a>
      </div>

      <div class="collapsableBody margin-bottom-def">
        <div class="display-flex flex-items-centered widget-padding collapse-head">
          <div class="flex-basis-0 flex-grow-1 margin-bottom-def margin-top-def">Change Password</div>
        </div>
        <div class="display-flex break-la">
          <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            <ichs-password name="currentPassword" [(ngModel)]="changePasswordObj.currentPassword" required label="Current Password"></ichs-password>
          </div>
        </div>
        <div class='display-flex break-la'>
          <div class="flex-child flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            <ichs-password #passwordComponent name="newPassword" [(ngModel)]="changePasswordObj.newPassword" required label="New Password"
              passwordStrength></ichs-password>
              <div class="position-relative">
                <div class="password-rules">
                  (At least 12 characters including: capital character, small character, number or symbol.)
                </div>
              </div>
          </div>
        </div>
        <div class='display-flex break-la'>
          <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            <ichs-password name="confirmPassword" [(ngModel)]="confirmPassword" label="Confirm Password" validateEqual
              [relatedControl]="passwordComponent.model.control" required></ichs-password>
          </div>

        </div>
      </div>

    </form>
  </div>

  <div>
    <app-footer></app-footer>
  </div>
</div>