import { Component, OnInit, OnDestroy } from '@angular/core';
import { MedcorSignalRService, ActiveUserModel } from '../../services/medcor-signalr.service';
import { Subscription } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IchsInputDialogComponent, IchsControl, InputDialogConfig, IchsTextBox } from '../../controls/ichs-dialog/ichs-input-dialog/ichs-input-dialog.component';
import { SecureFileService } from '../../rest/index';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'active-users-list',
  templateUrl: './active-users-list.component.html',
  host: { class: "fill-parent" },
  styleUrls: ['./active-users-list.component.css']
})
/** ActiveUsersList component*/
export class ActiveUsersListComponent implements OnInit, OnDestroy {
  activeUsers: ActiveUserModel[] = [];  // Active Users List
  private _activeUsersSubs?: Subscription;  // Active Users Observable Subscribtion

  /** ActiveUsersList ctor */
  constructor(
    private signalRService: MedcorSignalRService,
    private dialog: MatDialog,
    private secureFileService: SecureFileService,
  ) { }

  ngOnInit() {
    this._activeUsersSubs = this.signalRService.activeUsersObs.subscribe(result => {
      // filter new users
      let newUsers = result.filter(user => this.activeUsers.map(obj => obj.id).indexOf(user.id) == -1);

      // active users = current users + new users - removed users (users not in the new result), 
      // this method is used to preserve activeUser.userImage
      this.activeUsers = this.activeUsers.concat(newUsers).filter(obj => result.map(user => user.id).indexOf(obj.id) > -1);

      for (let user of newUsers) {
        let subs: Subscription = this.secureFileService.downloadUserImage(user.id, 'events', true)
          .subscribe(
            {
              next: (event) => {
                if (event.type == HttpEventType.Response) {
                  // if no image for this user, json MedcorResponse will be returned
                  if (event.body.type.toLowerCase() == "application/json") {
                    return;
                  }

                  var reader = new FileReader();
                  reader.onload = (event: any) => {
                    user.userImage = event.target.result;
                  }
                  reader.readAsDataURL(event.body!);
                }
              },
              error: () => subs.unsubscribe(),
              complete: () => subs.unsubscribe(),
            }
          );
      }
    });
  }

  ngOnDestroy() {
    if (this._activeUsersSubs) {
      this._activeUsersSubs.unsubscribe();// unsubscribe for active users change 
    }
  }

  /**
   * Send Message to Active User if Specified
   * else Send Message to All Active Users
   * @param activeUserDetail
   */
  sendMessage(activeUserDetail: ActiveUserModel | null) {
    let messageControl: IchsTextBox = new IchsTextBox({
      label: "Message",
      placeholder: "Write the Message",
      required: true,
    });

    let dialogConfig = new InputDialogConfig({
      title: 'Send Message to ' + (activeUserDetail ? activeUserDetail.name : 'All Online Users'),
      controls: [messageControl]
    });

    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: dialogConfig,
    });

    let sub = dialogRef.afterClosed().subscribe(result => {
      sub.unsubscribe();
      if (result && result.length > 0) {
        let message = result[0].value
        if (message) {
          this.signalRService.sendMessage(activeUserDetail, message);
        }
      }
    });
  }
}
