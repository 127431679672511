<div *ngIf="_configs.title != ''">
  <h1 class="main-title" style="margin-top:2px;">{{_configs.title}}</h1>

</div>
<div class="widget-head widget-top-radius widget-padding display-flex flex-items-centered" *ngIf="_configs">
  <div class="flex-basis-0 flex-grow-1">
    <a *ngIf="_configs.hasNew" class="cp-btn cp-grid-head-btn " [routerLink]="['/'+_configs.entityController,-1]">
      New
    </a>
    <a *ngIf="_configs.customNewAction" class="cp-btn cp-grid-head-btn " (click)="customNew()">
      {{_configs.customNewAction.title}}
    </a>
    <a *ngIf="_configs.addExistingConfig && _configs.hasAddExisting" class="cp-btn cp-grid-head-btn" (click)="addExisting()" style="width:100px">
      {{_configs.addExistingConfig.buttonText}}
    </a>
    <a *ngIf="_configs.hasExport" class="cp-btn cp-grid-head-btn" (click)="export()">
      Export
    </a>
    <a *ngIf="_configs.hasSearch" (click)="clearFilters()" class="cp-btn cp-grid-head-btn">
      Reset Filters
    </a>
    <a *ngIf="_configs.hasSearch" (click)="loadData(0)" class="cp-btn cp-grid-head-btn">
      Search
    </a>
    <a *ngIf="_configs.customNewAction2" class="cp-btn cp-grid-head-btn " (click)="customNew2()">
      {{_configs.customNewAction2.title}}
    </a>
  </div>

  <div *ngIf="_configs.hasVoid || _configs.showVoided">
    <mat-radio-group name="recordStatus_{{uniqueIdentifier}}" [(ngModel)]="recordStatus">
      <mat-radio-button value="active" class="margin-right-def">Active</mat-radio-button>
      <mat-radio-button value="void" class="margin-right-def">Void</mat-radio-button>
      <mat-radio-button value="all">All</mat-radio-button>
    </mat-radio-group>
  </div>
</div>
<ng-scrollbar appearance="compact" class="grid-body flex-grow-1">
  <div class="widget-body flex-grow-1 ">
    <mat-table [dataSource]="dataSource">
      <ng-container matColumnDef="select">
        <mat-header-cell *matHeaderCellDef class="cp-grid-cell-select" >
          <mat-checkbox *ngIf="_configs.multiSelectConfig.hasSelectAllCheckbox" (change)="masterToggle($event.checked)"
                        [checked]="selectedItems.length > 0 && isAllSelected()"
                        [indeterminate]="selectedItems.length > 0 && !isAllSelected()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let row" class="cp-grid-cell-select" >
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="rowSelectionEvent($event.checked,row[_configs.primaryId].toString(),row)"
                        [checked]="rowSelected(row[_configs.primaryId].toString())">
          </mat-checkbox>
        </mat-cell>
      </ng-container>
      <ng-container *ngFor="let col of _configs.headers" matColumnDef="{{col.propertyName}}">

        <mat-header-cell *matHeaderCellDef class="ichs-grid-head-cell" [style.width.px]="col.width" [style.flex]="(col.flex ? col.flex : '1')">
          <div class="flex-child flex-basis-0 flex-grow-1">
            <div>
              <a class="grid-title" [class.sortedColumn]="col.propertyName == sortedColumn" (click)="sortColumn(col)">{{col.headerTitle}} </a>
              <img class="sorted-icon" *ngIf="col.propertyName == sortedColumn" [src]="sortedMode=='desc'?'assets/images/descending.svg':'assets/images/ascending.svg'" />
            </div>
            <ichs-grid-header-field *ngIf="col.searchable" (enterEvent)="loadData(0)" [field-config]="col"></ichs-grid-header-field>
          </div>
        </mat-header-cell>

        <mat-cell [title]="col.titlePropertyName ? obj[col.titlePropertyName] : ''" *matCellDef="let obj" class="ichs-grid-data-cell" [style.width.px]="col.width" [style.flex]="(col.flex ? col.flex : '1')">
          <span class="word-wrap" *ngIf="col.wordWrap">
            {{col.controlType==3 && obj[col.propertyName] ? (col.convertUtcDateToLocal ? (obj[col.propertyName] | amFromUtc | amLocal | date : col.dateFormat : col.dateTimeZone) : (obj[col.propertyName] | date : col.dateFormat : col.dateTimeZone)) : col.controlType==2 ? (obj[col.propertyName] ? 'Yes' : 'No') : obj[col.propertyName]}}
          </span>
          <ng-container *ngIf="!col.wordWrap">
            {{col.controlType==3 && obj[col.propertyName] ? (col.convertUtcDateToLocal ? (obj[col.propertyName] | amFromUtc | amLocal | date : col.dateFormat : col.dateTimeZone) : (obj[col.propertyName] | date : col.dateFormat : col.dateTimeZone)) : col.controlType==2 ? (obj[col.propertyName] ? 'Yes' : 'No') : obj[col.propertyName]}}
          </ng-container>
        </mat-cell>

      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef style="flex:0 1 auto">
          <a *ngIf="_configs.hasDetails" class="cp-grid-btn" style="visibility:hidden">1</a>
          <a *ngIf="_configs.secondAction" class="cp-grid-btn" style="visibility:hidden">2</a>
          <a *ngIf="_configs.thirdAction" class="cp-grid-btn" style="visibility:hidden">3</a>
          <a *ngIf="_configs.fourthAction" class="cp-grid-btn" style="visibility:hidden">4</a>
          <a *ngIf="_configs.fifthAction" class="cp-grid-btn" style="visibility:hidden">5</a>
          <a *ngIf="_configs.hasVoid && _configs.voidAction" class="cp-grid-btn" style="visibility:hidden">R</a>
          <a *ngIf="_configs.hasAdditionalActionsMenu" class="cp-grid-btn" style="visibility:hidden">M</a>
        </mat-header-cell>
        <mat-cell *matCellDef="let obj" style="flex:0 1 auto">
          <a *ngIf="_configs.hasDetails" class="cp-grid-btn" (click)="openDetails(obj)">
            <img [src]="_configs.detailAction && _configs.detailAction.icon ? _configs.detailAction.icon : 'assets/images/details.svg'"
                 [title]="_configs.detailAction && _configs.detailAction.title ? _configs.detailAction.title : 'Details'" />
          </a>

          @if (_configs.secondAction) {
            <a class="cp-grid-btn" [matMenuTriggerFor]="_configs.secondAction.hasMatMenu ? secondActionMatMenu : null" (click)="secondAction(obj)" (menuClosed)="onActionMatMenuClosed(_configs.secondAction)">
              <img [src]="_configs.secondAction.icon" [title]="_configs.secondAction.title ? _configs.secondAction.title : ''" />
            </a>
            <mat-menu #secondActionMatMenu="matMenu">
              @if(_configs.secondAction.matMenuTitle){
                <div class="cp-mat-menu-title">{{ _configs.secondAction.matMenuTitle }}</div>
              }

              @if(!_configs.secondAction.matMenuItems || _configs.secondAction.matMenuItems.length == 0){
                <button class="spinner-btn" disabled mat-menu-item [ngClass]="{'ichs-grid-mat-menu-loader' : _configs.secondAction.centralizeMatMenuLoader}">
                  <mat-spinner mode="indeterminate" diameter="24"></mat-spinner>
                </button>
              }

              @for(action of _configs.secondAction.matMenuItems; track $index){
                <button mat-menu-item (click)="action.function(obj)" [class]="action.class">{{action.dynamicName ? action.dynamicName(obj) : action.name}}</button>
              }
            </mat-menu>
          }

          @if (_configs.thirdAction) {
            <a class="cp-grid-btn" [matMenuTriggerFor]="_configs.thirdAction.hasMatMenu ? thirdActionMatMenu : null" (click)="thirdAction(obj)" (menuClosed)="onActionMatMenuClosed(_configs.thirdAction)">
              <img [src]="_configs.thirdAction.icon" [title]="_configs.thirdAction.title ? _configs.thirdAction.title : ''" />
            </a>
            <mat-menu #thirdActionMatMenu="matMenu">
              @if(_configs.thirdAction.matMenuTitle){
                <div class="cp-mat-menu-title">{{ _configs.thirdAction.matMenuTitle }}</div>
              }

              @if(!_configs.thirdAction.matMenuItems || _configs.thirdAction.matMenuItems.length == 0){
                <button class="spinner-btn" disabled mat-menu-item [ngClass]="{'ichs-grid-mat-menu-loader' : _configs.thirdAction.centralizeMatMenuLoader}">
                  <mat-spinner mode="indeterminate" diameter="24"></mat-spinner>
                </button>
              }

              @for(action of _configs.thirdAction.matMenuItems; track $index){
                <button mat-menu-item (click)="action.function(obj)" [class]="action.class">{{action.dynamicName ? action.dynamicName(obj) : action.name}}</button>
              }
            </mat-menu>
          }

          @if (_configs.fourthAction) {
            <a class="cp-grid-btn" [matMenuTriggerFor]="_configs.fourthAction.hasMatMenu ? fourthActionMatMenu : null" (click)="fourthAction(obj)" (menuClosed)="onActionMatMenuClosed(_configs.fourthAction)">
              <img [src]="_configs.fourthAction.icon" [title]="_configs.fourthAction.title ? _configs.fourthAction.title : ''" />
            </a>
            <mat-menu #fourthActionMatMenu="matMenu">
              @if(_configs.fourthAction.matMenuTitle){
                <div class="cp-mat-menu-title">{{ _configs.fourthAction.matMenuTitle }}</div>
              }

              @if(!_configs.fourthAction.matMenuItems || _configs.fourthAction.matMenuItems.length == 0){
                <button class="spinner-btn" disabled mat-menu-item [ngClass]="{'ichs-grid-mat-menu-loader' : _configs.fourthAction.centralizeMatMenuLoader}">
                  <mat-spinner mode="indeterminate" diameter="24"></mat-spinner>
                </button>
              }

              @for(action of _configs.fourthAction.matMenuItems; track $index){
                <button mat-menu-item (click)="action.function(obj)" [class]="action.class">{{action.dynamicName ? action.dynamicName(obj) : action.name}}</button>
              }
            </mat-menu>
          }

          @if (_configs.fifthAction) {
            <a class="cp-grid-btn" [matMenuTriggerFor]="_configs.fifthAction.hasMatMenu ? fifthActionMatMenu : null" (click)="fifthAction(obj)" (menuClosed)="onActionMatMenuClosed(_configs.fifthAction)">
              <img [src]="_configs.fifthAction.icon" [title]="_configs.fifthAction.title ? _configs.fifthAction.title : ''" />
            </a>
            <mat-menu #fifthActionMatMenu="matMenu">
              @if(_configs.fifthAction.matMenuTitle){
                <div class="cp-mat-menu-title">{{ _configs.fifthAction.matMenuTitle }}</div>
              }

              @if(!_configs.fifthAction.matMenuItems || _configs.fifthAction.matMenuItems.length == 0){
                <button class="spinner-btn" disabled mat-menu-item [ngClass]="{'ichs-grid-mat-menu-loader' : _configs.fifthAction.centralizeMatMenuLoader}">
                  <mat-spinner mode="indeterminate" diameter="24"></mat-spinner>
                </button>
              }

              @for(action of _configs.fifthAction.matMenuItems; track $index){
                <button mat-menu-item (click)="action.function(obj)" [class]="action.class">{{action.dynamicName ? action.dynamicName(obj) : action.name}}</button>
              }
            </mat-menu>
          }

          <a *ngIf="_configs.hasVoid && _configs.voidAction" class="cp-grid-btn" [title]="obj.voidId > 0 ? 'Recover' : 'Void'" (click)="voidRecoverRecord(obj)"><img [src]="obj.voidId > 0?'assets/images/recover.svg':'assets/images/void.svg'" /></a>

          <ng-container *ngIf="_configs.hasAdditionalActionsMenu">
            <a class="cp-grid-btn"><img mat-button [matMenuTriggerFor]="additionalActionsMenu" src="assets/images/menu-dots-vertical.svg" title="Additional Actions" /></a>

            <mat-menu #additionalActionsMenu="matMenu">
              <button *ngFor="let action of _configs.additionalActions" mat-menu-item (click)="action.function(obj)">{{action.dynamicName ? action.dynamicName(obj) : action.name}}</button>
            </mat-menu>
          </ng-container>

        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="getDisplayedColumns()"></mat-header-row>
      <mat-row *matRowDef="let myRowData; columns: getDisplayedColumns()" [attr.style]="getRowStyle(myRowData)" [attr.style.background-color]="getRowColor(myRowData)" (dblclick)="doubleClickAction(myRowData)"></mat-row>

    </mat-table>
    <div *ngIf="waitingFullLoad" style="display: flex; justify-content: center; align-items: center; min-height:150px;">
      <mat-progress-spinner color="primary"
                            mode="indeterminate">
      </mat-progress-spinner>
    </div>
  </div>
</ng-scrollbar>

<div class="widget-footer widget-bottom-radius">
  <mat-paginator *ngIf="_configs.hasPagination"
                 [pageSize]="_configs.pageSize"
                 [pageIndex]="pageIndex"
                 [length]="pageLength"
                 [pageSizeOptions]="_configs.pageSizeOptions"
                 (page)="pageChanged($event)">
  </mat-paginator>
</div>
