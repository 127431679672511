<h1 class="center-align">
    <ng-container *ngIf="generalVariables.clientDeviceInfo.isMobile">
        Less then or equal to 500 Pixel width; Mode = Phone
    </ng-container>
    <ng-container *ngIf="generalVariables.clientDeviceInfo.isTablet">
        Less then or equal to 800 Pixel width; Mode = Tablet
    </ng-container>
    <ng-container *ngIf="generalVariables.clientDeviceInfo.isDesktop">
        Greater then 800 Pixel width; Mode = Desktop
    </ng-container>
</h1>