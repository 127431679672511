import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, } from '@angular/forms';

@Directive({
    selector: '[date][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: DateValidator, multi: true }
    ]
})

// date validatior, that validate the date min & max values
export class DateValidator implements Validator {
    @Input() public minDate: string | Date = new Date(1970, 0, 1);
    @Input() public maxDate: string | Date = null;

    validate(control: AbstractControl): ValidationErrors | null {
        // the [required] validator if exist will check this part.
        if (!control.value) {
            return null;
        }

        let value = new Date(control.value);
        let _minDate: Date = null;
        let _maxDate: Date = null;

        if (this.minDate) {
            _minDate = new Date(this.minDate);
        }
        if (this.maxDate) {
            _maxDate = new Date(this.maxDate);
        }

        if (_minDate && value < _minDate) {
            return {
                ["dateMin"]: 'This date is not valid since it is less than the minimum date: ' + _minDate.toLocaleDateString()
            };
        }
        else if (_maxDate && value > _maxDate) {
            return {
                ["dateMax"]: 'This date is not valid since it is greater than the maximum date: ' + _maxDate.toLocaleDateString()
            };
        }

        return null;
    }
}