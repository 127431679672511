<form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
  <div class="display-flex break-la">
    <ng-container *ngIf="isNew">
      <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
        <ichs-advance-search [advanceSearchGridConfig]="userAdvSearchGridConfig" [(ngModel)]="userPermission.userId"
          required name="userAdvSearch" selectedTextAttribute="email" selectedValueAttribute="id" label="Email" class="fill-width">
        </ichs-advance-search>
      </div>
      <div class="flex-col-sep"></div>
    </ng-container>
    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
      <ichs-select name="permissionSelect" label="Permission" placeholder="Permission" required [items]="permissionItems"
        [(ngModel)]="userPermission.permission" class="bl-control"></ichs-select>
    </div>
  </div>
  <div class="display-flex break-la">
    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
    </div>
    <div class="flex-col-sep"></div>
    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
    </div>
  </div>
  <div class="flex-basis-0 flex-grow-1 text-right">
    <button mat-button class="cp-btn cp-main-btn" (click)='save()'>Save</button>
    <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">Cancel</button>
  </div>
</form>