<div class="cont-padding" *ngIf="!showExternalPanel">
  <form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1" (keydown.enter)="$event.preventDefault()">
    <div class="email-cont-title">{{isForwardMessage?'Forward Email':'Compose New Email'}} </div>
    <div class="display-flex flex-items-centered" (drop)="drop($event)" (dragover)="allowDrop($event)">
      <div class="flex-child">
        <div class="flex-child email-field-title"><label>To</label></div>
      </div>
      <div class="flex-child flex-basis-0 flex-grow-1">
        <div class="input-cont">
          <input #emailInput class="to-textbox" autocomplete="off" type="email" matInput name="contact" [(ngModel)]="enteredEmails"
            placeholder="Enter Email or Drag Drop Contacts from the right list" (ngModelChange)="emailModelChanged.next($event)"
            (keyup.space)="validateNewEmail()" (keyup.enter)="validateNewEmail()" (blur)="validateNewEmail()"
            [matAutocomplete]="auto">

          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)">
            <mat-option *ngFor="let userEmail of userEmails" [value]="userEmail" [title]="userEmail">
              {{userEmail}}
            </mat-option>
          </mat-autocomplete>
        </div>
      </div>
    </div>
    <div class="display-flex flex-items-centered flex-child" *ngIf="emailBody.to.length > 0">
      <div class="email-field-title"></div>
      <div class="flex-basis-0 flex-grow-1 drop-contacts-area" (drop)="drop($event)" (dragover)="allowDrop($event)" >
        <mat-chip-listbox>
          <mat-chip-option [selectable]="false" [removable]="true" *ngFor="let contact of emailBody.to">
            {{contact}}
            <button class="cp-btn cp-chip-btn" (click)="removeContact(contact)"><img class="remove-chip-icon" src="assets/images/delete-cross.svg" /></button>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </div>

    <div class="display-flex flex-items-centered" *ngIf="externalEmails.length > 0">
      <div class="flex-child email-field-title"></div>
      <div class="flex-child flex-basis-0 flex-grow-1 break-sm">
        <b>For security reasons the following recipient(s); <i>{{externalEmails.join(", ")}}</i>, is/are set up as One Time users and are not included in the Reply all.</b>
      </div>
    </div>

    <div class="display-flex flex-items-centered">
      <div class="flex-child email-field-title"><label>Subject</label></div>
      <div class="flex-child flex-basis-0 flex-grow-1 break-sm">
        <ichs-text-box name="title" [(ngModel)]="emailBody.title" maxlength="500" required></ichs-text-box>
      </div>
    </div>

    <div class="flex-child  display-flex flex-items-centered">
      <div class="email-field-title">
      </div>
      <div class="flex-basis-0 flex-grow-1 attachment-area" (fileOver)="fileOver = $event" ng2FileDrop [uploader]="uploader" (onFileDrop)="dropFiles()" [ngClass]="{'file-over':fileOver}">
        <div class="display-flex flex-items-centered">
          <div>
            <button class="cp-btn cp-mail-btn cp-mail-btn-with-icon" (click)="fileInput.click()">
              <div class="display-flex flex-items-centered">
                <img class="cp-btn-icon attach-icon" src="assets/images/newIcons/attachComputer.svg" />
                <div>Attach from My Computer</div>
              </div>
            </button>
          </div>
          <div><div class="vertical-separator display-inline-block" style="margin-top:4px;margin-bottom:4px;"></div></div>
          <div>
            <button class="cp-btn cp-mail-btn cp-mail-btn-with-icon" (click)="showUserFiles()">
              <div class="display-flex flex-items-centered">
                <img src="assets/images/newIcons/attachMyfiles.svg" class="attach-icon cp-btn-icon" />
                <div>
                  Attach from My Files
                </div>
              </div>
            </button>
          </div>
          <div style="margin-left:4px;">
            <span *ngIf="emailBody.attachments && emailBody.attachments.length == 0"> - Or drag drop file to attach!</span>
            <span class="max-file-size">&nbsp;(Maximum File Size 50 MB (50,000 KB))</span>
          </div>
        </div>

        <mat-chip-listbox>
          <mat-chip-option [selectable]="false" [removable]="true" *ngFor="let file of emailBody.attachments">
            {{file.fileName}}
            <button class="cp-btn cp-chip-btn" (click)="removeFile(file)"><img class="remove-chip-icon" src="assets/images/delete-cross.svg" /></button>
          </mat-chip-option>
        </mat-chip-listbox>
      </div>
      <input (onFileSelected)="uploadSelectedFiles()" name="attachments" type="file" #fileInput ng2FileSelect [uploader]="uploader" multiple style="display:none" />
    </div>

    <div class="display-flex">
      <div class="flex-child email-field-title"></div>
      <div class="flex-child input-cont flex-basis-0 flex-grow-1">
        <quill-editor #richBody="ngModel" name="richBody" [(ngModel)]="emailBody.body" [styles]="{'height': '250px','background':'white'}"></quill-editor>
      </div>
    </div>

    <div class="display-flex flex-items-centered">
      <div class="flex-child flex-basis-0 flex-grow-1 break-sm"></div>

      <ng-container *ngIf="isActiveDirectoryUser">
        <div class="flex-child">
          <label>Receive an email when this message is read</label>
        </div>
        <div class="flex-child notify-when-open">
          <ichs-check-box name="notifyWhenOpen" [(ngModel)]="emailBody.notifyWhenOpen"></ichs-check-box>
        </div>
      </ng-container>

      <div class="flex-child">
        <button class="cp-btn cp-main-btn" (click)="sendEmail()" *ngIf="!isForwardMessage" [disabled]="isSending">Send</button>
        <button class="cp-btn cp-main-btn" (click)="forwardEmail()" *ngIf="isForwardMessage">Forward</button>
        <button class="cp-btn cp-mail-btn" (click)="cancelSending()">Cancel</button>
      </div>
    </div>

    <div class="display-flex flex-items-centered margin-top-def">
      <div class="email-field-title"></div>
      <div class="flex-basis-0 flex-grow-1"></div>
    </div>
  </form>
</div>

<div class="cont-padding" *ngIf="showExternalPanel">
  <div class="email-cont-title">
    External Emails One Time Access
  </div>
  <div *ngIf="pins.length == 0">
    <div>
      <p class="alert alert-warning">
        The following email address(es) do not have accounts in the system. This email was not sent to them. If you hit
        Generate One Time Access a link will be sent to the user(s) listed and an 8-digit code will be generated for each
        user. You will need to provide this 8-digit code to each user. The system will ask the user to enter the PIN for
        one-time access to this single email. If you check the box “Invite For Permanent Account” then the user will be
        prompted to create an account after accessing this email.
      </p>
    </div>
    <mat-list>
      <ng-container *ngFor="let extEmail of externalEmailsWithInvitationValues, let i = index">
        <mat-list-item>
          <div class="flex-grow-1">{{extEmail.email}}</div>

          <div class="margin-left-def invite-label">Invite for permanent account</div>
          <mat-checkbox [(ngModel)]="extEmail.inviteForPermanentAccount" id="externalEmail_{{i}}">
          </mat-checkbox>

          <img title="Remove Recipient" class="margin-right-def remove-email-btn" (click)="removeExternalEmail(extEmail)" src="assets/images/delete.svg" />
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-list>
    <button class="cp-btn cp-main-btn margin-top-def" (click)="sendExternalEmails()">Generate One Time Access</button>
    <button class="cp-btn cp-mail-btn margin-top-def" (click)="cancel()">Cancel</button>
  </div>
  <div class="margin-top-def" *ngIf="pins.length > 0">
    <p class="alert alert-warning">
      Please send the PINs via SMS or copy the PIN and send it in a secured connection to each recipient.
    </p>
    <mat-list>
      <ng-container *ngFor="let email of pins">
        <mat-list-item class="display-flex">
          <div class="flex-grow-1 flex-basis-0">{{email.email}} - One-Time Code: {{email.pin}}</div>
          <div>
            <button class="cp-btn cp-mail-btn cp-mail-btn-with-icon" ngxClipboard [cbContent]="email.pin" (cbOnSuccess)="copySucceeded()">
              <div class="display-flex align-items-center">
                <img class="email-action-icon cp-btn-icon" src="assets/images/copy-content.svg" />
                <span class="email-action-text">Copy</span>
              </div>

            </button>
            <button class="cp-btn cp-mail-btn cp-mail-btn-with-icon" (click)="sendSMS(email)">
              <div class="display-flex align-items-center">
                <img class="email-action-icon cp-btn-icon" src="assets/images/phone.svg" />
                <span class="email-action-text">SMS</span>
              </div>

            </button>
          </div>
        </mat-list-item>
        <mat-divider></mat-divider>
      </ng-container>
    </mat-list>
  </div>
</div>
