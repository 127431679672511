/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Reporting parameters types
 */
export type ParameterTypeEnum = 'Boolean' | 'DateTime' | 'Integer' | 'Float' | 'String' | 'Hidden' | 'AdvanceSearch';

export const ParameterTypeEnum = {
    Boolean: 'Boolean' as ParameterTypeEnum,
    DateTime: 'DateTime' as ParameterTypeEnum,
    Integer: 'Integer' as ParameterTypeEnum,
    Float: 'Float' as ParameterTypeEnum,
    String: 'String' as ParameterTypeEnum,
    Hidden: 'Hidden' as ParameterTypeEnum,
    AdvanceSearch: 'AdvanceSearch' as ParameterTypeEnum
};
