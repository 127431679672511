import { NgModule } from '@angular/core';
import { IchsGridComponent } from './ichs-grid/ichs-grid.component';
import { CdkTableModule } from '@angular/cdk/table';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule, MatPaginator } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IchsGridHeaderFieldComponent } from './ichs-grid/ichs-grid-header-field/ichs-grid-header-field.component';
import { IchsSelectComponent } from './ichs-select/ichs-select.component';
import { IchsCollapsablePanelComponent } from './ichs-collapsable-panel/ichs-collapsable-panel.component';
import { DateValidator } from './validation/validators/date-validator';
import { EmailValidator } from './validation/validators/email-validator';
import { IchsTextBoxComponent } from './ichs-text-box/ichs-text-box.component';
import { ValidationMessageComponent } from './validation/validation-message.component';
import { IchsDialogComponent } from '../controls/ichs-dialog/ichs-dialog.component';
import { VoidDialogComponent } from './ichs-dialog/void-dialog/void-dialog.component';
import { IchsTextAreaComponent } from './ichs-text-area/ichs-text-area.component';
import { IchsDatePickerComponent } from './ichs-date-picker/ichs-date-picker.component';
import { IchsCheckBoxComponent } from './ichs-check-box/ichs-check-box.component';
import { IchsPasswordComponent } from './ichs-password/ichs-password.component';
import { EqualValidator } from './validation/validators/equal-validator';
import { PasswordStrengthValidator } from './validation/validators/password-strength-validator';
import { IchsCommandToolbarComponent } from './ichs-command-toolbar/ichs-command-toolbar.component'
import { AddExistingComponent } from './ichs-grid/add-existing/add-existing.component';
import { FileUploadModule } from 'ng2-file-upload';
import { IchsInputDialogComponent } from './ichs-dialog/ichs-input-dialog/ichs-input-dialog.component';
import { AddExistingPopupComponent } from './ichs-dialog/add-existing-popup/add-existing-popup.component';
import { IchsAdvanceSearchComponent } from './ichs-advance-search/ichs-advance-search.component';
import { IchsPowerBIReport } from './ichs-powerbi-report/ichs-powerbi-report.component';
import { IchsDropDownTreeComponent } from './ichs-dropdown-tree/ichs-dropdown-tree.component';
import { DashboardAdvanceFiltersDialogComponent } from './ichs-dialog/dashboard-advance-filters-dialog/dashboard-advance-filters-dialog.component';
import { SharedFiltersHelper } from './ichs-dialog/shared-filters-helper.component';
import { IchsComponentDialogComponent, EXTRA_DATA, DIALOG_REF } from './ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { IchsDateTimePickerComponent } from './ichs-datetime-picker/ichs-datetime-picker.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { IchsTreeDialogComponent } from './ichs-dropdown-tree/ichs-tree-dialog/ichs-tree-dialog.component';
import { SearchPipe } from './ichs-pipes/search.pipe';
import { FilterPipe } from './ichs-pipes/filter.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { IchsCarouselComponent } from './ichs-carousel/ichs-carousel.component';
import { IchsImageCropperComponent } from './ichs-image-cropper/ichs-image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CropperDialogComponent } from './ichs-image-cropper/cropper-dialog/cropper-dialog.component';
import { MomentModule } from 'ngx-moment';
import { IchsHomeTileComponent } from './ichs-home-tile/ichs-home-tile.component';
import { IframeDialogComponent } from './ichs-dialog/iframe-dialog/iframe-dialog.component';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FooterComponent } from './footer/footer.component';
import { IchsGridDialogComponent } from './ichs-grid-dialog/ichs-grid-dialog.component';
import { IchsTreeItemComponent } from './ichs-dropdown-tree/ichs-tree-item/ichs-tree-item.component';
import { PasswordDirective } from './custom-directives/password.directive';
import { IchsHiddenDatePickerComponent } from './ichs-hidden-date-picker/ichs-hidden-date-picker.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { IchsSlideToggleComponent } from './ichs-slide-toggle/ichs-slide-toggle.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { CodeVerificationComponent } from './code-verification/code-verification.component';
// import { NgxMaskDirective } from 'ngx-mask';
// import { NgxMaskPipe } from 'ngx-mask/lib/ngx-mask.pipe';
// import { provideNgxMask } from 'ngx-mask/lib/ngx-mask.providers';

@NgModule({
    declarations: [
        IchsGridComponent,
        IchsGridDialogComponent,
        IchsGridHeaderFieldComponent,
        IchsCollapsablePanelComponent,
        IchsTextBoxComponent,
        IchsSelectComponent,
        ValidationMessageComponent,
        EmailValidator,
        PasswordDirective,
        IchsDialogComponent,
        VoidDialogComponent,
        IchsTextAreaComponent,
        IchsDatePickerComponent,
        DateValidator,
        IchsCheckBoxComponent,
        IchsPasswordComponent,
        EqualValidator,
        PasswordStrengthValidator,
        IchsCommandToolbarComponent,
        AddExistingComponent,
        IchsInputDialogComponent,
        AddExistingPopupComponent,
        IchsAdvanceSearchComponent,
        IchsTreeItemComponent,
        IchsPowerBIReport,
        IchsDropDownTreeComponent,
        DashboardAdvanceFiltersDialogComponent,
        IchsComponentDialogComponent,
        IchsDateTimePickerComponent,
        IchsHiddenDatePickerComponent,
        IchsTreeDialogComponent,
        SearchPipe,
        FilterPipe,
        SafeHtmlPipe,
        IchsCarouselComponent,
        IchsImageCropperComponent,
        CropperDialogComponent,
        IchsHomeTileComponent,
        IframeDialogComponent,
        SafeUrlPipe,
        FooterComponent,
        IchsSlideToggleComponent,
        CodeVerificationComponent,
    ],
    providers: [
        SharedFiltersHelper,
        { provide: EXTRA_DATA, useValue: null },
        { provide: DIALOG_REF, useValue: null },
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        // provideNgxMask(),
    ],
    imports: [
        // NgxMaskDirective,
        // NgxMaskPipe,
        BrowserModule,
        CdkTableModule,
        MatTableModule,
        RouterModule,
        MatPaginatorModule,
        MatRadioModule,
        BrowserAnimationsModule,
        FormsModule,
        MatDialogModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatTabsModule,
        MatProgressSpinnerModule,
        FileUploadModule,
        MatChipsModule,
        MatButtonToggleModule,
        MatBadgeModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        NgScrollbarModule,
        ScrollingModule,
        ImageCropperModule,
        MomentModule,
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatSlideToggleModule,
    ],
    exports: [
        // NgxMaskDirective,
        // NgxMaskPipe,
        IchsTextBoxComponent,
        EmailValidator,
        PasswordDirective,
        IchsGridComponent,
        IchsGridDialogComponent,
        IchsCollapsablePanelComponent,
        IchsDialogComponent,
        VoidDialogComponent,
        IchsSelectComponent,
        IchsTextAreaComponent,
        IchsDatePickerComponent,
        DateValidator,
        IchsCheckBoxComponent,
        IchsPasswordComponent,
        EqualValidator,
        PasswordStrengthValidator,
        IchsCommandToolbarComponent,
        MatTabsModule,
        MatProgressSpinnerModule,
        FileUploadModule,
        MatDialogModule,
        IchsInputDialogComponent,
        MatChipsModule,
        AddExistingPopupComponent,
        MatListModule,
        MatPaginator,
        MatAutocompleteModule,
        MatDividerModule,
        IchsAdvanceSearchComponent,
        IchsPowerBIReport,
        MatMenuModule,
        MatButtonModule,
        IchsDropDownTreeComponent,
        DashboardAdvanceFiltersDialogComponent,
        IchsComponentDialogComponent,
        MatButtonToggleModule,
        MatBadgeModule,
        MatExpansionModule,
        MatFormFieldModule,
        IchsDateTimePickerComponent,
        IchsHiddenDatePickerComponent,
        SearchPipe,
        FilterPipe,
        SafeHtmlPipe,
        IchsCarouselComponent,
        IchsImageCropperComponent,
        IchsHomeTileComponent,
        IframeDialogComponent,
        SafeUrlPipe,
        FooterComponent,
        IchsTreeItemComponent,
        MatCheckboxModule,
        CdkTableModule,
        MatTableModule,
        MatPaginatorModule,
        MatRadioModule,
        BrowserAnimationsModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgScrollbarModule,
        ScrollingModule,
        ImageCropperModule,
        MomentModule,
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        IchsSlideToggleComponent,
        CodeVerificationComponent,
    ]
})
// General Module to hold the different types of custom control for the project.
export class IchsControlsModule {
}
