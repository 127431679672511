import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { MedcorResponse, SharedFolderService, SharedFileService, SharedFolderViewModel, SharedFileViewModel } from '../../../rest';
import { ActivatedRoute, Router } from '@angular/router';
import { InputDialogConfig, IchsControl, IchsTextBox, IchsInputDialogComponent } from '../../../controls/ichs-dialog/ichs-input-dialog/ichs-input-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { APISettings, AppSettings, ValidationMessages, FileTypesEnum } from '../../../app.general.constants';
import { IchsDialogComponent } from '../../../controls/ichs-dialog/ichs-dialog.component';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../../services/medcor-alert.service';
import { MoveSharedFileComponent } from '../move-shared-file/move-shared-file.component';
import { GeneralVariablesService } from '../../../services/general-variables.service';
import { IchsComponentDialogComponent, IchsComponentDialogData } from '../../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { ShareFileComponent } from '../../share-file/share-file.component';
import { SelectFileTypeDialogComponent, SelectFileTypeDialogData } from '../../select-file-type-dialog/select-file-type-dialog.component';
import * as moment from 'moment';
import { SharedFunctionsService } from '../../../services/shared-functions.service';

@Component({
  selector: 'user-shared-folders',
  templateUrl: './user-shared-folders.component.html',
  styleUrls: ['./user-shared-folders.component.css']
})
export class UserSharedFoldersComponent implements OnInit, OnDestroy {

  @ViewChild('fileTrigger', { read: MatMenuTrigger }) protected trigger: MatMenuTrigger;
  @ViewChild('folderTrigger', { read: MatMenuTrigger }) protected folderTrigger: MatMenuTrigger;
  uploader: FileUploader;
  folderId: number | undefined;
  files: SharedFileViewModel[] = null;
  folders: SharedFolderViewModel[] = null;
  dropArea: string | number;
  dropFolderId: string = null;
  sub: Subscription;
  folder: SharedFolderViewModel;
  pathName: string[] = [];
  pathId: string[] = [];
  selectedFiles: number[] = [];
  dialogConfig: InputDialogConfig;
  currentFolderHaveEditPermission: boolean;
  noSharedFolders: boolean;
  fileTypes: string[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private alertService: MedcorAlertService,
    private dialog: MatDialog,
    private sharedFolderService: SharedFolderService,
    private sharedFileService: SharedFileService,
    public generalVariables: GeneralVariablesService,
    private sharedFunctions: SharedFunctionsService,
  ) { }

  ngOnInit() {
    this.dialogConfig = new InputDialogConfig({
      title: 'Create New Folder',
      controls: [
        new IchsTextBox({
          label: "Folder Name",
          placeholder: "Enter folder name here",
          required: true,
          isEmail: false,
          maxLength: 200,
        })
      ]
    });

    var URL: string = APISettings.API_BASE_PATH + "/UploadSharedFile";
    this.uploader = new FileUploader({
      url: URL, authTokenHeader: "Authorization", isHTML5: true, itemAlias: "file", autoUpload: false, maxFileSize: AppSettings.MAX_FILE_SIZE,
    });

    this.uploader.onBuildItemForm = (item, form) => {
      if (this.dropFolderId) {
        form.append("folderId", this.dropFolderId);
      } else if (this.folderId > 1) {
        form.append("folderId", this.folderId);
      }

      this.dropFolderId = null;

      let idx = this.uploader.queue.indexOf(item);
      form.append("type", this.fileTypes[idx]);
    }

    this.uploader.onCompleteAll = () => {
      if (this.dropFolderId == null) {
        this.loadData();
      }

      this.fileTypes = [];
      this.uploader.clearQueue();
    }

    this.uploader.onCompleteItem = ((_item, _response, _status, _headers): any => {
      let response: MedcorResponse<any> = JSON.parse(_response);
      this.alertService.handleMedcorResponse(response);
    });

    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      if (filter.name == "fileSize") {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: [ValidationMessages.FILE_SIZE_LIMIT_EXCEEDED]
        });
      }
    }

    this.sub = this.route.paramMap.subscribe(params => {
      this.folderId = +params.get('folderId');
      this.folderId = !this.folderId ? 1 : this.folderId;
      this.loadData();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  loadData() {
    // reset old values first
    this.files = null;
    this.folders = null;
    if (this.folderId && this.folderId != 1) {
      this.sharedFolderService.getFolderContents(this.folderId).pipe(first()).subscribe(result => {
        this.folder = result.result!;
        this.files = result.result!.filesList;
        this.folders = result.result!.foldersList;

        this.pathId = this.folder.pathUnique.split("/");
        this.pathName = this.folder.path.split("/");

        // remove Root
        this.pathId.shift();
        this.pathName.shift();

        this.currentFolderHaveEditPermission = result.result!.haveEditPermission;
      });
    }
    else {
      this.sharedFolderService.listSharedFolders().pipe(first()).subscribe(result => {
        this.folder = null;
        this.files = [];
        this.folders = result.result!;
        ///if the user has access to only one shared folder, then navigate to it directly
        if (this.folders.length == 1) {
          this.routeToFolder(this.folders[0].id);
        }
        this.noSharedFolders = this.folders.length == 0;

        this.pathName = [];
        this.pathId = [];
      });
      this.currentFolderHaveEditPermission = false;
    }
  }

  createNewFolder() {
    this.dialogConfig.controls![0].value = "";  //reset the old name
    this.dialogConfig.allowClose = (controls: IchsControl[]) => this.validateFolderNameForSlashes(controls);

    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: this.dialogConfig,
      width: "400px",
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result && result.length > 0) {
        let folder: SharedFolderViewModel = {
          name: result[0].value!,
          parentId: this.folderId,
          version: ""
        };
        this.sharedFolderService.post(folder).pipe(first()).subscribe(() => {
          this.loadData();
        });
      }
    });
  }

  areaFileOver(event: boolean, folderId: string | number) {
    if (this.generalVariables.userImpersonationInfo.impersonatorRoleInd) {
      return;
    }

    if (event) {
      this.dropArea = folderId;
    } else {
      this.dropArea = null;
    }
  }

  routeToFolder(id: number) {
    this.router.navigate(["./user-shared-folders/" + id]);
  }

  dropFolder(folder: SharedFolderViewModel) {

    if (this.generalVariables.userImpersonationInfo.impersonatorRoleInd) {
      return;
    }

    if (folder) {
      // if you are inside a folder that have edit permission OR inside root, but pointing at a folder that have edit permission
      if (this.currentFolderHaveEditPermission || folder.haveEditPermission) {
        this.dropFolderId = folder.id.toString();
      }
      else {
        this.uploader.clearQueue();
        return;
      }
    } else {
      if (this.currentFolderHaveEditPermission) {
        this.dropFolderId = null;
      }
      else {
        this.uploader.clearQueue();
        return;
      }
    }

    Array.prototype.forEach.call(this.uploader.queue, (fileItem: FileItem) => {
      if (fileItem._file.size > AppSettings.MAX_FILE_SIZE) {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.auto,
          messages: [ValidationMessages.FILE_SIZE_LIMIT_EXCEEDED]
        });

        this.uploader.removeFromQueue(fileItem);
      }
      else if (!this.sharedFunctions.isSupportedFileType(fileItem._file)) {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.auto,
          messages: [ValidationMessages.INVALID_FILETYPE]
        });


        this.uploader.removeFromQueue(fileItem);
      }
      else {
        this.fileTypes.push(undefined);
      }
    });

    if (this.uploader.queue.length > 0) {
      let dialogRef = this.dialog.open(IchsComponentDialogComponent, {
        disableClose: true,
        width: '700px',
        data: {
          component: SelectFileTypeDialogComponent,
          title: "Select Type(s)",
          hideCloseButton: true,
          extraData: <SelectFileTypeDialogData>{
            fileItems: this.uploader.queue,
            fileTypes: this.fileTypes,
          },
        }
      });

      dialogRef.afterClosed().pipe(first()).subscribe(result => {
        if (result) {
          this.uploadFiles();
        }
        else {
          this.fileTypes = [];
          this.uploader.clearQueue();
        }
      });
    }
  }

  downloadFile(id: number) {
    this.trigger.closeMenu();
    let sub = this.sharedFileService.downloadSharedFile(id, 'events', true)
      .subscribe(
        event => {
          if (event.type == HttpEventType.Response) {
            this.sharedFunctions.saveToFileSystem(event);
          }
        },
        () => { sub.unsubscribe() },
        () => { sub.unsubscribe() });
  }

  viewFile(id: number) {
    this.trigger.closeMenu();
    let sub = this.sharedFileService.downloadSharedFile(id, 'events', true)
      .subscribe(
        event => {
          if (event.type == HttpEventType.Response) {
            if (this.sharedFunctions.isViewableFile(event.body.type)) {
              var fileURL = URL.createObjectURL(event.body);
              window.open(fileURL);
            }
            else {
              this.sharedFunctions.saveToFileSystem(event);
            }
          }
        },
        () => { sub.unsubscribe() },
        () => { sub.unsubscribe() });
  }

  deleteFile(id: number) {
    this.trigger.closeMenu();
    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Delete File ", message: "Are you sure you want to delete this file?" }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.sharedFileService.deleteSharedFile(id).pipe(first()).subscribe(obj => {
          if (obj.result) {
            this.loadData();
          }
        });
      }
    });
  }

  renameFolder(folder: SharedFolderViewModel) {

    let folderRenamingDialogConfig = new InputDialogConfig({
      title: 'Rename Folder',
      controls: [
        new IchsTextBox({
          label: "Folder Name",
          placeholder: "Enter folder name here",
          required: true,
          isEmail: false,
          value: folder.name,
          maxLength: 200,
        })
      ],
      allowClose: (controls: IchsControl[]) => this.validateFolderNameForSlashes(controls),
    });

    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: folderRenamingDialogConfig,
      width: "400px",
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result && result.length > 0) {
        // copy "folder" object so that folder name in the ui won't change in case rename failed
        let folderToRename: SharedFolderViewModel = Object.assign({}, folder)
        folderToRename.name = result[0].value!;
        this.sharedFolderService.post(folderToRename).pipe(first()).subscribe(() => {
          this.loadData();
        });
      }
    });
  }

  deleteFolder(folder: SharedFolderViewModel) {
    this.folderTrigger.closeMenu();
    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Delete Folder ", message: "Are you sure about deleting this Folder?" }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.sharedFolderService._delete(folder.id, folder.version).pipe(first()).subscribe(() => {
          this.loadData();
        });
      }
    });
  }

  selectFile(id: number) {
    var index = this.selectedFiles.indexOf(id);
    if (index > -1) {
      this.selectedFiles.splice(index, 1);
    } else {
      this.selectedFiles.push(id);
    }
  }

  onFileSelected(event: any) {
    var files = event.srcElement.files as File[];

    let _files: File[] = [];
    Array.prototype.forEach.call(files, (file: File) => {
      if (file.size > AppSettings.MAX_FILE_SIZE) {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.auto,
          messages: [ValidationMessages.FILE_SIZE_LIMIT_EXCEEDED]
        });
      }
      else if (!this.sharedFunctions.isSupportedFileType(file)) {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.auto,
          messages: [ValidationMessages.INVALID_FILETYPE]
        });
      }
      else {
        this.fileTypes.push(undefined);
        _files.push(file);
      }
    });

    if (_files.length > 0) {
      this.uploader.addToQueue(_files);

      let dialogRef = this.dialog.open(IchsComponentDialogComponent, {
        disableClose: true,
        width: '700px',
        data: {
          component: SelectFileTypeDialogComponent,
          title: "Select Type(s)",
          hideCloseButton: true,
          extraData: <SelectFileTypeDialogData>{
            fileItems: this.uploader.queue,
            fileTypes: this.fileTypes,
          },
        }
      });

      dialogRef.afterClosed().pipe(first()).subscribe(result => {
        if (result) {
          this.uploadFiles();
        }
        else {
          this.fileTypes = [];
          this.uploader.clearQueue();
        }
      });
    }
  }

  uploadFiles() {
    this.uploader.uploadAll();
  }

  renameFile(file: SharedFileViewModel) {
    this.trigger.closeMenu();
    let renameDialogConfig: InputDialogConfig;
    let renameControls: IchsControl[] = [];

    let fileNewName: IchsTextBox = new IchsTextBox({
      required: true,
      value: file.name
    });

    renameControls.push(fileNewName);
    renameDialogConfig = new InputDialogConfig({
      hasTitle: false,
      message: "Please type in the new file name",
      controls: renameControls,
    });

    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: renameDialogConfig,
      width: "400px",
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        file.name = result[0].value;
        this.sharedFileService.renameSharedFile(file).pipe(first()).subscribe(returnVal => {
          if (returnVal && returnVal.result) {
            this.loadData();
          }
        })
      }
    });
  }

  shareFile(file: SharedFileViewModel) {
    this.dialog.open(IchsComponentDialogComponent, {
      disableClose: true,
      width: '650px',
      data: <IchsComponentDialogData>{
        component: ShareFileComponent,
        title: "Share File",
        hideCloseButton: true,
        extraData: file,
      }
    });
  }

  durationFromNow(expirationDate: Date): string {
    var now = moment.utc();
    var end = moment.utc(expirationDate);
    var duration = moment.duration(end.diff(now));

    let _durationStr: string = null;

    if (duration.asDays() > 1) {
      _durationStr = Math.ceil(duration.asDays()) + " days";
    }
    else if (duration.asHours() > 11) {
      _durationStr = "less than a day";
    }
    else if (duration.asHours() > 1) {
      _durationStr = "few hours";
    }
    else {
      _durationStr = "less than an hour";
    }

    return _durationStr;
  }

  isCallRecording(file: SharedFileViewModel) {
    return file.type == FileTypesEnum.CALL_RECORDING;
  }

  isPhiFile(file: SharedFileViewModel) {
    return file.type == FileTypesEnum.PHI;
  }

  private validateFolderNameForSlashes(controls: IchsControl[]): boolean {
    if (controls![0].value.indexOf('/') > -1) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Folder name cannot contain slashes!"]
      });
      return false;
    }

    return true;
  }
}
