<div class="display-flex flex-grow-1 login-container flex-items-centered">
  <div class="login-content flex-basis-0 flex-grow-1">
    <div class="display-flex flex-items-centered">
      <div class="logo-cont"><img src="assets/images/clientPortalLogo.svg" /></div>
      <div><span class="app-name">Client Portal</span></div>
    </div>
    <div>
      <hr class="client-portal-hr" />
    </div>
    <form #form="ngForm" novalidate>
      <div>
        <div class="label-cont">
          <label>Email</label>
        </div>
        <div class="input-cont flex-basis-0 flex-grow-1">
          <ichs-text-box required name="email" [(ngModel)]="email" initialFocus="true"></ichs-text-box>
        </div>
      </div>
      <div class="margin-top-def">
        <div class="label-cont">
          <label>PIN Code</label>
        </div>
        <div class="input-cont flex-basis-0 flex-grow-1">
          <ichs-text-box required name="pinCode" [(ngModel)]="pinCode"></ichs-text-box>
        </div>
      </div>
      <div class="flex-child" style="text-align:right;"><button (click)="submit()">Submit</button></div>
    </form>
  </div>
</div>