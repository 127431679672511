import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NotificationViewModel, NotificationService, NotificationModel } from '../../rest';
import { IchsDialogComponent } from '../../controls/ichs-dialog/ichs-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';

@Component({
  selector: 'user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.css']
})
export class UserNotificationsComponent implements OnInit {

  @Input() notifications: NotificationModel[] = [];
  @Output() notificationOpened: EventEmitter<NotificationModel> = new EventEmitter<NotificationModel>();

  constructor(
    private notificationService: NotificationService,
    private dialog: MatDialog,
  ) { }

  ngOnInit() { }

  openNotification(notification: NotificationModel) {
    // emit event to close notifications list
    this.notificationOpened.emit(notification);

    // mark notification as read
    notification.isRead = true;
    this.notificationService.updateNotificationReadStatus(notification.id, true).pipe(first()).subscribe();

    // open notification in a popup
    this.dialog.open(IchsDialogComponent, {
      disableClose: true,
      width: '450px',
      data: {
        title: "Announcement",
        message: notification.description,
        hideCancel: true,
      }
    });
  }
}
