import { Component, OnInit, ViewChild, Optional, Inject, Input } from '@angular/core';
import { NgModel, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlBase } from '../control-base';

@Component({
    selector: 'ichs-text-area',
    templateUrl: './ichs-text-area.component.html',
    styleUrls: ['./ichs-text-area.component.css'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: IchsTextAreaComponent,
        multi: true,
    }]
})
//Text area control
export class IchsTextAreaComponent extends ControlBase<string> implements OnInit {
    @ViewChild(NgModel, { static: true }) model: NgModel;
    @Input("disabled") disabled: boolean;
    @Input() placeholder: string = "";
    @Input("label") label: string;
    @Input("rows") rows: number = 3;

    public identifier = `ichs-text-area-${textAreaIdentifier++}`;

    ngOnInit() {
        super.ngOnInit();
    }
}

let textAreaIdentifier = 0;
