<div class="display-flex flex-basis-0 flex-grow-1 flex-col">

  <div class="cont-padding border-bottom">
    <ichs-command-toolbar [model]="model" class="display-flex flex-col"></ichs-command-toolbar>
  </div>

  <mat-tab-group mat-align-tabs="start"  mat-stretch-tabs="false"[(selectedIndex)]="model.selectedTab" class="flex-grow-1">
    <mat-tab label="Shared Folder Details">
      <ichs-collapsable-panel panelTitle="Shared Folder Details" style="padding-top:0px">
        <form #form="ngForm" novalidate class="collapsableBody flex-basis-0 flex-grow-1">
          <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            <ichs-text-box label="Name" name="name" [(ngModel)]="model.objModel.name" initialFocus="true" maxlength="200" required></ichs-text-box>
          </div>
        </form>
      </ichs-collapsable-panel>
    </mat-tab>

    <mat-tab label="Shared Folder User Access" [disabled]="model.objModelId == -1">
      <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab has-command">
        <ichs-grid [grid-config]="gridConfigAppUser" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
      </div>
    </mat-tab>
  </mat-tab-group>

</div>
