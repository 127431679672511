<div class="cont-padding flex-basis-0 flex-grow-1">
  <div class="border-bottom">
    <ichs-command-toolbar [model]="model"></ichs-command-toolbar>
  </div>
  <form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1 display-grid tabs-cont">
    <mat-tab-group class="user-details-tabs" [(selectedIndex)]="model.selectedTab"
      (selectedTabChange)="onSelectedTabChange($event)">
      <mat-tab label=" User Detail">
        <ichs-collapsable-panel panelTitle="App User Details">
          <div class="collapsableBody">
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [disabled]="true" name="email" [(ngModel)]="model.objModel.email" label="Email">
                </ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>

              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [disabled]="true" type="text" name="firstName" [(ngModel)]="model.objModel.firstName"
                  label="First Name">
                </ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>

              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [disabled]="true" type="text" name="middleName" [(ngModel)]="model.objModel.middleName"
                  label="Middle Initial"></ichs-text-box>
              </div>
            </div>
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [disabled]="true" name="lastName" [(ngModel)]="model.objModel.lastName"
                  label="Last Name"></ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>

              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [disabled]="true" name="roleName" label="Role Name"
                  [(ngModel)]="model.objModel.roleName"></ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>

              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-check-box [disabled]="true" name="ActiveDirectoryUserInd"
                  [(ngModel)]="model.objModel.activeDirectoryUserInd" label="Active Directory"
                  style="align-self:flex-end;"></ichs-check-box>
              </div>
            </div>

            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <ichs-text-box [disabled]="true" name="status" label="Status" [(ngModel)]="model.objModel.status">
                </ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [disabled]="true" [(ngModel)]="model.objModel.clientName" name="ClientName"
                  label="Client Name"></ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <ichs-check-box style="align-self: flex-end;" [disabled]="true" name="phiDataRestriction"
                  [(ngModel)]="model.objModel.phiDataRestrictionInd" label="Block Personal Health Information">
                </ichs-check-box>
              </div>
            </div>

            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <ichs-text-box *ngIf='!model.objModel.activeDirectoryUserInd' [disabled]="true" name="passwordStatus"
                  [ngModel]="model.objModel.passwordStatus" label="Password Status"></ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
              </div>

              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
              </div>
            </div>
          </div>
        </ichs-collapsable-panel>
      </mat-tab>

      <mat-tab [label]="userDataAccessTabLabel" *ngIf="userGroupsDataAccessGrids.length > 0">
        <mat-tab-group mat-align-tabs="start"  mat-stretch-tabs="false" #innerMatTabGroup class="sub-tabs" (selectedIndexChange)="onSelectedIndexChange($event)">
          <mat-tab *ngFor="let item of userGroupsDataAccessGrids" [label]="item.lineOfBusiness">
            <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab">
              <ichs-grid [grid-config]="item.gridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col">
              </ichs-grid>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-tab>

      <mat-tab label="User Contacts">
        <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab has-command">
          <ichs-grid [grid-config]="userContactsGridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col">
          </ichs-grid>
        </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>
