import { Component, OnInit, ViewChild } from '@angular/core';
import { SecuredEmailsService, ExternalEmailPinViewModel, EmailViewModel } from '../../rest/index';
import { NgForm, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AlertMessageType, MedcorAlertService, AlertDismissType } from '../../services/medcor-alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpEventType } from '@angular/common/http';
import { AppSettings, ValidationMessages } from '../../app.general.constants';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Component({
  selector: 'email-external-view',
  templateUrl: './email-external-view.component.html',
  styleUrls: ['./email-external-view.component.css']
})
export class EmailExternalViewComponent implements OnInit {

  model: ExternalEmailPinViewModel = {};
  email: EmailViewModel;
  downloadProgress: number = 0;

  @ViewChild('form') ngForm: NgForm;

  constructor(
    private emailService: SecuredEmailsService,
    private alertService: MedcorAlertService,
    public activatedRoute: ActivatedRoute,
    private sharedFunctions: SharedFunctionsService,
    private router: Router,
  ) {

  }

  ngOnInit() {
    let url = location.href.replace(AppSettings.UI_ORIGIN_PATH, "");
    let pathParts: string[] = url.split("/");
    if (pathParts.length != 4) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Invalid External Link!"]
      });
      return;
    }
    this.model.internalEmailId = pathParts[3];
    this.model.email = pathParts[2];

  }

  viewExternalEmail() {
    this.validateAllFormFields(this.ngForm.form);
    if (!this.ngForm.valid) {// check if form is valid 
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    this.emailService.viewExternalEmails(this.model).subscribe(resp => {
      if (resp) {
        this.email = resp.result!;
      }
    });
  }

  downloadFile(id: number) {
    let sub = this.emailService
      .externalDownloadEmailAttachment(id, this.model, 'events', true)
      .subscribe({
        next: (event) => {
          if (event.type == HttpEventType.Response) {
            this.downloadProgress = 0;
            this.sharedFunctions.saveToFileSystem(event);
          }
          else if (event.type == HttpEventType.DownloadProgress) {
            if (event.total != undefined) {
              this.downloadProgress = (event.loaded / event.total) * 100;
            }
            else {
              this.downloadProgress = 1;
            }
          }
        },
        complete: () => sub.unsubscribe(),
      });
  }

  navigateToCreateAccountPage() {
    this.router.navigate(['create-secure-messaging-account', this.model.email, this.model.internalEmailId, this.model.pin]);
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
