
<div style="width:100%" class="cont-padding">
  <div class="border-bottom">
    <ichs-command-toolbar [model]="model" class="display-flex flex-col"></ichs-command-toolbar>
  </div>

  <mat-tab-group mat-align-tabs="start"  mat-stretch-tabs="false" (selectedTabChange)="customizedChanged($event)">
    <mat-tab label="Default Page Design">
      <ng-template matTabContent>
        <div class="display-flex flex-basis-0 flex-grow-1 flex-col fill-width">
          <form #form="ngForm" novalidate class="flex-grow-1" style="padding-bottom:4px;">
            <div class="display-flex flex-items-centered">
              <h1 class="main-title flex-basis-0 flex-grow-1">Page Design</h1>
              <div>
                <button class="cp-btn cp-mail-btn" name="design" (click)="designMode()">Design</button>
                <button class="cp-btn cp-mail-btn" name="view" (click)="viewMode()">View</button>
              </div>
            </div>
            <hr class="main-hr" />
            <div [hidden]="!designInd" class="margin-top-def">
              <div>
                <ichs-text-area class="main-section" name="top" [(ngModel)]="model.objModel.top" required></ichs-text-area>
              </div>
              <div class="main-page-margin">
                <div class="display-flex">
                  <ichs-text-area class="flex-basis-0 flex-grow-1 page-item" name="BottomLeft" [(ngModel)]="model.objModel.bottomLeft" required></ichs-text-area>
                  <ichs-text-area class="flex-basis-0 flex-grow-1 page-item margin-left-def" name="BottomCentral" [(ngModel)]="model.objModel.bottomCentral" required></ichs-text-area>
                  <ichs-text-area class="flex-basis-0 flex-grow-1 page-item margin-left-def" name="BottomRight" [(ngModel)]="model.objModel.bottomRight" required></ichs-text-area>
                </div>
              </div>
            </div>
            <div [hidden]="designInd">
              <div class="featured-cont">
                <div [innerHTML]="model.objModel.top"></div>
              </div>
              <div class="main-page-margin">
                <div class="display-flex">
                  <div class="flex-basis-0 flex-grow-1 tile-item">
                    <div [innerHTML]="model.objModel.bottomLeft"></div>
                  </div>
                  <div class="flex-basis-0 flex-grow-1 tile-item">
                    <div [innerHTML]="model.objModel.bottomCentral"></div>
                  </div>
                  <div class="flex-basis-0 flex-grow-1 tile-item">
                    <div [innerHTML]="model.objModel.bottomRight"></div>
                  </div>
                </div>
              </div>
            </div>

          </form>

        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Default Triage Page Design">
      <ng-template matTabContent>
        <div class="display-flex flex-basis-0 flex-grow-1 flex-col fill-width">
          <form #form="ngForm" novalidate class="flex-grow-1" style="padding-bottom:4px;">
            <div class="display-flex flex-items-centered">
              <h1 class="main-title flex-basis-0 flex-grow-1">Page Design</h1>
              <div>
                <button class="cp-btn cp-mail-btn" name="design" (click)="designMode()">Design</button>
                <button class="cp-btn cp-mail-btn" name="view" (click)="viewMode()">View</button>
              </div>
            </div>
            <hr class="main-hr" />
            <div [hidden]="!designInd" class="margin-top-def">
              <div>
                <ichs-text-area name="top" [(ngModel)]="model.objModel.top" required class="main-section"></ichs-text-area>
              </div>
              <div class="main-page-margin">
                <div class="display-flex">
                  <ichs-text-area class="flex-basis-0 flex-grow-1 page-item" name="BottomLeft" [(ngModel)]="model.objModel.bottomLeft" required></ichs-text-area>
                  <ichs-text-area class="flex-basis-0 flex-grow-1 page-item" name="BottomCentral" [(ngModel)]="model.objModel.bottomCentral" required></ichs-text-area>
                  <ichs-text-area class="flex-basis-0 flex-grow-1 page-item" name="BottomRight" [(ngModel)]="model.objModel.bottomRight" required></ichs-text-area>
                </div>
              </div>
            </div>
            <div [hidden]="designInd">
              <div class="featured-cont">
                <div [innerHTML]="model.objModel.top"></div>
              </div>
              <div class="main-page-margin">
                <div class="display-flex">
                  <div class="flex-basis-0 flex-grow-1 tile-item">
                    <div [innerHTML]="model.objModel.bottomLeft"></div>
                  </div>
                  <div class="flex-basis-0 flex-grow-1 tile-item">
                    <div [innerHTML]="model.objModel.bottomCentral"></div>
                  </div>
                  <div class="flex-basis-0 flex-grow-1 tile-item">
                    <div [innerHTML]="model.objModel.bottomRight"></div>
                  </div>
                </div>
              </div>
            </div>

          </form>

        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Default Onsite Page Design">
      <ng-template matTabContent>
        <div class="display-flex flex-basis-0 flex-grow-1 flex-col fill-width">
          <form #form="ngForm" novalidate class="flex-grow-1" style="padding-bottom:4px;">
            <div class="display-flex flex-items-centered">
              <h1 class="main-title flex-basis-0 flex-grow-1">Page Design</h1>
              <div>
                <button class="cp-btn cp-mail-btn" name="design" (click)="designMode()">Design</button>
                <button class="cp-btn cp-mail-btn" name="view" (click)="viewMode()">View</button>
              </div>
            </div>
            <hr class="main-hr" />
            <div [hidden]="!designInd" class="margin-top-def">
              <div>
                <ichs-text-area name="top" [(ngModel)]="model.objModel.top" required class="main-section"></ichs-text-area>
              </div>
              <div class="main-page-margin">
                <div class="display-flex">
                  <ichs-text-area class="flex-basis-0 flex-grow-1 page-item" name="BottomLeft" [(ngModel)]="model.objModel.bottomLeft" required></ichs-text-area>
                  <ichs-text-area class="flex-basis-0 flex-grow-1 page-item" name="BottomCentral" [(ngModel)]="model.objModel.bottomCentral" required></ichs-text-area>
                  <ichs-text-area class="flex-basis-0 flex-grow-1 page-item" name="BottomRight" [(ngModel)]="model.objModel.bottomRight" required></ichs-text-area>
                </div>
              </div>
            </div>
            <div [hidden]="designInd">
              <div class="featured-cont">
                <div [innerHTML]="model.objModel.top"></div>
              </div>
              <div class="main-page-margin">
                <div class="display-flex">
                  <div class="flex-basis-0 flex-grow-1 tile-item">
                    <div [innerHTML]="model.objModel.bottomLeft"></div>
                  </div>
                  <div class="flex-basis-0 flex-grow-1 tile-item">
                    <div [innerHTML]="model.objModel.bottomCentral"></div>
                  </div>
                  <div class="flex-basis-0 flex-grow-1 tile-item">
                    <div [innerHTML]="model.objModel.bottomRight"></div>
                  </div>
                </div>
              </div>
            </div>

          </form>

        </div>
      </ng-template>
    </mat-tab>
    <mat-tab label="Custom Page Design">
      <ng-template matTabContent>
        <div class="">
          <form #form="ngForm" novalidate class="flex-grow-1" style="padding-bottom:4px;">
            <div class="display-flex flex-items-centered">
              <h1 class="main-title flex-basis-0 flex-grow-1">Custom Page Design</h1>
              <div>
                <button *ngIf="groupId>0" class="cp-btn cp-mail-btn" name="design" (click)="clearDesign()">Clear Design</button>
                <button class="cp-btn cp-mail-btn" name="design" (click)="designMode()">Design</button>
                <button class="cp-btn cp-mail-btn" name="view" (click)="viewMode()">View</button>
              </div>
            </div>
            <hr class="main-hr" />

            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">

                <ichs-select name="groupName" label="Group Name" [(ngModel)]="groupId"
                             required [items]="groupItems" (selectionChanged)="groupChanged($event)"></ichs-select>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              </div>
            </div>
            <div [hidden]="!designInd" class="margin-top-def">
              <div>
                <ichs-text-area name="top" [(ngModel)]="model.objModel.top" required class="main-section"></ichs-text-area>
              </div>
              <div class="main-page-margin">
                <div class="display-flex">
                  <ichs-text-area class="flex-basis-0 flex-grow-1 page-item" name="BottomLeft" [(ngModel)]="model.objModel.bottomLeft" required></ichs-text-area>
                  <ichs-text-area class="flex-basis-0 flex-grow-1 page-item" name="BottomCentral" [(ngModel)]="model.objModel.bottomCentral" required></ichs-text-area>
                  <ichs-text-area class="flex-basis-0 flex-grow-1 page-item" name="BottomRight" [(ngModel)]="model.objModel.bottomRight" required></ichs-text-area>
                </div>
              </div>
            </div>
            <div [hidden]="designInd">
              <div class="featured-cont">
                <div [innerHTML]="model.objModel.top"></div>
              </div>
              <div class="main-page-margin">
                <div class="display-flex">
                  <div class="flex-basis-0 flex-grow-1 tile-item">
                    <div [innerHTML]="model.objModel.bottomLeft"></div>
                  </div>
                  <div class="flex-basis-0 flex-grow-1 tile-item">
                    <div [innerHTML]="model.objModel.bottomCentral"></div>
                  </div>
                  <div class="flex-basis-0 flex-grow-1 tile-item">
                    <div [innerHTML]="model.objModel.bottomRight"></div>
                  </div>
                </div>
              </div>
            </div>

          </form>

        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
