import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Inject, Injectable, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import { first } from 'rxjs/operators';
import { DIALOG_REF, EXTRA_DATA, IchsComponentDialogComponent } from '../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { CallRecordInfoViewModel, UserSecuredFileService, ServiceRequestService } from '../../rest';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from '../../services/medcor-alert.service';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { ValidationMessages } from '../../app.general.constants';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Injectable()
export class CallRecordRequestData {
  public refNumber: string;
  public email: string;
}

@Component({
  selector: 'send-call-record',
  templateUrl: './send-call-record.component.html',
  styleUrls: ['./send-call-record.component.css']
})
export class SendCallRecordComponent implements OnInit {

  @ViewChild("form") ngForm: NgForm;
  public callRecordInfo: CallRecordInfoViewModel = <CallRecordInfoViewModel>{};
  public email: string;
  public refNumber: string;
  public isSearchDone: boolean = false;
  public disableControls: boolean = false;
  public isFileDownloaded: boolean = false;

  constructor(
    private serviceRequestService: ServiceRequestService,
    private userSecureFileService: UserSecuredFileService,
    private alertService: MedcorAlertService,
    private sharedFunctions: SharedFunctionsService,
    public generalVariables: GeneralVariablesService,
    @Inject(EXTRA_DATA) private callRecordRequestData: CallRecordRequestData,
    @Inject(DIALOG_REF) private dialogRef: MatDialogRef<IchsComponentDialogComponent>,
  ) {
    if (callRecordRequestData) {
      this.refNumber = callRecordRequestData.refNumber;
      this.email = callRecordRequestData.email;
      this.disableControls = true;
      this.getCallRecordInfo();
    }
  }

  ngOnInit() {
  }

  getCallRecordInfo() {
    if (!this.disableControls) {
      if (!this.ngForm.controls.refNumber.valid) {
        this.ngForm.controls.email.markAsUntouched();
        this.ngForm.controls.refNumber.markAsTouched();

        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: [ValidationMessages.INVALID_FORM]
        });
        return;
      }
    }

    this.serviceRequestService.getCallRecordInfo(this.refNumber).pipe(first()).subscribe(res => {
      this.callRecordInfo = res.result;
      this.isSearchDone = true;
    });
  }

  download() {
    this.downloadCallRecording();
  }

  downloadOrSend() {
    if (this.isFileDownloaded) {
      this.sendCallRecording();
    }
    else {
      this.downloadCallRecording();
    }
  }

  downloadCallRecording() {
    let sub = this.userSecureFileService.downloadCallRecording(this.refNumber, 'events', true).subscribe(event => {
      if (event.type == HttpEventType.Response) {
        this.sharedFunctions.saveToFileSystem(event);
        this.isFileDownloaded = true;
      }
    }, () => { sub.unsubscribe() }, () => { sub.unsubscribe() });
  }

  sendCallRecording() {
    if (!this.disableControls) {
      if (!this.ngForm.controls.email.valid) { // check if form is valid 
        this.ngForm.controls.email.markAsTouched();
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: [ValidationMessages.INVALID_FORM]
        });
        return;
      }
    }

    this.userSecureFileService.sendCallRecording(this.refNumber, this.email).pipe(first()).subscribe(result => {
      this.dialogRef.close();
    });
  }

  resetForm() {
    this.callRecordInfo = <CallRecordInfoViewModel>{};
    this.isSearchDone = false;
    this.isFileDownloaded = false;
  }
}
