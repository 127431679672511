<div class='label-cont' *ngIf="label">
  <label [attr.for]="advSearchIdentifier">{{label}}</label>
</div>
<div class="display-flex flex-items-centered">
  <div class='flex-cell input-cont flex-basis-0 flex-grow-1'>
    <input type="text"
           [(ngModel)]="value"
           [id]="advSearchIdentifier"
           autocomplete="off"
           (change)="advSearchChangedEvent($event)"
           style="display:none" />
    <div [title]="selectedText ? selectedText : placeholder" class="btn-tree flex-cell fill-width" (click)="OpenGrid()">
      <span class="advanced-search-text">
        {{(selectedText ? selectedText : placeholder)}}
      </span>
    </div>
  </div>
  <div *ngIf="!disabled" class="flex-cell">
    <img src="assets/images/select.svg" (click)="OpenGrid()" class="advanced-search-icon" />
  </div>
  <div class="flex-cell">
    <validation-message *ngIf="invalid && model.control.touched" [messages]="failures"></validation-message>
  </div>
</div>