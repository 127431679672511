/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface ReportItemViewModel { 
    name: string;
    description?: string;
    subTitle?: string;
    keyWords?: string;
    reportPath: string;
    reportPathId: string;
    thumbnailId: number;
    snapshotId: number;
    isFavorite?: boolean;
    lineOfBusiness: string;
    hasPhiData?: boolean;
    hasHrData?: boolean;
    hasFinanceData?: boolean;
    internalInd?: boolean;
    tag?: string;
    tagTitle?: string;
    exportOptionsList?: Array<string>;
    downloadOnViewer?: boolean;
    downloadFormat?: string;
    id?: number;
    voidId?: number;
    version?: string;
}
