import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'not-supported-browser-popup',
  templateUrl: './not-supported-browser-popup.component.html',
  styleUrls: ['./not-supported-browser-popup.component.css']
})
export class NotSupportedBrowserPopupComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
