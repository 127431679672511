<div class="container-padding">
  <div class="featured-cont">
    <ichs-carousel [items]="items"></ichs-carousel>
    <div [class.temp-height]="items.length == 0" *ngIf="waitingFullLoad" class="text-center">
      <mat-spinner class="loading" color='warn'></mat-spinner>
    </div>
  </div>
  <div class="main-page-margin">
    <div class="display-flex flex-wrap justify-cont-res">
      <ichs-home-tile description="24/7 nationwide telemedicine gives injured workers convenient access to physicians without going offsite for care."
                      label="About Medcor"
                      titleLink="https://www.medcor.com/about/"
                      imgSrc="assets/images/preLogin/Medcor_ClientPortal_H2.jpg"
                      imgLink="https://www.youtube.com/embed/-RmWaGLMXI4">
      </ichs-home-tile>

      <ichs-home-tile description="Medcor provides health navigation and clinical services to improve outcomes, service levels, and access to care, while reducing costs"
                      label="New Service!"
                      titleLink="https://www.medcortelemed.com"
                      imgSrc="assets/images/preLogin/Medcor_ClientPortal_H3.jpg"
                      imgLink="https://www.medcortelemed.com">
      </ichs-home-tile>

      <ichs-home-tile description="Insights from four guiding health principles that can help us be healthy and happy."
                      label="Health Tips"
                      titleLink="https://www.medcor.com/category/health-tips/"
                      imgSrc="assets/images/preLogin/Medcor_ClientPortal_H4.jpg"
                      imgLink="https://www.medcor.com/category/health-tips/">
      </ichs-home-tile>

      <ichs-home-tile description="New way to login"
                      label="Microsoft 365 Login"
                      titleLink="https://player.vimeo.com/video/719877693?h=705997b400&color=ffffff&title=0&byline=0&portrait=0"
                      imgSrc="assets/images/CP-Login.png"
                      imgLink="https://player.vimeo.com/video/719877693?h=705997b400&color=ffffff&title=0&byline=0&portrait=0"
                      dialogWidth="1200px">
      </ichs-home-tile>

    </div>
  </div>

</div>
