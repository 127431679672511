import { Component, OnInit } from '@angular/core';
import { PageDesignService, PageDesignViewModel, GroupAppUserAssociationService } from '../../rest';
import { first } from 'rxjs/operators';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { MatDialog } from '@angular/material/dialog';
import { IframeDialogComponent } from 'src/app/controls/ichs-dialog/iframe-dialog/iframe-dialog.component';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {
  homePage: PageDesignViewModel;

  topLink: string;
  leftLink: string;
  centerLink: string;
  rightLink: string;

  groupIds: number[] = [];
  currentId: number = 0;
  constructor(private generalVariables: GeneralVariablesService,
    private pageDesignService: PageDesignService,
    private groupAssociationService: GroupAppUserAssociationService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.groupAssociationService.getAssociatedGroupIds().pipe(first()).subscribe(result => {
      this.groupIds = result.result;
      if (this.groupIds.length > 0) {
        this.loadNextDesign();
      } else {
        this.loadDesign(0);
      }
    });
  }

  loadDesign(id) {
    this.pageDesignService.getUserPageDesign(id).pipe(first()).subscribe(result => {
      this.homePage = result.result;
      this.extractLink("top", "topLink");
      this.extractLink("bottomLeft", "leftLink");
      this.extractLink("bottomCentral", "centerLink");
      this.extractLink("bottomRight", "rightLink");
    });
  }

  extractLink(source: string, variable: string) {
    if (this.homePage[source].startsWith("###")) {
      var lines = this.homePage[source].split("\n");
      this[variable] = lines.splice(0, 1)[0].split("###")[1];
      this.homePage[source] = lines.join("\n");
    }
  }

  canDeactivate() {
    if (this.generalVariables.firstCheck) {
      this.generalVariables.firstCheck = false;
      this.generalVariables.appViewModel.contactsIsHidden = true;
    }
    return true;
  }

  loadNextDesign() {
    if (this.groupIds.length > 0) {
      this.loadDesign(this.groupIds[this.currentId]);
      this.currentId = (this.currentId + 1) % this.groupIds.length;
    }
  }

  linkClicked(event: any, link: string, isTopLink: boolean = false) {
    event.stopPropagation();

    // if user clicked on a link inside innerHTML, don't open the iframe
    if (event.target.tagName.toLowerCase() == 'a') {
      return;
    }

    if (!link || link.length == 0) {
      return;
    }

    if (isTopLink) {
      this.dialog.open(IframeDialogComponent,
        {
          data: { title: "", link: link, height: 820, width: 1450 },
          height: "905px",
          width: "1520px",
        });
    }
    else {
      this.dialog.open(IframeDialogComponent,
        {
          data: { title: "", link: link },
          height: "780px"
        });
    }
  }
}
