<div *ngIf="!isEditMode">
  <div class="prof-user-info">
    <div class="position-relative" *ngIf="!generalVariables.userImpersonationInfo.impersonatorRoleInd">
      <div class="position-absolute" style="top:-32px; right:-24px;">
        <button class="cp-btn cp-edit-btn" (click)="edit()">
          <div class="display-flex flex-items-centered">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 40"
                style="width:24px; height:24px;">
                <defs>
                  <style>
                    .cls-3 {
                      fill: rgba(19,17,17,0.6);
                    }
                  </style>

                </defs>
                <g id="edit">
                  <g id="edit-2" data-name="edit" transform="translate(-302 -520)">
                    <g id="edit-3" data-name="edit" transform="translate(304.899 521.32)">
                      <path id="Path_4916" data-name="Path 4916" class="cls-3" d="M32.4,57.687a.874.874,0,0,0-.874.874v7.762A2.625,2.625,0,0,1,28.9,68.946H4.371a2.625,2.625,0,0,1-2.623-2.623V43.543A2.626,2.626,0,0,1,4.371,40.92h7.762a.874.874,0,1,0,0-1.748H4.371A4.376,4.376,0,0,0,0,43.543V66.324a4.376,4.376,0,0,0,4.371,4.371H28.9a4.376,4.376,0,0,0,4.371-4.371V58.562a.874.874,0,0,0-.874-.874Zm0,0"
                        transform="translate(0 -35.51)" />
                      <path id="Path_4917" data-name="Path 4917" class="cls-3" d="M131.657,1.413a3.934,3.934,0,0,0-5.563,0l-15.6,15.6a.874.874,0,0,0-.224.385l-2.051,7.4a.874.874,0,0,0,1.076,1.076l7.4-2.051a.874.874,0,0,0,.385-.224L132.682,8a3.938,3.938,0,0,0,0-5.563ZM112.4,17.577,125.166,4.813l4.117,4.117L116.518,21.694Zm-.822,1.65,3.289,3.289-4.549,1.26ZM131.446,6.766l-.927.927L126.4,3.576l.927-.927a2.185,2.185,0,0,1,3.09,0l1.026,1.026A2.189,2.189,0,0,1,131.446,6.766Zm0,0"
                        transform="translate(-98.732 0)" />
                    </g>
                  </g>
                </g>
              </svg>
            </div>
            <div style="margin-left:4px;">Edit</div>
          </div>
        </button>
      </div>
    </div>
    <div class="text-center">
      <div class="user-image">
        <img [src]="userImage" />
      </div>
      <div class="user-full-name">
        {{model.firstName}} {{model.lastName}}
      </div>
      <div class="user-email">
        {{model.email}}
      </div>
    </div>
    <div class="display-flex flex-items-centered prof-spacing">
      <div class="flex-basis-0 flex-grow-1">
        <div class="display-flex align-items-center">
          <img class="label-icon" src="assets/images/newIcons/title.svg" />
          <div class="prof-label">Title</div>
        </div>
        <div class="prof-info">
          {{model.appUserExtension!.title}}
        </div>
      </div>
      <div class="flex-basis-0 flex-grow-1">
        <div class="display-flex align-items-center">
          <img class="label-icon" src="assets/images/newIcons/cellphone.svg" />
          <div class="prof-label">Cell Phone</div>
        </div>
        <div class="prof-info">
          {{model.appUserExtension!.cellphone}}
        </div>
      </div>
      <div class="flex-basis-0 flex-grow-1">
        <div class="display-flex align-items-center">
          <img class="label-icon" src="assets/images/newIcons/office.svg" />
          <div class="prof-label">Office Phone Number</div>
        </div>
        <div class="prof-info">
          {{model.appUserExtension!.office}}
        </div>
      </div>
    </div>
  </div>
  <div class="text-center contact-card-prev">
    <h3 class="text-center card-prev-title">Card Preview</h3>
    <appuser-card-template [loadLogoImage]="false" [logoImage]="logoImage" [appuser]="model" [previewOnly]="true"></appuser-card-template>
  </div>

</div>
<div class="display-flex flex-basis-0 flex-grow-1 flex-col" *ngIf="isEditMode">

  <mat-tab-group mat-align-tabs="start"  mat-stretch-tabs="false" [(selectedIndex)]="selectedTabIdx" (selectedIndexChange)="onTabChange($event)">
    <mat-tab label="Profile Details">
      <div class="cont-padding border-bottom toolbar-div">
        <button class="cp-btn cp-command-btn" (click)="back()">Close</button>
        <button class="cp-btn cp-command-btn" (click)="save()">Save</button>
      </div>
      <form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
        <ichs-collapsable-panel panelTitle="Profile Details">
          <div class="collapsableBody">
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box name="email" [(ngModel)]="model.email" required email label="Email" disabled="true"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box name="lastName" [(ngModel)]="model.lastName" required label="Last Name"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box type="text" name="firstName" [(ngModel)]="model.firstName" required label="First Name"></ichs-text-box>
              </div>
            </div>
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box type="text" name="middleName" [(ngModel)]="model.middleName" maxlength="1" label="Middle Name"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-select name="timeZone" [(ngModel)]="model.appUserExtension!.timeZone"
                  label="Time Zone (for Secure Messaging only)" listId="108000000000013" [hideResetLabel]="true" required>
                </ichs-select>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm fiscal-year">
                <ichs-select label="Fiscal Year Starting Month" (selectionChanged)="onFiscalYearStartingMonthChange()" name="fiscalYearStartingMonth"
                  [(ngModel)]="model.appUserExtension!.fiscalYearStartingMonth" [items]="monthsSelectionListItems"
                  [hideResetLabel]="true" required>
                </ichs-select>

                <div class="flex-col-sep"></div>

                <ichs-select label="Fiscal Year Starting Date" name="fiscalYearStartingDate" [(ngModel)]="model.appUserExtension!.fiscalYearStartingDate"
                  [items]="monthDatesSelectionListItems" [hideResetLabel]="true" required>
                </ichs-select>
              </div>
            </div>
          </div>
        </ichs-collapsable-panel>
        <ichs-collapsable-panel panelTitle="User Business Details">
          <div class="collapsableBody">
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [(ngModel)]="model.appUserExtension!.title" name="title" label="Title"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [(ngModel)]="model.appUserExtension!.office" name="office" label="Office Phone Number"
                  inputMask="phone-with-ext"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <div class='flex-cell label-cont'><label>User Image</label></div>
                <ichs-image-cropper [cropperConfig]="ichsImageCropperConfig" [inputImage]="userImage" (imageCropped)="onImageCropped($event)"></ichs-image-cropper>
              </div>
            </div>
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [(ngModel)]="model.appUserExtension!.cellphone" name="cellphone" label="Cell Phone"
                  inputMask="phone"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [(ngModel)]="model.appUserExtension!.fax" name="fax" label="Fax" inputMask="phone"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <div class='flex-cell label-cont'><label>Company Logo</label></div>
                <div class="upload-img" (click)="logoInput.click()">
                  <div class="uploaded-img-cont"><img width="50" [src]="logoImage" title="User Logo" /></div>
                  <input (change)="logoImageSelected($event)" type="file" #logoInput style="display:none" accept="image/x-png,image/gif,image/jpeg" />
                  <div class="position-relative">
                    <div class="change-img position-absolute">
                      <span>Change Image</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [(ngModel)]="model.appUserExtension!.website" name="website" label="Website"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-select name="BusinessCardTemplate" [(ngModel)]="model.appUserExtension!.businessCardTemplate"
                  label="Business Card" listId="108000000000002" [hideResetLabel]="true" required></ichs-select>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered"></div>
            </div>
            <div class="text-center contact-card-prev">
              <h3 class="text-center card-prev-title">Card Preview</h3>
              <appuser-card-template [loadLogoImage]="false" [logoImage]="logoImage" [appuser]="model" [previewOnly]="true"></appuser-card-template>
            </div>
          </div>
        </ichs-collapsable-panel>

      </form>
    </mat-tab>

    <mat-tab label="Change Password" *ngIf="!model.activeDirectoryUserInd">
    </mat-tab>

    <mat-tab label="Security" *ngIf="!model.activeDirectoryUserInd">
      <ichs-collapsable-panel panelTitle="Manage Security Question">
        <div class="collapsableBody">
          <div class="manage-sec-questions-btn">
            <a class="btn btn-default" (click)="openSecurityQuestionsPopup()">
               Manage Security Questions
            </a>
          </div>
        </div>
      </ichs-collapsable-panel>

      <ichs-collapsable-panel panelTitle="Multifactor Authentication">
        <div class="collapsableBody">
          <div class="display-flex break-la">
            <div style="align-self: flex-end;" class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <ichs-text-box name="mfaAppStatus" [(ngModel)]="model.mfaStatus.mfaAppStatus"
                  label="Authentication App" [disabled]="true"></ichs-text-box>
            </div>

            <div style="align-self: flex-end;" class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm"></div>
          </div>
          <div class="display-flex break-la">
            <div style="align-self: flex-end;" class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
              <ichs-text-box name="mfaTextStatus" [(ngModel)]="model.mfaStatus.mfaTextStatus"
              label="Text/SMS" [disabled]="true"></ichs-text-box>
            </div>

            <div style="align-self: flex-end;" class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm"></div>
          </div>
          <div class="display-flex break-la">
            <div style="align-self: flex-end;" class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
              <ichs-text-box name="mfaSharedEmailStatus" [(ngModel)]="model.mfaStatus.mfaSharedEmailStatus"
              label="Email" [disabled]="true"></ichs-text-box>
            </div>

            <div style="align-self: flex-end;" class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm"></div>
          </div>
        </div>
      </ichs-collapsable-panel>
    </mat-tab>

  </mat-tab-group>

</div>
