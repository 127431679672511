<div class="cont-padding flex-basis-0 flex-grow-1">
    <div class="border-bottom">
        <ichs-command-toolbar [model]="model"></ichs-command-toolbar>
    </div>
    <form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
        <mat-tab-group mat-align-tabs="start"  mat-stretch-tabs="false" [(selectedIndex)]="model.selectedTab">
            <mat-tab label="User Detail">
                <ichs-collapsable-panel panelTitle="Triage Portal User Details">
                    <div class="collapsableBody">
                        <div class="display-flex break-la">
                            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                                <ichs-text-box initialFocus="true" name="email" [(ngModel)]="model.objModel.email"
                                    maxlength="256" required email label="Email"></ichs-text-box>
                            </div>

                            <div class="flex-col-sep"></div>

                            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                                <ichs-text-box type="text" name="firstName" [(ngModel)]="model.objModel.firstName"
                                    maxlength="50" required label="First Name"></ichs-text-box>
                            </div>

                            <div class="flex-col-sep"></div>

                            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                                <ichs-text-box type="text" name="middleName" [(ngModel)]="model.objModel.middleName"
                                    maxlength="1" label="Middle Initial"></ichs-text-box>
                            </div>
                        </div>
                        <div class="display-flex break-la">
                            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                                <ichs-text-box name="lastName" [(ngModel)]="model.objModel.lastName" maxlength="50"
                                    required label="Last Name"></ichs-text-box>
                            </div>

                            <div class="flex-col-sep"></div>

                            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                                <ichs-text-box [(ngModel)]="model.objModel.clientName" name="ClientName" label="Client Name"
                                    required></ichs-text-box>
                            </div>

                            <div class="flex-col-sep"></div>

                            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                                <ichs-select name="status" label="Status" [(ngModel)]="model.objModel.status" [items]="statusList"
                                    hideResetLabel="true" (selectionChanged)="statusChanged($event)">
                                </ichs-select>
                            </div>
                        </div>
                    </div>
                </ichs-collapsable-panel>
            </mat-tab>

            <mat-tab label="User App Log">
                <div class="cont-padding">
                    <ichs-select hideResetLabel="true" name="logsType" label="Logs Type" [items]="logsTypeSelectionListItems"
                        [(ngModel)]="logsType"></ichs-select>
                </div>
                <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab has-command app-log">
                    <ichs-grid [grid-config]="appLogGridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
                </div>
            </mat-tab>

            <mat-tab label="User Pin Codes">
                <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab has-command">
                    <ichs-grid [grid-config]="userPinCodesGridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
                </div>
            </mat-tab>
        </mat-tab-group>
    </form>
</div>
