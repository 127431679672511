import { Component, OnInit } from '@angular/core';
import { TriageSecuredFileService, TriageSecuredFileInfoViewModel } from '../../rest';
import { first } from 'rxjs/operators';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Component({
  selector: 'external-triage-secured-file',
  templateUrl: './external-triage-secured-file.component.html',
  styleUrls: ['./external-triage-secured-file.component.scss']
})
export class ExternalTriageSecuredFileComponent implements OnInit {
  encryptedData: string;
  triageSecuredFileInfo: TriageSecuredFileInfoViewModel = <TriageSecuredFileInfoViewModel>{};
  fileFound: boolean = null;

  constructor(
    private triageSecuredFileService: TriageSecuredFileService,
    private router: Router,
    private sharedFunctions: SharedFunctionsService,
  ) {
  }

  ngOnInit() {
    this.encryptedData = location.search.replace("?data=", "");

    this.triageSecuredFileService.getTriageSecuredFileForMobile(this.encryptedData).pipe(first()).subscribe(resp => {
      this.triageSecuredFileInfo = resp.result;
      if (this.triageSecuredFileInfo.fileName) {
        this.triageSecuredFileInfo.fileName = this.shortenFileName(this.triageSecuredFileInfo.fileName);
        this.fileFound = true;
      }
      else {
        this.fileFound = false;
      }
    });
  }

  downloadFile() {
    let sub = this.triageSecuredFileService.downloadTriageSecuredFileForMobile(this.encryptedData, 'events', true).subscribe(
      event => {
        if (event.type == HttpEventType.Response) {
          this.sharedFunctions.saveToFileSystem(event);
        }
      },
      () => { sub.unsubscribe() },
      () => { sub.unsubscribe() }
    );
  }

  navigateToMainPage() {
    this.router.navigate(['/']);
  }

  shortenFileName(filename: string) {
    let firstIndex = filename.indexOf("_");
    let secondIndex = filename.indexOf("_", firstIndex + 1);
    let thirdIndex = filename.indexOf("_", secondIndex + 1);
    let nameStartIndex = filename.indexOf("-", thirdIndex + 1) + 1;

    return filename.substring(nameStartIndex, filename.length);
  }
}
