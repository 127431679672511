<div class="display-flex flex-grow-1 login-container flex-items-centered">

    <div class="login-content flex-basis-0 flex-grow-1">
        <div class="display-flex flex-items-centered">
            <div class="logo-cont"><img src="assets/images/medcorLogo_white.svg" /></div>
            <div><span class="app-name">Client Portal</span></div>
        </div>
        <div>
            <hr class="client-portal-hr" />
        </div>
        <form #form="ngForm" novalidate>
            <ng-container *ngIf="!showTriageUserPinCodeForm">
                <div class="shared-inbox-alert margin-bottom-def" *ngIf="isSharedInboxAccount">
                    <i>{{sharedInboxEmail}}</i> is recognized as a shared inbox account, please enter your individual login at this time. If you do not have
                    an individual login, please contact your Medcor representative.
                </div>
                <div>
                    <div class="label-cont">
                        <label>Email</label>
                    </div>
                    <div class="input-cont flex-basis-0 flex-grow-1">
                        <ichs-text-box #emailTextBox required name="email" [(ngModel)]="email" [initialFocus]="true">
                        </ichs-text-box>
                    </div>
                </div>

                <div class="label-cont margin-top-def">
                    By logging in to the site, I agree to the terms and conditions in the <a (click)="showUserAgreement()"
                        class="user-agreement">user agreement.</a>
                </div>

                <div class="login-btns next-btn">
                    <button (click)="next()">Next</button>
                    <div class="margin-top-def">
                        <a (click)="openForgetPasswordForm()" class="forgot-btn">
                            Forgot Password
                        </a>
                    </div>
                </div>

                <div class="display-flex align-items-center login-sep">
                    <div class="flex-grow-1 custom-sep"></div>
                    <div class="or-div">Or</div>
                    <div class="flex-grow-1 custom-sep"></div>
                </div>

                <div class="flex-child" class="sso-btn">
                    <img class="sign-in-with-microsoft" src="assets/images/sign-in-with-microsoft.svg" (click)="loginUsingAzureAd()" />
                </div>
            </ng-container>

            <ng-container *ngIf="showTriageUserPinCodeForm">
                <div>
                    <div class="label-cont">
                        <label *ngIf="!triagePortalUserDefaultAdminEmailUsed">
                            Your password is expired, we have sent an email containing a one-time security code to
                            your supervisor '{{triageUserAdminEmail}}'. To
                            complete your login, please enter the code in the field below and click Log Me In.
                        </label>

                        <label *ngIf="triagePortalUserDefaultAdminEmailUsed">
                            Your password is expired, Please contact '{{triageUserAdminEmail}}'.
                        </label>
                    </div>
                    <br />
                    <div class="input-cont flex-basis-0 flex-grow-1">
                        <ichs-text-box required name="triageUserPinCode" [(ngModel)]="triageUserPinCode" initialFocus="true">
                        </ichs-text-box>
                    </div>
                </div>
                <div class="display-flex" style="justify-content:flex-end;">
                    <div class="flex-child submit-btn">
                        <button (click)="submitTriageUserPinCode()">Submit</button>
                    </div>

                    <div class="flex-child">
                        <button (click)="showTriageUserPinCodeForm = false; triageUserPinCode = null;">Cancel</button>
                    </div>
                </div>
            </ng-container>
        </form>
    </div>
</div>
