<div class="triage-portal-user-pincode-access" *ngIf="model">
    <div>
        <div class="display-flex flex-items-centered">
            <div class="logo-cont"><img src="assets/images/medcorLogo.svg" class="main-logo" /></div>
            <div class="flex-grow-1 flex-basis-0"></div>
            <div style="margin-right: 8px" class="user-info-cont"><button class="cp-head-btn" (click)="logout()">Logout</button></div>
        </div>
        <hr class="main-hr" />
    </div>
    <div>
        <h1 class="main-title">Triage Portal User Password Change</h1>
        <hr class="main-hr" />
    </div>
    <div class="flex-grow-1 flex-basis-0 external-page-cont">

        <div class="cont-padding">
            <div class="main-container">
                <hr class="main-hr" style="margin-top: 20px;" />
                <div>
                    <form #form="ngForm" novalidate>
                        <ichs-collapsable-panel panelTitle="Change Password">
                            <div class="collapsableBody">
                                <div class='display-flex break-la'>
                                    <div class="flex-child flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                                        <ichs-password #passwordComponent name="password" [(ngModel)]="model.newPassword" required label="Password"
                                            passwordStrength></ichs-password>
                                            <div class="position-relative">
                                                <div class="password-rules">
                                                  (At least 12 characters including: capital character, small character, number or symbol.)
                                                </div>
                                              </div>
                                    </div>
                                    <div class="flex-col-sep"></div>
                                    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                                        <ichs-password name="confirmPassword" [(ngModel)]="confirmPassword" label="Confirm Password"
                                            validateEqual [relatedControl]="passwordComponent.model.control" required></ichs-password>
                                    </div>
                                </div>
                            </div>
                        </ichs-collapsable-panel>

                        <ichs-collapsable-panel panelTitle="Security Questions" *ngIf="hasSecQuestions == false">
                            <div class="collapsableBody">
                                <div class='display-flex break-la' *ngFor="let secQuestion of model.secQuestionAssociationList, let i = index">
                                    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                                        <ichs-select label="Security Question" name="securityQuestionSelect{{i}}"
                                            placeholder="Select Question" [items]="getFilteredQuestionsList(secQuestion.question)"
                                            [(ngModel)]="secQuestion.question" required>
                                        </ichs-select>
                                    </div>

                                    <div class="flex-col-sep"></div>
                                    <div class="flex-child display-flex sec-ques-answer break-sm flex-items-centered">
                                        <ichs-text-box required label="Answer" name="securityQuestionAnswer{{i}}"
                                            [(ngModel)]="secQuestion.answer">
                                        </ichs-text-box>
                                    </div>
                                </div>
                            </div>
                        </ichs-collapsable-panel>

                        <div style="position: relative; left: 20px;" class="flex-child">
                            <button class="cp-btn cp-mail-btn" (click)="save()">Save</button>
                        </div>

                    </form>
                </div>
            </div>
        </div>

    </div>
    <div>
        <app-footer></app-footer>
    </div>
</div>