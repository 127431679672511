import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AfkamService, TriageAfterCareFileViewModel, MedfilesAfterCareFileViewModel } from '../../rest';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from '../../services/medcor-alert.service';
import { ValidationMessages } from '../../app.general.constants';
import { MedfilesAfterCareInstructionsService } from 'src/app/rest';
import { Observable } from 'rxjs';
import { MedcorResponse } from '../../rest/model/medcorResponse';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Component({
  selector: 'care-instruction-form',
  templateUrl: './care-instruction-form.component.html',
  styleUrls: ['./care-instruction-form.component.scss']
})
/** care-instruction component*/
export class CareInstructionFormComponent implements OnInit {
  @ViewChild("form") ngForm: NgForm;
  public triageAfterCareList: TriageAfterCareFileViewModel[] | MedfilesAfterCareFileViewModel[] = [];
  public lastName: string;
  public refNumber: string;
  public isSearchDone: boolean = false;
  isMedfilesAfterCare: boolean = false;

  constructor(
    private afkamService: AfkamService,
    private medfilesAfterCareInstructionsService: MedfilesAfterCareInstructionsService,
    private alertService: MedcorAlertService,
    private sharedFunctions: SharedFunctionsService,
  ) {

  }

  ngOnInit() {
    this.isMedfilesAfterCare = location.pathname.endsWith("/medfiles-care-instruction");
  }

  getInfo() {
    this.isSearchDone = false;
    if (this.ngForm.controls.refNumber.invalid || this.ngForm.controls.lastName.invalid) {
      this.ngForm.controls.refNumber.markAsTouched();
      this.ngForm.controls.lastName.markAsTouched();

      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    let obs: Observable<MedcorResponse<any>> = this.isMedfilesAfterCare ?
      this.medfilesAfterCareInstructionsService.getTriageAfterCareFiles(this.refNumber, this.lastName) :
      this.afkamService.getTriageAfterCareFiles(this.refNumber, this.lastName);

    obs.pipe(first()).subscribe(resp => {
      this.triageAfterCareList = resp.result;
      this.isSearchDone = true;
    });
  }

  downloadFile(file: TriageAfterCareFileViewModel | MedfilesAfterCareFileViewModel) {
    if (this.ngForm.controls.refNumber.invalid || this.ngForm.controls.lastName.invalid) {
      this.ngForm.controls.lastName.markAsTouched();
      this.ngForm.controls.refNumber.markAsTouched();

      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    let obs: Observable<HttpEvent<Blob>> = this.isMedfilesAfterCare ?
      this.medfilesAfterCareInstructionsService.downloadTriageAfterCareFile(file.triageAfterCareFileId, this.refNumber, this.lastName, file.language, 'events', true) :
      this.afkamService.downloadTriageAfterCareFile(file.triageAfterCareFileId, this.refNumber, this.lastName, 'events', true);

    let sub = obs.subscribe(event => {
      if (event.type == HttpEventType.Response) {
        this.sharedFunctions.saveToFileSystem(event);
      }
    }, () => { sub.unsubscribe() }, () => { sub.unsubscribe() });
  }
}
