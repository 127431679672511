<div class="main-container">
  <div *ngIf="submitted; else notSubmitted" class="thank-you-container">
    <div class="display-flex flex-items-centered">
      <div class="logo-cont"><img src="assets/images/medcorLogo.svg" class="main-logo" /></div>
    </div>
    <div style="text-align: center;min-height: 60px;">
      <h1>Thank You!</h1>
    </div>
  </div>
  <ng-template #notSubmitted>
    <pdf-viewer [src]="pdfSrc" [fit-to-page]="true" class="pdf-container"></pdf-viewer>
    <canvas #canvas [ngClass]="{'hidden': !signaturevisible}" class="signaturepad-container"></canvas>
    <div class="buttons-container">
      <button (click)="submit()" *ngIf="signaturevisible" class="button-style cp-btn cp-main-btn">Submit</button>
      <button (click)="clear()" *ngIf="signaturevisible" class="button-style cp-btn cp-main-btn">Clear</button>
      <button (click)="cancel()" *ngIf="signaturevisible" class="button-style cp-btn cp-main-btn">Cancel</button>
      <button (click)="sign()" *ngIf="!signaturevisible" class="button-style cp-btn cp-main-btn">Sign</button>

    </div>
  </ng-template>
</div>
