import { Component, OnInit } from '@angular/core';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';
import { catchError, first } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { AfkamCubeService, AppUserService, SelectionTreeViewModel, UserBusinessLineListViewModel } from 'src/app/rest';
import { LineOfBusiness } from 'src/app/app.general.constants';
import { DashboardSchedulerService } from '../../rest/api/dashboardScheduler.service';
import { DashboardSchedulerViewModel } from '../../rest/model/dashboardSchedulerViewModel';
import { MedcorResponse } from '../../rest/model/medcorResponse';
import { IColumnTarget } from 'powerbi-models';

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {

  //Power BI Control properties
  selectedLineOfBusiness: string;
  userLineOfBusinessList: string[] = [];
  oshaRecordKeepingInstallationAccessOnly: boolean;
  isMaximized: boolean = false;
  selectedTabIndex: number = 0;
  dashboardScheduler: DashboardSchedulerViewModel;

  constructor(
    private alertService: MedcorAlertService,
    private appUserService: AppUserService,
    private activatedRoute: ActivatedRoute,
    private generalVariables: GeneralVariablesService,
    private dashboardSchedulerService: DashboardSchedulerService,
  ) {
  }

  ngOnInit() {
    let userBusinessLinesObs: Observable<MedcorResponse<UserBusinessLineListViewModel>> = this.appUserService.getUserBusinessLines().pipe(first());
    let dashboardSchedulerObs: Observable<MedcorResponse<DashboardSchedulerViewModel>> = of(null);

    let params = this.activatedRoute.snapshot.queryParams;
    if (params['scheduleId']) {
      let scheduleId = +params['scheduleId'];
      dashboardSchedulerObs = this.dashboardSchedulerService.getById(scheduleId).pipe(first(), catchError(() => of({})));
    }

    forkJoin([userBusinessLinesObs, dashboardSchedulerObs])
      .subscribe(([userBusinessLinesResp, dashboardSchedulerObsResp]) => {
        this.userLineOfBusinessList = userBusinessLinesResp.result.lineOfBusinessList;
        this.userLineOfBusinessList = this.userLineOfBusinessList.filter(line => line != LineOfBusiness.SCREENING);
        this.oshaRecordKeepingInstallationAccessOnly = userBusinessLinesResp.result.oshaRecordKeepingInstallationAccessOnly;

        if (dashboardSchedulerObsResp) {
          if (dashboardSchedulerObsResp.result) {
            this.dashboardScheduler = dashboardSchedulerObsResp.result;

            if (this.userLineOfBusinessList.indexOf(this.dashboardScheduler.lineOfBusiness) > -1) {
              this.changeLineOfBusiness(this.dashboardScheduler.lineOfBusiness);
            }
            else {
              this.showNoAccessAlert();
            }
          }
        }
        else {
          let lineOfBusiness = params['lineOfBusiness'];

          if (lineOfBusiness && this.userLineOfBusinessList.indexOf(lineOfBusiness) > -1) {
            this.changeLineOfBusiness(lineOfBusiness);
          }
          else if (this.generalVariables.lineOfBusiness && this.userLineOfBusinessList.indexOf(this.generalVariables.lineOfBusiness) > -1) {
            this.changeLineOfBusiness(this.generalVariables.lineOfBusiness);
          }
          else if (this.userLineOfBusinessList.length > 0) {
            this.changeLineOfBusiness(this.userLineOfBusinessList[0]);
          }
          else {
            this.showNoAccessAlert();
          }
        }
      });
  }

  changeLineOfBusiness(lineOfBusiness: string) {
    this.generalVariables.lineOfBusiness = lineOfBusiness;
    this.selectedLineOfBusiness = lineOfBusiness;
    this.selectedTabIndex = 0
  }

  private showNoAccessAlert() {
    this.alertService.addAlert({
      type: AlertMessageType.warning,
      title: 'Warning!',
      dismiss: AlertDismissType.controlled,
      messages: ["You don't have access on this data!"],
    });
  }
}

export class FilterTargetConfig {
  public readonly filterTarget: IColumnTarget;
}

export class FilterControlConfig extends FilterTargetConfig {
  public readonly label: string;
  public readonly name: string;
  public readonly isLevel: boolean;
  public readonly sessionStorageKey: string;
  public readonly loadChildrenFunction?: (node: SelectionTreeViewModel) => Observable<MedcorResponse<SelectionTreeViewModel[]>>;
}

export class DateFilterControlConfig extends FilterControlConfig {
  public readonly advancedDateFilterTarget: IColumnTarget;
}

export class AdvancedDateFilterationAttributes {
  dateRange: string;
  startDate: Date;
  endDate: Date;
}

export class DashboardFilterationValues {
  dateRange: string;
  startDate: string;
  endDate: string;
  dropdownTreeFilterationValues: DropdownTreeFilterationValues[] = [];
}

export class DropdownTreeFilterationValues {
  treeName: string;
  included: string[];
  excluded: string[];
}