import { Component, OnInit } from '@angular/core';
import { GroupViewModel, GroupService, SearchViewModel, GroupSearchViewModel, VoidInfoViewModel } from '../../rest';
import { IchsGridComponentConfig, IchsControlType } from '../../controls/ichs-grid/grid-configs';
import { MedcorUserInfoService } from 'src/app/services/medcor-user-info.service';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css'],
  host: {
    class: "data-list"
  }
})

/** group component*/
export class GroupListComponent implements OnInit {
  gridConfig: IchsGridComponentConfig<GroupViewModel>;
  adminRoleInd: boolean = false;
  loggedUserSub: Subscription;

  /** group ctor */
  constructor(
    private groupService: GroupService,
    private medcorUserInfoService: MedcorUserInfoService,
  ) {
    this.loggedUserSub = this.medcorUserInfoService.loggedInUserObs.subscribe(loggedInUser => {
      this.adminRoleInd = loggedInUser.roleName == "Admin";
    });
    this.gridConfig = this.defineGrid();
    this.groupService.getParentGroupsAsSelectionList().pipe(first()).subscribe(response => {
      this.gridConfig.headers[2].listData = response.result!;
    });
  }

  /** GroupList OnInit*/
  ngOnInit() {

  }

  ngOnDestroy() {
    this.loggedUserSub.unsubscribe();
  }

  private defineGrid(): IchsGridComponentConfig<GroupSearchViewModel> {
    let configs: IchsGridComponentConfig<GroupSearchViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Groups",
      entityController: "app-group",
      hasVoid: true,
      hasNew: this.adminRoleInd,
      voidAction: (this.adminRoleInd ? (reason: VoidInfoViewModel) => this.groupService._void(reason) : null),
      recoverAction: (this.adminRoleInd ? (id: number) => this.groupService.recover(id) : null),
      entityDataSource: (filter: SearchViewModel) => this.groupService.searchGroupsWithParent(filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Base Group",
          propertyName: "isStandard",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Boolean
        },
        {
          headerTitle: "Parent Name",
          propertyName: "parentName",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.StaticSelect
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ],
    }

    return configs;
  }
}
