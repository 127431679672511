<h2 mat-dialog-title>Please double click on the folder that you want to place the file in</h2>
<mat-dialog-content>

  <div class="secured-files-tools display-flex flex-items-centered">
    <div><a style="cursor: default">Shared Folders</a>&nbsp;</div>
    <div class="flex-grow-1 flex-basis-0">
      <ng-container *ngFor="let item of pathName; index as i">
        / <a style="cursor: pointer" (click)="navigateTofolder(pathId[i],i)">{{item}}</a>
      </ng-container>
    </div>
  </div>

  <div class="flex-grow-1  secured-files" style="min-height:400px;">
    <div class="main-folder" id="mainFolder">
      <div class="user-folder" (dblclick)="loadFolders(folder)" *ngFor="let folder of folders">
        <div class="folder-visuals">
          <img src="assets/images/newIcons/folder.svg" [title]="folder.name" class="folder-icon" />
          <span style="display:block">{{folder.name}}</span>
        </div>
      </div>
    </div>
  </div>

</mat-dialog-content>

<mat-dialog-actions>
  <div class="text-right fill-width">
    <button mat-button (click)="moveFileTo()" class="cp-btn cp-main-btn">Move Here</button>
    <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">Cancel</button>
  </div>
</mat-dialog-actions>