<div class="main-theme">
  <ng-progress></ng-progress>
  <ng-chat
    *ngIf="!popupMode && !showTriageSecuredFilePage && (chatAdapter.userId | async) != null && ((!(loggedInUserObs | async).firstLoginInd && !(loggedInUserObs | async).changePasswordInd && (loggedInUserObs | async).acceptedUserAgreement && !showMfa)
    || (loggedInUserObs | async).isImpersonated) && !impersonatorRoleInd"
    [adapter]="chatAdapter" [userId]="chatAdapter.userId | async" [isCollapsed]="true">
  </ng-chat>
  <ng-template #authenticatedBlock
    [ngIf]="(authenticatedObs | async) && !showTriageSecuredFilePage && ((!(loggedInUserObs | async).firstLoginInd && !(loggedInUserObs | async).changePasswordInd && (loggedInUserObs | async).acceptedUserAgreement && !showMfa) || (loggedInUserObs | async).isImpersonated)">
    <div class="main-container" [class.is-desktop]="generalVariables.clientDeviceInfo.isDesktop" [ngClass]="{'full-width':generalVariables.clientStatisticsPage}">
      <div class="display-grid main-cont-grid">
        <div >
        <div *ngIf="!popupMode" class="side-back">
          <div class="display-grid left-menu-grid">
            <div class="position-absolute left-collapse-button-cont">
              <button class="cp-side-collapse margin-left-def" (click)="generalVariables.appViewModel.toggleLeft()"
                [class.expanded]="generalVariables.appViewModel.leftIsHidden">
                <svg class="side-collapse-icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60"
                  style="enable-background:new 0 0 20 20;" xml:space="preserve">
                  <style type="text/css"></style>
                  <path class="st0"
                    d="M30,0.7C13.9,0.7,0.7,13.9,0.7,30S13.9,59.3,30,59.3S59.3,46.1,59.3,30S46.1,0.7,30,0.7z" />

                  <path class="cls-1" d="M24.3,37c-1.2,1.2-1.2,3.1,0,4.3l0,0c1.2,1.2,3.1,1.2,4.3,0l9-8.9c1.3-1.3,1.3-3.4,0-4.7l0,0l-9-8.9
	                  c-1.2-1.2-3.1-1.2-4.3,0c-1.2,1.2-1.2,3.1,0,4.3l0,0l7.1,7C31.4,30,24.3,37,24.3,37z" />
                </svg>
              </button>
            </div>
            <div class="text-center side-menu-logo">
              <a href="https://www.medcor.com" target="_blank">
                <img src="assets/images/medcorLogo.svg"  [class.logo-left-hidden]="generalVariables.appViewModel.leftIsHidden"/>
              </a>
            </div>
            <div class="side-cont left" [class.hidden-side]="generalVariables.appViewModel.leftIsHidden">
              <ng-scrollbar appearance="compact" class="links-cont" style="height:calc(100vh - 128px);">
                <ng-template [ngxPermissionsExcept]="['Client User - Scheduled Reports Only']">
                  <a [routerLink]="['/home-page']" routerLinkActive="menu-selected">
                    <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                      <div class="menu-item-icon" title="Home">
                        <img src="assets/images/newcolor/home.svg" />
                      </div>
                      <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                        Home
                      </div>
                    </div>
                  </a>
                  <ng-template [ngxPermissionsExcept]="['Client User - Triage Portal']"
                    *ngIf="userPermissions.hasDashboardDataAccess && (loggedInUserObs | async).impersonatedUserRoleName != 'Client User - Triage Portal'">
                    <a [routerLink]="['/dashboard']" routerLinkActive="menu-selected">
                      <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                        <div class="menu-item-icon" title="Dashboard">
                          <img src="assets/images/newcolor/dashboard.svg" />
                        </div>
                        <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                          Dashboard
                        </div>
                      </div>
                    </a>
                  </ng-template>
                  <a [routerLink]="['/executive-dashboard']" routerLinkActive="menu-selected"
                    *ngIf="userPermissions.hasExecutiveDashaboardAccess">
                    <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                      <div class="menu-item-icon" title="Executive Dashboard">
                        <img src="assets/images/newcolor/dashboard.svg" />
                      </div>
                      <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                        Executive Dashboard
                      </div>
                    </div>
                  </a>
                  <a [routerLink]="['/triage-call-report']" routerLinkActive="menu-selected"
                    *ngIf="hasTriageCallReportsAccess">
                    <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                      <div class="menu-item-icon" title="Triage Call Reports">
                        <img src="assets/images/newcolor/triageCallReports.svg" />
                      </div>
                      <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                        Triage Call Reports
                      </div>
                    </div>
                  </a>
                  <ng-template [ngxPermissionsExcept]="['Client User - Triage Portal']"
                    *ngIf="userPermissions.hasDataAccess && (loggedInUserObs | async).impersonatedUserRoleName != 'Client User - Triage Portal'">
                    <a [routerLink]="['/app-reporting']" routerLinkActive="menu-selected">
                      <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                        <div class="menu-item-icon" title="Reports">
                          <img src="assets/images/newcolor/reports.svg" />
                        </div>
                        <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                          Reports
                        </div>
                      </div>
                    </a>
                  </ng-template>
                  <a [routerLink]="['/dashboard-schedule']" routerLinkActive="menu-selected"
                    *ngIf="userPermissions.hasScheduledDashboards">
                    <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                      <div class="menu-item-icon" title="Scheduled Dashboards">
                        <img src="assets/images/newcolor/scheduledDashboards.svg" />
                      </div>
                      <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                        Scheduled Dashboards
                      </div>
                    </div>
                  </a>
                  <ng-template [ngxPermissionsExcept]="['Client User - Triage Portal']">
                    <a [routerLink]="['/report-schedule']" routerLinkActive="menu-selected">
                      <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                        <div class="menu-item-icon" title="Scheduled Reports">
                          <img src="assets/images/newcolor/reportschedules.svg" />
                        </div>
                        <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                          Scheduled Reports
                        </div>
                      </div>
                    </a>
                  </ng-template>
                  <a [routerLink]="['/secureMessaging']" routerLinkActive="menu-selected">
                    <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                      <div class="menu-item-icon" title="Secure Messaging">
                        <img src="assets/images/newcolor/securedMail.svg" />
                      </div>
                      <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                        Secure Messaging
                      </div>
                      <span class="menu-badge"
                        *ngIf="generalVariables.systemCounters.inboxCounter + generalVariables.systemCounters.notificationCounter > 0">{{generalVariables.systemCounters.inboxCounter
                        + generalVariables.systemCounters.notificationCounter}}</span>
                    </div>
                  </a>
                </ng-template>
                <a [routerLink]="['/securedfiles']" routerLinkActive="menu-selected">
                  <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                    <div class="menu-item-icon" title="My Files">
                      <img src="assets/images/newcolor/securedFiles.svg" />
                    </div>
                    <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                      My Files
                    </div>
                    <span class="menu-badge"
                      *ngIf="generalVariables.systemCounters.newFilesCounter > 0">{{generalVariables.systemCounters.newFilesCounter}}</span>
                  </div>
                </a>
                <ng-template [ngxPermissionsExcept]="['Client User - Scheduled Reports Only']">
                  <ng-container *ngIf="userPermissions.hasSharedFolders">
                    <a [routerLink]="['/user-shared-folders']" routerLinkActive="menu-selected">
                      <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                        <div class="menu-item-icon" title="Shared Folders">
                          <img src="assets/images/newcolor/sharedFolder.svg" />
                        </div>
                        <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                          Shared Folders
                        </div>
                      </div>
                    </a>
                  </ng-container>
                  <a [routerLink]="['/service-request']" routerLinkActive="menu-selected">
                    <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                      <div class="menu-item-icon" title="Service Request">
                        <img src="assets/images/newcolor/requestForm.svg" />
                      </div>
                      <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                        Service Request
                      </div>
                    </div>
                  </a>
                  <ng-container *ngIf="(loggedInUserObs | async).allowMaintainCalls">
                    <a style="cursor: pointer">
                      <div class="menu-item display-flex flex-items-centered" (click)="sendCallRecording()">
                        <div class="menu-item-icon" title="Send Telehealth Call Recording">
                          <img src="assets/images/newcolor/SendCallRecordings.svg" />
                        </div>
                        <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                          Send Telehealth Recording
                        </div>
                      </div>
                    </a>
                  </ng-container>
                  <ng-container *ngIf="(loggedInUserObs | async).roleName == 'Admin'||
                                      (loggedInUserObs | async).roleName == 'Advance User'||
                                      (loggedInUserObs | async).roleName == 'Medcor User'||
                                      (loggedInUserObs | async).impersonatedUserRoleName == 'Admin' ||
                                      (loggedInUserObs | async).impersonatedUserRoleName == 'Advance User' ||
                                      (loggedInUserObs | async).impersonatedUserRoleName == 'Medcor User'">
                    <a style="cursor: pointer">
                      <div class="menu-item display-flex flex-items-centered" (click)="sendFile()">
                        <div class="menu-item-icon" title="Send File">
                          <img src="assets/images/newcolor/sendFile.svg" />
                        </div>
                        <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                          Send File
                        </div>
                      </div>
                    </a>
                  </ng-container>
                  <a [routerLink]="['/user-management']" routerLinkActive="menu-selected" *ngIf="(loggedInUserObs | async).manageUsersAccess">
                    <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                      <div class="menu-item-icon" title="User Management">
                        <img src="assets/images/newIcons/applicationUsers.svg" />
                      </div>
                      <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                        User Management
                      </div>
                    </div>
                  </a>
                  <a [routerLink]="['/user-lookup']" routerLinkActive="menu-selected" *ngIf="(loggedInUserObs | async).userLookupAccess">
                    <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                      <div class="menu-item-icon" title="User Lookup">
                        <img src="assets/images/newIcons/applicationUsers.svg" />
                      </div>
                      <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                        User Lookup
                      </div>
                    </div>
                  </a>
                  <a [routerLink]="['/help-center']" routerLinkActive="menu-selected">
                    <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                      <div class="menu-item-icon" title="Help Center">
                        <img src="assets/images/newcolor/helpCenter.svg" />
                      </div>
                      <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                        Help Center
                      </div>
                    </div>
                  </a>
                  <a [routerLink]="['/about']" routerLinkActive="menu-selected">
                    <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                      <div class="menu-item-icon" title="About Client Portal">
                        <img src="assets/images/newcolor/about2.svg" />
                      </div>
                      <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">
                        About Client Portal
                      </div>
                      <span class="menu-badge" *ngIf="generalVariables.systemCounters.newReleaseNoteReadInd">1</span>
                    </div>
                  </a>
                  <ng-container *ngIf="showTriagePart">
                    <a [routerLink]="['/service-request']" [queryParams]="{type: 'Call Recording Request'}">
                      <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                        <div class="menu-item-icon" title="Request Call Recording">
                          <img src="assets/images/topmenu/requestCallRecording.svg" />
                        </div>
                        <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">Request Call
                          Recording</div>
                      </div>
                    </a>
                    <a [routerLink]="['/service-request']" [queryParams]="{type: 'Training Materials Request'}">
                      <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                        <div class="menu-item-icon" title="Request Training Materials">
                          <img src="assets/images/topmenu/reqeustTrainingMaterials.svg" />
                        </div>
                        <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">Request Training
                          Materials</div>
                      </div>
                    </a>
                  </ng-container>
                  <ng-container *ngIf="showOnsitePart">
                    <a class="sub-menu-link" [routerLink]="['/service-request']"
                      [queryParams]="{type: 'Employee Record Request'}">
                      <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                        <div class="menu-item-icon" title="Employee Record Request">
                          <img src="assets/images/topmenu/employeeRecordRequest.svg" />
                        </div>
                        <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">EE Record Request
                        </div>
                      </div>
                    </a>
                    <a class="sub-menu-link" [routerLink]="['/help-center']" [queryParams]="{type: '151000000000004'}">
                      <div class="menu-item display-flex flex-items-centered selected-menu-inner-div">
                        <div class="menu-item-icon" title="Invoice Questions">
                          <img src="assets/images/topmenu/invoiceQuestion.svg" />
                        </div>
                        <div class="menu-item-txt" [hidden]="generalVariables.appViewModel.leftIsHidden">Invoice Questions
                        </div>
                      </div>
                    </a>
                  </ng-container>
                </ng-template>
              </ng-scrollbar>
            </div>
          </div>

        </div>
      </div>
        <div class="display-grid main-content-grid">
          <div>
            <div *ngIf="!popupMode" class="display-flex flex-items-centered content-main-header">
              <div class="flex-grow-1 flex-basis-0 display-flex flex-items-centered">
                <ng-template [ngxPermissionsExcept]="['Client User - Scheduled Reports Only']">
                  <div *ngIf="!popupMode" class="display-flex main-search flex-grow-1 flex-items-centered">
                    <div class="flex-grow-1">
                      <ichs-text-box (keyup.enter)="searchHelpCenter()" name="helpCenterSearchTerm"
                        placeholder="Search Help Center" [(ngModel)]="helpCenterSearchTerm"></ichs-text-box>
                    </div>
                    <div>
                      <div title="Search Help Center" class="main-search-icon" (click)="searchHelpCenter()">
                        <img src="assets/images/search.svg" />
                      </div>
                    </div>
                  </div>
                </ng-template>
              </div>

              <ng-template [ngxPermissionsExcept]="['Client User - Scheduled Reports Only', 'Impersonator']">

                <div class="upper-menu-collapsed notifications" (click)="showNotificationsList($event)">
                  <div class="position-relative" *ngIf="notificationsCount">
                    <div class="position-absolute announcement-notifications">
                      {{notificationsCount}}
                    </div>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" class='ann-icon'
                    [class.ann-icon-not-selected]="!(notificationsCount > 0)"
                    [class.ann-icon-selected]="notificationsCount > 0" (click)="showNotificationsList($event)">
                    <defs>
                      <style>
                        .ann-1 {
                          fill: #d75a4a;
                        }

                        .ann-2 {
                          fill: #34cd76;
                        }
                      </style>
                    </defs>
                    <title>announcements</title>
                    <g id="Layer_12" data-name="Layer 12">
                      <g>
                        <path class="ann-1"
                          d="M16.2,20,14.05,9.94c-.28.09-.55.2-.83.27L5,12a.84.84,0,0,0-.65,1l.18.82L2,14.31a.84.84,0,0,0-.65,1l1.05,5a.84.84,0,0,0,1,.65l2.48-.52.18.82a.83.83,0,0,0,1,.65l.5-.1,5.2,8.14a1.7,1.7,0,0,0,1.43.77,1.86,1.86,0,0,0,.34,0,1.62,1.62,0,0,0,1-.73l1.57-2.39a1.7,1.7,0,0,0-.1-2l-3.95-5,2.19-.47Z" />
                        <path class="ann-2"
                          d="M25.82,1,25.76,1a.78.78,0,0,0-.41-.19h-.06a.65.65,0,0,0-.24,0,.69.69,0,0,0-.15.07l-.09,0a.77.77,0,0,0-.31.31s0,0,0,0a17.76,17.76,0,0,1-8.83,8.12L17.9,19.91a17.74,17.74,0,0,1,11.36,3.83l0,0a1,1,0,0,0,.24.11s0,0,.08,0a.82.82,0,0,0,.35,0,.81.81,0,0,0,.27-.11,0,0,0,0,0,0,0,.85.85,0,0,0,.29-.34.36.36,0,0,0,0-.09.8.8,0,0,0,0-.46L26.05,1.44A.77.77,0,0,0,25.82,1Z" />
                      </g>
                    </g>
                  </svg>
                </div>
                <div class="position-relative" *ngIf="generalVariables.appViewModel.isNotificationsListVisible"
                  (click)="$event.stopPropagation()">
                  <user-notifications (notificationOpened)="onNotificationOpen($event)"
                    [notifications]="notifications"></user-notifications>
                </div>
              </ng-template>

              <div class="user-info-cont" *ngIf="!(loggedInUserObs | async).isImpersonated">
                <div class="display-flex flex-items-centered">
                  <button mat-button [matMenuTriggerFor]="menu" class="user-menu upper-menu-btn">
                    {{(loggedInUserObs | async).fullUserName}}
                  </button>

                  <button mat-button [matMenuTriggerFor]="menu" class="user-img-btn">
                    <div [title]="(loggedInUserObs | async).fullUserName" class="profile-img">
                      <img *ngIf="userImage" [src]="userImage | safeUrl" />
                      <ngx-avatars *ngIf="!(loggedInUserObs | async).userImageId" background="#808080" size="34"
                        [name]="(loggedInUserObs | async).userInitials"></ngx-avatars>
                    </div>
                    <div *ngIf="isUserActive != null" class="position-relative">
                      <span [title]="isUserActive ? 'Online' : 'Offline'"
                        [ngClass]="{'current-user-status': true, 'online': isUserActive, 'offline': !isUserActive}">
                      </span>
                    </div>
                  </button>
                </div>
              </div>

              <div class="user-info-cont" *ngIf="(loggedInUserObs | async).isImpersonated">
                <div class="display-flex flex-items-centered">
                  <button mat-button [matMenuTriggerFor]="menu" class="user-menu upper-menu-btn">
                    {{(loggedInUserObs | async).fullUserName}}
                    <span class="impersonator-name">
                      Impersonated by {{(loggedInUserObs | async).impersonaterFullUserName}}
                    </span>
                  </button>

                  <button mat-button [matMenuTriggerFor]="menu" class="user-img-btn">
                    <div [title]="(loggedInUserObs | async).fullUserName + ' (Impersonated)'" class="profile-img">
                      <img *ngIf="userImage" [src]="userImage | safeUrl" />
                      <ngx-avatars *ngIf="!(loggedInUserObs | async).userImageId" background="#808080" size="34"
                        [name]="(loggedInUserObs | async).userInitials"></ngx-avatars>
                    </div>
                    <div class="position-relative display-inline-block">
                      <div [title]="(loggedInUserObs | async).impersonaterFullUserName + ' (Impersonater)'"
                        class="impersonator position-absolute">
                        <img *ngIf="impersonatorUserImage"  [src]="impersonatorUserImage | safeUrl" />
                        <ngx-avatars *ngIf="!(loggedInUserObs | async).impersonaterImageId" background="#808080" size="13"
                          [name]="(loggedInUserObs | async).impersonaterInitials"></ngx-avatars>
                      </div>
                    </div>
                    <div *ngIf="isUserActive != null" class="position-relative">
                      <span [title]="isUserActive ? 'Online' : 'Offline'"
                        [ngClass]="{'current-user-status': true, 'online': isUserActive, 'offline': !isUserActive}">
                      </span>
                    </div>
                  </button>
                </div>
              </div>

              <div class="margin-left-def">
                <mat-menu #menu="matMenu" class="upper-menu">
                  <ng-template [ngxPermissionsExcept]="['Client User - Scheduled Reports Only']">
                    <button mat-menu-item (click)="personProfile()">
                      Profile
                    </button>

                    @if ((loggedInUserObs | async).isActiveDirectoryUser) {
                      <button mat-menu-item (click)="setLoginPin()">
                        Set PIN
                      </button>
                    }

                    <ng-template [ngxPermissionsExcept]="['Impersonator']">
                      <button mat-menu-item (click)="changeUserStatus()">{{isUserActive ? 'Go Offline' : 'Go Online'}}</button>
                    </ng-template>

                    <button *ngIf="(loggedInUserObs | async).isImpersonated" mat-menu-item (click)="unImpersonate()">
                      UnImpersonate
                    </button>
                    <button
                      *ngIf="!(loggedInUserObs | async).isImpersonated && (loggedInUserObs | async).hasImpersonatePermission"
                      mat-menu-item (click)="Impersonate()">
                      Impersonate
                    </button>

                    <ng-template [ngxPermissionsExcept]="['Impersonator']">
                      <ng-container *ngIf="!generalVariables.appViewModel.rightIsDisabled">
                        <button mat-menu-item class="upper-menu-collapsed" (click)="generalVariables.appViewModel.toggleAdminMenu()">
                          {{generalVariables.appViewModel.adminMenuIsHidden? 'Show':'Hide'}} Admin Menu
                        </button>
                        <button mat-menu-item class="upper-menu-collapsed" (click)="generalVariables.appViewModel.toggleContacts()">
                          {{generalVariables.appViewModel.contactsIsHidden? 'Show':'Hide'}} Contacts
                        </button>
                      </ng-container>
                    </ng-template>

                    <hr class="client-portal-hr-s" />
                  </ng-template>

                  <button mat-menu-item (click)="logout()">Logout</button>
                </mat-menu>
              </div>

            </div>
          </div>
          <div>
          <hr class="main-hr" />

          </div>

          <div class="client-body display-flex" [class.client-body-popup]="popupMode">
            <div class="client-out flex-grow-1">
              <ng-scrollbar class="main-content-scroll" [appearance]="'standard'" *ngIf="!popupMode">
                <router-outlet></router-outlet>
              </ng-scrollbar>

              <ng-container *ngIf="popupMode">
                <div>
                  <router-outlet></router-outlet>
                </div>
              </ng-container>

              <div>
                <app-footer></app-footer>
              </div>
            </div>
            <ng-template [ngxPermissionsExcept]="['Client User - Scheduled Reports Only', 'Impersonator']">
              <div *ngIf="!popupMode && !generalVariables.appViewModel.rightIsDisabled">
                <div class="position-relative right-section-btn-cont">
                  <button class="cp-side-collapse right-section-btn margin-left-def position-absolute"
                    (click)="generalVariables.appViewModel.toggleRight()"
                    [class.expanded]="!(generalVariables.appViewModel.contactsIsHidden && generalVariables.appViewModel.adminMenuIsHidden)">
                    <svg class="side-collapse-icon" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                      xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 60 60"
                      style="enable-background:new 0 0 20 20;" xml:space="preserve">
                      <style type="text/css"></style>
                      <path class="st0" d="M30,0.7C13.9,0.7,0.7,13.9,0.7,30S13.9,59.3,30,59.3S59.3,46.1,59.3,30S46.1,0.7,30,0.7z" />

                      <path class="cls-1"
                        d="M24.3,37c-1.2,1.2-1.2,3.1,0,4.3l0,0c1.2,1.2,3.1,1.2,4.3,0l9-8.9c1.3-1.3,1.3-3.4,0-4.7l0,0l-9-8.9 c-1.2-1.2-3.1-1.2-4.3,0c-1.2,1.2-1.2,3.1,0,4.3l0,0l7.1,7C31.4,30,24.3,37,24.3,37z" />
                    </svg>
                  </button>
                </div>
                <div class="side-cont right display-flex flex-col"
                  [class.collapsed]="generalVariables.appViewModel.contactsIsHidden && generalVariables.appViewModel.adminMenuIsHidden">
                  <div class="display-flex align-items-center">
                    <div class="upper-menu-btn">
                      <button class="cp-btn cp-icon-btn" title="show/hide contacts"
                        (click)="generalVariables.appViewModel.toggleContacts()">

                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                          viewBox="0 0 40 40">
                          <g id="contactsActive">
                            <g id="friends" transform="translate(1 -51.142)">
                              <g id="Group_598" data-name="Group 598" transform="translate(4.464 57.142)">
                                <g id="Group_597" data-name="Group 597">
                                  <path id="Path_4740" data-name="Path 4740" class="cls-1"
                                    d="M68.616,57.142a7.162,7.162,0,1,0,6.743,7.149A6.963,6.963,0,0,0,68.616,57.142Z"
                                    transform="translate(-61.873 -57.142)" />
                                </g>
                              </g>
                              <g id="Group_600" data-name="Group 600" transform="translate(20.812 58.165)">
                                <g id="Group_599" data-name="Group 599">
                                  <path id="Path_4741" data-name="Path 4741" class="cls-1"
                                    d="M293.421,71.321h-.046a5.594,5.594,0,0,0-4.1,1.855,6.386,6.386,0,0,0,.071,8.61,5.6,5.6,0,0,0,4.087,1.787h.047a5.594,5.594,0,0,0,4.1-1.855,6.278,6.278,0,0,0,1.634-4.319A5.975,5.975,0,0,0,293.421,71.321Z"
                                    transform="translate(-287.637 -71.321)" />
                                </g>
                              </g>
                              <g id="Group_602" data-name="Group 602" transform="translate(19.536 71.633)">
                                <g id="Group_601" data-name="Group 601">
                                  <path id="Path_4742" data-name="Path 4742" class="cls-1"
                                    d="M277.3,257.358h-.56a10.083,10.083,0,0,0-6.784,2.616,13.018,13.018,0,0,1,5.083,9.658H287.42v-2.155a10.131,10.131,0,0,0-10.12-10.119Z"
                                    transform="translate(-269.96 -257.358)" />
                                </g>
                              </g>
                              <g id="Group_604" data-name="Group 604" transform="translate(0 73.723)">
                                <g id="Group_603" data-name="Group 603">
                                  <path id="Path_4743" data-name="Path 4743" class="cls-1"
                                    d="M22.393,296.5a10.868,10.868,0,0,0-10.848-10.185h-.674A10.87,10.87,0,0,0,0,297.19v1.289H22.414v-1.29Q22.414,296.843,22.393,296.5Z"
                                    transform="translate(0 -286.318)" />
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div class="display-flex flex-items-centered flex-grow-1">
                      <div class="flex-basis-0 flex-grow-1">
                        <h3 class="section-title overflow-hidden" *ngIf="hideAddContactForm"
                          (click)="generalVariables.appViewModel.toggleContacts()">Contacts</h3>
                      </div>

                      <form #addContactForm="ngForm" novalidate>
                        <div [class.add-contact-div-expanded]="!hideAddContactForm"
                          class="add-contact-div-hidden display-flex flex-items-centered"
                          style="background-color:#94BD6A;">

                          <div class="flex-grow-1 flex-basis-0" [class.margin-left-def]="!hideAddContactForm"
                            style="overflow:hidden;">
                            <div [hidden]="hideAddContactForm">

                              <ng-container *ngIf="!isContactsAutoCompleteOn">
                                <input #contactsSearchBox class="contact-search-txt" type="email" name="contactEmail"
                                  placeholder="Enter Contact Email" [(ngModel)]="contactEmail" email required>
                              </ng-container>

                              <ng-container *ngIf="isContactsAutoCompleteOn">
                                <input #contactsSearchBox class="contact-search-txt" type="email" name="contactEmail"
                                  placeholder="Enter Contact Email" [(ngModel)]="contactEmail"
                                  (ngModelChange)="emailModelChanged.next($event)" [matAutocomplete]="auto" email
                                  required>

                                <mat-autocomplete #auto="matAutocomplete">
                                  <mat-option *ngFor="let userEmail of userEmails" [value]="userEmail"
                                    [title]="userEmail">
                                    {{userEmail}}
                                  </mat-option>
                                </mat-autocomplete>
                              </ng-container>

                            </div>
                          </div>

                          <div>
                            <button style="position:relative; left:7px;" *ngIf="!hideAddContactForm"
                              (click)="addContact()" class="add-contacts-action">
                              Add
                            </button>
                          </div>

                          <div [class.hidden]="generalVariables.appViewModel.contactsIsHidden">
                            <button (click)="toggleContactAdd()" class="add-contacts-btn">
                              <img src="assets/images/newIcons/addContacts.svg"
                                [class.rotate-img]="!hideAddContactForm" />
                            </button>
                          </div>

                        </div>
                      </form>
                    </div>
                  </div>

                  <ng-scrollbar appearance="compact" class="flex-grow-1 right-section"
                    [class.hidden]="generalVariables.appViewModel.contactsIsHidden">
                    <div class="contacts-cont">

                      <appuser-card *ngFor="let userCard of (medcorUserInfoService.relatedAppUsersObs | async)"
                        [userContact]="userCard"
                        [status]="getUserStatus(userCard)"
                        (openCard)="onCardOpened($event)"
                        (openChat)="openChat(userCard)"
                        [draggable]="allowContactsDrag"
                        [style]="allowContactsDrag ? 'cursor: grab' : ''"
                        (dragstart)="drag($event,userCard)"
                        [allowSelect]="allowSelectContacts">
                      </appuser-card>

                    </div>
                  </ng-scrollbar>

                  <ng-container *ngIf="!impersonatorRoleInd && (adminRoleInd || (loggedInUserObs | async).allowResetPasswordInd || (loggedInUserObs | async).allowAddAppUserInd ||
                                    (loggedInUserObs | async).pageDesignAccess || (loggedInUserObs | async).allowMaintainCalls)">

                                    <div *ngIf="!generalVariables.appViewModel.contactsIsHidden">
                                      <hr class="main-hr" />
                                    </div>
                                    <div class="display-flex align-items-center">
                                      <div class="upper-menu-btn">
                                        <button class="cp-btn cp-icon-btn" title="show/hide Admin Menu"
                                          (click)="generalVariables.appViewModel.toggleAdminMenu()">
                                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                            viewBox="0 0 40 40">
                                            <g id="admainMenuActive">
                                              <g id="admain_items" data-name="admain items" transform="translate(-407 -802)">
                                                <g id="ADMAIN_ITEMS-2" data-name="ADMAIN ITEMS" transform="translate(-1368 774)">
                                                  <rect id="Rectangle_62" data-name="Rectangle 62" class="cls-1" width="9.872"
                                                    height="8.077" rx="1" transform="translate(1778 32)" />
                                                  <rect id="Rectangle_63" data-name="Rectangle 63" class="cls-1" width="9.872"
                                                    height="8.077" rx="1" transform="translate(1790.564 32)" />
                                                  <rect id="Rectangle_64" data-name="Rectangle 64" class="cls-1" width="9.872"
                                                    height="8.077" rx="1" transform="translate(1803.128 32)" />
                                                  <rect id="Rectangle_65" data-name="Rectangle 65" class="cls-1" width="9.872"
                                                    height="8.077" rx="1" transform="translate(1778 44.066)" />
                                                  <rect id="Rectangle_66" data-name="Rectangle 66" class="cls-1" width="9.872"
                                                    height="8.077" rx="1" transform="translate(1790.564 44.066)" />
                                                  <rect id="Rectangle_67" data-name="Rectangle 67" class="cls-1" width="9.872"
                                                    height="8.077" rx="1" transform="translate(1803.128 44.066)" />
                                                  <rect id="Rectangle_68" data-name="Rectangle 68" class="cls-1" width="9.872"
                                                    height="8.077" rx="1" transform="translate(1778 56.133)" />
                                                  <rect id="Rectangle_69" data-name="Rectangle 69" class="cls-1" width="9.872"
                                                    height="8.077" rx="1" transform="translate(1790.564 56.133)" />
                                                  <rect id="Rectangle_70" data-name="Rectangle 70" class="cls-1" width="9.872"
                                                    height="8.077" rx="1" transform="translate(1803.128 56.133)" />
                                                </g>
                                              </g>
                                            </g>
                                          </svg>
                                        </button>
                                      </div>

                                      <div class="flex-basis-0 flex-grow-1">
                                        <h3 class="section-title overflow-hidden" (click)="generalVariables.appViewModel.toggleAdminMenu()">Administration</h3>
                                      </div>
                                    </div>
                  </ng-container>

                  <ng-scrollbar appearance="compact" class="flex-grow-1 right-section"
                    [class.hidden]="generalVariables.appViewModel.adminMenuIsHidden"
                    *ngIf="adminRoleInd || (loggedInUserObs | async).allowResetPasswordInd || (loggedInUserObs | async).allowAddAppUserInd
                                                     || (loggedInUserObs | async).pageDesignAccess || (loggedInUserObs | async).allowMaintainCalls">

                    <div>
                      <ng-container
                        *ngIf="adminRoleInd || (loggedInUserObs | async).allowResetPasswordInd || (loggedInUserObs | async).allowAddAppUserInd || (loggedInUserObs | async).appUsersReadOnlyRestriction">
                        <a [routerLink]="['/../appuser']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Application Users">
                              <img src="assets/images/newIcons/applicationUsers.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Application Users
                            </div>
                          </div>
                        </a>
                      </ng-container>
                      <ng-container *ngxPermissionsOnly="['Admin']">
                        <a [routerLink]="['/applications-access']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Applications Access">
                              <img src="assets/images/newIcons/applicationsAccess.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Applications Access
                            </div>
                          </div>
                        </a>
                      </ng-container>
                      <ng-container *ngIf="adminRoleInd || (loggedInUserObs | async).allowAddAppUserInd">
                        <a [routerLink]="['/app-group']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Groups">
                              <img src="assets/images/newIcons/groups.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Groups
                            </div>
                          </div>
                        </a>
                      </ng-container>
                      <ng-container *ngIf="adminRoleInd">
                        <a [routerLink]="['/medfiles-virtual-company']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Medfiles Virtual Companies">
                              <img src="assets/images/newIcons/medfilesVirtualCompanies.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Medfiles Virtual Companies
                            </div>
                          </div>
                        </a>
                      </ng-container>
                      <ng-container
                        *ngIf="(loggedInUserObs | async).triageUserMaintenanceAccessible && (adminRoleInd || (loggedInUserObs | async).allowMaintainTriageUsers)">
                        <a [routerLink]="['/triage-portal-user-pin-code']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Telehealth Users PIN Codes">
                              <img src="assets/images/newIcons/triageUsersPinCodes.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Telehealth Users PIN Codes
                            </div>
                          </div>
                        </a>
                      </ng-container>
                      <ng-container *ngxPermissionsOnly="['Admin']">
                        <a [routerLink]="['/report-item']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Report Items">
                              <img src="assets/images/newIcons/reportsetting.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Report Items
                            </div>
                          </div>
                        </a>
                      </ng-container>
                      <ng-container *ngxPermissionsOnly="['Admin']">
                        <a [routerLink]="['/active-users']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Active Users">
                              <img src="assets/images/newIcons/activeUsers.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Active Users
                            </div>
                          </div>
                        </a>
                      </ng-container>
                      <ng-container *ngIf="(loggedInUserObs | async).pageDesignAccess">
                        <a [routerLink]="['/page-design']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Page Design">
                              <img src="assets/images/newIcons/pageDesign.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Page Design
                            </div>
                          </div>
                        </a>
                      </ng-container>
                      <ng-container *ngxPermissionsOnly="['Admin']">
                        <a [routerLink]="['/dashboard-item']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Dashboard Items">
                              <img src="assets/images/newIcons/dashboardSetting.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Dashboard Items
                            </div>
                          </div>
                        </a>

                      </ng-container>
                      <ng-container *ngxPermissionsOnly="['Admin']">
                        <a [routerLink]="['/announcements']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Announcements">
                              <img src="assets/images/annoucement.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Announcements
                            </div>
                          </div>
                        </a>
                      </ng-container>
                      <ng-container *ngxPermissionsOnly="['Admin']">
                        <a [routerLink]="['/shared-folder-maintenance']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Shared Folder Maintenance">
                              <img src="assets/images/newIcons/sharedFoldersMaint.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Shared Folder Maintenance
                            </div>
                          </div>
                        </a>
                      </ng-container>
                      <ng-container *ngxPermissionsOnly="['Admin']">
                        <a [routerLink]="['/change-log']" routerLinkActive="menu-selected"
                          *ngIf="showChangeLog && (loggedInUserObs | async).hasChangeLogAccess">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Change Log">
                              <img src="assets/images/newIcons/changeLog.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Change Log
                            </div>
                          </div>
                        </a>
                      </ng-container>
                      <ng-container *ngxPermissionsOnly="['Admin']">
                        <a [routerLink]="['/app-log']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="App Log">
                              <img src="assets/images/newIcons/appLog.svg" />
                            </div>
                            <div class="menu-item-txt">
                              App Log
                            </div>
                          </div>
                        </a>
                      </ng-container>
                      <ng-container *ngxPermissionsOnly="['Admin']">
                        <a [routerLink]="['/help-category']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Help Categories">
                              <img src="assets/images/userIcons/helpCatogries.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Help Categories
                            </div>
                          </div>
                        </a>
                      </ng-container>
                      <ng-container *ngxPermissionsOnly="['Admin']">
                        <a [routerLink]="['/help-question']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Help Questions">
                              <img src="assets/images/userIcons/helpCenter.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Help Questions
                            </div>
                          </div>
                        </a>
                      </ng-container>
                      <ng-container *ngxPermissionsOnly="['Admin']">
                        <a [routerLink]="['/release-notes']" routerLinkActive="menu-selected">
                          <div class="menu-item display-flex flex-items-centered selected-menu-inner-div admin-menu">
                            <div class="menu-item-icon" title="Release Notes">
                              <img src="assets/images/userIcons/releaseNotes.svg" />
                            </div>
                            <div class="menu-item-txt">
                              Release Notes
                            </div>
                          </div>
                        </a>
                      </ng-container>
                    </div>
                  </ng-scrollbar>

                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #firstLoginBlock
    [ngIf]="((loggedInUserObs | async).firstLoginInd || (loggedInUserObs | async).changePasswordInd) && !(loggedInUserObs | async).isImpersonated">
    <first-login [loggedInUserInfo]="(loggedInUserObs | async)"></first-login>
  </ng-template>
  <ng-template #loginBlock [ngIf]="!(authenticatedObs | async) && !triageSecuredFileAccessedUsingPinCode">
    <ng-scrollbar appearance="compact" class="fill-parent" style="height:100vh;">
      <div class="main-container content-container">
        <ng-container *ngIf="mainActivePage == 0 || mainActivePage == 4">
          <div class="login-back" *ngIf="mainActivePage <= 1">
            <img class="login-back-img" ngSrc="assets/images/loginBack.jpg" fill priority />
          </div>
          <div class="display-flex flex-items-centered container-padding">
            <div class="logo-cont" *ngIf="mainActivePage == 4">
              <img src="assets/images/medcorLogo.svg" class="main-logo" />
              <span class="logo-text health-text">Health Navigation & Clinical Service</span>
            </div>
            <div class="flex-grow-1 flex-basis-0"></div>
          </div>
          <div class="position-relative login-form-cont" *ngIf="mainActivePage != 4 && (!showTriageSecuredFilePage || (showTriageSecuredFilePage && userHasAccount))" (click)="$event.stopPropagation()">
            <div class="login-form">
              <login class="display-flex flex-grow-1" style="height:100%"></login>
            </div>
          </div>
          <div class="position-relative login-form-cont" *ngIf="showTriageSecuredFilePage && !userHasAccount"
            (click)="$event.stopPropagation()">
            <div class="login-form triage-secured-file-pincode-form">
              <triage-secured-file-pincode-form style="height:100%" [encryptedData]="triageSecuredFileEncryptedData"
                (pinCodeSubmitted)="onTriageSecuredFilePinCodeSubmit($event)">
              </triage-secured-file-pincode-form>>
            </div>
          </div>
        </ng-container>

        <div [ngSwitch]="mainActivePage">
          <email-external-view *ngSwitchCase="1"></email-external-view>
          <external-file-request *ngSwitchCase="2"></external-file-request>
          <care-instruction *ngSwitchCase="4" style="display:block;"></care-instruction>
          <change-password *ngSwitchCase="5" (redirectToLogin)="redirectToLoginFromReset()"></change-password>
          <recover-password *ngSwitchCase="6" (redirectToLogin)="redirectToLoginFromReset()"></recover-password>
          <external-care-instruction *ngSwitchCase="7"></external-care-instruction>
          <triage-portal-user-pincode-access *ngSwitchCase="8"></triage-portal-user-pincode-access>
          <external-triage-secured-file *ngSwitchCase="9"></external-triage-secured-file>
          <external-signature-request *ngSwitchCase="10"></external-signature-request>
          <create-secure-messaging-account *ngSwitchCase="11"></create-secure-messaging-account>
          <grant-app-access *ngSwitchCase="12"></grant-app-access>
          <responsive *ngSwitchCase="13"></responsive>
          <mfa-reset *ngSwitchCase="14" (redirectToLogin)="redirectToLoginFromReset()"></mfa-reset>
        </div>
      </div>
      <div *ngIf="mainActivePage == 0" class="main-container"
        [ngClass]="{'full-width':generalVariables.clientStatisticsPage}">
        <app-footer [termsOfService]="true"></app-footer>
      </div>
    </ng-scrollbar>
  </ng-template>

  <ng-template #triageSecuredFileBlock [ngIf]="showTriageSecuredFilePage && triageSecuredFileAccessedUsingPinCode">
    <triage-secured-file [email]="userEmail" [pinCode]="triageSecuredFilePinCode"
      [encryptedData]="triageSecuredFileEncryptedData" (registerOrLogoutEvent)="onTriageUserRegisterOrLogout()">
    </triage-secured-file>
  </ng-template>

  <div *ngIf="generalVariables.appViewModel.isUserCardOpen" class="position-absolute" style="top:0px; left:0px;">
    <div class="contact-card-overlay" (click)="hideUserCard()"></div>
    <div class="position-absolute" [style.top.px]="generalVariables.appViewModel.selectedCard.cardY"
      [style.left.px]="generalVariables.appViewModel.selectedCard.cardX">
      <appuser-card-template [appuser]="generalVariables.appViewModel.selectedCard.userContact"></appuser-card-template>
    </div>
  </div>
</div>
