import { Component, OnInit } from '@angular/core';
import { IchsGridComponentConfig, IchsControlType } from 'src/app/controls/ichs-grid/grid-configs';
import { HelpCategoryViewModel, HelpCategoryService, SearchViewModel, VoidInfoViewModel } from 'src/app/rest';

@Component({
  selector: 'help-center-category-list',
  templateUrl: './help-center-category-list.component.html',
  styleUrls: ['./help-center-category-list.component.css'],
  host: {
    class: "data-list"
  }
})
export class HelpCenterCategoryListComponent implements OnInit {
  gridConfig: IchsGridComponentConfig<HelpCategoryViewModel>;

  /** group ctor */
  constructor(
    private categoryService: HelpCategoryService,
  ) {
    this.gridConfig = this.defineGrid();
  }

  /** GroupList OnInit*/
  ngOnInit(): void {
    this.refresh()
  }

  /** This function is used to get the Group list*/
  refresh() {
  }


  private defineGrid(): IchsGridComponentConfig<HelpCategoryViewModel> {
    let configs: IchsGridComponentConfig<HelpCategoryViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Help Categories",
      entityController: "help-category",
      hasVoid: true,
      hasNew: true,
      voidAction: (reason: VoidInfoViewModel) => this.categoryService._void(reason),
      recoverAction: (id: number) => this.categoryService.recover(id),
      entityDataSource: (filter: SearchViewModel) => this.categoryService.search(filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ],
    }

    return configs;
  }
}
