import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ManageSecQuestionViewModel } from '../../rest/model/manageSecQuestionViewModel';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUserService, SelectionListService, SelectionItemViewModel } from '../../rest/index';
import { Location } from '@angular/common';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from '../../services/medcor-alert.service';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { DIALOG_REF, IchsComponentDialogComponent } from 'src/app/controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { SharedFunctionsService } from '../../services/shared-functions.service';
import { ValidationMessages } from 'src/app/app.general.constants';

@Component({
  selector: 'manage-security-questions',
  templateUrl: './manage-security-questions.component.html',
  styleUrls: ['./manage-security-questions.component.css']
})
export class ManageSecurityQuestionsComponent implements OnInit {
  @ViewChild('form') ngForm: NgForm;
  questionsList: SelectionItemViewModel[] = [];
  model: ManageSecQuestionViewModel = <ManageSecQuestionViewModel>{ secQuestionAssociationList: [{}, {}, {}] };

  get selectedQuestionsList(): string[] {
    if (this.model.secQuestionAssociationList == null)
      return [];
    else
      return this.model.secQuestionAssociationList.map(item => item.question);
  }

  constructor(
    private _route: ActivatedRoute,
    private router: Router,
    private appUserService: AppUserService,
    private location: Location,
    private alertService: MedcorAlertService,
    private dialog: MatDialog,
    private selectionService: SelectionListService,
    private sharedFunctions: SharedFunctionsService,
    @Inject(DIALOG_REF) private dialogRef: MatDialogRef<IchsComponentDialogComponent>,
  ) {

  }

  ngOnInit() {
    this.selectionService.getSelectionItems(108000000000001).pipe(first()).subscribe(lst => {
      this.questionsList = lst.result![0].items;
    });

    this.appUserService.getSecurityQuestions().pipe(first()).subscribe(resp => {
      this.model = resp.result;
    });
  }

  getFilteredQuestionsList(selected: string): SelectionItemViewModel[] {
    return this.questionsList.filter((question) => {
      if (this.selectedQuestionsList.indexOf(question.selectionValue) == -1 || question.selectionValue == selected)
        return true;
    });
  }

  save() {
    this.sharedFunctions.validateAllFormFields(this.ngForm.form);
    if (this.ngForm.form.valid) {
      this.appUserService.updateSecQuestions(this.model).pipe(first()).subscribe(() => this.close());
    }
    else {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}
