<div [ngSwitch]="appuser.appUserExtension!.businessCardTemplate">
    <div class="card-template1" *ngSwitchDefault>
        <div class="contact-card">
            <div class="display-flex flex-items-centered fill-parent">
                <div style="min-width:140px;">
                    <div class="card-logo">
                        <img *ngIf="logoImage" [src]="logoImage" />
                    </div>
                </div>
                <div style="align-self:stretch;">
                    <div class="display-flex flex-items-centered fill-parent">
                        <div class="card-sep"></div>
                    </div>
                </div>
                <div class="flex-basis-0 flex-grow-1 text-left">
                    <h4>{{appuser.name}}</h4>
                    <div>{{appuser.appUserExtension.title}}</div>
                    <br />
                    <div>
                        <div><span>office: <a [href]="officePhone" target="_top">{{appuser.appUserExtension.office}}</a></span></div>
                        <div><span>fax: {{appuser.appUserExtension.fax}}</span></div>
                        <ng-container *ngIf="!previewOnly">
                          <button mat-button [matMenuTriggerFor]="menu" class="card-btn"><div><span>{{appuser.email}}</span></div></button>
                          <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="sendSecureEmail()">Send Secure Email</button>
                            <a mat-menu-item [href]="regularEmail" target="_top">Send Regular Email</a>
                          </mat-menu>
                        </ng-container>
                        <ng-container *ngIf="previewOnly">
                          <div><span>{{appuser.email}}</span></div>
                        </ng-container>
                        
                    </div>
                </div>
                <div class="fill-parent">
                    <div class="card-website fill-parent">
                        <span>{{appuser.appUserExtension.website}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-template2" *ngSwitchCase="'Temp02'">
        <div class="contact-card ">
            <div class="display-flex flex-items-centered fill-parent">
                <div class="fill-parent">
                    <div class="card-website fill-parent">
                        <span>{{appuser.appUserExtension.website}}</span>
                    </div>
                </div>

                <div class="flex-basis-0 flex-grow-1">
                    <div style="min-width:140px;" class="text-right">
                        <div class="card-logo">
                            <img *ngIf="logoImage" [src]="logoImage" />
                        </div>
                    </div>
                    <div class="card-name">
                        <h4>{{appuser.name}}</h4>
                        <div>{{appuser.appUserExtension.title}}</div>
                    </div>
                    <div class="display-flex">
                        <div class="flex-grow-1 flex-basis-0"></div>
                        <div>
                          <div class="card-info">
                            <div><span>office: <a [href]="officePhone" target="_top">{{appuser.appUserExtension.office}}</a></span></div>
                            <div><span>fax:{{appuser.appUserExtension.fax}}</span></div>
                            <ng-container *ngIf="!previewOnly">
                              <button mat-button [matMenuTriggerFor]="menu" class="card-btn"><div><span>{{appuser.email}}</span></div></button>
                              <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="sendSecureEmail()">Send Secure Email</button>
                                <a mat-menu-item [href]="regularEmail" target="_top">Send Regular Email</a>
                              </mat-menu>
                            </ng-container>
                            <ng-container *ngIf="previewOnly">
                              <div><span>{{appuser.email}}</span></div>
                            </ng-container>
                          </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>

    <div class="card-template3" *ngSwitchCase="'Temp03'">
        <div class="contact-card ">
            <div class="display-flex flex-col fill-parent">
                <div class="flex-grow-1">
                    <div class="card-name">
                        <h4>{{ appuser.name }}</h4>
                        <div>{{appuser.appUserExtension.title}}</div>
                    </div>


                    <div class="display-flex flex-items-centered">
                        <div class="flex-grow-1 flex-basis-0 text-center">
                            <div class="card-logo">
                                <img *ngIf="logoImage" [src]="logoImage" />
                            </div>
                        </div>
                        <div>
                          <div class="card-info">
                            <div><span> <a [href]="officePhone" target="_top">{{appuser.appUserExtension.office}}</a></span></div>
                            <div><span>fax:{{appuser.appUserExtension.fax}}</span></div>
                            <ng-container *ngIf="!previewOnly">
                              <button mat-button [matMenuTriggerFor]="menu" class="card-btn"><div><span>{{appuser.email}}</span></div></button>
                              <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="sendSecureEmail()">Send Secure Email</button>
                                <a mat-menu-item [href]="regularEmail" target="_top">Send Regular Email</a>
                              </mat-menu>
                            </ng-container>
                            <ng-container *ngIf="previewOnly">
                              <div><span>{{appuser.email}}</span></div>
                            </ng-container>
                          </div>
                        </div>
                    </div>

                </div>
                <div>
                    <div class="card-website">
                        <span>{{appuser.appUserExtension.website}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>
