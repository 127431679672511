import { Component, OnInit, Inject, OnDestroy, ViewChild } from '@angular/core';
import { AccountsService, AppUserService } from '../../rest/index';
import { first, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MedcorAuthenticationService } from '../../services/medcor-authentication.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, Subscription, of } from 'rxjs';
import { NgForm } from '@angular/forms';
import { MedcorAlertService, AlertMessageType } from '../../services/medcor-alert.service';
import { AppSettings } from '../../app.general.constants';

@Component({
  selector: 'impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.css']
})
export class ImpersonateComponent implements OnInit, OnDestroy {
  emailModel: string;
  emailModelChanged: Subject<string> = new Subject<string>();
  userEmails: string[] = [];
  subs: Subscription;

  @ViewChild("form") ngForm: NgForm;

  constructor(
    private accountService: AccountsService,
    private authenticationService: MedcorAuthenticationService,
    private appuserService: AppUserService,
    private alertService: MedcorAlertService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit() {
    this.subs = this.emailModelChanged
      .pipe(
        debounceTime(400),
        distinctUntilChanged(),
        switchMap(value => value.length > 2 ? this.appuserService.searchUserEmails(value) : of(null)),
      )
      .subscribe(res => this.userEmails = res ? res.result : []);
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.unsubscribe();
    }
  }

  submit() {
    if (this.ngForm.controls.email.invalid) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        messages: ["Invalid email address!"],
      });
      return;
    }

    this.accountService.impersonate(this.emailModel).pipe(first()).subscribe(() => {
      this.authenticationService.impersonate();
      location.assign(AppSettings.UI_ORIGIN_PATH); // navigate & refresh
    });
  }
}
