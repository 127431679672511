<form #form="ngForm">
    <div *ngFor="let node of selectedValues,let i = index" class="display-flex">
      <div class="display-flex tree-margin-bottom">
        <div class="node-actions">
          <img *ngIf="!node.multiple" src="assets/images/add-button.svg" (click)="addNew()" class="business-btn" />
          <img src="assets/images/delete-button.svg" (click)="deleteNode(node)" class="business-btn" />
        </div>

        <div class="bl-control">
          <ichs-select name="lineOfBusinessSelect_{{i}}"
                       placeholder="Line of Business"
                       [items]="lineOfBusiness"
                       (selectionChanged)="selectedLOBChanged($event,node)"
                       [(ngModel)]="node.lineOfBusiness"
                       class="bl-control"></ichs-select>
        </div>
        <div *ngIf="node.lineOfBusiness" class="bl-control margin-left-def ra-control">
          <ichs-select name="propertySelect_{{i}}"
                       placeholder="Select Property"
                       [items]="propertyList[node.lineOfBusiness]"
                       (selectionChanged)="selectedPropertyChanged($event,node)"
                       [(ngModel)]="node.property"
                       class="bl-control" required></ichs-select>
        </div>
        <div *ngIf="node.property" class="margin-left-def">Not Equal</div>
        <div *ngIf="node.property" class="bl-control margin-left-def ra-control" >
          
          <ichs-select name="propertyValueSelect{{i}}"
                       placeholder="Property Value"
                       [items]="propertyListValue[node.propertyValueListId]"
                       [(ngModel)]="node.propertyValue"
                       class="bl-control" required></ichs-select>
        </div>

      </div>
      <br />
    </div>

</form>

