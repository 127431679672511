import { Component, OnInit } from '@angular/core';
import { EmailSearchViewModel, SecuredEmailsService, SearchViewModel, SelectionItemViewModel, AppUserService } from '../../rest/index';
import { ActivatedRoute, Router } from '@angular/router';
import { first, switchMap } from 'rxjs/operators';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { of, Subscription } from 'rxjs';
import * as moment from 'moment';
import { IchsDialogComponent } from 'src/app/controls/ichs-dialog/ichs-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'emails-list',
  templateUrl: './emails-list.component.html',
  styleUrls: ['./emails-list.component.css'],
  host: {
    class: "data-list"
  }
})
export class EmailsListComponent implements OnInit {
  mailBox: string;
  emails: EmailSearchViewModel[];
  sortColumn: string = "sentReceiveDate";
  sortMode: string = "Desc";
  pageIndex: number = 0;
  pageSize: number = 10;
  pageLength: number = 50;
  searchFormToggle: boolean;
  emailFilter: EmailSearchViewModel = <EmailSearchViewModel>{};
  inboxEmailsCounterSubscription: Subscription;
  notificationEmailsCounterSubscription: Subscription;

  readonly deliveryMethodItems: SelectionItemViewModel[] =
    [
      { selectionText: "Email", selectionValue: "Email" },
      { selectionText: "Mobile SM", selectionValue: "Mobile SM" }
    ];

  constructor(
    private emailService: SecuredEmailsService,
    private router: Router,
    private route: ActivatedRoute,
    private generalVariablesService: GeneralVariablesService,
    private appuserService: AppUserService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.mailBox = this.route.snapshot.url[0].path;
    this.loadData(0);

    this.inboxEmailsCounterSubscription = this.generalVariablesService.inboxEmailsCountChangeObs
      .subscribe(() => {
        if (this.mailBox == "inbox" && this.pageIndex == 0) {
          this.loadData(this.pageIndex);
        }
      });

    this.notificationEmailsCounterSubscription = this.generalVariablesService.notificationEmailsCountChangeObs
      .subscribe(() => {
        if (this.mailBox == "notifications" && this.pageIndex == 0) {
          this.loadData(this.pageIndex);
        }
      });
  }

  ngOnDestroy() {
    this.inboxEmailsCounterSubscription.unsubscribe();
    this.notificationEmailsCounterSubscription.unsubscribe();
  }

  loadData(pageIndex: number) {
    let objSearch: SearchViewModel = {
      filterExpression: this.formatSearchExpression(),
      pageSize: this.pageSize,
      pageIndex: pageIndex + 1,
      sortExpressions: [this.sortColumn + " " + this.sortMode]
    };

    let date = undefined;
    if (this.emailFilter.sentReceiveDate) {
      date = moment(this.emailFilter.sentReceiveDate).hour(12).toDate();
    }

    this.emailService.listEmails(this.mailBox, date, objSearch).pipe(first()).subscribe(result => {
      this.emails = result.result!.entities!;
      this.pageIndex = result.result!.pageIndex! - 1;
      this.pageSize = result.result!.pageSize!;
      this.pageLength = result.result!.totalItems!;
    });
  }

  clearFilters() {
    this.emailFilter = <EmailSearchViewModel>{};
    this.loadData(0);
  }

  formatSearchExpression(): string {
    let searchExp: string = "";

    searchExp += this.emailFilter.title && this.emailFilter.title.length > 0 ? 'title.contains("' + this.emailFilter.title + '") && ' : "";
    searchExp += this.emailFilter.senderName && this.emailFilter.senderName.length > 0 ? 'senderName.contains("' + this.emailFilter.senderName + '") && ' : "";
    searchExp += this.emailFilter.hasAttachments != undefined ? "hasAttachments == " + this.emailFilter.hasAttachments + " && " : "";
    searchExp += this.emailFilter.isSharedInboxMessage != undefined ? "isSharedInboxMessage == " + this.emailFilter.isSharedInboxMessage : "";
    
    if (searchExp.endsWith(" && ")) {
      searchExp = searchExp.substring(0, searchExp.length - 4);
    }
    return searchExp;
  }

  openEmail(email: EmailSearchViewModel) {
    switch (this.mailBox) {
      case "draft":
        this.router.navigate(["../new/" + email.id], { relativeTo: this.route });
        break;
      case "inbox":
        this.router.navigate(["../inbox/" + email.id], { relativeTo: this.route });
        break;
      case "sent":
        this.router.navigate(["../sent/" + email.id], { relativeTo: this.route });
        break;
      case "archive":
        this.router.navigate(["../archive/" + email.id], { relativeTo: this.route });
        break;
      case "saved":
        this.router.navigate(["../saved/" + email.id], { relativeTo: this.route });
        break;
      case "notifications":
        this.router.navigate(["../notifications/" + email.id], { relativeTo: this.route });
      default:
    }
  }

  onEmailChanged() {
    this.loadData(this.pageIndex);
  }

  sortBy(column: string) {
    if (this.sortColumn == column) {
      this.sortMode = this.sortMode == "Desc" ? "Asc" : "Desc";
    } else {
      this.sortColumn = column;
      this.sortMode = "Desc";
    }
    this.loadData(0);
  }

  toggleSearchForm() {
    this.searchFormToggle = !this.searchFormToggle;
  }

  deleteAllArchive() {

    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Delete Archieved Emails", message: "Are you sure about deleting all archieved emails?" }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.emailService.deleteAllArchivedEmails().pipe(first(), switchMap(() => {
          this.loadData(this.pageIndex);
          return this.appuserService.getUserCountersSummary().pipe(first());
        })).subscribe(response => {
          if (response !== null) {
            this.generalVariablesService.updateSystemCounters(response.result);
          }
        });
      }
    });
  }
}
