import { Directive, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, Validator, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';

@Directive({
    selector: '[validateEqual][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualValidator), multi: true }
    ]
})
export class EqualValidator implements Validator {
    @Input() public relatedControl: AbstractControl;

    validate(confirmationControl: AbstractControl): ValidationErrors | null {

        if (!confirmationControl.value) {
            return null;
        }

        if (this.relatedControl) {
            this.relatedControl.valueChanges.pipe(first()).subscribe(() => {
                confirmationControl.updateValueAndValidity();
            });
        }

        if (this.relatedControl && this.relatedControl.value != confirmationControl.value) {
            return {
                ["validateEqual"]: 'The new passwords are not matching.'
            };
        }

        return null;
    }
}