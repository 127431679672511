<div class="display-flex email-container fill-parent">
  <div class="email-actions">
    <ng-container *ngIf="!generalVariables.userImpersonationInfo.impersonatorRoleInd">
      <div class="cont-padding display-flex align-items-center gap-m">
        <img class="email-action-icon" mat-button [matMenuTriggerFor]="mailFolders" src="assets/images/mailBurgerMenu.svg" title="Mail Folders" *ngIf="!generalVariables.clientDeviceInfo.isDesktop" />

        <a class="cp-btn text-center flex-grow-1 cp-main-btn new-message-btn"  matLine
          [routerLink]="['./new']"><span>New Message</span></a>
          <div class="display-flex align-items-center email-actions-cont" *ngIf="!generalVariables.clientDeviceInfo.isDesktop && emailId && (mailBox == 'inbox' || mailBox == 'sent' || mailBox == 'archive')">
            <button class="cp-btn cp-mail-btn cp-mail-btn-with-icon" *ngIf="mailBox != 'sent'" (click)="replyAll()">
              <div class="display-flex align-items-center">
                <img class="email-action-icon" src="assets/images/securedMail/replyAll.svg"/>
                <span class="email-action-text" *ngIf="generalVariables.clientDeviceInfo.isTablet">Reply All</span>
              </div>
            </button>
            <button class="cp-btn cp-mail-btn cp-mail-btn-with-icon" *ngIf="mailBox != 'sent'" (click)="reply()">
              <div class="display-flex align-items-center">
                <img class="email-action-icon" src="assets/images/securedMail/reply.svg"/>
                <span class="email-action-text" *ngIf="generalVariables.clientDeviceInfo.isTablet">Reply</span>
              </div>

            </button>
            <button class="cp-btn cp-mail-btn cp-mail-btn-with-icon" (click)="forward()">
              <div class="display-flex align-items-center">
                <img class="email-action-icon" src="assets/images/securedMail/forward.svg" />
                <span class="email-action-text" *ngIf="generalVariables.clientDeviceInfo.isTablet">Forward</span>
              </div>
            </button>
          </div>
      </div>
      <hr class="main-hr" />
    </ng-container>

    <mat-menu #mailFolders="matMenu">
      <a class="email-menu-item" [routerLink]="['./inbox']">
          <div class="mail-nav-icon">
            <img style="width:20px" src="assets/images/securedMail/inbox.svg" />
          </div>
          <span>Inbox</span>
          <span class="menu-badge margin-left-def" *ngIf="generalVariables.systemCounters.inboxCounter > 0">{{generalVariables.systemCounters.inboxCounter}}</span>
      </a>

      <a class="email-menu-item" [routerLink]="['./sent']">
        <div class="mail-nav-icon"><img style="width:20px" src="assets/images/securedMail/sent.svg" /></div>
        <span>Sent</span>
      </a>

      <a class="email-menu-item" [routerLink]="['./archive']">
        <div class="mail-nav-icon"><img style="width:20px" src="assets/images/securedMail/archive.svg" /></div>
         <span>Archive</span>
         <span class="menu-badge margin-left-def" *ngIf="generalVariables.systemCounters.archiveCounter > 0">{{generalVariables.systemCounters.archiveCounter}}</span>
      </a>

      <a class="email-menu-item" [routerLink]="['./saved']">
        <div class="mail-nav-icon"><img style="width:20px" src="assets/images/securedMail/saved.svg" /></div>
         <span>Saved</span>
         <span class="menu-badge margin-left-def" *ngIf="generalVariables.systemCounters.savedCounter > 0">{{generalVariables.systemCounters.savedCounter}}</span>
      </a>
    </mat-menu>



    <mat-nav-list *ngIf="generalVariables.clientDeviceInfo.isDesktop">
      <mat-list-item [routerLink]="['./inbox']">
        <a matLine>
          <div class="display-flex flex-items-centered">
            <div class="mail-nav-icon">
              <img src="assets/images/securedMail/inbox.svg" />
            </div>
            <span>Inbox</span>
            <span class="menu-badge margin-left-def" *ngIf="generalVariables.systemCounters.inboxCounter > 0">{{generalVariables.systemCounters.inboxCounter}}</span>
          </div>
        </a>
      </mat-list-item>
      <mat-list-item [routerLink]="['./sent']">
        <a matLine>
          <div class="mail-nav-icon"><img src="assets/images/securedMail/sent.svg" /></div> <span>Sent</span>
        </a>
      </mat-list-item>
      <mat-list-item [routerLink]="['./archive']">
        <a matLine>
          <div class="mail-nav-icon"><img src="assets/images/securedMail/archive.svg" /></div>
           <span>Archive</span>
           <span class="menu-badge margin-left-def" *ngIf="generalVariables.systemCounters.archiveCounter > 0">{{generalVariables.systemCounters.archiveCounter}}</span>
        </a>
      </mat-list-item>
      <mat-list-item [routerLink]="['./saved']">
        <a matLine>
          <div class="mail-nav-icon"><img src="assets/images/securedMail/saved.svg" /></div>
           <span>Saved</span>
           <span class="menu-badge margin-left-def" *ngIf="generalVariables.systemCounters.savedCounter> 0">{{generalVariables.systemCounters.savedCounter}}</span>
        </a>
      </mat-list-item>
    </mat-nav-list>
  </div>
  <div class="flex-basis-0 flex-grow-1">
    <ng-scrollbar>
      <router-outlet></router-outlet>
    </ng-scrollbar>
  </div>
</div>
