import { Component, OnInit, ViewChild } from '@angular/core';
import { AppUserService, AppUserViewModel, AccountsService, AppUserExtensionViewModel, UserProfileViewModel, SecureFileService, SelectionItemViewModel } from '../../rest/index';
import { NgForm, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MedcorAlertService, AlertDismissType, AlertMessageType } from '../../services/medcor-alert.service';
import { HttpEventType } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { ValidationMessages, AppSettings } from '../../app.general.constants';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { IchsImageCropperConfig } from '../../controls/ichs-image-cropper/image-cropper-config';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { MfaStatusViewModel } from 'src/app/rest';
import { IchsComponentDialogComponent } from 'src/app/controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { ManageSecurityQuestionsComponent } from '../manage-security-questions/manage-security-questions.component';
import { ChangePasswordRequestComponent } from '../change-password-request/change-password-request.component';

@Component({
  selector: 'app-appuser-self-detail',
  templateUrl: './appuser-self-detail.component.html',
  styleUrls: ['./appuser-self-detail.component.css']
})

export class AppuserSelfDetailComponent implements OnInit {
  @ViewChild('form') ngForm: NgForm;
  userImage: string = "assets/images/user.svg";
  logoImage: string = "assets/images/logo.svg";
  public isEditMode: boolean = true;
  ichsImageCropperConfig: IchsImageCropperConfig = new IchsImageCropperConfig({
    cropperDialogTitle: "Change User Image",
  });

  model: AppUserViewModel = <AppUserViewModel>{ appUserExtension: <AppUserExtensionViewModel>{}, mfaStatus: <MfaStatusViewModel>{} };

  selectedTabIdx: number = 0;

  monthsSelectionListItems: SelectionItemViewModel[];
  monthDatesSelectionListItems: SelectionItemViewModel[];

  /** Appuser constructor*/
  constructor(
    private _appUserService: AppUserService,
    private secureFileService: SecureFileService,
    private accService: AccountsService,
    private alertService: MedcorAlertService,
    private generalVariables: GeneralVariablesService,
    private dialog: MatDialog,
  ) {
    this.monthsSelectionListItems = this.getMonthsAsSelectionList();
  }

  /** Appuser OnInit*/
  ngOnInit() {

    if (this.generalVariables.userImpersonationInfo.impersonatorRoleInd) {
      this.isEditMode = false;
    }

    // redirect to change password tab in case of changePassword=true flag
    if (location.search.indexOf("?changePassword=true") > -1) {
      this.isEditMode = true;
      this.selectedTabIdx = 1;
    }

    this._appUserService.getLoggedInUserDetail().subscribe(resp => {
      this.model = resp.result;

      this.onFiscalYearStartingMonthChange();

      if (this.model.appUserExtension!.userImageId) {
        this.secureFileService.downloadFile(this.model.appUserExtension!.userImageId!, 'events', true).subscribe(event => {
          if (event.type == HttpEventType.Response) {
            var reader = new FileReader();
            reader.onload = (event: any) => { this.userImage = event.target.result; }
            reader.readAsDataURL(event.body!);
          }
        });
      }

      if (this.model.appUserExtension!.logoId) {
        this.secureFileService.downloadFile(this.model.appUserExtension!.logoId!, 'events', true).subscribe(event => {
          if (event.type == HttpEventType.Response) {
            var reader = new FileReader();
            reader.onload = (event: any) => {
              this.logoImage = event.target.result;
            }
            reader.readAsDataURL(event.body!);
          }
        });
      }
    });
  }

  save() {
    this.validateAllFormFields(this.ngForm.form);
    if (this.ngForm.form.invalid) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    let updatedProfile = <UserProfileViewModel>{
      firstName: this.model.firstName,
      middleName: this.model.middleName,
      lastName: this.model.lastName,
      businessCardTemplate: this.model.appUserExtension.businessCardTemplate,
      cellphone: this.model.appUserExtension.cellphone,
      title: this.model.appUserExtension.title,
      office: this.model.appUserExtension.office,
      website: this.model.appUserExtension.website,
      fax: this.model.appUserExtension.fax,
      timeZone: this.model.appUserExtension.timeZone,
      fiscalYearStartingMonth: this.model.appUserExtension.fiscalYearStartingMonth,
      fiscalYearStartingDate: this.model.appUserExtension.fiscalYearStartingDate,
    };

    this._appUserService.updateProfile(updatedProfile).subscribe(() => {
      this.isEditMode = false;
    });
  }

  back() {
    this.isEditMode = false;
  }

  edit() {
    this.isEditMode = !this.isEditMode;
  }

  changePassword() {
    this.accService.sendResetPasswordForm().pipe(first()).subscribe();
  }

  onImageCropped(imgCroppedEvent: ImageCroppedEvent) {
    var reader = new FileReader();
    reader.onload = (event: any) => { this.userImage = event.target.result; }
    reader.readAsDataURL(imgCroppedEvent.blob);

    this._appUserService
      .uploadUserImage(ImageType.UserImage, this.model.id!, this.model.version, 0, [imgCroppedEvent.blob], 'events', true)
      .subscribe(event => {
        if (event.type == HttpEventType.Response) {
          var objResult = event.body!.result;
          this.model.appUserExtension!.userImageId = objResult!.imageFileId!;
          this.model.version = objResult!.version!;
        }
      });
  }

  logoImageSelected(ev: any) {
    if (ev.srcElement.files.length != 1) {
      return;
    }

    let file = ev.srcElement.files[0] as File;

    if (file.size > AppSettings.MAX_FILE_SIZE) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.FILE_SIZE_LIMIT_EXCEEDED]
      });
      return;
    }

    if (file.type.toLowerCase() == "image/svg+xml") {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FILETYPE]
      });
      return;
    }

    if (file.type.toLowerCase().indexOf("image/") == -1) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.NOT_IMAGE_FILETYPE]
      });
      return;
    }

    var logoFile = file;
    this._appUserService
      .uploadUserImage(ImageType.Logo, this.model.id!, this.model.version, 0, [logoFile], 'events', true)
      .subscribe(event => {
        if (event.type == HttpEventType.Response) {
          var objResult = event.body!.result;
          this.model.appUserExtension!.logoId = objResult!.imageFileId!;
          this.model.version = objResult!.version!;
        }
      });

    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.logoImage = event.target.result;
    }

    reader.readAsDataURL(logoFile);
  }

  openChangePasswordPopup() {
    let dialogRef = this.dialog.open(IchsComponentDialogComponent, {
      disableClose: true,
      width: '800px',
      data: { component: ChangePasswordRequestComponent, hideCloseButton: false }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(() => {
      this.selectedTabIdx = 0
    });
  }

  openSecurityQuestionsPopup() {
    this.dialog.open(IchsComponentDialogComponent, {
      disableClose: true,
      width: '800px',

      data: { component: ManageSecurityQuestionsComponent, hideCloseButton: true }
    });
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  onFiscalYearStartingMonthChange() {
    this.monthDatesSelectionListItems = this.getDatesInMonthAsSelectionList(this.model.appUserExtension.fiscalYearStartingMonth);
    let dateAvailableInNewMonth = this.monthDatesSelectionListItems.some(obj => obj.selectionValue == this.model.appUserExtension.fiscalYearStartingDate);

    if (!dateAvailableInNewMonth) {
      this.model.appUserExtension.fiscalYearStartingDate = null;
    }
  }

  onTabChange(tabIndex: number) {
    if (tabIndex == 1) {
      this.openChangePasswordPopup();
    }
  }

  getMonthsAsSelectionList() {
    return Array.from(
      {
        length: 12
      },
      (_, i) => <SelectionItemViewModel>{
        selectionText: (i + 1).toString(),
        selectionValue: (i + 1)
      });
  }

  getDatesInMonthAsSelectionList(month: number) {
    let monthDates: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    return Array.from(
      {
        length: monthDates[month - 1]
      },
      (_, i) => <SelectionItemViewModel>{
        selectionText: (i + 1).toString(),
        selectionValue: (i + 1)
      });
  }
}

export enum ImageType {
  UserImage = "UserImage",
  Logo = "Logo",
}
