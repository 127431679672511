<div class="display-flex flex-items-centered">
  <div class="flex-cell" *ngIf="label">
    <label [attr.for]="identifier">{{label}}</label>
  </div>

  <div class="flex-cell input-cont flex-basis-0 flex-grow-1">
    <span class="label-date-picker" (click)="openPicker()">{{value | date: 'shortDate'}}</span>
    <input matInput
            [(ngModel)]="value"
            [matDatepicker]="picker"
            [id]="identifier"
            (dateChange)="dateChangedEvent($event)"
            class="flex-basis-0 flex-grow-1 hidden-input">
  </div>

  <div class="flex-cell">
    <mat-datepicker #picker (opened)="onMatDatePickerOpened()" (closed)="onMatDatePickerClosed()"></mat-datepicker>
  </div>

  <div class="flex-cell">
    <validation-message *ngIf="invalid && model.control.touched" [messages]="failures"></validation-message>
  </div>
</div>
