import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IchsGridComponentConfig } from '../../controls/ichs-grid/grid-configs';
import { IchsGridComponent } from '../ichs-grid/ichs-grid.component';

@Component({
  selector: 'ichs-grid-dialog',
  templateUrl: './ichs-grid-dialog.component.html',
  styleUrls: ['./ichs-grid-dialog.component.css']
})
export class IchsGridDialogComponent implements OnInit {
  @ViewChild(IchsGridComponent, { static: true }) ichsGrid: IchsGridComponent;

  constructor(
    public dialogRef: MatDialogRef<IchsGridDialogComponent, any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
  }

  ngOnInit() { }

  close() {
    this.dialogRef.close(this.ichsGrid.dataSource.data);
  }

  AddExisting() {
    this.dialogRef.close({ addSelectedInd: true, selectedIds: this.ichsGrid.multiSelectConfig!.getSelected!() });
  }
}
