<form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
  <ichs-collapsable-panel panelTitle="Reset User Password">
    <div class="collapsableBody">
      <div class="display-flex break-la">
        <div class="flex-child flex-basis-0 flex-grow-1 break-sm flex-items-centered">
          <ichs-password name="newPassword" #passwordComponent [(ngModel)]="newPassword" required label="New Password" passwordStrength></ichs-password>
            <div class="position-relative">
              <div class="password-rules">
                (At least 12 characters including: capital character, small character, number or symbol.)
              </div>
            </div>
        </div>
        <div class="flex-col-sep"></div>
        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered"></div>
      </div>
      <div class="display-flex break-la">
        <div class="flex-child flex-basis-0 flex-grow-1 break-sm flex-items-centered">
          <ichs-password name="confirmPassword" [relatedControl]="passwordComponent.model.control" #confirmPassword="ngModel" [(ngModel)]="confirmNewPassword" label="Confirm Password" validateEqual
             required></ichs-password>

        </div>
        <div class="flex-col-sep"></div>
        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
        </div>
      </div>
      <div class="display-flex break-la">
        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
          <a class="btn btn-default" (click)="resetPassword()">
            <i class="glyphicon"></i> Reset Password
          </a>
        </div>
        <div class="flex-col-sep"></div>
        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
        </div>
      </div>
    </div>
  </ichs-collapsable-panel>
</form>