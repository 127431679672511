<div class="main-container" style="width:100%; padding:10px;">
  <div class="image-rotator-cont" *ngIf="groupIds.length > 1">
    <img src="assets/images/down-arrow.svg" class="image-rotator" (click)="loadNextDesign()" />
  </div>

  <div class="featured-cont" (click)="linkClicked($event, topLink, true)">
    <div *ngIf="homePage" [innerHTML]="homePage.top"></div>
  </div>
  <div class="main-page-margin">
    <div class="display-flex flex-wrap justify-cont-res">
      <div class="flex-basis-0 flex-grow-1 tile-item" (click)="linkClicked($event, leftLink)">
        <div *ngIf="homePage" [innerHTML]="homePage.bottomLeft"></div>
      </div>
      <div class="flex-basis-0 flex-grow-1 tile-item" (click)="linkClicked($event, centerLink)">
        <div *ngIf="homePage" [innerHTML]="homePage.bottomCentral"></div>
      </div>
      <div class="flex-basis-0 flex-grow-1 tile-item" (click)="linkClicked($event, rightLink)">
        <div *ngIf="homePage" [innerHTML]="homePage.bottomRight"></div>
      </div>
    </div>
  </div>
</div>
