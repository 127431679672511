<div class="display-flex flex-col fill-parent">
    <div class="header">
        <div class="display-flex flex-items-centered">
            <div class="flex-basis-0 flex-grow-1 main-title">
                Reports
            </div>
            <div>
                <button [mat-dialog-close]="newScheduleCreated" class="close-btn" title="Close">
                    <img src="assets/images/close.svg" class="cp-dialog-close" />
                </button>
            </div>
        </div>
    </div>
    <ng-scrollbar class="flex-grow-1">
        <div>

            <div class="display-flex flex-items-centered">
                <div class="flex-basis-0 flex-grow-1">
                    <div class="display-flex flex-items-centered margin-left-def">
                        <ichs-text-box name="search" [(ngModel)]="searchText" placeholder="Search"
                            class="flex-grow-1 flex-basis-0"></ichs-text-box>
                        <a (click)="clearFilter()" class="cp-btn cp-grid-head-btn  margin-left-def"
                            style="align-self:flex-end;">
                            Clear
                        </a>
                    </div>
                </div>
        
                <div style="flex-basis:100px;"></div>
        
                <div>
                    <div *ngIf="lineOfBusinessList.length > 1" class="margin-top-def">
                        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="selectedLineOfBusiness">
                            <mat-button-toggle *ngFor="let userLine of lineOfBusinessList" [value]="userLine"
                                (change)="changeLineOfBusiness(userLine)">
                                <ng-container
                                    *ngIf="(userLine == 'Occupational Health' && lineOfBusinessList.indexOf('Advanced Practice') == -1) || (userLine == 'Advanced Practice' && lineOfBusinessList.indexOf('Occupational Health') == -1); else elseBlock">
                                    Onsite Care
                                </ng-container>
                                <ng-template #elseBlock>
                                    {{ (userLine == 'Screening') ? 'Health Security' : userLine.replace('Advanced Practice',
                                    'Advanced Care').replace('Triage', 'Telehealth') }}
                                </ng-template>
                            </mat-button-toggle>
                        </mat-button-toggle-group>
                    </div>
                </div>
            </div>
        
            <div class="margin-bottom-def">
                <div class="flex-wrap">
                    <div *ngFor="let report of filteredReportList" 
                        class="flex-basis-0 flex-grow-1"
                        [title]="report.description ? report.description : report.name">
                        
                        <dialog-report-item-card [reportViewModel]="report" (onNewScheduleCreated)="newScheduleCreated = true"
                            class="report-item">
                        </dialog-report-item-card>
                    </div>
                </div>
            </div>
        </div>

    </ng-scrollbar>

</div>
