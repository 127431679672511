<form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
  <div class="display-flex break-la">
    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
      <ichs-text-box email required name="email" [disabled]="disableControls" [(ngModel)]="email" label="Sent To E-mail"></ichs-text-box>
    </div>
    <div class="flex-col-sep"></div>
    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
      <ichs-text-box cdkFocusInitial initialFocus="true" name="refNumber" [(ngModel)]="refNumber" (ngModelChange)="resetForm()" [disabled]="disableControls"
                     required (buttonClicked)="getCallRecordInfo()" (keyup.enter)="getCallRecordInfo()" label="Telehealth Call Reference Nbr">
      </ichs-text-box>
      <a *ngIf="!disableControls" (click)="getCallRecordInfo()" class="cp-btn cp-grid-head-btn  margin-left-def" style="align-self:flex-end;">
        Search
      </a>
    </div>
  </div>
</form>

<ng-container *ngIf="isSearchDone">
  <div class="display-flex break-la">
    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
      <ichs-text-box name="personName" [ngModel]="callRecordInfo.personName ? callRecordInfo.personName : 'N/A'" label="Employee Name" disabled>
      </ichs-text-box>
    </div>
    <div class="flex-col-sep"></div>
    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
      <ichs-text-box name="timeOfTheCall" [ngModel]="callRecordInfo.callTime ? (callRecordInfo.callTime | date:'medium') : 'N/A'" label="Call Date and Time" disabled>
      </ichs-text-box>
    </div>
    <div class="flex-col-sep"></div>
    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
      <ichs-text-box name="bodyParts" [ngModel]="callRecordInfo.bodyParts ? callRecordInfo.bodyParts : 'N/A'" label="Body Parts" disabled>
      </ichs-text-box>
    </div>
  </div>
  <div class="display-flex break-la">
    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
      <ichs-text-box name="actualInjury" [ngModel]="callRecordInfo.timeZone ? callRecordInfo.timeZone : 'N/A'" label="Time Zone" disabled>
      </ichs-text-box>
    </div>
    <div class="flex-col-sep"></div>
    <div class="flex-child flex-basis-0 flex-grow-1 break-sm flex-items-centered">
      <div class='label-cont' >
        <label style="margin-bottom: 4px; font-weight: bold">Disposition</label>
      </div>
      <ichs-text-box name="disposition" [ngModel]="callRecordInfo.disposition ? callRecordInfo.disposition : 'N/A'" disabled>
      </ichs-text-box>
    </div>
    <div class="flex-col-sep"></div>
    <div class="flex-child flex-basis-0 flex-grow-1 break-sm flex-items-centered">
      <div class='label-cont' >
        <label style="margin-bottom: 4px; font-weight: bold">Impression(s)</label>
      </div>
      <ichs-text-box name="impressions" [ngModel]="callRecordInfo.impressions ? callRecordInfo.impressions : 'N/A'" disabled>
      </ichs-text-box>
    </div>
  </div>
</ng-container>

<div class="text-right fill-width margin-top-def" style="padding-top:20px;">
  <button mat-button *ngIf="isSearchDone && generalVariables.userImpersonationInfo.impersonatorRoleInd" (click)="download()" class="cp-btn cp-main-btn">
    Preview
  </button>
  <button mat-button *ngIf="isSearchDone && !generalVariables.userImpersonationInfo.impersonatorRoleInd" (click)="downloadOrSend()" class="cp-btn cp-main-btn">
    {{isFileDownloaded ? "Submit" : "Preview"}}
  </button>
  <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">Cancel</button>
</div>
