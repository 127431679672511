/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UserLookupDetailsViewModel { 
    /**
     * 
     */
    roleName?: string;
    /**
     * 
     */
    email?: string;
    /**
     * 
     */
    name?: string;
    /**
     * 
     */
    firstName?: string;
    /**
     * 
     */
    lastName?: string;
    /**
     * 
     */
    middleName?: string;
    /**
     * 
     */
    active?: boolean;
    /**
     * 
     */
    status?: string;
    /**
     * 
     */
    passwordStatus?: string;
    /**
     * 
     */
    passwordExpired?: boolean;
    /**
     * 
     */
    activeDirectoryUserInd?: boolean;
    /**
     * 
     */
    clientName?: string;
    /**
     * 
     */
    phiDataRestrictionInd?: boolean;
    /**
     * 
     */
    groupsLineOfBusinessList?: Array<string>;
    id?: number;
    voidId?: number;
    version?: string;
}
