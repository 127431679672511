<div class="main-container" style="width:100%; padding:10px;">
  <div class="featured-cont" (click)="linkClicked($event, topLink)">
    <div *ngIf="onSitePage" [innerHTML]="onSitePage.top"></div>
  </div>
  <div class="main-page-margin">
    <div class="display-flex flex-wrap justify-cont-res">
      <div class="flex-basis-0 flex-grow-1 tile-item" (click)="linkClicked($event, leftLink)">
        <div *ngIf="onSitePage" [innerHTML]="onSitePage.bottomLeft"></div>
      </div>
      <div class="flex-basis-0 flex-grow-1 tile-item" (click)="linkClicked($event, centerLink)">
        <div *ngIf="onSitePage" [innerHTML]="onSitePage.bottomCentral"></div>
      </div>
      <div class="flex-basis-0 flex-grow-1 tile-item" (click)="linkClicked($event, rightLink)">
        <div *ngIf="onSitePage" [innerHTML]="onSitePage.bottomRight"></div>
      </div>
    </div>
  </div>
</div>
