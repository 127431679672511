import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as pbi from 'powerbi-client';
import { DisplayOption, IFilter, LayoutType, PageSizeType, Permissions } from 'powerbi-models';
import { first } from 'rxjs/operators';
import { DashboardService, PowerBIDashboardItemViewModel } from 'src/app/rest';
import { GeneralVariablesService } from 'src/app/services/general-variables.service';
import { DashboardSettings } from '../../app.general.constants';

@Component({
  selector: 'ichs-powerbi-report',
  templateUrl: './ichs-powerbi-report.component.html',
  styleUrls: ['./ichs-powerbi-report.component.css'],
})
export class IchsPowerBIReport implements OnInit {
  @Input() filters: Array<IFilter> = [];
  @Input() dashboardItem: PowerBIDashboardItemViewModel;
  @Input() accessToken: string;
  @Input() showMaximiz: boolean = true;
  @Input() singleTile: boolean = false;
  @Input() pageNavigationVisible: boolean = true;

  report: pbi.Report;

  fullScreenReport: boolean = false;
  refreshReportInd: boolean = false;
  public dataSelectionFilters: Array<IFilter> = [];
  @Output() errorEvent = new EventEmitter<null>();
  @Output() maximize = new EventEmitter<boolean>();

  private isInitialLoad: boolean = true;
  public bookmarkAppliedManually: boolean = false;

  constructor(
    public generalVariables: GeneralVariablesService,
    private dashboardService: DashboardService,
  ) { }

  ngOnInit() {
    this.populateDashboardItem();
    this.refreshReportInd = true;
  }

  fullScreen() {
    this.fullScreenReport = !this.fullScreenReport;
    this.maximize.emit(this.fullScreenReport);
  }

  //Populate dashboard item
  populateDashboardItem() {
    // Embed URL
    let embedUrl = DashboardSettings.POWER_BI_EMBED_PATH + this.dashboardItem.powerBIGroupId + "&reportId=" + this.dashboardItem.powerBIReportId;
    // Configuration used to describe the what and how to embed.
    // This object is used when calling powerbi.embed.    
    let config: pbi.IEmbedConfiguration = {
      type: 'report',
      accessToken: this.accessToken,
      embedUrl: embedUrl,
      id: this.dashboardItem.powerBIReportId,
      filters: this.filters,
      permissions: Permissions.Read,
      theme: { themeJson: DashboardSettings.MEDCOR_KPI_THEME },
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        layoutType: LayoutType.Master,
        customLayout: {
          pageSize: {
            type: PageSizeType.Standard
          },
          displayOption: DisplayOption.FitToPage
        },
        panes: {
          pageNavigation: {
            visible: this.pageNavigationVisible,
          }
        }
      },
    };

    if (this.dashboardItem.defaultBookmarkData) {
      config.bookmark = {
        state: this.dashboardItem.defaultBookmarkData
      };
    }

    // Grab the reference to the div HTML element that will host the report.
    let reportContainer = <HTMLElement>document.getElementsByClassName('dashboard-item').item(0);

    // Embed the report and display it within the div container.
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    powerbi.reset(reportContainer);
    this.report = <pbi.Report>powerbi.embed(reportContainer, config);
    this.report.iframe.style.border = 'none';

    this.report.off('loaded');
    this.report.on('loaded', () => {
      this.setFilters(this.filters);
    });

    this.report.off('rendered');
    this.report.on('rendered', () => {
      this.refreshReportInd = false;

      if (!this.isInitialLoad && !this.bookmarkAppliedManually) {
        this.updateDashboardDefaultBookmark();
      }
      else {
        this.isInitialLoad = false;
        this.bookmarkAppliedManually = false;
      }
    });

    this.report.off('error');
    this.report.on('error', () => {
      this.refreshReportInd = false;
      reportContainer.innerHTML = '<span>Error</span>';
      this.errorEvent.emit();
    });
  }

  //Set Dashboard item filters
  setFilters(filters: Array<IFilter>) {
    console.log("IchsPowerBIReport.setFilters filters:", filters);

    this.refreshReportInd = true;
    this.report.setFilters(filters)
      .then(() => {
        this.refreshReportInd = false;

        this.report.getFilters().then(value => {
          console.log("Get filters that set in IchsPowerBIReport.setFilters: getFilters value:", value);
        });
      })
      .catch(error => {
        console.error("IchsPowerBIReport.setFilters ERROR:", error);
      });
  }

  reload() {
    this.dashboardItem.defaultBookmarkData = null;
    this.populateDashboardItem();
  }

  private updateDashboardDefaultBookmark() {
    this.captureBookmarkState().then(bookmark => {
      this.dashboardService.updateDashboardDefaultBookmark({
        data: bookmark.state,
        userDashboardItemAssociationId: this.dashboardItem.userDashboardItemAssociationId,
      }).pipe(first()).subscribe();
    });
  }

  public captureBookmarkState() {
    return this.report.bookmarksManager.capture({ allPages: true, personalizeVisuals: true });
  }

  public applyBookmarkState(bookmarkState: string) {
    return this.report.bookmarksManager.applyState(bookmarkState);
  }
}

export class DataPoint {
  target: Target;
  equals: Array<string>
}

export class Target {
  table: string;
  column: string;
}
