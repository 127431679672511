<form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
  <div class="display-flex break-la">
    <ng-container *ngIf="associateUserToApplication || associateApplicationToUser">
      <div *ngIf="associateUserToApplication"
        class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
        <ichs-advance-search [advanceSearchGridConfig]="userAdvSearchGridConfig"
          [(ngModel)]="userApplicationAccess.userId" required name="userAdvSearch" selectedTextAttribute="email"
          selectedValueAttribute="id" label="Email" class="fill-width">
        </ichs-advance-search>
      </div>

      <div *ngIf="associateApplicationToUser"
        class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
        <ichs-select required name="applicationName" label="Application Name" hasSearch="true"
          [(ngModel)]="userApplicationAccess.applicationId" [items]="applicationNamesList">
        </ichs-select>
      </div>
      <div class="flex-col-sep"></div>
    </ng-container>

    <ng-container *ngIf="userApplicationAccess.applicationId == 174000000000001">
      <div class="flex-child flex-basis-0 flex-grow-1 break-sm flex-items-centered">
        <div class="label-cont margin-b-s">
          <label for="selectEntities">Selected Entities</label>
        </div>
        <div class="display-flex flex-items-centered">
          <a #selectEntities class="cp-btn cp-mail-btn flex-cell select-entities" (click)="selectEntitiesGrid()">
            {{ userApplicationAccess.entityIds ? userApplicationAccess.entityIds.length : 0 }} Selected
          </a>
          <input type="text" #hiddenEntitySelect="ngModel" [(ngModel)]="userApplicationAccess.entityIds"
            name="hiddenEntitySelect" style="display:none" />
        </div>
      </div>
      <div class="flex-col-sep"></div>
    </ng-container>

    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
      <ichs-date-picker name="expirationDate" label="Expiration Date" placeholder="Expiration Date"
        [(ngModel)]="userApplicationAccess.expirationDate">
      </ichs-date-picker>
    </div>
  </div>
  <div class="display-flex break-la">
    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
    </div>
    <div class="flex-col-sep"></div>
    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
    </div>
  </div>
  <div class="flex-basis-0 flex-grow-1 text-right">
    <button mat-button class="cp-btn cp-main-btn" (click)='save()'>Save</button>
    <button mat-button [mat-dialog-close]="false" class="cp-btn cp-mail-btn">Cancel</button>
  </div>
</form>