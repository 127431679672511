<div style="height:100vh;">
  <div class="recover-password">
    <div class="display-flex flex-items-centered">
      <div class="logo-cont"><img src="assets/images/medcorLogo.svg" class="main-logo" /></div>
    </div>
    <div>
      <div class="panel-footer">
        <a class="btn btn-default margin-right-def" (click)='redirectToLogin.emit()'>
          <i></i> Go To Login
        </a>
      </div>
      <ichs-collapsable-panel panelTitle="Forgot Password">
        <form class="collapsableBody" #form="ngForm" novalidate>
          <div class='display-flex break-la'>
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              <ichs-text-box name="email" [(ngModel)]="forgetPasswordObj.email" disabled email label="Email"></ichs-text-box>
            </div>

            <div class="flex-col-sep"></div>
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            </div>
          </div>

          <div class='display-flex break-la'>
            <div class="flex-child flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              <ichs-password #passwordComponent name="newPassword" [(ngModel)]="forgetPasswordObj.newPassword" required label="New Password"
                passwordStrength></ichs-password>
                <div class="position-relative">
                  <div class="password-rules">
                    (At least 12 characters including: capital character, small character, number or symbol.)
                  </div>
                </div>
            </div>

            <div class="flex-col-sep"></div>
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            </div>
          </div>
          <div class='display-flex break-la'>
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              <ichs-password name="confirmPassword" [(ngModel)]="confirmPassword" label="Confirm Password"
                validateEqual [relatedControl]="passwordComponent.model.control" required></ichs-password>
            </div>
            <div class="flex-col-sep"></div>
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            </div>
          </div>

          <div class='display-flex break-la'>
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              Please enter the answers to your security questions below to verify
            </div>
          </div>

          <div class='display-flex break-la' *ngFor="let secQuestion of forgetPasswordObj.secQuestionAssociationList,let i = index">
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              <ichs-text-box label="Security Question" name="securityQuestion{{i}}" [(ngModel)]="secQuestion.question"
                disabled="true"></ichs-text-box>
            </div>

            <div class="flex-col-sep"></div>
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              <ichs-text-box label="Answer" name="securityQuestionAnswer{{i}}" [(ngModel)]="secQuestion.answer"
                required></ichs-text-box>
            </div>
          </div>

          <div class='display-flex break-la'>
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              <div class="flex-grow-1">
                Please contact your Medcor Representative to reestablish your account.
              </div>
              <a class='cp-btn cp-grid-head-btn' (click)="saveNewPassword()">
                Save New Password
              </a>
            </div>
          </div>

        </form>
      </ichs-collapsable-panel>
    </div>
  </div>
</div>
