import { Component, OnInit, ViewChild } from '@angular/core';
import { IchsGridComponentConfig, IchsControlType } from 'src/app/controls/ichs-grid/grid-configs';
import { IchsCommandToolbarModel, IchsCommandToolbarDelegate } from 'src/app/controls/ichs-command-toolbar/ichs-command-toolbar.component';
import { NgForm } from '@angular/forms';
import { HelpCategoryViewModel, CommonQuestionViewModel, MedcorResponse, HelpCategoryService, CommonQuestionService, SearchViewModel, CategoryQuestionAssociationViewModel } from 'src/app/rest';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MedcorAlertService } from 'src/app/services/medcor-alert.service';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { IchsDialogComponent } from 'src/app/controls/ichs-dialog/ichs-dialog.component';
import { AppGenericLists } from '../../app.general.lists';

@Component({
  selector: 'help-center-category',
  templateUrl: './help-center-category.component.html',
  styleUrls: ['./help-center-category.component.css']
})
export class HelpCenterCategoryComponent implements OnInit, IchsCommandToolbarDelegate<HelpCategoryViewModel> {
  @ViewChild('form', { static: true }) ngForm: NgForm;// 1) bind the Form From view 
  model: IchsCommandToolbarModel<HelpCategoryViewModel>;// 2) define Command Toolbar model and use model.objModel to bing the FORM UI and use model as IchsCommandToolbar [model]
  gridConfigQuestions: IchsGridComponentConfig<CommonQuestionViewModel>;
  get categoryId(): number { return this.model.objModelId }

  constructor(
    private location: Location,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private alertService: MedcorAlertService,
    private categoryService: HelpCategoryService,
    private questionService: CommonQuestionService,
  ) {

    this.model = new IchsCommandToolbarModel<HelpCategoryViewModel>(location, router, "/help-category", "HelpCenterCategory", this, dialog, alertService); // 3) Create Command Toolbar Model 

    this.gridConfigQuestions = this.defineQuestionsGrid();
    this.model.hasSave = true;
    this.model.hasVoidRecover = true;
  }

  /** Group OnInit*/
  ngOnInit() {
    this.model.init(this.route); // 4) Initialize The Model
  }

  // 5) implement IchsCommandToolbarDelegate .
  canDeactivate(): boolean | Observable<boolean> {
    if (this.ngForm.dirty) {
      let dialogRef: MatDialogRef<IchsDialogComponent, boolean> = this.dialog.open<IchsDialogComponent, any>(IchsDialogComponent, {
        width: '450px',
        data: { title: "Discard Changes ", message: "You will lose all changes in this page." }
      });
      return dialogRef.afterClosed().pipe(first(), map(result => {
        if (result && result == true) {
          return result;
        } else {
          return false;
        }
      }));
    }
    return true;
  }


  getLoadObjModelObservable(id: number) {
    return this.categoryService.getById(id);
  }
  getSaveObservable(objModel: HelpCategoryViewModel): Observable<MedcorResponse<HelpCategoryViewModel>> {
    return this.categoryService.post(objModel)
  }
  getVoidObservable(objVoidModel: HelpCategoryViewModel): Observable<MedcorResponse<HelpCategoryViewModel>> {
    return this.categoryService._void(objVoidModel)
  }
  getRecoverObservable(id: number): Observable<MedcorResponse<HelpCategoryViewModel>> {
    return this.categoryService.getById(id);
  }

  /**
   * Configure AppUsers Grid
   */
  private defineQuestionsGrid(): IchsGridComponentConfig<CommonQuestionViewModel> {
    let configs: IchsGridComponentConfig<CommonQuestionViewModel> = {
      primaryId: "id",
      defaultOrder: "question",
      title: "Questions",
      entityController: "commonQuestion",
      entityDataSource: (filter: SearchViewModel) => this.questionService.searchQuestionsByCategoryId(this.categoryId, false, filter),
      addExistingConfig: {
        buttonText: "Add Existing",
        gridConfig: this.addExistingQuestionsGridConfig(),
        addExistingService: (Ids: string[]) => {
          let close = new Subject<MedcorResponse<boolean>>();
          let arrCategoryQuestionAssociations = Ids.map<CategoryQuestionAssociationViewModel>((item) => {
            return { categoryId: this.categoryId, questionId: +item }
          });
          let subs = this.questionService.addAssociationList(arrCategoryQuestionAssociations).subscribe(result => {
            subs.unsubscribe();
            if (result) {
              close.next({ result: true, statusCode: 200 });
            } else {
              close.next({ result: false });
            }
          });
          return close.asObservable();
        },
        title: null,
      },
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: true,
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: CommonQuestionViewModel) => {
          if (!deletedObj.id) return;
          let dialogRef = this.dialog.open(IchsDialogComponent, {
            width: '450px',
            data: { title: "Remove Question", message: "Are you sure about remove this Question from Category?" }
          });

          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.questionService.deleteQuestionAssociation(this.categoryId, deletedObj.id).subscribe(result => {
                if (result) {
                  this.gridConfigQuestions.refreshGrid!(this.gridConfigQuestions.getPage!());
                }
              });
            }
          });
        }
      },
      headers: [
        {
          headerTitle: "Question",
          propertyName: "question",
          sortable: true,
          searchable: true,
          controlType: IchsControlType.Textfield,
          width: 700
        },
        {
          headerTitle: "Line Of Business",
          propertyName: "lineOfBusiness",
          sortable: true,
          searchable: true,
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.LineOfBusinessCategory,
        }
      ]
    }
    return configs;
  }
  /**
   * Add Existing AppUser 
   */
  private addExistingQuestionsGridConfig(): IchsGridComponentConfig<CommonQuestionViewModel> {
    let addExistingGridConfig: IchsGridComponentConfig<CommonQuestionViewModel> = {
      primaryId: "id",
      defaultOrder: "question",
      title: "Questions",
      entityController: "commonQuestion",
      entityDataSource: (filter: SearchViewModel) => this.questionService.searchQuestionsByCategoryId(this.categoryId, true, filter),
      multiSelectConfig: {
      },
      hasVoid: false,
      headers: [
        {
          headerTitle: "Question",
          propertyName: "question",
          sortable: true,
          searchable: true,
          controlType: IchsControlType.Textfield,
          width: 700
        },
        {
          headerTitle: "Line Of Business",
          propertyName: "lineOfBusiness",
          sortable: true,
          searchable: true,
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.LineOfBusinessCategory,
        }
      ],
    };
    return addExistingGridConfig;
  }
}
