import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DashboardItemViewModel } from '../../rest/index';
import { MedcorAlertService } from '../../services/medcor-alert.service';
import { APISettings } from '../../app.general.constants';

@Component({
  selector: 'dashboard-item-card',
  templateUrl: './dashboard-item-card.component.html',
  styleUrls: ['./dashboard-item-card.component.css']
})
export class DashboardItemCardComponent implements OnInit {
  @Input() public dashboardItem: DashboardItemViewModel;
  @Input() enableSelection: boolean = true;
  @Output() DashboardItemCardSelected: EventEmitter<DashboardItemViewModel> = new EventEmitter<DashboardItemViewModel>();

  dashboardItemImage: string;

  constructor(
    private alertService: MedcorAlertService,
  ) { }

  ngOnInit() {
    if (this.dashboardItem.imageId) {
      this.dashboardItemImage = APISettings.API_FILES_PATH + '/' + this.dashboardItem.imageId;
    }
  }

  select() {
    if (this.dashboardItem.isSelected) {
      return;
    }

    this.DashboardItemCardSelected.emit(this.dashboardItem);
  }
}
