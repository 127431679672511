<div class="secured-files cont-padding">
  <div class="secured-files-tools display-flex flex-items-centered">
    <div><a [routerLink]="['/securedfiles']">My Files</a>&nbsp;</div>
    <div class="flex-grow-1 flex-basis-0">
      <ng-container *ngFor="let item of pathName; index as i">
        / <a [routerLink]="['/securedfiles/'+pathId[i]]">{{item}}</a>
      </ng-container>
      <ng-container *ngIf="folderId == 2">/ <a style="cursor: pointer;">Favorites</a></ng-container>
      <ng-container *ngIf="folderId == 3">/ <a style="cursor: pointer;">Received Files</a></ng-container>
      <ng-container *ngIf="folderId == 4">/ <a style="cursor: pointer;">Recorded Calls</a></ng-container>
      <ng-container *ngIf="folderId == 5">/ <a style="cursor: pointer;">Reports Folder</a></ng-container>
    </div>

    <div *ngIf="(folderId == 1 || folderId > 5) && !clientUserScheduledReportsOnlyRoleInd">
      <button *ngIf="!generalVariables.userImpersonationInfo.impersonatorRoleInd" class="cp-btn cp-main-btn" (click)="fileInput.click()">Upload Files</button>
      <button *ngIf="!generalVariables.userImpersonationInfo.impersonatorRoleInd" class="cp-btn cp-main-btn" (click)="addNewFolder()">New Folder</button>
      <button class="cp-btn cp-main-btn" [routerLink]="['/file-request']">Request File</button>
    </div>

    <input (onFileSelected)="uploadSelectedFiles()" type="file" #fileInput ng2FileSelect [uploader]="uploader" multiple style="display:none" />
  </div>
  <hr class="main-hr" />

  <div class="main-folder"
         ng2FileDrop
         [uploader]="uploader"
         (fileOver)="areaFileOver($event, folderId)"
         [ngClass]="{'file-over-style': dropAreaFolderId == folderId}"
         (onFileDrop)="dropFolder(folderId)">

    <div *ngIf="folderId > 5 && files && files.length == 0 && !generalVariables.userImpersonationInfo.impersonatorRoleInd" style="margin:auto" class="folder-drop-area">
      <h3>Drop files here</h3>
    </div>

    <div class="user-folder" *ngIf="folderId == 1" title="Favorites" (dblclick)="routeToFolder(2)">
      <div class="folder-visuals">
        <img src="assets/images/newIcons/favorites.svg" class="folder-icon" draggable="false" />
        <span class="file-item-name">Favorites</span>
      </div>
    </div>

    <ng-container *ngIf="!clientUserScheduledReportsOnlyRoleInd">
      <div class="user-folder" *ngIf="folderId == 1" title="Received Files" (dblclick)="routeToFolder(3)">
        <div class="folder-visuals">
          <img src="assets/images/newIcons/receivedFiles.svg" class="folder-icon" draggable="false" />
          <div class="position-absolute not-seen-noti" *ngIf="newReceivedFilesCount > 0">
            {{newReceivedFilesCount}}
          </div>
          <span class="file-item-name">Received Files</span>
        </div>
      </div>
  
      <div class="user-folder" *ngIf="folderId == 1 && callRecordingFilesCount > 0" title="Recorded Calls" (dblclick)="routeToFolder(4)">
        <div class="folder-visuals">
          <img src="assets/images/newIcons/recordedCalls.svg" class="folder-icon" draggable="false" />
          <div class="position-absolute not-seen-noti" *ngIf="newCallRecordingFilesCount > 0">
            {{newCallRecordingFilesCount}}
          </div>
          <span class="file-item-name">Recorded Calls</span>
        </div>
      </div>
    </ng-container>

    <div class="user-folder" *ngIf="folderId == 1" title="Reports Folder" (dblclick)="routeToFolder(5)">
      <div class="folder-visuals">
        <img src="assets/images/newIcons/securedFiles.svg" class="folder-icon" draggable="false" />
        <div class="position-absolute not-seen-noti" *ngIf="newSecureReportFilesCount > 0">
          {{newSecureReportFilesCount}}
        </div>
        <span class="file-item-name">Reports Folder</span>
      </div>
    </div>

    <div *ngIf="folderId == 3" class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab">
      <ichs-grid [grid-config]="gridConfigReceivedFilesFolder" class="flex-basis-0 flex-grow-1 display-flex flex-col folder-grid"></ichs-grid>
    </div>

    <div *ngIf="folderId == 5" class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab">
      <ichs-grid [grid-config]="gridConfigReportsFolder" class="flex-basis-0 flex-grow-1 display-flex flex-col folder-grid"></ichs-grid>
    </div>

    <ng-container *ngIf="!clientUserScheduledReportsOnlyRoleInd || folderId == 2">
      <ng-container *ngIf="folderId != 3 && folderId != 5">
        <div class="user-folder"
          ng2FileDrop
          [uploader]="uploader"
          (fileOver)="areaFileOver($event,item.id)"
          [ngClass]="{'file-over-style': dropAreaFolderId == item.id}"
          (dblclick)="routeToFolder(item.id)"
          (onFileDrop)="dropFolder(item.id)"
          [title]="item.fileName"
          *ngFor="let item of getFolders()">

          <div class="folder-visuals">
            <img src="assets/images/newIcons/folder.svg" class="folder-icon" draggable="false" />
            <div class="position-absolute not-seen-noti" *ngIf="item.notSeenCount > 0">
              {{item.notSeenCount}}
            </div>
            <span class="file-item-name">{{item.fileName}}</span>
            <button *ngIf="!generalVariables.userImpersonationInfo.impersonatorRoleInd" #folderTrigger mat-button
              class="menu-btn file-menu" [matMenuTriggerFor]="folderMenu">...</button>
            <mat-menu #folderMenu="matMenu">
              <button mat-menu-item (click)="renameFolder(item)">Rename</button>
              <button mat-menu-item (click)="deleteFolder(item)">Delete</button>
            </mat-menu>
          </div>
        </div>
      </ng-container>

      <div class="user-file" 
          [title]="item.expirationDate ? item.fileName + ' (will be expired in ' + durationFromNow(item.expirationDate) + ')' : item.fileName" 
          (dblclick)="viewFile(item)"
          *ngFor="let item of getFiles()">

        <div class="file-visuals">
          <img src="assets/images/newIcons/file.svg" class="file-icon" draggable="false" />
          <div class="position-absolute new-file-noti" *ngIf="item.isNew">
            New
          </div>
          <div class="position-absolute file-type-tag" *ngIf="isCallRecording(item)">
            Call Recording
          </div>
          <div class="position-absolute file-type-tag" *ngIf="isPhiFile(item)">
            PHI
          </div>
          <span class="file-item-name">{{item.fileName}}</span>
        
          <div class="position-absolute fav-file">
            <img *ngIf="item.isFavorite" class="control-image"
              [src]="item.isFavorite ? 'assets/images/star-filled.svg' : 'assets/images/star-empty.svg'"
              (click)="!generalVariables.userImpersonationInfo.impersonatorRoleInd ? favorite(item) : null"
              [title]="!generalVariables.userImpersonationInfo.impersonatorRoleInd ? (item.isFavorite ? 'Remove from Favorite' : 'Add to Favorite') : ''" />
          </div>
        
          <button #fileTrigger mat-button class="menu-btn file-menu" [matMenuTriggerFor]="menu">...</button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="viewFile(item)">View</button>
            <button mat-menu-item (click)="downloadFile(item)">Download</button>

            <ng-container *ngIf="folderId != 2 && !generalVariables.userImpersonationInfo.impersonatorRoleInd">
              <button mat-menu-item (click)="renameFile(item)">Rename</button>
              <button mat-menu-item (click)="moveTo(item)" *ngIf="!clientUserScheduledReportsOnlyRoleInd">Move to</button>
              <button mat-menu-item (click)="deleteFile(item)">Delete</button>
            </ng-container>

            <button mat-menu-item *ngIf="hasRequestFileSignAccess && item.fileType.toLowerCase() == 'application/pdf'" (click)="requestSignature(item)">Request Signature</button>

            <button *ngIf="!generalVariables.userImpersonationInfo.impersonatorRoleInd" mat-menu-item
                    (click)="favorite(item)">
              {{item.isFavorite ? "Remove from Favorite" : "Add to Favorite"}}
            </button>
          </mat-menu>
        </div>
      </div>
    </ng-container>

  </div>
  <mat-progress-spinner class="upload-spinner" *ngIf="uploader.isUploading" [value]="uploader.progress" mode="determinate"></mat-progress-spinner>
</div>
