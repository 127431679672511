<form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
    <div class="files-cont">
        <div class="margin-top-def">
            <div *ngFor="let fileItem of extraData.fileItems; index as i" class="display-flex flex-items-centered file-item">
                <div class="flex-grow-1 flex-basis-0">{{fileItem._file.name}}</div>
                <div style="width:150px">
                    <ichs-select required placeholder="Type" name="type_{{i}}" [items]="fileTypesListItem" [(ngModel)]="extraData.fileTypes[i]"></ichs-select>
                </div>
                <div>
                    <button title="Remove File" class="cp-btn cp-chip-btn" (click)="removeFile(i)">
                        <img class="remove-file-icon" src="assets/images/delete-cross.svg" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>

<div class="text-right fill-width margin-top-def margin-bottom-def" style="padding-top:20px;">
    <button *ngIf="!generalVariables.userImpersonationInfo.impersonatorRoleInd" mat-button class="cp-btn cp-main-btn"
        (click)="submit()">Upload File(s)</button>
    <button mat-button [mat-dialog-close]="false" class="cp-btn cp-mail-btn">Close</button>
</div>