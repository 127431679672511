/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';









import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {MedcorResponse,PaginatedListViewModel,SelectionTreeViewModel,DynamicInstanceViewModel,SelectionItemViewModel,TriageAfterCareFileViewModel} from '../index';


@Injectable()
export class AfkamService {

    protected basePath = 'https://localhost/ClientPortalAPI';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Download File
     * 
     * @param id 
     * @param refNbr 
     * @param lastName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadTriageAfterCareFile(id?: string, refNbr?: string, lastName?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadTriageAfterCareFile(id?: string, refNbr?: string, lastName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadTriageAfterCareFile(id?: string, refNbr?: string, lastName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadTriageAfterCareFile(id?: string, refNbr?: string, lastName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (refNbr !== undefined && refNbr !== null) {
            queryParameters = queryParameters.set('refNbr', <any>refNbr);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any>lastName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/Afkam/DownloadTriageAfterCareFile`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download File
     * 
     * @param id 
     * @param encParam 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadTriageAfterCareFileByEnc(id?: string, encParam?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadTriageAfterCareFileByEnc(id?: string, encParam?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadTriageAfterCareFileByEnc(id?: string, encParam?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadTriageAfterCareFileByEnc(id?: string, encParam?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (encParam !== undefined && encParam !== null) {
            queryParameters = queryParameters.set('encParam', <any>encParam);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/Afkam/DownloadTriageAfterCareFileByEnc`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Payor by ID from Afkam
     * 
     * @param payorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPayor(payorId?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<DynamicInstanceViewModel>>;
    public getPayor(payorId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<DynamicInstanceViewModel>>>;
    public getPayor(payorId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<DynamicInstanceViewModel>>>;
    public getPayor(payorId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (payorId !== undefined && payorId !== null) {
            queryParameters = queryParameters.set('payorId', <any>payorId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<DynamicInstanceViewModel>>(`${this.basePath}/api/Afkam/GetPayor`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all entities for a structure.
     * 
     * @param payorId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPayorEntityTypes(payorId?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<SelectionItemViewModel>>>;
    public getPayorEntityTypes(payorId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<SelectionItemViewModel>>>>;
    public getPayorEntityTypes(payorId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<SelectionItemViewModel>>>>;
    public getPayorEntityTypes(payorId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (payorId !== undefined && payorId !== null) {
            queryParameters = queryParameters.set('payorId', <any>payorId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<SelectionItemViewModel>>>(`${this.basePath}/api/Afkam/EntityTypes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all entities for a structure.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPayorEntityTypesTree(observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<SelectionTreeViewModel>>>;
    public getPayorEntityTypesTree(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<SelectionTreeViewModel>>>>;
    public getPayorEntityTypesTree(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<SelectionTreeViewModel>>>>;
    public getPayorEntityTypesTree(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<SelectionTreeViewModel>>>(`${this.basePath}/api/Afkam/GetPayorEntityTypesTree`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param refNbr 
     * @param lastName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTriageAfterCareFiles(refNbr?: string, lastName?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<TriageAfterCareFileViewModel>>>;
    public getTriageAfterCareFiles(refNbr?: string, lastName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<TriageAfterCareFileViewModel>>>>;
    public getTriageAfterCareFiles(refNbr?: string, lastName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<TriageAfterCareFileViewModel>>>>;
    public getTriageAfterCareFiles(refNbr?: string, lastName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refNbr !== undefined && refNbr !== null) {
            queryParameters = queryParameters.set('refNbr', <any>refNbr);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any>lastName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<TriageAfterCareFileViewModel>>>(`${this.basePath}/api/Afkam/GetTriageAfterCareFiles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * GetTriageAfterCareFilesByEncryptedParam
     * 
     * @param encParam 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTriageAfterCareFilesByEncryptedParam(encParam?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<TriageAfterCareFileViewModel>>>;
    public getTriageAfterCareFilesByEncryptedParam(encParam?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<TriageAfterCareFileViewModel>>>>;
    public getTriageAfterCareFilesByEncryptedParam(encParam?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<TriageAfterCareFileViewModel>>>>;
    public getTriageAfterCareFilesByEncryptedParam(encParam?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (encParam !== undefined && encParam !== null) {
            queryParameters = queryParameters.set('encParam', <any>encParam);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<TriageAfterCareFileViewModel>>>(`${this.basePath}/api/Afkam/GetTriageAfterCareFilesByEncryptedParam`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param refNbr 
     * @param lastName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTriageAfterCareLink(refNbr?: string, lastName?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<string>>;
    public getTriageAfterCareLink(refNbr?: string, lastName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<string>>>;
    public getTriageAfterCareLink(refNbr?: string, lastName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<string>>>;
    public getTriageAfterCareLink(refNbr?: string, lastName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refNbr !== undefined && refNbr !== null) {
            queryParameters = queryParameters.set('refNbr', <any>refNbr);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any>lastName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<string>>(`${this.basePath}/api/Afkam/GetTriageAfterCareLink`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Instances for an entity in a payor.
     * 
     * @param filterExpression Linq Expression Ex.: userName &#x3D;&#x3D; \\\&quot;Medcor\\\&quot; and  Age &gt; 0
     * @param pageIndex Linq Expression And Direction Ex. userName, userName Asc, userName Desc
     * @param pageSize 0 is not Allowed , Null or greater than 0
     * @param sortExpressions 0 is not allowed , Null or greated than 0
     * @param payorId 
     * @param entityId 
     * @param selectedIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchNotSelectedPayorInstances(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, payorId?: string, entityId?: string, selectedIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>;
    public searchNotSelectedPayorInstances(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, payorId?: string, entityId?: string, selectedIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchNotSelectedPayorInstances(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, payorId?: string, entityId?: string, selectedIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchNotSelectedPayorInstances(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, payorId?: string, entityId?: string, selectedIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterExpression !== undefined && filterExpression !== null) {
            queryParameters = queryParameters.set('FilterExpression', <any>filterExpression);
        }
        if (pageIndex !== undefined && pageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (sortExpressions) {
            queryParameters = queryParameters.set('SortExpressions', sortExpressions.join(COLLECTION_FORMATS['csv']));
        }
        if (payorId !== undefined && payorId !== null) {
            queryParameters = queryParameters.set('payorId', <any>payorId);
        }
        if (entityId !== undefined && entityId !== null) {
            queryParameters = queryParameters.set('entityId', <any>entityId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>(`${this.basePath}/api/Afkam/NotSelectedPayorInstances`,
            selectedIds,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Active Payors from Afkam
     * 
     * @param filterExpression Linq Expression Ex.: userName &#x3D;&#x3D; \\\&quot;Medcor\\\&quot; and  Age &gt; 0
     * @param pageIndex Linq Expression And Direction Ex. userName, userName Asc, userName Desc
     * @param pageSize 0 is not Allowed , Null or greater than 0
     * @param sortExpressions 0 is not allowed , Null or greated than 0
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPayors(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>;
    public searchPayors(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchPayors(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchPayors(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterExpression !== undefined && filterExpression !== null) {
            queryParameters = queryParameters.set('FilterExpression', <any>filterExpression);
        }
        if (pageIndex !== undefined && pageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (sortExpressions) {
            queryParameters = queryParameters.set('SortExpressions', sortExpressions.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>(`${this.basePath}/api/Afkam/SearchPayors`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Instances for an entity in a payor.
     * 
     * @param filterExpression Linq Expression Ex.: userName &#x3D;&#x3D; \\\&quot;Medcor\\\&quot; and  Age &gt; 0
     * @param pageIndex Linq Expression And Direction Ex. userName, userName Asc, userName Desc
     * @param pageSize 0 is not Allowed , Null or greater than 0
     * @param sortExpressions 0 is not allowed , Null or greated than 0
     * @param payorId 
     * @param entityId 
     * @param selectedIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSelectedPayorInstances(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, payorId?: string, entityId?: string, selectedIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>;
    public searchSelectedPayorInstances(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, payorId?: string, entityId?: string, selectedIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchSelectedPayorInstances(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, payorId?: string, entityId?: string, selectedIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchSelectedPayorInstances(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, payorId?: string, entityId?: string, selectedIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterExpression !== undefined && filterExpression !== null) {
            queryParameters = queryParameters.set('FilterExpression', <any>filterExpression);
        }
        if (pageIndex !== undefined && pageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (sortExpressions) {
            queryParameters = queryParameters.set('SortExpressions', sortExpressions.join(COLLECTION_FORMATS['csv']));
        }
        if (payorId !== undefined && payorId !== null) {
            queryParameters = queryParameters.set('payorId', <any>payorId);
        }
        if (entityId !== undefined && entityId !== null) {
            queryParameters = queryParameters.set('entityId', <any>entityId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>(`${this.basePath}/api/Afkam/SelectedPayorInstances`,
            selectedIds,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
