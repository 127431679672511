import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { SelectionTreeViewModel, MedcorResponse } from '../../rest';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { IchsTreeDialogComponent, IchsTreeDialogData, IchsTreeDialogReturnData } from './ichs-tree-dialog/ichs-tree-dialog.component';
import { first } from 'rxjs/operators';
import { AdvancedDateFilterationAttributes } from 'src/app/reporting/dashboard/dashboard.component';
import * as moment from 'moment';
import { SharedFunctionsService } from '../../services/shared-functions.service';

@Component({
  selector: 'ichs-dropdown-tree',
  templateUrl: './ichs-dropdown-tree.component.html',
  styleUrls: ['./ichs-dropdown-tree.component.css']
})
export class IchsDropDownTreeComponent implements OnChanges {
  @Input() data: SelectionTreeViewModel[] = [];
  @Input() name: string;
  @Input() label: string;
  @Input() disabled: boolean = false;
  @Input() isLevel: boolean = false;
  @Input() selectedLeaves: boolean = false;
  @Input() loadChildren: (node: SelectionTreeViewModel) => Observable<MedcorResponse<Array<SelectionTreeViewModel>>>;
  @Input() hideDataTree: boolean = false;
  @Input() customDateSelectionText: boolean = false;
  @Input() showAdvanceDate: boolean = false;
  @Input() advancedDateFilterationAttributes: AdvancedDateFilterationAttributes = <AdvancedDateFilterationAttributes>{};
  @Input() showSaveSelectionForFutureUse: boolean = false;
  @Input() showActiveOnlyToggle: boolean = false;
  @Output() selectionEvent = new EventEmitter<NodeSelectionModel>();
  @Output('onSubmit') submissionEvent = new EventEmitter<null>();
  @Output('onCancel') cancellationEvent = new EventEmitter<null>();
  @Output() advancedDateFiltersChanged = new EventEmitter<AdvancedDateFilterationAttributes>();

  public saveSelectionForFutureUse: boolean = false;
  public selectionModel: SelectionModel = new SelectionModel();
  private _hash: any = {};

  public get hash() {
    return this._hash;
  }

  constructor(
    private dialog: MatDialog,
    private sharedFunctionsService: SharedFunctionsService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.buildDataHierarchy(this.data);
    }

    this.selectionChanged();
  }

  ngOnInit() {
    if (this.hideDataTree) {
      this.selectionModel.selectedText = DROP_DOWN_TREE_LABEL_SELECT_DATE_RANGE;
    }
  }

  openTreePopup() {
    if (this.disabled || (!this.hideDataTree && (!this.data[0] || this.data[0].children.length == 0))) {
      return;
    }

    let originalSelections = this.getSelection();
    if (this.showAdvanceDate) {
      var originalAdvancedDateFilterationAttributes = Object.assign({}, this.advancedDateFilterationAttributes);
    }

    this.dialog.open(IchsTreeDialogComponent, {
      width: '750px',
      height: this.hideDataTree ? "340px" : "725px",
      data: <IchsTreeDialogData>{
        node: this.hideDataTree ? null : this.data[0],
        hash: this._hash,
        loadChildren: this.loadChildren,
        hideTree: this.hideDataTree,
        showAdvanceDate: this.showAdvanceDate,
        advanceDateFilter: this.advancedDateFilterationAttributes,
        showSaveSelectionForFutureUse: this.showSaveSelectionForFutureUse,
        showActiveOnlyToggle: this.showActiveOnlyToggle,
      }
    }).afterClosed()
      .pipe(first())
      .subscribe((result: IchsTreeDialogReturnData) => {
        if (result) {
          if (this.showAdvanceDate) {
            this.customDateFiltersSelectionChanged();
          }
          else {
            this.selectionChanged();
          }

          this.saveSelectionForFutureUse = result.saveSelectionForFutureUse;
          this.submissionEvent.emit();
        }
        else {
          // reset all to original values
          if (!this.hideDataTree) {
            this.sharedFunctionsService.correctTreeNodes(this.data[0], this.isLevel, originalSelections.included, originalSelections.excluded);
          }

          if (this.showAdvanceDate) {
            // copy by value          
            this.advancedDateFilterationAttributes.dateRange = originalAdvancedDateFilterationAttributes.dateRange;
            this.advancedDateFilterationAttributes.startDate = originalAdvancedDateFilterationAttributes.startDate;
            this.advancedDateFilterationAttributes.endDate = originalAdvancedDateFilterationAttributes.endDate;
          }

          this.cancellationEvent.emit();
        }
      });
  }

  getSelection(): NodeSelectionModel {
    const included: string[] = [];
    const excluded: string[] = [];

    if (this.data) {
      this.data.forEach(child => {
        const childSelection = this.getSelectionTree(child);
        let _included = childSelection.included.map(obj => this.isLevel || obj.level == "0" ? obj.level : obj.id);
        let _excluded = childSelection.excluded.map(obj => this.isLevel || obj.level == "0" ? obj.level : obj.id);

        included.push(..._included);
        excluded.push(..._excluded);
      });
    }

    return { included: included, excluded: excluded };
  }

  getLeafSelection(): NodeSelectionModel {
    const included: string[] = [];
    const excluded: string[] = [];

    if (this.data) {
      this.data.forEach(child => {
        const childSelection = this.getLeafSelectionTree(child);

        let _included = childSelection.included.map(obj => obj.level == "0" ? obj.level : obj.id);
        let _excluded = childSelection.excluded.map(obj => obj.level == "0" ? obj.level : obj.id);

        included.push(..._included);
        excluded.push(..._excluded);
      });
    }

    return { included: included, excluded: excluded };
  }

  selectionChanged() {
    if (this.hideDataTree) {
      return;
    }

    if (this.data.length > 0) {
      const included: SelectionTreeViewModel[] = [];
      const excluded: SelectionTreeViewModel[] = [];
      this.data.forEach(child => {
        const childSelection = this.getSelectionTree(child);
        included.push(...childSelection.included);
        excluded.push(...childSelection.excluded);
      });

      /* update selectedText */
      if (included.length === 1 && included[0].id === '0') {
        // if all items are 3 or less, show their labels, else show 'All'
        this.selectionModel.selectedText = included[0].children.length <= 3 ? included[0].children.map(obj => obj.label).join(", ") : 'All';
      }
      else if (excluded.length === 1 && excluded[0].id === '0') {
        this.selectionModel.selectedText = 'All Excluded';
      }
      else if (included.length <= 2) {
        let selectedText: string;

        if (this.customDateSelectionText) {
          let labels = included.map(obj => {
            return { parentId: obj.parentId, label: obj.label }
          });

          // change "Q1" to "year (Q1)" in date drop-down-tree selectedText
          for (let i = 0; i < labels.length; i++) {
            if (labels[i].label == "Q1" || labels[i].label == "Q2" || labels[i].label == "Q3" || labels[i].label == "Q4") {
              labels[i].label = labels[i].parentId + " (" + labels[i].label + ")";
            }
          }

          selectedText = labels.map(obj => obj.label).join(", ");
        }
        else {
          selectedText = included.map(obj => obj.label).join(", ");
        }

        this.selectionModel.selectedText = selectedText;
      }
      else {
        this.selectionModel.selectedText = 'Multiple';
      }

      let _included = included.map(obj => this.isLevel ? obj.level : obj.id);
      let _excluded = excluded.map(obj => this.isLevel ? obj.level : obj.id);

      this.selectionEvent.emit({ included: _included, excluded: _excluded });
    }
  }

  customDateFiltersSelectionChanged() {
    // if dateRange is set, then advanced date filters are used
    if (this.advancedDateFilterationAttributes.dateRange) {
      if (this.advancedDateFilterationAttributes.dateRange != "Custom") {
        this.selectionModel.selectedText = this.advancedDateFilterationAttributes.dateRange;
      }
      else {
        this.selectionModel.selectedText =
          moment(this.advancedDateFilterationAttributes.startDate).format('MM/DD/YYYY') +
          " - " +
          moment(this.advancedDateFilterationAttributes.endDate).format('MM/DD/YYYY');
      }

      this.advancedDateFiltersChanged.emit(this.advancedDateFilterationAttributes);
    }
    else {
      this.advancedDateFiltersChanged.emit(null);
      this.selectionChanged();
    }
  }

  reset() {
    if (!this.hideDataTree) {
      this.data.forEach(obj => {
        this.resetNode(obj);
      });

      this.selectionChanged();
    }
    else if (this.showAdvanceDate) {
      this.selectionModel.selectedText = DROP_DOWN_TREE_LABEL_SELECT_DATE_RANGE;
      this.selectionEvent.emit();
    }
  }

  resetNode(node: SelectionTreeViewModel) {
    node.selected = true;
    node.partialSelection = false;

    if (node.children) {
      node.children.forEach(obj => {
        this.resetNode(obj);
      });
    }
  }

  getSelectionTree(node: SelectionTreeViewModel): ObjNodeSelectionModel {
    if (node.partialSelection) {
      const included: SelectionTreeViewModel[] = [];
      const excluded: SelectionTreeViewModel[] = [];
      node.children.forEach(child => {
        const childSelection = this.getSelectionTree(child);
        included.push(...childSelection.included);
        excluded.push(...childSelection.excluded);
      })
      return { included: included, excluded: excluded };
    } else if (node.selected) {
      return { included: [node], excluded: [] };
    } else {
      return { included: [], excluded: [node] };
    }
  }

  getLeafSelectionTree(node: SelectionTreeViewModel): ObjNodeSelectionModel {
    if (node.children && node.children.length > 0) {
      const included: SelectionTreeViewModel[] = [];
      const excluded: SelectionTreeViewModel[] = [];
      node.children.forEach(child => {
        const childSelection = this.getLeafSelectionTree(child);
        included.push(...childSelection.included);
        excluded.push(...childSelection.excluded);
      })
      return { included: included, excluded: excluded };
    } else if (node.selected) {
      return { included: [node], excluded: [] };
    } else {
      return { included: [], excluded: [node] };
    }
  }

  getSelectedItemsNamedPaths(getLeafSelections: boolean = false): string[] {
    if (this.data.length > 0) {
      const included: SelectionTreeViewModel[] = [];
      const excluded: SelectionTreeViewModel[] = [];
      this.data.forEach(child => {
        const childSelection = getLeafSelections ? this.getLeafSelectionTree(child) : this.getSelectionTree(child);
        included.push(...childSelection.included);
        excluded.push(...childSelection.excluded);
      });

      /* get names of all selected items */
      if (included.length === 1 && included[0].id === '0') {
        // if all selected, get only the names of all root nodes
        return included[0].children.map(item => item.label);
      }
      else if (included.length > 0) {
        // get named paths for all selected items
        // replace 'Root>' or 'All>' that occur in the beginning of the path
        return included.map(item =>
          item.namedPath
            .replace(new RegExp('^Root>'), '')
            .replace(new RegExp('^All>'), '')
        );
      }
      else {
        // if nothing selected
        return [];
      }
    }

    return [];
  }

  isAllExcluded(): boolean {
    if (this.data[0] && this.data[0].children.length != 0) {
      return !(this.data[0].selected || this.data[0].partialSelection);
    }
    return true;
  }

  private buildDataHierarchy(data: SelectionTreeViewModel[]) {
    if (!this.hideDataTree && data) {
      data.forEach(obj => {
        this._hash[obj.level] = obj;
        this.buildDataHierarchy(obj.children);
      });
    }
  }
}

export class SelectionModel {
  selectedTitle: string;
  selectedText: string = DROP_DOWN_TREE_LABEL_LOADING;
}

export interface NodeSelectionModel {
  included: string[];
  excluded: string[];
}

export interface ObjNodeSelectionModel {
  included: SelectionTreeViewModel[];
  excluded: SelectionTreeViewModel[];
}

export const DROP_DOWN_TREE_LABEL_SELECT_DATE_RANGE: string = "Select Date Range";
export const DROP_DOWN_TREE_LABEL_LOADING: string = "Loading...";
