import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DIALOG_REF, IchsComponentDialogComponent } from 'src/app/controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { AccountsService } from 'src/app/rest';
import { AlertDismissType, AlertMessageType } from 'src/app/services/medcor-alert.service';
import { MedcorAlertService } from '../../services/medcor-alert.service';

@Component({
  selector: 'forget-password-form',
  templateUrl: './forget-password-form.component.html',
  styleUrls: ['./forget-password-form.component.css']
})
export class ForgetPasswordFormComponent implements OnInit {

  @ViewChild('form') ngForm: NgForm;
  resetEmail: string;

  constructor(
    private accountsService: AccountsService,
    private alertService: MedcorAlertService,
    @Inject(DIALOG_REF) private dialogRef: MatDialogRef<IchsComponentDialogComponent>,
  ) { }

  ngOnInit() { }

  submitForgetPasswordRequest() {
    if (this.ngForm.controls.resetEmail.invalid) {
      this.ngForm.controls.resetEmail.markAsTouched();
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please enter a valid email address"]
      });

      return;
    }

    this.accountsService.sendForgetPasswordForm(this.resetEmail).subscribe(() => this.dialogRef.close());
  }
}