import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, first, map } from "rxjs/operators";

@Injectable()
export class MedcorAuthenticationService {
  private _authToken = new BehaviorSubject<string | null>(null);
  private _originToken = new BehaviorSubject<string | null>(null);

  get authTokenObs(): Observable<string | null> { return this._authToken.pipe(distinctUntilChanged()); }
  get originTokenObs(): Observable<string | null> { return this._originToken.pipe(distinctUntilChanged()); }
  get authenticatedObs(): Observable<boolean> { return this.authTokenObs.pipe(distinctUntilChanged(), map(obj => obj != null && obj != '')); }

  constructor(private dialogRef: MatDialog) {
    this._authToken.next(sessionStorage.getItem('token'));
    this._originToken.next(sessionStorage.getItem('originToken'));

    this.authTokenObs.subscribe(token => {
      if (token && token != '')
        sessionStorage.setItem('token', token);
      else {
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('originToken');
      }
    });

    this.originTokenObs.subscribe(originToken => {
      if (originToken && originToken != '')
        sessionStorage.setItem('originToken', originToken);
      else
        sessionStorage.removeItem('originToken');
    });
  }

  authenticate() {
    this._authToken.next("Authenticated");
  }

  impersonate() {
    this._authToken.pipe(first()).subscribe(result => {
      sessionStorage.clear();
      this._originToken.next(result);
      this._authToken.next("Impersonate Authentication");
    });
  }

  unAuthenticate() {
    // close any opened mat dialogs
    this.dialogRef.closeAll();

    this._authToken.next(null);
    this._originToken.next(null);
    sessionStorage.clear();
  }

  unImpersonate() {
    this._originToken.pipe(first()).subscribe(result => {
      sessionStorage.clear();
      this._authToken.next(result);
      this._originToken.next(null);
    });
  }
}
