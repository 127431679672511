import { Component, ViewChildren, OnInit, AfterViewInit, Output, EventEmitter, Input, ViewChild, QueryList } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IFilter, FilterType, IAdvancedFilter, IBasicFilter } from 'powerbi-models';
import { first } from 'rxjs/operators';
import { IchsPowerBIReport } from 'src/app/controls/ichs-powerbi-report/ichs-powerbi-report.component';
import { DashboardAdvanceFiltersDialogModel, DashboardAdvanceFiltersDialogComponent } from 'src/app/controls/ichs-dialog/dashboard-advance-filters-dialog/dashboard-advance-filters-dialog.component';
import { IchsDropDownTreeComponent } from 'src/app/controls/ichs-dropdown-tree/ichs-dropdown-tree.component';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from 'src/app/services/medcor-alert.service';
import { GeneralVariablesService } from 'src/app/services/general-variables.service';
import { DashboardService, SelectionItemViewModel, AfkamCubeService, PowerBIReportViewModel, SelectionTreeViewModel, DashboardExportViewModel, UserDashboardBookmarkViewModel } from 'src/app/rest';
import { DateRangeEnum, SharedFiltersHelper } from 'src/app/controls/ichs-dialog/shared-filters-helper.component';
import { LineOfBusiness, ValidationMessages, DashboardSettings } from 'src/app/app.general.constants';
import { AdvancedDateFilterationAttributes, DashboardFilterationValues, DropdownTreeFilterationValues, FilterControlConfig, FilterTargetConfig, DateFilterControlConfig } from '../dashboard.component';
import * as moment from 'moment';
import { SharedFunctionsService } from '../../../services/shared-functions.service';
import { HttpEventType } from '@angular/common/http';
import { InputDialogConfig, IchsControl, IchsInputDialogComponent, IchsSelect, IchsTextBox } from 'src/app/controls/ichs-dialog/ichs-input-dialog/ichs-input-dialog.component';
import { AppGenericLists } from 'src/app/app.general.lists';
import { DashboardSchedulerComponent, DashboardSchedulerConfig } from '../../dashboard-scheduler/dashboard-scheduler.component';
import { DashboardItemViewModel } from '../../../rest/model/dashboardItemViewModel';
import { DashboardSchedulerViewModel } from '../../../rest/model/dashboardSchedulerViewModel';
import { IchsDialogComponent } from 'src/app/controls/ichs-dialog/ichs-dialog.component';

@Component({
  selector: 'triage-dashboard',
  templateUrl: './triage-dashboard.component.html',
  styleUrls: ['./triage-dashboard.component.css'],
})
export class TriageDashboardComponent implements OnInit, AfterViewInit {
  @Input() dashboardScheduler: DashboardSchedulerViewModel;
  @Output() maximized: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(IchsPowerBIReport) powerBIReport: IchsPowerBIReport;

  //Power BI Control properties
  powerBIResult: PowerBIReportViewModel;
  accessToken: string;
  hasAdvancedFilters: boolean;
  filters: Array<any> = [];
  selectedLineOfBusiness: string = LineOfBusiness.TRIAGE;
  isMaximized: boolean = false;
  advancedCustomFiltersDialogData: DashboardAdvanceFiltersDialogModel = <DashboardAdvanceFiltersDialogModel>{
    title: "Dashboard Filters",
    baseIds: [],
    filters: [],
    gridFilters: [],
    lineOfBusiness: this.selectedLineOfBusiness,
  };

  //Drop down tree properties 
  hierarchies: SelectionTreeViewModel[] = [];
  states: SelectionTreeViewModel[] = [];
  dates: SelectionTreeViewModel[] = [];

  @ViewChildren(IchsDropDownTreeComponent) allDropDownTreesQueryList: QueryList<IchsDropDownTreeComponent>;
  allDropDownTrees: IchsDropDownTreeComponent[];
  allDropDownTreesExceptDateTree: IchsDropDownTreeComponent[];
  enabledDropDownTrees: IchsDropDownTreeComponent[];
  dateDropDownTree: IchsDropDownTreeComponent;

  userDataGroups: SelectionItemViewModel[] = [];

  filterControlsConfig: TriageDashboardFilterControlsConfig = new TriageDashboardFilterControlsConfig(this.afkamCubeService);

  dateRangesItems: SelectionItemViewModel[];
  advancedDateFilters: IFilter[] = [];
  advancedDateFilterationAttributes: AdvancedDateFilterationAttributes = <AdvancedDateFilterationAttributes>{};

  storedFilterationValues: DashboardFilterationValues;

  exportFilter: string;
  isBookmarksPaneVisible: boolean = false;
  bookmarks: UserDashboardBookmarkViewModel[];

  constructor(
    private afkamCubeService: AfkamCubeService,
    private dashboardService: DashboardService,
    private alertService: MedcorAlertService,
    private dialog: MatDialog,
    private generalVariables: GeneralVariablesService,
    private sharedFilterHelper: SharedFiltersHelper,
    private sharedFunctions: SharedFunctionsService,
  ) {
  }

  ngOnInit() {
    this.dateRangesItems = this.sharedFilterHelper.BuildDateRanges();
    ///get and set the line of business
    this.loadControls();
    this.dashboardService.getUserDataGroups(this.selectedLineOfBusiness).pipe(first()).subscribe(res => {
      this.userDataGroups = res.result;
    });
  }

  ngAfterViewInit() {
    this.allDropDownTreesExceptDateTree = this.allDropDownTreesQueryList.filter(tree => tree.name != this.filterControlsConfig.dateHierarchy.name);
    this.dateDropDownTree = this.allDropDownTreesQueryList.find(tree => tree.name == this.filterControlsConfig.dateHierarchy.name);
    this.allDropDownTrees = this.dateDropDownTree.hideDataTree ? this.allDropDownTreesExceptDateTree : this.allDropDownTreesQueryList.toArray();
    this.enabledDropDownTrees = this.allDropDownTrees;
  }

  loadControls() {
    //Load dashboard related content from API
    this.dashboardService.generatePowerBIDashboard(this.selectedLineOfBusiness).pipe(first()).subscribe(
      returnValue => {
        if (returnValue.result) {
          this.powerBIResult = returnValue.result!;
          this.bookmarks = this.powerBIResult.userDashboardBookmarks;
          this.loadDashbaord();
        }
      }
    );
  }

  //Set dashboard properties
  loadDashbaord() {
    if (!this.powerBIResult.dashboardItem) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: ["You have not selected any dashboard item in Dashboard Maintenance."]
      });
      return;
    }
    this.accessToken = this.powerBIResult.accessTokenDetails!.accessToken!;
    this.initializeFilters();
  }

  //Initialize filters
  initializeFilters() {
    this.populateStoredDashboardFilterationValues();

    this.setAppUserFilter();

    //build filter controls
    this.buildFilterControls();
  }

  //Fill filters controls
  buildFilterControls() {
    //advanced filter dialog
    this.advancedCustomFiltersDialogData = <DashboardAdvanceFiltersDialogModel>{
      title: "Dashboard Filters",
      baseIds: this.powerBIResult.instanceIds!,
      filters: [],
      gridFilters: [],
      userDataGroups: this.userDataGroups,
      lineOfBusiness: this.selectedLineOfBusiness
    };

    if (sessionStorage.getItem(this.filterControlsConfig.clientHierarchy.sessionStorageKey)) {
      let _hierarchies = JSON.parse(sessionStorage.getItem(this.filterControlsConfig.clientHierarchy.sessionStorageKey)) as SelectionTreeViewModel[];

      if (this.storedFilterationValues && this.storedFilterationValues.dropdownTreeFilterationValues) {
        let storedHierarchies = this.storedFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.clientHierarchy.name);
        if (storedHierarchies) {
          let rootHierarchyNodeSelected = _hierarchies[0].children.findIndex(node => storedHierarchies.included.indexOf(node.id) > -1) > -1;

          // if one of the root nodes in hierarchy is selected, then populate it and invoke correctTreeNodes, else don't (or the result will be "All Excluded")
          if (rootHierarchyNodeSelected) {
            this.sharedFunctions.correctTreeNodes(_hierarchies[0], this.filterControlsConfig.clientHierarchy.isLevel, storedHierarchies.included, storedHierarchies.excluded);
          }
        }
      }

      this.hierarchies = _hierarchies;
    }
    else {
      //Fill Hierarchy dropdown tree
      this.afkamCubeService.getHierarchyLocations([]).pipe(first()).subscribe(
        returnValue => {
          if (returnValue.result) {
            let _hierarchies = returnValue.result!.selectionTrees;

            try {
              sessionStorage.setItem(this.filterControlsConfig.clientHierarchy.sessionStorageKey, JSON.stringify(_hierarchies));
            }
            catch (e) {
              let ex = e as Error;
              if (ex.name === 'QuotaExceededError') {
                console.warn(ex.message);
              }
              else {
                throw e;
              }
            }

            if (this.storedFilterationValues && this.storedFilterationValues.dropdownTreeFilterationValues) {
              let storedHierarchies = this.storedFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.clientHierarchy.name);
              if (storedHierarchies) {
                let rootHierarchyNodeSelected = _hierarchies[0].children.findIndex(node => storedHierarchies.included.indexOf(node.id) > -1) > -1;

                // if one of the root nodes in hierarchy is selected, then populate it and invoke correctTreeNodes, else don't (or the result will be "All Excluded")
                if (rootHierarchyNodeSelected) {
                  this.sharedFunctions.correctTreeNodes(_hierarchies[0], this.filterControlsConfig.clientHierarchy.isLevel, storedHierarchies.included, storedHierarchies.excluded);
                }
              }
            }

            this.hierarchies = _hierarchies;
          }
        }
      );
    }

    if (sessionStorage.getItem(this.filterControlsConfig.locationHierarchy.sessionStorageKey)) {
      let _states = JSON.parse(sessionStorage.getItem(this.filterControlsConfig.locationHierarchy.sessionStorageKey)) as SelectionTreeViewModel[];

      if (this.storedFilterationValues && this.storedFilterationValues.dropdownTreeFilterationValues) {
        let storedStates = this.storedFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.locationHierarchy.name);
        if (storedStates) {
          this.sharedFunctions.correctTreeNodes(_states[0], this.filterControlsConfig.locationHierarchy.isLevel, storedStates.included, storedStates.excluded);
        }
      }

      this.states = _states;
    }
    else {
      //Fill Geography dropdown tree
      this.afkamCubeService.getHierarchyLevels(
        {
          hierarchyName: '[Dim Location].[Location Geo Hierarchy]',
          isHierarchyLevels: true,
          levelsOrColumns: 1,
          stopOnCompany: false
        }
      ).pipe(first()).subscribe(
        returnValue => {
          if (returnValue.result) {
            let _states = returnValue.result!.selectionTrees;

            try {
              sessionStorage.setItem(this.filterControlsConfig.locationHierarchy.sessionStorageKey, JSON.stringify(_states));
            }
            catch (e) {
              let ex = e as Error;
              if (ex.name === 'QuotaExceededError') {
                console.warn(ex.message);
              }
              else {
                throw e;
              }
            }

            if (this.storedFilterationValues && this.storedFilterationValues.dropdownTreeFilterationValues) {
              let storedStates = this.storedFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.locationHierarchy.name);
              if (storedStates) {
                this.sharedFunctions.correctTreeNodes(_states[0], this.filterControlsConfig.locationHierarchy.isLevel, storedStates.included, storedStates.excluded);
              }
            }

            this.states = _states;
          }
        }
      );
    }

    if (this.dateDropDownTree.hideDataTree) {
      if (this.storedFilterationValues && this.storedFilterationValues.dateRange) {
        this.advancedDateFilterationAttributes.dateRange = this.storedFilterationValues.dateRange;
        setTimeout(() => this.onDateRangeChange());
      }
      else if (this.storedFilterationValues && this.storedFilterationValues.startDate && this.storedFilterationValues.endDate) {
        this.advancedDateFilterationAttributes.startDate = moment(this.storedFilterationValues.startDate).toDate();
        this.advancedDateFilterationAttributes.endDate = moment(this.storedFilterationValues.endDate).toDate();
        setTimeout(() => this.onDatesChange());
      }
      else {
        this.advancedDateFilterationAttributes.dateRange = DateRangeEnum.YearToDate;
        setTimeout(() => this.onDateRangeChange());
      }
    }
    else {
      if (sessionStorage.getItem(this.filterControlsConfig.dateHierarchy.sessionStorageKey)) {
        let _dates = JSON.parse(sessionStorage.getItem(this.filterControlsConfig.dateHierarchy.sessionStorageKey)) as SelectionTreeViewModel[];

        if (this.storedFilterationValues && this.storedFilterationValues.dateRange) {
          this.dates = _dates;
          this.advancedDateFilterationAttributes.dateRange = this.storedFilterationValues.dateRange;
          setTimeout(() => this.onDateRangeChange());
        }
        else if (this.storedFilterationValues && this.storedFilterationValues.startDate && this.storedFilterationValues.endDate) {
          this.dates = _dates;
          this.advancedDateFilterationAttributes.startDate = moment(this.storedFilterationValues.startDate).toDate();
          this.advancedDateFilterationAttributes.endDate = moment(this.storedFilterationValues.endDate).toDate();
          setTimeout(() => this.onDatesChange());
        }
        else if (this.storedFilterationValues && this.storedFilterationValues.dropdownTreeFilterationValues) {
          let storedDates = this.storedFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.dateHierarchy.name);
          if (storedDates) {
            this.sharedFunctions.correctTreeNodes(_dates[0], this.filterControlsConfig.dateHierarchy.isLevel, storedDates.included, storedDates.excluded);
          }
          this.dates = _dates;
        }
        else {
          this.dates = _dates;
          this.advancedDateFilterationAttributes.dateRange = DateRangeEnum.YearToDate;
          setTimeout(() => this.onDateRangeChange());
        }
      }
      else {
        //Fill date dropdown tree
        this.afkamCubeService.getHierarchyLevels(
          {
            hierarchyName: '[Dim Date Call].[Call Date Hierarchy]',
            isHierarchyLevels: true,
            levelsOrColumns: 4,
            stopOnCompany: false
          }
        ).pipe(first()).subscribe(
          returnValue => {
            if (returnValue.result) {
              let _dates = returnValue.result!.selectionTrees;

              try {
                sessionStorage.setItem(this.filterControlsConfig.dateHierarchy.sessionStorageKey, JSON.stringify(_dates));
              }
              catch (e) {
                let ex = e as Error;
                if (ex.name === 'QuotaExceededError') {
                  console.warn(ex.message);
                }
                else {
                  throw e;
                }
              }

              if (this.storedFilterationValues && this.storedFilterationValues.dateRange) {
                this.dates = _dates;
                this.advancedDateFilterationAttributes.dateRange = this.storedFilterationValues.dateRange;
                setTimeout(() => this.onDateRangeChange());
              }
              else if (this.storedFilterationValues && this.storedFilterationValues.startDate && this.storedFilterationValues.endDate) {
                this.dates = _dates;
                this.advancedDateFilterationAttributes.startDate = moment(this.storedFilterationValues.startDate).toDate();
                this.advancedDateFilterationAttributes.endDate = moment(this.storedFilterationValues.endDate).toDate();
                setTimeout(() => this.onDatesChange());
              }
              else if (this.storedFilterationValues && this.storedFilterationValues.dropdownTreeFilterationValues) {
                let storedDates = this.storedFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.dateHierarchy.name);
                if (storedDates) {
                  this.sharedFunctions.correctTreeNodes(_dates[0], this.filterControlsConfig.dateHierarchy.isLevel, storedDates.included, storedDates.excluded);
                }
                this.dates = _dates;
              }
              else {
                this.dates = _dates;
                this.advancedDateFilterationAttributes.dateRange = DateRangeEnum.YearToDate;
                setTimeout(() => this.onDateRangeChange());
              }
            }
          }
        );
      }
    }
  }

  //Initialize the advanced dashoard filters dialog
  public advanceFilters(): any {
    let dialogConfig: MatDialogConfig<DashboardAdvanceFiltersDialogModel> = {
      data: this.advancedCustomFiltersDialogData,
      width: '600px',
      maxHeight: '750px',
      disableClose: true
    };

    let dialogRef: MatDialogRef<DashboardAdvanceFiltersDialogComponent, DashboardAdvanceFiltersDialogModel> =
      this.dialog.open(DashboardAdvanceFiltersDialogComponent, dialogConfig);

    //Get result data from dialog when close
    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (!result) return;
      this.advancedCustomFiltersDialogData = result;
      this.hasAdvancedFilters = result.filters.length > 0;
      this.setFilters();
    });
  }

  resetDateFilters() {
    this.resetAdvancedDateFilters();
    this.resetDateDropDownTree();
  }

  resetAdvancedDateFilters() {
    this.advancedDateFilterationAttributes = <AdvancedDateFilterationAttributes>{};
    this.advancedDateFilters = [];
    this.enabledDropDownTrees = this.allDropDownTrees;
  }

  onAdvancedDateFiltersChange(advancedDateFilterationAttributes: AdvancedDateFilterationAttributes) {
    if (advancedDateFilterationAttributes) {
      this.advancedDateFilterationAttributes = advancedDateFilterationAttributes;
      this.setAdvancedDateFilters();
    }
    else {
      this.resetAdvancedDateFilters();

      if (this.dateDropDownTree.hideDataTree) {
        // resetting date dropdown tree, will reset its selection text
        this.resetDateDropDownTree();
      }
    }
  }

  onDateRangeChange() {
    var dates = this.sharedFilterHelper.populateDateFilters(this.advancedDateFilterationAttributes.dateRange);
    this.advancedDateFilterationAttributes.startDate = moment(dates.startDate).toDate();
    this.advancedDateFilterationAttributes.endDate = moment(dates.endDate).toDate();
    this.dateDropDownTree.selectionModel.selectedText = this.advancedDateFilterationAttributes.dateRange;
    this.setAdvancedDateFilters();
  }

  onDatesChange() {
    if (this.advancedDateFilterationAttributes.startDate && this.advancedDateFilterationAttributes.endDate) {
      this.advancedDateFilterationAttributes.dateRange = "Custom";
      this.dateDropDownTree.selectionModel.selectedText =
        moment(this.advancedDateFilterationAttributes.startDate).format('MM/DD/YYYY') +
        " - " +
        moment(this.advancedDateFilterationAttributes.endDate).format('MM/DD/YYYY');

      this.setAdvancedDateFilters();
    }
  }

  setAdvancedDateFilters() {
    if (this.advancedDateFilterationAttributes.startDate && this.advancedDateFilterationAttributes.endDate) {
      let dateFilter: IAdvancedFilter = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: this.filterControlsConfig.dateHierarchy.advancedDateFilterTarget,
        logicalOperator: "And",
        conditions: [
          {
            operator: "GreaterThanOrEqual",
            value: this.formatDate(this.advancedDateFilterationAttributes.startDate),
          },
          {
            operator: "LessThanOrEqual",
            value: this.formatDate(this.advancedDateFilterationAttributes.endDate),
          }
        ],
        filterType: FilterType.Advanced
      };

      this.advancedDateFilters = [dateFilter];

      // exclude date tree from filteration
      this.enabledDropDownTrees = this.allDropDownTreesExceptDateTree;

      // clear date filter tree
      this.dateDropDownTree.data.forEach(obj => {
        this.dateDropDownTree.resetNode(obj);
      });

      this.setFilters();
    }
  }

  //Clear dashboard filters
  clearFilter() {
    this.hasAdvancedFilters = false;

    this.advancedDateFilters = [];
    this.advancedDateFilterationAttributes = <AdvancedDateFilterationAttributes>{};

    this.advancedCustomFiltersDialogData = <DashboardAdvanceFiltersDialogModel>{
      title: "Dashboard Filters",
      baseIds: this.powerBIResult.instanceIds!,
      filters: [],
      gridFilters: [],
      lineOfBusiness: this.selectedLineOfBusiness
    };

    this.filters.splice(0);
    this.setAppUserFilter();

    this.resetDateFilters();
    this.allDropDownTreesExceptDateTree.forEach(dropDownTree => {
      dropDownTree.disabled = false;
      dropDownTree.reset();
    });

    this.powerBIReport.dataSelectionFilters.splice(0);
    this.powerBIReport.reload();
  }

  //Set Dashboard filters
  setFilters() {
    this.filters.splice(0);
    this.setAppUserFilter();

    if (this.advancedCustomFiltersDialogData.filters.length > 0) {
      this.advancedCustomFiltersDialogData.filters.forEach(filter => this.filters.push(filter));
    }
    if (this.advancedDateFilters.length > 0) {
      this.advancedDateFilters.forEach(filter => this.filters.push(filter));
    }

    this.enabledDropDownTrees.forEach(dropDownTree => {
      var selection = dropDownTree.getSelection();

      // for hierarchy filter, always send all included selection 
      // (to avoid the slow NotIn operator, and to avoid showing FullDataAccess in case no filter was sent)
      if (dropDownTree.name == this.filterControlsConfig.clientHierarchy.name) {

        if (selection.included.length === 1 && selection.included[0] === '0') {
          if (!this.powerBIResult.appUserId) {
            this.filterControls(dropDownTree.name, []);
          }
          else {
            let selectedIds = dropDownTree.data[0].children.map(child => child.id);
            this.filterControls(dropDownTree.name, selectedIds);
          }
        }
        else if (selection.excluded.length === 1 && selection.excluded[0] === '0') {
          // if all excluded, filter on a string that will never occur, to retrive null data
          this.filterControls(dropDownTree.name, ["string_that_will_never_occur_in_data"]);
        }
        else {
          this.filterControls(dropDownTree.name, selection.included);
        }
      }
      else {
        if (selection.included.length === 1 && selection.included[0] === '0') {
          this.filterControls(dropDownTree.name, [], true);
        }
        else if (selection.excluded.length === 1 && selection.excluded[0] === '0') {
          // if all excluded, filter on a string that will never occur, to retrive null data
          this.filterControls(dropDownTree.name, ["string_that_will_never_occur_in_data"], true);
        }
        else if (selection.included.length <= selection.excluded.length) {
          this.filterControls(dropDownTree.name, selection.included.map(obj => obj.slice(2)), true);
        }
        else {
          this.filterControls(dropDownTree.name, selection.excluded.map(obj => obj.slice(2)), false);
        }
      }
    });

    // don't store before ensuring that all drop-down trees are loaded
    if ((this.dateDropDownTree.hideDataTree || this.dates.length > 0) && this.hierarchies.length > 0 && this.states.length > 0) {
      this.storeDashboardFilterationValues();
    }

    if (this.powerBIReport) {
      this.powerBIReport.setFilters(this.filters);
    }

    this.setExportFilter();
  }

  filterControls(controlName: string, ids: string[], isInclude: boolean = null) {
    switch (controlName) {
      case this.filterControlsConfig.dateHierarchy.name: {
        this.setDateFilter(ids, isInclude);
        break;
      }
      case this.filterControlsConfig.clientHierarchy.name: {
        this.setHierarchyFilter(ids);
        break;
      }
      case this.filterControlsConfig.locationHierarchy.name: {
        this.setStateFilter(ids, isInclude);
        break;
      }
    }
  }

  showDashboardError() {
    this.alertService.addAlert({
      type: AlertMessageType.warning,
      title: 'Warning!',
      dismiss: AlertDismissType.auto,
      messages: ["Dashboard items were not loaded correctly."]
    });
  }

  setAppUserFilter() {
    if (this.powerBIResult.appUserId && this.powerBIResult.appUserId != 0 && !this.powerBIResult.isTriagePerformanceAndOutcomesDashboard) {
      let appUserFilter: IAdvancedFilter = {
        $schema: 'http://powerbi.com/product/schema#advanced',
        target: this.filterControlsConfig.appuserFilter.filterTarget,
        logicalOperator: 'And',
        filterType: FilterType.Advanced,
        conditions: [{ operator: 'Is', value: this.powerBIResult.appUserId }],
      };

      this.filters.push(appUserFilter);
    }
  }

  //Set structure hierarchy filter 
  setHierarchyFilter(hierarchies: string[]) {
    let hierarchyFilter: IBasicFilter = null;
    if (hierarchies.length > 0) {
      // for hierarchy filter, always send all included selection (to avoid the slow NotIn operator)
      hierarchyFilter = {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: this.filterControlsConfig.clientHierarchy.filterTarget,
        filterType: FilterType.Basic,
        operator: 'In',
        values: hierarchies,
      };

      this.filters.push(hierarchyFilter);
    }
  }

  //Set Date hierarchy filter 
  setDateFilter(dates: string[], isIncluded: boolean) {
    var conditions: any[] = [];
    if (dates.length > 0) {

      dates.forEach(item => conditions.push({ operator: (!isIncluded ? 'DoesNotStartWith' : 'StartsWith'), value: item.toString() }));
      let dateFilter: IAdvancedFilter = {
        $schema: 'http://powerbi.com/product/schema#advanced',
        target: this.filterControlsConfig.dateHierarchy.filterTarget,
        logicalOperator: (!isIncluded ? 'And' : 'Or'),
        filterType: FilterType.Advanced,
        conditions: conditions
      };

      this.filters.push(dateFilter);
    }
  }

  //Set Georgraphy hierarchy filter 
  setStateFilter(states: string[], isIncluded: boolean) {
    if (states.length > 0) {
      let stateFilter = {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: this.filterControlsConfig.locationHierarchy.filterTarget,
        filterType: FilterType.Basic,
        operator: 'In',
        values: states,
      };

      this.filters.push(stateFilter);
    }
  }

  itemMaximized(isMaximized: boolean) {
    this.isMaximized = isMaximized;
    this.maximized.emit(this.isMaximized);
  }

  viewExportDashboardDialog() {
    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: new InputDialogConfig({
        hasTitle: false,
        message: "Please select exported file format",
        controls: [
          new IchsSelect({
            required: true,
            placeholder: "File format",
            items: AppGenericLists.DashboardExportFormat,
          })
        ],
      }),
      width: "400px",
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        let exportFormat = result[0].value;
        this.exportDashboardToFile(exportFormat);
      }
    });
  }

  scheduleDashboard() {
    if (
      !this.powerBIResult || !this.powerBIResult.dashboardItem ||
      this.hierarchies.length == 0 || this.states.length == 0
    ) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: [ValidationMessages.WAIT_DASHBOARD_FULL_LOAD]
      });
      return;
    }

    let dialogConfig: MatDialogConfig<DashboardSchedulerConfig> = {
      data: {
        dashboard: <DashboardItemViewModel>{
          id: this.powerBIResult.dashboardItem.dashboardItemId,
          name: this.powerBIResult.dashboardItem.name,
        },
        dateHierarchy: {
          label: this.filterControlsConfig.dateHierarchy.label,
          name: this.filterControlsConfig.dateHierarchy.name,
          advancedDateFilterationAttributes: JSON.parse(JSON.stringify(this.advancedDateFilterationAttributes)),
        },
        secondHierarchy: {
          label: this.filterControlsConfig.clientHierarchy.label,
          name: this.filterControlsConfig.clientHierarchy.name,
          data: JSON.parse(JSON.stringify(this.hierarchies)),
          isLevel: false,
          loadChildrenFunction: this.filterControlsConfig.clientHierarchy.loadChildrenFunction,
        },
        thirdHierarchy: {
          label: this.filterControlsConfig.locationHierarchy.label,
          name: this.filterControlsConfig.locationHierarchy.name,
          data: JSON.parse(JSON.stringify(this.states)),
          isLevel: true,
        },
      },
      width: '800px',
      disableClose: true
    };

    this.dialog.open(DashboardSchedulerComponent, dialogConfig);
  }

  addNewBookmark() {
    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: new InputDialogConfig({
        title: "Save to 'My Views'",
        message: "Enter a name for this view",
        controls: [
          new IchsTextBox({
            required: true,
            placeholder: "Example: December 2019 Sales Profit",
          })
        ],
      }),
      width: "400px",
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        let newBookmarkName = result[0].value;

        this.powerBIReport.captureBookmarkState().then(bookmark => {
          this.dashboardService.addDashboardBookmark({
            name: newBookmarkName,
            data: bookmark.state,
            userDashboardItemAssociationId: this.powerBIResult.dashboardItem.userDashboardItemAssociationId,
            dashboardFiltrationValues: JSON.stringify(this.getCurrentDashboardFilterationValues()),
          }).pipe(first()).subscribe(resp => {
            let newBookmark = resp.result;
            this.bookmarks.push(<UserDashboardBookmarkViewModel>{
              id: newBookmark.id,
              name: newBookmark.name,
            });
          });
        });
      }
    });
  }

  applyBookmark(bookmarkId: number) {
    this.dashboardService.getDashboardBookmarkAndSetAsDefault(bookmarkId).pipe(first()).subscribe(resp => {
      this.powerBIReport.bookmarkAppliedManually = true;
      let bookmark = resp.result;

      this.powerBIReport.applyBookmarkState(bookmark.data).then(() => {
        this.applyBookmarkFilters(bookmark);
      })
    });

    this.hideBookmarksPane();
  }

  deleteBookmark(bookmarkId: number) {
    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Delete Bookmark", message: "Are you sure about deleting this bookmark?" }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.dashboardService.deleteDashboardBookmark(bookmarkId).pipe(first()).subscribe(() => {
          this.bookmarks = this.bookmarks.filter(bookmark => bookmark.id != bookmarkId);
        });
      }
    });
  }

  showBookmarksPane() {
    this.isBookmarksPaneVisible = true;
  }

  hideBookmarksPane() {
    this.isBookmarksPaneVisible = false;
  }

  private applyBookmarkFilters(bookmark: UserDashboardBookmarkViewModel) {
    let bookmarkStoredFilterationValues = JSON.parse(bookmark.dashboardFiltrationValues) as DashboardFilterationValues;
    let storedHierarchies = bookmarkStoredFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.clientHierarchy.name);
    let storedLocations = bookmarkStoredFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.locationHierarchy.name);

    this.sharedFunctions.correctTreeNodes(this.hierarchies[0], this.filterControlsConfig.clientHierarchy.isLevel, storedHierarchies.included, storedHierarchies.excluded);
    this.sharedFunctions.correctTreeNodes(this.states[0], this.filterControlsConfig.locationHierarchy.isLevel, storedLocations.included, storedLocations.excluded);

    for (let tree of this.allDropDownTreesExceptDateTree) {
      tree.selectionChanged();
    }

    if (bookmarkStoredFilterationValues.dateRange) {
      this.advancedDateFilterationAttributes.dateRange = bookmarkStoredFilterationValues.dateRange;
      this.onDateRangeChange();
    }
    else if (bookmarkStoredFilterationValues.startDate && bookmarkStoredFilterationValues.endDate) {
      this.advancedDateFilterationAttributes.startDate = new Date(bookmarkStoredFilterationValues.startDate);
      this.advancedDateFilterationAttributes.endDate = new Date(bookmarkStoredFilterationValues.endDate);
      this.onDatesChange();
    }
    else if (!this.dateDropDownTree.hideDataTree) {
      let storedDates = bookmarkStoredFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.dateHierarchy.name);
      if (storedDates) {
        this.sharedFunctions.correctTreeNodes(this.dates[0], this.filterControlsConfig.dateHierarchy.isLevel, storedDates.included, storedDates.excluded);
        this.dateDropDownTree.selectionChanged();
      }
    }
  }

  private setExportFilter() {
    this.exportFilter = null;

    let appUserFilter = this.filters.find(filter => filter.target == this.filterControlsConfig.appuserFilter.filterTarget);
    if (appUserFilter) {
      let appUserFilterTable = appUserFilter.target.table.replace(new RegExp(' ', 'ig'), DashboardSettings.DASHBOARD_EXPORT_FILTER_SPACE_ESCAPE);
      let appUserFilterColumn = appUserFilter.target.column.replace(new RegExp(' ', 'ig'), DashboardSettings.DASHBOARD_EXPORT_FILTER_SPACE_ESCAPE);

      let appUserExportFilter = `${appUserFilterTable}/${appUserFilterColumn} eq ${this.powerBIResult.appUserId}`;
      this.exportFilter = appUserExportFilter;
    }

    let hierarchyFilter = this.filters.find(filter => filter.target == this.filterControlsConfig.clientHierarchy.filterTarget);
    if (hierarchyFilter) {
      let hierarchyFilterTable = hierarchyFilter.target.table.replace(new RegExp(' ', 'ig'), DashboardSettings.DASHBOARD_EXPORT_FILTER_SPACE_ESCAPE);
      let hierarchyFilterColumn = hierarchyFilter.target.column.replace(new RegExp(' ', 'ig'), DashboardSettings.DASHBOARD_EXPORT_FILTER_SPACE_ESCAPE);

      let _values = hierarchyFilter.values as string[];
      let valueList = _values.map(val => `'${val}'`).join(',');

      let hierarchyExportFilter = `${hierarchyFilterTable}/${hierarchyFilterColumn} in (${valueList})`;
      if (this.exportFilter) {
        this.exportFilter += ` and ${hierarchyExportFilter}`;
      }
      else {
        this.exportFilter = hierarchyExportFilter;
      }
    }

    let stateFilter = this.filters.find(filter => filter.target == this.filterControlsConfig.locationHierarchy.filterTarget);
    if (stateFilter) {
      let stateFilterTable = stateFilter.target.table.replace(new RegExp(' ', 'ig'), DashboardSettings.DASHBOARD_EXPORT_FILTER_SPACE_ESCAPE);
      let stateFilterColumn = stateFilter.target.column.replace(new RegExp(' ', 'ig'), DashboardSettings.DASHBOARD_EXPORT_FILTER_SPACE_ESCAPE);

      let stateExportFilter = `${stateFilterTable}/${stateFilterColumn} in (${stateFilter.values.map(val => `'${val}'`).join(',')})`;
      if (this.exportFilter) {
        this.exportFilter += ` and ${stateExportFilter}`;
      }
      else {
        this.exportFilter = stateExportFilter;
      }
    }

    let dateRangeFilter = this.filters.find(filter => filter.target == this.filterControlsConfig.dateHierarchy.advancedDateFilterTarget);
    if (dateRangeFilter) {
      let dateRangeFilterTable = dateRangeFilter.target.table.replace(new RegExp(' ', 'ig'), DashboardSettings.DASHBOARD_EXPORT_FILTER_SPACE_ESCAPE);
      let dateRangeFilterColumn = dateRangeFilter.target.column.replace(new RegExp(' ', 'ig'), DashboardSettings.DASHBOARD_EXPORT_FILTER_SPACE_ESCAPE);

      let dateRangeExportFilter = `${dateRangeFilterTable}/${dateRangeFilterColumn} ge ${this.formatDate(this.advancedDateFilterationAttributes.startDate)} and ${dateRangeFilterTable}/${dateRangeFilterColumn} le ${this.formatDate(this.advancedDateFilterationAttributes.endDate)}`;
      if (this.exportFilter) {
        this.exportFilter += ` and ${dateRangeExportFilter}`;
      }
      else {
        this.exportFilter = dateRangeExportFilter;
      }
    }
  }

  private exportDashboardToFile(exportFormat: string) {
    let dashboardExportVM: DashboardExportViewModel = {
      userDashboardItemAssociationId: this.powerBIResult.dashboardItem.userDashboardItemAssociationId,
      exportFormat: exportFormat,
      filter: this.exportFilter,
    };

    let sub = this.dashboardService.exportDashboardToFile(dashboardExportVM, 'response', true)
      .subscribe(
        event => {
          if (event.type == HttpEventType.Response) {
            this.sharedFunctions.saveToFileSystem(event);
          }
        },
        () => { sub.unsubscribe() },
        () => { sub.unsubscribe() });
  }

  // un-select all years in date drop-down tree except last year
  private resetDateDropDownTree() {
    let numberOfItemsToKeepSelected = 1;

    if (!this.dateDropDownTree.hideDataTree && this.dates[0] && this.dates[0].children.length > numberOfItemsToKeepSelected) {
      let nodesToSelect = this.dates[0].children.slice(-numberOfItemsToKeepSelected);
      let nodesToDeselect = this.dates[0].children.slice(0, this.dates[0].children.length - numberOfItemsToKeepSelected);

      this.dates[0].partialSelection = true;
      nodesToSelect.forEach(node => { node.selected = true; node.partialSelection = false; this.toggleNodeChildren(node); });
      nodesToDeselect.forEach(node => { node.selected = false; node.partialSelection = false; this.toggleNodeChildren(node); });

      this.dateDropDownTree.selectionChanged();
    }
    else {
      this.dateDropDownTree.reset();
    }
  }

  private toggleNodeChildren(node: SelectionTreeViewModel) {
    if (!node || !node.children || node.children.length == 0) {
      return;
    }
    node.children.forEach(child => {
      child.partialSelection = false;
      child.selected = node.selected;
      this.toggleNodeChildren(child);
    });
  }

  private formatDate(date): number {
    let _date = null;

    if (date instanceof Date) {
      _date = date;
    }
    else {  // Moment
      _date = date.toDate();
    }

    let day = _date.getDate();
    let monthIndex = _date.getMonth() + 1;
    let year = _date.getFullYear();

    return (((year * 100) + monthIndex) * 100) + day;
  }

  private storeDashboardFilterationValues() {
    // local storage is disabled in case of impersonation
    if (this.generalVariables.userImpersonationInfo.isImpersonatedInd) {
      return;
    }

    let dashboardFilterValues = this.getCurrentDashboardFilterationValues();

    try {
      localStorage.setItem(this.filterControlsConfig.localStorageKey, JSON.stringify(dashboardFilterValues));
    }
    catch (e) {
      let ex = e as Error;
      if (ex.name === 'QuotaExceededError') {
        console.warn(ex.message);
      }
      else {
        throw e;
      }
    }
  }

  private getCurrentDashboardFilterationValues() {
    let dashboardFilterValues: DashboardFilterationValues = new DashboardFilterationValues();

    if (this.advancedDateFilterationAttributes.dateRange && this.advancedDateFilterationAttributes.dateRange != "Custom") {
      dashboardFilterValues.dateRange = this.advancedDateFilterationAttributes.dateRange;
    }
    else if (this.advancedDateFilterationAttributes.startDate && this.advancedDateFilterationAttributes.endDate) {
      dashboardFilterValues.startDate = moment(this.advancedDateFilterationAttributes.startDate).format('YYYY-MM-DD');
      dashboardFilterValues.endDate = moment(this.advancedDateFilterationAttributes.endDate).format('YYYY-MM-DD');
    }

    this.enabledDropDownTrees.forEach(dropDownTree => {
      var selection = dropDownTree.getSelection();
      let dropdownTreeFilterValues = <DropdownTreeFilterationValues>{
        treeName: dropDownTree.name,
        included: selection.included,
        excluded: selection.excluded,
      };

      dashboardFilterValues.dropdownTreeFilterationValues.push(dropdownTreeFilterValues)
    });

    return dashboardFilterValues;
  }

  private populateStoredDashboardFilterationValues() {
    if (this.dashboardScheduler) {
      this.storedFilterationValues = JSON.parse(this.dashboardScheduler.dashboardParam) as DashboardFilterationValues;
    }
    else {
      // local storage is disabled in case of impersonation
      if (this.generalVariables.userImpersonationInfo.isImpersonatedInd) {
        return;
      }

      let _storedValues = localStorage.getItem(this.filterControlsConfig.localStorageKey);
      this.storedFilterationValues = JSON.parse(_storedValues) as DashboardFilterationValues;
    }
  }
}


export class TriageDashboardFilterControlsConfig {
  public readonly localStorageKey: string = "TriageDashboardFilterationValues";

  public readonly dateHierarchy: DateFilterControlConfig = {
    label: "Date Range",
    name: "DateFilter",
    isLevel: true,
    sessionStorageKey: "afkamDates",
    filterTarget: {
      table: "Dim Date Call",
      column: "Filter",
    },
    advancedDateFilterTarget: {
      table: "Dim Date Call",
      column: "Date Integer",
    },
  };
  public readonly clientHierarchy: FilterControlConfig = {
    label: "Client Hierarchy",
    name: "HierarchyFilter",
    isLevel: false,
    sessionStorageKey: "afkamHierarchy",
    filterTarget: {
      table: "Dim Hierarchy Nodes",
      column: "Node Id",
    },
    loadChildrenFunction: (node: SelectionTreeViewModel) => this.afkamCubeService.getHierarchyNodeChildren(node.level, node.selected),
  };
  public readonly locationHierarchy: FilterControlConfig = {
    label: "Geographic Location",
    name: "GeographyFilter",
    isLevel: true,
    sessionStorageKey: "afkamLocations",
    filterTarget: {
      table: "Dim Location",
      column: "Location State Abbr",
    },
  };

  public readonly appuserFilter: FilterTargetConfig = {
    filterTarget: {
      table: "Dim User Hierarchy",
      column: "App User Id",
    }
  };

  constructor(private afkamCubeService: AfkamCubeService) { }
}