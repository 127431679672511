import { Component, Input, EventEmitter, Output, ViewChild, Inject, AfterViewChecked, AfterViewInit, AfterContentInit, OnDestroy } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel, RequiredValidator } from '@angular/forms';
import { ControlBase } from '../control-base';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
    selector: 'ichs-check-box',
    templateUrl: './ichs-check-box.component.html',
    styleUrls: ['./ichs-check-box.component.css'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: IchsCheckBoxComponent,
        multi: true,
    }],
    host: { class: "display-flex flex-items-centered" }
})
export class IchsCheckBoxComponent extends ControlBase<boolean> {
    @Input('label') label: string;
    @Input("disabled") isDisabled: boolean;
    @Input("indeterminate") indeterminate: boolean = false;
    isRequired: boolean = false;

    @Output() checkBoxChanged: EventEmitter<MatCheckboxChange> = new EventEmitter<MatCheckboxChange>();

    @ViewChild(NgModel, { static: true }) model: NgModel;

    // this attribute to give a unique identifier for the textboxs.
    public checkboxIdentifier = `ichs-chek-boxt-${checkboxIdentifier++}`;

    ngOnInit() {
        super.ngOnInit();
        if (this.validators) {
            for (var i = 0; i < this.validators.length; i++) {
                if (this.validators[i] instanceof RequiredValidator) {
                    this.isRequired = true;
                }
            }
            //if required and there is no value then set it indeterminate.
            if (this.model.value == null && this.isRequired) {
                this.indeterminate = true;
            }
        }
    }

    // raise an event to the outer component.
    checkBoxChangedEvent(event: MatCheckboxChange) {
        this.checkBoxChanged.emit(event);
    }
}

let checkboxIdentifier = 0;