<div>
  Log Time: {{logData.logTime | amFromUtc | amLocal | date: 'MM/dd/yyyy h:mm:ss a'}}
</div>
<br />

<div>
  User Email: {{logData.userEmail}}
</div>
<br />

<div>
  Impersonator Email: {{logData.impersonatorUserEmail}}
</div>
<br />

<div>
  Log ID: {{logData.logId}}
</div>
<br />

<div>
  Client IP: {{logData.clientIp}}
</div>
<br />

<div>
  Level: {{logData.level}}
</div>
<br />

<div>
  Action: {{logData.action}}
</div>
<br />

<div>
  Request URL: {{logData.requestUrl}}
</div>
<br />

<div>
  Response Message: {{logData.logType}}
</div>
<br />

<div>
  Entity Name: {{logData.entityName}}
</div>
<br />

<div>
  Entity ID: {{logData.entityId}}
</div>
<br />

<div>
  Extra Info: {{logData.extraInfo}}
</div>
<br />

<div>
  User Agent: {{logData.userAgent}}
</div>
<br />

<div>
  Message: {{logData.message}}
</div>
<br />

<div *ngIf="logData.isException">
  Exception: {{logData.exception}}
</div>