/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';










import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {MedcorResponse,PaginatedListViewModel,CubeHierarchyParameterViewModel,OshaReportableLocationsSearchViewModel,DynamicInstanceViewModel,SelectionTreeViewModel,SelectionItemViewModel} from '../index';


@Injectable()
export class OshaReportableService {

    protected basePath = 'https://localhost/ClientPortalAPI';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get Company by Id
     * 
     * @param companyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompany(companyId?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<DynamicInstanceViewModel>>;
    public getCompany(companyId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<DynamicInstanceViewModel>>>;
    public getCompany(companyId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<DynamicInstanceViewModel>>>;
    public getCompany(companyId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (companyId !== undefined && companyId !== null) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<DynamicInstanceViewModel>>(`${this.basePath}/api/OshaReportable/GetCompany`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get OSHA Company Location Tree
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCompanyLocationTree(observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<SelectionTreeViewModel>>>;
    public getCompanyLocationTree(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<SelectionTreeViewModel>>>>;
    public getCompanyLocationTree(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<SelectionTreeViewModel>>>>;
    public getCompanyLocationTree(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<SelectionTreeViewModel>>>(`${this.basePath}/api/OshaReportable/GetCompanyLocationTree`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Hierarchy levels selection tree
     * 
     * @param cubeHierarchy 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getHierarchyLevels(cubeHierarchy?: CubeHierarchyParameterViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<CubeHierarchyParameterViewModel>>;
    public getHierarchyLevels(cubeHierarchy?: CubeHierarchyParameterViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<CubeHierarchyParameterViewModel>>>;
    public getHierarchyLevels(cubeHierarchy?: CubeHierarchyParameterViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<CubeHierarchyParameterViewModel>>>;
    public getHierarchyLevels(cubeHierarchy?: CubeHierarchyParameterViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<CubeHierarchyParameterViewModel>>(`${this.basePath}/api/OshaReportable/HierarchyLevels`,
            cubeHierarchy,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get OSHA States Tree
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getStatesTree(observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<SelectionTreeViewModel>>>;
    public getStatesTree(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<SelectionTreeViewModel>>>>;
    public getStatesTree(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<SelectionTreeViewModel>>>>;
    public getStatesTree(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<SelectionTreeViewModel>>>(`${this.basePath}/api/OshaReportable/GetStatesTree`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Locations by ID from OSHA Reportable
     * 
     * @param companyIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserLocationsByCompanies(companyIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<SelectionItemViewModel>>>;
    public getUserLocationsByCompanies(companyIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<SelectionItemViewModel>>>>;
    public getUserLocationsByCompanies(companyIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<SelectionItemViewModel>>>>;
    public getUserLocationsByCompanies(companyIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<Array<SelectionItemViewModel>>>(`${this.basePath}/api/OshaReportable/GetUserLocationsByCompanies`,
            companyIds,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search All Companies
     * 
     * @param filterExpression Linq Expression Ex.: userName &#x3D;&#x3D; \\\&quot;Medcor\\\&quot; and  Age &gt; 0
     * @param pageIndex Linq Expression And Direction Ex. userName, userName Asc, userName Desc
     * @param pageSize 0 is not Allowed , Null or greater than 0
     * @param sortExpressions 0 is not allowed , Null or greated than 0
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchCompanies(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>;
    public searchCompanies(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchCompanies(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchCompanies(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterExpression !== undefined && filterExpression !== null) {
            queryParameters = queryParameters.set('FilterExpression', <any>filterExpression);
        }
        if (pageIndex !== undefined && pageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (sortExpressions) {
            queryParameters = queryParameters.set('SortExpressions', sortExpressions.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>(`${this.basePath}/api/OshaReportable/SearchCompanies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Selected or Not Selected Locations by Companies
     * 
     * @param oshaSearchVM 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchLocations(oshaSearchVM?: OshaReportableLocationsSearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>;
    public searchLocations(oshaSearchVM?: OshaReportableLocationsSearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchLocations(oshaSearchVM?: OshaReportableLocationsSearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchLocations(oshaSearchVM?: OshaReportableLocationsSearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>(`${this.basePath}/api/OshaReportable/SearchLocations`,
            oshaSearchVM,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Not Selected Locations by CompanyId
     * 
     * @param filterExpression Linq Expression Ex.: userName &#x3D;&#x3D; \\\&quot;Medcor\\\&quot; and  Age &gt; 0
     * @param pageIndex Linq Expression And Direction Ex. userName, userName Asc, userName Desc
     * @param pageSize 0 is not Allowed , Null or greater than 0
     * @param sortExpressions 0 is not allowed , Null or greated than 0
     * @param companyId 
     * @param selectedIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchNotSelectedLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, companyId?: string, selectedIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>;
    public searchNotSelectedLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, companyId?: string, selectedIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchNotSelectedLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, companyId?: string, selectedIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchNotSelectedLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, companyId?: string, selectedIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterExpression !== undefined && filterExpression !== null) {
            queryParameters = queryParameters.set('FilterExpression', <any>filterExpression);
        }
        if (pageIndex !== undefined && pageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (sortExpressions) {
            queryParameters = queryParameters.set('SortExpressions', sortExpressions.join(COLLECTION_FORMATS['csv']));
        }
        if (companyId !== undefined && companyId !== null) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>(`${this.basePath}/api/OshaReportable/SearchNotSelectedLocations`,
            selectedIds,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Selected Locations by CompanyId
     * 
     * @param filterExpression Linq Expression Ex.: userName &#x3D;&#x3D; \\\&quot;Medcor\\\&quot; and  Age &gt; 0
     * @param pageIndex Linq Expression And Direction Ex. userName, userName Asc, userName Desc
     * @param pageSize 0 is not Allowed , Null or greater than 0
     * @param sortExpressions 0 is not allowed , Null or greated than 0
     * @param companyId 
     * @param selectedIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSelectedLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, companyId?: string, selectedIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>;
    public searchSelectedLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, companyId?: string, selectedIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchSelectedLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, companyId?: string, selectedIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchSelectedLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, companyId?: string, selectedIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterExpression !== undefined && filterExpression !== null) {
            queryParameters = queryParameters.set('FilterExpression', <any>filterExpression);
        }
        if (pageIndex !== undefined && pageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (sortExpressions) {
            queryParameters = queryParameters.set('SortExpressions', sortExpressions.join(COLLECTION_FORMATS['csv']));
        }
        if (companyId !== undefined && companyId !== null) {
            queryParameters = queryParameters.set('companyId', <any>companyId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>(`${this.basePath}/api/OshaReportable/SearchSelectedLocations`,
            selectedIds,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
