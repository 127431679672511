<div class='label-cont' *ngIf="label" >
    <label [attr.for]="identifier">{{label}}</label>
</div>
<div class="display-flex fill-parent">
  <div class='flex-cell input-cont flex-basis-0 flex-grow-1'>
    <textarea [(ngModel)]="value"
              [ngClass]="{invalid: (invalid)}"
              [id]="identifier"
              [disabled]="disabled"
              [placeholder]="placeholder"
              [rows]="rows"></textarea>

  </div>
  <div class="flex-cell">
    <validation-message *ngIf="invalid && model.control.touched" [messages]="failures"></validation-message>
  </div>

</div>
