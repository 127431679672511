/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';





import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {MedcorResponse,PaginatedListViewModel,DynamicInstanceViewModel,SelectionItemViewModel} from '../index';


@Injectable()
export class NextgenService {

    protected basePath = 'https://localhost/ClientPortalAPI';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Get Practice by ID from NextGen
     * 
     * @param practiceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPractice(practiceId?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<DynamicInstanceViewModel>>;
    public getPractice(practiceId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<DynamicInstanceViewModel>>>;
    public getPractice(practiceId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<DynamicInstanceViewModel>>>;
    public getPractice(practiceId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (practiceId !== undefined && practiceId !== null) {
            queryParameters = queryParameters.set('practiceId', <any>practiceId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<DynamicInstanceViewModel>>(`${this.basePath}/api/Nextgen/GetPractice`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Locations by PracticeIds as SelectionList
     * 
     * @param practiceIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserPracticeLocationsByPractices(practiceIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<SelectionItemViewModel>>>;
    public getUserPracticeLocationsByPractices(practiceIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<SelectionItemViewModel>>>>;
    public getUserPracticeLocationsByPractices(practiceIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<SelectionItemViewModel>>>>;
    public getUserPracticeLocationsByPractices(practiceIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<Array<SelectionItemViewModel>>>(`${this.basePath}/api/Nextgen/GetUserPracticeLocationsByPractices`,
            practiceIds,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search not selected Locations of some Practice
     * 
     * @param filterExpression Linq Expression Ex.: userName &#x3D;&#x3D; \\\&quot;Medcor\\\&quot; and  Age &gt; 0
     * @param pageIndex Linq Expression And Direction Ex. userName, userName Asc, userName Desc
     * @param pageSize 0 is not Allowed , Null or greater than 0
     * @param sortExpressions 0 is not allowed , Null or greated than 0
     * @param practiceId 
     * @param selectedIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchNotSelectedPracticeLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, practiceId?: string, selectedIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>;
    public searchNotSelectedPracticeLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, practiceId?: string, selectedIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchNotSelectedPracticeLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, practiceId?: string, selectedIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchNotSelectedPracticeLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, practiceId?: string, selectedIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterExpression !== undefined && filterExpression !== null) {
            queryParameters = queryParameters.set('FilterExpression', <any>filterExpression);
        }
        if (pageIndex !== undefined && pageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (sortExpressions) {
            queryParameters = queryParameters.set('SortExpressions', sortExpressions.join(COLLECTION_FORMATS['csv']));
        }
        if (practiceId !== undefined && practiceId !== null) {
            queryParameters = queryParameters.set('practiceId', <any>practiceId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>(`${this.basePath}/api/Nextgen/NotSelectedPracticeLocations`,
            selectedIds,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search All Practices in NextGen
     * 
     * @param filterExpression Linq Expression Ex.: userName &#x3D;&#x3D; \\\&quot;Medcor\\\&quot; and  Age &gt; 0
     * @param pageIndex Linq Expression And Direction Ex. userName, userName Asc, userName Desc
     * @param pageSize 0 is not Allowed , Null or greater than 0
     * @param sortExpressions 0 is not allowed , Null or greated than 0
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPractices(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>;
    public searchPractices(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchPractices(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchPractices(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterExpression !== undefined && filterExpression !== null) {
            queryParameters = queryParameters.set('FilterExpression', <any>filterExpression);
        }
        if (pageIndex !== undefined && pageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (sortExpressions) {
            queryParameters = queryParameters.set('SortExpressions', sortExpressions.join(COLLECTION_FORMATS['csv']));
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>(`${this.basePath}/api/Nextgen/SearchPractices`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search selected Locations of some Practice
     * 
     * @param filterExpression Linq Expression Ex.: userName &#x3D;&#x3D; \\\&quot;Medcor\\\&quot; and  Age &gt; 0
     * @param pageIndex Linq Expression And Direction Ex. userName, userName Asc, userName Desc
     * @param pageSize 0 is not Allowed , Null or greater than 0
     * @param sortExpressions 0 is not allowed , Null or greated than 0
     * @param practiceId 
     * @param selectedIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchSelectedPracticeLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, practiceId?: string, selectedIds?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>;
    public searchSelectedPracticeLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, practiceId?: string, selectedIds?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchSelectedPracticeLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, practiceId?: string, selectedIds?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>>;
    public searchSelectedPracticeLocations(filterExpression?: string, pageIndex?: number, pageSize?: number, sortExpressions?: Array<string>, practiceId?: string, selectedIds?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filterExpression !== undefined && filterExpression !== null) {
            queryParameters = queryParameters.set('FilterExpression', <any>filterExpression);
        }
        if (pageIndex !== undefined && pageIndex !== null) {
            queryParameters = queryParameters.set('PageIndex', <any>pageIndex);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (sortExpressions) {
            queryParameters = queryParameters.set('SortExpressions', sortExpressions.join(COLLECTION_FORMATS['csv']));
        }
        if (practiceId !== undefined && practiceId !== null) {
            queryParameters = queryParameters.set('practiceId', <any>practiceId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>>(`${this.basePath}/api/Nextgen/SelectedPracticeLocations`,
            selectedIds,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
