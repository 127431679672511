import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { DashboardItemService, DashboardItemViewModel } from '../../rest/index';
import { first } from 'rxjs/operators';

@Component({
  selector: 'dashboard-item-card-list',
  templateUrl: './dashboard-item-card-list.component.html',
  styleUrls: ['./dashboard-item-card-list.component.css']
})
export class DashboardItemCardListComponent implements OnInit {
  @Input() lineOfBusiness: string;
  rawDashboardItemList: DashboardItemViewModel[] = [];
  selectedItemsCount: number = 0;
  searchText: string = '';

  get filteredDashboardItemList(): DashboardItemViewModel[] {
    var lowerSearchText = this.searchText.toLowerCase();
    return this.rawDashboardItemList.filter((dashboardItem) => {
      if (lowerSearchText.length == 0 || dashboardItem.name.toLowerCase().indexOf(lowerSearchText) >= 0 ||
        dashboardItem.description && dashboardItem.description.toLowerCase().indexOf(lowerSearchText) >= 0) return true;
    });
  }

  constructor(
    private dashboardItemService: DashboardItemService,
  ) { }

  ngOnInit() {
  }

  clearFilter() {
    this.searchText = "";
  }

  ngOnChanges() {
    if (this.lineOfBusiness) {
      this.dashboardItemService.getDashboardItemsByUserLines(this.lineOfBusiness).pipe(first())
        .subscribe(result => {
          if (result && result.result) {
            this.rawDashboardItemList = result.result!;
            this.selectedItemsCount = this.rawDashboardItemList.filter(obj => obj.isSelected).length;
          }
        });
    }
  }

  dashboardItemCardSelected(item: DashboardItemViewModel) {
    this.rawDashboardItemList.forEach(item => item.isSelected = false);
    item.isSelected = true;

    console.log("item", item);
    this.dashboardItemService.updateAssociatedDashboardItem(item.id).pipe(first()).subscribe();
  }
}
