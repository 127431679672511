/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';













import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {MedcorResponse,PaginatedListViewModel,HierarchyInstancesSearchViewModel,ReportingParametersViewModel,ReportingRenderViewModel,SearchViewModel,AfkamHierarchyNode,ReportViewHistoryViewModel} from '../index';


@Injectable()
export class ReportingService {

    protected basePath = 'https://localhost/ClientPortalAPI';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Download History Report
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadHistoryReport(id: number, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadHistoryReport(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadHistoryReport(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadHistoryReport(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling downloadHistoryReport.');
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/Reporting/DownloadHistoryReport/${encodeURIComponent(String(id))}`,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export the report to the specified format
     * 
     * @param executionId 
     * @param fileName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportReport(executionId?: string, fileName?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public exportReport(executionId?: string, fileName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public exportReport(executionId?: string, fileName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public exportReport(executionId?: string, fileName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (executionId !== undefined && executionId !== null) {
            queryParameters = queryParameters.set('executionId', <any>executionId);
        }
        if (fileName !== undefined && fileName !== null) {
            queryParameters = queryParameters.set('fileName', <any>fileName);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/Reporting/ExportReport`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Triage Secured File by Ref. Number and Folder Name
     * 
     * @param refNumber 
     * @param folderName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmbeddedTriageSecuredFile(refNumber?: string, folderName?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public getEmbeddedTriageSecuredFile(refNumber?: string, folderName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public getEmbeddedTriageSecuredFile(refNumber?: string, folderName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public getEmbeddedTriageSecuredFile(refNumber?: string, folderName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refNumber !== undefined && refNumber !== null) {
            queryParameters = queryParameters.set('refNumber', <any>refNumber);
        }
        if (folderName !== undefined && folderName !== null) {
            queryParameters = queryParameters.set('folderName', <any>folderName);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/Reporting/GetEmbeddedTriageSecuredFile`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Render the report based on execution Id
     * 
     * @param reportingRenderViewModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loadReport(reportingRenderViewModel?: ReportingRenderViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<ReportingRenderViewModel>>;
    public loadReport(reportingRenderViewModel?: ReportingRenderViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<ReportingRenderViewModel>>>;
    public loadReport(reportingRenderViewModel?: ReportingRenderViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<ReportingRenderViewModel>>>;
    public loadReport(reportingRenderViewModel?: ReportingRenderViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<ReportingRenderViewModel>>(`${this.basePath}/api/Reporting/LoadReport`,
            reportingRenderViewModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Render the report and return it as downloadable file
     * 
     * @param reportingRenderViewModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loadReportAsDownloadableFile(reportingRenderViewModel?: ReportingRenderViewModel, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public loadReportAsDownloadableFile(reportingRenderViewModel?: ReportingRenderViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public loadReportAsDownloadableFile(reportingRenderViewModel?: ReportingRenderViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public loadReportAsDownloadableFile(reportingRenderViewModel?: ReportingRenderViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post(`${this.basePath}/api/Reporting/LoadReportAsDownloadableFile`,
            reportingRenderViewModel,
            {
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Render report page based on execution Id
     * 
     * @param reportingRenderViewModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loadReportPage(reportingRenderViewModel?: ReportingRenderViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<ReportingRenderViewModel>>;
    public loadReportPage(reportingRenderViewModel?: ReportingRenderViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<ReportingRenderViewModel>>>;
    public loadReportPage(reportingRenderViewModel?: ReportingRenderViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<ReportingRenderViewModel>>>;
    public loadReportPage(reportingRenderViewModel?: ReportingRenderViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<ReportingRenderViewModel>>(`${this.basePath}/api/Reporting/LoadReportPage`,
            reportingRenderViewModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * This handles the report viewing process, setting parameters
     * 
     * @param reportingParametersViewModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public loadReportParameters(reportingParametersViewModel?: ReportingParametersViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<ReportingParametersViewModel>>;
    public loadReportParameters(reportingParametersViewModel?: ReportingParametersViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<ReportingParametersViewModel>>>;
    public loadReportParameters(reportingParametersViewModel?: ReportingParametersViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<ReportingParametersViewModel>>>;
    public loadReportParameters(reportingParametersViewModel?: ReportingParametersViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<ReportingParametersViewModel>>(`${this.basePath}/api/Reporting/LoadReportParameters`,
            reportingParametersViewModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the report images (Charts, logos ...)
     * 
     * @param executionId 
     * @param imageId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportImage(executionId: string, imageId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public reportImage(executionId: string, imageId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public reportImage(executionId: string, imageId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public reportImage(executionId: string, imageId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (executionId === null || executionId === undefined) {
            throw new Error('Required parameter executionId was null or undefined when calling reportImage.');
        }

        if (imageId === null || imageId === undefined) {
            throw new Error('Required parameter imageId was null or undefined when calling reportImage.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/Reporting/ReportImage/${encodeURIComponent(String(executionId))}/${encodeURIComponent(String(imageId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns reporting server status related to the selected report
     * 
     * @param lineOfBussiness 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reportingStatus(lineOfBussiness: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public reportingStatus(lineOfBussiness: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public reportingStatus(lineOfBussiness: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public reportingStatus(lineOfBussiness: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (lineOfBussiness === null || lineOfBussiness === undefined) {
            throw new Error('Required parameter lineOfBussiness was null or undefined when calling reportingStatus.');
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/Reporting/ReportingStatus/${encodeURIComponent(String(lineOfBussiness))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Return the report general resouorces (images)
     * 
     * @param resId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resource(resId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public resource(resId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public resource(resId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public resource(resId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (resId === null || resId === undefined) {
            throw new Error('Required parameter resId was null or undefined when calling resource.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<any>(`${this.basePath}/api/Reporting/Resource/${encodeURIComponent(String(resId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export the report to the specified format
     * 
     * @param executionId 
     * @param filename 
     * @param clientReportName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveToSecuredInbox(executionId?: string, filename?: string, clientReportName?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<boolean>>;
    public saveToSecuredInbox(executionId?: string, filename?: string, clientReportName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<boolean>>>;
    public saveToSecuredInbox(executionId?: string, filename?: string, clientReportName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<boolean>>>;
    public saveToSecuredInbox(executionId?: string, filename?: string, clientReportName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (executionId !== undefined && executionId !== null) {
            queryParameters = queryParameters.set('executionId', <any>executionId);
        }
        if (filename !== undefined && filename !== null) {
            queryParameters = queryParameters.set('filename', <any>filename);
        }
        if (clientReportName !== undefined && clientReportName !== null) {
            queryParameters = queryParameters.set('clientReportName', <any>clientReportName);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<MedcorResponse<boolean>>(`${this.basePath}/api/Reporting/SaveToSecuredInbox`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Hierarchy Instances
     * 
     * @param objPost 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchHierarchyInstances(objPost?: HierarchyInstancesSearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<AfkamHierarchyNode>>>;
    public searchHierarchyInstances(objPost?: HierarchyInstancesSearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<AfkamHierarchyNode>>>>;
    public searchHierarchyInstances(objPost?: HierarchyInstancesSearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<AfkamHierarchyNode>>>>;
    public searchHierarchyInstances(objPost?: HierarchyInstancesSearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<AfkamHierarchyNode>>>(`${this.basePath}/api/Reporting/SearchHierarchyInstances`,
            objPost,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * search report&#39;s view history
     * 
     * @param reportItemId 
     * @param objSearch 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchReportHistory(reportItemId?: number, objSearch?: SearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<ReportViewHistoryViewModel>>>;
    public searchReportHistory(reportItemId?: number, objSearch?: SearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<ReportViewHistoryViewModel>>>>;
    public searchReportHistory(reportItemId?: number, objSearch?: SearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<ReportViewHistoryViewModel>>>>;
    public searchReportHistory(reportItemId?: number, objSearch?: SearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reportItemId !== undefined && reportItemId !== null) {
            queryParameters = queryParameters.set('reportItemId', <any>reportItemId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<ReportViewHistoryViewModel>>>(`${this.basePath}/api/Reporting/SearchReportHistory`,
            objSearch,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
