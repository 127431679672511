import { Component, OnInit, Inject } from '@angular/core';
import { VoidInfoViewModel } from '../../../rest/model/voidInfoViewModel';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'void-dialog',
  templateUrl: './void-dialog.component.html',
  styleUrls: ['./void-dialog.component.css']
})
    //Void component to be used with Mat-Dialog service to get void info (reason and comment)
export class VoidDialogComponent implements OnInit {

    voidReason: string;
    comments: string;

    constructor(public dialogRef: MatDialogRef<VoidDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

  getVoidObject(form:NgForm): void {
      if (form.valid) {
          this.dialogRef.close({ comment: this.comments, reason: this.voidReason });
      } else {
          form.controls["voidReason"].markAsTouched();
      }
  }

}
