<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
  {{data.message}}
  <form #frm="ngForm" class="flex-basis-0 flex-grow-1 margin-bottom-def">
    <div class="display-flex break-la">
      <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
        <ichs-select [(ngModel)]="voidReason" name="voidReason" placeholder="Select Reason" listId="108000000000003" label="Void Reason" required></ichs-select>
      </div>

    </div>
    <div class="display-flex break-la">

      <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
        <ichs-text-area [(ngModel)]="comments" name="voidComments" label="Void Comments" placeholder="Comments" rows="5"></ichs-text-area>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-basis-0 flex-grow-1 text-right">
    <button mat-button (click)="getVoidObject(frm)" cdkFocusInitial class="cp-btn cp-main-btn">Void</button>
    <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">Cancel</button>
  </div>

</mat-dialog-actions>
