import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { ControlBase } from '../control-base';
import { NG_VALIDATORS, NgModel, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
    selector: 'ichs-datetime-picker',
    templateUrl: './ichs-datetime-picker.component.html',
    styleUrls: ['./ichs-datetime-picker.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: IchsDateTimePickerComponent,
            multi: true,
        },
    ],
})
//a DatePicker contrl.
export class IchsDateTimePickerComponent extends ControlBase<Date> implements OnInit {

    @Input('pickerType') pickerType: string = "both";  // Set the type of the dateTime picker. both: show both calendar and timer, calendar: only show calendar, timer: only show timer.
    @Input('pickerMode') pickerMode: string = "popup";  // The style the picker would open as. (popup or dialog)
    @Input('label') label: string;
    @Input("disabled") isDisabled: boolean = false;
    @Output() dateChanged: EventEmitter<Date> = new EventEmitter<Date>();

    @ViewChild(NgModel, {static: true }) model: NgModel;

    public identifier = `ichs-datetime-picker-${identifier++}`;    // this attribute to give a unique identifier for the textboxs.
    //to holds the Start Date and End date as dates
    public minDate: Date;
    public maxDate: Date;
    datePlaceHolder: string;

    ngOnInit() {
        super.ngOnInit();
        if (this.pickerType == "calendar") {
            this.datePlaceHolder = "mm/dd/yyyy";
        }
        else if (this.pickerType == "timer") {
            this.datePlaceHolder = "h:mm tt";
        }
        else {
            this.datePlaceHolder = "mm/dd/yyyy, h:mm tt";
        }
    }

    // raise an event to the outer component.
    dateChangedEvent(event: any) {
        if (this.model.control.status == "VALID") {
            let datetime = event.value as Date;
            datetime.setSeconds(0);
            datetime.setMilliseconds(0);
            this.dateChanged.emit(datetime);
        }
        else {
            this.value = new Date(""); // if the value is not valid date, clear it.
        }
    }

    keydownEventHandler(event: any) {
        //prevent of typing a text.
        var key = event.keyCode;

        if (key >= 65 && key <= 90) {
            event.preventDefault();
        }
    }
}

let identifier = 0;
