import { Component, OnInit } from '@angular/core';
import { IchsGridComponentConfig, IchsGridActionsConfig, IchsControlType } from '../../controls/ichs-grid/grid-configs';
import { UserApplicationAccessViewModel } from '../../rest/model/userApplicationAccessViewModel';
import { SearchViewModel, UserApplicationAccessService } from '../../rest';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IchsComponentDialogComponent } from '../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { UserApplicationAccessComponent } from '../user-application-access/user-application-access.component';
import { Location } from '@angular/common';

@Component({
  selector: 'applications-access',
  templateUrl: './applications-access.component.html',
  styleUrls: ['./applications-access.component.css']
})
export class ApplicationsAccessComponent implements OnInit {
  applicationId: number;
  gridConfig: IchsGridComponentConfig<UserApplicationAccessViewModel>;

  constructor(
    private userApplicationAccessService: UserApplicationAccessService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private location: Location,
  ) { }

  ngOnInit() {
    this.applicationId = +this.route.snapshot.paramMap.get('applicationId');
    this.gridConfig = this.defineGrid();
  }

  /**
  * Configure Grid
  */
  private defineGrid(): IchsGridComponentConfig<UserApplicationAccessViewModel> {
    let configs: IchsGridComponentConfig<UserApplicationAccessViewModel> = {
      primaryId: "id",
      defaultOrder: "email",
      title: "Users with Access to this Application",
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: true,
      hasVoid: false,
      hasActionColumn: true,
      entityController: null,
      entityDataSource: (filter: SearchViewModel) => this.userApplicationAccessService.searchApplicationAccessByApplicationId(this.applicationId, filter),
      customNewAction: new IchsGridActionsConfig({
        title: "Back To List",
        function: () => {
          this.location.back();
        },
      }),
      customNewAction2: new IchsGridActionsConfig({
        title: "Add Existing",
        function: () => {
          let dialogRef: MatDialogRef<IchsComponentDialogComponent> = this.dialog.open(IchsComponentDialogComponent, {
            data: {
              component: UserApplicationAccessComponent,
              title: "Add Existing User",
              hideCloseButton: true,
              extraData: <UserApplicationAccessViewModel>{
                applicationId: this.applicationId,
              },
            },
            width: "1000px",
          });
          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.gridConfig.refreshGrid!(0);
            }
          });
        },
      }),
      detailAction: new IchsGridActionsConfig({
        title: "Modify Access",
        function: (obj: UserApplicationAccessViewModel) => {
          let dialogRef: MatDialogRef<IchsComponentDialogComponent> = this.dialog.open(IchsComponentDialogComponent, {
            data: {
              title: "Modify Access",
              component: UserApplicationAccessComponent,
              hideCloseButton: true,
              extraData: obj,
            },
            width: "500px",
          });
          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.gridConfig.refreshGrid!(0);
            }
          });
        },
      }),
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: UserApplicationAccessViewModel) => {
          if (!deletedObj.id) {
            return;
          }

          this.userApplicationAccessService.deleteUserApplicationAccess(deletedObj.id, deletedObj.version).pipe(first()).subscribe(result => {
            if (result) {
              this.gridConfig.refreshGrid!(this.gridConfig.getPage!());
            }
          });
        }
      },
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
          width: 200
        },
        {
          headerTitle: "Expiration Date",
          propertyName: "expirationDate",
          convertUtcDateToLocal: false,
          sortable: true,
          searchable: true,
          nullable: true,
          controlType: IchsControlType.Date,
        }
      ],
    }
    return configs;
  }
}
