<ng-scrollbar appearance="compact" style="height:calc(100vh - 60px);">

<div class="container-padding">
  <div class="featured-cont padding-xl">
    <h2 class="care-inst-title">Care Instructions</h2>
    <care-instruction-form></care-instruction-form>
  </div>
  <div class="main-page-margin">
    <div class="main-page-margin">
      <div class="display-flex items-cont">
        <ichs-home-tile description="24/7 nationwide telemedicine gives injured workers convenient access to physicians without going offsite for care."
                        label="About Medcor"
                        titleLink="https://www.youtube.com/embed/-RmWaGLMXI4"
                        imgSrc="assets/images/preLogin/Medcor_ClientPortal_H2.jpg"
                        imgLink="https://www.youtube.com/embed/-RmWaGLMXI4"></ichs-home-tile>

        <ichs-home-tile description="Follow us on Linkedin"
                        label="Connect with Medcor"
                        titleLink="https://www.linkedin.com/company/medcor/"
                        imgSrc="assets/images/preLogin/Medcor_ClientPortal_H5.jpg"
                        imgLink="https://www.linkedin.com/company/medcor/"
                        tabMode="true"></ichs-home-tile>

      </div>
    </div>

  </div>
</div>
</ng-scrollbar>
