<div class="display-flex flex-basis-0 flex-grow-1 flex-col">
  <mat-tab-group mat-align-tabs="start"  mat-stretch-tabs="false">
    <mat-tab label="Dashboard Item Detail">
      <div class="cont-padding">
        <div class="cont-padding border-bottom">
          <ichs-command-toolbar [model]="model" class="display-flex flex-col"></ichs-command-toolbar>
        </div>
        <mat-horizontal-stepper [linear]="true" #stepper="matHorizontalStepper">
          <ng-template matStepperIcon="edit">
            <img src="assets/images/pencil-edit-button.svg" class="custom-mat-icon" />
          </ng-template>
          <mat-step label="Choose Dashboard" [stepControl]="formGroupReport">
            <ng-scrollbar appearance="compact" class="links-cont" style="height:calc(100vh - 128px);">
              <div class="flex-grow-1  secured-files">
                <div class="secured-files-tools display-flex flex-items-centered">
                  <div><a (click)="routeToFolder(null)" style="cursor:pointer">Root</a>&nbsp;</div>
                  <div class="flex-grow-1 flex-basis-0">
                    <ng-container *ngFor="let item of pathFolders">
                      / <a style="cursor:pointer">{{item}}</a>
                    </ng-container>
                  </div>
                  <div class="validation" *ngIf="formGroupReport.invalid && isformGroupReportChecked">
                    <div title="Please Select a File">
                      <img src="assets/images/information.svg" class="validation-icon" />
                    </div>
                  </div>
                </div>
                <hr class="main-hr" />
                <div class="stepper-scrolling" id="mainFolder">
                  <div class="user-file" *ngFor="let item of getFiles()"
                    [ngClass]="{'selected-file': selectedReport && selectedReport.id == item.id }" [title]="item.name"
                    (click)="selectFile(item)">
                    <div class="file-visuals">
                      <img src="assets/images/file.svg" class="file-icon" />
                      <span style="display:block; cursor:default;">{{item.name}}</span>
                    </div>
                  </div>
                  <div class="user-folder" (dblclick)="routeToFolder(item)" [title]="item.name" *ngFor="let item of getFolders()">
                    <div class="folder-visuals">
                      <img src="assets/images/folder.svg" class="folder-icon" />
                      <span style="display:block">{{item.name}}</span>
                      <div class="file-actions flex-items-centered">
                        <div class="flex-basis-0 flex-grow-1 text-center">
                          <img class="control-image" src="assets/images/open.svg" (click)="routeToFolder(item)"
                            title="Open Folder" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form #formGroupReport="ngForm" novalidate class="flex-basis-0 flex-grow-1">
                <ichs-text-box type="text" name="selectedReportPath" [ngModel]="selectedReportPath" required style="display:none">
                </ichs-text-box>
              </form>
              <div>
                <button type="button" class="cp-btn cp-main-btn" matStepperNext
                  (click)="model.validateAllFormFields(formGroupReport.form)">Next</button>
              </div>
            </ng-scrollbar>
          </mat-step>
          <mat-step label="Upload Photos" [stepControl]="formGroupImages">
            <div class="stepper-scrolling">
              <div class="display-flex">
                <div class="flex-basis-0 flex-grow-1"></div>
                <div class="validation" *ngIf="formGroupImages.invalid && isformGroupImagesChecked">
                  <div title="Please Select Images">
                    <img src="assets/images/information.svg" class="validation-icon" />
                  </div>
                </div>
              </div>

              <div class="display-flex break-la">
                <div class="flex-col-sep"></div>
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                  <div class='flex-cell label-cont'><label>Image</label></div>
                  <div class="upload-img" (click)="ImageInput.click()">
                    <img width="50" *ngIf="image == null || image == ''" src="assets/images/user.svg" title="Image" (click)="ImageInput.click()" />
                    <img width="50" *ngIf="image != null && image != ''" [src]="image" title="Image" (click)="ImageInput.click()" />
                    <input (change)="imageSelected($event)" type="file" #ImageInput style="display:none" accept="image/x-png,image/gif,image/jpeg" />
                    <div class="position-relative">
                      <div class="change-img position-absolute">
                        <span>Change Image</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-col-sep"></div>
              </div>
            </div>


            <form #formGroupImages="ngForm" novalidate class="flex-basis-0 flex-grow-1">
              <ichs-text-box type="text" name="ImagePath" [ngModel]="image" required style="display:none"></ichs-text-box>
            </form>

            <div>
              <button class="cp-btn cp-mail-btn" type="button" matStepperPrevious>Back</button>
              <button class="cp-btn cp-main-btn" type="button" matStepperNext (click)="model.validateAllFormFields(formGroupImages.form)">Next</button>
            </div>
          </mat-step>
          <mat-step label="Complete Details" [stepControl]="formGroupDetails">
            <form #formGroupDetails="ngForm" novalidate class="flex-basis-0 flex-grow-1">
              <div class="stepper-scrolling">
                <div class="display-flex break-la">
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                    <ichs-text-box type="text" name="name" [(ngModel)]="model.objModel.name" required label="Name"></ichs-text-box>
                  </div>
                  <div class="flex-col-sep"></div>

                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                    <ichs-check-box name="internalInd" [(ngModel)]="model.objModel.internalInd" label="Internal Item"></ichs-check-box>
                    <div class="flex-col-sep"></div>
                    <ichs-check-box name="maintenance" [ngModel]="!model.objModel.hasExport" (ngModelChange)="model.objModel.hasExport = !$event" label="Maintenance"></ichs-check-box>
                  </div>
                </div>
                <div class="display-flex break-la">
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                    <ichs-select type="text" name="tag" [(ngModel)]="model.objModel.tag" label="Tag"
                                 [items]="tagItems"></ichs-select>
                  </div>
                  <div class="flex-col-sep"></div>
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                    <ichs-text-box type="text" name="tagTitle" [(ngModel)]="model.objModel.tagTitle" label="Tag Title" [required]="model.objModel.tag != null" maxlength="250"></ichs-text-box>
                  </div>
                </div>
                <div class="display-flex break-la">
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                    <ichs-text-box type="text" name="description" [(ngModel)]="model.objModel.description" label="Description"></ichs-text-box>
                  </div>
                  <div class="flex-col-sep"></div>
                  <div class="flex-child  flex-basis-0 flex-grow-1 flex-end">
                    <ichs-check-box name="hasPhi" [(ngModel)]="model.objModel.hasPhiData" label="PHI"></ichs-check-box>
                  </div>
                </div>
              </div>
            </form>
            <div>
              <button class="cp-btn cp-mail-btn" type="button" matStepperPrevious>Back</button>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </mat-tab>
    <mat-tab label="Dashboard Item Groups" [disabled]="model.objModelId == -1 ? true : false">
      <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab">
        <ichs-grid [grid-config]="gridConfigGroup" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
