<div class="display-flex break-la">
  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
    <ichs-text-box [(ngModel)]="password" name="password" label="Generated Password" hasButton="true" (buttonClicked)="generate(12,true,true,true)"
      buttonIcon="assets/images/refresh.svg" iconTitle="Refresh">
    </ichs-text-box>

    <div class="flex-cell" style="margin-top: 24px; margin-left: 2px;">
      <img title="Copy to Clipboard" class="field-icon" src="assets/images/copy-content.svg" (click)="copyPassword()" />
    </div>
  </div>
</div>