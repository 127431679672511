/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AppUserExtensionViewModel { 
    website?: string;
    fax?: string;
    cellphone?: string;
    title?: string;
    office?: string;
    businessCardTemplate?: string;
    userImageId?: number;
    logoId?: number;
    clientName?: string;
    timeZone?: string;
    fiscalYearStartingMonth?: number;
    fiscalYearStartingDate?: number;
    allowAdd?: boolean;
    allowReset?: boolean;
    allowMaintainTriageUsers?: boolean;
    allowMaintainCall?: boolean;
    allowScheduleReports?: boolean;
    allowNoGroupUsersImpersonation?: boolean;
    acceptedUserAgreement?: boolean;
    phiDataRestrictionInd?: boolean;
    appUsersReadOnlyRestriction?: boolean;
    pageDesignAccess?: boolean;
    showDataAccess?: boolean;
    financeDataAccess?: boolean;
    hrDataAccess?: boolean;
    userLookupAccess?: boolean;
    manageUsersAccess?: boolean;
    sharedInboxEmail?: boolean;
    id?: number;
    voidId?: number;
    version?: string;
}
