<div class="display-flex" style="justify-content: space-between">
  <h2 *ngIf="inputDialogConfig.hasTitle" mat-dialog-title>{{inputDialogConfig.title}}</h2>
  <div *ngIf="inputDialogConfig.hasXButton">
    <button mat-dialog-close class="cp-btn cp-close-btn">
      <img src="assets/images/close.svg" class="cp-dialog-close" />
    </button>
  </div>
</div>

<mat-dialog-content>
  <form #form="ngForm" style="padding:10px;" *ngIf="controls" (keyup.enter)="submit()">
    {{inputDialogConfig.message}}
    <ng-container *ngIf="controls.length > 0">
      <ng-container *ngFor="let control of controls; index as i">

        <div [ngSwitch]="control.type" class="flex-child display-flex  flex-grow-1 break-sm flex-items-centered">

          <ichs-date-picker *ngSwitchCase="datePickerType"
                            [name]="'datePicker-' + i"
                            [label]="control.label"
                            [(ngModel)]="control.value"
                            [required]="control.required"
                            [disabled]="control.disabled"
                            [placeholder]="control.datePlaceholder">

          </ichs-date-picker>

          <ichs-text-box *ngSwitchCase="textType"
                         [name]="'textBox-' + i"
                         [label]="control.label"
                         [(ngModel)]="control.value"
                         [required]="control.required"
                         [disabled]="control.disabled"
                         [placeholder]="control.placeholder ? control.placeholder : ''"
                         [buttonIcon]="control.buttonIcon"
                         [hasButton]="control.showButton"
                         [inputMask]="control.inputMask"
                         [email]="control.isEmail"
                         [maxlength]="control.maxLength">

          </ichs-text-box>

          <ichs-select *ngSwitchCase="selectType"
                       [label]="control.label"
                       [name]="'select-' + i"
                       [(ngModel)]="control.value"
                       [required]="control.required"
                       [disabled]="control.disabled"
                       [placeholder]="control.placeholder"
                       [resetLabel]="control.resetLabel"
                       [hideResetLabel]="control.hideResetLabel"
                       [multiple]="control.multiple"
                       [groupItems]="control.groupItems"
                       [items]="control.items"
                       [listId]="control.listId"
                       [useGroups]="control.useGroups">

          </ichs-select>

          <ichs-text-area *ngSwitchCase="textArea"
                          [label]="control.label"
                          [name]="'textArea-' + i"
                          [disabled]="control.disabled"
                          [rows]="control.rows"
                          [placeholder]="control.placeholder"
                          [(ngModel)]="control.value"
                          [maxlength]="control.maxLength">

          </ichs-text-area>

          <div *ngSwitchCase="imageType" class="display-flex flex-grow-1">
            <div class="flex-basis-0 flex-grow-1"></div>
            <img [src]="control.src" />
            <div class="flex-basis-0 flex-grow-1"></div>
          </div>

        </div>
      </ng-container>
    </ng-container>
  </form>
</mat-dialog-content>
<mat-dialog-actions *ngIf="inputDialogConfig.hasActions">
  <div class="flex-basis-0 flex-grow-1 text-right">
    <button mat-button class="cp-btn cp-main-btn" (click)="submit()" cdkFocusInitial>{{inputDialogConfig.okText}}</button>
    <button mat-button class="cp-btn cp-mail-btn" mat-dialog-close>{{inputDialogConfig.cancelText}}</button>
  </div>
</mat-dialog-actions>
