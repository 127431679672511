import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { MedcorResponse } from '../../app/rest/index';

// Alert type
export enum AlertMessageType {
  success = 1,
  error = 2,
  warning = 3,
  info = 4
}
// Dismiss type
export enum AlertDismissType {
  auto = "auto",
  click = "click",
  controlled = "controlled"
}
// Position type
export enum AlertPositionClassType {
  topRight = "toast-top-right",
  topCenter = "toast-top-center",
  topLeft = "toast-top-left",
  topFullWidth = "toast-top-full-width",
  bottomRight = "toast-bottom-right",
  bottomCenter = "toast-bottom-center",
  bottomLeft = "toast-bottom-left",
  bottomFullWidth = "toast-bottom-full-width",
}
// wrapper for Notified alerts
export interface MedcorAlertServiceMessage {
  type: AlertMessageType;
  messages: Array<string>;
  title?: string;
  dismiss?: AlertDismissType;
  positionClass?: AlertPositionClassType;
}

@Injectable()
export class MedcorAlertService {
  // Alert Observer
  private alert = new Subject<MedcorAlertServiceMessage>();
  // start observing added alerts 
  get getAlert(): Observable<MedcorAlertServiceMessage> {
    return this.alert.asObservable();
  }
  // notify new alert
  addAlert(alert: MedcorAlertServiceMessage) {
    this.alert.next(alert);
  }

  // take medcor response and decide what to do
  handleMedcorResponse(response: MedcorResponse<any>) {
    if (response && response.notify != undefined && response.notify && response.messages != undefined && response.messages.length > 0) {
      switch (response.statusCode) {
        case 200:
          this.addAlert({
            type: AlertMessageType.success,
            title: response.hideTitle ? undefined : 'Success!',
            messages: response.messages
          });
          break;
        case 400:
          this.addAlert({
            type: AlertMessageType.warning,
            title: response.hideTitle ? undefined : 'Warning!',
            messages: response.messages
          });
          break;
        case 403:
          this.addAlert({
            type: AlertMessageType.error,
            title: response.hideTitle ? undefined : 'Authorization!',
            messages: response.messages
          });
          break;
        case 500:
          this.addAlert({
            type: AlertMessageType.error,
            title: response.hideTitle ? undefined : 'An Error Occurred!',
            dismiss: AlertDismissType.controlled,
            messages: response.messages
          });
          break;
        default:
      }
    }
  }
}
