import { Location } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Component, OnInit, ViewChild, isDevMode } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { IchsCommandToolbarDelegate, IchsCommandToolbarModel } from '../../controls/ichs-command-toolbar/ichs-command-toolbar.component';
import { IchsComponentDialogComponent } from '../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { AppUserService, AppUserViewModel, GroupService, LoggedUserViewModel, SelectionItemViewModel, VoidInfoViewModel, AppLogSearchModel, SearchViewModel, UserContactService, ContactGridViewModel, SecureFileService, UserApplicationAccessViewModel, UserApplicationAccessService, SiteCompanyInstViewModel, AppUserExtensionViewModel, UserSearchViewModel } from '../../rest/index';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from '../../services/medcor-alert.service';
import { MedcorUserInfoService } from '../../services/medcor-user-info.service';
import { LineOfBusinessTreeComponent, NodeType } from '../line-of-business-tree/line-of-business-tree.component';
import { IchsDialogComponent, IchsDialogConfig } from '../../controls/ichs-dialog/ichs-dialog.component';
import { AppGenericLists } from 'src/app/app.general.lists';
import { ValidationMessages, AppSettings, LineOfBusiness } from '../../app.general.constants';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { IchsGridComponentConfig, IchsControlType, IchsGridActionsConfig } from '../../controls/ichs-grid/grid-configs';
import { AppLogDialogComponent } from '../../app-log/app-log-dialog/app-log-dialog.component';
import { SharedFunctionsService } from '../../services/shared-functions.service';
import { UserApplicationAccessComponent } from '../../applications-access/user-application-access/user-application-access.component';
import { IchsGridDialogComponent } from 'src/app/controls/ichs-grid-dialog/ichs-grid-dialog.component';
import { GroupViewModel } from '../../rest/model/groupViewModel';
import { GroupAppUserAssociationService } from '../../rest/api/groupAppUserAssociation.service';
import { MedcorResponse } from 'src/app/rest/model/medcorResponse';
import { PracticeLocationViewModel } from 'src/app/rest/model/practiceLocationViewModel';
import { ScreeningInstanceCollectionViewModel } from 'src/app/rest/model/screeningInstanceCollectionViewModel';
import { OshaCompanyLocationViewModel } from 'src/app/rest/model/oshaCompanyLocationViewModel';
import { AfkamIntanceViewModel } from 'src/app/rest/model/afkamIntanceViewModel';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { UserGroupsDataAccessGrid } from '../user-lookup-details/user-lookup-details.component';

@Component({
  selector: 'app-appuser',
  templateUrl: './appuser.component.html',
  styleUrls: ['./appuser.component.scss']
})

/** Appuser component*/
export class AppuserComponent implements OnInit, IchsCommandToolbarDelegate<AppUserViewModel> {
  confirmPassword: string = "";
  @ViewChild('form', { static: true }) ngForm: NgForm;
  @ViewChild(LineOfBusinessTreeComponent) lineOfBusinessTreeComponent: LineOfBusinessTreeComponent;
  @ViewChild('innerMatTabGroup') innerMatTabGroup: MatTabGroup;
  //@ViewChild(RestrictedAccessTreeComponent, {static: false }) dataAccessComponent: RestrictedAccessTreeComponent;
  model: IchsCommandToolbarModel<AppUserViewModel>;
  get appuserId(): number { return this.model.objModelId }
  get loggedInUserObs(): Observable<LoggedUserViewModel> { return this.medcorUserInfoService.loggedInUserObs }
  public roleItems: SelectionItemViewModel[];
  public groupItems: SelectionItemViewModel[];
  userImage: string = "assets/images/user.svg";
  logoImage: string = "assets/images/logo.svg";
  logoImgFile: File | null;
  userImgFile: Blob | null;
  public statusList: SelectionItemViewModel[] = AppGenericLists.UserStatus;
  showAccessTab: boolean = false;
  showMembershipTab: boolean = false;
  showManageUsersTab: boolean = false;

  userContactsGridConfig: IchsGridComponentConfig<ContactGridViewModel>;
  appLogGridConfig: IchsGridComponentConfig<AppLogSearchModel>;
  applicationsAccessGridConfig: IchsGridComponentConfig<UserApplicationAccessViewModel>;
  membershipsGridConfig: IchsGridComponentConfig<UserSearchViewModel>;
  manageUsersGridConfig: IchsGridComponentConfig<UserSearchViewModel>;

  logsType: string = "All";

  appUsersReadOnlyRestriction: boolean;
  userGroupsChanged: boolean = false;

  userGroupsDataAccessGrids: UserGroupsDataAccessGrid[] = [];
  userDataAccessTabLabel: string = "User Data Access (From Groups)";
  innerMatTabGroupUpdated: boolean = false;
  allowAddAppUserInd: boolean;

  private dataAccessUpdates: boolean = false;
  private originalLineOfBusinessListJson: string;

  readonly logsTypeSelectionListItems: SelectionItemViewModel[] =
    [
      { selectionText: "All", selectionValue: "All" },
      { selectionText: "Exceptions Only", selectionValue: "Exceptions Only" },
      { selectionText: "Non-Exceptions Only", selectionValue: "Non-Exceptions Only" },
    ];


  /** Appuser constructor*/
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appUserService: AppUserService,
    private location: Location,
    private alertService: MedcorAlertService,
    private dialog: MatDialog,
    private secureFileService: SecureFileService,
    private medcorUserInfoService: MedcorUserInfoService,
    private groupService: GroupService,
    private userContactService: UserContactService,
    private sharedFunctions: SharedFunctionsService,
    private userApplicationAccessService: UserApplicationAccessService,
    private groupAppUserAssociationService: GroupAppUserAssociationService,
  ) {
    this.model = new IchsCommandToolbarModel<AppUserViewModel>(location, router, "/appuser", "App User", this, dialog, alertService);

    this.loggedInUserObs.pipe(first()).subscribe(value => {
      this.appUsersReadOnlyRestriction = value.appUsersReadOnlyRestriction;
      this.allowAddAppUserInd = value.allowAddAppUserInd;
      if (this.appUsersReadOnlyRestriction) {
        this.model.hasSave = false;
        this.model.hasVoidRecover = false;
      }

      this.appUserService.getRolesAsSelectionList().pipe(first()).subscribe(response => {
        this.roleItems = response.result!;
      });
      this.groupService.getGroupsAsSelectionList().pipe(first()).subscribe(response => {
        this.groupItems = response.result!;
      });
    });
  }

  /** Appuser OnInit*/
  ngOnInit() {
    this.model.init(this.route);
    this.userContactsGridConfig = this.defineUserContactsGridConfig();
    this.appLogGridConfig = this.defineAppLogGridConfig();
    this.applicationsAccessGridConfig = this.defineApplicationsAccessGrid();

    this.membershipsGridConfig = this.defineMembershipsGrid();
    this.manageUsersGridConfig = this.defineManageUsersGrid();

    this.appUserService.getRolesAsSelectionList().pipe(first()).subscribe(response => {
      this.membershipsGridConfig.headers[4].listData = response.result!;
      this.manageUsersGridConfig.headers[4].listData = response.result!;
    });
  }

  getLoadObjModelObservable(id: number) {
    return this.appUserService.getWithLineOfBusiness(id);
  }

  beforeSave(objModel: AppUserViewModel) {
    this.dataAccessUpdates = this.userGroupsChanged || this.originalLineOfBusinessListJson !== JSON.stringify(this.model.objModel.lineOfBusinessList);
  }

  getSaveObservable(objModel: AppUserViewModel): Observable<MedcorResponse<AppUserViewModel>> {
    return this.appUserService.post(objModel)
  }

  getVoidObservable(objVoidModel: VoidInfoViewModel): Observable<MedcorResponse<AppUserViewModel>> {
    return this.appUserService._void(objVoidModel)
  }

  getRecoverObservable(id: number): Observable<MedcorResponse<AppUserViewModel>> {
    return this.appUserService.recover(id);
  }

  getEmptyObject(): AppUserViewModel {
    var emptyObj: AppUserViewModel = {
      email: "",
      firstName: "",
      lastName: "",
      roleName: "",
      active: true,
      status: "Active",
      activeDirectoryUserInd: false,
      appUserExtension: <AppUserExtensionViewModel>{
        businessCardTemplate: "Temp01",
        fiscalYearStartingMonth: 1,
        fiscalYearStartingDate: 1,
      },
      mfaStatus: {},
    };

    this.userImage = "assets/images/user.svg";
    this.logoImage = "assets/images/logo.svg";
    return emptyObj;
  }

  //before save function
  canSave(objModel: AppUserViewModel): boolean {
    if (this.model.objModelId == -1)
      return true;

    //if (!this.dataAccessComponent.ngForm.valid) {
    //  this.alertService.addAlert({
    //    type: AlertMessageType.warning,
    //    title: 'Warning!',
    //    dismiss: AlertDismissType.controlled,
    //    messages: ["Invalid Data Access Rules!"]
    //  });
    //}

    //this.model.objModel.lineOfBusinessDataAccess = this.dataAccessComponent.getSelectedItems();

    if (!objModel.appUserExtension.showDataAccess || (objModel.lineOfBusinessList!.length == 1 && objModel.lineOfBusinessList![0].subNodes!.length == 0))
      return true;

    var validComponent: boolean = this.sharedFunctions.validateLineOfBusinessFields(this.lineOfBusinessTreeComponent);
    if (!validComponent) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
    }

    return validComponent;
  }

  //after save function
  didSave(objModel: AppUserViewModel) {
    this.confirmPassword = "";
    if (this.userImgFile) {
      this.appUserService.uploadUserImage(ImageType.UserImage, objModel.id!, objModel.version, 0, [this.userImgFile], 'events', true).subscribe(event => {
        if (event.type == HttpEventType.Response) {
          var objResult = event.body!.result;
          this.model.objModel.appUserExtension!.userImageId = objResult!.imageFileId!;
          this.model.objModel.version = objResult!.version!;
          this.userImgFile = null;
        }
      });
    }

    if (this.logoImgFile) {
      this.appUserService.uploadUserImage(ImageType.Logo, objModel.id!, objModel.version, 0, [this.logoImgFile], 'events', true).subscribe(event => {
        if (event.type == HttpEventType.Response) {
          var objResult = event.body!.result;
          this.model.objModel.appUserExtension!.logoId = objResult!.imageFileId!;
          this.model.objModel.version = objResult!.version!;
          this.logoImgFile = null;
        }
      });
    }

    this.ngForm.form.markAsPristine();
    if (this.model.objModel.appUserExtension.showDataAccess) {
      this.lineOfBusinessTreeComponent.ngForm.form.markAsPristine();
    }

    if (this.dataAccessUpdates) {
      this.sharedFunctions.showDashboardDataAccessChangesReminder();
    }
    this.showMembershipTab = this.model.objModel?.appUserExtension?.sharedInboxEmail;
    this.showManageUsersTab = this.model.objModel?.appUserExtension?.manageUsersAccess;
  }

  didLoad(objModel: AppUserViewModel) {
    if (this.model.objModel.appUserExtension!.userImageId) {
      this.loadUserImage();
    }

    if (this.model.objModel.appUserExtension!.logoId) {
      this.loadLogoImage();
    }

    // reset flag
    this.userGroupsChanged = false;

    // store new values as original values
    this.originalLineOfBusinessListJson = JSON.stringify(this.model.objModel.lineOfBusinessList);

    this.showAccessTab = this.model.objModel.activeDirectoryUserInd || isDevMode();

    if (objModel.groupsLineOfBusinessList) {
      this.userGroupsDataAccessGrids = objModel.groupsLineOfBusinessList.map(lob => <UserGroupsDataAccessGrid>{
        lineOfBusiness: lob,
        gridConfig: this.getUserGroupsDataAccessGridConfig(lob),
        isGridLoaded: false,
      });

      this.innerMatTabGroupUpdated = false;
    }

    this.showMembershipTab = objModel.appUserExtension?.sharedInboxEmail;
    this.showManageUsersTab = objModel.appUserExtension?.manageUsersAccess;
  }

  onRoleChange() {
    if (this.model.objModel.roleName == 'Client User - Triage Portal') {
      this.model.objModel.passwordDoesNotExpire = true;
    }
  }

  onSelectedTabChange(matTabChangeEvent: MatTabChangeEvent) {
    // if innerMatTabGroup (Groups Data Access) is not updated yet, update it, if and only if it is currently selected
    if (!this.innerMatTabGroupUpdated && matTabChangeEvent.tab.textLabel == this.userDataAccessTabLabel) {
      this.innerMatTabGroup.realignInkBar();  // re-align ink bar (underline selected tab in the inner mat-tab-group)
      this.innerMatTabGroupUpdated = true;    // update flag so this won't get updated again

      if (this.userGroupsDataAccessGrids.length > 0 && !this.userGroupsDataAccessGrids[0].isGridLoaded) {
        this.userGroupsDataAccessGrids[0].gridConfig.refreshGrid(0);
        this.userGroupsDataAccessGrids[0].isGridLoaded = true;
      }
    }
  }

  onSelectedIndexChange(newTabIndex: number) {
    if (!this.userGroupsDataAccessGrids[newTabIndex].isGridLoaded) {
      this.userGroupsDataAccessGrids[newTabIndex].gridConfig.refreshGrid(0);
      this.userGroupsDataAccessGrids[newTabIndex].isGridLoaded = true;
    }
  }

  loadLogoImage() {
    this.secureFileService.downloadFile(this.model.objModel.appUserExtension!.logoId!, 'events', true).subscribe(event => {
      if (event.type == HttpEventType.Response) {
        var reader = new FileReader();
        reader.onload = (event: any) => { this.logoImage = event.target.result; }
        reader.readAsDataURL(event.body!);
      }
    });
  }

  loadUserImage() {
    this.secureFileService.downloadFile(this.model.objModel.appUserExtension!.userImageId!, 'events', true).subscribe(event => {
      if (event.type == HttpEventType.Response) {
        var reader = new FileReader();
        reader.onload = (event: any) => { this.userImage = event.target.result; }
        reader.readAsDataURL(event.body!);
      }
    });
  }

  statusChanged(event: any) {
    this.model.objModel.active = event.value == "Active";
  }

  onImageCropped(imgCroppedEvent: ImageCroppedEvent) {
    var reader = new FileReader();
    reader.onload = (event: any) => { this.userImage = event.target.result; }
    reader.readAsDataURL(imgCroppedEvent.blob);

    if (this.appuserId == -1)
      this.userImgFile = imgCroppedEvent.blob;
    else {
      this.appUserService.uploadUserImage(ImageType.UserImage, this.appuserId, this.model.objModel.version, 0, [imgCroppedEvent.blob], 'events', true)
        .subscribe(event => {
          if (event.type == HttpEventType.Response) {
            var objResult = event.body!.result;
            this.model.objModel.appUserExtension!.userImageId = objResult!.imageFileId!;
            this.model.objModel.version = objResult!.version!;
          }
        });
    }
  }

  logoImageSelected(ev: any) {
    if (ev.srcElement.files.length != 1) {
      return;
    }

    let file = ev.srcElement.files[0] as File;

    if (file.size > AppSettings.MAX_FILE_SIZE) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.FILE_SIZE_LIMIT_EXCEEDED]
      });
      return;
    }

    if (file.type.toLowerCase() == "image/svg+xml") {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FILETYPE]
      });
      return;
    }
    
    if (file.type.toLowerCase().indexOf("image/") == -1) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.NOT_IMAGE_FILETYPE]
      });
      return;
    }

    var logoFile = file;
    if (this.appuserId == -1)
      this.logoImgFile = logoFile;
    else {
      this.appUserService.uploadUserImage(ImageType.Logo, this.appuserId, this.model.objModel.version, 0, [logoFile], 'events', true).subscribe(event => {
        if (event.type == HttpEventType.Response) {
          var objResult = event.body!.result;
          this.model.objModel.appUserExtension!.logoId = objResult!.imageFileId!;
          this.model.objModel.version = objResult!.version!;
        }
      });
    }
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.logoImage = event.target.result;
    }

    reader.readAsDataURL(logoFile);
  }

  onPasswordReset(newPasswordExpirationStatus: string) {
    this.model.objModel.passwordStatus = newPasswordExpirationStatus;
  }

  resetSecurityQuestions() {
    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Reset Security Questions", message: "Are you sure about reseting (deleting) security questions for this user?" }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.appUserService.deleteUserSecurityQuestions(this.model.objModelId).pipe(first()).subscribe(resp => {
          this.model.objModel.secQuestionsStatus = resp.result;
        });
      }
    });
  }

  unlockAccount() {
    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Unlock User Account", message: "Are you sure you want to unlock this user account?" }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.appUserService.unlockUserAccount(this.model.objModelId)
          .pipe(first())
          .subscribe(() => {
            this.model.objModel.lockedOut = false
          });
      }
    });
  }

  unexpirePassword() {
    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: <IchsDialogConfig>{
        title: "Unexpire User Password",
        message: "Have you verified that this user still has legal access to this data?",
        okText: "Yes",
        cancelText: "No",
      }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.appUserService.unexpireUserPassword(this.model.objModelId)
          .pipe(first())
          .subscribe(resp => {
            this.model.objModel.passwordStatus = resp.result;
            this.model.objModel.passwordExpired = false;
          });
      }
    });
  }

  // implementing the canDeactivate function.
  canDeactivate() {
    // check if the any of 'User Detail' or 'Access Features' or 'Data Access' tabs is dirty.
    if (this.ngForm.dirty || this.originalLineOfBusinessListJson !== JSON.stringify(this.model.objModel.lineOfBusinessList)) {
      let dialogRef: MatDialogRef<IchsDialogComponent, boolean> = this.dialog.open<IchsDialogComponent, any>(IchsDialogComponent, {
        width: '450px',
        data: { title: "Discard Changes ", message: "You will lose all changes in this page." }
      });
      return dialogRef.afterClosed().pipe(first(), map(result => {
        return result ? true : false;
      }));
    }
    return true;
  }

  fetchActiveDirectoryUserInfo(value: string) {
    if (!value || value == "") {
      return;
    }
    this.appUserService.getActiveDirectoryData(value.trim()).pipe(first()).subscribe(
      response => {
        if (response.result) {
          this.model.objModel.activeDirectoryUserInd = true;
          this.model.objModel.email = response.result.email;
          this.model.objModel.firstName = response.result.firstName;
          this.model.objModel.lastName = response.result.lastName;
          this.model.objModel.middleName = response.result.middleName;
          this.model.objModel.appUserExtension.clientName = response.result.appUserExtension.clientName;
          this.model.objModel.appUserExtension.website = response.result.appUserExtension.website;
          this.model.objModel.appUserExtension.logoId = response.result.appUserExtension.logoId;
          this.loadLogoImage();
        }
      }
    )
  }

  resetMfa(mfaType: string) {
    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Reset MFA", message: `Are you sure about reseting ${mfaType} MFA for this user?` }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.model.objModel.mfaAppEnabled = false;
        this.model.objModel.mfaTextEnabled = false;
        this.model.objModel.mfaSharedEmailEnabled = false;
        this.appUserService.resetMfa(this.model.objModelId, mfaType).pipe(first()).subscribe(resp => {
          this.model.objModel.mfaStatus = resp.result;
        });
      }
    });
  }

  setupMFA(mfaType: string) {

  }

  viewUserGroupsGrid() {
    this.dialog.open(IchsGridDialogComponent, {
      width: '1024px',
      height: '800px',
      data: {
        config: this.defineUserGroupsGrid(),
        title: "User Groups"
      }
    });
  }

  private defineUserGroupsGrid(): IchsGridComponentConfig<GroupViewModel> {
    let configs: IchsGridComponentConfig<GroupViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      entityDataSource: (filter: SearchViewModel) => this.groupAppUserAssociationService.searchGroupsByAppUserId(this.model.objModelId, filter),
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }

  private defineUserContactsGridConfig(): IchsGridComponentConfig<ContactGridViewModel> {
    let configs: IchsGridComponentConfig<ContactGridViewModel> = {
      primaryId: "id",
      defaultOrder: "lastName",
      title: null,
      entityController: null,
      entityDataSource: (filter: SearchViewModel) => {
        return this.userContactService.searchRelatedContacts(this.model.objModelId, false, filter);
      },
      addExistingConfig: {
        buttonText: "Add Existing",
        gridConfig: this.addExistingContactGridConfig(),
        addExistingService: (ids: string[]) => {
          let close = new Subject<MedcorResponse<boolean>>();
          let subs = this.userContactService.postList(this.model.objModelId, ids.map(obj => +obj))
            .subscribe(result => {
              subs.unsubscribe();
              if (result) {
                close.next({ result: true, statusCode: 200 });
              } else {
                close.next({ result: false });
              }
            });
          return close.asObservable();
        },
        title: "Add Contacts",
      },
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: ContactGridViewModel) => {
          if (!deletedObj.id) return;
          let subs = this.userContactService.removeContact(deletedObj.id, this.model.objModelId)
            .subscribe(result => {
              subs.unsubscribe();
              if (result) {
                this.userContactsGridConfig.refreshGrid!(this.userContactsGridConfig.getPage!());
              }
            });
        }
      },
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
          width: 300,
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "First Name",
          propertyName: "firstName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }

  private addExistingContactGridConfig(): IchsGridComponentConfig<ContactGridViewModel> {
    let addExistingGridConfig: IchsGridComponentConfig<ContactGridViewModel> = {
      primaryId: "id",
      defaultOrder: "lastName",
      title: "Users",
      entityController: null,
      entityDataSource: (filter: SearchViewModel) => this.userContactService.searchRelatedContacts(this.model.objModelId, true, filter),
      multiSelectConfig: {
      },
      hasVoid: false,
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
          width: 300,
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "First Name",
          propertyName: "firstName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    };
    return addExistingGridConfig;
  }

  private defineAppLogGridConfig(): IchsGridComponentConfig<AppLogSearchModel> {
    let configs: IchsGridComponentConfig<AppLogSearchModel> = {
      primaryId: "id",
      defaultOrder: "logTime",
      defaultOrderType: "desc",
      title: null,
      entityController: null,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => {
        if (this.logsType == "Exceptions Only") {
          filter.filterExpression = filter.filterExpression.length > 0 ?
            "isException == true && " + filter.filterExpression : "isException == true";
        }
        else if (this.logsType == "Non-Exceptions Only") {
          filter.filterExpression = filter.filterExpression.length > 0 ?
            "isException == false && " + filter.filterExpression : "isException == false";
        }

        return this.appUserService.searchUserAppLog(this.appuserId, filter)
      },
      beforeResetFunction: () => {
        this.logsType = "All";
      },
      detailAction: new IchsGridActionsConfig({
        title: "Show Log Details",
        function: (logObj: AppLogSearchModel) => {
          this.dialog.open(IchsComponentDialogComponent, {
            data: {
              title: "Log Details",
              component: AppLogDialogComponent,
              extraData: logObj,
            },
            width: "1000px"
          });
        },
      }),
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: true,
      hasVoid: false,
      headers: [
        {
          headerTitle: "Log ID",
          propertyName: "logId",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
          width: 300,
        },
        {
          headerTitle: "Log Time",
          propertyName: "logTime",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
          dateFormat: "MM/dd/yyyy h:mm:ss a",
          width: 180,
        },
        {
          headerTitle: "User Email",
          propertyName: "userEmail",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Impersonator Email",
          propertyName: "impersonatorUserEmail",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Level",
          propertyName: "level",
          searchable: true,
          sortable: true,
          filterVal: "Info",  // default value
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.LogLevels,
          width: 80,
        },
        {
          headerTitle: "Action",
          propertyName: "action",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Entity Name",
          propertyName: "entityName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }

  private defineApplicationsAccessGrid(): IchsGridComponentConfig<UserApplicationAccessViewModel> {
    let configs: IchsGridComponentConfig<UserApplicationAccessViewModel> = {
      primaryId: "id",
      defaultOrder: "applicationName",
      title: "Applications User Have Access To",
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: true,
      hasVoid: false,
      hasActionColumn: true,
      entityDataSource: (filter: SearchViewModel) => this.userApplicationAccessService.searchApplicationAccessByAppUserId(this.appuserId, filter),
      customNewAction: new IchsGridActionsConfig({
        title: "Add Existing",
        function: () => {
          let dialogRef: MatDialogRef<IchsComponentDialogComponent> = this.dialog.open(IchsComponentDialogComponent, {
            data: {
              title: "Associate Application to User",
              component: UserApplicationAccessComponent,
              hideCloseButton: true,
              extraData: <UserApplicationAccessViewModel>{
                userId: this.appuserId
              },
            },
            width: "1000px",
          });
          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.applicationsAccessGridConfig.refreshGrid!(0);
            }
          });
        },
      }),
      detailAction: new IchsGridActionsConfig({
        title: "Modify Access",
        function: (obj: UserApplicationAccessViewModel) => {
          let dialogRef: MatDialogRef<IchsComponentDialogComponent> = this.dialog.open(IchsComponentDialogComponent, {
            data: {
              title: "Modify Access",
              component: UserApplicationAccessComponent,
              hideCloseButton: true,
              extraData: obj,
            },
            width: "500px",
          });
          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.applicationsAccessGridConfig.refreshGrid!(0);
            }
          });
        },
      }),
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: UserApplicationAccessViewModel) => {
          if (!deletedObj.id) {
            return;
          }

          this.userApplicationAccessService.deleteUserApplicationAccess(deletedObj.id, deletedObj.version).pipe(first()).subscribe(result => {
            if (result) {
              this.applicationsAccessGridConfig.refreshGrid!(this.applicationsAccessGridConfig.getPage!());
            }
          });
        }
      },
      headers: [
        {
          headerTitle: "Application Name",
          propertyName: "applicationName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
          width: 200,
        },
        {
          headerTitle: "Expiration Date",
          propertyName: "expirationDate",
          convertUtcDateToLocal: false,
          sortable: true,
          searchable: true,
          nullable: true,
          controlType: IchsControlType.Date,
        }
      ],
    }
    return configs;
  }

  private getUserGroupsDataAccessGridConfig(lineOfBusiness: string) {
    switch (lineOfBusiness) {
      case LineOfBusiness.TRIAGE:
        return this.defineAfkamGroupsAccessGrid();
      case LineOfBusiness.OCCUPATIONAL_HEALTH:
        return this.defineMedfilesGroupsAccessGrid();
      case LineOfBusiness.ADVANCED_PRACTICE:
        return this.defineNextGenGroupsAccessGrid();
      case LineOfBusiness.SCREENING:
        return this.defineScreeningGroupsAccessGrid();
      case LineOfBusiness.OSHA_REPORTABLE:
        return this.defineOshaReportableGroupsAccessGrid();
      default:
        return null;
    }
  }

  private defineAfkamGroupsAccessGrid(): IchsGridComponentConfig<AfkamIntanceViewModel> {
    let configs: IchsGridComponentConfig<AfkamIntanceViewModel> = {
      primaryId: "name",
      defaultOrder: "name",
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: false,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUserAfkamDataAccess(this.appuserId, filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Sub Level",
          propertyName: "namedPath",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }

  private defineMedfilesGroupsAccessGrid(): IchsGridComponentConfig<SiteCompanyInstViewModel> {
    let configs: IchsGridComponentConfig<SiteCompanyInstViewModel> = {
      primaryId: "siteName",
      defaultOrder: "siteName",
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: false,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUserMedfilesDataAccess(this.appuserId, filter),
      headers: [
        {
          headerTitle: "Site",
          propertyName: "siteName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Company",
          propertyName: "companyName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Installation",
          propertyName: "installationName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        }
      ],
    }
    return configs;
  }

  private defineNextGenGroupsAccessGrid(): IchsGridComponentConfig<PracticeLocationViewModel> {
    let configs: IchsGridComponentConfig<PracticeLocationViewModel> = {
      primaryId: "locationName",
      defaultOrder: "locationName",
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: false,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUserNextGenDataAccess(this.appuserId, filter),
      headers: [
        {
          headerTitle: "Location",
          propertyName: "locationName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Practice",
          propertyName: "practiceName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }

  private defineScreeningGroupsAccessGrid(): IchsGridComponentConfig<ScreeningInstanceCollectionViewModel> {
    let configs: IchsGridComponentConfig<ScreeningInstanceCollectionViewModel> = {
      primaryId: "locationSubLevelName",
      defaultOrder: "locationSubLevelName",
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: false,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUserScreeningDataAccess(this.appuserId, filter),
      headers: [
        {
          headerTitle: "Location Sub-Level",
          propertyName: "locationSubLevelName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Location",
          propertyName: "locationName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Client",
          propertyName: "clientName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }

  private defineOshaReportableGroupsAccessGrid(): IchsGridComponentConfig<OshaCompanyLocationViewModel> {
    let configs: IchsGridComponentConfig<OshaCompanyLocationViewModel> = {
      primaryId: "locationName",
      defaultOrder: "locationName",
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: false,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUserOshaReportableDataAccess(this.appuserId, filter),
      headers: [
        {
          headerTitle: "Location",
          propertyName: "locationName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Company",
          propertyName: "companyName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }

  private defineMembershipsGrid(): IchsGridComponentConfig<UserSearchViewModel> {
    let configs: IchsGridComponentConfig<UserSearchViewModel> = {
      primaryId: "id",
      defaultOrder: "lastName",
      entityController: "appuser",
      hasAddExisting: this.allowAddAppUserInd,
      hasNew: false,
      hasVoid: false,
      loadOnInit: false,
      hasDetails: false,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUserMemberships(this.appuserId, false, filter),
      addExistingConfig: {
        buttonText: "Add Existing",
        gridConfig: this.addExistingMembersGridConfig(),
        addExistingService: (ids: string[]) => {
          let close = new Subject<MedcorResponse<boolean>>();
          let subs = this.appUserService.postMembershipList(this.model.objModelId, ids.map(obj => +obj))
            .subscribe(result => {
              subs.unsubscribe();
              if (result) {
                close.next({ result: true, statusCode: 200 });
              } else {
                close.next({ result: false });
              }
            });
          return close.asObservable();
        },
        title: "Add Memeberships",
      },
      secondAction: {
        icon: "assets/images/passwordStatus.svg",
        title: "Password Status",
        function: (user: UserSearchViewModel) => {
          this.appUserService.getUserPasswordStatus(user.id)
            .pipe(first())
            .subscribe(resp => {
              this.alertService.addAlert({
                type: AlertMessageType.info,
                title: 'Password Status:',
                dismiss: AlertDismissType.auto,
                messages: [resp.result],
              });
            });
        }
      },
      thirdAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: ContactGridViewModel) => {
          if (!deletedObj.id) return;
          this.appUserService.removeMembership(deletedObj.id, this.model.objModelId)
            .pipe(first()).subscribe(result => {
              if (result) {
                this.membershipsGridConfig.refreshGrid!(this.membershipsGridConfig.getPage!());
              }
            });
        }
      },
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
          width: 250
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "First Name",
          propertyName: "firstName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Membership Access",
          propertyName: "hasDataAccess",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Boolean
        },
        {
          headerTitle: "User Role",
          propertyName: "roleName",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.StaticSelect
        },
        {
          headerTitle: "Client Name",
          propertyName: "clientName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Status",
          propertyName: "status",
          searchable: true,
          sortable: true,
          listData: AppGenericLists.UserStatus,
          controlType: IchsControlType.StaticSelect,
        },
        {
          headerTitle: "Password Expired",
          propertyName: "passwordExpired",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Boolean,
        },
        {
          headerTitle: "Last Login",
          propertyName: "lastLoginTime",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
          nullable: true,
        }
      ]
    }
    return configs;
  }


  private addExistingMembersGridConfig(): IchsGridComponentConfig<UserSearchViewModel> {
    let addExistingGridConfig: IchsGridComponentConfig<UserSearchViewModel> = {
      primaryId: "id",
      defaultOrder: "lastName",
      entityController: null,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUserMemberships(this.appuserId, true, filter),
      multiSelectConfig: {
      },
      hasVoid: false,
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
          width: 300,
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "First Name",
          propertyName: "firstName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    };
    return addExistingGridConfig;
  }

  private defineManageUsersGrid(): IchsGridComponentConfig<UserSearchViewModel> {
    let configs: IchsGridComponentConfig<UserSearchViewModel> = {
      primaryId: "id",
      defaultOrder: "lastName",
      entityController: "appuser",
      hasAddExisting: this.allowAddAppUserInd,
      hasNew: false,
      hasVoid: false,
      loadOnInit: false,
      hasDetails: false,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUserManagement(this.appuserId, false, filter),
      addExistingConfig: {
        buttonText: "Add Existing",
        gridConfig: this.addExistingManageUsersGridConfig(),
        addExistingService: (ids: string[]) => {
          let close = new Subject<MedcorResponse<boolean>>();
          let subs = this.appUserService.postUserManagementList(this.model.objModelId, ids.map(obj => +obj))
            .subscribe(result => {
              subs.unsubscribe();
              if (result) {
                close.next({ result: true, statusCode: 200 });
              } else {
                close.next({ result: false });
              }
            });
          return close.asObservable();
        },
        title: "Add Users",
      },
      secondAction: {
        icon: "assets/images/passwordStatus.svg",
        title: "Password Status",
        function: (user: UserSearchViewModel) => {
          this.appUserService.getUserPasswordStatus(user.id)
            .pipe(first())
            .subscribe(resp => {
              this.alertService.addAlert({
                type: AlertMessageType.info,
                title: 'Password Status:',
                dismiss: AlertDismissType.auto,
                messages: [resp.result],
              });
            });
        }
      },
      thirdAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: ContactGridViewModel) => {
          if (!deletedObj.id) return;
          this.appUserService.removeUserManagement(deletedObj.id, this.model.objModelId)
            .pipe(first()).subscribe(result => {
              if (result) {
                this.manageUsersGridConfig.refreshGrid!(this.manageUsersGridConfig.getPage!());
              }
            });
        }
      },
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
          width: 250
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "First Name",
          propertyName: "firstName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Data Access",
          propertyName: "hasDataAccess",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Boolean
        },
        {
          headerTitle: "User Role",
          propertyName: "roleName",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.StaticSelect
        },
        {
          headerTitle: "Client Name",
          propertyName: "clientName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Status",
          propertyName: "status",
          searchable: true,
          sortable: true,
          listData: AppGenericLists.UserStatus,
          controlType: IchsControlType.StaticSelect,
        },
        {
          headerTitle: "Password Expired",
          propertyName: "passwordExpired",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Boolean,
        },
        {
          headerTitle: "Last Login",
          propertyName: "lastLoginTime",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
          nullable: true,
        }
      ]
    }
    return configs;
  }


  private addExistingManageUsersGridConfig(): IchsGridComponentConfig<UserSearchViewModel> {
    let addExistingGridConfig: IchsGridComponentConfig<UserSearchViewModel> = {
      primaryId: "id",
      defaultOrder: "lastName",
      entityController: null,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUserManagement(this.appuserId, true, filter),
      multiSelectConfig: {
      },
      hasVoid: false,
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
          width: 300,
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "First Name",
          propertyName: "firstName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    };
    return addExistingGridConfig;
  }
}

export enum ImageType {
  UserImage = "UserImage",
  Logo = "Logo",
}
