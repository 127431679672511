<h2 mat-dialog-title>{{config.copySchedule ? 'Copy ' : ''}}Report Scheduler for {{reportTitle}}</h2>
<mat-dialog-content>
  <div class="display-flex flex-basis-0 flex-grow-1 flex-col" [style.display]="showSaveAsScreen ? null : 'none'">
    <ichs-text-box label="Save as" placeholder="New Client Report Title" [initialFocus]="true" name="clientReportTitle"
      [(ngModel)]="model.clientReportTitle">
    </ichs-text-box>
  </div>
  <div class="display-flex flex-basis-0 flex-grow-1 flex-col" [style.display]="!showSaveAsScreen ? null : 'none'">
    <form #form="ngForm" novalidate class="flex-grow-1">
      <div class="cont-padding email-container">
        <div class="flex-child">
          <ichs-select label="How often do I want to receive this report" name="scheduleMode" [disabled]="!isNew && !userHasScheduleReportFullAccess && !config.copySchedule" [hideResetLabel]="true" [items]="ScheduleModeItems" [(ngModel)]="model.runMode" required></ichs-select>
        </div>
        <div *ngIf="model.runMode == 'Once'" class="flex-child">
          <ichs-date-picker class="flex-basis-0 flex-grow-2" name="runDate"
            [disabled]="!isNew && !userHasScheduleReportFullAccess && !config.copySchedule" [(ngModel)]="model.startPeriod" label="Report Run Date"
            required></ichs-date-picker>
        </div>

        <ng-container *ngIf="model.runMode && model.runMode != 'Once'">
          <div class="flex-child">
            <ichs-date-picker class="flex-basis-0 flex-grow-2" name="periodStartDate"
              [disabled]="!isNew && !userHasScheduleReportFullAccess && !config.copySchedule" [(ngModel)]="model.startPeriod"
              label="First day I want to receive this report" required></ichs-date-picker>
          </div>
          <div class="flex-child">
            <ichs-date-picker class="flex-basis-0 flex-grow-2" name="periodEndDate"
              [disabled]="!isNew && !userHasScheduleReportFullAccess && !config.copySchedule" [(ngModel)]="model.endPeriod"
              label="Last day I want to receive this report"></ichs-date-picker>
          </div>
        </ng-container>

        <div class="flex-child">
          <ichs-select label="Report Format" name="ReportFormat" [disabled]="!isNew && !userHasScheduleReportFullAccess && !config.copySchedule" [hideResetLabel]="true" [items]="ReportFormatItems" [(ngModel)]="model.reportFormat" required></ichs-select>
        </div>

        <div>
          <div class="flex-child email-field-title label-cont">
            <label style="margin-bottom:4px;">Recipients</label>
          </div>
          <div class="input-cont" *ngIf="userHasScheduleReportFullAccess">
            <input autocomplete="off" type="email" matInput name="contact" [(ngModel)]="enteredEmail" placeholder="Enter Email Address"
                    (keydown.space)="validateNewEmail()"  (keydown.enter)="$event.preventDefault(); validateNewEmail()" (blur)="validateNewEmail()"
                    [email]="enteredEmail != '' && enteredEmail != null" />
          </div>
        </div>

        <div class="display-flex flex-items-centered ">
          <div class="flex-basis-0 flex-grow-1 drop-contacts-area" [ngClass]="{'flex-child' : userHasScheduleReportFullAccess }" *ngIf="this.scheduleReceivers.length > 0">
            <div class="email-field-title"></div>
            <mat-chip-listbox>
              <mat-chip-option [style.background-color]="!contact.recieverHasAccount ? 'pink' : ''" 
              [title]="!contact.recieverHasAccount ? 'No account was found for this recipient in Client Portal' : ''" 
              [selectable]="false" [removable]="userHasScheduleReportFullAccess" *ngFor="let contact of this.scheduleReceivers">
                {{contact.receiverEmail}}
                <button *ngIf="userHasScheduleReportFullAccess" class="cp-btn cp-chip-btn" (click)="removeContact(contact)">
                  <img class="remove-chip-icon" src="assets/images/delete-cross.svg" />
                </button>
              </mat-chip-option>
            </mat-chip-listbox>
          </div>
        </div>

        <span *ngIf="model.runMode && model.runMode != 'Once'">
          Report will run once every {{getReportRunModePeriod(model.runMode)}} starting at the start date and will end at the end date
        </span>
      </div>
    </form>
    <div class="display-flex cont-padding">
      <div class="flex-basis-0 flex-grow-1"></div>
      <button [disabled]="isLoadingParameters" class="cp-btn cp-main-btn" (click)="fillParameters()">
        {{isLoadingParameters ? 'Loading Parameters...' : 'Report Parameters'}}
      </button>
      <div class="flex-basis-0 flex-grow-1"></div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-basis-0 flex-grow-1 text-right">
    <button mat-button class="cb-btn cp-main-btn" *ngIf="config.copySchedule" (click)="nextOrBack()">{{showSaveAsScreen ? 'Next' : 'Back'}}</button>
    <button mat-button class="cb-btn cp-main-btn" *ngIf="!showSaveAsScreen && !generalVariables.userImpersonationInfo.impersonatorRoleInd && (isNew || userHasScheduleReportFullAccess || config.copySchedule)" (click)="submit()">Submit</button>
    <button mat-button class="cb-btn cp-mail-btn" mat-dialog-close>{{(isNew || userHasScheduleReportFullAccess) ? 'Cancel' : 'Close'}}</button>
  </div>
</mat-dialog-actions>
