<div style="height:100vh; overflow:auto;">
  <div class="triage-secured-file">
    <div>
      <div class="display-flex flex-items-centered">
        <div class="logo-cont">
          <img src="assets/images/medcorLogo.svg" class="main-logo" />
        </div>
      </div>
      <hr class="main-hr" />
    </div>
    <div>
      <h1 class="main-title">Create Account</h1>
      <hr class="main-hr" />
    </div>
    <div class="flex-grow-1 external-page-cont">

      <div class="cont-padding">
        <div class="main-container">
          <div class="">
            <div>
              <form #registrationForm="ngForm" novalidate>

                <ichs-collapsable-panel panelTitle="User Information">
                  <div class="collapsableBody">
                    <div class='display-flex break-la'>
                      <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                        <ichs-text-box name="firstName" [(ngModel)]="registrationModel.firstName" label="First Name"
                          required></ichs-text-box>
                      </div>

                      <div class="flex-col-sep"></div>
                      <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                        <ichs-text-box name="lastName" [(ngModel)]="registrationModel.lastName" label="Last Name"
                          required></ichs-text-box>
                      </div>
                    </div>

                    <div class='display-flex break-la'>
                      <div class="flex-child flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                        <ichs-password #passwordComponent name="password" [(ngModel)]="registrationModel.password"
                          required label="Password" passwordStrength>
                        </ichs-password>
                        <div class="position-relative">
                          <div class="password-rules">
                            (At least 12 characters including: capital character, small character, number or symbol.)
                          </div>
                        </div>
                      </div>
                      <div class="flex-col-sep"></div>
                      <div class="flex-child flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                        <ichs-password name="confirmPassword" [(ngModel)]="confirmPassword" label="Confirm Password"
                          validateEqual [relatedControl]="passwordComponent.model.control" required></ichs-password>
                      </div>
                    </div>

                    <div class='display-flex break-la'>
                      <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                        <ichs-text-box name="clientName" [(ngModel)]="registrationModel.clientName" label="Client Name"
                          required></ichs-text-box>
                      </div>

                      <div class="flex-col-sep"></div>
                      <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered"></div>
                    </div>
                  </div>
                </ichs-collapsable-panel>

                <ichs-collapsable-panel panelTitle="Security Questions">
                  <div class="collapsableBody">
                    <div class='display-flex break-la'
                      *ngFor="let secQuestion of registrationModel.secQuestionAssociationList, let i = index">
                      <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                        <ichs-select label="Security Question" name="securityQuestionSelect{{i}}"
                          placeholder="Select Question" [items]="getFilteredQuestionsList(secQuestion.question)"
                          [(ngModel)]="secQuestion.question" required>
                        </ichs-select>
                      </div>

                      <div class="flex-col-sep"></div>
                      <div class="flex-child display-flex sec-ques-answer break-sm flex-items-centered">
                        <ichs-text-box required label="Answer" name="securityQuestionAnswer{{i}}"
                          [(ngModel)]="secQuestion.answer">
                        </ichs-text-box>
                      </div>
                    </div>
                  </div>
                </ichs-collapsable-panel>

                <div style="position: relative; left: 20px;" class="flex-child">
                  <button class="cp-btn cp-main-btn" (click)="createAccount()">Create Account</button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div>
      <app-footer></app-footer>
    </div>
  </div>
</div>