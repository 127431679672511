<div style="height:100vh; overflow:auto;">
    <div class="triage-secured-file">
        <div>
            <div class="display-flex flex-items-centered">
                <div class="logo-cont"><img src="assets/images/medcorLogo.svg" class="main-logo" /></div>
                <div class="flex-grow-1 flex-basis-0"></div>
                <div class="user-info-cont">
                    <div class="display-flex flex-items-centered">
                        <button mat-button [matMenuTriggerFor]="menu" class="user-img-btn">
                            <div class="profile-img">
                                <ngx-avatars background="#808080" size="34"></ngx-avatars>
                            </div>
                        </button>
                        <button mat-button [matMenuTriggerFor]="menu" class="user-menu">
                            User
                        </button>
                    </div>
                </div>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="navigateToMainPage()">Go to Login</button>
                </mat-menu>
            </div>
            <hr class="main-hr" />
        </div>

        <div>
            <h1 class="main-title">Retrieve Triage File</h1>
            <hr class="main-hr" />
        </div>

        <div class="flex-grow-1 external-page-cont">
            <div class="section-margin" style="margin-top:10px;">
                <div>
                    <span>
                        <b>Company Name: </b>
                    </span>
                    <span>
                        {{ triageSecuredFileInfo.companyName }}
                    </span>
                </div>

                <div>
                    <span>
                        <b>Location Name: </b>
                    </span>
                    <span>
                        {{ triageSecuredFileInfo.locationName }}
                    </span>
                </div>

                <div>
                    <span>
                        <b>Reference Number: </b>
                    </span>
                    <span>
                        {{ triageSecuredFileInfo.referenceNumber }}
                    </span>
                </div>

                <div>
                    <span>
                        <b>Call Date: </b>
                    </span>
                    <span>
                        {{ triageSecuredFileInfo.callDate | date: 'MM/dd/yyyy' }}
                    </span>
                </div>

                <div>
                    <span>
                        <b>Employee Name: </b>
                    </span>
                    <span>
                        {{ triageSecuredFileInfo.employeeName }}
                    </span>
                </div>
            </div>

            <div class="section-margin">
                By clicking on the link below I agree to view the data, report or document before downloading it, and
                will
                not download the data, report or document if I am not entitled to retain same.
            </div>
            <div class="section-margin">
                The following link will expire on {{triageSecuredFileInfo.expirationDate | date: 'MM/dd/yyyy'}}
            </div>
            <div class="section-margin" *ngIf="fileFound">
                <div class="display-flex flex-items-centered">
                    <div class="flex-child flex-basis-0 flex-grow-1 filename-label">
                        <ichs-text-box name="name" [ngModel]="triageSecuredFileInfo.fileName"
                            [title]="triageSecuredFileInfo.fileName" disabled>
                        </ichs-text-box>
                    </div>
                    <div class="flex-col-sep"></div>
                    <div class="flex-child align-self-end">
                        <button (click)="downloadFile()" class="cp-btn cp-main-btn">
                            Download
                        </button>
                    </div>
                </div>
            </div>
            <div class="section-margin" *ngIf="fileFound == false">
                <p class="alert alert-warning">
                    The requested report was not generated or could not be found. Please contact Medcor Client Services
                    for assistance with generating the report at triageclientservice&#64;medcor.com
                </p>
            </div>
        </div>
        <div>
            <app-footer></app-footer>
        </div>
    </div>
</div>