<div class="display-flex flex-basis-0 flex-grow-1 flex-col">
  <form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
    <div class="cont-padding">
      <div class="cont-padding border-bottom">
        <ichs-command-toolbar [model]="model" class="display-flex flex-col"></ichs-command-toolbar>
      </div>
      <ichs-collapsable-panel panelTitle="Announcement Details" style="padding-top:0px">
        <div class="collapsableBody">
          <div class="display-flex break-la">
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              <ichs-text-area type="text" name="description" [(ngModel)]="model.objModel.description" label="Description"
                maxlength="255" required>
              </ichs-text-area>
            </div>
          </div>
          <div class="display-flex break-la">
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              <ichs-datetime-picker name="startTime" [(ngModel)]="model.objModel.startTime " required label="Start Date">
              </ichs-datetime-picker>
            </div>
            <div class="flex-col-sep"></div>
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              <ichs-datetime-picker name="endTime" [(ngModel)]="model.objModel.endTime " label="End Date"></ichs-datetime-picker>
            </div>
          </div>
          <div class="display-flex break-la">
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              <ichs-select name="Group" label="Group Name" [(ngModel)]="groupId" [items]="groupItems" hideResetLabel="false" hasSearch="true"
                required></ichs-select>
            </div>
            <div class="flex-col-sep"></div>
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            </div>
          </div>
        </div>
      </ichs-collapsable-panel>
    </div>
  </form>
</div>