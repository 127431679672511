import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { AccountsService, ForgetPasswordViewModel } from '../../rest';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';
import { first } from 'rxjs/operators';
import { UntypedFormGroup, NgForm, UntypedFormControl } from '@angular/forms';
import { ValidationMessages } from '../../app.general.constants';

@Component({
  selector: 'recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.css']
})
/** recover-password component*/
export class RecoverPasswordComponent implements OnInit {
  @ViewChild('form') ngForm: NgForm;
  @Output() redirectToLogin: EventEmitter<string> = new EventEmitter<string>();
 
  forgetPasswordObj: ForgetPasswordViewModel = { email: "", encryption: "", newPassword: "", secQuestionAssociationList: [] };
  confirmPassword: string = "";
  zendeskSecQuestionsResetRequestFormLink: string;

  /** recover-password ctor */
  constructor(
    private accountsService: AccountsService,
    private alertService: MedcorAlertService,
  ) {
  }

  ngOnInit() {
    let pathParts: string[] = location.pathname.split("/");
    if (pathParts.length < 3) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Invalid Link!"]
      });
      return;
    }

    this.forgetPasswordObj.encryption = pathParts[pathParts.length - 1];
    this.accountsService.getSecurityResetData(this.forgetPasswordObj.encryption, true)
      .pipe(first())
      .subscribe(res => {
        let securityResetData = res.result;
        this.forgetPasswordObj.email = securityResetData.email;
        this.forgetPasswordObj.secQuestionAssociationList = securityResetData.secQuestionAssociationList;
        this.zendeskSecQuestionsResetRequestFormLink = securityResetData.zendeskSecQuestionsResetRequestFormLink;
      });
  }

  saveNewPassword() {
    this.validateAllFormFields(this.ngForm.form);
    if (this.ngForm.form.valid) {
      this.accountsService.changePasswordBySecQuestions(this.forgetPasswordObj).pipe(first()).subscribe(() => {
        this.redirectToLogin.emit();
      });
    }
    else {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
