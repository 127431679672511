import { Component, OnInit, ViewChild } from '@angular/core';
import { IchsCommandToolbarDelegate, IchsCommandToolbarModel } from 'src/app/controls/ichs-command-toolbar/ichs-command-toolbar.component';
import { NgForm } from '@angular/forms';
import { CommonQuestionViewModel, CommonQuestionService, MedcorResponse, SelectionItemViewModel } from 'src/app/rest';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MedcorAlertService } from 'src/app/services/medcor-alert.service';
import { Observable } from 'rxjs';
import { IchsDialogComponent } from 'src/app/controls/ichs-dialog/ichs-dialog.component';
import { first, map } from 'rxjs/operators';
import { AppGenericLists } from 'src/app/app.general.lists';

@Component({
  selector: 'help-center-question',
  templateUrl: './help-center-question.component.html',
  styleUrls: ['./help-center-question.component.css']
})
export class HelpCenterQuestionComponent implements OnInit, IchsCommandToolbarDelegate<CommonQuestionViewModel> {
  @ViewChild('form', { static: true }) ngForm: NgForm;// 1) bind the Form From view 
  model: IchsCommandToolbarModel<CommonQuestionViewModel>;// 2) define Command Toolbar model and use model.objModel to bing the FORM UI and use model as IchsCommandToolbar [model]
  get questionId(): number { return this.model.objModelId }
  public lineOfBusinessList: SelectionItemViewModel[] = AppGenericLists.LineOfBusinessCategory;

  constructor(
    private location: Location,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private alertService: MedcorAlertService,
    private questionService: CommonQuestionService,
  ) {

    this.model = new IchsCommandToolbarModel<CommonQuestionViewModel>(location, router, "/help-question", "CommonQuestion", this, dialog, alertService); // 3) Create Command Toolbar Model 

    this.model.hasSave = true;
    this.model.hasVoidRecover = true;
  }

  /** Group OnInit*/
  ngOnInit() {
    this.model.init(this.route); // 4) Initialize The Model
  }

  // 5) implement IchsCommandToolbarDelegate .
  canDeactivate(): boolean | Observable<boolean> {
    if (this.ngForm.dirty) {
      let dialogRef: MatDialogRef<IchsDialogComponent, boolean> = this.dialog.open<IchsDialogComponent, any>(IchsDialogComponent, {
        width: '450px',
        data: { title: "Discard Changes ", message: "You will lose all changes in this page." }
      });
      return dialogRef.afterClosed().pipe(first(), map(result => {
        if (result && result == true) {
          return result;
        } else {
          return false;
        }
      }));
    }
    return true;
  }


  getLoadObjModelObservable(id: number) {
    return this.questionService.getById(id);
  }
  getSaveObservable(objModel: CommonQuestionViewModel): Observable<MedcorResponse<CommonQuestionViewModel>> {
    return this.questionService.post(objModel)
  }
  getVoidObservable(objVoidModel: CommonQuestionViewModel): Observable<MedcorResponse<CommonQuestionViewModel>> {
    return this.questionService._void(objVoidModel)
  }
  getRecoverObservable(id: number): Observable<MedcorResponse<CommonQuestionViewModel>> {
    return this.questionService.getById(id);
  }
}
