/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface TokenViewModel { 
    id?: number;
    authToken?: string;
    email?: string;
    expiryTime?: Date;
    lastLoggedInDate?: Date;
    firstLoginInd?: boolean;
    changePasswordInd?: boolean;
    isImpersonate?: boolean;
    roleName?: string;
    mfaRequested?: boolean;
    mfaTextEnabled?: boolean;
    mfaSharedEmailEnabled?: boolean;
    triagePortalUserPinCodeRequested?: boolean;
    triagePortalUserAdminEmail?: string;
    triagePortalUserDefaultAdminEmailUsed?: boolean;
}
