<img src="assets/images/medcorLogo.svg" class="logo" />

<div [hidden]="pageNum != 1">
    <h4>More Information Required</h4>

    <p>Medcor needs more information to keep your account secure</p>

    <p style="text-decoration: underline;">The Multifactor Authentication is going to be enforced on {{ dateTobeEnforced | date: 'MM/dd/yyyy' }}. Please take
        the time now to set it up beforehand.</p>

    <div class="text-right fill-width">
        <button class="cp-btn cp-main-btn" (click)="nextPage()">Next</button>
    </div>
</div>

<ng-template #textAndAppBlock  [ngIf]="!emailVerification" [ngIfElse]="emailBlock">
    <div [hidden]="pageNum != 2">
        <p>Please select which Authentication method you would like to use to secure your account</p>

        <div class="flex-child display-flex justify-content-center">
            <a class="btn btn-default mfa-btn" (click)="setPageNum(3)">
                <div class="display-flex flex-items-centered fill-width">
                    <img src="assets/images/newIcons/authenticator.svg" />
                    <div>
                        Use Authentication App
                    </div>
                </div>

            </a>
        </div>
        <div class="flex-child display-flex justify-content-center">
            <a class="btn btn-default mfa-btn" (click)="setPageNum(4)">
                <div class="display-flex flex-items-centered fill-width">
                    <img src="assets/images/newIcons/sms.svg" />
                    <div>
                        Use Text/SMS
                    </div>
                </div>
            </a>
        </div>
    </div>

    <div [hidden]="pageNum != 3">
        <h4>Configure Mobile App</h4>

        <p>Complete the following steps to configure your mobile app.</p>

        <p>1. Install the Google or Microsoft Authenticator app for Android, or iOS.</p>
        <p>2. Scan the image below</p>

        <qr-code [value]="qrDataVM.authenticatorUri" size="200"></qr-code>

        <p>If you are unable to scan the image, enter the following information in your app.</p>

        <p>Code: <kbd>{{qrDataVM.sharedKey}}</kbd></p>


        <code-verification #emailVerficationCode [(ngModel)]="qrDataVM.code"
            [message]="appVerMessage" [hasResendCode]="false" (verifyClicked)="verifyAuthenticatorCode()"
            (cancelClicked)="setPageNum(1)" ></code-verification>
    </div>

    <div [hidden]="pageNum != 4">
        <h4>Configure Text/SMS</h4>

        <p>Enter a valid phone number</p>

        <div class="display-flex break-la margin-bottom-def">
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-select disabled [hideResetLabel]="true" [items]="countryCodes" class="country-code"
                    [ngModel]="'+1'" name="countryCode"></ichs-select>
                <ichs-text-box class="cell-phone" [(ngModel)]="cellPhone" name="phoneNumber"
                    inputMask="phone"></ichs-text-box>
            </div>
        </div>

        <div class="text-right fill-width">
            <button class="cp-btn cp-mail-btn" (click)="setPageNum(1)">Cancel</button>
            <button class="cp-btn cp-main-btn" (click)="updateCellPhone()">Continue</button>
        </div>
    </div>

    <div [hidden]="pageNum != 5">
        <code-verification #emailVerficationCode [(ngModel)]="phoneNbrVerficationCode" [title]="textVerTitle"
            [message]="textVerMessage" [hasResendCode]="true" (verifyClicked)="verifySmsCode()"
            (cancelClicked)="previousPage()" (resendCode)="resendVerificationCode()"></code-verification>
    </div>

    <div [hidden]="pageNum != 6">
        <h4>Great Job!</h4>

        <p>You have successfully set up your security info. Choose "Done" to continue signing in.</p>

        <div class="text-right fill-width">
            <button class="cp-btn cp-mail-btn" [mat-dialog-close]="true">Done</button>
        </div>
    </div>
</ng-template>

<ng-template #emailBlock>
    <div [hidden]="pageNum != 2">
        <h4>Configure Email</h4>

        <p>Verify the email filled in is correct</p>

        <div class="display-flex break-la margin-bottom-def">
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [(ngModel)]="email" name="sharedInboxEmail" [disabled]="true">

                </ichs-text-box>
            </div>
        </div>

        <div class="text-right fill-width">
            <button class="cp-btn cp-mail-btn" (click)="previousPage()">Cancel</button>
            <button class="cp-btn cp-main-btn" (click)="sendEmailVerification()">Continue</button>
        </div>
    </div>

    <div [hidden]="pageNum != 3">
        <code-verification #emailVerficationCode [(ngModel)]="emailVerificationCode" [title]="emailVerTitle" [message]="emailVerMessage" [hasResendCode]="true"
            (verifyClicked)="verifyEmailCode()" (cancelClicked)="previousPage()" (resendCode)="resendVerificationCode(true)"></code-verification>
    </div>

    <div [hidden]="pageNum != 4">
        <h4>Great Job!</h4>

        <p>You have successfully set up your security info. Choose "Done" to continue signing in.</p>

        <div class="text-right fill-width">
            <button class="cp-btn cp-mail-btn" [mat-dialog-close]="true">Done</button>
        </div>
    </div>

</ng-template>
