import { Component, OnInit } from '@angular/core';
import { IchsGridComponentConfig, IchsControlType } from '../../../controls/ichs-grid/grid-configs';
import { SearchViewModel } from '../../../rest/model/searchViewModel';
import { MedfilesVirtualCompanyService } from '../../../rest/api/medfilesVirtualCompany.service';
import { MedfilesVirtualCompanyViewModel } from '../../../rest/model/medfilesVirtualCompanyViewModel';
import { VoidInfoViewModel } from 'src/app/rest';

@Component({
  selector: 'medfiles-virtual-company-list',
  templateUrl: './medfiles-virtual-company-list.component.html',
  styleUrls: ['./medfiles-virtual-company-list.component.css']
})
export class MedfilesVirtualCompanyListComponent implements OnInit {

  gridConfig: IchsGridComponentConfig<MedfilesVirtualCompanyViewModel>;

  constructor(
    private medfilesVirtualCompanyService: MedfilesVirtualCompanyService,
  ) {
    this.gridConfig = this.defineGrid();
  }

  ngOnInit() {
  }

  private defineGrid(): IchsGridComponentConfig<MedfilesVirtualCompanyViewModel> {
    let configs: IchsGridComponentConfig<MedfilesVirtualCompanyViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Medfiles Virtual Companies",
      entityController: "medfiles-virtual-company",
      hasVoid: true,
      hasNew: true,
      entityDataSource: (filter: SearchViewModel) => this.medfilesVirtualCompanyService.search(filter),
      voidAction: (reason: VoidInfoViewModel) => this.medfilesVirtualCompanyService._void(reason),
      recoverAction: (id: number) => this.medfilesVirtualCompanyService.recover(id),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ],
    }

    return configs;
  }
}
