<div class="display-flex flex-basis-0 flex-grow-1 flex-col">
  <form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1" style="display:none"></form>
  <mat-tab-group mat-align-tabs="start"  mat-stretch-tabs="false" class="flex-grow-1">
    <mat-tab label="Report Item Detail" class="fill-parent">
      <div class="cont-padding">
        <div class="cont-padding border-bottom">
          <ichs-command-toolbar [model]="model" class="display-flex flex-col"></ichs-command-toolbar>
        </div>
        <mat-horizontal-stepper [linear]="true" #stepper="matHorizontalStepper">
          <ng-template matStepperIcon="edit">
            <img src="assets/images/pencil-edit-button.svg" class="custom-mat-icon" />
          </ng-template>
          <mat-step label="Choose Report" [stepControl]="formGroupReport">
            <div class="flex-grow-1 secured-files">
              <div class="secured-files-tools display-flex flex-items-centered">
                <div><a style="cursor: pointer;" (click)="routeToFolder(null)">Root</a>&nbsp;</div>
                <div class="flex-grow-1 flex-basis-0">
                  <ng-container *ngFor="let item of pathFolders">
                    / <a style="cursor: pointer;" (click)="routeToFolder(item)">{{item.name}}</a>
                  </ng-container>
                </div>
                <div class="validation" *ngIf="formGroupReport.invalid && isformGroupReportChecked">
                  <div title="Please Select a File">
                    <img src="assets/images/information.svg" class="validation-icon"/>
                  </div>
                </div>
              </div>
              <hr class="main-hr" />
              <ng-scrollbar class="stepper-scrolling">
                <div class="cont-padding" id="mainFolder">
                  <div class="user-file"
                       *ngFor="let item of getFiles()"
                       [title]="item.name"
                       [ngClass]="{'selected-file':selectedReport == item }"
                       (click)="selectFile(item)">
                    <div class="file-visuals">
                      <img src="assets/images/file.svg" class="file-icon" />
                      <span style="display:block; cursor:default;">{{item.name}}</span>
                    </div>
                  </div>
                  <div class="user-folder"
                       [title]="item.name"
                       (dblclick)="routeToFolder(item)"
                       *ngFor="let item of getFolders()">
                    <div class="folder-visuals">
                      <img src="assets/images/folder.svg" class="folder-icon" />
                      <span style="display:block">{{item.name}}</span>
                      <div class="file-actions flex-items-centered">
                        <div class="flex-basis-0 flex-grow-1 text-center">
                          <img class="control-image" src="assets/images/open.svg" (click)="routeToFolder(item)" title="Open Folder" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-scrollbar>

            </div>
            <form #formGroupReport="ngForm" novalidate class="flex-basis-0 flex-grow-1">
              <ichs-text-box name="selectedReportPath" [ngModel]="selectedReportPath" required style="display:none"></ichs-text-box>
            </form>
            <div>
              <button type="button" class="cp-btn cp-main-btn" matStepperNext (click)="model.validateAllFormFields(formGroupReport.form)">Next</button>
            </div>
          </mat-step>
          <mat-step label="Upload Photos" [stepControl]="formGroupImages">
            <div class="stepper-scrolling">
              <div class="display-flex">
                <div class="flex-basis-0 flex-grow-1"></div>
                <div class="validation" *ngIf="formGroupImages.invalid && isformGroupImagesChecked">
                  <div title="Please Select Images">
                    <img src="assets/images/information.svg" class="validation-icon" />
                  </div>
                </div>
              </div>

              <div class="display-flex break-la">
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                  <div class='flex-cell label-cont'><label> Thumbnail </label></div>
                  <div class="margin-left-def">
                    <img width="50" *ngIf="thumbImage == null || thumbImage == ''" src="assets/images/user.svg" title="Thumbnail" (click)="thumbImageInput.click()" />
                    <img width="50" *ngIf="thumbImage != null && thumbImage != ''" [src]="thumbImage" title="Thumbnail" (click)="thumbImageInput.click()" />
                    <input (change)="thumbImageSelected($event)" type="file" #thumbImageInput style="display:none" accept="image/x-png,image/gif,image/jpeg" />
                  </div>
                </div>
                <div class="flex-col-sep"></div>
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                  <div class='flex-cell label-cont'><label> Snapshot </label></div>
                  <div class="margin-left-def">
                    <img width="150" *ngIf="snapshotImage == null || snapshotImage == ''" src="assets/images/user.svg" title="Snapshot" (click)="snapshotImageInput.click()" />
                    <img width="150" *ngIf="snapshotImage != null && snapshotImage != ''" [src]="snapshotImage" title="Snapshot" (click)="snapshotImageInput.click()" />
                    <input (change)="snapshotImageSelected($event)" type="file" #snapshotImageInput style="display:none" accept="image/x-png,image/gif,image/jpeg" />
                  </div>
                </div>
              </div>
            </div>

            <form #formGroupImages="ngForm" novalidate class="flex-basis-0 flex-grow-1">
              <ichs-text-box name="thumbImagePath" [ngModel]="thumbImage" required style="display:none"></ichs-text-box>
              <ichs-text-box name="SnapshotPath" [ngModel]="snapshotImage" required style="display:none"></ichs-text-box>
            </form>

            <div>
              <button class="cp-btn cp-mail-btn" type="button" matStepperPrevious>Back</button>
              <button class="cp-btn cp-main-btn" type="button" matStepperNext (click)="model.validateAllFormFields(formGroupImages.form)">Next</button>
            </div>
          </mat-step>
          <mat-step label="Complete Details" [stepControl]="formGroupDetails">
            <form #formGroupDetails="ngForm" novalidate class="flex-basis-0 flex-grow-1">

              <div class="stepper-scrolling">
                <div class="display-flex break-la">
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                    <ichs-text-box name="name" [(ngModel)]="model.objModel.name" required maxlength="250" label="Name"></ichs-text-box>
                  </div>
                  <div class="flex-col-sep"></div>
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                    <ichs-text-box name="description" [(ngModel)]="model.objModel.description" maxlength="255" label="Description"></ichs-text-box>
                  </div>
                </div>
                <div class="display-flex break-la">
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                    <ichs-text-box name="subTitle" [(ngModel)]="model.objModel.subTitle" required maxlength="100" label="SubTitle"></ichs-text-box>
                  </div>
                  <div class="flex-col-sep"></div>
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                    <ichs-text-box name="keyWords" [(ngModel)]="model.objModel.keyWords" maxlength="255" label="KeyWords"></ichs-text-box>
                  </div>
                </div>
                <div class="display-flex break-la">
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                    <ichs-select name="exportOptions" [(ngModel)]="model.objModel.exportOptionsList" required label="Export Options"
                                 listId="108000000000004" multiple="true" hasSelectAll="true"></ichs-select>
                  </div>
                  <div class="flex-col-sep"></div>
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                    <ichs-select name="lineOfBusiness" [(ngModel)]="model.objModel.lineOfBusiness" required label="Line Of Business"
                                 listId="108000000000007"></ichs-select>
                  </div>
                </div>
                <div class="display-flex break-la">
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                    <ichs-select name="tag" [(ngModel)]="model.objModel.tag" label="Tag"
                                 [items]="tagItems"></ichs-select>
                  </div>
                  <div class="flex-col-sep"></div>
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                    <ichs-text-box name="tagTitle" [(ngModel)]="model.objModel.tagTitle" label="Tag Title" [required]="model.objModel.tag != null" maxlength="250"></ichs-text-box>
                  </div>
                </div>
                <div class="display-flex break-la">
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                    <ichs-check-box name="hasPhiData" [(ngModel)]="model.objModel.hasPhiData" label="Has PHI Data"></ichs-check-box>
                  </div>
                  <div class="flex-col-sep"></div>
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                    <ichs-check-box name="internalInd" [(ngModel)]="model.objModel.internalInd" label="Internal Report"></ichs-check-box>
                  </div>
                </div>
                <div class="display-flex break-la">
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                    <ichs-check-box name="hasHrData" [(ngModel)]="model.objModel.hasHrData" label="Has HR Data"></ichs-check-box>
                  </div>
                  <div class="flex-col-sep"></div>
                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                    <ichs-check-box name="hasFinanceData" [(ngModel)]="model.objModel.hasFinanceData" label="Has Finance Data"></ichs-check-box>
                  </div>
                </div>
              </div>
            </form>
            <div>
              <button class="cp-btn cp-mail-btn" type="button" matStepperPrevious>Back</button>
            </div>
          </mat-step>
        </mat-horizontal-stepper>

      </div>
    </mat-tab>
    <mat-tab label="Report Item Groups" [disabled]="model.objModelId == -1 ? true : false">
      <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab">
        <ichs-grid [grid-config]="gridConfigGroup" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
