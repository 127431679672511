import { Component, OnInit, ViewChild } from '@angular/core';
import { SharedFolderService, MedcorResponse, AppUserViewModel, SearchViewModel, SharedFolderUserPermissionViewModel, SharedFolderUserPermissionService, SharedFolderViewModel } from '../../../rest';
import { IchsCommandToolbarModel, IchsCommandToolbarDelegate } from '../../../controls/ichs-command-toolbar/ichs-command-toolbar.component';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MedcorAlertService } from '../../../services/medcor-alert.service';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { IchsComponentDialogComponent } from '../../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { UserPermissionComponent } from '../user-permission/user-permission.component';
import { first } from 'rxjs/operators';
import { IchsGridComponentConfig, IchsControlType, IchsGridActionsConfig } from '../../../controls/ichs-grid/grid-configs';
import { AppGenericLists } from '../../../app.general.lists';

@Component({
  selector: 'shared-folders-maintenance',
  templateUrl: './shared-folders-maintenance.component.html',
  styleUrls: ['./shared-folders-maintenance.component.css'],
  host: {
    class: "fill-parent"
  }
})
export class SharedFoldersMaintenanceComponent implements OnInit, IchsCommandToolbarDelegate<SharedFolderViewModel> {

  @ViewChild('form', { static: true }) ngForm: NgForm;  // 1) bind the Form From view 
  model: IchsCommandToolbarModel<SharedFolderViewModel>;  // 2) define Command Toolbar model and use model.objModel to bing the FORM UI and use model as IchsCommandToolbar [model]
  gridConfigAppUser: IchsGridComponentConfig<SharedFolderUserPermissionViewModel>;

  /** shared-folders-maintenance ctor */
  constructor(
    private location: Location,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private alertService: MedcorAlertService,
    private sharedFolderService: SharedFolderService,
    private sharedFolderUserPermissionService: SharedFolderUserPermissionService
  ) {
    this.model = new IchsCommandToolbarModel<SharedFolderViewModel>(location, router, "/shared-folder-maintenance", "Shared Folder", this, dialog, alertService); // 3) Create Command Toolbar Model 
    this.gridConfigAppUser = this.defineAppUserGrid();
  }

  /** shared-folders-maintenance OnInit */
  ngOnInit() {
    this.model.init(this.route); // 4) Initialize The Model
    this.model.hasVoid = false;
    this.model.hasVoidRecover = false;
  }

  // 5) implement IchsCommandToolbarDelegate
  canDeactivate(): boolean | Observable<boolean> {
    return this.model.confirmCanDeactivate();
  }

  getLoadObjModelObservable(id: number) {
    return this.sharedFolderService.get(id);
  }

  getSaveObservable(objModel: SharedFolderViewModel): Observable<MedcorResponse<SharedFolderViewModel>> {
    return this.sharedFolderService.post(objModel);
  }

  get folderId(): number { return this.model.objModelId }

  /**
   * Configure Grid
   */
  private defineAppUserGrid(): IchsGridComponentConfig<SharedFolderUserPermissionViewModel> {
    let configs: IchsGridComponentConfig<SharedFolderUserPermissionViewModel> = {
      primaryId: "id",
      defaultOrder: "email",
      title: "Allowed Users",
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: true,
      hasVoid: false,
      hasActionColumn: true,
      entityController: null,
      entityDataSource: (filter: SearchViewModel) => this.sharedFolderUserPermissionService.searchUserPermissions(this.folderId, filter),
      customNewAction: new IchsGridActionsConfig({
        title: "Add Existing",
        function: () => {
          let dialogRef: MatDialogRef<IchsComponentDialogComponent> = this.dialog.open(IchsComponentDialogComponent, {
            data: {
              component: UserPermissionComponent, title: "Add Existing User", hideCloseButton: true, extraData: this.folderId
            },
            width: "800px"
          });
          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.gridConfigAppUser.refreshGrid!(0);
            }
          });
        },
      }),
      detailAction: new IchsGridActionsConfig({
        title: "Modify User Permission",
        function: (obj: SharedFolderUserPermissionViewModel) => {
          let dialogRef: MatDialogRef<IchsComponentDialogComponent> = this.dialog.open(IchsComponentDialogComponent, {
            data: {
              component: UserPermissionComponent, title: "Modify User Permission", hideCloseButton: true, extraData: obj
            },
            width: "400px"
          });
          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.gridConfigAppUser.refreshGrid!(0);
            }
          });
        },
      }),
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: AppUserViewModel) => {
          if (!deletedObj.id) {
            return;
          }

          this.sharedFolderUserPermissionService._delete(deletedObj.id, deletedObj.version).pipe(first()).subscribe(result => {
            if (result) {
              this.gridConfigAppUser.refreshGrid!(this.gridConfigAppUser.getPage!());
            }
          });
        }
      },
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Permission",
          propertyName: "permission",
          sortable: true,
          searchable: true,
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.SharedFolderPermission,
          width: 200,
        }
      ],
    }
    return configs;
  }
}
