import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectionTreeViewModel } from '../../../rest';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'ichs-tree-item',
  templateUrl: './ichs-tree-item.component.html',
  styleUrls: ['./ichs-tree-item.component.css']
})
export class IchsTreeItemComponent implements OnInit {

  @Input() opened: boolean = false;
  @Input() node: SelectionTreeViewModel;
  @Output() nodeSelectedEvent = new EventEmitter<SelectionTreeViewModel>();
  @Output() nodeOpened = new EventEmitter<SelectionTreeViewModel>();
  @Input() popUpMode: boolean = false;

  ngOnInit(): void {
  }

  childNodeOpened(node: SelectionTreeViewModel) {
    this.nodeOpened.emit(node);
  }

  toggleTreeItem() {
    if (this.popUpMode) {
      this.nodeOpened.emit(this.node);
      return;
    }
    this.opened = !this.opened;
  }
  nodeChecked(change: MatCheckboxChange) {
    this.node.partialSelection = false;
    this.node.selected = change.checked;
    this.nodeSelected(this.node);
  }

  nodeSelected(node: SelectionTreeViewModel) {
    this.nodeSelectedEvent.emit(node);
  }

  indeterminateChange(val: boolean | undefined) {
    if (val != undefined) {
      this.node.partialSelection = val;
    }
  }

}

