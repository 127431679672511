import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { IchsComponentDialogComponent } from 'src/app/controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { IchsControlType, IchsGridActionsConfig, IchsGridComponentConfig } from '../../controls/ichs-grid/grid-configs';
import { SearchViewModel, ServiceRequestViewModel, ServiceRequestService } from '../../rest';
import { NewServiceRequestComponent } from '../new-service-request/new-service-request.component';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { GeneralVariablesService } from '../../services/general-variables.service';

@Component({
  selector: 'service-request-list',
  templateUrl: './service-request-list.component.html',
  styleUrls: ['./service-request-list.component.css']
})
/** service-request-list component*/
export class ServiceRequestListComponent implements OnInit {
  gridConfig: IchsGridComponentConfig<ServiceRequestViewModel>;
  requestTypeId: string;
  sub: Subscription;

  /** request-form ctor */
  constructor(
    private requestFormService: ServiceRequestService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private generalVariables: GeneralVariablesService,
    private serviceRequestService: ServiceRequestService,
  ) {
    this.gridConfig = this.defineGrid();

    this.serviceRequestService.getRequestTypesAsSelectionList().pipe(first()).subscribe(res => {
      this.gridConfig.headers[1].listData = res.result;
    });
  }

  ngOnInit() {
    this.sub = this.route
      .queryParams
      .subscribe(params => {
        this.requestTypeId = params['typeId'];
        if (!this.generalVariables.userImpersonationInfo.impersonatorRoleInd) {
          setTimeout(() => this.openNewDialog());
        }
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private defineGrid(): IchsGridComponentConfig<ServiceRequestViewModel> {
    let configs: IchsGridComponentConfig<ServiceRequestViewModel> = {
      primaryId: "id",
      defaultOrder: "timeStampCreated",
      defaultOrderType: "desc",
      title: "",
      entityController: "service-request",
      entityDataSource: (filter: SearchViewModel) => this.requestFormService.searchUserRequests(filter),
      hasVoid: false,
      hasNew: false,
      hasDetails: false,
      customNewAction: (!this.generalVariables.userImpersonationInfo.impersonatorRoleInd ? new IchsGridActionsConfig({
        function: () => {
          this.requestTypeId = null;
          this.openNewDialog();
        },
        title: "Start New Request",
      }) : null),
      headers: [
        {
          headerTitle: "Request Date",
          propertyName: "timeStampCreated",
          sortable: true,
          searchable: true,
          controlType: IchsControlType.Date,
          dateFormat: "MM/dd/yyyy",
          width: 150
        },
        {
          headerTitle: "Service Request Type",
          propertyName: "requestType",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.StaticSelect,
          width: 200,
          hasSearch: true,
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        }
      ]
    }
    return configs;
  }

  openNewDialog() {
    let dialogRef: MatDialogRef<IchsComponentDialogComponent> = this.dialog.open(IchsComponentDialogComponent, {
      width: '800px',
      data: {
        component: NewServiceRequestComponent,
        title: "Service Request",
        hideCloseButton: true,
        extraData: this.requestTypeId
      }
    });

    dialogRef.afterClosed().pipe(first()).subscribe((result: boolean) => {
      if (result) {
        this.gridConfig.refreshGrid!(0);
      }
    });
  }
}
