import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { EXTRA_DATA } from '../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { GeneralVariablesService } from '../../services/general-variables.service';

@Component({
  selector: 'user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.css']
})
export class UserAgreementComponent implements OnInit, OnDestroy {

  constructor(
    @Inject(EXTRA_DATA) public hideAcceptDeclineOptions: boolean,
    private generalVariables: GeneralVariablesService) {
  }

  ngOnInit() {
    this.generalVariables.userAgreementPopupOpened = true;
  }

  ngOnDestroy() {
    this.generalVariables.userAgreementPopupOpened = false;
  }
}
