<div class="display-flex flex-basis-0 flex-grow-1 flex-col">
    <div class="cont-padding border-bottom">
        <ichs-command-toolbar [model]="model" class="display-flex flex-col"></ichs-command-toolbar>
    </div>

    <mat-tab-group mat-align-tabs="start"  mat-stretch-tabs="false" [(selectedIndex)]="model.selectedTab" class="flex-grow-1">
        <mat-tab label="Virtual Company Details">
            <form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
                <ichs-collapsable-panel panelTitle="Virtual Company Details">
                    <div class="collapsableBody">
                        <div class="display-flex break-la">
                            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                                <ichs-text-box type="text" name="name" [(ngModel)]="model.objModel.name" required
                                    label="Name" maxlength="150"></ichs-text-box>
                            </div>
                            <div class="flex-col-sep"></div>
                            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                                <ichs-text-box type="text" name="description" [(ngModel)]="model.objModel.description"
                                    label="Description" maxlength="255"></ichs-text-box>
                            </div>
                        </div>
                    </div>
                </ichs-collapsable-panel>
            </form>
        </mat-tab>
        <mat-tab label="Sites" *ngIf="model.objModelId > -1">
            <div class="display-flex cont-padding grid-content-tab has-command">
                <div class="flex-basis-0 flex-grow-1 margin-right-def">
                    <ichs-grid [grid-config]="sitesGridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col">
                    </ichs-grid>
                </div>
                <div class="flex-basis-0 flex-grow-1">
                    <ichs-grid [grid-config]="notSelectSitesGridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col">
                    </ichs-grid>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
</div>
