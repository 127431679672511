import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from './medcor-alert.service';

//a guard to decides if a route can be activated.
@Injectable()
export class CanActivateGuard  {

    constructor(private _router: Router,
        private _location: Location,
        private alertService: MedcorAlertService, ) {

    }

    //Implement the canActivate fucntion to check if the ID value is valid or not.
    canActivate(route: ActivatedRouteSnapshot): boolean {
        const id: number = +route.params.id;

        // check if the Id is valid.
        if (isNaN(id) || (id != -1 && String(id).length != 15)) {

            this.alertService.addAlert({
                type: AlertMessageType.warning,
                title: 'Warning!',
                dismiss: AlertDismissType.controlled,
                messages: ["Invalid Id"]
            });

            //back to previous page.
            this._location.back();
            return false;
        }

        return true;
    }
}

//Can Component Deactivate interface
export interface CanComponentDeactivate {
    canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}


//a guard to decides if a route can be deactivated.
@Injectable()
export class CanDeactivateGuard  {
    // implement canDeactivate function to call custom compnent function.
    canDeactivate(component: CanComponentDeactivate) {

        if (component && component.canDeactivate) {
            return component.canDeactivate();
        }
        return true;
    }
}
