import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IchsGridComponentConfig, IchsControlType } from '../../controls/ichs-grid/grid-configs';
import { EXTRA_DATA, IchsComponentDialogComponent, DIALOG_REF } from '../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { MedcorAlertService, AlertDismissType, AlertMessageType } from '../../services/medcor-alert.service';
import { ValidationMessages } from '../../app.general.constants';
import { UserApplicationAccessViewModel, SearchViewModel, UserApplicationAccessService, ShortUserViewModel, SelectionItemViewModel } from '../../rest';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { IchsGridDialogComponent } from '../../controls/ichs-grid-dialog/ichs-grid-dialog.component';
import { DynamicInstanceViewModel } from '../../rest/model/dynamicInstanceViewModel';
import { ScreeningService } from '../../rest/api/screening.service';
import { Observable } from 'rxjs';
import { MedcorResponse } from '../../rest/model/medcorResponse';
import { PaginatedListViewModel } from '../../rest/model/paginatedListViewModel';

@Component({
    selector: 'user-application-access',
    templateUrl: './user-application-access.component.html',
    styleUrls: ['./user-application-access.component.css']
})
export class UserApplicationAccessComponent implements OnInit {
    userApplicationAccess: UserApplicationAccessViewModel = <UserApplicationAccessViewModel>{
        entityIds: [],
    };
    userAdvSearchGridConfig: IchsGridComponentConfig<ShortUserViewModel>;
    isNew: boolean;
    associateUserToApplication: boolean;
    associateApplicationToUser: boolean;

    applicationNamesList: SelectionItemViewModel[] = [];
    @ViewChild("form") ngForm: NgForm;

    entitiesGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;

    constructor(
        private userApplicationAccessService: UserApplicationAccessService,
        private alertService: MedcorAlertService,
        private dialog: MatDialog,
        private screeningService: ScreeningService,
        @Inject(EXTRA_DATA) private extraData: UserApplicationAccessViewModel,
        @Inject(DIALOG_REF) private dialogRef: MatDialogRef<IchsComponentDialogComponent>,
    ) {
    }

    ngOnInit() {
        Object.assign(this.userApplicationAccess, this.extraData);

        // if add, then it is either associate app to user (from Application Users menu) or associate user to app (from Applications Access menu)
        this.associateUserToApplication = !this.userApplicationAccess.userId;
        this.associateApplicationToUser = !this.userApplicationAccess.applicationId;

        if (this.associateApplicationToUser) {
            this.userApplicationAccessService.getApplicationsUserDoesNotHaveAccessTo(this.userApplicationAccess.userId)
                .pipe(first())
                .subscribe(resp => {
                    this.applicationNamesList = resp.result;
                });
        }

        let isNew = !this.userApplicationAccess.id;
        if (!isNew) {
            this.userApplicationAccessService.getUserApplicationAccess(this.userApplicationAccess.id)
                .pipe(first())
                .subscribe(resp => {
                    this.userApplicationAccess = resp.result;
                });
        }

        this.userAdvSearchGridConfig = this.defineUserAdvSearchGrid();
    }

    selectEntitiesGrid() {
        this.entitiesGridConfig = this.defineSelectEntitiesGrid();
        this.dialog.open(IchsGridDialogComponent, {
            width: '1024px',
            height: '800px',
            data: {
                config: this.entitiesGridConfig,
                title: "Selected Entities"
            }
        });
    }

    save() {
        this.validateAllFormFields(this.ngForm.form); // validate the form and show errors
        if (!this.ngForm.valid) {   // check if form is valid 
            this.alertService.addAlert({
                type: AlertMessageType.warning,
                title: 'Warning!',
                dismiss: AlertDismissType.controlled,
                messages: [ValidationMessages.INVALID_FORM]
            });
            return;
        }

        if (this.userApplicationAccess.expirationDate) {
            this.userApplicationAccess.expirationDate = moment(this.userApplicationAccess.expirationDate).hour(12).toDate();
        }

        this.userApplicationAccessService.post(this.userApplicationAccess).pipe(first()).subscribe(() => {
            this.dialogRef.close(true);
        });
    }

    private defineUserAdvSearchGrid(): IchsGridComponentConfig<ShortUserViewModel> {
        let configs: IchsGridComponentConfig<ShortUserViewModel> = {
            primaryId: "id",
            defaultOrder: "email",
            title: "",
            hasVoid: false,
            hasActionColumn: false,
            hasNew: false,
            entityController: null,
            multiSelectConfig: { allowSelectObjects: true },
            entityDataSource: (filter: SearchViewModel) => this.userApplicationAccessService.searchUsersWithoutAccessToApplication(this.userApplicationAccess.applicationId, filter),
            headers: [
                {
                    headerTitle: "Email",
                    propertyName: "email",
                    searchable: true,
                    sortable: true,
                    controlType: IchsControlType.Textfield
                }
            ]
        }
        return configs;
    }

    private validateAllFormFields(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched();
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    private defineSelectEntitiesGrid() {
        let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
            primaryId: "instanceId",
            defaultOrder: "name",
            hasVoid: false,
            hasActionColumn: true,
            hasNew: false,
            pageSize: 1000,
            multiSelectConfig: { allowSelectObjects: true },
            entityDataSource: (filter: SearchViewModel) => this.getSearchSelectedEntitiesFunction(this.userApplicationAccess.entityIds, filter),
            secondAction: {
                icon: "assets/images/delete.svg",
                title: "Remove",
                function: (deletedObj: DynamicInstanceViewModel) => {
                    this.userApplicationAccess.entityIds = this.userApplicationAccess.entityIds.filter(obj => obj != deletedObj.instanceId);
                    this.entitiesGridConfig.refreshGrid(this.entitiesGridConfig.getPage());
                }
            },
            customNewAction2: {
                title: "Remove Selected",
                function: () => {
                    let removedObjects = this.entitiesGridConfig.multiSelectConfig.getSelectedObjects();
                    if (removedObjects && removedObjects.length > 0) {
                        this.userApplicationAccess.entityIds = this.userApplicationAccess.entityIds
                            .filter(obj => removedObjects.map(obj => obj.instanceId).indexOf(obj) == -1);

                        this.entitiesGridConfig.refreshGrid(this.entitiesGridConfig.getPage());
                    }
                }
            },
            hasDetails: false,
            addExistingConfig: {
                buttonText: "Add Existing",
                title: "Add Entities",
                gridConfig: this.defineMedfilesCompAddExistingGrid(),
                customAddExisting: (newIds: Array<string>) => {
                    this.userApplicationAccess.entityIds.push(...newIds);
                    return true;
                }
            },
            headers: [
                {
                    headerTitle: "Name",
                    propertyName: "name",
                    searchable: true,
                    sortable: true,
                    controlType: IchsControlType.Textfield,
                }
            ]
        }

        return configs;
    }

    private defineMedfilesCompAddExistingGrid(): IchsGridComponentConfig<DynamicInstanceViewModel> {
        let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
            primaryId: "instanceId",
            defaultOrder: "name",
            hasVoid: false,
            hasActionColumn: false,
            hasNew: false,
            pageSize: 1000,
            multiSelectConfig: { allowSelectObjects: true },
            entityDataSource: (filter: SearchViewModel) => this.getSearchNotSelectedEntitiesFunction(this.userApplicationAccess.entityIds, filter),
            headers: [
                {
                    headerTitle: "Name",
                    propertyName: "name",
                    searchable: true,
                    sortable: true,
                    controlType: IchsControlType.Textfield
                }
            ]
        }
        return configs;
    }

    private getSearchSelectedEntitiesFunction(selectedEntityIds: string[], filter: SearchViewModel): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>> | PaginatedListViewModel<DynamicInstanceViewModel> {
        return this.screeningService.searchSelectedClients(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, selectedEntityIds);
    }

    private getSearchNotSelectedEntitiesFunction(selectedEntityIds: string[], filter: SearchViewModel): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>> | PaginatedListViewModel<DynamicInstanceViewModel> {
        return this.screeningService.searchNotSelectedClients(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, selectedEntityIds);
    }
}
