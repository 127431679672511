<form #form="ngForm">
  <div *ngFor="let node of treeData,let i = index" class="display-flex">
    <div class="display-flex tree-margin-bottom">
      
      <div class="node-actions">
        <img *ngIf="!node.multiple" src="assets/images/add-button.svg" (click)="addNew(node)" class="business-btn" />
        <img src="assets/images/delete-button.svg" (click)="deleteNode(node)" class="business-btn" />
      </div>

      <div *ngIf="node.nodeType == 'LineOfBusiness' || node.nodeType == 'EntityType'" class="bl-control">
        <ichs-select name="lineOfBusinessSelect_{{i}}"
                     [placeholder]="dropDownPlaceholder"
                     [items]="listItems"
                     (selectionChanged)="selectedItemChanged($event, node)"
                     [(ngModel)]="node.nodeValue"
                     class="bl-control">
        </ichs-select>
      </div>

      <div *ngIf="node.multiple">
        <div class="display-flex flex-items-centered">
          <a class="btn btn-tree flex-cell" (click)="manageInstancesGrid()">
            Manage {{addExistingPlaceholder}}: ({{selectedItems}} Selected)
          </a>
          <input type="text" [(ngModel)]="node.nodeValues" name="HiddenManageSelect_{{i}}" autocomplete="off"
            style="display:none" [required]="!node.allSelected" />
          <div *ngIf="node.nodeValues.length == 0 && !node.allSelected" title="Required" class="flex-cell">
            <img src="assets/images/information.svg" class="validation-icon" />
          </div>
        </div>
      </div>

      <div *ngIf="node.nodeType == 'Payor'">
        <ichs-advance-search [advanceSearchGridConfig]="payorAdvSearchGridConfig" [(ngModel)]="node.nodeValue" required
          placeholder="Manage Payors" name="PayorAdvSearch_{{i}}" selectedTextAttribute="name"
          selectedValueAttribute="instanceId" (advSearchChanged)="payorChanged($event, node)"
          [populateSource]="payorNameFunction" class="bl-control">
        </ichs-advance-search>
      </div>

      <div *ngIf="node.nodeType == 'Installation'">
        <ichs-advance-search [advanceSearchGridConfig]="instAdvSearchGridConfig" [(ngModel)]="node.nodeValue" required
          placeholder="Manage Installations" name="InstAdvSearch_{{i}}" selectedTextAttribute="name"
          selectedValueAttribute="instanceId" (advSearchChanged)="installationChanged($event, node)"
          [populateSource]="instNameFunction" class="bl-control">
        </ichs-advance-search>
      </div>

      <div *ngIf="node.nodeType == 'Company'">
        <ichs-advance-search [advanceSearchGridConfig]="medfilesCompAdvSearchGridConfig" [(ngModel)]="node.nodeValue"
          required placeholder="Manage Companies" name="MedfilesCompAdvSearch_{{i}}" selectedTextAttribute="name"
          selectedValueAttribute="instanceId" (advSearchChanged)="medfilesCompanyChanged($event, node)"
          [populateSource]="medfilesCompNameFunction" class="bl-control">
        </ichs-advance-search>
      </div>

      <div *ngIf="node.nodeType == 'Practice'" class="display-flex">
        <div class="flex-grow-1">
          <ichs-advance-search [advanceSearchGridConfig]="practiceAdvSearchGridConfig" [(ngModel)]="node.nodeValue" required
            placeholder="Manage Practices" name="PracticeAdvSearch_{{i}}" selectedTextAttribute="name"
            selectedValueAttribute="instanceId" (advSearchChanged)="practiceChanged($event, node)"
            [populateSource]="practiceNameFunction" class="bl-control">
          </ichs-advance-search>
        </div>

        <div class="margin-left-def">
          <ichs-check-box [label]="node.allSelected ? 'All Locations Selected' : null" [title]="node.allSelected ? 'De-select All Locations' : 'Select All Locations'"
                          name="AllPracticeLocations_{{i}}" [(ngModel)]="node.allSelected"
                          (ngModelChange)="allPracticeLocationsCheckboxChanged(node)">
          </ichs-check-box>
        </div>
      </div>

      <div *ngIf="node.nodeType == 'ScreeningClient'" class="display-flex">
        <div class="flex-grow-1">
          <ichs-advance-search [advanceSearchGridConfig]="screeningClientAdvSearchGridConfig" [(ngModel)]="node.nodeValue"
                               required placeholder="Manage Clients" name="ScreeningClientAdvSearch_{{i}}" selectedTextAttribute="name"
                               selectedValueAttribute="instanceId" (advSearchChanged)="node.allSelected ? null : screeningClientChanged($event, node)"
                               [populateSource]="screeningClientNameFunction" class="bl-control">
          </ichs-advance-search>
        </div>

        <div class="margin-left-def">
          <ichs-check-box [label]="node.allSelected ? 'All Locations Selected' : null" [title]="node.allSelected ? 'De-select All Locations' : 'Select All Locations'"
                          name="AllClientLocations_{{i}}" [(ngModel)]="node.allSelected"
                          (ngModelChange)="allClientLocationsCheckboxChanged(node)">
          </ichs-check-box>
        </div>
      </div>

      <div *ngIf="node.nodeType == 'ScreeningLocation'">
        <ichs-advance-search [advanceSearchGridConfig]="screeningLocationAdvSearchGridConfig" [(ngModel)]="node.nodeValue"
          required placeholder="Manage Locations" name="ScreeningLocationAdvSearch_{{i}}" selectedTextAttribute="name"
          selectedValueAttribute="instanceId" (advSearchChanged)="screeningLocationChanged($event, node)"
          [populateSource]="screeningLocationNameFunction" class="bl-control">
        </ichs-advance-search>
      </div>
    </div>

    <div *ngIf="node.nodeType == 'OshaCompany'" class="display-flex">
      <div class="flex-grow-1">
        <ichs-advance-search [advanceSearchGridConfig]="oshaCompanyAdvSearchGridConfig" [(ngModel)]="node.nodeValue"
          required placeholder="Manage Companies" name="OshaCompanyAdvSearch_{{i}}" selectedTextAttribute="name"
          selectedValueAttribute="instanceId" (advSearchChanged)="oshaCompanyChanged($event, node)"
          [populateSource]="oshaCompanyNameFunction" class="bl-control">
        </ichs-advance-search>
      </div>

      <div class="margin-left-def">
        <ichs-check-box [label]="node.allSelected ? 'All Locations Selected' : null"
          [title]="node.allSelected ? 'De-select All Locations' : 'Select All Locations'" name="AllOshaLocations_{{i}}"
          [(ngModel)]="node.allSelected" (ngModelChange)="allOshaLocationsCheckboxChanged(node)">
        </ichs-check-box>
      </div>
    </div>

    <line-of-business-tree [treeData]="node.subNodes"></line-of-business-tree>
  </div>
</form>
