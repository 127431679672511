import { SelectionItemViewModel } from '../../rest/index';
import * as moment from 'moment';
import { Injectable } from "@angular/core";
import { GeneralVariablesService } from 'src/app/services/general-variables.service';

// Shared report and dashboard Filters Dialog component
@Injectable()
export class SharedFiltersHelper {

  constructor(private generalVariables: GeneralVariablesService) { }

  //Build date ranges filters data
  public BuildDateRanges(): Array<SelectionItemViewModel> {

    return [
      { selectionValue: DateRangeEnum.Last24Hours, selectionText: DateRangeEnum.Last24Hours },
      { selectionValue: DateRangeEnum.Last7Days, selectionText: DateRangeEnum.Last7Days },
      { selectionValue: DateRangeEnum.LastMonth, selectionText: DateRangeEnum.LastMonth },
      { selectionValue: DateRangeEnum.LastQuarter, selectionText: DateRangeEnum.LastQuarter },
      { selectionValue: DateRangeEnum.LastYear, selectionText: DateRangeEnum.LastYear },
      { selectionValue: DateRangeEnum.YearToDate, selectionText: DateRangeEnum.YearToDate },
      { selectionValue: DateRangeEnum.SinceInception, selectionText: DateRangeEnum.SinceInception },
    ];
  }

  //Populated start and end date filters
  public populateDateFilters(dateRange: string, effictiveDate: Date = new Date): any {
    var todayDate = new Date(effictiveDate);
    let startDate = new Date(effictiveDate);
    let endDate = new Date(effictiveDate);

    var fiscalYearStartingDate = this.generalVariables.userFiscalYearSettings.startingDate;
    var fiscalYearStartingMonth = this.generalVariables.userFiscalYearSettings.startingMonth;

    let month = endDate.getMonth();
    let year = endDate.getFullYear();

    switch (dateRange) {
      case DateRangeEnum.Last24Hours:
        startDate.setDate(startDate.getDate() - 1);
        endDate.setDate(endDate.getDate() - 1);
        break;
      case DateRangeEnum.Last7Days:
        startDate = new Date(year, month, startDate.getDate() - 7);
        endDate = new Date(year, month, endDate.getDate() - 1);
        break;
      case DateRangeEnum.LastMonth:
        startDate = new Date(year, month - 1, 1); // first day of previous month
        endDate = new Date(year, month, 0); // last day of previous month
        break;
      case DateRangeEnum.LastQuarter:
        let firstMonthInPreviousQuarter = (Math.floor((month - fiscalYearStartingMonth % 3) / 3) - 1) * 3 + fiscalYearStartingMonth % 3;
        startDate = new Date(year, firstMonthInPreviousQuarter, fiscalYearStartingDate);
        endDate = new Date(year, firstMonthInPreviousQuarter + 3, fiscalYearStartingDate - 1);

        if (endDate >= todayDate) {
          startDate = new Date(year, firstMonthInPreviousQuarter - 3, fiscalYearStartingDate);
          endDate = new Date(year, firstMonthInPreviousQuarter, fiscalYearStartingDate - 1);
        }
        break;
      case DateRangeEnum.YearToDate:
        startDate = new Date(year, fiscalYearStartingMonth, fiscalYearStartingDate);

        if (startDate > todayDate) {
          startDate.setFullYear(startDate.getFullYear() - 1);
        }
        // in case fiscal year starts today, then let YearToDate act like LastYear (per client's requirement)
        // goto case DateRangeEnum.LastYear, no break statement if startDate == todayDate
        if (startDate != todayDate) {
          break;
        }
      case DateRangeEnum.LastYear:
        endDate = new Date(year, fiscalYearStartingMonth, fiscalYearStartingDate - 1);
        if (endDate < todayDate) {
          startDate = new Date(year - 1, fiscalYearStartingMonth, fiscalYearStartingDate);
        }
        else {
          endDate = new Date(year - 1, fiscalYearStartingMonth, fiscalYearStartingDate - 1);
          startDate = new Date(year - 2, fiscalYearStartingMonth, fiscalYearStartingDate);
        }
        break;
      case DateRangeEnum.SinceInception:
        startDate = new Date(1980, 0, 1); // Jan, 1st 1980
        break;
      default:
        break;
    }

    return { startDate: moment(startDate).format('YYYY-MM-DD'), endDate: moment(endDate).format('YYYY-MM-DD') };
  }
}

export type DateRangeEnum = 'Last 24 Hours' | 'Last 7 Days' | 'Last Month' | 'Last Quarter' | 'Last Year' | 'Year To Date' | 'Since Inception';
export const DateRangeEnum = {
  Last24Hours: 'Last 24 Hours' as DateRangeEnum,
  Last7Days: 'Last 7 Days' as DateRangeEnum,
  LastMonth: 'Last Month' as DateRangeEnum,
  LastQuarter: 'Last Quarter' as DateRangeEnum,
  LastYear: 'Last Year' as DateRangeEnum,
  YearToDate: 'Year To Date' as DateRangeEnum,
  SinceInception: 'Since Inception' as DateRangeEnum,
}
