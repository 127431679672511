import { User, ChatAdapter, UserStatus, Message } from '../../ng-chat/ng-chat';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppUserViewModel, UserContactViewModel } from '../../rest/index';
import { MedcorSignalRService } from '../../services/medcor-signalr.service';
import { APISettings } from '../../app.general.constants';
import { MedcorUserInfoService } from '../../services/medcor-user-info.service';
import { switchMap } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs'
import { MedcorAlertService, AlertMessageType } from '../../services/medcor-alert.service';
@Injectable()
export class SignalRChatAdapter extends ChatAdapter {

  publicImageUrl: string = APISettings.API_FILES_PATH;
  userId = new Observable<number | null>();

  private _listOfFriends: User[] = [];
  private _newFriends: User[] = [];
  private _history: Message[] = [];

  constructor(
    private signalRService: MedcorSignalRService,
    private medcorUserInfoService: MedcorUserInfoService,
    private alertService: MedcorAlertService,
  ) {
    super();
    this.userId = signalRService.userIdObs;
    this.resolveUsers();
    this.resolveMessage();
    this.resolveUndeliveredMessage();
  }

  get combinedFriends() {
    let newFriends = this._newFriends.filter(nObj => this._listOfFriends.findIndex(obj => obj.id == nObj.id) == -1);
    newFriends.push(...this._listOfFriends);
    return newFriends;
  }

  /**
   * subscribe to group related users and check their status
   */
  private resolveUsers() {
    this.userId.pipe(switchMap(obj => {
      if (obj) {
        var users = this.medcorUserInfoService.relatedAppUsersObs;
        var active = this.signalRService.activeUsersObs;
        return combineLatest(users, active, (users, active) => {
          this._newFriends = this._newFriends.map(friend => {
            let isActive = active.findIndex(obj => obj.id == friend.id) > -1;
            friend.status = isActive ? UserStatus.Online : UserStatus.Offline;
            return friend;
          });

          if (!users) {
            return [];
          }

          return users.map(user => {
            let isActive = active.findIndex(activeUser => activeUser.id == user.id) > -1;
            var _user = <User>{
              id: user.id,
              displayName: user.name,
              avatar: this.getImageForUser(user),
              status: isActive ? UserStatus.Online : UserStatus.Offline
            }
            return _user;
          });
        })
      }
      return of([]);
    })).subscribe(obj => {
      this._listOfFriends = obj;
      this.onFriendsListChanged(this.combinedFriends);
    });
  }

  /**
   * catch chat messages 
   */
  private resolveMessage() {
    this.signalRService.chatMessageRecivedObs.subscribe(message => {
      let user = this.combinedFriends.find(user => user.id == message.fromId);
      if (!user) {
        user = <User>{
          id: message.fromId,
          displayName: message.displayName,
          status: UserStatus.Online,
        };

        this._newFriends.push(user);
        this.onFriendsListChanged(this.combinedFriends);
      }

      this.onMessageReceived(user, message);
    });
  }

  private resolveUndeliveredMessage() {
    this.signalRService.chatMessageNotDeliveredObs.subscribe(undeliveredMessage => {

      let messages = this._history.filter(msg =>
        msg.message == undeliveredMessage.message &&
        msg.fromId == undeliveredMessage.fromId &&
        msg.toId == undeliveredMessage.toId &&
        msg.timeStamp == undeliveredMessage.timeStamp);

      messages.forEach(msg => msg.isDelivered = false);

      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        messages: ["Both users must be online to chat!"],
      });
    });
  }

  chatWithUser(userContact: AppUserViewModel) {
    let user = this.combinedFriends.find(user => user.id == userContact.id);
    if (!user) {
      user = <User>{
        id: userContact.id,
        displayName: userContact.email,
        status: UserStatus.Online,
      }
      this._newFriends.push(user);
      this.onFriendsListChanged(this.combinedFriends);
    }
    this.openChatWindow(user);
  }

  /**
   * resolve User Image 
   * @param appUser
   */
  private getImageForUser(appUser: UserContactViewModel): string {
    if (appUser.appUserExtension && appUser.appUserExtension.userImageId) {
      return this.publicImageUrl + '/' + appUser.appUserExtension.userImageId.toString();
    }
    return '';
  }

  /**
   * Override ChatAdapter Methods
   */
  listFriends(): Observable<User[]> {
    return of(this.combinedFriends);
  }

  getMessageHistory(userId: any): Observable<Message[]> {
    let filteredHistory = this._history.filter(obj => obj.toId == userId || obj.fromId == userId);
    return of(filteredHistory);
  }

  sendMessage(message: Message): void {
    this._history.push(message);
    this.signalRService.sendChatMessage(message);
  }

  onMessageReceived(user: User, message: Message): void {
    this._history.push(message);
    super.onMessageReceived(user, message);
  }
}
