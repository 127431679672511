<div class="cont-padding">
  <div class="display-flex flex-wrap help-categories" *ngIf="generalVariables.clientDeviceInfo.isDesktop">
    <div class="help-cat-item display-flex flex-items-centered" [class.not-active]="selectedCategoryId > 0 && category.id != selectedCategoryId" *ngFor="let category of categoriesList,let i = index" (click)="toggleSelectCategory(category.id)">
      <div class="cat-img">
        <img [src]="category.iconPath" alt="">
      </div>
      <div class="cat-title">
        {{category.name}}
      </div>
    </div>
  </div>

  <div>
    <div class="cont-padding">

      <div class="display-flex break-la">
        <div class="display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
          <div>
            <ichs-select class="cat-selector" name="categoryId" [(ngModel)]="filterCategoryId" [items]="categoriesSelectionList" hideResetLabel="true" (selectionChanged)="categoryChanged()"></ichs-select>
          </div>

          <div class="qusetion-search flex-grow-1 flex-basis-0">
            <ichs-text-box initialFocus="true" name="search" [(ngModel)]="searchText" placeholder="Search Questions and Answers">
            </ichs-text-box>
          </div>
          <div title="Search" class="main-search-icon">
            <img src="assets/images/search.svg" />
          </div>
          <div style="align-self:flex-end;">
            <a (click)="clearFilter()" class="cp-btn cp-grid-head-btn margin-left-def" >
              Reset
            </a>
          </div>
        </div>
        <div class="flex-col-sep"></div>
        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">

        </div>
      </div>

      <mat-accordion class="margin-top-def" style="margin-top:16px; display:block;">
        <mat-expansion-panel *ngFor="let question of filteredQuestionList,let i = index">
          <mat-expansion-panel-header collapsedHeight="60px">
            <mat-panel-title>
              <div>
                <div class="question">{{question.question}}</div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="answer-cont">
            <p>{{question.answer}}</p>
            <a *ngIf="question.hyperlink" [href]="question.hyperlink.startsWith('http') ? question.hyperlink : '//' + question.hyperlink" target="_blank">{{question.hyperlink}}</a>
          </div>

          <mat-action-row *ngIf="!generalVariables.userImpersonationInfo.impersonatorRoleInd">
            <img *ngIf="!question.isUseFulAnswer" src="assets/images/like.svg" (click)="usefullAnswer(question,true)" title="Useful" class="like-img" style="cursor:pointer" />
            <img *ngIf="question.isUseFulAnswer" src="assets/images/likeselected.svg" title="Voted as Useful" class="like-img" />
          </mat-action-row>

        </mat-expansion-panel>
      </mat-accordion>
    </div>

  </div>
</div>
