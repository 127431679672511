<div class="tree-item" [style.display]="node.visible? 'block':'none'">
  <div class="display-flex flex-items-centered">
    <div [style.visibility]="(node.children && node.children.length > 0) || node.leafInd == false ?'visible':'hidden'" (click)="toggleTreeItem()"
      class="tree-triangle">
      <img [src]="opened?'assets/images/arrowDown.svg':'assets/images/arrowRight.svg'"/>
    </div>
    <div class='flex-cell input-cont margin-left-def'>
      <mat-checkbox [(ngModel)]="node.selected"
                    (change)="nodeChecked($event)"
                    [indeterminate]="node.partialSelection"
                    (indeterminateChange)="indeterminateChange($event)">
      </mat-checkbox>
    </div>
    <div class='flex-cell tree-item-label' (click)="(node.children && node.children.length > 0) || node.leafInd == false ? toggleTreeItem() : null">
      <label>{{node.label}}</label>
    </div>
  </div>

  <ng-container *ngIf="node.children && node.children.length>0 && opened">
    <ichs-tree-item (nodeSelectedEvent)="nodeSelected($event)" (nodeOpened)="childNodeOpened($event)" [popUpMode]="popUpMode" *ngFor="let child of node.children" [node]="child"></ichs-tree-item>
  </ng-container>
</div>
