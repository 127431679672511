import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MedcorAlertService, AlertMessageType } from '../../../services/medcor-alert.service';
import { IchsGridComponentConfig, IchsGridActionsConfig } from '../../ichs-grid/grid-configs';

@Component({
    selector: 'app-add-existing-popup',
    templateUrl: './add-existing-popup.component.html',
    styleUrls: ['./add-existing-popup.component.css']
})
export class AddExistingPopupComponent implements OnInit {

    gridConfig: IchsGridComponentConfig<any>;

    constructor(public dialogRef: MatDialogRef<AddExistingPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AddExistingPopupConfig<any>,
        private _alertService: MedcorAlertService) {
        this.gridConfig = data.gridConfig;
        if (data.singleSelect) {
            this.gridConfig.multiSelectConfig = undefined;
            this.gridConfig.detailAction = new IchsGridActionsConfig({
                icon: "assets/images/select.svg",
                function: (obj: any) => { this.returnSingleSelect(obj) }
            });
            this.gridConfig.hasActionColumn = true;
            this.gridConfig.hasDetails = true;
            this.gridConfig.hasVoid = false;
        }
    }

    ngOnInit() {
    }

    returnData() {
        if (this.data.returnObjects) {
            if (this.gridConfig.multiSelectConfig!.getSelectedObjects!().length > 0) {
                this.dialogRef.close(this.gridConfig.multiSelectConfig!.getSelectedObjects!());
                return;
            }
        } else {
            if (this.gridConfig.multiSelectConfig!.getSelected!().length > 0) {
                this.dialogRef.close(this.gridConfig.multiSelectConfig!.getSelected!());
                return;
            }
        }
        this._alertService.addAlert({ messages: ["No Selected Items!"], type: AlertMessageType.warning });
    }

    returnSingleSelect(selected: any) {
        if (this.data.returnObjects) {
            this.dialogRef.close([selected]);
            return;
        } else {
            this.dialogRef.close([selected[this.gridConfig.primaryId]]);
        }
    }

}

export class AddExistingPopupConfig<T> {
    title: string;
    okText?: string = "OK";
    cancelText?: string = "Cancel";
    gridConfig: IchsGridComponentConfig<T>;
    returnObjects?: boolean = false;
    singleSelect?: boolean = false;
    constructor(init?: Partial<AddExistingPopupConfig<T>>) {
        Object.assign(this, init);
    }
}
