import { Component, Input, OnInit, ViewChild, Inject, Optional, Injector, EventEmitter, Output, SimpleChanges, AfterViewChecked } from '@angular/core';
import { MedcorResponse } from '../../rest/index';
import { AddExistingPopupConfig, AddExistingPopupComponent } from '../ichs-dialog/add-existing-popup/add-existing-popup.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ControlBase } from '../control-base';
import { NgModel, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { Observable } from 'rxjs';
import { IchsGridComponentConfig } from '../ichs-grid/grid-configs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'ichs-advance-search',
  templateUrl: './ichs-advance-search.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: IchsAdvanceSearchComponent,
    multi: true,
  }],
  styleUrls: ['./ichs-advance-search.component.css'],
})
/** ichs-advance-search component*/
export class IchsAdvanceSearchComponent extends ControlBase<string> implements OnInit, AfterViewChecked {
  @Input() advanceSearchGridConfig: IchsGridComponentConfig<any>;
  @Input() populateSource: (recordId: string) => Observable<MedcorResponse<any>>;
  @Input() label: string;
  @Input() selectedTextAttribute: string;
  @Input() selectedValueAttribute: string;
  @Input() defaultPopulatedText: string;
  @Input() disabled: boolean;
  @Input() placeholder: string = "Select Item";
  @Input() addExistingGridTitle: string = "Select Item";
  @Input() addExistingGridCancelText: string = "Cancel";

  @Output() advSearchChanged: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild(NgModel, { static: true }) model: NgModel;

  public populatedInd: boolean = false;
  public selectedText: string;
  public advSearchIdentifier = `adv-search-${identifier++}`;

  /** ichs-advance-search ctor */
  constructor(
    private dialog: MatDialog,
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Inject(Injector) inj: Injector,
  ) {
    super(validators, inj);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  ngOnChanges() {
    this.selectedText = null;
  }

  ngAfterViewChecked() {
    if (this.model.value && this.value != "") {
      if (!this.populatedInd) {
        this.populatedInd = true;
        if (this.populateSource) {
          let dataResult = this.populateSource(this.value);
          if (dataResult instanceof Observable) {
            dataResult.pipe(first()).subscribe(response => {
              if (response.result) {
                this.selectedText = response.result[this.selectedTextAttribute];
              }
            });
          }
        }
        else if (this.defaultPopulatedText) {
          this.selectedText = this.defaultPopulatedText;
        }
      }
    }
    else {
      this.selectedText = null;
    }
  }

  // raise an event to the outer component.
  advSearchChangedEvent(event: string) {
    this.advSearchChanged.emit(event);
  }

  OpenGrid() {
    let dialogConfig: AddExistingPopupConfig<any> = new AddExistingPopupConfig({
      title: this.addExistingGridTitle,
      gridConfig: this.advanceSearchGridConfig,
      returnObjects: true,
      singleSelect: true,
      cancelText: this.addExistingGridCancelText,
    });
    let dialogRef: MatDialogRef<AddExistingPopupComponent, any[]> = this.dialog.open(AddExistingPopupComponent, {
      width: '1024px',
      data: dialogConfig
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        let selectedItem = result[0];
        this.selectedText = selectedItem[this.selectedTextAttribute].toString();
        this.model.control.setValue(selectedItem[this.selectedValueAttribute].toString());
        this.advSearchChanged.emit(this.model.value);
      }
    });
  }
}

let identifier = 0;