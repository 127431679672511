import { Component, OnInit, ViewChild } from '@angular/core';
import { IchsCommandToolbarModel, IchsCommandToolbarDelegate } from '../../../controls/ichs-command-toolbar/ichs-command-toolbar.component';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MedcorAlertService } from '../../../services/medcor-alert.service';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { IchsDialogComponent } from '../../../controls/ichs-dialog/ichs-dialog.component';
import { LineOfBusinessTreeComponent } from '../../line-of-business-tree/line-of-business-tree.component';
import { first, map } from 'rxjs/operators';
import { MedcorResponse } from '../../../rest/model/medcorResponse';
import { MedfilesVirtualCompanyViewModel, MedfilesVirtualCompanyService, VoidInfoViewModel } from 'src/app/rest';
import { IchsGridComponentConfig, IchsControlType } from 'src/app/controls/ichs-grid/grid-configs';
import { DynamicInstanceViewModel } from '../../../rest/model/dynamicInstanceViewModel';
import { SearchViewModel } from 'src/app/rest';

@Component({
  selector: 'medfiles-virtual-company',
  templateUrl: './medfiles-virtual-company.component.html',
  styleUrls: ['./medfiles-virtual-company.component.css']
})
export class MedfilesVirtualCompanyComponent implements OnInit, IchsCommandToolbarDelegate<MedfilesVirtualCompanyViewModel> {

  @ViewChild('form', { static: true }) ngForm: NgForm; // 1) bind the Form From view 
  model: IchsCommandToolbarModel<MedfilesVirtualCompanyViewModel>; // 2) define Command Toolbar model and use model.objModel to bing the FORM UI and use model as IchsCommandToolbar [model]
  @ViewChild(LineOfBusinessTreeComponent) child: LineOfBusinessTreeComponent;

  sitesGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;
  notSelectSitesGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;

  get virtualCompanyId(): number { return this.model.objModelId }

  constructor(
    private location: Location,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private alertService: MedcorAlertService,
    private medfilesVirtualCompanyService: MedfilesVirtualCompanyService,
  ) {
    this.model = new IchsCommandToolbarModel<MedfilesVirtualCompanyViewModel>(location, router, "/medfiles-virtual-company", "Medfiles Virtual Company", this, dialog, alertService); // 3) Create Command Toolbar Model 
    this.sitesGridConfig = this.defineSitesGrid();
    this.notSelectSitesGridConfig = this.addExistingSitesGridConfig();
  }

  ngOnInit() {
    this.model.init(this.route); // 4) Initialize The Model
  }

  // 5) implement IchsCommandToolbarDelegate .
  canDeactivate(): boolean | Observable<boolean> {
    // check if the user detail or LineOfBusiness component is dirty.
    if (this.ngForm.dirty) {
      let dialogRef: MatDialogRef<IchsDialogComponent, boolean> = this.dialog.open<IchsDialogComponent, any>(IchsDialogComponent, {
        width: '450px',
        data: { title: "Discard Changes ", message: "You will lose all changes in this page." }
      });
      return dialogRef.afterClosed().pipe(first(), map(result => {
        if (result && result == true) {
          return result;
        } else {
          return false;
        }
      }));
    }
    return true;
  }

  getLoadObjModelObservable(id: number) {
    return this.medfilesVirtualCompanyService.getById(id);
  }

  getSaveObservable(objModel: MedfilesVirtualCompanyViewModel): Observable<MedcorResponse<MedfilesVirtualCompanyViewModel>> {
    return this.medfilesVirtualCompanyService.post(objModel)
  }

  getVoidObservable(objVoidModel: VoidInfoViewModel): Observable<MedcorResponse<MedfilesVirtualCompanyViewModel>> {
    return this.medfilesVirtualCompanyService._void(objVoidModel)
  }

  getRecoverObservable(id: number): Observable<MedcorResponse<MedfilesVirtualCompanyViewModel>> {
    return this.medfilesVirtualCompanyService.recover(id);
  }

  private defineSitesGrid() {
    let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      title: "Selected Sites",
      primaryId: "instanceId",
      defaultOrder: "name",
      entityDataSource: (filter: SearchViewModel) => this.medfilesVirtualCompanyService.searchSelectedSites(this.virtualCompanyId, filter),
      multiSelectConfig: { allowSelectObjects: true },
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: true,
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Remove",
        function: (deletedObj: DynamicInstanceViewModel) => {
          if (!deletedObj.instanceId)
            return;

          this.medfilesVirtualCompanyService.deleteSiteFromVirtualCompany(this.virtualCompanyId, deletedObj.instanceId).pipe(first()).subscribe(result => {
            if (result) {
              this.sitesGridConfig.refreshGrid!(this.sitesGridConfig.getPage!());
              this.notSelectSitesGridConfig.refreshGrid!(this.notSelectSitesGridConfig.getPage!());
            }
          });
        }
      },
      customNewAction2: {
        title: "Remove Selected",
        function: () => {
          let siteIds = this.sitesGridConfig.multiSelectConfig.getSelected();
          if (siteIds && siteIds.length > 0) {
            this.medfilesVirtualCompanyService.deleteSitesFromVirtualCompany(this.virtualCompanyId, siteIds).pipe(first()).subscribe(() => {
              this.sitesGridConfig.refreshGrid!(this.sitesGridConfig.getPage!());
              this.notSelectSitesGridConfig.refreshGrid!(this.notSelectSitesGridConfig.getPage!());
            });
          }
        }
      },
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          sortable: true,
          searchable: true,
          controlType: IchsControlType.Textfield,
        }
      ],
    }
    return configs;
  }

  private addExistingSitesGridConfig(): IchsGridComponentConfig<DynamicInstanceViewModel> {
    let addExistingGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      title: "Available Sites",
      primaryId: "instanceId",
      defaultOrder: "name",
      multiSelectConfig: { allowSelectObjects: true },
      entityDataSource: (filter: SearchViewModel) => this.medfilesVirtualCompanyService.searchNotSelectedSites(this.virtualCompanyId, filter),
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: false,
      customNewAction2: {
        title: "Add Selected",
        function: () => {
          let siteIds = this.notSelectSitesGridConfig.multiSelectConfig.getSelected();
          if (siteIds && siteIds.length > 0) {
            this.medfilesVirtualCompanyService.addSitesToVirtualCompany(this.virtualCompanyId, siteIds).pipe(first()).subscribe(() => {
              this.notSelectSitesGridConfig.refreshGrid!(this.notSelectSitesGridConfig.getPage!());
              this.sitesGridConfig.refreshGrid!(this.sitesGridConfig.getPage!());
            });
          }
        }
      },
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          sortable: true,
          searchable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    };
    return addExistingGridConfig;
  }
}
