import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ichs-collapsable-panel',
  templateUrl: './ichs-collapsable-panel.component.html',
  styleUrls: ['./ichs-collapsable-panel.component.css']
})
export class IchsCollapsablePanelComponent implements OnInit {

    @Input("collapsed") collapsed: boolean = false;
    @Input("panelTitle") panelTitle: string;
    @Output("onCollapse") clicked: EventEmitter<boolean> = new EventEmitter();
  constructor() { }

  ngOnInit() {

  }

  collapse() {
      this.collapsed = !this.collapsed;
      this.clicked.emit(this.collapsed);
  }

}
