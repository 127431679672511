import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { SelectionItemViewModel, LineOfBusinessTreeViewModel, AfkamService, AppUserViewModel, SearchViewModel, AppUserService, MedcorResponse, PaginatedListViewModel, DynamicInstanceViewModel, NextgenService, ScreeningService } from '../../rest/index';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MedFilesService } from '../../rest/api/medFiles.service';
import { AppGenericLists } from '../../app.general.lists';
import { IchsGridComponentConfig, IchsControlType } from '../../controls/ichs-grid/grid-configs';
import { IchsGridDialogComponent } from '../../controls/ichs-grid-dialog/ichs-grid-dialog.component';
import { LineOfBusiness } from 'src/app/app.general.constants';
import { first } from 'rxjs/operators';
import { OshaReportableService } from 'src/app/rest';
import { Observable } from 'rxjs';

@Component({
  selector: 'line-of-business-tree',
  templateUrl: './line-of-business-tree.component.html',
  styleUrls: ['./line-of-business-tree.component.css']
})
export class LineOfBusinessTreeComponent implements OnChanges {
  @Input() treeData: LineOfBusinessTreeViewModel[];
  @ViewChild('form', { static: true }) ngForm: NgForm;
  @ViewChildren(LineOfBusinessTreeComponent) LineOfBusinessChildren: QueryList<LineOfBusinessTreeComponent>;
  public listItems: SelectionItemViewModel[];
  public dropDownPlaceholder: string;
  public addExistingPlaceholder: string;

  addExistingInstancesGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;
  instancesGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;

  payorAdvSearchGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;
  payorNameFunction: (recordId: string) => Observable<MedcorResponse<DynamicInstanceViewModel>>;
  instAdvSearchGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;
  instNameFunction: (recordId: string) => Observable<MedcorResponse<DynamicInstanceViewModel>>;
  medfilesCompAdvSearchGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;
  medfilesCompNameFunction: (recordId: string) => Observable<MedcorResponse<DynamicInstanceViewModel>>;
  practiceAdvSearchGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;
  practiceNameFunction: (recordId: string) => Observable<MedcorResponse<DynamicInstanceViewModel>>;
  screeningClientAdvSearchGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;
  screeningClientNameFunction: (recordId: string) => Observable<MedcorResponse<DynamicInstanceViewModel>>;
  screeningLocationAdvSearchGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;
  screeningLocationNameFunction: (recordId: string) => Observable<MedcorResponse<DynamicInstanceViewModel>>;
  oshaCompanyAdvSearchGridConfig: IchsGridComponentConfig<DynamicInstanceViewModel>;
  oshaCompanyNameFunction: (recordId: string) => Observable<MedcorResponse<DynamicInstanceViewModel>>;
  get selectedItems(): string { return (this.treeData[0].allSelected ? "All" : this.treeData[0].nodeValues!.length.toString()) }

  constructor(
    private dialog: MatDialog,
    private afkamService: AfkamService,
    private medfilesService: MedFilesService,
    private nextgenService: NextgenService,
    private screeningService: ScreeningService,
    private oshaReportableService: OshaReportableService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (this.treeData && this.treeData.length > 0) {
      var firstNode = this.treeData[0];
      if (firstNode.nodeType == NodeType.Payor) {
        this.payorAdvSearchGridConfig = this.definePayorAdvSearchGrid();
        this.payorNameFunction = this.definePopulatePayorAdvSearch();
      }
      else if (firstNode.nodeType == NodeType.Installation) {
        this.instAdvSearchGridConfig = this.defineInstAdvSearchGrid();
        this.instNameFunction = this.definePopulateInstAdvSearch();
      }
      else if (firstNode.nodeType == NodeType.Company) {
        this.medfilesCompAdvSearchGridConfig = this.defineMedfilesCompAdvSearchGrid(firstNode.parentValues!);
        this.medfilesCompNameFunction = this.definePopulateMedfilesCompAdvSearch();
      }
      else if (firstNode.nodeType == NodeType.Practice) {
        this.practiceAdvSearchGridConfig = this.definePracticeAdvSearchGrid();
        this.practiceNameFunction = this.definePopulatePracticeAdvSearch();
      }
      else if (firstNode.nodeType == NodeType.ScreeningClient) {
        this.screeningClientAdvSearchGridConfig = this.defineScreeningClientAdvSearchGrid();
        this.screeningClientNameFunction = this.definePopulateScreeningClientAdvSearch();
      }
      else if (firstNode.nodeType == NodeType.ScreeningLocation) {
        this.screeningLocationAdvSearchGridConfig = this.defineScreeningLocationAdvSearchGrid(firstNode.parentValues!);
        this.screeningLocationNameFunction = this.definePopulateScreeningLocationAdvSearch();
      }
      else if (firstNode.nodeType == NodeType.OshaCompany) {
        this.oshaCompanyAdvSearchGridConfig = this.defineOshaCompanyAdvSearchGrid();
        this.oshaCompanyNameFunction = this.definePopulateOshaCompanyAdvSearch();
      }
      else if (firstNode.nodeType == NodeType.LineOfBusiness || firstNode.nodeType == NodeType.EntityType) {
        this.getListItems(firstNode.nodeType!, firstNode.parentValues!);
        this.dropDownPlaceholder = this.getDropdownPlaceHolder(firstNode.nodeType!);
      }
      else {
        this.addExistingPlaceholder = this.getAddExistingPlaceholder(firstNode.nodeType!);
      }
    }
  }

  //add new branch.
  addNew(node: LineOfBusinessTreeViewModel) {
    this.treeData.push({
      nodeValue: "",
      appUserId: node.appUserId,
      groupId: node.groupId,
      parentValues: node.parentValues,
      nodeType: node.nodeType,
      subNodes: [],
    });
  }

  //delete branch.
  deleteNode(node: LineOfBusinessTreeViewModel) {
    if (node.nodeValues)
      node.nodeValues = [];
    else if (node.nodeType == NodeType.LineOfBusiness && this.treeData.length == 1) {
      node.nodeValue = "";
      node.subNodes = [];
    }
    else {
      var index = this.treeData.indexOf(node);
      if (index > -1) {
        if (this.treeData.length == 1) {
          this.treeData[0].nodeValue = "";
          this.treeData[0].subNodes = [];
        }
        else
          this.treeData.splice(index, 1);
      }
    }
  }

  //selected payor changed.
  payorChanged(nodeValue: string, node: LineOfBusinessTreeViewModel) {
    var subnodesParents: string[] = [];

    if (node.parentValues != null) {
      subnodesParents = node.parentValues.slice(0); // copy without referencing.
      subnodesParents.push(String(nodeValue));
    }
    node.subNodes = [{
      nodeType: NodeType.EntityType, appUserId: node.appUserId, groupId: node.groupId, parentValues: subnodesParents, subNodes: []
    }];
  }

  installationChanged(nodeValue: string, node: LineOfBusinessTreeViewModel) {
    var subnodesParents: string[] = [];

    if (node.parentValues != null) {
      subnodesParents = node.parentValues.slice(0); // copy without referencing.
      subnodesParents.push(String(nodeValue));
    }
    node.subNodes = [{
      nodeType: NodeType.Company, appUserId: node.appUserId, groupId: node.groupId, parentValues: subnodesParents, subNodes: []
    }];
  }

  medfilesCompanyChanged(nodeValue: string, node: LineOfBusinessTreeViewModel) {
    var subnodesParents: string[] = [];

    if (node.parentValues != null) {
      subnodesParents = node.parentValues.slice(0); // copy without referencing.
      subnodesParents.push(String(nodeValue));
    }
    node.subNodes = [{
      nodeType: NodeType.Site, appUserId: node.appUserId, groupId: node.groupId, parentValues: subnodesParents, multiple: true, nodeValues: [], subNodes: []
    }];
  }

  practiceChanged(nodeValue: string, node: LineOfBusinessTreeViewModel) {
    var subnodesParents: string[] = [];

    if (node.parentValues != null) {
      subnodesParents = node.parentValues.slice(0); // copy without referencing.
      subnodesParents.push(String(nodeValue));
    }
    node.subNodes = [{
      nodeType: NodeType.PracticeLocation, appUserId: node.appUserId, groupId: node.groupId, parentValues: subnodesParents, multiple: true, nodeValues: [], subNodes: []
    }];
  }

  screeningClientChanged(nodeValue: string, node: LineOfBusinessTreeViewModel) {
    var subnodesParents: string[] = [];

    if (node.parentValues != null) {
      subnodesParents = node.parentValues.slice(0); // copy without referencing.
      subnodesParents.push(String(nodeValue));
    }

    node.subNodes = [{
      nodeType: NodeType.ScreeningLocation, appUserId: node.appUserId, groupId: node.groupId, parentValues: subnodesParents, subNodes: []
    }];
  }

  screeningLocationChanged(nodeValue: string, node: LineOfBusinessTreeViewModel) {

    this.screeningService.locationHasSubLevels(nodeValue).pipe(first()).subscribe(resp => {
      if (resp.result) {
        var subnodesParents: string[] = [];

        if (node.parentValues != null) {
          subnodesParents = node.parentValues.slice(0); // copy without referencing.
          subnodesParents.push(String(nodeValue));
        }

        node.subNodes = [{
          nodeType: NodeType.ScreeningLocationSubLevel, appUserId: node.appUserId, groupId: node.groupId, parentValues: subnodesParents, multiple: true, nodeValues: [], subNodes: []
        }];
      }
    })
  }

  oshaCompanyChanged(nodeValue: string, node: LineOfBusinessTreeViewModel) {
    var subnodesParents: string[] = [];

    if (node.parentValues != null) {
      subnodesParents = node.parentValues.slice(0); // copy without referencing.
      subnodesParents.push(String(nodeValue));
    }
    node.subNodes = [{
      nodeType: NodeType.OshaLocation, appUserId: node.appUserId, groupId: node.groupId, parentValues: subnodesParents, multiple: true, nodeValues: [], subNodes: []
    }];
  }

  allPracticeLocationsCheckboxChanged(node: LineOfBusinessTreeViewModel) {
    if (!node.allSelected) {
      this.practiceChanged(node.nodeValue, node);
    }
    else {
      node.subNodes = [];
    }
  }

  allClientLocationsCheckboxChanged(node: LineOfBusinessTreeViewModel) {
    if (!node.allSelected && node.nodeValue) {
      this.screeningClientChanged(node.nodeValue, node);
    }
    else {
      node.subNodes = [];
    }
  }

  allOshaLocationsCheckboxChanged(node: LineOfBusinessTreeViewModel) {
    if (!node.allSelected && node.nodeValue) {
      this.oshaCompanyChanged(node.nodeValue, node);
    }
    else {
      node.subNodes = [];
    }
  }

  //selected value changed.
  selectedItemChanged(event: MatSelectChange, node: LineOfBusinessTreeViewModel) {
    var subnodesParents: string[] = [];

    if (node.parentValues != null) {
      subnodesParents = node.parentValues.slice(0); // copy without referencing.
      subnodesParents.push(String(event.value));
    }

    switch (node.nodeType) {
      case NodeType.LineOfBusiness:
        switch (event.value) {
          case LineOfBusiness.TRIAGE:
            node.subNodes = [{
              nodeValue: "", nodeType: NodeType.Payor, appUserId: node.appUserId, groupId: node.groupId, parentValues: subnodesParents, subNodes: []
            }];
            break;

          case LineOfBusiness.OCCUPATIONAL_HEALTH:
            node.subNodes = [{
              nodeValue: "", nodeType: NodeType.Installation, appUserId: node.appUserId, groupId: node.groupId, parentValues: subnodesParents, subNodes: []
            }];
            break;

          case LineOfBusiness.ADVANCED_PRACTICE:
            node.subNodes = [{
              nodeValue: "", nodeType: NodeType.Practice, appUserId: node.appUserId, groupId: node.groupId, parentValues: subnodesParents, subNodes: []
            }];
            break;

          case LineOfBusiness.SCREENING:
            node.subNodes = [{
              nodeValue: "", nodeType: NodeType.ScreeningClient, appUserId: node.appUserId, groupId: node.groupId, parentValues: subnodesParents, subNodes: []
            }];
            break;

          case LineOfBusiness.OSHA_REPORTABLE:
            node.subNodes = [{
              nodeValue: "", nodeType: NodeType.OshaCompany, appUserId: node.appUserId, groupId: node.groupId, parentValues: subnodesParents, subNodes: []
            }];
            break;

          default:
            node.subNodes = [];
        }
        break;

      case NodeType.EntityType:
        node.subNodes = [{
          nodeType: NodeType.Instance, appUserId: node.appUserId, groupId: node.groupId, parentValues: subnodesParents, multiple: true, nodeValues: [], subNodes: []
        }];
        break;

      case NodeType.Instance:
        break;

      default:
    }
  }

  // Get List Items.
  getListItems(nodeType: string, parentValues: string[]) {
    switch (nodeType) {
      case NodeType.LineOfBusiness:
        this.listItems = AppGenericLists.LineOfBusiness;
        break;

      case NodeType.EntityType:
        this.afkamService.getPayorEntityTypes(parentValues[parentValues.length - 1]).subscribe(resultObj => this.listItems = resultObj.result!);
        break;

      default:
    }
  }

  // Get place holder.
  getDropdownPlaceHolder(nodeType: string): string {
    switch (nodeType) {
      case NodeType.LineOfBusiness:
        return 'Line Of Business';
      case NodeType.EntityType:
        return 'Entity Type';
      default:
        return '';
    }
  }

  getAddExistingPlaceholder(nodeType: string): string {
    switch (nodeType) {
      case NodeType.Instance:
        return 'Instances';
      case NodeType.Site:
        return 'Sites';
      case NodeType.Practice:
        return 'Practices';
      case NodeType.PracticeLocation:
        return 'Locations';
      case NodeType.ScreeningLocationSubLevel:
        return 'Location Sub Levels';
      case NodeType.OshaLocation:
        return 'Locations';
      default:
        return '';
    }
  }

  manageInstancesGrid() {
    this.instancesGridConfig = this.defineManageSelectedGrid();
    this.dialog.open(IchsGridDialogComponent, {
      width: '1024px',
      height: '800px',
      data: {
        config: this.instancesGridConfig,
        title: "Selected Instances"
      }
    });
  }

  private defineManageSelectedGrid(): IchsGridComponentConfig<DynamicInstanceViewModel> {
    let treeNode = this.treeData![0];
    let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      primaryId: "instanceId",
      defaultOrder: "Name",
      entityDataSource: (filter: SearchViewModel) => this.getSelectedInstancesSearchFunction(filter),
      hasNew: false,
      hasActionColumn: true,
      pageSize: 1000,
      hasVoid: false,
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: DynamicInstanceViewModel) => {
          treeNode.nodeValues!.splice(treeNode.nodeValues!.indexOf(deletedObj.instanceId!), 1);
          this.instancesGridConfig.refreshGrid!(this.instancesGridConfig.getPage!());
        }
      },
      hasDetails: false,
      addExistingConfig: {
        buttonText: "Add Existing",
        title: "Add Instances",
        gridConfig: this.defineAddExistingGrid(),
        customAddExisting: (Ids: Array<string>) => {
          let treeNode = this.treeData![0];
          treeNode.nodeValues = treeNode.nodeValues.concat(Ids);
          return true;
        }
      },
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
          width: 500
        }, {
          headerTitle: "Code",
          propertyName: "code",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ]
    }

    return configs;
  }

  private defineAddExistingGrid(): IchsGridComponentConfig<DynamicInstanceViewModel> {
    let treeNode = this.treeData![0];
    let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      primaryId: "instanceId",
      defaultOrder: "Name",
      pageSize: 1000,
      multiSelectConfig: { initialSelection: treeNode.nodeValues },
      entityDataSource: (filter: SearchViewModel) => this.getAddExistingGridSearchFunction(filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
          width: 500
        }, {
          headerTitle: "Code",
          propertyName: "code",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ]
    }
    return configs;
  }

  private definePayorAdvSearchGrid(): IchsGridComponentConfig<DynamicInstanceViewModel> {

    let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      primaryId: "instanceId",
      defaultOrder: "Name",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      multiSelectConfig: { allowSelectObjects: true },
      entityDataSource: (filter: SearchViewModel) => this.getPayorAdvSearchFunction(filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
          width: 500
        }, {
          headerTitle: "Code",
          propertyName: "code",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ]
    }
    return configs;
  }

  private defineInstAdvSearchGrid(): IchsGridComponentConfig<DynamicInstanceViewModel> {

    let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      primaryId: "instanceId",
      defaultOrder: "Name",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      multiSelectConfig: { allowSelectObjects: true },
      entityDataSource: (filter: SearchViewModel) => this.getInstAdvSearchFunction(filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
        }
      ]
    }
    return configs;
  }

  private defineMedfilesCompAdvSearchGrid(parentValues: string[]): IchsGridComponentConfig<DynamicInstanceViewModel> {

    let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      primaryId: "instanceId",
      defaultOrder: "Name",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      multiSelectConfig: { allowSelectObjects: true },
      entityDataSource: (filter: SearchViewModel) => this.getMedfilesCompAdvSearchFunction(filter, parentValues[parentValues.length - 1]),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield
        }
      ]
    }
    return configs;
  }

  private definePracticeAdvSearchGrid(): IchsGridComponentConfig<DynamicInstanceViewModel> {

    let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      primaryId: "instanceId",
      defaultOrder: "Name",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      multiSelectConfig: { allowSelectObjects: true },
      entityDataSource: (filter: SearchViewModel) => this.getPracticeAdvSearchFunction(filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield
        }
      ]
    }
    return configs;
  }

  private defineScreeningClientAdvSearchGrid(): IchsGridComponentConfig<DynamicInstanceViewModel> {

    let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      primaryId: "instanceId",
      defaultOrder: "Name",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      multiSelectConfig: { allowSelectObjects: true },
      entityDataSource: (filter: SearchViewModel) => this.getScreeningClientSearchFunction(filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield
        }
      ]
    }
    return configs;
  }

  private defineScreeningLocationAdvSearchGrid(parentValues: string[]): IchsGridComponentConfig<DynamicInstanceViewModel> {

    let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      primaryId: "instanceId",
      defaultOrder: "Name",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      multiSelectConfig: { allowSelectObjects: true },
      entityDataSource: (filter: SearchViewModel) => this.getScreeningLocationSearchFunction(filter, parentValues[parentValues.length - 1]),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield
        }
      ]
    }
    return configs;
  }

  private defineOshaCompanyAdvSearchGrid(): IchsGridComponentConfig<DynamicInstanceViewModel> {

    let configs: IchsGridComponentConfig<DynamicInstanceViewModel> = {
      primaryId: "instanceId",
      defaultOrder: "Name",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      multiSelectConfig: { allowSelectObjects: true },
      entityDataSource: (filter: SearchViewModel) => this.getOshaCompanyAdvSearchFunction(filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield
        }
      ]
    }
    return configs;
  }

  private definePopulatePayorAdvSearch(): (recordId: string) => Observable<MedcorResponse<DynamicInstanceViewModel>> {
    let PopulatePayor = (recordId: string) => this.afkamService.getPayor(recordId);
    return PopulatePayor;
  }

  private definePopulateInstAdvSearch(): (recordId: string) => Observable<MedcorResponse<DynamicInstanceViewModel>> {
    let PopulateInstallation = (recordId: string) => this.medfilesService.getInstallation(recordId);
    return PopulateInstallation;
  }

  private definePopulateMedfilesCompAdvSearch(): (recordId: string) => Observable<MedcorResponse<DynamicInstanceViewModel>> {
    let PopulateCompany = (recordId: string) => this.medfilesService.getCompany(recordId);
    return PopulateCompany;
  }

  private definePopulatePracticeAdvSearch(): (recordId: string) => Observable<MedcorResponse<DynamicInstanceViewModel>> {
    let PopulatePractice = (recordId: string) => this.nextgenService.getPractice(recordId);
    return PopulatePractice;
  }

  private definePopulateScreeningClientAdvSearch(): (recordId: string) => Observable<MedcorResponse<DynamicInstanceViewModel>> {
    let PopulateClient = (recordId: string) => this.screeningService.getClient(recordId);
    return PopulateClient;
  }

  private definePopulateScreeningLocationAdvSearch(): (recordId: string) => Observable<MedcorResponse<DynamicInstanceViewModel>> {
    let PopulateLocation = (recordId: string) => this.screeningService.getLocation(recordId);
    return PopulateLocation;
  }

  private definePopulateOshaCompanyAdvSearch(): (recordId: string) => Observable<MedcorResponse<DynamicInstanceViewModel>> {
    let PopulateOshaCompany = (recordId: string) => this.oshaReportableService.getCompany(recordId);
    return PopulateOshaCompany;
  }

  private getPayorAdvSearchFunction(filter: SearchViewModel): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>> | PaginatedListViewModel<DynamicInstanceViewModel> {
    return this.afkamService.searchPayors(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions);
  }

  private getInstAdvSearchFunction(filter: SearchViewModel): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>> | PaginatedListViewModel<DynamicInstanceViewModel> {
    return this.medfilesService.searchInstallations(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions);
  }

  private getMedfilesCompAdvSearchFunction(filter: SearchViewModel, instId: string): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>> | PaginatedListViewModel<DynamicInstanceViewModel> {
    return this.medfilesService.searchCompaniesByInstId(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, instId);
  }

  private getPracticeAdvSearchFunction(filter: SearchViewModel): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>> | PaginatedListViewModel<DynamicInstanceViewModel> {
    return this.nextgenService.searchPractices(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions);
  }

  private getScreeningClientSearchFunction(filter: SearchViewModel): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>> | PaginatedListViewModel<DynamicInstanceViewModel> {
    return this.screeningService.searchClients(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions);
  }

  private getScreeningLocationSearchFunction(filter: SearchViewModel, clientId: string): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>> | PaginatedListViewModel<DynamicInstanceViewModel> {
    return this.screeningService.searchLocations(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, clientId);
  }

  private getOshaCompanyAdvSearchFunction(filter: SearchViewModel): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>> | PaginatedListViewModel<DynamicInstanceViewModel> {
    return this.oshaReportableService.searchCompanies(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions);
  }

  getAddExistingGridSearchFunction(filter: SearchViewModel): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>> | PaginatedListViewModel<DynamicInstanceViewModel> {
    let treeNode = this.treeData![0];
    let parentValues = treeNode.parentValues;
    if (treeNode.nodeType == NodeType.Instance) {
      return this.afkamService.searchNotSelectedPayorInstances(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, parentValues![parentValues!.length - 2], parentValues![parentValues!.length - 1], treeNode.nodeValues);
    }
    else if (treeNode.nodeType == NodeType.Site) {
      return this.medfilesService.searchNotSelectedSites(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, parentValues![parentValues!.length - 1], treeNode.nodeValues);
    }
    else if (treeNode.nodeType == NodeType.PracticeLocation) {
      return this.nextgenService.searchNotSelectedPracticeLocations(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, parentValues![parentValues!.length - 1], treeNode.nodeValues);
    }
    else if (treeNode.nodeType == NodeType.ScreeningLocationSubLevel) {
      return this.screeningService.searchNotSelectedLocationSubLevels(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, parentValues![parentValues!.length - 1], treeNode.nodeValues);
    }
    else if (treeNode.nodeType == NodeType.OshaLocation) {
      return this.oshaReportableService.searchNotSelectedLocations(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, parentValues![parentValues!.length - 1], treeNode.nodeValues);
    }

    return null;
  }

  getSelectedInstancesSearchFunction(filter: SearchViewModel): Observable<MedcorResponse<PaginatedListViewModel<DynamicInstanceViewModel>>> | PaginatedListViewModel<DynamicInstanceViewModel> {
    let treeNode = this.treeData![0];
    let parentValues = treeNode.parentValues;

    if (treeNode.nodeType == NodeType.Instance) {
      return this.afkamService.searchSelectedPayorInstances(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, parentValues![parentValues!.length - 2], parentValues![parentValues!.length - 1], treeNode.nodeValues);
    }
    else if (treeNode.nodeType == NodeType.Site) {
      return this.medfilesService.searchSelectedSites(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, parentValues![parentValues!.length - 1], treeNode.nodeValues);
    }
    else if (treeNode.nodeType == NodeType.PracticeLocation) {
      return this.nextgenService.searchSelectedPracticeLocations(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, parentValues![parentValues!.length - 1], treeNode.nodeValues);
    }
    else if (treeNode.nodeType == NodeType.ScreeningLocationSubLevel) {
      return this.screeningService.searchSelectedLocationSubLevels(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, parentValues![parentValues!.length - 1], treeNode.nodeValues);
    }
    else if (treeNode.nodeType == NodeType.OshaLocation) {
      return this.oshaReportableService.searchSelectedLocations(filter.filterExpression, filter.pageIndex, filter.pageSize, filter.sortExpressions, parentValues![parentValues!.length - 1], treeNode.nodeValues);
    }

    return null;
  }
}

export enum NodeType {
  LineOfBusiness = "LineOfBusiness",
  Payor = "Payor",
  EntityType = "EntityType",
  Instance = "Instance",
  Installation = "Installation",
  Company = "Company",
  Site = "Site",
  Practice = "Practice",
  PracticeLocation = "PracticeLocation",
  ScreeningClient = "ScreeningClient",
  ScreeningLocation = "ScreeningLocation",
  ScreeningLocationSubLevel = "ScreeningLocationSubLevel",
  OshaCompany = "OshaCompany",
  OshaLocation = "OshaLocation",
}
