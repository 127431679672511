import { Component, ViewChild, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';
import { TriageSecuredFileService, ExternalUserRegistrationViewModel, SelectionItemViewModel, DeviceType } from '../../rest';
import { first } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl, NgForm } from '@angular/forms';
import { MedcorAuthenticationService } from '../../services/medcor-authentication.service';
import { Router } from '@angular/router';
import { ValidationMessages } from '../../app.general.constants';
import { GeneralVariablesService } from 'src/app/services/general-variables.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { SelectionListService } from '../../rest/api/selectionList.service';

@Component({
  selector: 'triage-secured-file',
  templateUrl: './triage-secured-file.component.html',
  styleUrls: ['./triage-secured-file.component.scss']
})
export class TriageSecuredFileComponent implements OnInit {
  @ViewChild("registrationForm") registrationForm: NgForm;

  @Input() email: string;
  @Input() pinCode: string;
  @Input() encryptedData: string;

  @Output() registerOrLogoutEvent: EventEmitter<null> = new EventEmitter<null>();

  registrationModel: ExternalUserRegistrationViewModel;
  confirmPassword: string;
  newlyRegisteredUserName: string = "User";
  questionsList: SelectionItemViewModel[];

  get selectedQuestionsList(): string[] {
    return this.registrationModel.secQuestionAssociationList.map(item => item.question);
  }

  constructor(
    private triageSecuredFileService: TriageSecuredFileService,
    private alertService: MedcorAlertService,
    private authenticationService: MedcorAuthenticationService,
    private selectionService: SelectionListService,
    private router: Router,
    private deviceService: DeviceDetectorService,
    private generalVariables: GeneralVariablesService,
  ) {
  }

  ngOnInit() {
    this.initializeRegistrationForm();
    this.selectionService.getSecurityQuestions().pipe(first()).subscribe(lst => {
      this.questionsList = lst.result![0].items;
    });
  }

  registerAndRetrieveData() {
    if (this.registrationForm.invalid) {   // check if form is valid 
      this.validateAllFormFields(this.registrationForm.form);
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    this.triageSecuredFileService.registerUserAndRetrieveData(this.encryptedData, this.pinCode, this.registrationModel).pipe(first()).subscribe(resp => {
      if (resp.result) {
        this.authenticationService.authenticate();
        this.generalVariables.firstCheck = true;
        this.registerOrLogoutEvent.emit();
        this.router.navigate(['/triage-call-report'],
          {
            replaceUrl: true,
            queryParams: {
              "ichs-grid-refNbr": resp.result.referenceNumber,
              "loadOnInit": true,
            }
          });
      }
    });
  }

  logout() {
    this.registerOrLogoutEvent.emit();
  }

  initializeRegistrationForm() {
    this.confirmPassword = "";
    let deviceInfo = this.deviceService.getDeviceInfo();

    //initiate an object.
    this.registrationModel = {
      firstName: "",
      lastName: "",
      email: this.email,
      password: "",
      secQuestionAssociationList: [
        { answer: "", question: "" },
        { answer: "", question: "" },
        { answer: "", question: "" }
      ],
      device: {
        deviceType: DeviceType.Browser,
        os: deviceInfo.os,
        browser: deviceInfo.browser,
        device: deviceInfo.device,
        osVersion: deviceInfo.os_version,
        browserVersion: deviceInfo.browser_version,
        isPrivateMachine: false,
      },
    };
  }

  getFilteredQuestionsList(selected: string): SelectionItemViewModel[] {
    if (this.questionsList == null) return [];
    return this.questionsList.filter((question) => {
      if (this.selectedQuestionsList.indexOf(question.selectionValue) == -1 || question.selectionValue == selected)
        return true;
    });
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
