<div class='label-cont' *ngIf="label">
  <label [class.disabled]="disabled">{{label}}</label>
</div>
<div [title]="selectionModel.selectedText">
  <div>
    <button [class.disabled]="disabled" class='cp-def-btn cp-dashboard-btn' type='button' style="text-align:left;" (click)="openTreePopup()">
      <div style="max-height: 19px; " class="display-flex flex-items-centered">
        <div class="flex-grow-1 flex-basis-0" style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap; width:150px;">
          {{selectionModel.selectedText}}
        </div>
        <div class="margin-left-def" style="text-align:right;">
          <span class='caret custom-drop-arrow'></span>
        </div>
      </div>
    </button>
  </div>
</div>