import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CanActivateGuard, CanDeactivateGuard } from '../services/routing-guard.service';
import { AppModuleShared } from '../app.shared.module';
import { GroupListComponent } from './group-list/group-list.component';
import { GroupComponent } from './group/group.component';
import { ReportItemListComponent } from './report-item-list/report-item-list.component';
import { ReportItemComponent } from './report-item/report-item.component';
import { MatStepperModule } from '@angular/material/stepper';
import { AppUserModule } from '../appuser/appuser.module';
import { PageDesignComponent } from './page-design/page-design.component';
@NgModule({
    exports: [

    ],
    imports: [
        RouterModule.forChild([
            { path: 'page-design', component: PageDesignComponent },
            { path: 'app-group', component: GroupListComponent },
            { path: 'app-group/:id', canActivate: [CanActivateGuard], canDeactivate: [CanDeactivateGuard], component: GroupComponent },
            { path: 'report-item', component: ReportItemListComponent },
            { path: 'report-item/:id', canActivate: [CanActivateGuard], canDeactivate: [CanDeactivateGuard], component: ReportItemComponent },
        ]),
        AppModuleShared,
        MatStepperModule,
        AppUserModule,
    ],
    declarations: [
        GroupListComponent,
        GroupComponent,
        ReportItemListComponent,
        ReportItemComponent,
        PageDesignComponent,
    ],
    providers: [
        CanActivateGuard,
        CanDeactivateGuard,
    ]
})
export class GroupingModule {
}
