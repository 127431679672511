<h4 *ngIf="title">{{title}}</h4>

<!-- <p>We've sent a email message to <span>{{email}}</span></p>

<p>When you receive the verification code enter it here</p> -->
<div [innerHTML]="message"></div>

<div class="display-flex break-la">
    <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
        <ichs-password required class="phone-nbr-verify"
         [(ngModel)]="value" placeholder="Verification Code" autoComplete="new-password">
        </ichs-password>
        <!-- <ichs-text-box required class="phone-nbr-verify" [name]="identifier" placeholder="Verification Code"
            [(ngModel)]="value" ></ichs-text-box> -->
    </div>
</div>
<div class="display-flex break-la margin-bottom-def" *ngIf="hasResend">
    <div class="display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
        <a style="cursor: pointer; margin-top: 4px;" (click)="resendCodeClicked(true)">Resend Code</a>
    </div>
</div>

<div class="text-right fill-width">
    <button class="cp-btn cp-mail-btn" (click)="cancelButtonClicked()">Cancel</button>
    <button class="cp-btn cp-main-btn" (click)="verifyButtonClicked()">Verify</button>
</div>
