import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { FirstLoginViewModel } from '../../rest/model/firstLoginViewModel';
import { AppUserService, SelectionItemViewModel, SelectionListService, LoggedUserViewModel } from '../../rest/index';
import { NgForm, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MedcorUserInfoService } from '../../services/medcor-user-info.service';
import { SharedFunctionsService } from '../../services/shared-functions.service';
import { AlertMessageType, AlertDismissType, MedcorAlertService } from '../../services/medcor-alert.service';
import { ValidationMessages } from '../../app.general.constants';

@Component({
  selector: 'first-login',
  templateUrl: './first-login.component.html',
  styleUrls: ['./first-login.component.css']
})

export class FirstLoginComponent implements OnInit {
  @ViewChild('form') ngForm: NgForm;
  @Input() loggedInUserInfo: LoggedUserViewModel;
  public model: FirstLoginViewModel;
  questionsList: SelectionItemViewModel[];
  confirmPassword: string;

  changeFirstAndLastName: boolean = false;

  get selectedQuestionsList(): string[] {
    if (this.model.secQuestionAssociationList == null)
      return [];
    else
      return this.model.secQuestionAssociationList.map(item => item.question);
  }

  constructor(
    private appUserService: AppUserService,
    private selectionService: SelectionListService,
    private medcorUserInfoService: MedcorUserInfoService,
    private sharedFunctions: SharedFunctionsService,
    private alertService: MedcorAlertService,
  ) {
  }

  ngOnInit() {
    this.changeFirstAndLastName = this.loggedInUserInfo.fullUserName.trim().length == 0;

    //initiate an Empty object.
    this.model = {
      currentPassword: "",
      newPassword: "",
      secQuestionAssociationList: [
        { answer: "", question: "" },
        { answer: "", question: "" },
        { answer: "", question: "" }
      ]
    };

    this.selectionService.getSelectionItems(108000000000001).pipe(first()).subscribe(lst => {
      this.questionsList = lst.result![0].items;
    });
  }

  submit() {
    this.validateAllFormFields(this.ngForm.form);
    if (this.ngForm.form.invalid) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    this.appUserService.addFirstLoginData(this.model).subscribe(() => {
      if (location.search.indexOf("?redirectTo=") > -1) {
        let redirectUrl = location.search.split('&')[0].replace('?redirectTo=', '');
        location.replace(decodeURIComponent(redirectUrl));
      }
      else {
        // create new object so that loggedInUser Observable can recognize the change
        let newObj = Object.assign({}, this.loggedInUserInfo);
        newObj.acceptedUserAgreement = false;
        newObj.firstLoginInd = false;
        newObj.changePasswordInd = false;

        if (this.changeFirstAndLastName) {
          newObj.fullUserName = this.model.firstName + " " + this.model.lastName;
        }

        this.medcorUserInfoService.setLoggedInUserObs(newObj);
      }
    });
  }

  getFilteredQuestionsList(selected: string): SelectionItemViewModel[] {
    if (this.questionsList == null) return [];
    return this.questionsList.filter((question) => {
      if (this.selectedQuestionsList.indexOf(question.selectionValue) == -1 || question.selectionValue == selected)
        return true;
    });
  }

  logout() {
    this.sharedFunctions.logout();
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
