import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { AppUserService, SearchViewModel, SharedFolderUserPermissionService, SelectionItemViewModel, SharedFolderUserPermissionViewModel, ShortUserViewModel } from '../../../rest';
import { first } from 'rxjs/operators';
import { EXTRA_DATA, DIALOG_REF, IchsComponentDialogComponent } from '../../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { NgForm, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../../services/medcor-alert.service';
import { MatDialogRef } from '@angular/material/dialog';
import { IchsGridComponentConfig, IchsControlType } from '../../../controls/ichs-grid/grid-configs';
import { ValidationMessages } from '../../../app.general.constants';
import { AppGenericLists } from '../../../app.general.lists';

@Component({
  selector: 'user-permission',
  templateUrl: './user-permission.component.html',
  styleUrls: ['./user-permission.component.css']
})
/** UserPermission component*/
export class UserPermissionComponent implements OnInit {
  userPermission: SharedFolderUserPermissionViewModel = <SharedFolderUserPermissionViewModel>{};
  userAdvSearchGridConfig: IchsGridComponentConfig<ShortUserViewModel>;
  permissionItems: SelectionItemViewModel[] = AppGenericLists.SharedFolderPermission;
  isNew: boolean;
  @ViewChild("form") ngForm: NgForm;

  /** UserPermission ctor */
  constructor(
    private sharedFolderUserPermissionService: SharedFolderUserPermissionService,
    private alertService: MedcorAlertService,
    @Inject(EXTRA_DATA) private extraData: number | SharedFolderUserPermissionViewModel,
    @Inject(DIALOG_REF) private dialogRef: MatDialogRef<IchsComponentDialogComponent>,
  ) {
  }

  ngOnInit() {
    if (typeof this.extraData == 'number') {
      this.userPermission.folderId = this.extraData;
      this.isNew = true;
    }
    else {
      Object.assign(this.userPermission, this.extraData)
      this.isNew = false;
    }

    this.userAdvSearchGridConfig = this.defineUserAdvSearchGrid();
  }

  save() {
    this.validateAllFormFields(this.ngForm.form); // validate the form and show errors
    if (!this.ngForm.valid) {// check if form is valid 
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    this.sharedFolderUserPermissionService.post(this.userPermission).pipe(first()).subscribe(result => {
      this.dialogRef.close(true);
    });
  }

  private defineUserAdvSearchGrid(): IchsGridComponentConfig<ShortUserViewModel> {
    let configs: IchsGridComponentConfig<ShortUserViewModel> = {
      primaryId: "id",
      defaultOrder: "email",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      entityController: null,
      multiSelectConfig: { allowSelectObjects: true },
      entityDataSource: (filter: SearchViewModel) => this.sharedFolderUserPermissionService.searchUsersWithoutAccess(this.userPermission.folderId, filter),
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ]
    }
    return configs;
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
