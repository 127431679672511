<div id="dashboardFiltersPane" class="display-flex flex-items-centered dashboard-filters" >
  <div class="display-flex align-items-center main-filters">
    <div class="filter-cont flex-basis-0 display-flex flex-items-centered date-range">
      <div class="dashboard-filter-label">{{filterControlsConfig.dateHierarchy.label}}</div>
      <ichs-dropdown-tree class="flex-basis-0 flex-grow-1"
                          [name]="filterControlsConfig.dateHierarchy.name"
                          [data]="dates"
                          (selectionEvent)="setFilters()"
                          [isLevel]="filterControlsConfig.dateHierarchy.isLevel"
                          [customDateSelectionText]="true"
                          [hideDataTree]="true"
                          [showAdvanceDate]="true"
                          [advancedDateFilterationAttributes]="advancedDateFilterationAttributes"
                          (advancedDateFiltersChanged)="onAdvancedDateFiltersChange($event)">
      </ichs-dropdown-tree>
    </div>

    <div class="filter-cont flex-grow-1 flex-basis-0 display-flex flex-items-centered">
      <div class="dashboard-filter-label">{{filterControlsConfig.clientHierarchy.label}}</div>
      <ichs-dropdown-tree [name]="filterControlsConfig.clientHierarchy.name" class="flex-basis-0 flex-grow-1"
                          [data]="oshaHierarchy"
                          (selectionEvent)="setFilters()"
                          [isLevel]="filterControlsConfig.clientHierarchy.isLevel"
                          [selectedLeaves]="true">
      </ichs-dropdown-tree>
    </div>

    <div class="filter-cont flex-basis-0 display-flex flex-items-centered">
      <div class="dashboard-filter-label">{{filterControlsConfig.stateHierarchy.label}}</div>
      <ichs-dropdown-tree [name]="filterControlsConfig.stateHierarchy.name" class="geo-location"
                          [data]="oshaStates"
                          (selectionEvent)="setFilters()"
                          [isLevel]="filterControlsConfig.stateHierarchy.isLevel">
      </ichs-dropdown-tree>
    </div>
  </div>

  <div class="display-flex align-items-center filter-options">
    <div class="display-flex align-items-center flex-wrap">
        <button title="Schedule dashboard" class="option-btn" (click)="scheduleDashboard()"><img class="option-btn-icon" src="assets/images/newcolor/scheduledDashboards.svg"/> </button>
        @if(powerBIResult?.dashboardItem.hasExport){
          <button title="Export dashboard"   class="option-btn" (click)="viewExportDashboardDialog()"><img class="option-btn-icon" src="assets/images/export-dashboard.svg"/></button>
        }
        <button title="Capture view"       class="option-btn" (click)="addNewBookmark()"><img class="option-btn-icon" src="assets/images/capture-view.svg"/></button>
        <button title="Saved views"        class="option-btn" (click)="showBookmarksPane()"><img class="option-btn-icon" src="assets/images/saved-views.svg"/></button>
    </div>


    <div class="filter-cont" >
      <button class="cp-btn filter-btn" (click)="clearFilter()"><img class="btn-icon" src="assets/images/refresh2.svg"/> Reset</button>
    </div>
  </div>



</div>

<div class="display-flex position-relative">
  <div id="dashboardPane1" class="display-flex flex-wrap flex-grow-1 flex-basis-0 dashboard-items-cont"
    *ngIf="powerBIResult && powerBIResult.dashboardItem" style="min-height:600px;">
    <ichs-powerbi-report [singleTile]="true" [filters]="filters"
      [dashboardItem]="powerBIResult.dashboardItem" [accessToken]="accessToken" (errorEvent)="showDashboardError()"
      (maximize)="itemMaximized($event)">
    </ichs-powerbi-report>
  </div>

  <div class="display-flex flex-col dashboard-bookmark" *ngIf="isBookmarksPaneVisible">
    <div class="display-flex align-items-center bookmark-title">
      <div class="flex-grow-1">Saved Views</div>
      <img title="Close" (click)="hideBookmarksPane()" src="assets/images/close.svg" class="cp-dialog-close" />
    </div>
    <ng-scrollbar style="height: 100%" appearance="compact" class="flex-grow-1 right-section">
      <div class=" display-flex flex-items-centered bookmark-item" *ngFor="let bookmark of bookmarks"
        (click)="applyBookmark(bookmark.id)">
        <span class="flex-grow-1">{{bookmark.name}}</span>
        <img title="Delete" class="delete-bookmark" (click)="$event.stopPropagation(); deleteBookmark(bookmark.id)"
          src="assets/images/delete-cross.svg" />
      </div>
    </ng-scrollbar>
  </div>
</div>
