import { Injectable } from '@angular/core';
import { MedcorAuthenticationService } from './medcor-authentication.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { LoggedUserViewModel, AppUserService, UserContactViewModel } from '../rest/index';
import { NgxPermissionsService } from 'ngx-permissions';
import { MedcorAlertService, AlertMessageType } from './medcor-alert.service';
import { first, distinctUntilChanged, switchMap, map } from 'rxjs/operators';
import { GeneralVariablesService } from './general-variables.service';
import { ZendeskService } from './zendesk.service';

export function initUser(medcorUserInfoService: MedcorUserInfoService): () => Promise<any> {
  return () => medcorUserInfoService.initializeUser();
}

@Injectable()
export class MedcorUserInfoService {
  private _loggedInUser = new BehaviorSubject<LoggedUserViewModel>({});
  private _relatedAppUsers = new BehaviorSubject<UserContactViewModel[]>([]);

  get loggedInUserObs(): Observable<LoggedUserViewModel> { return this._loggedInUser.pipe(distinctUntilChanged()); }
  get relatedAppUsersObs(): Observable<UserContactViewModel[]> { return this._relatedAppUsers.asObservable(); }

  get userChangesObs(): Observable<LoggedUserViewModel> {
    return this.medcorAuthenticationService.authenticatedObs.pipe(switchMap(value => {
      if (!value) {
        return of<LoggedUserViewModel>({});
      }

      this.appUserService.getUserSettings().pipe(first()).subscribe(result => {
        if (result && result.result) {
          this.generalVariables.userSettings = result.result;
        }
      });

      this.appUserService.getUserFiscalYearSettings().pipe(first()).subscribe(result => {
        if (result && result.result) {
          this.generalVariables.userFiscalYearSettings = result.result;
          this.generalVariables.userFiscalYearSettings.startingMonth--; // month in TS start from 0 to 11
        }
      });

      return this.appUserService
        .getLoggedUserInfo()
        .pipe(first(), map(medcorResult => medcorResult.result ? medcorResult.result : {}));
    }));
  }

  constructor(
    private medcorAuthenticationService: MedcorAuthenticationService,
    private appUserService: AppUserService,
    private permissionsService: NgxPermissionsService,
    private medcorAlertService: MedcorAlertService,
    private generalVariables: GeneralVariablesService,
    private zendeskChatService: ZendeskService,
  ) { }

  setLoggedInUserObs(loggedInUser: LoggedUserViewModel) {
    this._loggedInUser.next(loggedInUser);
  }

  getLoggedInUserInfo(): LoggedUserViewModel {
    return this._loggedInUser.getValue();
  }

  setRelatedAppUsers(relatedAppUsers: UserContactViewModel[]) {
    this._relatedAppUsers.next(relatedAppUsers);
  }

  sortRelatedAppUsers() {
    this._relatedAppUsers.pipe(first()).subscribe(contacts => {
      contacts = contacts.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase())
          return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase())
          return -1;
        return 0;
      });

      contacts = contacts.sort((a, b) => {
        if (a.isFavorite && !b.isFavorite)
          return -1;
        if (!a.isFavorite && b.isFavorite)
          return 1;
        return 0
      });

      this._relatedAppUsers.next(contacts);
    });
  }

  addRelatedAppUsers(relatedAppUser: UserContactViewModel) {
    this._relatedAppUsers.pipe(first()).subscribe(obj => {
      obj.push(relatedAppUser);
      this.sortRelatedAppUsers();
    });
  }

  removeRelatedAppUsers(contactUserId: number) {
    this._relatedAppUsers.pipe(first()).subscribe(obj => {
      var userObjList = obj.filter(contact => contact.id == contactUserId);
      obj.splice(obj.indexOf(userObjList[0]), 1);
      this._relatedAppUsers.next(obj);
    });
  }

  initializeUser(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userChangesObs.subscribe(loggedInUser => {
        this.userChanged(loggedInUser, loggedInUser.roleName);

        // if user is logged in
        if (loggedInUser.appUserId > 0 && loggedInUser.roleName != "Client User - Scheduled Reports Only") {
          this.zendeskChatService.showZendesk(loggedInUser);
        }
        else {
          this.zendeskChatService.hideZendesk();
        }

        resolve("");
      });
    });
  }

  private userChanged(loggedInUser: LoggedUserViewModel, permissions: string) {
    this._loggedInUser.next(loggedInUser);
    const perm = Array.from([permissions]);
    this.permissionsService.loadPermissions(perm);
    if (loggedInUser.passwordResetMessage && loggedInUser.passwordResetMessage.length > 0) {
      this.medcorAlertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Password expiration warning',
        messages: [loggedInUser.passwordResetMessage]
      });
    }
  }
}
