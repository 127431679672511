import { Injectable } from "@angular/core";
import { Configuration } from "../rest";
import { APISettings } from 'src/app/app.general.constants';

@Injectable()
export class ApiConfiguration extends Configuration {
    constructor() {
        super();
        this.withCredentials = true;
        // remove http or https from basePath to keep the link starting with //domain.com, so that we can use AntiForgery Token (XSRF Token)
        this.basePath = APISettings.API_BASE_PATH.replace("http:", "").replace("https:", "");
    }

}
