import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { MedcorResponse, VoidInfoViewModel } from '../../rest/index';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from '../../services/medcor-alert.service';
import { IchsDialogComponent } from '../ichs-dialog/ichs-dialog.component';
import { VoidDialogComponent } from '../ichs-dialog/void-dialog/void-dialog.component';
import { ValidationMessages } from '../../app.general.constants';

@Component({
  selector: 'ichs-command-toolbar',
  templateUrl: './ichs-command-toolbar.component.html',
  styleUrls: ['./ichs-command-toolbar.component.css']
})
/** IchsCommandToolbar component*/
export class IchsCommandToolbarComponent {
  @Input("model") public model: IchsCommandToolbarInterface;
  /** IchsCommandToolbar ctor */
  constructor() {
  }
}

/**
Command Toolbar Functions and Properties
*/
export interface IchsCommandToolbarInterface {
  New(): void;
  SaveAndReload(): void;
  SaveAndNew(): void;
  Back(): void;
  SaveAndBack(): void;
  Cancel(): void;
  Void(): void;
  Recover(): void;
  NavNext(): void;
  NavPrevious(): void;
  NavFirst(): void;
  NavLast(): void;
  CustomAction(): void;

  hasNew?: boolean;
  hasSave?: boolean;
  hasSaveAndNew?: boolean;
  hasBack?: boolean;
  hasSaveAndBack?: boolean;
  hasCancel?: boolean;
  hasVoid?: boolean;
  hasRecover?: boolean;
  hasNavNext?: boolean;
  hasNavPrevious?: boolean;
  hasNavFirst?: boolean;
  hasNavLast?: boolean;
  hasNavigation?: boolean;
  hasVoidRecover?: boolean;
  hasCustomAction?: boolean;
  hasCustomIdentifier?: boolean;

  backText?: string;
  customActionText?: string;
}

/**
Command Toolbar Model Delegate
The Delegate of IchsCommandToolbarModel<T> should implement these methods 
*/
export interface IchsCommandToolbarDelegate<T> {
  ngForm: NgForm;// the form to interact with 
  canSave?: (objModel: T) => boolean;// called before save actions, if implemented and returned false the save will not called 
  beforeSave?: (objModel: T) => void;// called before save actions 
  didSave?: (objModel: T) => void;// called after save actions 
  didLoad?: (objModel: T) => void;// called after load model 
  canDeactivate: () => boolean | Observable<boolean>;// called before nvigate, implement this and check if the form is dirty 
  getSaveObservable?: (objModel: T) => Observable<MedcorResponse<T>> | T | undefined;// save observable to be called when save required 
  getLoadObjModelObservable?: (id: number) => Observable<MedcorResponse<T>> | T | undefined;// get object by id to be called when refresh is needed 
  getVoidObservable?: (objVoidModel: VoidInfoViewModel) => Observable<MedcorResponse<T>> | T | undefined;// get observable to be called when voiding 
  getRecoverObservable?: (id: number) => Observable<MedcorResponse<T>> | T | undefined;// get observable to be called when recovering 
  getEmptyObject?: () => T;//get custom empty object.
  validatingAllFormFields?: (formGroup: UntypedFormGroup) => void;//called before start validating form 
}
/*
Generic Class to interact with the Command toolbar
Override this when you need different functionality
T: viewmodel type of the form
should have id and voidId
*/
export class IchsCommandToolbarModel<T> implements IchsCommandToolbarInterface {
  objModel: T = <T>{}; // Model to be binded with the form 
  identityProperty: string = "id";// Identity Field of the Model
  voidIdProperty: string = "voidId"// Void field of the model 
  objModelId: number = -1;// current navigation Id
  selectedTab: number = 0;//Selected tab
  actionsDisabled: boolean = false;
  constructor(
    protected location: Location,// needed when calling back action 
    protected router: Router,// for navigate actions
    protected componentRoute: string,// the main route of the component 
    protected title: string,// Component title 
    protected delegate: IchsCommandToolbarDelegate<T>,// pass this to make the compnent delegate for actions 
    protected dialog: MatDialog,// to show void and recover dialogs
    protected alertService: MedcorAlertService// to send Validation Messages 
  ) { }

  init(route: ActivatedRoute) { // call on ngInit to initializa the form and model 
    if (this.delegate.getEmptyObject != undefined)
      this.objModel = this.delegate.getEmptyObject();
    else
      this.objModel = <T>{};

    if (this.hasCustomIdentifier)
      this.objModelId = 1;
    else {
      const param = route.snapshot.paramMap == null ? -1 : route.snapshot.paramMap.get(this.identityProperty);
      if (param) {
        this.objModelId = +param;
      }
    }
    this.getObjectModel();
  }
  /*
  default can deactivate : check if form is dirty
  */
  confirmCanDeactivate(): boolean | Observable<boolean> {
    if (this.delegate.ngForm.dirty) {
      let dialogRef: MatDialogRef<IchsDialogComponent, boolean> = this.dialog.open<IchsDialogComponent, any>(IchsDialogComponent, {
        width: '450px',
        data: { title: "Discard Changes ", message: "You will lose all changes in this page." }
      });
      return dialogRef.afterClosed().pipe(first(), map(result => {
        if (result && result == true) {
          return result;
        } else {
          return false;
        }
      }));
    }
    return true;
  }

  protected getObjectModel() {// load model based on ID
    if (this.objModelId > 0 && this.delegate.getLoadObjModelObservable != undefined) {
      let loadObjResult = this.delegate.getLoadObjModelObservable(this.objModelId);
      if (loadObjResult == undefined) {
        return;
      }

      if (loadObjResult instanceof Observable) {
        loadObjResult.pipe(first()).subscribe(medcorResult => {// get object model 
          if (medcorResult.result == undefined) {
            return;
          }
          this.loadObjectModel(medcorResult.result);// process object model
        });
      } else {
        this.loadObjectModel(loadObjResult);// process object model
      }
    } else {
      this.loadObjectModel(null);// load new 
    }
  }
  /*
  process object model
  if object model is null then load new action 
  */
  protected loadObjectModel(objModel: T | null) {
    this.objModelId = -1;
    this.hasVoid = false; // no void or recover if new 
    this.hasRecover = false;
    if (objModel != null) {
      this.objModel = objModel;

      let id = (objModel as any)[this.identityProperty];  // find the id
      this.objModelId = id;

      let voidId = (objModel as any)[this.voidIdProperty];  // find the voidId
      if (voidId == 0) { // check if the form need recover or void actions 
        this.hasVoid = true;
        this.hasRecover = false;
      } else {
        this.hasRecover = true;
        this.hasVoid = false;
      }
    } else {  // if new reset model and form , reset model to remove id
      this.delegate.ngForm.form.reset();
      this.selectedTab = 0;
      if (this.delegate.getEmptyObject != undefined)
        this.objModel = this.delegate.getEmptyObject();
      else
        this.objModel = <T>{};
    }
    if (this.delegate.didLoad) {
      this.delegate.didLoad(this.objModel);
    }
    if (!this.hasCustomIdentifier)
      this.router.navigate([this.componentRoute, this.objModelId], { replaceUrl: true }); // just change the url, and don't add to history 
  }
  /*
  to validate the form and show errors 
  */
  validateAllFormFields(formGroup: UntypedFormGroup) {
    if (this.delegate.validatingAllFormFields) {
      this.delegate.validatingAllFormFields(formGroup);
    }
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  /*
  save without reload
  will be used in other functions 
  */
  protected Save(): Observable<T> | null {// return observable if save succeeded | null if validation errors or (cansave returned false) 
    let completed = new Subject<T>();
    this.validateAllFormFields(this.delegate.ngForm.form); // validate the form and show errors
    if (this.delegate.canSave != undefined) {
      if (!this.delegate.canSave(this.objModel)) { // check if can save , before save 
        return null;
      }
    }

    if (this.delegate.ngForm.invalid) { // check if form is invalid 
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return null;
    }

    if (this.delegate.beforeSave != undefined) {
      this.delegate.beforeSave(this.objModel);
    }

    if (this.delegate.getSaveObservable == undefined) {
      return null;
    }

    let saveObjResult = this.delegate.getSaveObservable(this.objModel);
    if (saveObjResult == undefined) {
      return null;
    }

    if (saveObjResult instanceof Observable) {
      saveObjResult.pipe(first()).subscribe(
        medcorResult => { // call the save service  

          if (medcorResult.result == undefined) {
            return;
          };
          this.delegate.ngForm.form.markAsPristine();// after saving , the form is not dirty 
          if (this.delegate.didSave != undefined) {
            this.delegate.didSave(medcorResult.result); // call after save function if exists 
          }
          this.actionsDisabled = false
          completed.next(medcorResult.result)// add result to observable completed block 
        },
        () => {
          this.actionsDisabled = false;
        });
    } else {
      completed.next(saveObjResult);
    }
    return completed.asObservable();
  }

  /** IchsCommandToolbarInterface*/
  New(): void {
    if (this.delegate.canDeactivate()) { // if the form is not dirty 
      this.loadObjectModel(null); // load new form (make id = -1, model = empty, void = 0, reset form and validation)
    }
  }
  /*
  back to prev page 
  */
  Back(): void {
    this.location.back();
  }
  /*
  save the form and reload (change url with the new id, and load saved data)
  */
  SaveAndReload() {
    this.actionsDisabled = true;
    let saveObs = this.Save();
    if (saveObs != null) {
      saveObs.pipe(first()).subscribe(
        result => {// call save function and subscribe for completed 
          this.loadObjectModel(result);// load saved object 
        });
    } else {
      this.actionsDisabled = false;
    }
  }
  /*
  Save the form and navigate to create new record 
  */
  SaveAndNew(): void {
    this.actionsDisabled = true;
    let saveObs = this.Save();
    if (saveObs != null) {
      saveObs.pipe(first()).subscribe(
        () => {// call save function and subscribe for completed 
          this.New();// open new page with id = -1 
        });
    } else {
      this.actionsDisabled = false;
    }
  }
  /*
  save and navigate to prev page 
  */
  SaveAndBack(): void {
    this.actionsDisabled = true;
    let saveObs = this.Save();
    if (saveObs != null) {
      saveObs.pipe(first()).subscribe(
        () => {// call save function and subscribe for completed 
          this.Back();// back to the prev page 
        });
    } else {
      this.actionsDisabled = false;
    }
  }
  /*
  cancel and discard changes 
  */
  Cancel(): void {
    if (this.delegate.canDeactivate()) {// check if the form is dirty 
      this.delegate.ngForm.form.reset();// reset in case of id = -1 
      this.getObjectModel();// reset the form and load the data from server 
    }
  }
  /*
  Void the record 
  */
  Void(): void {
    this.actionsDisabled = true;
    let dialogRef = this.dialog.open(VoidDialogComponent, {
      width: '450px',
      data: { title: "Void " + this.title, message: "Are you sure about voiding this Record?" }
    });// Show Void Dialog 

    let dialogSubs = dialogRef.afterClosed().subscribe(result => {// when closed 
      dialogSubs.unsubscribe();
      if (result) {
        if (this.delegate.getVoidObservable == undefined) {
          return;
        }

        // create void object 
        let voidObj: VoidInfoViewModel = {
          instanceId: (this.objModel as any)[this.identityProperty],
          reason: result.reason,
          voidComment: result.comment
        };

        let voidObjResult = this.delegate.getVoidObservable(voidObj);
        if (voidObjResult == undefined) {
          return;
        }

        if (voidObjResult instanceof Observable) {
          voidObjResult.pipe(first()).subscribe(medcorResult => {// call void service 

            if (medcorResult.result == undefined) return;

            //update voidId and version then reload.
            (this.objModel as any)[this.voidIdProperty] = (medcorResult.result as any)[this.voidIdProperty];
            (this.objModel as any)["version"] = (medcorResult.result as any)["version"];
            this.loadObjectModel(this.objModel);// reload form data 
          }, () => {
            this.actionsDisabled = false;
          }, () => {
            this.actionsDisabled = false;
          });
        } else {
          this.actionsDisabled = false;
          this.loadObjectModel(voidObjResult);
        }
      } else {
        this.actionsDisabled = false;
      }
    });
  }
  /*
  Recover Voided Record 
  */
  Recover(): void {
    this.actionsDisabled = true;
    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Recover " + this.title, message: "Are you sure about recovering this Record?" }
    });// Show recover Dialog 

    let dialogSubs = dialogRef.afterClosed().subscribe(result => {
      dialogSubs.unsubscribe();
      if (result) {
        if (this.delegate.getRecoverObservable == undefined) {
          return;
        }

        let recoverObjResult = this.delegate.getRecoverObservable((this.objModel as any)[this.identityProperty]);
        if (recoverObjResult == undefined) {
          return;
        }
        if (recoverObjResult instanceof Observable) {
          recoverObjResult.pipe(first()).subscribe(medcorResult => {// call recover service 
            this.actionsDisabled = false;
            if (medcorResult.result == undefined) return;

            //update voidId and version then reload.
            (this.objModel as any)[this.voidIdProperty] = (medcorResult.result as any)[this.voidIdProperty];
            (this.objModel as any)["version"] = (medcorResult.result as any)["version"];
            this.loadObjectModel(this.objModel);// reload form data 
          }, () => {
            this.actionsDisabled = false;
          }, () => {
            this.actionsDisabled = false;
          });
        } else {
          this.loadObjectModel(recoverObjResult);// Reload Form Data
        }
      } else {
        this.actionsDisabled = false;
      }
    });
  }

  NavNext(): void {

  }

  NavPrevious(): void {

  }

  NavFirst(): void {

  }

  NavLast(): void {

  }

  CustomAction(): void {
    console.log("CustomAction()");
  }

  hasNew?: boolean = true;
  hasSave?: boolean = true;
  hasSaveAndNew?: boolean = false;
  hasBack?: boolean = true;
  hasSaveAndBack?: boolean = false;
  hasCancel?: boolean = false;
  hasVoid?: boolean = true;
  hasRecover?: boolean = true;
  hasNavNext?: boolean = false;
  hasNavPrevious?: boolean = false;
  hasNavFirst?: boolean = false;
  hasNavLast?: boolean = false;
  hasNavigation?: boolean = false;
  hasVoidRecover?: boolean = true;
  hasCustomIdentifier?: boolean = false;
  hasCustomAction?: boolean = false;
  backText?: string = "Back To List"
  customActionText?: string;
}

