<div *ngIf="dashboardItem" class="fill-parent" [title]="dashboardItem.name">
  <div class="position-relative">


    <div class="position-absolute tag-cont">
      <div class="text-center shadow-cont">
        <div class="position-relative" *ngIf="dashboardItem.tag==='Tag 1'">
          <div class="report-tag-h tag1">
            {{dashboardItem.tagTitle}}
            <div class="position-absolute tag1-shadow"></div>
          </div>
        </div>
        <div class="position-relative" *ngIf="dashboardItem.tag==='Tag 2'">
          <div class="report-tag-h tag2">
            {{dashboardItem.tagTitle}}
            <div class="position-absolute tag2-shadow"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="display-flex dashboard-manage-item-title flex-items-centered">
    <h2 class="flex-basis-0 flex-grow-1">{{dashboardItem.name}}</h2>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 455 455" class="fav-icon"
         [class.fav-icon-not-selected]="!dashboardItem.isSelected"
         [class.fav-icon-selected]="dashboardItem.isSelected" (click)="select()">
      <path d="M434.539,98.499l-38.828-38.828c-5.324-5.328-11.799-7.993-19.41-7.993c-7.618,0-14.093,2.665-19.417,7.993L169.59,247.248   l-83.939-84.225c-5.33-5.33-11.801-7.992-19.412-7.992c-7.616,0-14.087,2.662-19.417,7.992L7.994,201.852   C2.664,207.181,0,213.654,0,221.269c0,7.609,2.664,14.088,7.994,19.416l103.351,103.349l38.831,38.828   c5.327,5.332,11.8,7.994,19.414,7.994c7.611,0,14.084-2.669,19.414-7.994l38.83-38.828L434.539,137.33   c5.325-5.33,7.994-11.802,7.994-19.417C442.537,110.302,439.864,103.829,434.539,98.499z" />
    </svg>
  </div>
  <hr class="main-hr" />
  <div class="text-center" style="padding:10px;">
    <div class="report-thumbnail-cont dash-thumbnail-cont" style="padding:0;">
      <img [src]="dashboardItemImage" class="report-thumbnail" />

    </div>
  </div>
</div>
