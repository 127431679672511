<div class='flex-cell input-cont'>
    <mat-checkbox [(ngModel)]="value"
                  [id]="checkboxIdentifier"
                  [disabled]="isDisabled"
                  (change)="checkBoxChangedEvent($event)"
                  [(indeterminate)]="indeterminate"
                  class="mat-checkbox">
    </mat-checkbox>
</div>

<div class='flex-cell label-cont' *ngIf="label">
    <label [attr.for]="checkboxIdentifier">{{label}}</label>
</div>

<div class="flex-cell">
    <validation-message *ngIf="invalid && model.control.touched" [messages]="failures"></validation-message>
</div>
