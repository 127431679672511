<div style="height:100vh;">
  <div class="upload-requested-files">
    <div class="display-flex flex-items-centered">
      <div class="logo-cont"><img src="assets/images/medcorLogo.svg" class="main-logo" /></div>
    </div>
    <div *ngIf="!filesUploaded">
      <form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
        <div>
          <h1 class="main-title">Upload Requested File(s)</h1>
        </div>
        <hr class="main-hr" />
        <div class="display-flex break-la flex-items-centered cont-padding">
          <div class="flex-child flex-basis-0 flex-grow-1 break-sm" style="margin-right:8px;">
            <ichs-text-box name="pinCode" label="PIN Code" [(ngModel)]="filePinCode" required></ichs-text-box>
          </div>
          <div style="align-self: flex-end" class="flex-child">
            <button class="cp-btn cp-main-btn" (click)="validatePinCode()">Submit</button>
          </div>
        </div>

        <div class="cont-padding" *ngIf="pinCodeValidated">
          <div class="margin-top-def">
            <h5>Request Title: {{requestTitle}}</h5>
          </div>
          <div class="margin-top-def" *ngIf="files.length > 0">
            <h4>Selected File(s):</h4>
            <div *ngFor="let file of files; index as i" class="display-flex flex-items-centered file-item">
              <div class="flex-grow-1 flex-basis-0">{{file.name}}</div>
              <div>
                <button title="Remove File" class="cp-btn cp-chip-btn" (click)="removeFile(i)">
                  <img class="remove-file-icon" src="assets/images/delete-cross.svg" />
                </button>
              </div>
            </div>
          </div>

          <div class="display-flex flex-items-centered margin-top-def">
            <div>
              <input #fileInput type="file" (change)="onFileSelected($event)" style="display:none" multiple>
              <button class="cp-btn cp-mail-btn" (click)="fileInput.click()" style="margin-right:8px;">
                {{files.length == 0 ? 'Choose file from Computer' : 'Choose another file'}}
              </button>
            </div>
            <div>
              <button class="cp-btn cp-main-btn" (click)="uploadFiles()">Submit file(s)</button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="filesUploaded" style="text-align: center;min-height: 60px;">
      <h1 class="thank-you">Thank You!</h1>
    </div>
  </div>
</div>
