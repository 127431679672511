import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { AccountsService, ChangePasswordViewModel } from '../../rest';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';
import { first } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { NgForm, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ValidationMessages } from '../../app.general.constants';

@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
/** change-password component*/
export class ChangePasswordComponent implements OnInit {
  @ViewChild('form') ngForm: NgForm;
  tokenPart: string;
  changePasswordObj: ChangePasswordViewModel = <ChangePasswordViewModel>{};
  @Output() redirectToLogin: EventEmitter<string> = new EventEmitter<string>();
  confirmPassword: string = "";
  redirectTo: string;

  /** change-password ctor */
  constructor(
    private accService: AccountsService,
    private alertService: MedcorAlertService,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    this.redirectTo = decodeURIComponent(location.search.split('&')[0].replace('?redirectTo=', ''));

    let pathParts: string[] = location.pathname.split("/");
    if (pathParts.length < 3) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Invalid File Request Link!"]
      });
      return;
    }

    this.tokenPart = pathParts[pathParts.length - 1];
    this.changePasswordObj.token = this.tokenPart;
  }


  saveNewPassword() {
    this.validateAllFormFields(this.ngForm.form);
    if (this.ngForm.form.valid) {
      this.accService.changePassword(this.changePasswordObj).pipe(first()).subscribe(() => {
        if (this.redirectTo) {
          setTimeout(() => location.replace(this.redirectTo), 1000);
        }
        else {
          this.redirectToLogin.emit();
        }
      });
    }
    else {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
