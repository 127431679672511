import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmailsListComponent } from './emails-list/emails-list.component';
import { EmailContainterComponent } from './email-containter/email-containter.component';
import { EmailComposerComponent } from './email-composer/email-composer.component';
import { EmailDetailsComponent } from './email-details/email-details.component';
import { RouterModule } from '@angular/router';
import { CanActivateGuard, CanDeactivateGuard } from '../services/routing-guard.service';
import { AppModuleShared } from '../app.shared.module';
import { EmailItemComponent } from './email-item/email-item.component';
import { QuillModule } from 'ngx-quill';
import { ReactiveFormsModule } from '@angular/forms';
import { SendCallRecordComponent } from './send-call-record/send-call-record.component';
import * as QuillNamespace from 'quill';
import { EmailExternalViewComponent } from '../external-service/email-external-view/email-external-view.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CreateSecureMessagingAccountComponent } from '../external-service/create-secure-messaging-account/create-secure-messaging-account.component';
import { SecuredFileAttachmentComponent } from './secured-file-attachment/secured-file-attachment.component';

const Quill: any = QuillNamespace;
const BlockEmbed = Quill.import('blots/block/embed');

export class HorizontalRule extends BlockEmbed {
  static blotName = 'hr';
  static tagName = 'hr';
}

Quill.register(HorizontalRule);

@NgModule({
    imports: [
        CommonModule,
        AppModuleShared,
        MatCheckboxModule,
        QuillModule.forRoot({
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'],
                    ['blockquote', 'code-block'],
                    [{ 'header': 1 }, { 'header': 2 }],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'script': 'sub' }, { 'script': 'super' }],
                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                    [{ 'direction': 'rtl' }],
                    [{ 'size': ['small', false, 'large', 'huge'] }],
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    [{ 'color': [] }, { 'background': [] }],
                    [{ 'align': [] }],
                    ['clean'],
                    ['link', 'image', 'video'] // link and image, video
                ]
            }
        }),
        ReactiveFormsModule,
        RouterModule.forRoot([
            { path: 'securedEmailAccess/:email/:id', component: EmailExternalViewComponent },
            { path: 'create-secure-messaging-account/:email/:id/:pin', component: CreateSecureMessagingAccountComponent },
        ], {}),
        RouterModule.forChild([
            {
                path: 'secureMessaging',
                component: EmailContainterComponent,
                children: [
                    { path: '', redirectTo: '/secureMessaging/inbox', pathMatch: 'full' },
                    { path: 'inbox', component: EmailsListComponent },
                    { path: 'archive', component: EmailsListComponent },
                    { path: 'sent', component: EmailsListComponent },
                    { path: 'notifications', component: EmailsListComponent },
                    { path: 'saved', component: EmailsListComponent },
                    { path: 'sent/:id', component: EmailDetailsComponent },
                    { path: 'new', canDeactivate: [CanDeactivateGuard], component: EmailComposerComponent },
                    { path: 'new/:id', canDeactivate: [CanDeactivateGuard], component: EmailComposerComponent },
                    { path: 'inbox/:id', component: EmailDetailsComponent },
                    { path: 'archive/:id', component: EmailDetailsComponent },
                    { path: 'notifications/:id', component: EmailDetailsComponent },
                    { path: 'saved/:id', component: EmailDetailsComponent },
                    { path: 'externalLink/:id', component: EmailDetailsComponent },
                    { path: 'forward/:id', canDeactivate: [CanDeactivateGuard], component: EmailComposerComponent },
                ]
            }
        ]),
    ],
    providers: [CanActivateGuard, CanDeactivateGuard,],
    declarations: [
        EmailsListComponent,
        EmailContainterComponent,
        EmailComposerComponent,
        EmailDetailsComponent,
        EmailItemComponent,
        EmailExternalViewComponent,
        SendCallRecordComponent,
        CreateSecureMessagingAccountComponent,
        SecuredFileAttachmentComponent,
    ],
    exports: [
        EmailExternalViewComponent,
        CreateSecureMessagingAccountComponent,
    ]
})
export class SecuredEmailModule { }
