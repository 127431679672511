import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserSecuredFileService, UserFolderViewModel, UserSecuredFileViewModel } from '../../../rest';
import { first } from 'rxjs/operators';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../../services/medcor-alert.service';

@Component({
  selector: 'app-move-file',
  templateUrl: './move-file.component.html',
  styleUrls: ['./move-file.component.css']
})
export class MoveFileComponent implements OnInit {

  folders: UserFolderViewModel[] = [];
  path: UserFolderViewModel[] = [];
  inReceivedFilesFolder: boolean = false;
  inRecordedCallsFolder: boolean = false;
  inSecureReportsFolder: boolean = false;
  inRootFolder: boolean = true;

  constructor(private dialogRef: MatDialogRef<MoveFileComponent, number>,
    @Inject(MAT_DIALOG_DATA) private fileToMove: UserSecuredFileViewModel,
    private userFiles: UserSecuredFileService,
    private alertService: MedcorAlertService) {
  }

  ngOnInit() {
    this.loadRoot();
  }

  loadRoot() {
    this.inRootFolder = true;
    this.inReceivedFilesFolder = false;
    this.inRecordedCallsFolder = false;
    this.inSecureReportsFolder = false;

    this.userFiles.listFolders(1).pipe(first()).subscribe(result => {
      if (result && result.statusCode == 200) {
        this.folders = result.result!;
        this.path = [];
      }
    });
  }

  loadReceivedFilesFolder() {
    this.inRootFolder = false;
    this.inReceivedFilesFolder = true;
    this.inRecordedCallsFolder = false;
    this.inSecureReportsFolder = false;
  }

  loadRecordedCallsFolder() {
    this.inRootFolder = false;
    this.inReceivedFilesFolder = false;
    this.inRecordedCallsFolder = true;
    this.inSecureReportsFolder = false;
  }

  loadSecureReportsFolder() {
    this.inRootFolder = false;
    this.inReceivedFilesFolder = false;
    this.inRecordedCallsFolder = false;
    this.inSecureReportsFolder = true;
  }

  loadFolders(folder: UserFolderViewModel) {
    this.inRootFolder = false;
    this.inReceivedFilesFolder = false;
    this.inRecordedCallsFolder = false;
    this.inSecureReportsFolder = false;

    this.userFiles.listFolders(folder.id).pipe(first()).subscribe(result => {
      if (result && result.statusCode == 200) {
        this.folders = result.result!;
        this.path.push(folder);
      }
    });
  }

  navigateTofolder(folder: UserFolderViewModel, index: number) {
    this.inRootFolder = false;
    this.inReceivedFilesFolder = false;
    this.inRecordedCallsFolder = false;
    this.inSecureReportsFolder = false;

    this.userFiles.listFolders(folder.id).pipe(first()).subscribe(result => {
      if (result && result.statusCode == 200) {
        this.folders = result.result!;
        this.path.splice(index + 1);
      }
    });
  }

  moveFileTo() {

    if (this.inRootFolder) {  // move file to Root
      if (this.fileToMove.folderId == null && !this.fileToMove.isReceivedFile && !this.fileToMove.isCallRecording && !this.fileToMove.isSecureReport) { // if already in Root
        this.showAlert();
      }
      else {
        this.dialogRef.close(1)   // 1 = Root Folder ID
      }
    }
    else if (this.inReceivedFilesFolder) {  // move file to "Received Files"
      if (this.fileToMove.isReceivedFile) {   // if already in "Received Files"
        this.showAlert();
      }
      else {
        this.dialogRef.close(3)   // 3 = "Received Files" Folder ID
      }
    }
    else if (this.inRecordedCallsFolder) {  // move file to "Recorded Calls"
      if (this.fileToMove.isCallRecording) {  // if already in "Recorded Calls"
        this.showAlert();
      }
      else {
        this.dialogRef.close(4)   // 4 = "Recorded Calls" Folder ID
      }
    }
    else if (this.inSecureReportsFolder) {  // move file to "Reports Folder"
      if (this.fileToMove.isSecureReport) {  // if already in "Reports Folder"
        this.showAlert();
      }
      else {
        this.dialogRef.close(5)   // 5 = "Reports Folder" Folder ID
      }
    }
    else {  // move file to one of user folders

      let targetFolder = this.path.pop();

      if (targetFolder.id == this.fileToMove.folderId) {  // file is already in this target folder
        this.path.push(targetFolder);
        this.showAlert();
      }
      else {
        this.dialogRef.close(targetFolder.id);
      }
    }
  }

  showAlert() {
    this.alertService.addAlert({
      type: AlertMessageType.warning,
      title: 'Warning!',
      dismiss: AlertDismissType.controlled,
      messages: ["This file is already in this folder!"]
    });
  }
}
