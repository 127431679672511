<div class="secured-files cont-padding">
    <div class="secured-files-tools display-flex flex-items-centered">
        <div><a [routerLink]="['/user-shared-folders']">Shared Folders</a>&nbsp;</div>
        <div class="flex-grow-1 flex-basis-0">
            <ng-container *ngFor="let item of pathName; index as i">
                / <a [routerLink]="['/user-shared-folders/'+pathId[i]]">{{item}}</a>
            </ng-container>
        </div>
    
        <div *ngIf="currentFolderHaveEditPermission && !generalVariables.userImpersonationInfo.impersonatorRoleInd">
            <button class="cp-btn cp-main-btn" (click)="fileInput.click()">Upload Files</button>
        </div>
    
        <input #fileInput (change)="onFileSelected($event)" type="file" multiple style="display:none" />
    </div>

    <hr class="main-hr" />

    <div class="main-folder display-flex flex-wrap" 
            ng2FileDrop 
            [uploader]="uploader" 
            (fileOver)="areaFileOver($event,'currentFolder')"
            [ngClass]="{'file-over-style': dropArea=='currentFolder' && currentFolderHaveEditPermission}" 
            (onFileDrop)="dropFolder(null)">

        <div *ngIf="folderId == 1 && noSharedFolders" style="margin:auto">
            <h3>You don't have any shared folders!</h3>
        </div>

        <div style="margin:auto" *ngIf="folderId != 1 && files && files.length == 0 && 
                     folders && folders.length == 0 && currentFolderHaveEditPermission && !generalVariables.userImpersonationInfo.impersonatorRoleInd">
            <h3>Drop files here</h3>
        </div>

        <div class="user-folder" 
            ng2FileDrop
            [uploader]="uploader"
            (fileOver)="areaFileOver($event,folder.id)"
            (onFileDrop)="dropFolder(folder)" 
            [ngClass]="{'file-over-style': dropArea==folder.id && (currentFolderHaveEditPermission || folder.haveEditPermission)}" 
            (dblclick)="routeToFolder(folder.id)"
            [title]="folder.name"
            *ngFor="let folder of folders">

            <div class="folder-visuals">
                <img src="assets/images/newIcons/folder.svg" class="folder-icon" draggable="false" />
                <span class="file-item-name">{{folder.name}}</span>

                <button *ngIf="currentFolderHaveEditPermission && !this.generalVariables.userImpersonationInfo.impersonatorRoleInd" #folderTrigger mat-button class="menu-btn file-menu" [matMenuTriggerFor]="folderMenu">...</button>
                <mat-menu #folderMenu="matMenu">
                    <button mat-menu-item (click)="renameFolder(folder)">Rename</button>
                    <button mat-menu-item (click)="deleteFolder(folder)">Delete</button>
                </mat-menu>
            </div>
        </div>

        <div class="user-file" 
             (dblclick)="viewFile(file.id)" 
             [title]="file.name" 
             [title]="file.expirationDate ? file.name + ' (will be expired in ' + durationFromNow(file.expirationDate) + ')' : file.name" 
             *ngFor="let file of files">

            <div class="file-visuals">
                <img src="assets/images/newIcons/file.svg" class="file-icon" draggable="false" />
                <div class="position-absolute file-type-tag" *ngIf="isCallRecording(file)">
                    Call Recording
                </div>
                <div class="position-absolute file-type-tag" *ngIf="isPhiFile(file)">
                    PHI
                </div>
                <span class="file-item-name">{{file.name}}</span>

                <button #fileTrigger mat-button class="menu-btn file-menu" [matMenuTriggerFor]="menu">...</button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="viewFile(file.id)">View</button>
                    <button mat-menu-item (click)="downloadFile(file.id)">Download</button>
                    <button mat-menu-item (click)="shareFile(file)">Share File</button>
                    <button *ngIf="currentFolderHaveEditPermission && !this.generalVariables.userImpersonationInfo.impersonatorRoleInd" mat-menu-item (click)="renameFile(file)">Rename</button>
                    <button *ngIf="currentFolderHaveEditPermission && !this.generalVariables.userImpersonationInfo.impersonatorRoleInd" mat-menu-item (click)="deleteFile(file.id)">Delete</button>
                </mat-menu>
            </div>
        </div>

    </div>
    <mat-progress-spinner class="upload-spinner" *ngIf="uploader.isUploading" [value]="uploader.progress" mode="determinate"></mat-progress-spinner>
</div>
