<div class='widget-padding display-flex flex-items-centered command-toolbar margin-bottom-def' >
    <div class="flex-basis-0 flex-grow-1">
        <button *ngIf="model.hasCustomAction" class='cp-btn cp-command-btn' (click)='model.CustomAction()' [disabled]="model.actionsDisabled">{{model.customActionText}}</button>
        <button *ngIf="model.hasBack" class='cp-btn cp-command-btn' (click)='model.Back()'>{{model.backText}}</button>
        <button *ngIf="model.hasSave" class='cp-btn cp-command-btn' (click)='model.SaveAndReload()' [disabled]="model.actionsDisabled">Save</button>
        <button *ngIf="model.hasSaveAndBack" class='cp-btn cp-command-btn' (click)='model.SaveAndBack()' [disabled]="model.actionsDisabled">Save And Back</button>
        <button *ngIf="model.hasCancel" class='cp-btn cp-command-btn' (click)='model.Cancel()'>Cancel</button>
        <button *ngIf="model.hasVoidRecover && model.hasVoid" class='cp-btn cp-command-btn' (click)='model.Void()' [disabled]="model.actionsDisabled">Void</button>
        <button *ngIf="model.hasVoidRecover && model.hasRecover" class='cp-btn cp-command-btn' (click)='model.Recover()' [disabled]="model.actionsDisabled">Recover</button>
    </div>

    <div *ngIf="model.hasNavigation">
        <a *ngIf="model.hasNavFirst" class='cp-btn cp-grid-head-btn' (click)='model.NavFirst()'> << </a>
        <a *ngIf="model.hasNavPrevious" class='cp-btn cp-grid-head-btn' (click)='model.NavPrevious()'> < </a>
        <a *ngIf="model.hasNavNext" class='cp-btn cp-grid-head-btn' (click)='model.NavNext()'> > </a>
        <a *ngIf="model.hasNavLast" class='cp-btn cp-grid-head-btn' (click)='model.NavLast()'> >> </a>
    </div>
</div>
