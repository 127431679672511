<div>
  <input #inputImage (change)="imageSelected($event)" type="file" style="display:none" accept="image/x-png,image/gif,image/jpeg" />
  <div class="text-center ">
    <button class="cp-btn cp-main-btn select-img-btn" (click)="inputImage.click()">Select Image</button>
  </div>

  <div [hidden]="!isUploadingImage" class="center" style="padding:15%;">
    <mat-spinner style="margin:auto;"></mat-spinner>
    <p style="text-align: center; padding:15px; margin:auto; font-weight:bold;">Loading Image...</p>
  </div>

  <div style="margin: auto;">
    <image-cropper 
      [imageChangedEvent]="imageChangedEvent" 
      [maintainAspectRatio]="cropperConfig.maintainAspectRatio"
      [aspectRatio]="cropperConfig.aspectRatio" 
      [resizeToWidth]="cropperConfig.resizeToWidth" 
      [onlyScaleDown]="cropperConfig.onlyScaleDown"
      [roundCropper]="cropperConfig.roundCropper" 
      [output]="cropperConfig.outputType"
      [autoCrop]="false" 
      format="png" 
      (imageLoaded)="imageLoaded()" 
      (cropperReady)="cropperReady()" 
      (loadImageFailed)="loadImageFailed()"
      [style.display]="hideCropper ? 'none' : null">
    </image-cropper>
  </div>

  <div>
    <button [disabled]="!isCropperReady" class="cp-btn cp-main-btn" (click)="saveCroppedImage()">Save</button>
    <button class="cp-btn cp-mail-btn" mat-dialog-close>Cancel</button>
  </div>
</div>
