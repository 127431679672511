import { Injectable, RendererFactory2 } from "@angular/core";
import { Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LoggedUserViewModel } from "../rest";
import { AppSettings } from '../app.general.constants';

@Injectable()
export class ZendeskService {

    private _renderer2: Renderer2;
    private visible: boolean = false;

    constructor(
        @Inject(DOCUMENT) private _document: Document,
        rendererFactory: RendererFactory2,
    ) {
        this._renderer2 = rendererFactory.createRenderer(null, null);
    }

    public showZendesk(userInfo: LoggedUserViewModel): void {
        this.configureZendesk(userInfo);
        this.loadZendesk();
        this.visible = true;
    }

    public hideZendesk(): void {
        if (this.visible) {
            // remove old element (if any)
            let zendeskHideElement = this._document.getElementById('zendesk-hide');
            if (zendeskHideElement) {
                zendeskHideElement.remove();
            }

            // code to hide zendesk feature
            let hideZendeskJsCode = `
                zE('webWidget', 'hide'); 
                zE('webWidget', 'close');
                zE('webWidget', 'clear');
            `;

            let hideZendeskJsScript = this._renderer2.createElement('script');
            hideZendeskJsScript.text = hideZendeskJsCode;
            hideZendeskJsScript.id = 'zendesk-hide';
            this._renderer2.appendChild(this._document.body, hideZendeskJsScript);
        }
    }

    private configureZendesk(userInfo: LoggedUserViewModel) {
        let prefillFields = {
            name: {
                value: userInfo.fullUserName,
                readOnly: true,
            },
            email: {
                value: userInfo.userEmail,
                readOnly: true,
            },
        };

        let zendeskSettings = {
            webWidget: {
                contactForm: {
                    fields: [
                        {
                            id: 'name',
                            prefill: {
                                '*': userInfo.fullUserName,
                            },
                        },
                        {
                            id: 'email',
                            prefill: {
                                '*': userInfo.userEmail,
                            },
                        },
                        {
                            id: 360033586552,
                            prefill: {
                                '*': userInfo.clientName,
                            },
                        }]
                },
                chat: {
                    departments: {
                        enabled: []
                    }
                }
            }
        };

        // code to update zendesk form, prefill fields and show it
        let configureZendeskJsCode = `function configureZendesk() { 
            zE('webWidget', 'prefill', ${JSON.stringify(prefillFields)});
            zE('webWidget', 'updateSettings', ${JSON.stringify(zendeskSettings)});
            zE('webWidget', 'show'); 
        }`;

        // remove old element (if any)
        let zendeskConfigureElement = this._document.getElementById('zendesk-configure');
        if (zendeskConfigureElement) {
            zendeskConfigureElement.remove();
        }

        let configureZendeskScript = this._renderer2.createElement('script');
        configureZendeskScript.text = configureZendeskJsCode;
        configureZendeskScript.id = 'zendesk-configure';
        this._renderer2.appendChild(this._document.body, configureZendeskScript);
    }

    private loadZendesk() {
        // remove old element (if any)
        let zendeskMainElement = this._document.getElementById('ze-snippet');
        if (zendeskMainElement) {
            zendeskMainElement.remove();
        }

        // zendesk main script
        let zendeskMainScript = this._renderer2.createElement('script');
        zendeskMainScript.id = "ze-snippet";
        zendeskMainScript.src = AppSettings.ZENDESK_SCRIPT_SRC_URL;
        zendeskMainScript.onload = () => { window['configureZendesk'](); };
        this._renderer2.appendChild(this._document.head, zendeskMainScript);
    }
}