import { Component, OnInit, Inject } from '@angular/core';
import { ValidationMessages } from 'src/app/app.general.constants';
import { IchsControlType, IchsGridComponentConfig } from 'src/app/controls/ichs-grid/grid-configs';
import { SearchViewModel, ShortUserViewModel, SelectionItemViewModel, SharedFileService, ShareFileViewModel, SharedFileViewModel, SharedFolderService } from 'src/app/rest';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from 'src/app/services/medcor-alert.service';
import { EXTRA_DATA, IchsComponentDialogComponent, DIALOG_REF } from 'src/app/controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { AppGenericLists } from '../../app.general.lists';
import { first } from 'rxjs/operators';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IchsGridDialogComponent } from '../../controls/ichs-grid-dialog/ichs-grid-dialog.component';

@Component({
  selector: 'share-file',
  templateUrl: './share-file.component.html',
  styleUrls: ['./share-file.component.css']
})
export class ShareFileComponent implements OnInit {

  userIds: number[] = [];
  usersGridConfig: IchsGridComponentConfig<ShortUserViewModel>;

  fileType: string;
  fileTypesListItem: SelectionItemViewModel[] = AppGenericLists.FileType;

  /** send-file ctor */
  constructor(
    private alertService: MedcorAlertService,
    private sharedFileService: SharedFileService,
    private sharedFolderService: SharedFolderService,
    private dialog: MatDialog,
    public generalVariables: GeneralVariablesService,
    @Inject(EXTRA_DATA) public fileToShare: SharedFileViewModel,
    @Inject(DIALOG_REF) private dialogRef: MatDialogRef<IchsComponentDialogComponent>,
  ) {
    this.fileType = fileToShare.type;
  }

  ngOnInit() {
    this.usersGridConfig = this.defineUsersAddExistingGrid();
  }

  shareFile() {
    if (this.userIds.length == 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: [ValidationMessages.NO_RECIPIENT]
      });

      return;
    }

    if (!this.fileType) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: ["Please select a type for the file."]
      });

      return;
    }

    let objPost: ShareFileViewModel = {
      fileId: this.fileToShare.id,
      userIds: this.userIds,
      type: this.fileType,
    };

    this.sharedFileService.sendFileToUsers(objPost)
      .pipe(first())
      .subscribe(() => {
        this.dialogRef.close();
      });
  }


  openUsersGrid() {
    let dialogRef = this.dialog.open(IchsGridDialogComponent, {
      width: '1024px',
      height: '800px',
      data: {
        config: this.usersGridConfig,
        hasAddExisting: true,
        title: "Users with access to this shared folder"
      }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(res => {
      if (res && res.addSelectedInd === true) {
        const newIds = this.usersGridConfig.multiSelectConfig!.getSelected!();
        this.userIds = newIds.map(id => +id);
      }
    });
  }


  private defineUsersAddExistingGrid(): IchsGridComponentConfig<ShortUserViewModel> {
    let configs: IchsGridComponentConfig<ShortUserViewModel> = {
      primaryId: "id",
      defaultOrder: "email",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      multiSelectConfig: { allowSelectObjects: true, },
      entityDataSource: (filter: SearchViewModel) => this.sharedFolderService.searchUsersWithAccessToFolder(this.fileToShare.mainFolderId, filter),
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ]
    }
    return configs;
  }
}
