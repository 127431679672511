/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * 
 */
export type DeviceType = 'Browser' | 'IPhone' | 'Android';

export const DeviceType = {
    Browser: 'Browser' as DeviceType,
    IPhone: 'IPhone' as DeviceType,
    Android: 'Android' as DeviceType
};
