import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccountsService } from './api/accounts.service';
import { AdvancedPracticeService } from './api/advancedPractice.service';
import { AfkamService } from './api/afkam.service';
import { AfkamCubeService } from './api/afkamCube.service';
import { AppUserService } from './api/appUser.service';
import { ChangeLogService } from './api/changeLog.service';
import { CommonQuestionService } from './api/commonQuestion.service';
import { DashboardService } from './api/dashboard.service';
import { DashboardItemService } from './api/dashboardItem.service';
import { DashboardSchedulerService } from './api/dashboardScheduler.service';
import { ExecutiveCubeService } from './api/executiveCube.service';
import { FileRequestService } from './api/fileRequest.service';
import { GroupService } from './api/group.service';
import { GroupAppUserAssociationService } from './api/groupAppUserAssociation.service';
import { GroupDashboardItemAssociationService } from './api/groupDashboardItemAssociation.service';
import { GroupReportItemAssociationService } from './api/groupReportItemAssociation.service';
import { HelpCategoryService } from './api/helpCategory.service';
import { LineOfBusinessService } from './api/lineOfBusiness.service';
import { LineOfBusinessPropertyService } from './api/lineOfBusinessProperty.service';
import { MedFilesService } from './api/medFiles.service';
import { MedfilesAfterCareInstructionsService } from './api/medfilesAfterCareInstructions.service';
import { MedfilesVirtualCompanyService } from './api/medfilesVirtualCompany.service';
import { NextgenService } from './api/nextgen.service';
import { NotificationService } from './api/notification.service';
import { OshaReportableService } from './api/oshaReportable.service';
import { PageDesignService } from './api/pageDesign.service';
import { PublicFileService } from './api/publicFile.service';
import { ReportItemService } from './api/reportItem.service';
import { ReportSchedulerService } from './api/reportScheduler.service';
import { ReportingService } from './api/reporting.service';
import { ScreeningService } from './api/screening.service';
import { SecureFileService } from './api/secureFile.service';
import { SecuredEmailsService } from './api/securedEmails.service';
import { SelectionListService } from './api/selectionList.service';
import { ServiceRequestService } from './api/serviceRequest.service';
import { SharedFileService } from './api/sharedFile.service';
import { SharedFolderService } from './api/sharedFolder.service';
import { SharedFolderUserPermissionService } from './api/sharedFolderUserPermission.service';
import { TeleHealthService } from './api/teleHealth.service';
import { TriagePortalUserService } from './api/triagePortalUser.service';
import { TriageReportCustomHierarchySelectionService } from './api/triageReportCustomHierarchySelection.service';
import { TriageSecuredFileService } from './api/triageSecuredFile.service';
import { UserApplicationAccessService } from './api/userApplicationAccess.service';
import { UserContactService } from './api/userContact.service';
import { UserSecuredFileService } from './api/userSecuredFile.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AccountsService,
    AdvancedPracticeService,
    AfkamService,
    AfkamCubeService,
    AppUserService,
    ChangeLogService,
    CommonQuestionService,
    DashboardService,
    DashboardItemService,
    DashboardSchedulerService,
    ExecutiveCubeService,
    FileRequestService,
    GroupService,
    GroupAppUserAssociationService,
    GroupDashboardItemAssociationService,
    GroupReportItemAssociationService,
    HelpCategoryService,
    LineOfBusinessService,
    LineOfBusinessPropertyService,
    MedFilesService,
    MedfilesAfterCareInstructionsService,
    MedfilesVirtualCompanyService,
    NextgenService,
    NotificationService,
    OshaReportableService,
    PageDesignService,
    PublicFileService,
    ReportItemService,
    ReportSchedulerService,
    ReportingService,
    ScreeningService,
    SecureFileService,
    SecuredEmailsService,
    SelectionListService,
    ServiceRequestService,
    SharedFileService,
    SharedFolderService,
    SharedFolderUserPermissionService,
    TeleHealthService,
    TriagePortalUserService,
    TriageReportCustomHierarchySelectionService,
    TriageSecuredFileService,
    UserApplicationAccessService,
    UserContactService,
    UserSecuredFileService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
