import { HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { AppUserService, SecureFileService } from 'src/app/rest';
import { GeneralVariablesService } from 'src/app/services/general-variables.service';

@Component({
  selector: 'about-client-portal',
  templateUrl: './about-client-portal.component.html',
  styleUrls: ['./about-client-portal.component.scss']
})
/** about-client-portal component*/
export class AboutClientPortalComponent implements OnInit {
  /** about-client-portal ctor */
  constructor(
    public dialog: MatDialog,
    private appuserService: AppUserService,
    private secureFileService: SecureFileService,
    private generalVariables: GeneralVariablesService,
  ) {
  }

  ngOnInit() {

  }

  viewReleaseNotes() {
    let sub = this.secureFileService.downloadReleaseNotes('response', true)
      .subscribe({
        next: (event) => {
          if (event.type == HttpEventType.Response) {
            var fileURL = URL.createObjectURL(event.body);
            window.open(fileURL);
          }
        },
        error: () => sub.unsubscribe(),
        complete: () => sub.unsubscribe(),
      });

    if (this.generalVariables.systemCounters.newReleaseNoteReadInd) {
      this.generalVariables.systemCounters.newReleaseNoteReadInd = false;
      this.appuserService.updateUserReleaseNote().pipe(first()).subscribe();
    }
  }
}
