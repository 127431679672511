import { Component, Input, OnInit } from '@angular/core';
import { AppUserViewModel, ContactViewModel, SecureFileService } from '../../rest/index';
import { Router } from '@angular/router';
import { HttpEventType } from '@angular/common/http';

@Component({
    selector: 'appuser-card-template',
    templateUrl: './appuser-card-template.component.html',
    styleUrls: ['./appuser-card-template.component.css']
})
/** appuser-card-template component*/
export class AppuserCardTemplateComponent implements OnInit {
    @Input() appuser: AppUserViewModel;
    @Input() logoImage: string;
    @Input() loadLogoImage: boolean = true;
    @Input() previewOnly: boolean = false;

    contactInfo: ContactViewModel = {};
    public regularEmail: string
    public officePhone: string;

    /** appuser-card-template ctor */
    constructor(
        private router: Router,
        private secureFileService: SecureFileService,
    ) {
    }

    ngOnInit() {
        this.contactInfo.id = this.appuser.id;
        this.contactInfo.name = this.appuser.name;
        this.contactInfo.method = this.appuser.email;
        this.regularEmail = "mailto:" + this.appuser.email;
        this.officePhone = "tel:+" + this.appuser.appUserExtension!.office;

        if (this.loadLogoImage && this.appuser.appUserExtension.logoId) {
            this.secureFileService.downloadFile(this.appuser.appUserExtension.logoId, 'events', true).subscribe(event => {
                if (event.type == HttpEventType.Response) {
                    var reader = new FileReader();
                    reader.onload = (event: any) => {
                        this.logoImage = event.target.result;
                    }
                    reader.readAsDataURL(event.body!);
                }
            });
        }
    }

    sendSecureEmail() {
        this.router.navigate(['/secureMessaging/new'], {
            queryParams: {
                recipient: JSON.stringify(this.contactInfo)
            }
        });
    }
}
