import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { SelectionItemViewModel, AppUserService, DashboardItemViewModel, DashboardSchedulerService, SelectionTreeViewModel, AfkamCubeService, MedFilesService, AdvancedPracticeService, OshaReportableService } from '../../rest/index';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgForm, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AlertMessageType, AlertDismissType, MedcorAlertService } from '../../services/medcor-alert.service';
import { first } from 'rxjs/operators';
import { ValidationMessages, LineOfBusiness } from '../../app.general.constants';
import * as moment from 'moment';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { AppGenericLists } from '../../app.general.lists';
import { Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { LoggedUserViewModel } from '../../rest/model/loggedUserViewModel';
import { MedcorUserInfoService } from '../../services/medcor-user-info.service';
import { ReportScheduleReceiver as DashboardScheduleReceiver } from '../reporting-scheduler/reporting-scheduler.component';
import { DashboardSchedulerViewModel } from 'src/app/rest/model/dashboardSchedulerViewModel';
import { DashboardHierarchyConfig, DashboardParametersDialogComponent, DashboardParametersDialogConfig } from '../dashboard-parameters-dialog/dashboard-parameters-dialog.component';
import { AdvancedDateFilterationAttributes, DashboardFilterationValues } from '../dashboard/dashboard.component';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { TriageDashboardFilterControlsConfig } from '../dashboard/triage-dashboard/triage-dashboard.component';
import { OccupationalHealthDashboardFilterControlsConfig } from '../dashboard/occupational-health-dashboard/occupational-health-dashboard.component';
import { SharedFiltersHelper } from 'src/app/controls/ichs-dialog/shared-filters-helper.component';
import { AdvancedPracticeDashboardFilterControlsConfig } from '../dashboard/advanced-practice-dashboard/advanced-practice-dashboard.component';
import { OshaReportableDashboardFilterControlsConfig } from '../dashboard/osha-reportable-dashboard/osha-reportable-dashboard.component';

@Component({
  selector: 'dashboard-scheduler',
  templateUrl: './dashboard-scheduler.component.html',
  styleUrls: ['./dashboard-scheduler.component.css']
})
export class DashboardSchedulerComponent implements OnInit {

  config: DashboardSchedulerConfig;
  @ViewChild("form") ngForm: NgForm;

  enteredEmail: string = "";

  isLoadingParameters: boolean = false;

  model: DashboardSchedulerViewModel = <DashboardSchedulerViewModel>{
    receivers: [],
  };

  ScheduleModeItems: SelectionItemViewModel[] = AppGenericLists.ReportScheduleMode;

  userHasScheduleReportFullAccess: boolean;
  isNew: boolean = true;
  dashboardParamsSet: boolean = false;
  dashboardParameters: string;

  get loggedInUserObs(): Observable<LoggedUserViewModel> { return this.medcorUserInfoService.loggedInUserObs }

  scheduleReceivers: DashboardScheduleReceiver[] = [];

  private dateHierarchyLoader = new BehaviorSubject<null>(null);
  private secondHierarchyLoader = new BehaviorSubject<null>(null);
  private thirdHierarchyLoader = new BehaviorSubject<null>(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DashboardSchedulerConfig,
    public dialogRef: MatDialogRef<DashboardSchedulerComponent>,
    public generalVariables: GeneralVariablesService,
    private dashboardSchedulerService: DashboardSchedulerService,
    private appuserService: AppUserService,
    private dialog: MatDialog,
    private alertService: MedcorAlertService,
    private medcorUserInfoService: MedcorUserInfoService,
    private sharedFunctions: SharedFunctionsService,
    private sharedFilterHelper: SharedFiltersHelper,
    private afkamCubeService: AfkamCubeService,
    private medfilesService: MedFilesService,
    private advPracticeService: AdvancedPracticeService,
    private oshaReportableService: OshaReportableService,
  ) {
    this.config = data;
  }

  ngOnInit() {
    this.loggedInUserObs.pipe(first()).subscribe(loggedInUser => {
      this.userHasScheduleReportFullAccess = loggedInUser.allowScheduleReports;

      // if user is trying to create a new schedule, but he doesn't have userHasScheduleReportFullAccess, then add his email only to receivers list
      if (this.config.dashboard && !this.userHasScheduleReportFullAccess) {
        this.scheduleReceivers.push({ receiverEmail: loggedInUser.userEmail, recieverHasAccount: true });
      }
    });

    if (this.config.dashboard) {
      this.isNew = true;
      this.model.dashboardId = this.config.dashboard.id;
      this.model.dashboardName = this.config.dashboard.name;
    }
    else {
      this.isNew = false;
      this.isLoadingParameters = true;
      this.dashboardParamsSet = true;

      forkJoin([this.dateHierarchyLoader, this.secondHierarchyLoader, this.thirdHierarchyLoader])
        .pipe(first())
        .subscribe(() => {
          this.isLoadingParameters = false;
        });

      this.dashboardSchedulerService.getById(this.config.scheduleId).pipe(first()).subscribe(resp => {
        this.model = resp.result;
        this.scheduleReceivers = this.model.receivers.map(email =>
          <DashboardScheduleReceiver>{
            receiverEmail: email,
            recieverHasAccount: true
          });

        this.loadDashboardParameters();
      });
    }
  }

  submit() {
    this.validateAllFormFields(this.ngForm.form); // validate the form and show errors
    if (!this.ngForm.valid) {// check if form is valid 
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    if (!this.dashboardParamsSet) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please set dashboard parameters."]
      });
      return;
    }

    if (this.scheduleReceivers.length == 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["No recipients!"]
      });
      return;
    }

    let receiversWithoutAccount = this.scheduleReceivers
      .filter(contact => !contact.recieverHasAccount)
      .map(contact => contact.receiverEmail);

    if (receiversWithoutAccount.length > 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [`No account was found for '${receiversWithoutAccount.join(", ")}' in Client Portal`]
      });
      return;
    }

    if (this.model.startPeriod) {
      this.model.startPeriod = moment(this.model.startPeriod).hour(12).toDate();
    }

    if (this.model.endPeriod) {
      this.model.endPeriod = moment(this.model.endPeriod).hour(12).toDate();
    }

    if (this.model.runMode == 'Once') {
      this.model.endPeriod = null;
    }

    this.model.receivers = this.scheduleReceivers.map(obj => obj.receiverEmail);

    // dashboardParameters will be undefined if user updated schedule without updating the parameters
    if (this.dashboardParameters) {
      this.model.dashboardParam = this.dashboardParameters;
    }

    this.dashboardSchedulerService.post(this.model).pipe(first()).subscribe(resp => {
      if (resp && resp.result) {
        this.dialogRef.close(true);
      }
    });
  }

  addToContact(_receiverEmail: string, _recieverHasAccount: boolean) {
    if (this.scheduleReceivers.map(obj => obj.receiverEmail).indexOf(_receiverEmail) == -1) {
      this.scheduleReceivers.push({ receiverEmail: _receiverEmail, recieverHasAccount: _recieverHasAccount });
    }
  }

  removeContact(contact: DashboardScheduleReceiver) {
    let index = this.scheduleReceivers.indexOf(contact);
    if (index > -1) {
      this.scheduleReceivers.splice(index, 1);
    }
  }

  validateNewEmail() {
    if (this.enteredEmail.trim().length == 0 || this.ngForm.form.controls["contact"].invalid) {
      this.enteredEmail = ""
    } else {
      this.appuserService.isValidUser(this.enteredEmail).pipe(first()).subscribe(resp => {
        this.addToContact(this.enteredEmail, resp.result);
        this.enteredEmail = "";
      });
    }
  }

  getDashboardRunModePeriod(runMode: string) {
    if (runMode == 'Daily') {
      return 'day';
    }
    else if (runMode == 'Weekly') {
      return 'week';
    }
    else if (runMode == 'Monthly') {
      return 'month';
    }
    else if (runMode == 'Quarterly') {
      return '3 months';
    }
    else {
      return '';
    }
  }

  showDashboardParametersDialog() {
    let dialogConfig: MatDialogConfig<DashboardParametersDialogConfig> = {
      data: {
        dateHierarchy: this.config.dateHierarchy,
        secondHierarchy: this.config.secondHierarchy,
        thirdHierarchy: this.config.thirdHierarchy,
      },
      width: '800px',
      disableClose: true
    };

    let dialogRef = this.dialog.open(DashboardParametersDialogComponent, dialogConfig);

    dialogRef.afterClosed().pipe(first()).subscribe((_dashboardParameters: DashboardFilterationValues) => {
      if (_dashboardParameters) {
        this.dashboardParamsSet = true;
        this.dashboardParameters = JSON.stringify(_dashboardParameters);
      }
    });
  }

  private loadDashboardParameters() {
    if (this.model.lineOfBusiness == LineOfBusiness.TRIAGE) {
      this.loadTriageDashboardParameters();
    }
    else if (this.model.lineOfBusiness == LineOfBusiness.OCCUPATIONAL_HEALTH) {
      this.loadOccupationalHealthDashboardParameters();
    }
    else if (this.model.lineOfBusiness == LineOfBusiness.ADVANCED_PRACTICE) {
      this.loadAdvancedPracticeDashboardParameters();
    }
    else if (this.model.lineOfBusiness == LineOfBusiness.OSHA_REPORTABLE) {
      this.loadOshaReportableDashboardParameters();
    }
  }

  private loadTriageDashboardParameters() {
    let dashboardSchedulerParams = JSON.parse(this.model.dashboardParam) as DashboardFilterationValues;
    let triageDashboardFilterControlsConfig = new TriageDashboardFilterControlsConfig(this.afkamCubeService);

    this.config.dateHierarchy = {
      ...triageDashboardFilterControlsConfig.dateHierarchy,
      advancedDateFilterationAttributes: this.populateAdvancedDateFilterationAttributes(dashboardSchedulerParams),
    };

    this.dateHierarchyLoader.complete();

    if (sessionStorage.getItem(triageDashboardFilterControlsConfig.clientHierarchy.sessionStorageKey)) {
      let _hierarchies = JSON.parse(sessionStorage.getItem(triageDashboardFilterControlsConfig.clientHierarchy.sessionStorageKey)) as SelectionTreeViewModel[];

      let storedHierarchies = dashboardSchedulerParams.dropdownTreeFilterationValues.find(obj => obj.treeName == triageDashboardFilterControlsConfig.clientHierarchy.name);
      if (storedHierarchies) {
        let rootHierarchyNodeSelected = _hierarchies[0].children.findIndex(node => storedHierarchies.included.indexOf(node.id) > -1) > -1;

        // if one of the root nodes in hierarchy is selected, then populate it and invoke correctTreeNodes, else don't (or the result will be "All Excluded")
        if (rootHierarchyNodeSelected) {
          this.sharedFunctions.correctTreeNodes(_hierarchies[0], triageDashboardFilterControlsConfig.clientHierarchy.isLevel, storedHierarchies.included, storedHierarchies.excluded);
        }
      }

      this.config.secondHierarchy = {
        ...triageDashboardFilterControlsConfig.clientHierarchy,
        data: _hierarchies,
      };

      this.secondHierarchyLoader.complete();
    } else {
      // Fill Hierarchy dropdown tree
      this.afkamCubeService.getHierarchyLocations([]).pipe(first()).subscribe(
        returnValue => {
          if (returnValue.result) {
            let _hierarchies = returnValue.result!.selectionTrees;

            try {
              sessionStorage.setItem(triageDashboardFilterControlsConfig.clientHierarchy.sessionStorageKey, JSON.stringify(_hierarchies));
            }
            catch (e) {
              let ex = e as Error;
              if (ex.name === 'QuotaExceededError') {
                console.warn(ex.message);
              }
              else {
                throw e;
              }
            }

            let storedHierarchies = dashboardSchedulerParams.dropdownTreeFilterationValues.find(obj => obj.treeName == triageDashboardFilterControlsConfig.clientHierarchy.name);
            if (storedHierarchies) {
              let rootHierarchyNodeSelected = _hierarchies[0].children.findIndex(node => storedHierarchies.included.indexOf(node.id) > -1) > -1;

              // if one of the root nodes in hierarchy is selected, then populate it and invoke correctTreeNodes, else don't (or the result will be "All Excluded")
              if (rootHierarchyNodeSelected) {
                this.sharedFunctions.correctTreeNodes(_hierarchies[0], triageDashboardFilterControlsConfig.clientHierarchy.isLevel, storedHierarchies.included, storedHierarchies.excluded);
              }
            }

            this.config.secondHierarchy = {
              ...triageDashboardFilterControlsConfig.clientHierarchy,
              data: _hierarchies,
            };

            this.secondHierarchyLoader.complete();
          }
        }
      );
    }

    if (sessionStorage.getItem(triageDashboardFilterControlsConfig.locationHierarchy.sessionStorageKey)) {
      let _geographies = JSON.parse(sessionStorage.getItem(triageDashboardFilterControlsConfig.locationHierarchy.sessionStorageKey)) as SelectionTreeViewModel[];

      let storedGeographies = dashboardSchedulerParams.dropdownTreeFilterationValues.find(obj => obj.treeName == triageDashboardFilterControlsConfig.locationHierarchy.name);
      if (storedGeographies) {
        this.sharedFunctions.correctTreeNodes(_geographies[0], triageDashboardFilterControlsConfig.locationHierarchy.isLevel, storedGeographies.included, storedGeographies.excluded);
      }

      this.config.thirdHierarchy = {
        ...triageDashboardFilterControlsConfig.locationHierarchy,
        data: _geographies,
      };

      this.thirdHierarchyLoader.complete();
    } else {
      // Fill Geography dropdown tree
      this.afkamCubeService.getHierarchyLevels(
        {
          hierarchyName: '[Dim Location].[Location Geo Hierarchy]',
          isHierarchyLevels: true,
          levelsOrColumns: 1,
          stopOnCompany: false
        }
      ).pipe(first()).subscribe(
        returnValue => {
          if (returnValue.result) {
            let _geographies = returnValue.result!.selectionTrees;

            try {
              sessionStorage.setItem(triageDashboardFilterControlsConfig.locationHierarchy.sessionStorageKey, JSON.stringify(_geographies));
            }
            catch (e) {
              let ex = e as Error;
              if (ex.name === 'QuotaExceededError') {
                console.warn(ex.message);
              }
              else {
                throw e;
              }
            }

            let storedGeographies = dashboardSchedulerParams.dropdownTreeFilterationValues.find(obj => obj.treeName == triageDashboardFilterControlsConfig.locationHierarchy.name);
            if (storedGeographies) {
              this.sharedFunctions.correctTreeNodes(_geographies[0], triageDashboardFilterControlsConfig.locationHierarchy.isLevel, storedGeographies.included, storedGeographies.excluded);
            }

            this.config.thirdHierarchy = {
              ...triageDashboardFilterControlsConfig.locationHierarchy,
              data: _geographies,
            };

            this.thirdHierarchyLoader.complete();
          }
        }
      );
    }
  }

  private loadOccupationalHealthDashboardParameters() {

    let dashboardSchedulerParams = JSON.parse(this.model.dashboardParam) as DashboardFilterationValues;
    let occupationalHealthDashboardFilterControlsConfig = new OccupationalHealthDashboardFilterControlsConfig();

    this.config.dateHierarchy = {
      ...occupationalHealthDashboardFilterControlsConfig.dateHierarchy,
      advancedDateFilterationAttributes: this.populateAdvancedDateFilterationAttributes(dashboardSchedulerParams),
    };

    this.dateHierarchyLoader.complete();

    if (sessionStorage.getItem(occupationalHealthDashboardFilterControlsConfig.clinicHierarchy.sessionStorageKey)) {
      let _companies = JSON.parse(sessionStorage.getItem(occupationalHealthDashboardFilterControlsConfig.clinicHierarchy.sessionStorageKey)) as SelectionTreeViewModel[];

      let storedHierarchies = dashboardSchedulerParams.dropdownTreeFilterationValues.find(obj => obj.treeName == occupationalHealthDashboardFilterControlsConfig.clinicHierarchy.name);
      if (storedHierarchies) {
        this.sharedFunctions.correctTreeNodes(_companies[0], occupationalHealthDashboardFilterControlsConfig.clinicHierarchy.isLevel, storedHierarchies.included, storedHierarchies.excluded);
      }

      this.config.secondHierarchy = {
        ...occupationalHealthDashboardFilterControlsConfig.clinicHierarchy,
        data: _companies,
      };

      this.secondHierarchyLoader.complete();
    } else {
      this.medfilesService.getCompanySitesAsTree().pipe(first()).subscribe(result => {
        if (result.result) {
          let _companies = result.result!;

          try {
            sessionStorage.setItem(occupationalHealthDashboardFilterControlsConfig.clinicHierarchy.sessionStorageKey, JSON.stringify(_companies));
          }
          catch (e) {
            let ex = e as Error;
            if (ex.name === 'QuotaExceededError') {
              console.warn(ex.message);
            }
            else {
              throw e;
            }
          }

          let storedCompanies = dashboardSchedulerParams.dropdownTreeFilterationValues.find(obj => obj.treeName == occupationalHealthDashboardFilterControlsConfig.clinicHierarchy.name);
          if (storedCompanies) {
            this.sharedFunctions.correctTreeNodes(_companies[0], occupationalHealthDashboardFilterControlsConfig.clinicHierarchy.isLevel, storedCompanies.included, storedCompanies.excluded);
          }

          this.config.secondHierarchy = {
            ...occupationalHealthDashboardFilterControlsConfig.clinicHierarchy,
            data: _companies,
          };

          this.secondHierarchyLoader.complete();
        }
      });
    }

    // no third hierarchy in this line of business, so just complete thirdHierarchyLoader
    this.thirdHierarchyLoader.complete();
  }

  private loadAdvancedPracticeDashboardParameters() {

    let dashboardSchedulerParams = JSON.parse(this.model.dashboardParam) as DashboardFilterationValues;
    let advancedPracticeDashboardFilterControlsConfig = new AdvancedPracticeDashboardFilterControlsConfig();

    this.config.dateHierarchy = {
      ...advancedPracticeDashboardFilterControlsConfig.dateHierarchy,
      advancedDateFilterationAttributes: this.populateAdvancedDateFilterationAttributes(dashboardSchedulerParams),
    };

    this.dateHierarchyLoader.complete();

    if (sessionStorage.getItem(advancedPracticeDashboardFilterControlsConfig.practiceHierarchy.sessionStorageKey)) {
      let _advPractices = JSON.parse(sessionStorage.getItem(advancedPracticeDashboardFilterControlsConfig.practiceHierarchy.sessionStorageKey)) as SelectionTreeViewModel[];

      let storedAdvPractices = dashboardSchedulerParams.dropdownTreeFilterationValues.find(obj => obj.treeName == advancedPracticeDashboardFilterControlsConfig.practiceHierarchy.name);
      if (storedAdvPractices) {
        this.sharedFunctions.correctTreeNodes(_advPractices[0], advancedPracticeDashboardFilterControlsConfig.practiceHierarchy.isLevel, storedAdvPractices.included, storedAdvPractices.excluded);
      }

      this.config.secondHierarchy = {
        ...advancedPracticeDashboardFilterControlsConfig.practiceHierarchy,
        data: _advPractices,
      };

      this.secondHierarchyLoader.complete();
    } else {
      this.advPracticeService.getAdvancedPracticeTree().pipe(first()).subscribe(result => {
        if (result.result) {
          let _advPractices = result.result;

          try {
            sessionStorage.setItem(advancedPracticeDashboardFilterControlsConfig.practiceHierarchy.sessionStorageKey, JSON.stringify(_advPractices));
          }
          catch (e) {
            let ex = e as Error;
            if (ex.name === 'QuotaExceededError') {
              console.warn(ex.message);
            }
            else {
              throw e;
            }
          }

          let storedAdvPractices = dashboardSchedulerParams.dropdownTreeFilterationValues.find(obj => obj.treeName == advancedPracticeDashboardFilterControlsConfig.practiceHierarchy.name);
          if (storedAdvPractices) {
            this.sharedFunctions.correctTreeNodes(_advPractices[0], advancedPracticeDashboardFilterControlsConfig.practiceHierarchy.isLevel, storedAdvPractices.included, storedAdvPractices.excluded);
          }

          this.config.secondHierarchy = {
            ...advancedPracticeDashboardFilterControlsConfig.practiceHierarchy,
            data: _advPractices,
          };

          this.secondHierarchyLoader.complete();
        }
      });
    }

    // no third hierarchy in this line of business, so just complete thirdHierarchyLoader
    this.thirdHierarchyLoader.complete();
  }

  private loadOshaReportableDashboardParameters() {

    let dashboardSchedulerParams = JSON.parse(this.model.dashboardParam) as DashboardFilterationValues;
    let oshaReportableDashboardFilterControlsConfig = new OshaReportableDashboardFilterControlsConfig();

    this.config.dateHierarchy = {
      ...oshaReportableDashboardFilterControlsConfig.dateHierarchy,
      advancedDateFilterationAttributes: this.populateAdvancedDateFilterationAttributes(dashboardSchedulerParams),
    };

    this.dateHierarchyLoader.complete();

    if (sessionStorage.getItem(oshaReportableDashboardFilterControlsConfig.clientHierarchy.sessionStorageKey)) {
      let _oshaHierarchy = JSON.parse(sessionStorage.getItem(oshaReportableDashboardFilterControlsConfig.clientHierarchy.sessionStorageKey)) as SelectionTreeViewModel[];

      let storedOshaHierarchy = dashboardSchedulerParams.dropdownTreeFilterationValues.find(obj => obj.treeName == oshaReportableDashboardFilterControlsConfig.clientHierarchy.name);
      if (storedOshaHierarchy) {
        this.sharedFunctions.correctTreeNodes(_oshaHierarchy[0], oshaReportableDashboardFilterControlsConfig.clientHierarchy.isLevel, storedOshaHierarchy.included, storedOshaHierarchy.excluded);
      }

      this.config.secondHierarchy = {
        ...oshaReportableDashboardFilterControlsConfig.clientHierarchy,
        data: _oshaHierarchy,
      };

      this.secondHierarchyLoader.complete();
    } else {
      this.oshaReportableService.getCompanyLocationTree().pipe(first()).subscribe(resp => {
        if (resp.result) {
          let _oshaHierarchy = resp.result;

          try {
            sessionStorage.setItem(oshaReportableDashboardFilterControlsConfig.clientHierarchy.sessionStorageKey, JSON.stringify(_oshaHierarchy));
          }
          catch (e) {
            let ex = e as Error;
            if (ex.name === 'QuotaExceededError') {
              console.warn(ex.message);
            }
            else {
              throw e;
            }
          }

          let storedOshaHierarchy = dashboardSchedulerParams.dropdownTreeFilterationValues.find(obj => obj.treeName == oshaReportableDashboardFilterControlsConfig.clientHierarchy.name);
          if (storedOshaHierarchy) {
            this.sharedFunctions.correctTreeNodes(_oshaHierarchy[0], oshaReportableDashboardFilterControlsConfig.clientHierarchy.isLevel, storedOshaHierarchy.included, storedOshaHierarchy.excluded);
          }

          this.config.secondHierarchy = {
            ...oshaReportableDashboardFilterControlsConfig.clientHierarchy,
            data: _oshaHierarchy,
          };

          this.secondHierarchyLoader.complete();
        }
      });
    }

    if (sessionStorage.getItem(oshaReportableDashboardFilterControlsConfig.stateHierarchy.sessionStorageKey)) {
      let _oshaStates = JSON.parse(sessionStorage.getItem(oshaReportableDashboardFilterControlsConfig.stateHierarchy.sessionStorageKey)) as SelectionTreeViewModel[];

      let storedOshaStates = dashboardSchedulerParams.dropdownTreeFilterationValues.find(obj => obj.treeName == oshaReportableDashboardFilterControlsConfig.stateHierarchy.name);
      if (storedOshaStates) {
        this.sharedFunctions.correctTreeNodes(_oshaStates[0], oshaReportableDashboardFilterControlsConfig.stateHierarchy.isLevel, storedOshaStates.included, storedOshaStates.excluded);
      }

      this.config.thirdHierarchy = {
        ...oshaReportableDashboardFilterControlsConfig.stateHierarchy,
        data: _oshaStates,
      };

      this.thirdHierarchyLoader.complete();

    } else {
      this.oshaReportableService.getStatesTree().pipe(first()).subscribe(resp => {
        if (resp.result) {
          let _oshaStates = resp.result;

          try {
            sessionStorage.setItem(oshaReportableDashboardFilterControlsConfig.stateHierarchy.sessionStorageKey, JSON.stringify(_oshaStates));
          }
          catch (e) {
            let ex = e as Error;
            if (ex.name === 'QuotaExceededError') {
              console.warn(ex.message);
            }
            else {
              throw e;
            }
          }

          let storedOshaStates = dashboardSchedulerParams.dropdownTreeFilterationValues.find(obj => obj.treeName == oshaReportableDashboardFilterControlsConfig.stateHierarchy.name);
          if (storedOshaStates) {
            this.sharedFunctions.correctTreeNodes(_oshaStates[0], oshaReportableDashboardFilterControlsConfig.stateHierarchy.isLevel, storedOshaStates.included, storedOshaStates.excluded);
          }

          this.config.thirdHierarchy = {
            ...oshaReportableDashboardFilterControlsConfig.stateHierarchy,
            data: _oshaStates,
          };

          this.thirdHierarchyLoader.complete();
        }
      });
    }
  }

  private populateAdvancedDateFilterationAttributes(dashboardSchedulerParams: DashboardFilterationValues) {
    if (dashboardSchedulerParams.dateRange) {
      var dates = this.sharedFilterHelper.populateDateFilters(dashboardSchedulerParams.dateRange);
    }
    else {
      dates.startDate = dashboardSchedulerParams.startDate;
      dates.endDate = dashboardSchedulerParams.endDate;
    }

    let _advancedDateFilterationAttributes: AdvancedDateFilterationAttributes = {
      dateRange: dashboardSchedulerParams.dateRange,
      startDate: new Date(dates.startDate),
      endDate: new Date(dates.endDate),
    };

    return _advancedDateFilterationAttributes;
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}

export class DashboardSchedulerConfig {
  dashboard?: DashboardItemViewModel;
  scheduleId?: number;
  dateHierarchy?: DashboardHierarchyConfig;
  secondHierarchy?: DashboardHierarchyConfig;
  thirdHierarchy?: DashboardHierarchyConfig;
}
