import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { LineOfBusiness, ReportingSettings } from '../../app.general.constants';
import { ReportingService, ReportingParametersViewModel, ReportingRenderViewModel, ReportItemService, ReportParameterValueViewModel, SelectionListService, SelectionItemViewModel, AfkamCubeService, SelectionTreeViewModel, LoggedUserViewModel, MedcorResponse, HierarchyTreeValues, ParameterTypeEnum, OshaReportableService, CubeHierarchyParameterViewModel, ReportItemViewModel } from '../../rest/index';
import { IchsTextBox, IchsControl, InputDialogConfig, IchsInputDialogComponent } from '../../controls/ichs-dialog/ichs-input-dialog/ichs-input-dialog.component';
import { first } from 'rxjs/operators';
import { MedcorUserInfoService } from '../../services/medcor-user-info.service';
import { Observable } from 'rxjs';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from 'src/app/services/medcor-alert.service';
import { ReportParametersDialogConfig, ReportParametersDialogComponent, ReportParametersDialogResult } from '../report-parameters-dialog/report-parameters-dialog.component';
import { HttpErrorResponse, HttpResponse, HttpEventType } from '@angular/common/http';
import { GeneralVariablesService } from '../../services/general-variables.service';
import * as findAndReplaceDOMText from 'findandreplacedomtext';
import * as RegexEscape from 'regex-escape';
import * as moment from 'moment';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { ReportingSchedulerComponent, ReportSchedulerConfig } from '../reporting-scheduler/reporting-scheduler.component';

@Component({
  selector: 'app-reporting-viewer',
  templateUrl: './reporting-viewer.component.html',
  styleUrls: ['./reporting-viewer.component.css'],
  host: { class: "fill-width fill-parent" }
})
export class ReportingViewerComponent implements OnInit {

  xmlSerializer: XMLSerializer;

  firstPageDisabled: boolean = true;
  previousPageDisabled: boolean = true;
  nextPageDisabled: boolean = true;
  lastPageDisabled: boolean = true;
  safeHtml: any = "";
  parsedHTML: Document;
  public reportingParametersViewModel: ReportingParametersViewModel = <ReportingParametersViewModel>{};
  public reportingRenderViewModel: ReportingRenderViewModel = <ReportingRenderViewModel>{};
  saveToSecuredFilesDialogConfig: InputDialogConfig;
  reportToolBarDisabled: boolean = true;
  hideProgressSpinner: boolean = false;
  reportItemId: number;
  lineOfBusiness: string;
  exportOptions: SelectionItemViewModel[] = [];
  selectedExportOptions: string[] = [];
  isGeneratingReport: boolean = false;
  reportLoaded: boolean = false;
  usedReportParameters: ReportParameterValueViewModel[] = [];
  previuosPageNumber: number = 1;
  reportFileDownloaded: boolean = false;

  htmlReportPages: Element[] = [];

  pagesMatchesCounters: number[] = [];
  firstMatchFound: boolean = false;
  firstMatchPageNumber: number = -1;
  currentMatchPageNumber: number = -1;
  searchTerm: string;
  currentSearchTerm: string;
  searchMatchesCount: number = 0;
  currentMatchIdx: number = -1;
  hideSearchButton: boolean = false;
  finders: any[] = [];

  dateRange: string;
  workingDate: Date;

  downloadReportFile: boolean;
  viewReportAsPdf: boolean;
  pdfReportUrl: string;

  reportViewModel: ReportItemViewModel;

  get loggedInUserObs(): Observable<LoggedUserViewModel> { return this.medcorUserInfoService.loggedInUserObs }

  constructor(
    private _route: ActivatedRoute,
    private _reportingService: ReportingService,
    private _sanitizer: DomSanitizer,
    private _dialog: MatDialog,
    private _ngZone: NgZone,
    private _reportItemService: ReportItemService,
    private _selectionService: SelectionListService,
    private _afkamCubeService: AfkamCubeService,
    private medcorUserInfoService: MedcorUserInfoService,
    private notificationService: MedcorAlertService,
    private alertService: MedcorAlertService,
    public generalVariables: GeneralVariablesService,
    private sharedFunctions: SharedFunctionsService,
    private oshaReportableService: OshaReportableService,
  ) {
    (window as any).ReportingViewerComponent = {
      zone: _ngZone,
      getEmbeddedFile: (refNumber: string, payorId: string) => this.getEmbeddedFile(refNumber, payorId),
      component: this
    };
  }

  ngOnInit() {

    this.xmlSerializer = new XMLSerializer();

    const param = this._route.snapshot.paramMap == null ? -1 : this._route.snapshot.paramMap.get('id');
    if (!param) {
      return;
    }

    this.reportItemId = +param;

    if (this.reportItemId == -1) {
      return;
    }

    this._selectionService.getSelectionItems(108000000000004).pipe(first()).subscribe(lst => {
      this.exportOptions = lst.result![0].items;
    });

    this._reportItemService.getReportItemDetails(this.reportItemId).pipe(first()).subscribe(resp => {
      if (!resp || !resp.result) {
        return;
      }

      this.reportViewModel = resp.result;
      this.selectedExportOptions = this.reportViewModel.exportOptionsList;
      this.lineOfBusiness = this.reportViewModel.lineOfBusiness;

      if (this.reportViewModel.downloadOnViewer) {
        if (this.reportViewModel.downloadFormat.toUpperCase() == "PDF") {
          this.viewReportAsPdf = true;
        }
        else {
          this.downloadReportFile = true;
        }
      }

      this.reportingParametersViewModel = {
        reportItemId: this.reportItemId,
        path: this.reportViewModel.reportPath,
        downloadOnViewer: this.reportViewModel.downloadOnViewer,
      };

      this.reportingRenderViewModel = { pageNumber: 1 };
      this.loadReportParameters();
    });

    this.saveToSecuredFilesDialogConfig = new InputDialogConfig({
      hasTitle: false,
      message: "Report Name",
      controls: [
        new IchsTextBox({
          placeholder: "Enter Report Name",
          required: true,
        })
      ]
    });
  }

  gotoFirstPage() {
    this.previuosPageNumber = this.reportingRenderViewModel.pageNumber;
    this.reportingRenderViewModel.pageNumber = 1;
    this.gotoPage();
  }

  gotoPreviousPage() {
    this.previuosPageNumber = this.reportingRenderViewModel.pageNumber;
    this.reportingRenderViewModel.pageNumber = this.reportingRenderViewModel.pageNumber! - 1;
    this.gotoPage();
  }

  gotoNextPage() {
    this.previuosPageNumber = this.reportingRenderViewModel.pageNumber;
    this.reportingRenderViewModel.pageNumber = this.reportingRenderViewModel.pageNumber! + 1;
    this.gotoPage();
  }

  gotoLastPage() {
    this.previuosPageNumber = this.reportingRenderViewModel.pageNumber;
    this.reportingRenderViewModel.pageNumber = this.reportingRenderViewModel.pageCount;
    this.gotoPage();
  }

  gotoPage() {
    if (this.reportingRenderViewModel.pageNumber > this.reportingRenderViewModel.pageCount || this.reportingRenderViewModel.pageNumber < 1) {
      this.notificationService.addAlert({
        messages: ["Invalid page number!"],
        title: "Validation Error",
        type: AlertMessageType.warning,
        dismiss: AlertDismissType.auto,
      });
      this.reportingRenderViewModel.pageNumber = this.previuosPageNumber;
      return;
    }

    this.disableToolbar();

    this.parsedHTML.body.removeChild(this.parsedHTML.body.children.item(0));
    this.parsedHTML.body.appendChild(this.htmlReportPages[this.reportingRenderViewModel.pageNumber - 1]);
    this.safeHtml = this._sanitizer.bypassSecurityTrustHtml(this.xmlSerializer.serializeToString(this.parsedHTML));
    this.refreshToolbar();

    // save previous page number
    this.previuosPageNumber = this.reportingRenderViewModel.pageNumber;
  }

  export(exportFormat: string) {
    if (exportFormat == "securedInbox") {
      this.saveToSecuredInbox();
    } else {
      let sub = this._reportingService
        .exportReport(this.reportingRenderViewModel.executionId, this.reportingParametersViewModel.reportItemName + "." + exportFormat, 'response', true)
        .subscribe(event => {
          if (event.type == HttpEventType.Response) {
            this.sharedFunctions.saveToFileSystem(event);
          }
        }, () => { sub.unsubscribe() }, () => { sub.unsubscribe() });
    }
  }

  refresh() {
    this.loadReportParameters();
  }

  printAll() {
    this.disableToolbar();
    var printContents = "";

    let _reportingRenderViewModel: ReportingRenderViewModel = <ReportingRenderViewModel>{
      pageNumber: 0,
      executionId: this.reportingParametersViewModel.executionId,
      reportItemId: this.reportingParametersViewModel.reportItemId,
      reportParameters: this.usedReportParameters,
      detailedReportParameters: [],
    };

    this.reportingParametersViewModel.reportParameters.forEach(param => {
      param.validValues = [];
      _reportingRenderViewModel.detailedReportParameters.push(param);
    });

    this._reportingService.loadReport(_reportingRenderViewModel).pipe(first()).subscribe(
      returnValue => {
        if (!returnValue.result) {
          return;
        }

        printContents = returnValue.result.htmlContent!;

        if (window) {
          if (navigator.userAgent.toLowerCase().indexOf('chrome') > -1) {
            var popup = window.open('', '_blank',
              'width=600,height=600,scrollbars=no,menubar=no,toolbar=no,'
              + 'location=no,status=no,titlebar=no');
            popup!.window.focus();
            popup!.document.write('<html><head>  '
              + '</head><body onload="window.print()">'
              + printContents! + '</html>');
            popup!.onbeforeunload = function (event) {
              popup!.close();
              return '.\n';
            };
            popup!.onabort = function (event) {
              popup!.document.close();
              popup!.close();
            }
            popup!.onafterprint = function (event) {
              popup!.document.close();
              popup!.close();
            }
          } else {
            var popup = window.open('', '_blank', 'width=800,height=600');
            popup!.document.open();
            popup!.document.write('<html><head>'
              + '</head><body onload="window.print()">' + printContents + '</html>');
            popup!.document.close();
            popup!.onafterprint = function (event) {
              popup!.document.close();
              popup!.close();
            }
          }
          popup!.document.close();
        }
        return true;
      }, (err: HttpErrorResponse) => {
        this.hideProgressSpinner = true;

        let error = err.error as MedcorResponse<null>;
        if (error.statusCode == 400 && !error.notify) {
          this.alertService.addAlert({
            type: AlertMessageType.warning,
            title: 'Warning!',
            dismiss: AlertDismissType.controlled,
            messages: error.messages,
          });
        }
      });

    this.refreshToolbar();
  }

  getEmbeddedFile(refNumber: string, payorId: string) {
    if (refNumber && payorId) {
      let sub = this._reportingService.getEmbeddedTriageSecuredFile(refNumber, payorId, 'events', true)
        .subscribe(event => {
          if (event.type == HttpEventType.Response) {
            var fileURL = URL.createObjectURL(event.body);
            window.open(fileURL, '_blank');
          }
        },
          () => { sub.unsubscribe() },
          () => { sub.unsubscribe() }
        );
    }
  }

  saveToSecuredInbox() {
    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this._dialog.open(IchsInputDialogComponent, {
      data: this.saveToSecuredFilesDialogConfig,
      width: "400px",
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result && result.length > 0) {
        this._reportingService.saveToSecuredInbox(this.reportingParametersViewModel.executionId!, this.reportingParametersViewModel.reportItemName, result[0].value!)
          .pipe(first())
          .subscribe(null,
            (err: HttpErrorResponse) => {
              let error = err.error as MedcorResponse<null>;
              if (error.statusCode == 400 && !error.notify) {
                this.alertService.addAlert({
                  type: AlertMessageType.warning,
                  title: 'Warning!',
                  dismiss: AlertDismissType.controlled,
                  messages: error.messages,
                });
              }
            });
      }
    });
  }

  searchEvent(event: KeyboardEvent) {
    if (event.key == "Enter") {
      this.search(!event.shiftKey); // enter: search forward, shift + enter: search backward
    }
  }

  search(forwardSearchDirection: boolean) {
    if (!this.searchTerm || this.searchTerm.trim().length == 0) {
      this.clearAllSearchResults();
    }
    else if (this.searchTerm != this.currentSearchTerm) {
      let _searchTerm = this.searchTerm;
      this.clearAllSearchResults();
      this.searchTerm = _searchTerm;
      this.currentSearchTerm = this.searchTerm;
      this.searchAndNavigateToFirstMatch();
    }
    else {
      if (forwardSearchDirection) {
        this.gotoNextSearchMatch();
      }
      else {
        this.gotoPreviousSearchMatch();
      }
    }
  }

  clearAllSearchResults() {
    this.searchTerm = null;
    this.currentSearchTerm = null;

    this.pagesMatchesCounters = [];
    this.firstMatchFound = false;
    this.firstMatchPageNumber = -1;

    this.currentMatchIdx = -1;
    this.searchMatchesCount = 0;

    this.hideSearchButton = false;

    this.finders.forEach(finder => finder.revert());
    this.finders = [];

    this.safeHtml = this._sanitizer.bypassSecurityTrustHtml(this.xmlSerializer.serializeToString(this.parsedHTML));
  }

  scheduleReport() {
    this._reportingService.reportingStatus(this.reportViewModel.lineOfBusiness).pipe(first()).subscribe(() => {

      let dialogConfig: MatDialogConfig<ReportSchedulerConfig> = {
        data: {
          report: this.reportViewModel
        },
        width: '800px',
        disableClose: true
      };
      this._dialog.open(ReportingSchedulerComponent, dialogConfig);
    },
      (err: HttpErrorResponse) => {
        this.sharedFunctions.showReportStatusDialog(err);
      });
  }

  private storeReportParams() {
    try {
      // local storage is disabled in case of impersonation
      if (this.generalVariables.userImpersonationInfo.isImpersonatedInd) {
        return;
      }

      if (this.reportingParametersViewModel.reportParameters) {
        var storedReportParams = localStorage.getItem('reportParams');
        var reportValues: ReportParameterValueViewModel[] = [];   // new report values
        var storedReportValues: ReportParameterValueViewModel[] = [];   // stored report values 

        let hiddenParamsNames = this.reportingParametersViewModel.reportParameters
          .filter(param => param.type == ParameterTypeEnum.Hidden)
          .map(param => param.name);

        if (this.reportingParametersViewModel.reportParametersValues) {
          reportValues = this.reportingParametersViewModel.reportParametersValues;

          // don't cache values of hidden parameters (they are filled from reporting server side)
          reportValues = reportValues.filter(param => hiddenParamsNames.indexOf(param.name) == -1);

          // don't cache 'Client Report Title' parameter
          reportValues = reportValues.filter(param => param.name != ReportingSettings.CLIENT_REPORT_TITLE_PARAM_NAME);
        }

        if (storedReportParams) {
          storedReportValues = JSON.parse(storedReportParams);
        }

        // find objects stored but not in the new values, and also,
        // not hidden in the current report (e.g. don't keep endDate if it is hidden in curent report)
        var filteredStoredValues = storedReportValues
          .filter(sObj => reportValues.findIndex(obj => obj.name == sObj.name) == -1 && hiddenParamsNames.indexOf(sObj.name) == -1);

        reportValues.push(...filteredStoredValues);

        localStorage.setItem('reportParams', JSON.stringify(reportValues));
      }

      if (this.dateRange) {
        localStorage.setItem("reportDateRangeParam", this.dateRange);
      }
      else {
        localStorage.removeItem("reportDateRangeParam");
      }

      // store working date only if it is different from today
      if (this.workingDate && moment(this.workingDate).format('YYYY-MM-DD') != moment(new Date).format('YYYY-MM-DD')) {
        localStorage.setItem("reportWorkingDate", this.workingDate.toISOString());
      }
      else {
        localStorage.removeItem("reportWorkingDate");
      }

      if (this.reportingParametersViewModel.triageReportHierarchyDisplayTreeValues) {
        localStorage.setItem("triageReportHierarchyDisplayParam", JSON.stringify(this.reportingParametersViewModel.triageReportHierarchyDisplayTreeValues));
      }

      if (this.reportingParametersViewModel.oshaHierarchyTreeValues) {
        localStorage.setItem("oshaHierarchyTreeValues", JSON.stringify(this.reportingParametersViewModel.oshaHierarchyTreeValues));
      }
    }
    catch (e) {
      let ex = e as Error;
      if (ex.name === 'QuotaExceededError') {
        console.warn(ex.message);
      }
      else {
        throw e;
      }
    }
  }

  private populateStoredReportParameters() {
    // local storage is disabled in case of impersonation
    if (this.generalVariables.userImpersonationInfo.isImpersonatedInd) {
      return;
    }

    var storedReportParams = localStorage.getItem('reportParams');
    if (storedReportParams) {
      this.reportingParametersViewModel.reportParametersValues = JSON.parse(storedReportParams) as ReportParameterValueViewModel[];
    }

    let storedDateRange = localStorage.getItem("reportDateRangeParam");
    if (storedDateRange) {
      this.dateRange = storedDateRange;
    }

    let storedWorkingDate = localStorage.getItem("reportWorkingDate");
    if (storedWorkingDate) {
      this.workingDate = new Date(storedWorkingDate);
    }

    let storedTriageReportHierarchyDisplayParam = localStorage.getItem("triageReportHierarchyDisplayParam");
    if (storedTriageReportHierarchyDisplayParam) {
      this.reportingParametersViewModel.triageReportHierarchyDisplayTreeValues = JSON.parse(storedTriageReportHierarchyDisplayParam) as HierarchyTreeValues;
    }

    let oshaHierarchyTreeValues = localStorage.getItem("oshaHierarchyTreeValues");
    if (oshaHierarchyTreeValues) {
      this.reportingParametersViewModel.oshaHierarchyTreeValues = JSON.parse(oshaHierarchyTreeValues) as HierarchyTreeValues;
    }
  }

  /**
   Load the report parameters
   */
  private loadReportParameters() {
    this.isGeneratingReport = false;
    this.disableToolbar();
    this.populateStoredReportParameters();
    this._reportingService.loadReportParameters(this.reportingParametersViewModel).pipe(first()).subscribe(
      returnValue => {
        if (returnValue.result) {
          this.reportingParametersViewModel = returnValue.result;
          this.fillParameters();
        }
      },
      (err: HttpErrorResponse) => {
        this.refreshToolbar();

        let error = err.error as MedcorResponse<null>;
        if (error.statusCode == 400 && !error.notify) {
          this.alertService.addAlert({
            type: AlertMessageType.warning,
            title: 'Warning!',
            dismiss: AlertDismissType.controlled,
            messages: error.messages,
          });
        }
      });
  }

  /**
   Load the report content from the API
   */
  private loadReport() {
    this.safeHtml = "";
    this.reportingRenderViewModel.executionId = this.reportingParametersViewModel.executionId;
    this.reportingRenderViewModel.reportItemId = this.reportItemId;
    this.reportingRenderViewModel.reportParameters = this.usedReportParameters;
    this.reportingRenderViewModel.detailedReportParameters = [];
    this.reportingParametersViewModel.reportParameters.forEach(param => {
      param.validValues = [];
      this.reportingRenderViewModel.detailedReportParameters.push(param);
    });

    if (this.reportingParametersViewModel.downloadOnViewer) {
      let sub = this._reportingService
        .loadReportAsDownloadableFile(this.reportingRenderViewModel, 'response', true)
        .subscribe(
          event => {
            if (event.type == HttpEventType.Response) {
              if (this.viewReportAsPdf) {
                this.pdfReportUrl = URL.createObjectURL(event.body);
              }
              else {
                this.sharedFunctions.saveToFileSystem(event);
                this.reportFileDownloaded = true;
              }

              this.reportToolBarDisabled = false;
              this.hideProgressSpinner = true;
            }
          },
          (err: HttpErrorResponse) => {
            this.hideProgressSpinner = true;

            let error = err.error as MedcorResponse<null>;
            if (error.statusCode == 400 && !error.notify) {
              this.alertService.addAlert({
                type: AlertMessageType.warning,
                title: 'Warning!',
                dismiss: AlertDismissType.controlled,
                messages: error.messages,
              });
            }

            sub.unsubscribe();
          },
          () => sub.unsubscribe(),
        );
    }
    else {
      this._reportingService.loadReport(this.reportingRenderViewModel).pipe(first()).subscribe(
        returnValue => {
          if (returnValue.result) {
            this.reportingRenderViewModel = returnValue.result;
            this.safeHtml = this.getInnerHTMLValue();
            this.refreshToolbar();
          }
        }, (err: HttpErrorResponse) => {
          this.hideProgressSpinner = true;

          let error = err.error as MedcorResponse<null>;
          if (error.statusCode == 400 && !error.notify) {
            this.alertService.addAlert({
              type: AlertMessageType.warning,
              title: 'Warning!',
              dismiss: AlertDismissType.controlled,
              messages: error.messages,
            });
          }
        });
    }
  }

  /**
   Get parameters values and load report.
   */
  private fillParameters() {
    this.hideProgressSpinner = true;

    if (this.lineOfBusiness == LineOfBusiness.TRIAGE) {
      this._afkamCubeService.getHierarchyLocations([]).pipe(first()).subscribe(
        returnValue => {
          if (returnValue.result) {
            this.showReportParametersDialog(returnValue.result.selectionTrees)
          }
        }
      );
    }
    else if (this.lineOfBusiness == LineOfBusiness.OSHA_REPORTABLE) {
      this.oshaReportableService.getCompanyLocationTree().pipe(first()).subscribe(resp => {
        if (resp.result) {
          this.showReportParametersDialog(null, resp.result)
        }
      });
    }
    else {
      this.showReportParametersDialog();
    }
  }

  private showReportParametersDialog(triageHierarchies: SelectionTreeViewModel[] = null, oshaHierarchies: SelectionTreeViewModel[] = null) {
    let dialogConfig: MatDialogConfig<ReportParametersDialogConfig> = {
      data: {
        title: "Report Parameters",
        message: "Please fill the following Parameters:",
        dateRange: this.dateRange,
        workingDate: this.workingDate,
        lineOfBusiness: this.lineOfBusiness,
        reportingParametersViewModel: this.reportingParametersViewModel,
        hierarchies: triageHierarchies,
        oshaHierarchies: oshaHierarchies,
        okText: "Run Report",
      },
      width: '600px',
      disableClose: true,
      panelClass: 'report-dialog'
    };

    let dialogRef: MatDialogRef<ReportParametersDialogComponent, ReportParametersDialogResult> = this._dialog.open(ReportParametersDialogComponent, dialogConfig);
    dialogRef.afterClosed().pipe(first()).subscribe(result => { // when closed 
      if (result) {
        if (this.reportLoaded && !this.reportingParametersViewModel.downloadOnViewer) {
          this.clearAllSearchResults();
        }
      }
      else {
        if (this.reportLoaded) {
          this.refreshToolbar();
        } else {
          window.close();
        }
        return;
      }

      this.reportLoaded = true;
      this.isGeneratingReport = true;
      this.hideProgressSpinner = false;
      this.dateRange = result.dateRange;
      this.workingDate = result.workingDate;
      this.reportingParametersViewModel = result.reportingParametersViewModel;
      this.usedReportParameters = result.usedParams;
      this.reportingRenderViewModel = { pageNumber: 1 }; // reset model
      this.storeReportParams();
      this.loadReport();
    });
  }

  /**
   Mark the HTML as safe so Angular loads it as HTML not text
   */
  private getInnerHTMLValue(): any {
    if (!this.reportingRenderViewModel.htmlContent) {
      return;
    }

    var html = this.reportingRenderViewModel.htmlContent;
    this.parsedHTML = (new DOMParser()).parseFromString(html, "text/html");

    // set 'oReportDiv' class instead of 'oReportDiv' id for the first page
    this.parsedHTML.body.children.item(0).removeAttribute("id");
    this.parsedHTML.body.children.item(0).className = "oReportDiv";

    let reversedReportPages: Element[] = [];

    // remove page breaks
    for (let i = this.parsedHTML.body.children.length - 1; i > 0; i--) {
      let element = this.parsedHTML.body.children.item(i);

      // if element doesn't has 'page-break-after' style, then it is a page (not hr or page break)
      if (!(element.getAttribute("style") && element.getAttribute("style").indexOf("page-break-after") > -1)) {
        element.className = "oReportDiv"; // set class to oReportDiv (page in middle)
        reversedReportPages.push(element);
      }

      this.parsedHTML.body.removeChild(element);  // delete it from page
    }

    // push first page
    reversedReportPages.push(this.parsedHTML.body.children.item(0));

    // reverse pages to make them in asc order
    this.htmlReportPages = reversedReportPages.reverse();

    // get page count
    this.reportingRenderViewModel.pageCount = this.htmlReportPages.length;

    let safeHTMLStr: any = this._sanitizer.bypassSecurityTrustHtml(this.xmlSerializer.serializeToString(this.parsedHTML));
    return safeHTMLStr;
  }

  /**
   Toolbar functions
   */
  private disableToolbar() {
    this.reportToolBarDisabled = true;
    this.hideProgressSpinner = false;
    this.firstPageDisabled = true;
    this.previousPageDisabled = true;
    this.nextPageDisabled = true;
    this.lastPageDisabled = true;

    if (this.downloadReportFile) {
      this.reportFileDownloaded = false;
    }
  }

  private refreshToolbar() {
    this.firstPageDisabled = false;
    this.previousPageDisabled = false;
    this.nextPageDisabled = false;
    this.lastPageDisabled = false;

    if (!this.reportingParametersViewModel.downloadOnViewer) {
      if (this.reportingRenderViewModel.pageCount == 1 || this.reportingRenderViewModel.pageNumber == 1) {
        this.firstPageDisabled = true;
        this.previousPageDisabled = true;
      }
      if (this.reportingRenderViewModel.pageNumber == this.reportingRenderViewModel.pageCount) {
        this.lastPageDisabled = true;
        this.nextPageDisabled = true;
      }
    }

    this.reportToolBarDisabled = false;
    this.hideProgressSpinner = true;

    if (this.downloadReportFile) {
      this.reportFileDownloaded = true;
    }
  }

  private searchAndNavigateToFirstMatch() {
    this.pagesMatchesCounters = [];
    this.firstMatchFound = false;
    this.firstMatchPageNumber = -1;
    this.searchMatchesCount = 0;
    this.currentMatchIdx = -1;

    let currentPageIdx = this.reportingRenderViewModel.pageNumber - 1;

    let i = currentPageIdx;
    let searchedInCurrentPage = false;

    // start searching from current page
    while (i != currentPageIdx || !searchedInCurrentPage) {
      searchedInCurrentPage = true;
      let page = this.htmlReportPages[i];

      this.finders[i] = findAndReplaceDOMText(page,
        {
          find: new RegExp(RegexEscape(this.searchTerm), 'ig'), // skip search term from special chars
          wrap: 'mark',
          portionMode: 'first'
        });

      let matches = page.getElementsByTagName('mark');
      this.pagesMatchesCounters[i] = matches.length;
      this.searchMatchesCount += matches.length;

      if (i < currentPageIdx) {
        this.currentMatchIdx += matches.length;
      }

      let firstMatch = matches.item(0);
      if (!this.firstMatchFound && firstMatch) {
        firstMatch.className = "highlighted";
        this.firstMatchFound = true;
        this.firstMatchPageNumber = i + 1;
        this.currentMatchPageNumber = i + 1;
        this.currentMatchIdx++;
      }

      i = (i + 1) % this.htmlReportPages.length;
    }

    this.hideSearchButton = true;

    if (this.firstMatchPageNumber != -1) {
      this.reportingRenderViewModel.pageNumber = this.firstMatchPageNumber;
      this.gotoPage();
    }
  }

  private gotoNextSearchMatch() {
    let currentMatchPageIdx = this.currentMatchPageNumber - 1;
    let currentPageMatches = this.htmlReportPages[currentMatchPageIdx].getElementsByTagName('mark');
    let highlightedMatchIdx = Array.from(currentPageMatches).findIndex(match => match.className == "highlighted");

    // if not the last match in current page
    if (highlightedMatchIdx != this.pagesMatchesCounters[currentMatchPageIdx] - 1) {
      currentPageMatches.item(highlightedMatchIdx).className = "";
      currentPageMatches.item(highlightedMatchIdx + 1).className = "highlighted";
      this.currentMatchIdx++;

      this.reportingRenderViewModel.pageNumber = currentMatchPageIdx + 1;
      this.currentMatchPageNumber = currentMatchPageIdx + 1;
      this.gotoPage();  // user may changed the page manually, so navigate to page with match
    }
    else {
      let nextMatchPageIdx = -1;

      for (let i = currentMatchPageIdx + 1; i < this.pagesMatchesCounters.length; i++) {
        if (this.pagesMatchesCounters[i] > 0) {
          nextMatchPageIdx = i;
          break;
        }
      }

      if (nextMatchPageIdx != -1) {
        currentPageMatches.item(highlightedMatchIdx).className = "";
        let firstMatch = this.htmlReportPages[nextMatchPageIdx].getElementsByTagName('mark').item(0);
        firstMatch.className = "highlighted";
        this.currentMatchIdx++;

        this.reportingRenderViewModel.pageNumber = nextMatchPageIdx + 1;
        this.currentMatchPageNumber = nextMatchPageIdx + 1;
        this.gotoPage();
      }
    }
  }

  private gotoPreviousSearchMatch() {
    let currentMatchPageIdx = this.currentMatchPageNumber - 1;
    let currentPageMatches = this.htmlReportPages[currentMatchPageIdx].getElementsByTagName('mark');
    let highlightedMatchIdx = Array.from(currentPageMatches).findIndex(match => match.className == "highlighted");

    // if not the first match in current page
    if (highlightedMatchIdx != 0) {
      currentPageMatches.item(highlightedMatchIdx).className = "";
      currentPageMatches.item(highlightedMatchIdx - 1).className = "highlighted";
      this.currentMatchIdx--;

      this.reportingRenderViewModel.pageNumber = currentMatchPageIdx + 1;
      this.currentMatchPageNumber = currentMatchPageIdx + 1;
      this.gotoPage(); // user may changed the page manually, so navigate to page with match
    }
    else {
      let previousMatchPageIdx = -1;

      for (let i = currentMatchPageIdx - 1; i >= 0; i--) {
        if (this.pagesMatchesCounters[i] > 0) {
          previousMatchPageIdx = i;
          break;
        }
      }

      if (previousMatchPageIdx != -1) {
        currentPageMatches.item(highlightedMatchIdx).className = "";

        let lastMatchIdx = this.pagesMatchesCounters[previousMatchPageIdx] - 1;
        let lastMatch = this.htmlReportPages[previousMatchPageIdx].getElementsByTagName('mark').item(lastMatchIdx);
        lastMatch.className = "highlighted";
        this.currentMatchIdx--;

        this.reportingRenderViewModel.pageNumber = previousMatchPageIdx + 1;
        this.currentMatchPageNumber = previousMatchPageIdx + 1;
        this.gotoPage();
      }
    }
  }
}
