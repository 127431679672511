import { Component, OnInit } from '@angular/core';
import { IchsGridComponentConfig, IchsControlType } from 'src/app/controls/ichs-grid/grid-configs';
import { SearchViewModel, CommonQuestionViewModel, CommonQuestionService } from 'src/app/rest';
import { IchsDialogComponent } from 'src/app/controls/ichs-dialog/ichs-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { AppGenericLists } from '../../app.general.lists';

@Component({
  selector: 'help-center-question-list',
  templateUrl: './help-center-question-list.component.html',
  styleUrls: ['./help-center-question-list.component.css']
})
export class HelpCenterQuestionListComponent implements OnInit {
  gridConfig: IchsGridComponentConfig<CommonQuestionViewModel>;

  /** group ctor */
  constructor(
    private questionService: CommonQuestionService,
    public dialog: MatDialog,
  ) {
    this.gridConfig = this.defineGrid();
  }

  /** GroupList OnInit*/
  ngOnInit(): void {
    this.refresh()
  }

  /** This function is used to get the Group list*/
  refresh() {
  }


  private defineGrid(): IchsGridComponentConfig<CommonQuestionViewModel> {
    let configs: IchsGridComponentConfig<CommonQuestionViewModel> = {
      primaryId: "id",
      defaultOrder: "question",
      title: "Help Questions",
      entityController: "help-question",
      hasVoid: false,
      hasActionColumn: true,
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: CommonQuestionViewModel) => {
          if (!deletedObj.id) return;
          let dialogRef = this.dialog.open(IchsDialogComponent, {
            width: '450px',
            data: { title: "Delete Question", message: "Are you sure about delete this Question?" }
          });

          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.questionService._delete(deletedObj.id, deletedObj.version).subscribe(result => {
                if (result) {
                  this.gridConfig.refreshGrid!(this.gridConfig.getPage!());
                }
              });
            }
          });
        }
      },
      hasNew: true,
      entityDataSource: (filter: SearchViewModel) => this.questionService.search(filter),
      headers: [
        {
          headerTitle: "Question",
          propertyName: "question",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Line Of Business",
          propertyName: "lineOfBusiness",
          sortable: true,
          searchable: true,
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.LineOfBusinessCategory,
        }
      ],
    }

    return configs;
  }
}
