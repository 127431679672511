import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { EXTRA_DATA, IchsComponentDialogComponent, DIALOG_REF } from '../../ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { ImageCropperComponent, ImageCroppedEvent } from 'ngx-image-cropper';
import { MatDialogRef } from '@angular/material/dialog';
import { AppSettings, ValidationMessages } from '../../../app.general.constants';
import { AlertMessageType, AlertDismissType, MedcorAlertService } from '../../../services/medcor-alert.service';
import { IchsImageCropperConfig } from '../image-cropper-config';

@Component({
  selector: 'cropper-dialog',
  templateUrl: './cropper-dialog.component.html',
  styleUrls: ['./cropper-dialog.component.css']
})
export class CropperDialogComponent implements OnInit {

  imageChangedEvent: any;
  hideCropper: boolean = true;
  isCropperReady: boolean = false;
  isUploadingImage: boolean = false;
  @ViewChild(ImageCropperComponent, { static: true }) imageCropper: ImageCropperComponent;

  constructor(
    private alertService: MedcorAlertService,
    @Inject(DIALOG_REF) private dialogRef: MatDialogRef<IchsComponentDialogComponent, ImageCroppedEvent>,
    @Inject(EXTRA_DATA) public cropperConfig: IchsImageCropperConfig,
  ) {
  }

  ngOnInit() {
    // define "toBlob" function if not defined (it is not defined by default in IE & Edge)
    if (!HTMLCanvasElement.prototype.toBlob) {
      Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
        value: function (callback, type, quality) {
          var dataURL = this.toDataURL(type, quality).split(',')[1];
          setTimeout(function () {

            var binStr = atob(dataURL),
              len = binStr.length,
              arr = new Uint8Array(len);

            for (var i = 0; i < len; i++) {
              arr[i] = binStr.charCodeAt(i);
            }

            callback(new Blob([arr], { type: type || 'image/png' }));
          });
        }
      });
    }
  }

  imageSelected(event: any) {
    if (event.srcElement.files.length == 0) {
      return;
    }

    if (event.srcElement.files[0].size > AppSettings.MAX_FILE_SIZE) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: [ValidationMessages.FILE_SIZE_LIMIT_EXCEEDED]
      });
      return;
    }

    this.hideCropper = true;
    this.isUploadingImage = true;
    this.imageChangedEvent = event;
  }

  saveCroppedImage() {
    let result = this.imageCropper.crop();

    if (result instanceof Promise) {
      result.then(value => {
        this.dialogRef.close(value);
      });
    } else {
      this.dialogRef.close(result);
    }
  }

  imageLoaded() {
    this.isUploadingImage = false;
    this.hideCropper = false;
  }

  cropperReady() {
    this.isCropperReady = true;
  }

  loadImageFailed() {
    this.alertService.addAlert({
      type: AlertMessageType.warning,
      title: 'Warning!',
      dismiss: AlertDismissType.auto,
      messages: ["Invalid image file, PNG, GIF & JPG files are only allowed!"]
    });
  }
}
