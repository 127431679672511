import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchViewModel, VoidInfoViewModel, MedcorResponse, PaginatedListViewModel } from '../../rest/index';
import { VoidDialogComponent } from '../ichs-dialog/void-dialog/void-dialog.component';
import { IchsDialogComponent } from '../ichs-dialog/ichs-dialog.component';
import { AddExistingComponent } from './add-existing/add-existing.component';
import { first } from 'rxjs/operators';
import { IchsGridComponentConfig, GridMultiSelectConfig, GridAddExistingConfig, IchsControlType, IchsGridColumnConfig, IchsGridActionsConfig } from './grid-configs';

@Component({
  selector: 'ichs-grid',
  templateUrl: './ichs-grid.component.html',
  styleUrls: ['./ichs-grid.component.css']
})

//THE GRID COMPONENT FOR ICONNECT PROJECT
//the grid receives a configuration object that controls how it should behave and load the data.
//it provide, search, sort, and default required actions. please refer to:
//http://10.1.1.80:8080/tfs/ClientPortalCollection/ClientPortal/_wiki?pagePath=%2FClient-Portal%27s-UI-controls%2FichsGrid
export class IchsGridComponent implements OnInit {
  public uniqueIdentifier = uniqueIdentifier++;

  waitingFullLoad: boolean = true;
  dataSource: MatTableDataSource<any>;
  private _recordStatus: string = "active";// holds the status of the rows to load, by default active
  set recordStatus(val: string) {
    this._recordStatus = val;
    this.loadData(0);
  }
  get recordStatus(): string {
    return this._recordStatus;
  }
  sortedColumn: string;// the sorted column
  sortedMode: string;// the sort mode.

  @ViewChild(MatPaginator) paginator: MatPaginator; // pointer to the paginator object in the page
  @Input('grid-config') _configs: IchsGridComponentConfig<any>; // grid configuration object passed from the parent page.
  pageIndex: number = 0;
  pageLength: number = 0;
  pageSize: number = 10;

  multiSelectConfig: GridMultiSelectConfig | undefined;
  addExistConfig: GridAddExistingConfig<any> | undefined;

  selectedItems: string[] = [];
  selectedObjects: any[] = [];

  private readonly queryParamPrefix: string = "ichs-grid-";

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit() {
    //use the default variables in the configuration object if they are not passed by the parent
    Object.assign(this._configs, { ...new IchsGridComponentConfig(), ...this._configs });
    if (this._configs.multiSelectConfig) {
      Object.assign(this._configs.multiSelectConfig, { ...new GridMultiSelectConfig(), ...this._configs.multiSelectConfig });
    }
    if (this._configs.headers) {
      for (let header of this._configs.headers) {
        Object.assign(header, { ...new IchsGridColumnConfig(), ...header });
      }
    }

    this.addExistConfig = this._configs.addExistingConfig;
    this.pageSize = this._configs.pageSize ? this._configs.pageSize : 10;
    this.multiSelectConfig = this._configs.multiSelectConfig;
    if (this.multiSelectConfig) {
      this.multiSelectConfig.getSelected = () => this.selectedItems;
      this.selectedItems.push(...this.multiSelectConfig.initialSelection!);
      this.multiSelectConfig.setSelected = (...items: string[]) => this.selectedItems.push(...items);
      this.multiSelectConfig.removeSelected = (...items: string[]) => this.selectedItems = this.selectedItems.filter(e => items.indexOf(e) == -1);
      if (this.multiSelectConfig.allowSelectObjects) {
        this.multiSelectConfig.getSelectedObjects = () => {
          this.selectedObjects = this.dataSource.data.filter(obj => this.selectedItems.indexOf(obj[this._configs.primaryId].toString()) > -1);
          return this.selectedObjects
        };
      }
    }
    this._configs.refreshGrid = (pageIndex: number) => {
      this.loadData(pageIndex);
    }

    this._configs.getPage = () => {
      return this.paginator.pageIndex;
    }

    this.sortedColumn = this._configs.defaultOrder ? this._configs.defaultOrder : this._configs.primaryId;
    this.sortedMode = this._configs.defaultOrderType ? this._configs.defaultOrderType : "desc";

    this.getFiltersFromQueryParams();

    if (this._configs.loadOnInit) {
      this.loadData(0);
    }
    else {
      this.waitingFullLoad = false;
    }
  }

  //load the filters from the grid header
  private loadFilters(): string {
    let filter: string = "";

    if ((this._configs.hasVoid || this._configs.showVoided) && this.recordStatus != "all")
      filter += " voidId " + (this.recordStatus == "active" ? "== 0" : "> 0");

    let queryFilter: string = this._configs.headers
      .filter((obj) => (obj.filterVal || "").length > 0 && (obj.controlType == IchsControlType.Textfield))
      .map((obj) => { return obj.propertyName + ".contains(" + this.getFilter(obj) + ")" }).join(" && ");

    let booleanFilter: string = this._configs.headers
      .filter((obj) => (obj.filterVal || "").length > 0 && obj.controlType == IchsControlType.Boolean)
      .map((obj) => { return obj.propertyName + "==" + obj.filterVal }).join(" && ");

    let equalityFilter: string = this._configs.headers
      .filter(
        (obj) => (obj.filterVal || "").length > 0 &&
          (obj.controlType == IchsControlType.StaticSelect || obj.controlType == IchsControlType.DynamicSelect || obj.controlType == IchsControlType.Number)
      )
      .map((obj) => { return obj.propertyName + "==" + this.getFilter(obj) }).join(" && ");

    let dateFilters: string = this._configs.headers
      .filter((obj) => obj.filterVal && obj.controlType == IchsControlType.Date)
      .map((obj) => {
        var dateObj = new Date(obj.filterVal);
        return "" + obj.propertyName + "." + (obj.nullable ? "Value.Date" : "Date") + " == \"" + (dateObj.getMonth() + 1) + "/" + dateObj.getDate() + "/" + dateObj.getFullYear() + "\""
      }).join(" && ");

    filter += filter.length > 0 && queryFilter.length > 0 ? " && " + queryFilter : (queryFilter.length > 0 ? queryFilter : "");

    filter += filter.length > 0 && booleanFilter.length > 0 ? " && " + booleanFilter : (booleanFilter.length > 0 ? booleanFilter : "");

    filter += filter.length > 0 && equalityFilter.length > 0 ? " && " + equalityFilter : (equalityFilter.length > 0 ? equalityFilter : "");

    filter += filter.length > 0 && dateFilters.length > 0 ? " && " + dateFilters : (dateFilters.length > 0 ? dateFilters : "");

    return filter;
  }

  //clear grid filters
  clearFilters() {
    if (this._configs.beforeResetFunction) {
      this._configs.beforeResetFunction();
    }

    this._configs.headers.map(obj => obj.filterVal = "");
    if (!this._configs.disableDataLoadingOnReset) {
      this.loadData(0);
    }
    else {
      this.dataSource = new MatTableDataSource<any>([]);
    }

    if (this._configs.afterResetFunction) {
      this._configs.afterResetFunction();
    }
  }

  //load the data from the service provided.
  loadData(_pageIndex: number): void {

    let vm: SearchViewModel = {
      filterExpression: this.loadFilters(),
      pageIndex: _pageIndex + 1,//in the server page index starts from 1, in the client it starts from 0
      pageSize: this.pageSize,
      sortExpressions: [this.sortedColumn + " " + this.sortedMode]
    };
    let dataResult = this._configs.entityDataSource(vm);
    if (dataResult instanceof Observable) {
      this.dataSource = null;
      this.waitingFullLoad = true;

      dataResult.pipe(first()).subscribe({
        next: (resp) => {
          if (resp.result) {
            let _result: PaginatedListViewModel<any> = resp.result!;

            if (this._configs.preprocessData) {
              this._configs.preprocessData(_result.entities);
            }

            this.dataSource = new MatTableDataSource<any>(_result.entities);
            this.pageIndex = _result.pageIndex! - 1;
            this.pageLength = _result.totalItems!;
            this.waitingFullLoad = false;

            if (this.multiSelectConfig) {
              let entities = _result.entities;
              this.selectedItems = this.selectedItems.filter(item => entities.map(entity => entity[this._configs.primaryId].toString()).indexOf(item) > -1);
            }
          }
          else {
            this.waitingFullLoad = false;
            this.dataSource = new MatTableDataSource<any>([]);
          }
        },
        error: () => {
          this.waitingFullLoad = false;
          this.dataSource = new MatTableDataSource<any>([]);
        }
      });

      this.setFiltersInQueryParams();
    } else {
      this.dataSource = new MatTableDataSource<any>(dataResult.entities);
      this.pageIndex = dataResult.pageIndex! - 1;
      this.pageLength = dataResult.totalItems!;

      if (this.multiSelectConfig) {
        let entities = dataResult.entities;
        this.selectedItems = this.selectedItems.filter(item => entities.map(entity => entity[this._configs.primaryId].toString()).indexOf(item) > -1);
      }

      this.setFiltersInQueryParams();
    }
  }

  getDisplayedColumns() {
    let displayedColumns = this._configs.headers
      .filter(col => (col.isVisible || col.isVisible == null) && (!col.hideColumn || (col.hideColumn && !col.hideColumn())))
      .map(prop => prop.propertyName);

    if (this._configs.hasActionColumn) {
      displayedColumns.push('actions');
    }

    if (this.multiSelectConfig) {
      displayedColumns.splice(0, 0, 'select');
    }

    return displayedColumns;
  }

  /** Whether if all the rows are selected or not*/
  isAllSelected(): boolean {
    if (this.dataSource == undefined || this.dataSource.data == undefined) {
      return false;
    }
    for (var i = 0; i < this.dataSource.data.length; i++) {
      if (!this.rowSelected(this.dataSource.data[i][this._configs.primaryId].toString())) {
        return false;
      }
    }
    return true;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(checkAll: boolean) {
    this.dataSource.data.forEach((value) => {

      let id = value[this._configs.primaryId].toString()
      let exists = this.rowSelected(id);
      if (!exists && checkAll) {
        this.selectedItems.push(id)
        if (this.multiSelectConfig!.allowSelectObjects) {
          this.selectedObjects.push(value);
        }
      } else if (exists && !checkAll) {
        var index = this.selectedItems.indexOf(id, 0);
        if (index > -1) {
          this.selectedItems.splice(index, 1);
          if (this.multiSelectConfig!.allowSelectObjects) {
            this.selectedObjects.splice(index, 1);
          }
        }
      }
    });
  }

  //select a single row based on row ID
  rowSelected(id: string): boolean {
    return this.selectedItems.indexOf(id) > -1;
  }

  //handle selecting a single row from UI
  rowSelectionEvent(checked: boolean, id: string, obj: any) {
    if (checked && !this.rowSelected(id)) {
      this.selectedItems.push(id.toString())
      if (this.multiSelectConfig!.allowSelectObjects) {
        this.selectedObjects.push(obj);
      }
    } else {
      var index = this.selectedItems.indexOf(id, 0);
      if (index > -1) {
        this.selectedItems.splice(index, 1);
        if (this.multiSelectConfig!.allowSelectObjects) {
          this.selectedObjects.splice(index, 1);
        }
      }
    }
  }

  //handles open details
  openDetails(obj: any) {
    if (!this._configs.hasDetails)
      return;
    if (!this._configs.detailAction)
      this.router.navigate(['/' + this._configs.entityController, obj[this._configs.primaryId]]);
    else
      this._configs.detailAction.function(obj);
  }

  //will be called if the second action was provided
  secondAction(obj: any) {
    if (this._configs.secondAction)
      this._configs.secondAction.function(obj);
  }

  //will be called if the third action was provided
  thirdAction(obj: any) {
    if (this._configs.thirdAction)
      this._configs.thirdAction.function(obj);
  }

  //will be called if the fourth action was provided
  fourthAction(obj: any) {
    if (this._configs.fourthAction)
      this._configs.fourthAction.function(obj);
  }

  //will be called if the fifith action was provided
  fifthAction(obj: any) {
    if (this._configs.fifthAction)
      this._configs.fifthAction.function(obj);
  }

  //call open details by default, if there is a double click action provided in the config, it will be called.
  doubleClickAction(obj: any) {

    if (!this._configs.doubleClickAction)
      this.openDetails(obj);
    else
      this._configs.doubleClickAction.function(obj);
  }

  // Get the Background color of the row
  getRowColor(obj: any): string | null {
    if (!this._configs.rowColor)
      return null
    else {
      return this._configs.rowColor(obj);
    }
  }

  // Get the css style of the row
  getRowStyle(obj: any): string | null {
    if (!this._configs.rowStyle)
      return null
    else {
      return this._configs.rowStyle(obj);
    }
  }

  voidRecoverRecord(obj: any) {
    if (obj.hasOwnProperty("voidId")) {
      if (obj.voidId > 0) {
        let dialogRef = this.dialog.open(IchsDialogComponent, {
          width: '450px',
          data: { title: "Recover " + this._configs.title, message: "Are you sure about recovering this Record?" }
        });

        let sub = dialogRef.afterClosed().subscribe(result => {
          sub.unsubscribe();
          if (result) {
            let sub = this._configs.recoverAction!(obj[this._configs.primaryId]).subscribe((recoverResult: MedcorResponse<any>) => {
              sub.unsubscribe();
              if (recoverResult.statusCode == 200) {
                this.loadData(this.paginator.pageIndex);
              }
            });
          }
        });

      } else {
        let dialogRef = this.dialog.open(VoidDialogComponent, {
          width: '450px',
          data: { title: "Void " + this._configs.title, message: "Are you sure about voiding this Record?" }
        });

        let sub = dialogRef.afterClosed().subscribe(result => {
          sub.unsubscribe();
          if (result) {
            let voidObj: VoidInfoViewModel = { instanceId: obj[this._configs.primaryId], reason: result.reason, voidComment: result.comment };
            this._configs.voidAction!(voidObj).subscribe((voidResult: MedcorResponse<any>) => {
              if (voidResult.statusCode == 200) {
                this.loadData(this.paginator.pageIndex);
              }
            });

          }
        });
      }
    }
  }

  //call add existing based on the grid
  addExisting() {
    if (this.addExistConfig) {

      let dialogRef = this.dialog.open(AddExistingComponent, {
        width: '1024px',
        height: '800px',
        panelClass:'main-theme',
        data: {
          config: this.addExistConfig.gridConfig,
          service: this.addExistConfig.addExistingService,
          customService: this.addExistConfig.customAddExisting,
          title: this.addExistConfig.title
        }
      });

      let sub = dialogRef.afterClosed().subscribe(result => {
        sub.unsubscribe();
        if (result) {
          this.loadData(0);
        }
      });
    }


  }
  //call export action
  export() {
    //TODO: implement default functionality
    if (this._configs.exportingAction) {
      this._configs.exportingAction.function();
    }
  }

  //sort the grid based on the clicked column and reset the pagination to the first page.
  //clicking on the same column will reverse the sorting mode.
  sortColumn(col: IchsGridColumnConfig) {
    if (!col.sortable)
      return;
    if (this.sortedColumn == col.propertyName) {
      this.sortedMode = this.sortedMode == "desc" ? "asc" : "desc";
    }
    this.sortedColumn = col.propertyName;
    this.loadData(0);
  }

  customNew() {
    if (this._configs.customNewAction) {
      this._configs.customNewAction.function();
    }
  }

  customNew2() {
    if (this._configs.customNewAction2) {
      this._configs.customNewAction2.function();
    }
  }

  //event that will be called when changing the page or the number of records per page.
  pageChanged(event: any) {
    this.pageSize = event.pageSize;
    this.loadData(this.paginator.pageIndex);
  }

  onActionMatMenuClosed(action: IchsGridActionsConfig){
    // clear mat-menu after menu is closed (so same items won't appear when user opens another menu)
    action.matMenuItems = [];
  }

  //add "" around the string variables. required by the server.
  private getFilter(col: IchsGridColumnConfig): string | undefined {
    switch (col.controlType) {
      case IchsControlType.Textfield:
      case IchsControlType.Date:
      case IchsControlType.DynamicSelect:
      case IchsControlType.StaticSelect:
        return '"' + col.filterVal.replace(/\\/g, '\\$&') + '"';  // skip '\' character
      default:
        return col.filterVal;
    }
  }

  private setFiltersInQueryParams() {
    let _params = {};

    if (this.recordStatus && this.recordStatus != "active") {
      _params[this.queryParamPrefix + 'recordStatus'] = this.recordStatus;
    }
    else {
      _params[this.queryParamPrefix + 'recordStatus'] = undefined;
    }

    this._configs.headers.forEach(header => {
      if (header.filterVal) {
        if (header.controlType == IchsControlType.Date) {
          _params[this.queryParamPrefix + header.propertyName] = new Date(header.filterVal).getTime();
        }
        else {
          _params[this.queryParamPrefix + header.propertyName] = header.filterVal;
        }
      }
      else {
        _params[this.queryParamPrefix + header.propertyName] = undefined;
      }
    });

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: _params,
        queryParamsHandling: 'merge'
      });
  }

  private getFiltersFromQueryParams() {
    if (this.activatedRoute.snapshot.queryParams[this.queryParamPrefix + 'recordStatus'] && this.activatedRoute.snapshot.queryParams[this.queryParamPrefix + 'recordStatus'] != "active") {
      this.recordStatus = this.activatedRoute.snapshot.queryParams[this.queryParamPrefix + 'recordStatus'];
    }

    for (let paramName in this.activatedRoute.snapshot.queryParams) {
      if (paramName != this.queryParamPrefix + 'recordStatus') {
        let header = this._configs.headers.find(header => this.queryParamPrefix + header.propertyName == paramName);
        if (header) {
          if (header.controlType == IchsControlType.Date) {
            header.filterVal = new Date(+this.activatedRoute.snapshot.queryParams[paramName]);
          }
          else {
            header.filterVal = this.activatedRoute.snapshot.queryParams[paramName];
          }
        }
      }
    }
  }
}

let uniqueIdentifier = 0;
