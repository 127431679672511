import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppGenericLists } from 'src/app/app.general.lists';
import { IchsDialogComponent } from 'src/app/controls/ichs-dialog/ichs-dialog.component';
import { IchsControlType, IchsGridActionsConfig, IchsGridComponentConfig } from 'src/app/controls/ichs-grid/grid-configs';
import { DashboardSchedulerService, DashboardSchedulerViewModel, LoggedUserViewModel, SearchViewModel, SelectionItemViewModel } from 'src/app/rest';
import { GeneralVariablesService } from 'src/app/services/general-variables.service';
import { MedcorUserInfoService } from 'src/app/services/medcor-user-info.service';
import { DashboardSchedulerConfig, DashboardSchedulerComponent } from '../dashboard-scheduler/dashboard-scheduler.component';

@Component({
  selector: 'dashboard-scheduler-list',
  templateUrl: './dashboard-scheduler-list.component.html',
  styleUrls: ['./dashboard-scheduler-list.component.css']
})
export class DashboardSchedulerListComponent implements OnInit {

  gridConfig: IchsGridComponentConfig<DashboardSchedulerViewModel>;
  userHasScheduleReportsAndDashboardsFullAccess: boolean;

  recipientId: number;
  recipientsItems: SelectionItemViewModel[];

  get loggedInUserObs(): Observable<LoggedUserViewModel> { return this.medcorUserInfoService.loggedInUserObs }

  constructor(
    private dashboardSchedulerService: DashboardSchedulerService,
    private dialog: MatDialog,
    private generalVariables: GeneralVariablesService,
    private medcorUserInfoService: MedcorUserInfoService,
  ) {
  }

  ngOnInit() {
    this.loggedInUserObs.pipe(first()).subscribe(loggedInUser => {
      this.userHasScheduleReportsAndDashboardsFullAccess = loggedInUser.allowScheduleReports;
      if (this.userHasScheduleReportsAndDashboardsFullAccess) {
        this.dashboardSchedulerService.getDashboardSchedulesRecipientsAsSelectionList()
          .pipe(first())
          .subscribe(resp => {
            this.recipientsItems = resp.result;
          });
      }

      this.gridConfig = this.defineGrid();
    });
  }

  private defineGrid(): IchsGridComponentConfig<DashboardSchedulerViewModel> {
    let configs: IchsGridComponentConfig<DashboardSchedulerViewModel> = {
      primaryId: "id",
      defaultOrder: "startPeriod",
      defaultOrderType: "desc",
      hasNew: false,
      hasVoid: false,
      detailAction: new IchsGridActionsConfig({
        function: (obj: DashboardSchedulerViewModel) => {
          let dialogConfig: MatDialogConfig<DashboardSchedulerConfig> = {
            data: {
              scheduleId: obj.id
            },
            width: '800px',
            disableClose: true
          };
          let dialogRef = this.dialog.open(DashboardSchedulerComponent, dialogConfig);
          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.gridConfig.refreshGrid(0);
            }
          });
        }
      }),
      secondAction: !this.generalVariables.userImpersonationInfo.impersonatorRoleInd ? new IchsGridActionsConfig({
        icon: "assets/images/remove-user.svg",
        title: "Remove Self from Recipients",
        function: (deletedObj: DashboardSchedulerViewModel) => {
          if (!deletedObj.id) {
            return
          };

          let dialogRef = this.dialog.open(IchsDialogComponent, {
            width: '450px',
            data: {
              title: "Remove Self from Recipients",
              message: "Are you sure you want to remove yourself from this dashboard schedule recipients list? You will no more receive this dashboard."
            }
          });

          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.dashboardSchedulerService.removeSelfFromRecipients(deletedObj.id)
                .pipe(first())
                .subscribe(resp => {
                  // if current user is the only recipient, then report schedule will be deleted, refresh the grid
                  // also, if user does not has userHasScheduleReportsAndDashboardsFullAccess, then schedule should be removed from his/her grid
                  if (resp.result || !this.userHasScheduleReportsAndDashboardsFullAccess) {
                    this.gridConfig.refreshGrid!(this.gridConfig.getPage!());
                  }
                });
            }
          });
        }
      }) : null,
      thirdAction: !this.generalVariables.userImpersonationInfo.impersonatorRoleInd && this.userHasScheduleReportsAndDashboardsFullAccess ? new IchsGridActionsConfig({
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: DashboardSchedulerViewModel) => {
          if (!deletedObj.id) {
            return
          };

          let dialogRef = this.dialog.open(IchsDialogComponent, {
            width: '450px',
            data: {
              title: "Delete Dashobard Schedule",
              message: "Are you sure you want to delete (cancel) this dashboard schedule? This operation will cancel the schedule for all recipients of the schedule. This process cannot be undone.",
            }
          });

          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.dashboardSchedulerService._delete(deletedObj.id, deletedObj.version)
                .pipe(first())
                .subscribe(() => {
                  this.gridConfig.refreshGrid!(this.gridConfig.getPage!());
                });
            }
          });
        }
      }) : null,
      entityDataSource: (filter: SearchViewModel) => this.dashboardSchedulerService.searchDashboardSchedulesByRecipient(this.recipientId, filter),
      beforeResetFunction: () => {
        this.recipientId = undefined;
      },
      headers: [
        {
          headerTitle: "Medcor Dashboard Name",
          propertyName: "dashboardName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Frequency",
          propertyName: "runMode",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.ReportScheduleMode,
          width: 100,
          hideColumn: () => !this.generalVariables.clientDeviceInfo.isDesktop,
        },
        {
          headerTitle: "Start Date",
          propertyName: "startPeriod",
          searchable: true,
          sortable: true,
          nullable: true,
          convertUtcDateToLocal: false,
          dateFormat: 'shortDate',
          controlType: IchsControlType.Date,
          width: 120,
          hideColumn: () => !this.generalVariables.clientDeviceInfo.isDesktop,
        },
        {
          headerTitle: "End Date",
          propertyName: "endPeriod",
          searchable: true,
          sortable: true,
          nullable: true,
          convertUtcDateToLocal: false,
          dateFormat: 'shortDate',
          controlType: IchsControlType.Date,
          width: 120,
          hideColumn: () => !this.generalVariables.clientDeviceInfo.isDesktop,
        },
        {
          headerTitle: "Last Sent",
          propertyName: "lastRunTime",
          searchable: true,
          sortable: true,
          nullable: true,
          convertUtcDateToLocal: false,
          dateFormat: 'short',
          controlType: IchsControlType.Date,
          width: 150,
        },
        {
          headerTitle: "Scheduled By",
          propertyName: "dashboardOwnerName",
          titlePropertyName: "dashboardOwnerEmail",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }
}
