<form #form="ngForm" class="flex-child">
  <div class="display-flex flex-items-centered ">
    <div>
      <h2 mat-dialog-title>
        <span>{{model.title}}</span>
      </h2>
    </div>
    <div class="flex-grow-1"></div>
    <div class="display-flex flex-item-centered v-cont" *ngIf="data.reportingParametersViewModel.showWorkingDateParameter && !data.fromReportScheduler">
      <div class="margin-right-def">
        <ichs-hidden-date-picker label="Working Date" date name="workingDate" [(ngModel)]="workingDate">
        </ichs-hidden-date-picker>
      </div>
      <div class="display-flex flex-items-centered">
        <img (click)="resetWorkingDate()" title="Reset Date" src="assets/images/refresh3.svg" class="reset-icon" />
      </div>
    </div>
    <div class="icon-cont">
      <img title="Drag" src="assets/images/newIcons/braille-six-dots.svg" class="report-params-dialog-grab-icon" cdkDrag
        cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle />
    </div>
  </div>

  <mat-dialog-content>
    <pre *ngIf="model.errorMessage">{{model.errorMessage}}</pre>
    <div *ngIf="!model.errorMessage">
      {{model.message}}
      <div [hidden]="model.showAccessControl">
        <div class="display-flex flex-child" *ngIf="!(startDateParamHidden || endDateParamHidden)">
          <ichs-select class="flex-basis-0 flex-grow-2" name="dateRange" [(ngModel)]="model.dateRange"
            [items]="model.dateRanges" label="The report should contain data from" placeholder="Custom Date Range"
            resetLabel="Custom Date Range"></ichs-select>
        </div>
        <div *ngIf="!model.dateRange || startDateParamHidden || endDateParamHidden">
          <div *ngIf="!startDateParamHidden" class="display-flex flex-child">
            <ichs-date-picker date class="flex-basis-0 flex-grow-2" name="startDate" [(ngModel)]="model.startDate"
              required [label]="startDateLabel"></ichs-date-picker>
          </div>
          <div *ngIf="!endDateParamHidden" class="display-flex flex-child">
            <ichs-date-picker date class="flex-basis-0 flex-grow-2" name="endDate" [(ngModel)]="model.endDate" required
              [label]="endDateLabel"></ichs-date-picker>
          </div>
        </div>
        <ng-container *ngIf="data.lineOfBusiness == 'Triage'">

          <div class="flex-child" *ngIf="!data.fromReportScheduler || (data.fromReportScheduler && hasPreviousSelection)">
            <ichs-select class="flex-basis-0 flex-grow-2" name="manageHierarchySelection"
              [(ngModel)]="manageHierarchySelection"
              [items]="hierarchySelectionOptions"
              label="Manage Hierarchy" [hideResetLabel]="true"
              required
              (selectionChanged)="onManageHierarchyChanged()">
            </ichs-select>
          </div>

          <div class="flex-child" *ngIf="manageHierarchySelection == USE_PREVIOUS_SELECTION">
            <div class="display-flex flex-items-centered">
              <a class="btn btn-tree flex-cell" (click)="viewPreviousHierarchySelectionsGrid()">
                View Previous Selection
              </a>
            </div>
          </div>

          <div class="flex-child" *ngIf="manageHierarchySelection == VIEW_SAVED_SELECTION">
            <div class="display-flex flex-items-centered">
              <a class="btn btn-tree flex-cell" (click)="viewSavedHierarchySelectionsGrid()">
                View Saved Selections
              </a>
            </div>
          </div>

          <div class="flex-child" [hidden]="manageHierarchySelection == USE_PREVIOUS_SELECTION">
            <ichs-dropdown-tree #triageHierarchyDropDown class="flex-basis-0 flex-grow-2" label="Selected Hierarchy" name="Hierarchy"
              [showSaveSelectionForFutureUse]="!data.fromReportScheduler"
              [data]="model.hierarchies"
              [isLevel]="false"
              [showActiveOnlyToggle]="true"
              (selectionEvent)="haveTriageHierarchyDisplayParam && hierarchiesInitialized ? onHierarchyDropDownChange() : null"
              (onSubmit)="onHierarchyDropDownSubmit()"
              (onCancel)="onHierarchyDropDownCancel()"
              [loadChildren]="loadNodeChildren">
            </ichs-dropdown-tree>
          </div>

          <div class="flex-child" *ngIf="haveTriageHierarchyDisplayParam">
            <ichs-dropdown-tree #triageHierarchyDisplayDropDown class="flex-basis-0 flex-grow-2" label="Hierarchy Display"
              name="HierarchyDisplay" [data]="model.hierarchyDisplay" [isLevel]="true">
            </ichs-dropdown-tree>
          </div>

        </ng-container>

        <ng-container *ngIf="data.lineOfBusiness == 'OSHA Reportable'">
          <div class="flex-child">
            <ichs-dropdown-tree #oshaHierarchyDropDown class="flex-basis-0 flex-grow-2" label="Selected Hierarchy" name="Hierarchy"
              [data]="model.oshaHierarchies" [isLevel]="false">
            </ichs-dropdown-tree>
          </div>
        </ng-container>

        <div *ngIf="model.reportParameters.length > 0">
          <div *ngFor="let reportParameter of model.reportParameters">
            <div class="display-flex flex-child" *ngIf="reportParameter.type != parameterType.Hidden">
              <div
                *ngIf="(reportParameter.validValues && reportParameter.validValues.length > 0) || reportParameter.validValuesQueryBased; then ichsSelect else notIchsSelect">
              </div>
              <ng-template #ichsSelect>
                <div *ngIf="reportParameter.multiValue ;then ichsSelectMulti else notIchsSelectMulti"></div>
                <ng-template #ichsSelectMulti>
                  <ichs-select *ngIf="!reportParameter.hidden" class="flex-basis-0 flex-grow-2"
                    [name]="reportParameter.name" [(ngModel)]="reportParameter.defaultValues"
                    [items]="reportParameter.validValues" [multiple]="true" [hasCloseButton]="true" [hasSelectAll]="true" [hasSearch]="true"
                    [disabled]="reportParameter.disabled" [label]="reportParameter.prompt"
                    (openedChange)="onChange(!$event, reportParameter)" [required]="reportParameter.required">
                  </ichs-select>
                </ng-template>
                <ng-template #notIchsSelectMulti>
                  <ichs-select *ngIf="!reportParameter.hidden" class="flex-basis-0 flex-grow-2"
                    [name]="reportParameter.name" [(ngModel)]="reportParameter.defaultValues[0]"
                    [items]="reportParameter.validValues" [disabled]="reportParameter.disabled"
                    [label]="reportParameter.prompt" (openedChange)="onChange(!$event, reportParameter)"
                    [required]="reportParameter.required" [hideResetLabel]="reportParameter.required"></ichs-select>
                </ng-template>
              </ng-template>
              <ng-template #notIchsSelect>
                <div [ngSwitch]="reportParameter.type" class="flex-basis-0 flex-grow-2">
                  <!--Boolean-->
                  <ichs-select *ngSwitchCase="parameterType.Boolean" [name]="reportParameter.name"
                    [(ngModel)]="reportParameter.defaultValues[0]" [label]="reportParameter.prompt"
                    [items]="[{ selectionValue:'True', selectionText:'True'},{ selectionValue:'False', selectionText:'False'}]"
                    (openedChange)="onChange(!$event, reportParameter)" [required]="reportParameter.required"
                    [hideResetLabel]="reportParameter.required"></ichs-select>

                  <!--DateTime-->
                  <ichs-date-picker *ngSwitchCase="parameterType.DateTime" [name]="reportParameter.name" [label]="reportParameter.prompt"
                    (dateChanged)="onChange(true, reportParameter)" [(ngModel)]="reportParameter.tempValue"
                    [required]="reportParameter.required"></ichs-date-picker>
                  <!--Integer-->
                  <ichs-text-box *ngSwitchCase="parameterType.Integer" [name]="reportParameter.name" [label]="reportParameter.prompt"
                    (change)="onChange(true, reportParameter)" [(ngModel)]="reportParameter.defaultValues[0]"
                    [required]="reportParameter.required"></ichs-text-box>
                  <!--Float-->
                  <ichs-text-box *ngSwitchCase="parameterType.Float" [name]="reportParameter.name" [label]="reportParameter.prompt"
                    (change)="onChange(true, reportParameter)" [(ngModel)]="reportParameter.defaultValues[0]"
                    [required]="reportParameter.required"></ichs-text-box>
                  <!--String-->
                  <ichs-text-box *ngSwitchCase="parameterType.String" [name]="reportParameter.name" [label]="reportParameter.prompt"
                    (change)="onChange(true, reportParameter)" [(ngModel)]="reportParameter.defaultValues[0]"
                    [required]="reportParameter.required" maxlength="200"></ichs-text-box>

                  <!--Advanced-Search-->
                  <div *ngSwitchCase="parameterType.AdvanceSearch">
                    <ng-container *ngIf="reportParameter.name == 'CompanyId'">
                      {{reportParameter.prompt}}
                      <div class="display-flex flex-items-centered">
                        <a class="btn btn-tree flex-cell" (click)="selectCompaniesGrid()">
                          {{reportParameter.defaultValues.length}} Selected
                        </a>
                        <input type="text" #hiddenCompanySelect="ngModel" [(ngModel)]="reportParameter.defaultValues"
                          name="hiddenCompanySelect" style="display:none"
                          [required]="reportParameter.defaultValues.length == 0" />
                        <div *ngIf="hiddenCompanySelect.touched && hiddenCompanySelect.invalid"
                          title="This field is required" class="flex-cell">
                          <img src="assets/images/information.svg" class="validation-icon" />
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="reportParameter.name == 'OshaLocationId'">
                      {{reportParameter.prompt}}
                      <div class="display-flex flex-items-centered">
                        <a class="btn btn-tree flex-cell" (click)="selectOshaLocationsGrid()" [class.disabled]="reportParameter.disabled">
                          {{reportParameter.defaultValues.length}} Selected
                        </a>
                        <input type="text" #hiddenOshaLocationSelect="ngModel" [(ngModel)]="reportParameter.defaultValues"
                          name="hiddenOshaLocationSelect" style="display:none"
                          [required]="reportParameter.defaultValues.length == 0" />
                        <div *ngIf="hiddenOshaLocationSelect.touched && hiddenOshaLocationSelect.invalid && !reportParameter.disabled"
                          title="This field is required" class="flex-cell">
                          <img src="assets/images/information.svg" class="validation-icon" />
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
        <a *ngIf="model.accessControlParameters.length > 0" (click)='model.showAccessControl = true'>Show Access Control
          Parameters</a>
      </div>
      <div [hidden]="!model.showAccessControl">
        <div *ngIf="model.accessControlParameters.length > 0">
          <div *ngFor="let reportParameter of model.accessControlParameters">
            <div class="display-flex flex-child">
              <ichs-select class="flex-basis-0 flex-grow-2" [name]="reportParameter.name"
                [label]="reportParameter.prompt" [(ngModel)]="reportParameter.defaultValues"
                [items]="reportParameter.validValues" [multiple]="true" hasSelectAll="true"
                (openedChange)="onChange(!$event, reportParameter)" [required]="reportParameter.required">
              </ichs-select>
            </div>
          </div>
        </div>
        <a (click)='model.showAccessControl = false'>Show Report Parameters</a>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions style="margin-top: 8px;">
    <div class="flex-basis-0 flex-grow-1 text-right display-flex justify-content-end">
      <button cdkFocusInitial
        [disabled]="(triageHierarchyDropDown && triageHierarchyDropDown.disabled) || (triageHierarchyDisplayDropDown && triageHierarchyDisplayDropDown.disabled)"
        mat-button class="cp-btn cp-main-btn" (click)="submit()">
        {{ config.okText ? config.okText : 'Ok'}}
      </button>
      <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">
        Cancel
      </button>
    </div>
  </mat-dialog-actions>
</form>
