import { Component, OnInit, ViewChild } from '@angular/core';
import { IchsCommandToolbarModel, IchsCommandToolbarDelegate } from '../../controls/ichs-command-toolbar/ichs-command-toolbar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MedcorAlertService } from '../../services/medcor-alert.service';
import { Location } from '@angular/common';
import { PageDesignViewModel, PageDesignService, SelectionItemViewModel, GroupService, MedcorResponse } from '../../rest';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { LineOfBusiness } from '../../app.general.constants';

@Component({
  selector: 'page-design',
  templateUrl: './page-design.component.html',
  styleUrls: ['./page-design.component.css']
})
export class PageDesignComponent implements OnInit, IchsCommandToolbarDelegate<PageDesignViewModel> {
  model: IchsCommandToolbarModel<PageDesignViewModel>;
  @ViewChild('form') ngForm: NgForm;
  customizedInd: boolean = false;
  designInd: boolean = true;
  groupId: string = null;
  lineOfBusiness: string = "All";
  public groupItems: SelectionItemViewModel[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pageDesignService: PageDesignService,
    private location: Location,
    private alertService: MedcorAlertService,
    private dialog: MatDialog,
    private groupService: GroupService,
  ) {
    this.model = new IchsCommandToolbarModel<PageDesignViewModel>(location, router, "/page-design", "Page Design", this, dialog, alertService);
    this.model.hasVoidRecover = false;
    this.model.hasNew = false;
    this.model.hasSaveAndNew = false;
    this.model.hasBack = false;
    this.model.hasCustomIdentifier = true;
  }


  ngOnInit() {
    this.lineOfBusinessChanged();
    this.groupService.getGroupsAsSelectionList().pipe(first()).subscribe(response => {
      this.groupItems = response.result!;
    });
  }

  canDeactivate() {
    return this.model.confirmCanDeactivate();
  }

  designMode() {
    this.designInd = true;
  }

  viewMode() {
    this.designInd = false;
  }

  clearDesign() {
    this.pageDesignService.clearPageDesignByGroupId(+this.groupId).pipe(first()).subscribe(response => {
      this.model.objModel = response.result;
    });
  }
  getSaveObservable(objModel: PageDesignViewModel): Observable<MedcorResponse<PageDesignViewModel>> {
    return this.pageDesignService.post(objModel)
  }

  groupChanged() {
    this.pageDesignService.getPageDesignByGroupId(+this.groupId).pipe(first()).subscribe(response => {
      this.model.objModel = response.result;
    });
  }

  lineOfBusinessChanged() {
    this.pageDesignService.getPageDesignByLineOfBusiness(this.lineOfBusiness).pipe(first()).subscribe(response => {
      this.model.objModel = response.result;
    });
  }

  customizedChanged(event: any) {
    this.designInd = true;
    if (event.index == 0) {
      this.groupId = null;
      this.lineOfBusiness = "All";
      this.lineOfBusinessChanged();
    }
    else if (event.index == 1) {
      this.model.objModel.bottomLeft = "";
      this.model.objModel.bottomCentral = "";
      this.model.objModel.bottomRight = "";
      this.model.objModel.top = "";
      this.groupId = null;
      this.lineOfBusiness = LineOfBusiness.TRIAGE;
      this.lineOfBusinessChanged();
    }
    else if (event.index == 2) {
      this.model.objModel.bottomLeft = "";
      this.model.objModel.bottomCentral = "";
      this.model.objModel.bottomRight = "";
      this.model.objModel.top = "";
      this.groupId = null;
      this.lineOfBusiness = LineOfBusiness.OCCUPATIONAL_HEALTH;
      this.lineOfBusinessChanged();
    }
    else {
      this.model.objModel.bottomLeft = "";
      this.model.objModel.bottomCentral = "";
      this.model.objModel.bottomRight = "";
      this.model.objModel.top = "";
    }
  }

}
