import { Component, OnInit, Inject } from '@angular/core';
import { FileRequestService, UserSecuredFileService, UserSecuredFileViewModel } from '../../rest';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpResponse, HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { first } from 'rxjs/operators';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Component({
  selector: 'download-request-file',
  templateUrl: './download-request-file.component.html',
  styleUrls: ['./download-request-file.component.css']
})
export class DownLoadRequestFileComponent implements OnInit {

  title: string = "";
  requestId: number = 0;
  files: UserSecuredFileViewModel[] = [];

  constructor(
    private fileRequestService: FileRequestService,
    private userFiles: UserSecuredFileService,
    private dialogRef: MatDialogRef<DownLoadRequestFileComponent>,
    private sharedFunctions: SharedFunctionsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.title = data.title;
    this.requestId = data.requestId;
  }

  ngOnInit() {
    this.fileRequestService.getListOfUploadedFiles(this.requestId).pipe(first()).subscribe(result => {
      this.files = result.result;
    });
  }

  downloadFile(fileId) {
    let sub = this.userFiles.downloadFile(fileId, 'events', true).subscribe(event => {
      if (event.type == HttpEventType.Response) {

        this.sharedFunctions.saveToFileSystem(event);

      } else if (event.type == HttpEventType.DownloadProgress) {

      }
    }, () => { sub.unsubscribe(); }, () => { sub.unsubscribe(); })
  }
}
