import { Pipe, PipeTransform } from '@angular/core';
import { SharedFunctionsService } from '../../services/shared-functions.service';

@Pipe({
  name: 'hideEmail'
})
export class HideEmailPipe implements PipeTransform {

  constructor(
    private sharedFunctionsService: SharedFunctionsService
  ) { }

  public transform(value: string) {
    if (!this.sharedFunctionsService.isValidEmail(value)) {
      return;
    }

    let indexOfLastDot = value.lastIndexOf(".");
    let numberOfStars = value.substring(1, indexOfLastDot).length;

    return value[0] + "*".repeat(numberOfStars) + value.substring(indexOfLastDot, value.length);
  }
}
