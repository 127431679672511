/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';





import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {MedcorResponse,PaginatedListViewModel,DynamicInstanceViewModel,MedfilesAfterCareFileViewModel} from '../index';


@Injectable()
export class MedfilesAfterCareInstructionsService {

    protected basePath = 'https://localhost/ClientPortalAPI';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Download File by RefNbr and LastName
     * 
     * @param id 
     * @param refNbr 
     * @param lastName 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadTriageAfterCareFile(id?: string, refNbr?: string, lastName?: string, language?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadTriageAfterCareFile(id?: string, refNbr?: string, lastName?: string, language?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadTriageAfterCareFile(id?: string, refNbr?: string, lastName?: string, language?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadTriageAfterCareFile(id?: string, refNbr?: string, lastName?: string, language?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (refNbr !== undefined && refNbr !== null) {
            queryParameters = queryParameters.set('refNbr', <any>refNbr);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any>lastName);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/MedfilesAfterCareInstructions/DownloadTriageAfterCareFile`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Download File by encrypted param
     * 
     * @param id 
     * @param encParam 
     * @param language 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadTriageAfterCareFileByEnc(id?: string, encParam?: string, language?: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadTriageAfterCareFileByEnc(id?: string, encParam?: string, language?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadTriageAfterCareFileByEnc(id?: string, encParam?: string, language?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadTriageAfterCareFileByEnc(id?: string, encParam?: string, language?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (encParam !== undefined && encParam !== null) {
            queryParameters = queryParameters.set('encParam', <any>encParam);
        }
        if (language !== undefined && language !== null) {
            queryParameters = queryParameters.set('language', <any>language);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(`${this.basePath}/api/MedfilesAfterCareInstructions/DownloadTriageAfterCareFileByEnc`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Triage After Care Files by RefNbr and LastName
     * 
     * @param refNbr 
     * @param lastName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTriageAfterCareFiles(refNbr?: string, lastName?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<MedfilesAfterCareFileViewModel>>>;
    public getTriageAfterCareFiles(refNbr?: string, lastName?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<MedfilesAfterCareFileViewModel>>>>;
    public getTriageAfterCareFiles(refNbr?: string, lastName?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<MedfilesAfterCareFileViewModel>>>>;
    public getTriageAfterCareFiles(refNbr?: string, lastName?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refNbr !== undefined && refNbr !== null) {
            queryParameters = queryParameters.set('refNbr', <any>refNbr);
        }
        if (lastName !== undefined && lastName !== null) {
            queryParameters = queryParameters.set('lastName', <any>lastName);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<MedfilesAfterCareFileViewModel>>>(`${this.basePath}/api/MedfilesAfterCareInstructions/GetTriageAfterCareFiles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Triage After Care Files By Encrypted Param
     * 
     * @param encParam 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTriageAfterCareFilesByEncryptedParam(encParam?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<MedfilesAfterCareFileViewModel>>>;
    public getTriageAfterCareFilesByEncryptedParam(encParam?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<MedfilesAfterCareFileViewModel>>>>;
    public getTriageAfterCareFilesByEncryptedParam(encParam?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<MedfilesAfterCareFileViewModel>>>>;
    public getTriageAfterCareFilesByEncryptedParam(encParam?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (encParam !== undefined && encParam !== null) {
            queryParameters = queryParameters.set('encParam', <any>encParam);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<MedfilesAfterCareFileViewModel>>>(`${this.basePath}/api/MedfilesAfterCareInstructions/GetTriageAfterCareFilesByEncryptedParam`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generate Triage After Care link by refNbr and replId
     * 
     * @param refNbr 
     * @param replId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTriageAfterCareLink(refNbr?: string, replId?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<string>>;
    public getTriageAfterCareLink(refNbr?: string, replId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<string>>>;
    public getTriageAfterCareLink(refNbr?: string, replId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<string>>>;
    public getTriageAfterCareLink(refNbr?: string, replId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refNbr !== undefined && refNbr !== null) {
            queryParameters = queryParameters.set('refNbr', <any>refNbr);
        }
        if (replId !== undefined && replId !== null) {
            queryParameters = queryParameters.set('replId', <any>replId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<string>>(`${this.basePath}/api/MedfilesAfterCareInstructions/GetTriageAfterCareLink`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
