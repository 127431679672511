<div class="text-center" [ngClass]="{ 'dashboard-item-fullscreen':fullScreenReport, 'dashboard-item-fullscreen-ie': generalVariables.clientBrowserInfo.isIE && fullScreenReport, 'fill-parent':singleTile,'flex-basis-0 flex-grow-1':!singleTile}">
  <div class="position-relative">
    <img class="btn-report-fullscreen" *ngIf="showMaximiz" (click)="fullScreen()" [title]="!fullScreenReport ? 'Maximize' : 'Minimize'" [src]="!fullScreenReport ? 'assets/images/maximize.svg' : 'assets/images/minimize.svg'" />
  </div>
  <div class="position-relative">


    <div class="position-absolute tag-cont">
      <div class="text-center shadow-cont">
        <div class="position-relative" *ngIf="dashboardItem.tag==='Tag 1'">
          <div class="report-tag-h tag1">
            {{dashboardItem.tagTitle}}
            <div class="position-absolute tag1-shadow"></div>
          </div>
        </div>
        <div class="position-relative" *ngIf="dashboardItem.tag==='Tag 2'">
          <div class="report-tag-h tag2">
            {{dashboardItem.tagTitle}}
            <div class="position-absolute tag2-shadow"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <h1 *ngIf="fullScreenReport">{{dashboardItem.name}}</h1>
  <div *ngIf="refreshReportInd" class="text-center position-absolute item-loader">
    <img class="loading" src="assets/images/loadingGear.svg" />
  </div>
  <div class="dashboard-item" [ngClass]="{'full-screen':fullScreenReport,'fill-parent':singleTile, 'single-dashboard-item':!singleTile}">
  </div>
</div>
