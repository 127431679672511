import { Component, OnInit, ViewChild } from '@angular/core';
import { IchsCommandToolbarDelegate, IchsCommandToolbarModel } from '../../../controls/ichs-command-toolbar/ichs-command-toolbar.component';
import { NotificationViewModel, NotificationService, MedcorResponse, VoidInfoViewModel, GroupService, SelectionItemViewModel } from '../../../rest/index';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MedcorAlertService } from '../../../services/medcor-alert.service';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.css']
})
/** NotificationDetail component*/
export class NotificationDetailComponent implements OnInit, IchsCommandToolbarDelegate<NotificationViewModel> {
  @ViewChild('form', { static: true }) ngForm: NgForm;// 1, {static: false }) bind the Form From view 
  model: IchsCommandToolbarModel<NotificationViewModel>;// 2) define Command Toolbar model and use model.objModel to bing the FORM UI and use model as IchsCommandToolbar [model]
  get folderId(): number { return this.model.objModelId }
  groupItems: SelectionItemViewModel[] = [];

  get groupId(): string {
    return this.model.objModel.groupId ? this.model.objModel.groupId.toString() : "";
  }

  set groupId(val: string) {
    if (val) {
      this.model.objModel.groupId = +val;
    }
  }

  /** folder ctor */
  constructor(
    private location: Location,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private alertService: MedcorAlertService,
    private notificationService: NotificationService,
    private groupService: GroupService,
  ) {
    this.model = new IchsCommandToolbarModel<NotificationViewModel>(location, router, "/announcements", "Announcement", this, dialog, alertService); // 3) Create Command Toolbar Model 
    this.model.hasVoidRecover = true;
    this.model.hasSaveAndBack = false;
    this.model.hasSave = true;
  }

  /** Folder OnInit*/
  ngOnInit() {
    this.model.init(this.route); // 4) Initialize The Model 

    this.groupService.getGroupsAsSelectionList().pipe(first()).subscribe(medcorResult => {
      if (!medcorResult.result) return;
      var Items = medcorResult.result;
      this.groupItems.push({ selectionText: "All", selectionValue: "1" }, ...Items);
    });
  }

  // 5) implement IchsCommandToolbarDelegate .
  canDeactivate(): boolean | Observable<boolean> {
    return this.model.confirmCanDeactivate();
  }

  getLoadObjModelObservable(id: number) {
    return this.notificationService.getById(id);
  }

  getSaveObservable(objModel: NotificationViewModel): Observable<MedcorResponse<NotificationViewModel>> {
    return this.notificationService.post(objModel)
  }

  getVoidObservable(objVoidModel: VoidInfoViewModel): Observable<MedcorResponse<NotificationViewModel>> {
    return this.notificationService._void(objVoidModel)
  }

  getRecoverObservable(id: number): Observable<MedcorResponse<NotificationViewModel>> {
    return this.notificationService.recover(id);
  }

  didLoad(objModel: NotificationViewModel) {
    if (objModel.groupId == null) {
      this.model.objModel.groupId = 1;
      this.groupId = "1";
    }
  }
}
