<form #form="ngForm" novalidate class="email-form flex-basis-0 flex-grow-1">
  <ng-container *ngIf="!email">
    <div class="external-email">
      <div class="display-flex flex-items-centered">
        <div class="logo-cont"><img src="assets/images/medcorLogo_white.svg" /></div>
        <div><span class="app-name">Client Portal</span></div>
      </div>
      <div>
        <hr class="client-portal-hr" />
      </div>
      <div class="box-title">
        Open Secure Message
      </div>
      <div class="display-flex break-la">
        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
          <ichs-text-box [(ngModel)]="model.email" name="emailAddress" required label="Receiver Email" [disabled]="true"
            email></ichs-text-box>
        </div>
        <div class="flex-col-sep"></div>
        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
          <ichs-text-box name="pinCode" label="PIN Code" [(ngModel)]="model.pin" required></ichs-text-box>
        </div>
      </div>
      <div class="flex-child display-flex access-btn-cont">
        <button [class.disabled-btn]="email != undefined" [disabled]="email != undefined" class="cp-btn cp-mail-btn access-btn"
          (click)="viewExternalEmail()">Access Email</button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="email">
    <div class="email-box">
      <ichs-collapsable-panel panelTitle="Open Secure Messaging">
        <div class="collapsableBody">
          <div class="display-flex break-la">
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              <ichs-text-box [(ngModel)]="model.email" name="emailAddress" required label="Receiver Email"
                [disabled]="true" email></ichs-text-box>
            </div>
            <div class="flex-col-sep"></div>
            <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">

            </div>
          </div>
        </div>
      </ichs-collapsable-panel>
      <ng-container *ngIf="email.inviteForPermanentAccount">
        <div class="cont-padding">
          <p class="alert alert-warning center-text">
            <a class="create-account" (click)="navigateToCreateAccountPage()">Click here to create a Client Portal
              account for secure messaging</a>
          </p>
        </div>

        <hr class="main-hr" />
      </ng-container>

      <div class="cont-padding email-container">
        <div class="display-flex flex-items-centered">
          <div class="flex-basis-0 flex-grow-1">
            <div>
              <span class="grey-color">
                {{email.sentReceivedDate | date}}
              </span>
            </div>
            <div class="email-cont-title"> {{email.title}}</div>
            <div>
              <span class="grey-color">
                From:
              </span>
              <span class="link-color">
                {{email.sender.name}}
              </span>
            </div>
          </div>
        </div>
      </div>

      <hr class="main-hr" />

      <div class="cont-padding">
        <div class="attachments-area" *ngIf="email.attachments && email.attachments.length>0">
          <span style="vertical-align:middle">Attachments:</span>
          <button class="cp-btn cp-mail-btn" (click)="downloadFile(file.id)"
            *ngFor="let file of email.attachments">{{file.fileName}}
          </button>
          <mat-progress-spinner class="upload-spinner flo" *ngIf="downloadProgress > 0" [value]="downloadProgress"
            mode="determinate" diameter="30"></mat-progress-spinner>
        </div>
        <hr class="main-hr" style="margin-top:8px;" />
        <div [innerHTML]="email.body" class="margin-top-def"></div>
      </div>
    </div>
  </ng-container>

</form>
<div class="background-cont">
  <img class="login-back-img" ngSrc="assets/images/loginBack.jpg" fill priority />
</div>
