<div class="cont-padding">
  <div class="display-flex flex-items-centered">
    <div class="flex-basis-0 flex-grow-1">
      <div class="display-flex flex-items-centered margin-left-def">
        <ichs-text-box name="search" [(ngModel)]="searchText" placeholder="Search" class="flex-grow-1 flex-basis-0"></ichs-text-box>
        <a (click)="clearFilter()" class="cp-btn cp-grid-head-btn  margin-left-def" style="align-self:flex-end;">
          Clear
        </a>
      </div>
    </div>
    <div style="flex-basis:100px;"></div>
    <div>
      <div *ngIf="lineOfBusinessList.length > 1" class="margin-top-def">
        <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [value]="selectedLineOfBusiness">
          <mat-button-toggle *ngFor="let userLine of lineOfBusinessList" [value]="userLine" (change)="changeLineOfBusiness(userLine)">
            <ng-container
              *ngIf="(userLine == 'Occupational Health' && lineOfBusinessList.indexOf('Advanced Practice') == -1) || (userLine == 'Advanced Practice' && lineOfBusinessList.indexOf('Occupational Health') == -1); else elseBlock">
              {{oshaRecordKeepingInstallationAccessOnly ? 'OSHA' : 'Onsite Care'}}
            </ng-container>
            <ng-template #elseBlock>
              {{
                 userLine == 'Screening' ? 'Health Security' : userLine.replace('Advanced Practice', 'Advanced Care')
                                                                       .replace('Triage', 'Telehealth')
                                                                       .replace('Occupational Health', 'Essential Care')
              }}
            </ng-template>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  </div>

  <div class="position-relative">
    <div class="position-absolute last-date-info" *ngIf="lastDataRefreshInfo">
      {{lastDataRefreshInfo}}
    </div>
  </div>

  <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedIndexChange)="onReportsTabChange()" mat-align-tabs="start"  mat-stretch-tabs="false">
    <mat-tab label="Favorite Reports">
      <div class="text-center">
        <button class="cp-btn cp-icon-btn" title="Tiles View" (click)="changeView()">
          <img [src]="defaultReportView ? 'assets/images/newIcons/largeReportActive.svg' : 'assets/images/newIcons/largeReport.svg'" />
        </button>
        <button class="cp-btn cp-icon-btn" title="List View" (click)="changeView()">
          <img [src]="defaultReportView ? 'assets/images/newIcons/linebyline.svg' : 'assets/images/newIcons/linebylineActive.svg'" />
        </button>
      </div>
      <div class="margin-bottom-def">
        <div class="flex-wrap reports-cont" [class.display-flex]="defaultReportView">
          <div *ngFor="let report of filteredFavoriteReportList" class="flex-basis-0 flex-grow-1" [class.report]="defaultReportView" [title]="report.description?report.description:report.name">
            <report-item-card [reportViewModel]="report" class="report-item" [defaultView]="defaultReportView"></report-item-card>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="All Reports">
      <div class="text-center">
        <button class="cp-btn cp-icon-btn" title="Tiles View" (click)="changeView()">
          <img [src]="defaultReportView ? 'assets/images/newIcons/largeReportActive.svg' : 'assets/images/newIcons/largeReport.svg'" />
        </button>
        <button class="cp-btn cp-icon-btn" title="List View" (click)="changeView()">
          <img [src]="defaultReportView ? 'assets/images/newIcons/linebyline.svg' : 'assets/images/newIcons/linebylineActive.svg'" />
        </button>
      </div>
      <div class="margin-bottom-def">
        <div class="flex-wrap reports-cont" [class.display-flex]="defaultReportView">
          <div *ngFor="let report of filteredReportList" class="flex-basis-0 flex-grow-1" [class.report]="defaultReportView" [title]="report.description?report.description:report.name">
            <report-item-card [reportViewModel]="report" class="report-item" [defaultView]="defaultReportView"></report-item-card>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
