import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { AppUserService } from '../../rest';
import { NgForm, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';
import { ValidationMessages } from '../../app.general.constants';
import { first } from 'rxjs/operators';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
/** ResetPassword component*/
export class ResetPasswordComponent {
  @Input('userId') userId: number;
  @Output('passwordChanged') passwordChangedEvent: EventEmitter<string> = new EventEmitter<string>();
  newPassword: string;
  confirmNewPassword: string;
  @ViewChild('form') ngForm: NgForm;

  /** ResetPassword constructor*/
  constructor(
    private appUserService: AppUserService,
    private alertService: MedcorAlertService,
  ) { }

  resetPassword() {
    this.validateAllFormFields(this.ngForm.form);
    if (this.ngForm.form.valid) {
      this.appUserService.resetPassword({ userId: this.userId, newPassword: this.newPassword })
        .pipe(first())
        .subscribe(resp => {
          this.passwordChangedEvent.emit(resp.result);
        });
    }
    else {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
