import { Component, OnInit, ViewChild } from '@angular/core';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';
import { first } from 'rxjs/operators';
import { NgForm } from '@angular/forms';
import { AppSettings, ValidationMessages } from '../../app.general.constants';
import { TeleHealthService } from '../../rest/api/teleHealth.service';
import { AccountsService } from '../../rest/api/accounts.service';
import { ApplicationAccessGrantingViewModel } from 'src/app/rest';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';

@Component({
  selector: 'grant-app-access',
  templateUrl: './grant-app-access.component.html',
  styleUrls: ['./grant-app-access.component.scss']
})
export class GrantAppAccessComponent implements OnInit {

  @ViewChild("loginForm") loginForm: NgForm;
  loggedIn: boolean = false;
  email: string;
  password: string;
  appName: string;
  encryption: string;
  myMedcorAppId: string;

  constructor(
    private alertService: MedcorAlertService,
    private teleHealthService: TeleHealthService,
    private accountsService: AccountsService,
    private sharedFunctions: SharedFunctionsService,
  ) {
  }

  ngOnInit() {
    let url = location.href.replace(AppSettings.UI_ORIGIN_PATH, "");
    let pathParts: string[] = url.split("/");
    if (pathParts.length != 3) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_LINK],
      });
      return;
    }

    this.myMedcorAppId = location.search.replace("?myMedcorAppId=", "");
    this.encryption = pathParts[2].replace(location.search, "");

    if (!this.encryption || !this.myMedcorAppId) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_LINK],
      });
      return;
    }

    this.teleHealthService.initializeAppGrantingRequest(this.encryption)
      .pipe(first())
      .subscribe(resp => {
        this.appName = resp.result;
      });
  }

  login() {
    if (!this.encryption || !this.myMedcorAppId) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_LINK],
      });
      return;
    }

    if (this.loginForm.invalid) {   // check if form is valid 
      this.sharedFunctions.validateAllFormFields(this.loginForm.form);
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    let model: ApplicationAccessGrantingViewModel = {
      email: this.email,
      password: this.password,
      encryption: this.encryption,
      myMedcorAppId: this.myMedcorAppId,
    };

    this.accountsService.grantAccessToApplication(model)
      .pipe(first())
      .subscribe(() => {
        this.loggedIn = true;
      });
  }
}
