import { Component, Inject, ViewChild } from '@angular/core';
import { EXTRA_DATA } from '../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { TriagePortalUserService } from '../../rest';
import { first } from 'rxjs/operators';
import { NgForm, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';
import { ValidationMessages } from '../../app.general.constants';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'show-triage-user-pin-code',
  templateUrl: './show-triage-user-pin-code.component.html',
  styleUrls: ['./show-triage-user-pin-code.component.css']
})
export class ShowTriageUserPinCodeComponent {

  @ViewChild("form") ngForm: NgForm;

  firstInitial: string;
  lastInitial: string;
  pinCode: string = null;

  constructor(
    private triagePortalUserService: TriagePortalUserService,
    private alertService: MedcorAlertService,
    private clipboardService: ClipboardService,
    @Inject(EXTRA_DATA) private id: number,
  ) { }

  showPinCode() {
    if (this.ngForm.form.invalid) {
      this.validateAllFormFields(this.ngForm.form);
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    this.triagePortalUserService.showUserPinCode(this.id, this.firstInitial, this.lastInitial)
      .pipe(first()).subscribe(resp => {
        this.pinCode = resp.result;
      });
  }

  copyPinCode() {
    let succeeded: boolean = this.clipboardService.copyFromContent(this.pinCode);
    if (succeeded) {
      this.alertService.addAlert({
        type: AlertMessageType.success,
        title: 'Coppied!',
        dismiss: AlertDismissType.auto,
        messages: ["PIN Code coppied to clipboard"]
      });
    }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
