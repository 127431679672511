/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface DashboardItemViewModel { 
    name: string;
    description?: string;
    lineOfBusiness: string;
    imageId?: number;
    groupId: string;
    reportItemId: string;
    isSelected?: boolean;
    internalInd?: boolean;
    tag?: string;
    tagTitle?: string;
    hasPhiData?: boolean;
    hasExport?: boolean;
    id?: number;
    voidId?: number;
    version?: string;
}
