import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SelectionItemViewModel, LineOfBusinessPropertyService, SelectionListService, LineOfBusinessPropertyViewModel, DataRestrictionViewModel } from '../../rest/index';
import { NgForm } from '@angular/forms';
import { AppGenericLists } from '../../app.general.lists';
import { first } from 'rxjs';

@Component({
  selector: 'restricted-access-tree',
  templateUrl: './restricted-access-tree.component.html',
  styleUrls: ['./restricted-access-tree.component.css']
})
export class RestrictedAccessTreeComponent implements OnInit {

  @ViewChild('form') ngForm: NgForm;
  lineOfBusiness: SelectionItemViewModel[] = AppGenericLists.LineOfBusiness;
  propertyList: any = {}; // holds lists of selection list items extracted from propertyListObjects
  propertyListObjects: any = {}; /// holds arrays of LineOfBusinessProperties
  propertyListValue: any = {}; /// holds selection lists for property values lists

  _accessRestriction: DataRestrictionViewModel[];
  @Input("accessRestriction")
  set accessRestriction(value: DataRestrictionViewModel[]) {
    this._accessRestriction = value;
    this.updateTree();
  }
  selectedValues: AccessData[] = [{}];


  ngOnInit(): void {

  }

  updateTree() {

    if (this._accessRestriction && this._accessRestriction.length > 0) {
      this.selectedValues = this._accessRestriction.map(obj => <AccessData>{
        lineOfBusiness: obj.lineOfBusiness,
        property: obj.propertyId + "",
        propertyValue: obj.filter,
        propertyValueListId: obj.selectionListId + ""
      });

      this.selectedValues.forEach(obj => {
        //load the properties for each selected line of business and fill the selection list and object list with values
        if (this.propertyList[obj.lineOfBusiness] == undefined) {
          this.propertyList[obj.lineOfBusiness] = [];
          this.propertyListObjects[obj.lineOfBusiness] = [];
          this.dataAccessService.getLineOfBusinessProperties(obj.lineOfBusiness).pipe(first()).subscribe(result => {

            this.propertyList[obj.lineOfBusiness].push(...result.result!.map(obj => {
              return <SelectionItemViewModel>{
                selectionText: obj.propertyName, selectionValue: obj.id + ""
              }
            }));
            this.propertyListObjects[obj.lineOfBusiness].push(...result.result);
            this.selectedPropertyChanged(undefined, obj);
          });
        }
        //load the selection lists for the selected Properties
        if (this.propertyListValue[obj.propertyValueListId] == undefined) {
          this.propertyListValue[obj.propertyValueListId] = [];
          this.selectionListService.getSelectionItems(+obj.propertyValueListId).pipe(first()).subscribe(result => {
            this.propertyListValue[obj.propertyValueListId] = result.result[0].items;

          });
        }


      });
    }
  }

  constructor(private dataAccessService: LineOfBusinessPropertyService,
    private selectionListService: SelectionListService) {

  }

  addNew() {
    this.selectedValues.push({});
  }

  deleteNode(node: AccessData) {
    this.selectedValues.splice(this.selectedValues.indexOf(node), 1);
    if (this.selectedValues.length == 0) {
      this.selectedValues.push({});
    }
  }

  selectedLOBChanged(event, node: AccessData) {
    if (!node.lineOfBusiness) {
      node.property = undefined;
      node.propertyValue = undefined;
      return;
    }
    if (this.propertyList[node.lineOfBusiness] == undefined) {
      this.propertyList[node.lineOfBusiness] = [];
      this.propertyListObjects[node.lineOfBusiness] = {};
      this.dataAccessService.getLineOfBusinessProperties(node.lineOfBusiness).pipe(first()).subscribe(result => {

        this.propertyList[node.lineOfBusiness] = result.result!.map(obj => {
          return <SelectionItemViewModel>{
            selectionText: obj.propertyName, selectionValue: obj.id + ""
          }
        });
        this.propertyListObjects[node.lineOfBusiness] = result.result;
      });
    }
  }

  selectedPropertyChanged(event, node: AccessData) {
    var list = <LineOfBusinessPropertyViewModel[]>this.propertyListObjects[node.lineOfBusiness];
    node.lineOfBusinessPropery = list.filter(obj => obj.id + "" == node.property)[0];
    node.propertyValueListId = node.lineOfBusinessPropery.selectionListId + "";
    if (!node.property) {

      node.propertyValue = undefined;
      return;
    }

    if (this.propertyListValue[node.lineOfBusinessPropery.selectionListId + ""] == undefined) {
      this.propertyListValue[node.lineOfBusinessPropery.selectionListId + ""] = [];
      this.selectionListService.getSelectionItems(node.lineOfBusinessPropery.selectionListId).pipe(first()).subscribe(result => {
        this.propertyListValue[node.lineOfBusinessPropery.selectionListId + ""] = result.result[0].items;

      });
    }
  }

  getSelectedItems(): DataRestrictionViewModel[] {

    this.selectedValues = this.selectedValues.filter(obj => obj.lineOfBusiness != undefined);

    this.selectedValues.forEach(obj => {
      if (obj.lineOfBusinessPropery == undefined) {
        var list = <LineOfBusinessPropertyViewModel[]>this.propertyListObjects[obj.lineOfBusiness];
        obj.lineOfBusinessPropery = list.filter(objFilter => objFilter.id + "" == obj.property)[0];
      }
    });

    return this.selectedValues.map(obj => <DataRestrictionViewModel>{
      filter: obj.propertyValue,
      propertyName: obj.lineOfBusinessPropery.propertyValue,
      propertyId: obj.lineOfBusinessPropery.id,
      lineOfBusiness: obj.lineOfBusinessPropery.lineOfBusiness,
      operator: "<>",
      selectionListId: +obj.propertyValueListId
    });
  }


}


export class AccessData {
  lineOfBusiness?: string;
  property?: string;
  propertyValue?: string;
  propertyValueListId?: string;
  lineOfBusinessPropery?: LineOfBusinessPropertyViewModel;
}



