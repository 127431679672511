<hr class="main-hr" />

<div class="display-flex app-footer">
  <div [class.flex-grow-1]="!termsOfService" [ngClass]="{'copy-rights' : !termsOfService && !generalVariables.clientDeviceInfo.isDesktop}">
    &copy;2019 Medcor, Inc. All Rights Reserved
  </div>
  <div class="flex-basis-0 flex-grow-1 terms-of-service" *ngIf="termsOfService">
    <a href="https://medcor.com/legal-notices/" target="_blank">Terms of Service</a>
  </div>
  <div class="powered-by-medcor" *ngIf="generalVariables.clientDeviceInfo.isDesktop">
    <div class="logo-cont-footer">
      <img src="assets/images/poweredByMedcorLogo.svg" />
    </div>
    Powered by Medcor Technology
  </div>
</div>
