<div class="shadow-cont cont-padding list-view">
    <div *ngIf="reportViewModel">
        <div class="display-flex flex-items-centered">
            <div class="flex-basis-0 flex-grow-1">
                <div class="position-relative">


                    <div class="position-absolute" style="top:-13px; left:-16px;">
                        <div class="text-center shadow-cont">
                            <div class="position-relative" *ngIf="reportViewModel.tag==='Tag 1'">
                                <div class="report-tag-h tag1">
                                    {{reportViewModel.tagTitle}}
                                    <div class="position-absolute tag1-shadow"></div>
                                </div>
                            </div>
                            <div class="position-relative" *ngIf="reportViewModel.tag==='Tag 2'">
                                <div class="report-tag-h tag2">
                                    {{reportViewModel.tagTitle}}
                                    <div class="position-absolute tag2-shadow"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="display-flex flex-items-centered report-hight">
                    <h2>{{reportViewModel.name}}</h2>
                    <div class="flex-basis-0 flex-grow-1"></div>
                    <div>
                        <button mat-button class="report-action" (click)="showSnapshot()">View Snapshot</button>
                        <button mat-button class="report-action" (click)="scheduleReport()">Select</button>
                    </div>
                </div>

            </div>

        </div>
    </div>
</div>