import { Component } from '@angular/core';
import { IchsControlType, IchsGridComponentConfig, IchsGridActionsConfig } from '../../controls/ichs-grid/grid-configs';
import { SearchViewModel, TriagePortalUserPinCodeSearchModel, TriagePortalUserService } from '../../rest';
import { AppGenericLists } from '../../app.general.lists';
import { IchsComponentDialogComponent } from '../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { ShowTriageUserPinCodeComponent } from '../show-triage-user-pin-code/show-triage-user-pin-code.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'triage-portal-user-pin-code-list',
  templateUrl: './triage-portal-user-pin-code-list.component.html',
  styleUrls: ['./triage-portal-user-pin-code-list.component.css']
})
export class TriagePortalUserPinCodeListComponent {

  gridConfig: IchsGridComponentConfig<TriagePortalUserPinCodeSearchModel>;

  /** AppuserList constructor*/
  constructor(
    private triagePortalUserService: TriagePortalUserService,
    private dialog: MatDialog,
  ) {
    this.gridConfig = this.defineGrid();
  }

  private defineGrid(): IchsGridComponentConfig<TriagePortalUserPinCodeSearchModel> {
    let configs: IchsGridComponentConfig<TriagePortalUserPinCodeSearchModel> = {
      primaryId: "id",
      defaultOrder: "expirationDate",
      defaultOrderType: "desc",
      title: null,
      entityController: null,
      entityDataSource: (filter: SearchViewModel) => this.triagePortalUserService.searchPinCodes(filter),
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: true,
      hasVoid: false,
      detailAction: new IchsGridActionsConfig({
        title: "Show User PIN Code",
        function: (obj: TriagePortalUserPinCodeSearchModel) => {
          this.dialog.open(IchsComponentDialogComponent, {
            data: {
              component: ShowTriageUserPinCodeComponent,
              title: "Show User PIN Code",
              hideCloseButton: true,
              extraData: obj.id,
            },
            width: "500px"
          });
        },
      }),
      headers: [
        {
          headerTitle: "User Email",
          propertyName: "userEmail",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "First Name",
          propertyName: "firstName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Status",
          propertyName: "status",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.TriagePortalUserPinCodeStatus,
        },
        {
          headerTitle: "Date Generated",
          propertyName: "dateGenerated",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
          dateFormat: "MM/dd/yyyy",
        },
        {
          headerTitle: "Expiration Date",
          propertyName: "expirationDate",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
          dateFormat: "MM/dd/yyyy",
        },
      ],
    }
    return configs;
  }
}
