import { Component, OnInit } from '@angular/core';
import { AdditionalAction, IchsControlType, IchsGridActionsConfig, IchsGridComponentConfig } from 'src/app/controls/ichs-grid/grid-configs';
import { SearchViewModel } from 'src/app/rest';
import { TriageSecuredFileViewModel } from '../../rest/model/triageSecuredFileViewModel';
import { HttpEventType } from '@angular/common/http';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { TriageSecuredFileService } from 'src/app/rest';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs';
import { formatDate } from '@angular/common';

@Component({
  selector: 'triage-call-report-list',
  templateUrl: './triage-call-report-list.component.html',
  styleUrls: ['./triage-call-report-list.component.css']
})
export class TriageCallReportListComponent implements OnInit {

  dataBackThrough: Date;
  gridConfig: IchsGridComponentConfig<TriageSecuredFileViewModel>;

  constructor(
    private triageSecuredFileService: TriageSecuredFileService,
    private sharedFunctions: SharedFunctionsService,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.dataBackThrough = new Date();
    this.dataBackThrough.setFullYear(this.dataBackThrough.getFullYear() - 1);

    // this is used when we redirect to this page from triage-secured-file page
    let loadOnInit = this.activatedRoute.snapshot.queryParams['loadOnInit'] as boolean;
    this.gridConfig = this.defineGridConfig(loadOnInit);
  }

  private defineGridConfig(_loadOnInit: boolean): IchsGridComponentConfig<TriageSecuredFileViewModel> {
    let configs: IchsGridComponentConfig<TriageSecuredFileViewModel> = {
      title: "Triage Call Reports",
      primaryId: "refNbr",
      defaultOrder: "callDate",
      defaultOrderType: "desc",
      entityDataSource: (filter: SearchViewModel) => this.triageSecuredFileService.searchReports(filter),
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      loadOnInit: _loadOnInit,
      disableDataLoadingOnReset: true,
      secondAction: new IchsGridActionsConfig({
        title: "View Report",
        icon: "assets/images/details.svg",
        hasMatMenu: true,
        matMenuTitle: "Click on date below to View",
        matMenuItems: [],
        centralizeMatMenuLoader: true,
        function: (file: TriageSecuredFileViewModel) => this.getAllFileVersions(file, true),
      }),
      thirdAction: new IchsGridActionsConfig({
        title: "Download Report",
        icon: "assets/images/download.svg",
        hasMatMenu: true,
        matMenuTitle: "Click on date below to Download",
        matMenuItems: [],
        centralizeMatMenuLoader: true,
        function: (file: TriageSecuredFileViewModel) => this.getAllFileVersions(file, false),
      }),
      headers: [
        {
          headerTitle: "Call Date",
          propertyName: "callDate",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
          convertUtcDateToLocal: false,
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Date of Birth",
          propertyName: "dateOfBirth",
          searchable: true,
          sortable: true,
          nullable: true,
          controlType: IchsControlType.Date,
          convertUtcDateToLocal: false,
        },
        {
          headerTitle: "Reference Number",
          propertyName: "refNbr",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }

    // set doubleClickAction to 'View Report' Action
    configs.doubleClickAction = configs.secondAction;
    return configs;
  }

  private getAllFileVersions(file: TriageSecuredFileViewModel, isView: boolean) {
    this.triageSecuredFileService.getTriageCallReportVersions(file.refNbr, file.payorId)
      .pipe(first())
      .subscribe(resp => {
        let gridAction = (isView ? this.gridConfig.secondAction : this.gridConfig.thirdAction);
        gridAction.matMenuItems = resp.result.map(obj => <AdditionalAction>{
          name: formatDate(new Date(obj.creationTime).toISOString().replace('T', ' ').replace('Z', ''), 'MM/dd/yyyy h:mm a', 'en-US') + ' (CT)',
          function: () => this.viewOrDownloadFile(file, obj.path, isView),
          class: "ichs-grid-mat-menu-btn",
        });
      });
  }

  private viewOrDownloadFile(file: TriageSecuredFileViewModel, filePath: string, isView: boolean) {
    let sub = this.triageSecuredFileService.downloadTriageCallReport(file.refNbr, file.payorId, filePath, 'response', true)
      .subscribe({
        next: (event) => {
          if (event.type == HttpEventType.Response) {
            if (isView) {
              var fileURL = URL.createObjectURL(event.body);
              window.open(fileURL);
            }
            else {
              this.sharedFunctions.saveToFileSystem(event);
            }
          }
        },
        error: () => { sub.unsubscribe() },
        complete: () => { sub.unsubscribe() }
      });
  }
}
