import { Component, OnInit, ViewChild } from '@angular/core';
import { IchsCommandToolbarModel, IchsCommandToolbarDelegate } from '../../controls/ichs-command-toolbar/ichs-command-toolbar.component';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';
import { GroupViewModel, GroupReportItemAssociationViewModel, GroupService, GroupReportItemAssociationService, MedcorResponse, VoidInfoViewModel, SearchViewModel, ReportItemViewModel, GroupAppUserAssociationService, GroupAppUserAssociationViewModel, SelectionItemViewModel, UserSearchViewModel, AppUserService, DashboardItemViewModel, GroupDashboardItemAssociationService, GroupDashboardItemAssociationViewModel } from '../../rest/index';
import { Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { LineOfBusinessTreeComponent } from '../../appuser/line-of-business-tree/line-of-business-tree.component';
import { IchsDialogComponent } from '../../controls/ichs-dialog/ichs-dialog.component';
import { IchsGridComponentConfig, IchsControlType } from '../../controls/ichs-grid/grid-configs';
import { MedcorUserInfoService } from 'src/app/services/medcor-user-info.service';
import { ValidationMessages } from '../../app.general.constants';
import { SharedFunctionsService } from '../../services/shared-functions.service';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  host: { class: "fill-parent" },
  styleUrls: ['./group.component.css']
})

/** group component
Example how to use IchsCommandToolbar
*/
export class GroupComponent implements OnInit, IchsCommandToolbarDelegate<GroupViewModel> {
  @ViewChild('form', { static: true }) ngForm: NgForm;// 1) bind the Form From view 
  model: IchsCommandToolbarModel<GroupViewModel>;// 2) define Command Toolbar model and use model.objModel to bing the FORM UI and use model as IchsCommandToolbar [model]
  gridConfigReportItem: IchsGridComponentConfig<ReportItemViewModel>;
  gridConfigDashboardItem: IchsGridComponentConfig<DashboardItemViewModel>;
  gridConfigAllReportItem: IchsGridComponentConfig<ReportItemViewModel>;
  gridConfigAppUser: IchsGridComponentConfig<UserSearchViewModel>;
  groupItems: SelectionItemViewModel[];
  @ViewChild(LineOfBusinessTreeComponent) child: LineOfBusinessTreeComponent;
  //@ViewChild(RestrictedAccessTreeComponent, {static: false }) dataAccessComponent: RestrictedAccessTreeComponent;
  fullAccessGroups: number[] = [
    118000000000051, 118000000000052, 118000000000053, 118000000000054, 118000000000055,
    118000000000041, 118000000000042, 118000000000043, 118000000000044, 118000000000045, 118000000000046
  ];
  adminRoleInd: boolean = false;
  userHasBaseGroupCheckboxAccess: boolean = false;

  parentGroupChanged: boolean = false;

  private originalLineOfBusinessListJson: string;
  private dataAccessUpdates: boolean = false;

  get groupId(): number { return this.model.objModelId }

  get parentGroupId(): string {
    if (this.model.objModel.parentGroupId)
      return this.model.objModel.parentGroupId.toString();
    return null;
  }
  set parentGroupId(value: string) {
    if (value)
      this.model.objModel.parentGroupId = +value;
    else
      this.model.objModel.parentGroupId = null;
  }

  /** group ctor */
  constructor(
    private location: Location,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private alertService: MedcorAlertService,
    private groupService: GroupService,
    private groupReportItemService: GroupReportItemAssociationService,
    private groupDashboardItemService: GroupDashboardItemAssociationService,
    private groupAppUserService: GroupAppUserAssociationService,
    private appUserService: AppUserService,
    private medcorUserInfoService: MedcorUserInfoService,
    private sharedFunctions: SharedFunctionsService,
  ) {
    this.medcorUserInfoService.loggedInUserObs.pipe(first()).subscribe(loggedInUser => {
      this.adminRoleInd = loggedInUser.roleName == "Admin";
      this.userHasBaseGroupCheckboxAccess = loggedInUser.hasBaseGroupCheckboxAccess;
    });

    this.model = new IchsCommandToolbarModel<GroupViewModel>(location, router, "/app-group", "Group", this, dialog, alertService); // 3) Create Command Toolbar Model 
    this.gridConfigReportItem = this.defineReportItemGrid();
    this.gridConfigDashboardItem = this.defineDashboardItemGrid();
    this.gridConfigAllReportItem = this.defineAllReportItemGrid();
    this.gridConfigAppUser = this.defineAppUserGrid();
    this.model.hasSave = this.adminRoleInd;
    this.model.hasVoidRecover = this.adminRoleInd;
  }

  /** Group OnInit*/
  ngOnInit() {
    this.model.init(this.route); // 4) Initialize The Model
    if (this.model.objModelId == -1) {
      this.groupService.getGroupsAsSelectionList().pipe(first()).subscribe(medcorResponse => {
        if (!medcorResponse.result) return;
        this.groupItems = medcorResponse.result;
      });
    } else {

      this.groupService.getParentGroupsSelectionList(this.model.objModelId).pipe(first()).subscribe(medcorResponse => {
        if (!medcorResponse.result) return;
        this.groupItems = medcorResponse.result;
      });

      this.appUserService.getRolesAsSelectionList().pipe(first()).subscribe(response => {
        this.gridConfigAppUser.headers[3].listData = response.result!;
      });
    }
  }

  // 5) implement IchsCommandToolbarDelegate .
  canDeactivate(): boolean | Observable<boolean> {
    // check if the user detail or LineOfBusiness component is dirty.
    if (this.ngForm.dirty || this.originalLineOfBusinessListJson !== JSON.stringify(this.model.objModel.lineOfBusinessList)) {
      let dialogRef: MatDialogRef<IchsDialogComponent, boolean> = this.dialog.open<IchsDialogComponent, any>(IchsDialogComponent, {
        width: '450px',
        data: { title: "Discard Changes ", message: "You will lose all changes in this page." }
      });
      return dialogRef.afterClosed().pipe(first(), map(result => {
        if (result && result == true) {
          return result;
        } else {
          return false;
        }
      }));
    }
    return true;
  }

  didLoad(objModel: GroupViewModel) {
    // reset flag
    this.parentGroupChanged = false;

    // store new values as original values
    this.originalLineOfBusinessListJson = JSON.stringify(this.model.objModel.lineOfBusinessList);
  }

  beforeSave(objModel: GroupViewModel) {
    this.dataAccessUpdates = this.parentGroupChanged || this.originalLineOfBusinessListJson !== JSON.stringify(this.model.objModel.lineOfBusinessList);
  }

  //before save function
  canSave(objModel: GroupViewModel): boolean {
    if (this.model.objModelId == -1)
      return true;

    //if (!this.dataAccessComponent.ngForm.valid) {
    //  this.alertService.addAlert({
    //    type: AlertMessageType.warning,
    //    title: 'Warning!',
    //    dismiss: AlertDismissType.controlled,
    //    messages: ["Invalid Data Access Rules!"]
    //  });
    //}

    //this.model.objModel.lineOfBusinessDataAccess = this.dataAccessComponent.getSelectedItems();

    if (objModel.isStandard || (objModel.lineOfBusinessList!.length == 1 && objModel.lineOfBusinessList![0].subNodes!.length == 0)) {
      return true;
    }

    var validComponent: boolean = this.sharedFunctions.validateLineOfBusinessFields(this.child);

    if (!validComponent) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
    }

    return validComponent;
  }

  didSave() {
    this.gridConfigAllReportItem = this.defineAllReportItemGrid();
    if (this.dataAccessUpdates) {
      this.sharedFunctions.showDashboardDataAccessChangesReminder();
    }
  }

  getLoadObjModelObservable(id: number) {
    return this.groupService.getGroupWithLineOfBusiness(id);
  }
  getSaveObservable(objModel: GroupViewModel): Observable<MedcorResponse<GroupViewModel>> {
    return this.groupService.post(objModel)
  }
  getVoidObservable(objVoidModel: VoidInfoViewModel): Observable<MedcorResponse<GroupViewModel>> {
    return this.groupService._void(objVoidModel)
  }
  getRecoverObservable(id: number): Observable<MedcorResponse<GroupViewModel>> {
    return this.groupService.recover(id);
  }

  /**
   * Configure ReportItems Grid
   */
  private defineAllReportItemGrid(): IchsGridComponentConfig<ReportItemViewModel> {
    let configs: IchsGridComponentConfig<ReportItemViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "View Report Access",
      entityDataSource: (filter: SearchViewModel) => this.groupReportItemService.searchAllReportItemsByGroupId(this.model.objModelId, filter),
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: true,
      headers: [
        {
          headerTitle: "Report Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ],
    }
    return configs;
  }
  /**
   * Configure AllReportItems Grid
   */
  private defineReportItemGrid(): IchsGridComponentConfig<ReportItemViewModel> {
    let configs: IchsGridComponentConfig<ReportItemViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Reports Items",
      entityDataSource: (filter: SearchViewModel) => this.groupReportItemService.searchReportItemsByGroupId(this.groupId, false, filter),
      addExistingConfig: {
        buttonText: "Add Existing",
        gridConfig: this.addExistingReportItemGridConfig(),
        addExistingService: (Ids: string[]) => {
          let close = new Subject<MedcorResponse<boolean>>();
          let arrGroupReportItemAssociations = Ids.map<GroupReportItemAssociationViewModel>((item) => {
            return { groupId: this.groupId, reportItemId: +item }
          });
          let subs = this.groupReportItemService.postList(true, arrGroupReportItemAssociations).subscribe(result => {
            subs.unsubscribe();
            this.gridConfigAllReportItem.refreshGrid!(this.gridConfigAllReportItem.getPage!());
            if (result) {
              close.next({ result: true, statusCode: 200 });
            } else {
              close.next({ result: false });
            }
          });
          return close.asObservable();
        },
        title: "Add Report Items",
      },
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: true,
      hasActionColumn: true,
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: ReportItemViewModel) => {
          if (!deletedObj.id) return;
          let subs = this.groupReportItemService.deleteAssociation(deletedObj.id, this.groupId, true).subscribe(result => {
            subs.unsubscribe();
            if (result) {
              this.gridConfigReportItem.refreshGrid!(this.gridConfigReportItem.getPage!());
              this.gridConfigAllReportItem.refreshGrid!(this.gridConfigAllReportItem.getPage!());
            }
          });
        }
      },
      rowColor: (obj: ReportItemViewModel) => obj.voidId != 0 ? 'Pink' : null,
      headers: [
        {
          headerTitle: "Report Item Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Line Of Business",
          propertyName: "lineOfBusiness",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.DynamicSelect,
          listId: 108000000000007
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ],
    }
    return configs;
  }
  /**
   * Configure Add Existing ReportItem
   */
  private addExistingReportItemGridConfig(): IchsGridComponentConfig<ReportItemViewModel> {
    let addExistingGridConfig: IchsGridComponentConfig<ReportItemViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Add Report Items to Group",
      entityDataSource: (filter: SearchViewModel) => this.groupReportItemService.searchReportItemsByGroupId(this.groupId, true, filter),
      multiSelectConfig: {
      },
      hasVoid: false,
      rowColor: (obj: ReportItemViewModel) => obj.voidId != 0 ? 'Pink' : null,
      headers: [
        {
          headerTitle: "Report Item Name",
          propertyName: "name",
          searchable: true,
          sortable: true,

          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Line Of Business",
          propertyName: "lineOfBusiness",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.DynamicSelect,
          listId: 108000000000007
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }

      ],
    };
    return addExistingGridConfig;
  }
  private defineDashboardItemGrid(): IchsGridComponentConfig<DashboardItemViewModel> {
    let configs: IchsGridComponentConfig<DashboardItemViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Dashboard Items",
      entityDataSource: (filter: SearchViewModel) => this.groupDashboardItemService.searchDashboardItemsByGroupId(this.groupId, false, filter),
      addExistingConfig: {
        buttonText: "Add Existing",
        gridConfig: this.addExistingDashboardItemGridConfig(),
        addExistingService: (Ids: string[]) => {
          let close = new Subject<MedcorResponse<boolean>>();
          let arrGroupDashboardItemAssociations = Ids.map<GroupDashboardItemAssociationViewModel>((item) => {
            return { groupId: this.groupId, dashboardItemId: +item }
          });
          let subs = this.groupDashboardItemService.postList(true, arrGroupDashboardItemAssociations).subscribe(result => {
            subs.unsubscribe();
            if (result) {
              close.next({ result: true, statusCode: 200 });
            } else {
              close.next({ result: false });
            }
          });
          return close.asObservable();
        },
        title: "Add Dashboard Items",
      },
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: true,
      hasActionColumn: true,
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: DashboardItemViewModel) => {
          if (!deletedObj.id) return;
          let subs = this.groupDashboardItemService.deleteAssociation(deletedObj.id, this.groupId, true).subscribe(result => {
            subs.unsubscribe();
            if (result) {
              this.gridConfigDashboardItem.refreshGrid!(this.gridConfigDashboardItem.getPage!());
            }
          });
        }
      },
      rowColor: (obj: DashboardItemViewModel) => obj.voidId != 0 ? 'Pink' : null,
      headers: [
        {
          headerTitle: "Dashboard Item Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Line Of Business",
          propertyName: "lineOfBusiness",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.DynamicSelect,
          listId: 108000000000007
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ],
    }
    return configs;
  }
  /**
   * Configure Add Existing ReportItem
   */
  private addExistingDashboardItemGridConfig(): IchsGridComponentConfig<DashboardItemViewModel> {
    let addExistingGridConfig: IchsGridComponentConfig<DashboardItemViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Add Dashboard Items to Group",
      entityDataSource: (filter: SearchViewModel) => this.groupDashboardItemService.searchDashboardItemsByGroupId(this.groupId, true, filter),
      multiSelectConfig: {
      },
      hasVoid: false,
      rowColor: (obj: DashboardItemViewModel) => obj.voidId != 0 ? 'Pink' : null,
      headers: [
        {
          headerTitle: "Dashboard Item Name",
          propertyName: "name",
          searchable: true,
          sortable: true,

          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Line Of Business",
          propertyName: "lineOfBusiness",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.DynamicSelect,
          listId: 108000000000007
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }

      ],
    };
    return addExistingGridConfig;
  }

  /**
   * Configure AppUsers Grid
   */
  private defineAppUserGrid(): IchsGridComponentConfig<UserSearchViewModel> {
    let configs: IchsGridComponentConfig<UserSearchViewModel> = {
      primaryId: "id",
      defaultOrder: "lastName",
      title: "Users",
      entityDataSource: (filter: SearchViewModel) => this.groupAppUserService.searchAppUsersByGroupId(this.groupId, false, filter),
      addExistingConfig: (this.adminRoleInd ? {
        buttonText: "Add Existing",
        gridConfig: this.addExistingAppUserGridConfig(),
        addExistingService: (Ids: string[]) => {
          let close = new Subject<MedcorResponse<boolean>>();
          let arrGroupAppUserAssociations = Ids.map<GroupAppUserAssociationViewModel>((item) => {
            return { groupId: this.groupId, appUserId: +item }
          });
          this.groupAppUserService.postList(true, arrGroupAppUserAssociations).pipe(first()).subscribe(result => {
            if (result) {
              this.sharedFunctions.showDashboardDataAccessChangesReminder();
              close.next({ result: true, statusCode: 200 });
            } else {
              close.next({ result: false });
            }
          });
          return close.asObservable();
        },
        title: "Add Application User",
      } : null),
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: this.adminRoleInd,
      hasActionColumn: this.adminRoleInd,
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: UserSearchViewModel) => {
          if (!deletedObj.id) return;
          this.groupAppUserService.deleteAssociation(deletedObj.id, this.groupId, true).pipe(first()).subscribe(result => {
            if (result) {
              this.sharedFunctions.showDashboardDataAccessChangesReminder();
              this.gridConfigAppUser.refreshGrid!(this.gridConfigAppUser.getPage!());
            }
          });
        }
      },
      rowColor: (obj: UserSearchViewModel) => obj.voidId != 0 ? 'Pink' : null,
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          sortable: true,
          searchable: true,
          controlType: IchsControlType.Textfield,
          width: 250
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "First Name",
          propertyName: "firstName",
          searchable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "User Role",
          propertyName: "roleName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.StaticSelect,
        }
      ],
    }
    return configs;
  }
  /**
   * Add Existing AppUser 
   */
  private addExistingAppUserGridConfig(): IchsGridComponentConfig<UserSearchViewModel> {
    let addExistingGridConfig: IchsGridComponentConfig<UserSearchViewModel> = {
      primaryId: "id",
      defaultOrder: "lastName",
      title: "Users",
      entityDataSource: (filter: SearchViewModel) => this.groupAppUserService.searchAppUsersByGroupId(this.groupId, true, filter),
      multiSelectConfig: {
      },
      hasVoid: false,
      rowColor: (obj: UserSearchViewModel) => obj.voidId != 0 ? 'Pink' : null,
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          sortable: true,
          searchable: true,
          controlType: IchsControlType.Textfield,
          width: 250
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "First Name",
          propertyName: "firstName",
          searchable: true,
          controlType: IchsControlType.Textfield
        }
      ],
    };
    return addExistingGridConfig;
  }
}
