import { Component, OnInit } from '@angular/core';
import { IchsGridComponentConfig, IchsControlType } from '../../controls/ichs-grid/grid-configs';
import { ApplicationViewModel, SearchViewModel, UserApplicationAccessService } from '../../rest';

@Component({
  selector: 'applications-access-list',
  templateUrl: './applications-access-list.component.html',
  styleUrls: ['./applications-access-list.component.css']
})
export class ApplicationsAccessListComponent implements OnInit {
  gridConfig: IchsGridComponentConfig<ApplicationViewModel>;

  /** ctor */
  constructor(
    private userApplicationAccessService: UserApplicationAccessService,
  ) {
    this.gridConfig = this.defineGrid();
  }

  /** OnInit*/
  ngOnInit() { }

  private defineGrid(): IchsGridComponentConfig<ApplicationViewModel> {
    let configs: IchsGridComponentConfig<ApplicationViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Applications",
      entityController: "applications-access",
      hasVoid: false,
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: true,
      hasActionColumn: true,
      entityDataSource: (filter: SearchViewModel) => this.userApplicationAccessService.searchApplications(filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        }
      ]
    }
    return configs;
  }
}
