import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { first } from 'rxjs/operators';
import { APISettings, AppSettings, ValidationMessages } from 'src/app/app.general.constants';
import { IchsDialogComponent } from 'src/app/controls/ichs-dialog/ichs-dialog.component';
import { MedcorResponse } from 'src/app/rest';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from 'src/app/services/medcor-alert.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.css']
})
export class ReleaseNotesComponent implements OnInit {
  uploader: FileUploader;
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private alertService: MedcorAlertService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    var apiUrl: string = APISettings.API_BASE_PATH + "/UpdateReleaseNotes";
    this.uploader = new FileUploader({
      url: apiUrl,
      authTokenHeader: "Authorization",
      isHTML5: true,
      itemAlias: "file",
      autoUpload: false,
    });

    this.uploader.onCompleteAll = () => {
      this.uploader.clearQueue();
    }

    this.uploader.onCompleteItem = ((_item, _response, _status, _headers): any => {
      let response: MedcorResponse<any> = JSON.parse(_response);
      this.alertService.handleMedcorResponse(response);
    });
  }

  onFileSelected(event: any) {
    let file = event.srcElement.files[0];

    // reset file input
    this.fileInput.nativeElement.value = null;

    if (file.size > AppSettings.MAX_FILE_SIZE) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: [ValidationMessages.FILE_SIZE_LIMIT_EXCEEDED]
      });
    }
    else if (file.type != "application/pdf") {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: ["Only PDF file type allowed"]
      });
    }
    else {
      let dialogRef = this.dialog.open(IchsDialogComponent, {
        width: '450px',
        data: { title: "Update Release Notes", message: "Are you sure you want to update Client Portal release notes? This action will notify all users." }
      });

      dialogRef.afterClosed().pipe(first()).subscribe(result => {
        if (result) {
          this.uploader.addToQueue([file]);
          this.uploader.uploadAll();
        }
      });
    }
  }
}
