<div class="display-flex flex-basis-0 flex-grow-1 flex-col">
  <form #form="ngForm" novalidate>
    <div class="display-flex flex-col  collapsable-panel cont-padding">
      <div class="display-flex flex-items-centered widget-padding collapse-head">
        <div style="margin-bottom: 5px;" class="flex-basis-0 flex-grow-1">Service Request Form</div>
      </div>

      <div class="collapse-body widget-padding widget-bottom-radius">
        <div class="display-flex break-la">
          <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            <ichs-select [hasSearch]="true" name="requestTypeId" [(ngModel)]="requestTypeId" label="Service Request Type" required [items]="typeList"></ichs-select>
          </div>
          <div class="flex-col-sep"></div>
          <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered"></div>
        </div>
        <div class="display-flex break-la">
          <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            <ichs-text-box name="userClientName" [(ngModel)]="clientName" label="Company" required></ichs-text-box>
          </div>
          <div class="flex-col-sep"></div>
          <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered"></div>
        </div>
        <div class="display-flex break-la">
          <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            <ichs-text-area name="requestDescription" [(ngModel)]="requestDescription" label="Description" maxlength="500"></ichs-text-area>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="text-right fill-width">
  <button mat-button (click)="submitServiceRequest()" class="cp-btn cp-main-btn">Submit</button>
  <button mat-button [mat-dialog-close]="false" class="cp-btn cp-mail-btn">Cancel</button>
</div>