import { Component } from '@angular/core';
import { GeneralVariablesService } from 'src/app/services/general-variables.service';

@Component({
  selector: 'responsive',
  templateUrl: './responsive.component.html',
  styleUrls: ['./responsive.component.css']
})
export class ResponsiveComponent {
  constructor(public generalVariables: GeneralVariablesService) { }
}
