/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';












import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {MedcorResponse,PaginatedListViewModel,UserPermissionsTeleViewModel,SearchViewModel,TriagePortalUserChangePasswordViewModel,TriagePortalUserViewModel,TriagePortalUserPinCodeSearchModel,AppLogSearchModel} from '../index';


@Injectable()
export class TriagePortalUserService {

    protected basePath = 'https://localhost/ClientPortalAPI';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param objPost 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public changePasswordByPinCode(objPost?: TriagePortalUserChangePasswordViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<boolean>>;
    public changePasswordByPinCode(objPost?: TriagePortalUserChangePasswordViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<boolean>>>;
    public changePasswordByPinCode(objPost?: TriagePortalUserChangePasswordViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<boolean>>>;
    public changePasswordByPinCode(objPost?: TriagePortalUserChangePasswordViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<boolean>>(`${this.basePath}/ChangePasswordByPinCode`,
            objPost,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get AppUser By Id
     * 
     * @param id Search Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(id: number, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<TriagePortalUserViewModel>>;
    public getById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<TriagePortalUserViewModel>>>;
    public getById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<TriagePortalUserViewModel>>>;
    public getById(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getById.');
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<TriagePortalUserViewModel>>(`${this.basePath}/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param objSearch 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchPinCodes(objSearch?: SearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<TriagePortalUserPinCodeSearchModel>>>;
    public searchPinCodes(objSearch?: SearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<TriagePortalUserPinCodeSearchModel>>>>;
    public searchPinCodes(objSearch?: SearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<TriagePortalUserPinCodeSearchModel>>>>;
    public searchPinCodes(objSearch?: SearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<TriagePortalUserPinCodeSearchModel>>>(`${this.basePath}/SearchPinCodes`,
            objSearch,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search Users
     * 
     * @param objSearch Search object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchTriagePortalUsers(objSearch?: SearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<TriagePortalUserViewModel>>>;
    public searchTriagePortalUsers(objSearch?: SearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<TriagePortalUserViewModel>>>>;
    public searchTriagePortalUsers(objSearch?: SearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<TriagePortalUserViewModel>>>>;
    public searchTriagePortalUsers(objSearch?: SearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<TriagePortalUserViewModel>>>(`${this.basePath}/SearchTriagePortalUsers`,
            objSearch,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userId 
     * @param objSearch 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchUserAppLog(userId?: number, objSearch?: SearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<AppLogSearchModel>>>;
    public searchUserAppLog(userId?: number, objSearch?: SearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<AppLogSearchModel>>>>;
    public searchUserAppLog(userId?: number, objSearch?: SearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<AppLogSearchModel>>>>;
    public searchUserAppLog(userId?: number, objSearch?: SearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<AppLogSearchModel>>>(`${this.basePath}/SearchUserAppLog`,
            objSearch,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userId 
     * @param objSearch 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchUserPinCodes(userId?: number, objSearch?: SearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<TriagePortalUserPinCodeSearchModel>>>;
    public searchUserPinCodes(userId?: number, objSearch?: SearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<TriagePortalUserPinCodeSearchModel>>>>;
    public searchUserPinCodes(userId?: number, objSearch?: SearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<TriagePortalUserPinCodeSearchModel>>>>;
    public searchUserPinCodes(userId?: number, objSearch?: SearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<TriagePortalUserPinCodeSearchModel>>>(`${this.basePath}/SearchUserPinCodes`,
            objSearch,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param firstInitial 
     * @param lastInitial 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public showUserPinCode(id?: number, firstInitial?: string, lastInitial?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<string>>;
    public showUserPinCode(id?: number, firstInitial?: string, lastInitial?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<string>>>;
    public showUserPinCode(id?: number, firstInitial?: string, lastInitial?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<string>>>;
    public showUserPinCode(id?: number, firstInitial?: string, lastInitial?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (firstInitial !== undefined && firstInitial !== null) {
            queryParameters = queryParameters.set('firstInitial', <any>firstInitial);
        }
        if (lastInitial !== undefined && lastInitial !== null) {
            queryParameters = queryParameters.set('lastInitial', <any>lastInitial);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<string>>(`${this.basePath}/ShowUserPinCode`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param objPost 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public submitTriageUserPinCode(objPost?: TriagePortalUserChangePasswordViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<boolean>>;
    public submitTriageUserPinCode(objPost?: TriagePortalUserChangePasswordViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<boolean>>>;
    public submitTriageUserPinCode(objPost?: TriagePortalUserChangePasswordViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<boolean>>>;
    public submitTriageUserPinCode(objPost?: TriagePortalUserChangePasswordViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<boolean>>(`${this.basePath}/SubmitTriageUserPinCode`,
            objPost,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update TriagePortalUserViewModel
     * 
     * @param objPost TriagePortalUser To Update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUser(objPost?: TriagePortalUserViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<TriagePortalUserViewModel>>;
    public updateUser(objPost?: TriagePortalUserViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<TriagePortalUserViewModel>>>;
    public updateUser(objPost?: TriagePortalUserViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<TriagePortalUserViewModel>>>;
    public updateUser(objPost?: TriagePortalUserViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<TriagePortalUserViewModel>>(`${this.basePath}/UpdateUser`,
            objPost,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
