import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppModuleShared } from '../app.shared.module';
import { AppLogListComponent } from './app-log-list/app-log-list.component';
import { AppLogDialogComponent } from './app-log-dialog/app-log-dialog.component';

@NgModule({
    exports: [],
    imports: [
        AppModuleShared,
        RouterModule.forChild([
            { path: 'app-log', component: AppLogListComponent },
        ]),
    ],
    declarations: [
        AppLogListComponent,
        AppLogDialogComponent,
    ]
})
export class AppLogModule { }
