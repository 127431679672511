import { Component } from '@angular/core';
import { ResourcesConstants } from 'src/app/app.general.constants';
import { CarouselItem } from 'src/app/controls/ichs-carousel/ichs-carousel.component';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent {
  selectedIndex: number = 0;
  waitingFullLoad: boolean = true;

  items: CarouselItem[] = [
  ];

  constructor() {
    this.getSliders();
  }

  getSliders() {
    ResourcesConstants.HOME_IMAGES_PATH.forEach(image => {
      let slide = new CarouselItem();
      slide.img = image.path;
      this.items.push(slide);
    });
    this.waitingFullLoad = false;
  }

}



