import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'email-containter',
  templateUrl: './email-containter.component.html',
  styleUrls: ['./email-containter.component.scss'],
  host: { class: "fill-parent" }
})

export class EmailContainterComponent implements OnInit {
  emailId: string;
  mailBox: string;

  sub: Subscription;

  constructor(
    public generalVariables: GeneralVariablesService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    let urlSegments = this.route.snapshot.firstChild.url;
    this.mailBox = urlSegments[0].path;
    this.emailId = urlSegments.length == 2 ? urlSegments[1].path : this.emailId = null;

    this.sub = this.router.events.pipe(filter(obj => obj instanceof NavigationEnd)).subscribe(() => {
      let urlSegments = this.route.snapshot.firstChild.url;
      this.mailBox = urlSegments[0].path;
      this.emailId = urlSegments.length == 2 ? urlSegments[1].path : this.emailId = null;
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  forward() {
    this.router.navigate(["forward/" + this.emailId],
      {
        relativeTo: this.route,
        queryParams: {
          mailBox: this.mailBox,
        },
      });
  }

  reply() {
    this.router.navigate(["new/" + this.emailId],
      {
        relativeTo: this.route
      });
  }

  replyAll() {
    this.router.navigate(["new/" + this.emailId],
      {
        queryParams: { isReplyAll: true },
        relativeTo: this.route,
      });
  }
}
