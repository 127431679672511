import { Component, OnInit, ViewChild } from '@angular/core';
import { IchsCommandToolbarModel, IchsCommandToolbarDelegate } from '../../controls/ichs-command-toolbar/ichs-command-toolbar.component';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';
import { ReportItemViewModel, ReportItemService, VoidInfoViewModel, SearchViewModel, GroupViewModel, GroupReportItemAssociationService, GroupReportItemAssociationViewModel, ReportingService, ReportCatalogItemViewModel, MedcorResponse, SelectionItemViewModel, PublicFileService, } from '../../rest/index';
import { Subject } from 'rxjs';
import { HttpEventType } from '@angular/common/http';
import { IchsGridComponentConfig, IchsControlType } from '../../controls/ichs-grid/grid-configs';
import { first } from 'rxjs/operators';
import { AppSettings, ValidationMessages } from '../../app.general.constants';

@Component({
  selector: 'report-item',
  templateUrl: './report-item.component.html',
  styleUrls: ['./report-item.component.css']
})

/** reportItem component
Example how to use IchsCommandToolbar
*/
export class ReportItemComponent implements OnInit, IchsCommandToolbarDelegate<ReportItemViewModel> {
  ngForm: NgForm = new NgForm([], []);
  @ViewChild('formGroupReport', { static: true }) formGroupReport: NgForm;// bind the Form From view 
  @ViewChild('formGroupImages', { static: true }) formGroupImages: NgForm;// bind the Form From view 
  @ViewChild('formGroupDetails', { static: true }) formGroupDetails: NgForm;// bind the Form From view 

  model: IchsCommandToolbarModel<ReportItemViewModel>;// 2) define Command Toolbar model and use model.objModel to bing the FORM UI and use model as IchsCommandToolbar [model]
  gridConfigGroup: IchsGridComponentConfig<GroupViewModel>;
  catalogs: Array<ReportCatalogItemViewModel> = [];
  pathFolders: Array<ReportCatalogItemViewModel> = [];
  selectedReport: ReportCatalogItemViewModel | null;
  selectedFolder: ReportCatalogItemViewModel | null;
  tagItems: SelectionItemViewModel[] = [{ selectionText: "Orange Tag", selectionValue: "Tag 1" }, { selectionText: "Green Tag", selectionValue: "Tag 2" }];

  thumbImage: string;
  thumbFile: File;
  snapshotImage: string;
  snapshotFile: File;

  get reportItemId(): number { return this.model.objModelId }
  get selectedReportPath(): string | null { return this.selectedReport && this.selectedReport.path ? this.selectedReport.path : "" };

  isformGroupReportChecked = false;
  isformGroupImagesChecked = false;
  isformGroupDetailsChecked = false;

  /** reportItem ctor */
  constructor(
    private _location: Location,
    private _router: Router,
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _alertService: MedcorAlertService,
    private _reportItemService: ReportItemService,
    private _reportingService: ReportingService,
    private _groupReportItemAssociationService: GroupReportItemAssociationService,
    private _filesService: PublicFileService,
  ) {
    this.model = new IchsCommandToolbarModel<ReportItemViewModel>(_location, _router, "/report-item", "ReportItem", this, _dialog, _alertService); // 3) Create Command Toolbar Model 
    this.gridConfigGroup = this.defineGroupGrid();
  }

  /** ReportItem OnInit*/
  ngOnInit() {
    this.ngForm.form = new UntypedFormGroup({
      formGroupReport: this.formGroupReport.form,
      formGroupImages: this.formGroupImages.form,
      formGroupDetails: this.formGroupDetails.form
    });
    this.model.init(this._route); // 4) Initialize The Model
  }
  // 5) implement IchsCommandToolbarDelegate .
  canDeactivate(): boolean | Observable<boolean> {
    return this.model.confirmCanDeactivate();
  }
  getLoadObjModelObservable(id: number) {
    return this._reportItemService.getById(id);
  }
  getSaveObservable(objModel: ReportItemViewModel): Observable<MedcorResponse<ReportItemViewModel>> {
    return this._reportItemService.postUpload(
      objModel.name,
      this.selectedReport!.path!,
      this.selectedReport!.id!,
      objModel.lineOfBusiness,
      objModel.description ? objModel.description : undefined,
      objModel.subTitle ? objModel.subTitle : undefined,
      objModel.keyWords ? objModel.keyWords : undefined,
      this.thumbFile,
      this.snapshotFile,
      objModel.hasPhiData,
      objModel.hasHrData,
      objModel.hasFinanceData,
      objModel.internalInd,
      objModel.tag ? objModel.tag : undefined,
      objModel.tagTitle ? objModel.tagTitle : undefined,
      objModel.exportOptionsList,
      objModel.id ? objModel.id : 0,
      objModel.voidId ? objModel.voidId : 0,
      objModel.version
    );
  }

  getVoidObservable(objVoidModel: VoidInfoViewModel): Observable<MedcorResponse<ReportItemViewModel>> {
    return this._reportItemService._void(objVoidModel)
  }

  getRecoverObservable(id: number): Observable<MedcorResponse<ReportItemViewModel>> {
    return this._reportItemService.recover(id);
  }

  beforeSave(objModel: ReportItemViewModel) {
    objModel.reportPathId = this.selectedReport.id;
    objModel.reportPath = this.selectedReport.path;
  }

  didLoad(objModel: ReportItemViewModel) {
    if (objModel.id && objModel.id != -1) {
      let subs = this.getCatalogItems(objModel.reportPath, true).subscribe(result => {
        if (!result) return;
        let matchReport = result.find(obj => obj.path == objModel.reportPath);
        if (matchReport) {
          this.selectedReport = matchReport;
          if (matchReport.virtualPath) {
            this.setFolderPath(matchReport.virtualPath, true);
          }
        }
      }, undefined, () => {
        subs.unsubscribe();
      });

      if (objModel.thumbnailId) {
        let subs = this._filesService.downloadFile(objModel.thumbnailId, 'events', true).subscribe(event => {
          if (event.type == HttpEventType.Response) {
            var reader = new FileReader();
            reader.onload = (event: any) => {
              this.thumbImage = event.target.result;
            }

            reader.readAsDataURL(event.body!);
          }
        }, undefined, () => { subs.unsubscribe(); });
      }

      if (objModel.snapshotId) {
        let subs = this._filesService.downloadFile(objModel.snapshotId, 'events', true).subscribe(event => {
          if (event.type == HttpEventType.Response) {
            var reader = new FileReader();
            reader.onload = (event: any) => {
              this.snapshotImage = event.target.result;
            }

            reader.readAsDataURL(event.body!);
          }
        }, undefined, () => { subs.unsubscribe(); });
      }
    } else {
      this.getCatalogItems(undefined);
    }
  }

  validatingAllFormFields(formGroup: UntypedFormGroup) {
    if (formGroup == this.formGroupReport.form) {
      this.isformGroupReportChecked = true;
    } else if (formGroup == this.formGroupImages.form) {
      this.isformGroupImagesChecked = true;
    } else if (formGroup == this.formGroupDetails.form) {
      this.isformGroupDetailsChecked = true;
    } else {
      this.isformGroupReportChecked = true;
      this.isformGroupImagesChecked = true;
      this.isformGroupDetailsChecked = true;
    }
  }

  /**
   * Configure Groups Grid
   */
  private defineGroupGrid(): IchsGridComponentConfig<GroupViewModel> {
    let configs: IchsGridComponentConfig<GroupViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Groups",
      entityController: "app-folder",
      entityDataSource: (filter: SearchViewModel) => this._groupReportItemAssociationService.searchGroupsByReportItemId(this.reportItemId, false, filter),
      addExistingConfig: {
        buttonText: "Add Existing",
        gridConfig: this.addExistingGroupGridConfig(),
        addExistingService: (Ids: string[]) => {
          let close = new Subject<MedcorResponse<boolean>>();
          let arrReportItemGroupAssociations = Ids.map<GroupReportItemAssociationViewModel>((item) => {
            return { reportItemId: this.reportItemId, groupId: +item }
          });
          this._groupReportItemAssociationService.postList(false, arrReportItemGroupAssociations).pipe(first()).subscribe(result => {
            if (result) {
              close.next({ result: true, statusCode: 200 });
            } else {
              close.next({ result: false });
            }
          });
          return close.asObservable();
        },
        title: "Add Groups",
      },
      hasSearch: false,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: true,
      hasActionColumn: true,
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: GroupViewModel) => {
          if (!deletedObj.id) return;
          let subs = this._groupReportItemAssociationService.deleteAssociation(this.reportItemId, deletedObj.id, false).subscribe(result => {
            if (result) {
              this.gridConfigGroup.refreshGrid!(this.gridConfigGroup.getPage!());
            }
          }, undefined, () => { subs.unsubscribe(); });
        }
      },
      rowColor: (obj: GroupViewModel) => obj.voidId != 0 ? 'Pink' : null,
      headers: [
        {
          headerTitle: "Group Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ],
    }
    return configs;
  }
  /**
   * Configure Add Existing Group
   */
  private addExistingGroupGridConfig(): IchsGridComponentConfig<GroupViewModel> {
    let addExistingGridConfig: IchsGridComponentConfig<GroupViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Add Groups to ReportItem",
      entityController: "app-folder",
      entityDataSource: (filter: SearchViewModel) => this._groupReportItemAssociationService.searchGroupsByReportItemId(this.reportItemId, true, filter),
      multiSelectConfig: {
      },
      hasVoid: false,
      rowColor: (obj: GroupViewModel) => obj.voidId != 0 ? 'Pink' : null,
      headers: [
        {
          headerTitle: "Group Name",
          propertyName: "name",
          searchable: true,
          sortable: true,

          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }

      ],
    };
    return addExistingGridConfig;
  }

  private getCatalogItems(path: string | undefined, isReport: boolean = false): Observable<Array<ReportCatalogItemViewModel> | null> {
    let completed = new Subject<Array<ReportCatalogItemViewModel> | null>();
    let subs = this._reportItemService.getCatalogs(path, isReport).subscribe(medcorResult => {
      if (!medcorResult || !medcorResult.result) return;
      this.catalogs = medcorResult.result;
      completed.next(this.catalogs);
    }, undefined,
      () => {
        subs.unsubscribe();
        completed.complete();
      });
    return completed.asObservable();
  }

  getFolders() {
    return this.catalogs.filter(obj => obj.typeName == "Folder");
  }

  getFiles() {
    return this.catalogs.filter(obj => obj.typeName != "Folder");
  }

  selectFile(report: ReportCatalogItemViewModel) {
    if (this.selectedReport == report) {
      this.selectedReport = null;
    } else {
      this.selectedReport = report;
      if (report.name) {
        this.model.objModel.name = report.name;
      }
    }
  }

  routeToFolder(folder: ReportCatalogItemViewModel | null) {
    // don't route to current folder (folder = null, means Root)
    if (!this.selectedFolder && !folder) {
      return;
    }

    // don't route to current folder
    if (this.selectedFolder && folder && this.selectedFolder.virtualPath == folder.virtualPath) {
      return;
    }

    this.selectedReport = null;
    if (folder && folder.virtualPath) {
      this.setFolderPath(folder.virtualPath);
      this.getCatalogItems(folder.virtualPath);
    } else {
      this.pathFolders = [];
      this.selectedFolder = null;
      this.getCatalogItems(undefined);
    }
  }

  private setFolderPath(virtualPath: string, isReport: boolean = false) {
    this.pathFolders = [];
    let folderNames = virtualPath.split("/");
    if (isReport) {
      folderNames.pop();
    }
    let fPath = "";
    folderNames.forEach(fName => {
      fPath += fName
      this.pathFolders.push({
        name: fName,
        virtualPath: fPath
      });
      fPath += "/"
    });

    this.selectedFolder = this.pathFolders[this.pathFolders.length - 1];
  }

  thumbImageSelected(ev: any) {
    if (ev.srcElement.files.length != 1) {
      return;
    }

    let file = ev.srcElement.files[0] as File;

    if (file.size > AppSettings.MAX_FILE_SIZE) {
      this._alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.FILE_SIZE_LIMIT_EXCEEDED]
      });
      return;
    }

    console.log("file", file);

    if (file.type.toLowerCase() == "image/svg+xml") {
      console.log("file.type", file.type);

      this._alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FILETYPE]
      });
      return;
    }

    if (file.type.toLowerCase().indexOf("image/") == -1) {
      this._alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.NOT_IMAGE_FILETYPE]
      });
      return;
    }

    this.thumbFile = file;
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.thumbImage = event.target.result;
    }
    reader.readAsDataURL(this.thumbFile);
  }

  snapshotImageSelected(ev: any) {
    if (ev.srcElement.files.length != 1) {
      return;
    }

    let file = ev.srcElement.files[0] as File;

    if (file.size > AppSettings.MAX_FILE_SIZE) {
      this._alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.FILE_SIZE_LIMIT_EXCEEDED]
      });
      return;
    }

    if (file.type.toLowerCase() == "image/svg+xml") {
      this._alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FILETYPE]
      });
      return;
    }

    if (file.type.toLowerCase().indexOf("image/") == -1) {
      this._alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.NOT_IMAGE_FILETYPE]
      });
      return;
    }

    this.snapshotFile = file;
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.snapshotImage = event.target.result;
    }
    reader.readAsDataURL(this.snapshotFile);
  }
}
