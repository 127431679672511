<div class="slider-container" *ngIf="data.length > 0">
  <div class="slider-btn-cont position-relative"><button class="btn-next slider-btn position-absolute" (click)="next()"><span>Next</span><img src="assets/images/down-arrow.svg" class="image-rotator" /> </button></div>
  <div class="slider-btn-cont position-relative"><button class="btn-prev slider-btn position-absolute" (click)="prev()"><img src="assets/images/down-arrow.svg" class="image-rotator" /> <span>Previous</span></button></div>
  <div class="slider-item" *ngFor="let item of data, let index = index" [ngClass]="{'selected-slide':selectedIndex == index}">
    <a *ngIf="item.link" [href]="item.link" target="_blank"> <img [src]="item.img" /></a>
    <img *ngIf="!item.link" [src]="item.img" />
    <div class="slide-desc">
      <a *ngIf="item.link && item.title" [href]="item.link" target="_blank">
        <h2 [innerHtml]="item.title | safeHtml"></h2>
      </a>
      <h2 *ngIf="!item.link && item.title" [innerHtml]="item.title | safeHtml"></h2>
      <h5 *ngIf="item.desc">{{item.desc}}</h5>
    </div>
  </div>

</div>
