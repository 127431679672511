<form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
    <p>Enter user initials to show PIN code</p>

    <div class="display-flex break-la">
        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            <ichs-text-box name="firstInital" label="First Inital" [(ngModel)]="firstInitial" (ngModelChange)="pinCode = null"
                required maxlength="1">
            </ichs-text-box>
        </div>
        <div class="flex-col-sep"></div>
        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
            <ichs-text-box name="lastInital" label="Last Inital" [(ngModel)]="lastInitial" (ngModelChange)="pinCode = null"
                required maxlength="1">
            </ichs-text-box>
        </div>
    </div>
    <div class="display-flex break-la">
        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
        </div>
        <div class="flex-col-sep"></div>
        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
        </div>
    </div>
    <div *ngIf="pinCode" class="display-flex break-la pin-code-cont">
        <div class="pin-code">
            <span>{{pinCode}}</span>
            <img title="Copy to Clipboard" class="field-icon" src="assets/images/copy-content.svg" (click)="copyPinCode()" />
        </div>
    </div>
    <div class="flex-basis-0 flex-grow-1 text-right">
        <button mat-button class="cp-btn cp-main-btn" (click)='showPinCode()'>Show PIN Code</button>
        <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">Close</button>
    </div>
</form>