/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface AppLogSearchModel { 
    logId?: string;
    userEmail?: string;
    impersonatorUserEmail?: string;
    clientIp?: string;
    logTime?: Date;
    level?: string;
    action?: string;
    requestUrl?: string;
    logType?: string;
    entityId?: string;
    entityName?: string;
    extraInfo?: string;
    userAgent?: string;
    message?: string;
    isException?: boolean;
    exception?: string;
}
