import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SharedFolderViewModel, SharedFolderService } from '../../../rest';
import { first } from 'rxjs/operators';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../../services/medcor-alert.service';

@Component({
  selector: 'app-move-shared-file',
  templateUrl: './move-shared-file.component.html',
  styleUrls: ['./move-shared-file.component.css']
})
export class MoveSharedFileComponent implements OnInit {

  folders: SharedFolderViewModel[] = [];
  pathName: string[] = [];
  pathId: string[] = [];

  constructor(private dialogRef: MatDialogRef<MoveSharedFileComponent, string>,
    @Inject(MAT_DIALOG_DATA) private obj: any,
    private sharedFolderService: SharedFolderService,
    private alertService: MedcorAlertService) {
  }

  ngOnInit() {
    let currentFolder = this.obj.currentFolder;
    this.sharedFolderService.listSharedFolders(currentFolder.mainFolderId).pipe(first()).subscribe(res => {
      this.folders = res.result!;
      this.pathId = currentFolder.pathUnique.split("/");
      this.pathName = currentFolder.path.split("/");

      // remove Root
      this.pathId.shift();
      this.pathName.shift();
      // remove all sub-folders and keep main folder only
      this.pathId.splice(1);
      this.pathName.splice(1);
    });
  }

  loadFolders(folder: SharedFolderViewModel) {
    this.sharedFolderService.listSharedFolders(folder.id).pipe(first()).subscribe(res => {
      this.folders = res.result!;

      this.pathId = folder.pathUnique.split("/");
      this.pathName = folder.path.split("/");

      // remove Root
      this.pathId.shift();
      this.pathName.shift();
    });
  }

  navigateTofolder(folderId: number, index: number) {
    this.sharedFolderService.listSharedFolders(folderId).pipe(first()).subscribe(res => {
      this.folders = res.result!;

      // remove all sub-folders of the target folder from path
      this.pathId.splice(index + 1);
      this.pathName.splice(index + 1);
    });
  }

  moveFileTo() {
    let targetFolderId = this.pathId.pop();

    if (this.obj.fileToMove.folderId == targetFolderId) {
      this.pathId.push(targetFolderId);   // return it back
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["This file is already in this folder!"]
      });
    }
    else {
      this.dialogRef.close(targetFolderId);
    }
  }
}
