<div class="cont-padding static-info">
  <h1 class="title">Construction Health &amp; Safety Services</h1>
  <div class="content-column two_third">
    <div style="padding-right:10px;">
      <h2 class="body-subtext" style="font-weight: normal; text-align: justify;">Driving Down Injuries, Claims, Costs and Recordables</h2>
      <p style="text-align: justify;">Construction projects nationwide rely on Medcor’s on-site health and safety services to help prevent on-the-job injuries, reduce workers compensation costs, and ensure occupational health compliance. Treating injuries on-site appropriately avoids unnecessary and excessive off-site referrals, treatments and prescriptions. Minor injuries can be resolved on-site, allowing workers to return to productive work. More serious cases are recognized sooner so they can receive appropriate diagnoses and treatment.</p>
      <h2 class="body-subtext" style="font-weight: normal; text-align: justify;">Serving the Construction Industry Throughout the U.S. and Canada</h2>
      <p style="text-align: justify;">Medcor’s clients include owners, construction managers, brokers and carriers. Projects types include skyscrapers, power plants, refineries, stadiums, airports, data centers, bridges, tunnels, dams, highways, pipelines, convention centers, and many others. Medcor provides urban and rural operations, and serves short-term and long-term projects. Medcor’s systems are designed to scale to fit projects of any size.</p>
      <h2 class="body-subtext" style="font-weight: normal; text-align: justify;">Caring for Injured Workers</h2>
      <p style="text-align: justify;">Construction sites are inherently challenging and hazardous work environments. When injuries occur, workers benefit from Medcor’s professional staff and clinical excellence, which navigate the healthcare process. Being on-site allows immediate response. We focus on the injured person, providing assessment and treatment on-site when appropriate, and making off-site referrals to the right level of care when necessary. We get injured employees on the right path to recovery right away, and always with a high level of customer service.</p>
      <h2 class="body-subtext" style="font-weight: normal; text-align: justify;">Onsite Medical Professionals</h2>
      <p style="text-align: justify;">Medcor employs experienced paramedics, EMTs, RNs, NPs and PAs, even physicians, onsite to meet each client’s specific needs. These are Medcor employees, not subcontractors. They are dedicated to their sites so projects receive consistent staffing. Medcor provides specialized training for our staff for this specialized work. We provide all necessary systems, software, equipment, and supplies, as well as operational support and physician medical direction. <a style="font-weight: bold;" href="https://www.medcor.com/construction-health-services-medic/">(Click here to learn more about our on-site staff.)</a></p>
      <h2 class="body-subtext" style="font-weight: normal; text-align: justify;">Clinics and Mobile Units</h2>
      <p style="text-align: justify;">At large construction projects at a fixed location, Medcor utilizes clinics in trailers or other temporary structures located on or near the project. Medcor can use trailers provided by the customer, or deliver our own clinics to the site. On projects covering wide areas, such as highway, pipeline, and wind farm projects, Medcor staff uses specially-configured mobile units.&nbsp;<a style="font-weight: bold;" href="https://www.medcor.com/construction-clinics-mobile-units/">(Click here to learn more about our clinics and mobile units.)</a></p>
      <h2 class="body-subtext" style="font-weight: normal; text-align: justify;">Onsite Safety Staffing and Training</h2>
      <p style="text-align: justify;">Medcor provides qualified safety staffing for short-term and long term projects to augment client programs. Medcor also provides comprehensive curriculum of safety training programs. Clients trust Medcor’s safety professionals for their experience, training, and certifications. Medcor can provide professionals with industry-specific experience. <a style="font-weight: bold;" href="https://www.medcor.com/safety-services/">(Click here to learn more about our safety staff and services.)</a></p>
    </div>
  </div>
</div>
