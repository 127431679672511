import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, Validator, ValidationErrors } from '@angular/forms';

@Directive({
    selector: '[email][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: EmailValidator, multi: true }
    ]
})
export class EmailValidator implements Validator {
    private _email: boolean;
    @Input()
    get email(): boolean | string { return this._email; }
    set email(value: boolean | string) {
        this._email = value != null && value !== false && `${value}` !== 'false';
    }

    validate(control: AbstractControl): ValidationErrors | null {
        //email expression.
        const expression = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!this._email) {
            return null;
        }

        // the [required] validator if exist will check this part.
        if (!control.value) {
            return null;
        }

        const value = control.value;
        if (expression.test(value)) {
            return null;
        }

        const validationMessage = {
            ["email"]: 'Please enter a valid Email pattern'
        };

        return validationMessage;
    }
}