import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of, Subscription } from 'rxjs';
import { ReportItemService, ReportItemViewModel, AppUserService, ReportingService } from '../../rest/index';
import { first, switchMap } from 'rxjs/operators';
import { UserSettingsConstants, LineOfBusiness } from '../../app.general.constants';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { AlertMessageType, MedcorAlertService, AlertDismissType } from '../../services/medcor-alert.service';
import { SharedFunctionsService } from '../../services/shared-functions.service';
import { HttpErrorResponse } from '@angular/common/http';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-reporting-list',
  templateUrl: './reporting-list.component.html',
  styleUrls: ['./reporting-list.component.css']
})
export class ReportingListComponent {
  public lineOfBusinessList: string[] = [];
  oshaRecordKeepingInstallationAccessOnly: boolean;

  reportList: ReportItemViewModel[] = [];
  searchText: string = '';
  selectedLineOfBusiness: string = LineOfBusiness.TRIAGE;
  type: string;
  defaultReportView: boolean = true;
  selectedIndex: number = 0;

  selectedIndexForTriage: number = null;
  selectedIndexForOccupationalHealth: number = null;
  selectedIndexForAdvancedPractice: number = null;
  selectedIndexForScreening: number = null;
  selectedIndexForOshaReportable: number = null;

  lastDataRefreshInfo: string;

  get filteredFavoriteReportList(): ReportItemViewModel[] {
    var lowerSearchText = this.searchText.toLowerCase();
    return this.filteredReportList.filter((reportItem) => {
      if (reportItem.isFavorite &&
        (lowerSearchText.length == 0 || reportItem.name.toLowerCase().indexOf(lowerSearchText) >= 0 ||
          reportItem.subTitle && reportItem.subTitle.toLowerCase().indexOf(lowerSearchText) >= 0 ||
          reportItem.description && reportItem.description.toLowerCase().indexOf(lowerSearchText) >= 0 ||
          reportItem.keyWords && reportItem.keyWords.toLowerCase().indexOf(lowerSearchText) >= 0) &&
        (reportItem.lineOfBusiness == this.selectedLineOfBusiness)) return true;
    });
  }

  get filteredReportList(): ReportItemViewModel[] {
    var lowerSearchText = this.searchText.toLowerCase();
    return this.reportList.filter((reportItem) => {
      if ((lowerSearchText.length == 0 || reportItem.name.toLowerCase().indexOf(lowerSearchText) >= 0 ||
        reportItem.subTitle && reportItem.subTitle.toLowerCase().indexOf(lowerSearchText) >= 0 ||
        reportItem.description && reportItem.description.toLowerCase().indexOf(lowerSearchText) >= 0 ||
        reportItem.keyWords && reportItem.keyWords.toLowerCase().indexOf(lowerSearchText) >= 0) &&
        (reportItem.lineOfBusiness == this.selectedLineOfBusiness)) return true;
    });
  }

  constructor(
    private reportItemService: ReportItemService,
    private route: ActivatedRoute,
    private appUserService: AppUserService,
    private generalVariablesService: GeneralVariablesService,
    private alertService: MedcorAlertService,
    private _reportingService: ReportingService,
    private _sharedFunctionsService: SharedFunctionsService
  ) {
    this.reportItemService.getForUser().pipe(first()).subscribe(medcorResult => {
      if (!medcorResult || !medcorResult.result) return;
      this.reportList = medcorResult.result;
      if (this.filteredFavoriteReportList.length == 0)
        this.selectedIndex = 1;
    });

    this.appUserService.getUserBusinessLines().pipe(first(), switchMap(response => {
      this.lineOfBusinessList = response.result.lineOfBusinessList;
      this.oshaRecordKeepingInstallationAccessOnly = response.result.oshaRecordKeepingInstallationAccessOnly;
      if (this.lineOfBusinessList.length > 0) {
        if (this.lineOfBusinessList.indexOf(LineOfBusiness.TRIAGE) == -1) {
          this.selectedLineOfBusiness = this.lineOfBusinessList[0];
        }

        this.updateLastDataRefreshInfo();
        return this._reportingService.reportingStatus(this.selectedLineOfBusiness);
      }
      else {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: ["You don't have access on this data!"]
        });
        return of();
      }
    }))
      .subscribe(resp => { },
        (error: HttpErrorResponse) => {
          this._sharedFunctionsService.showReportStatusDialog(error)
        });
    this.defaultReportView = this.generalVariablesService.userSettings.reportView != UserSettingsConstants.ReportView.Values.List;
  }


  clearFilter() {
    this.searchText = "";
  }

  changeLineOfBusiness(userLine: string) {
    this.selectedLineOfBusiness = userLine;

    if (this.selectedLineOfBusiness == LineOfBusiness.TRIAGE && this.selectedIndexForTriage != null) {
      this.selectedIndex = this.selectedIndexForTriage;
    }
    else if (this.selectedLineOfBusiness == LineOfBusiness.OCCUPATIONAL_HEALTH && this.selectedIndexForOccupationalHealth != null) {
      this.selectedIndex = this.selectedIndexForOccupationalHealth;
    }
    else if (this.selectedLineOfBusiness == LineOfBusiness.ADVANCED_PRACTICE && this.selectedIndexForAdvancedPractice != null) {
      this.selectedIndex = this.selectedIndexForAdvancedPractice;
    }
    else if (this.selectedLineOfBusiness == LineOfBusiness.SCREENING && this.selectedIndexForScreening != null) {
      this.selectedIndex = this.selectedIndexForScreening;
    }
    else if (this.selectedLineOfBusiness == LineOfBusiness.OSHA_REPORTABLE && this.selectedIndexForOshaReportable != null) {
      this.selectedIndex = this.selectedIndexForOshaReportable;
    }
    else {
      this.selectedIndex = this.filteredFavoriteReportList.length > 0 ? 0 : 1;
    }

    this.updateLastDataRefreshInfo();

    this._reportingService.reportingStatus(this.selectedLineOfBusiness).pipe(first()).subscribe(reposne => { },
      (error: HttpErrorResponse) => {
        this._sharedFunctionsService.showReportStatusDialog(error)
      });
  }

  onReportsTabChange() {
    this.clearFilter();

    if (this.selectedLineOfBusiness == LineOfBusiness.TRIAGE) {
      this.selectedIndexForTriage = this.selectedIndex;
    }
    else if (this.selectedLineOfBusiness == LineOfBusiness.OCCUPATIONAL_HEALTH) {
      this.selectedIndexForOccupationalHealth = this.selectedIndex;
    }
    else if (this.selectedLineOfBusiness == LineOfBusiness.ADVANCED_PRACTICE) {
      this.selectedIndexForAdvancedPractice = this.selectedIndex;
    }
    else if (this.selectedLineOfBusiness == LineOfBusiness.SCREENING) {
      this.selectedIndexForScreening = this.selectedIndex;
    }
    else if (this.selectedLineOfBusiness == LineOfBusiness.OSHA_REPORTABLE) {
      this.selectedIndexForOshaReportable = this.selectedIndex;
    }
  }

  changeView() {
    this.defaultReportView = !this.defaultReportView;
    this.generalVariablesService.userSettings.reportView = this.defaultReportView ? UserSettingsConstants.ReportView.Values.Tiles : UserSettingsConstants.ReportView.Values.List;
    this.appUserService.updateUserSettings(
      UserSettingsConstants.ReportView.Key,
      this.generalVariablesService.userSettings.reportView
    )
      .pipe(first()).subscribe();
  }

  private updateLastDataRefreshInfo() {
    let date = new Date();

    if (
      this.selectedLineOfBusiness == LineOfBusiness.TRIAGE ||
      this.selectedLineOfBusiness == LineOfBusiness.OCCUPATIONAL_HEALTH ||
      this.selectedLineOfBusiness == LineOfBusiness.ADVANCED_PRACTICE ||
      this.selectedLineOfBusiness == LineOfBusiness.OSHA_REPORTABLE
    ) {
      date.setDate(date.getDate() - 1); // Those business line are getting their data refreshed once daily
      this.lastDataRefreshInfo = `Data is current through ${formatDate(date, 'shortDate', 'en-US')}`;
    }
    else if (this.selectedLineOfBusiness == LineOfBusiness.SCREENING) {
      // Screening data are getting refreshed every 15 minutes starting from 00:00, that is at hh:00, hh:15, hh:30, hh:45
      date.setMinutes(Math.floor(date.getMinutes() / 15) * 15);
      this.lastDataRefreshInfo = `Data is current through ${formatDate(date, 'short', 'en-US')}`;
    }
    else {
      this.lastDataRefreshInfo = null;
    }
  }
}
