import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { DIALOG_REF, IchsComponentDialogComponent, EXTRA_DATA } from 'src/app/controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { SelectionItemViewModel, ServiceRequestService } from 'src/app/rest';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from 'src/app/services/medcor-alert.service';
import { ValidationMessages } from '../../app.general.constants';

@Component({
  selector: 'new-service-request',
  templateUrl: './new-service-request.component.html',
  styleUrls: ['./new-service-request.component.css']
})
/** new-service-request component*/
export class NewServiceRequestComponent {
  @ViewChild('form') ngForm: NgForm;
  typeList: SelectionItemViewModel[] = [];
  clientName: string;
  requestTypeId: string;
  requestDescription: string;

  /** new-service-request ctor */
  constructor(
    private serviceRequestService: ServiceRequestService,
    private alertService: MedcorAlertService,
    @Inject(DIALOG_REF) private dialogRef: MatDialogRef<IchsComponentDialogComponent>,
    @Inject(EXTRA_DATA) private _requestTypeId: string,
  ) {
    this.serviceRequestService.getUserClientAndRequestTypes().pipe(first()).subscribe(res => {
      this.typeList = res.result.requestTypes;
      this.clientName = res.result.clientName;
    });

    if (this._requestTypeId) {
      this.requestTypeId = this._requestTypeId;
    }
  }

  submitServiceRequest() {
    this.validateAllFormFields(this.ngForm.form); // validate the form and show errors
    if (this.ngForm.invalid) {   // check if form is valid 
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    this.serviceRequestService
      .submitServiceRequest({
        requestTypeId: +this.requestTypeId,
        clientName: this.clientName,
        description: this.requestDescription,
      })
      .pipe(first())
      .subscribe(() => this.dialogRef.close(true));
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
