import { Component, OnInit, ViewChild } from '@angular/core';
import { TriagePortalUserChangePasswordViewModel, SelectionItemViewModel, SelectionListService, TriagePortalUserService } from '../../rest';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgForm, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AlertMessageType, AlertDismissType, MedcorAlertService } from '../../services/medcor-alert.service';
import { ValidationMessages } from '../../app.general.constants';

@Component({
  selector: 'triage-portal-user-pincode-access',
  templateUrl: './triage-portal-user-pincode-access.component.html',
  styleUrls: ['./triage-portal-user-pincode-access.component.scss']
})
export class TriagePortalUserPincodeAccessComponent implements OnInit {

  @ViewChild("form") ngForm: NgForm;

  confirmPassword: string;
  model: TriagePortalUserChangePasswordViewModel;

  questionsList: SelectionItemViewModel[];
  hasSecQuestions: boolean;

  get selectedQuestionsList(): string[] {
    return this.model.secQuestionAssociationList.map(item => item.question);
  }

  constructor(
    private selectionService: SelectionListService,
    private triagePortalUserService: TriagePortalUserService,
    private router: Router,
    private alertService: MedcorAlertService,
  ) { }

  ngOnInit() {
    if (window.history.state && window.history.state.triageUserData) {
      this.model = <TriagePortalUserChangePasswordViewModel>{
        masToken: window.history.state.triageUserData.masToken,
        pinCode: window.history.state.triageUserData.pinCode,
        secQuestionAssociationList: [
          { answer: "", question: "" },
          { answer: "", question: "" },
          { answer: "", question: "" },
        ]
      };

      this.hasSecQuestions = window.history.state.triageUserData.hasSecQuestions;

      if (!this.hasSecQuestions) {
        this.selectionService.getSecurityQuestions().pipe(first()).subscribe(lst => {
          this.questionsList = lst.result![0].items;
        });
      }
    }
    else {
      this.router.navigate(['/home-page']);
    }
  }

  save() {
    if (this.ngForm.form.invalid) {   // check if form is valid 
      this.validateAllFormFields(this.ngForm.form);
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FORM]
      });
      return;
    }

    this.triagePortalUserService.changePasswordByPinCode(this.model).pipe(first()).subscribe(() => {
      this.router.navigate(['/home-page']);
    });
  }

  logout() {
    this.router.navigate(['/home-page']);
  }

  getFilteredQuestionsList(selected: string): SelectionItemViewModel[] {
    if (this.questionsList == null) return [];
    return this.questionsList.filter((question) => {
      if (this.selectedQuestionsList.indexOf(question.selectionValue) == -1 || question.selectionValue == selected)
        return true;
    });
  }

  private validateAllFormFields(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
