import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { IchsComponentDialogComponent, IchsComponentDialogData } from '../ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { CropperDialogComponent } from './cropper-dialog/cropper-dialog.component';
import { first } from 'rxjs/operators';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { IchsImageCropperConfig } from './image-cropper-config';

@Component({
  selector: 'ichs-image-cropper',
  templateUrl: './ichs-image-cropper.component.html',
  styleUrls: ['./ichs-image-cropper.component.css']
})
export class IchsImageCropperComponent implements OnInit {

  @Input() inputImage: string;
  @Input() cropperConfig: IchsImageCropperConfig = new IchsImageCropperConfig();
  @Output() imageCropped: EventEmitter<ImageCroppedEvent> = new EventEmitter<ImageCroppedEvent>();

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() { }

  openImageCropper() {
    let dialogConfig = <MatDialogConfig<IchsComponentDialogData>>{
      width: '600px',
      disableClose: true,
      data: {
        title: this.cropperConfig.cropperDialogTitle,
        component: CropperDialogComponent,
        hideCloseButton: true,
        extraData: this.cropperConfig,
      }
    };

    let dialogRef: MatDialogRef<IchsComponentDialogComponent, ImageCroppedEvent> = this.dialog.open(IchsComponentDialogComponent, dialogConfig);

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.imageCropped.emit(result);
      }
    });
  }
}

