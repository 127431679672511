import { NgModule } from '@angular/core';
import { SharedFoldersMaintenanceListComponent } from './shared-folders/shared-folders-maintenance-list/shared-folders-maintenance-list.component';
import { UserPermissionComponent } from './shared-folders/user-permission/user-permission.component';
import { UserSharedFoldersComponent } from './shared-folders/user-shared-folders/user-shared-folders.component';
import { MoveSharedFileComponent } from './shared-folders/move-shared-file/move-shared-file.component';
import { SharedFoldersMaintenanceComponent } from './shared-folders/shared-folders-maintenance/shared-folders-maintenance.component';
import { SecuredFilesComponent } from './secured-files/secured-files/secured-files.component';
import { MoveFileComponent } from './secured-files/move-file/move-file.component';
import { RouterModule } from '@angular/router';
import { CanActivateGuard, CanDeactivateGuard } from '../services/routing-guard.service';
import { AppModuleShared } from '../app.shared.module';
import { SendFileComponent } from './send-file/send-file.component';
import { ShareFileComponent } from './share-file/share-file.component';
import { SelectFileTypeDialogComponent } from './select-file-type-dialog/select-file-type-dialog.component';

@NgModule({
    exports: [],
    imports: [
        AppModuleShared,
        RouterModule.forChild([
            { path: 'securedfiles', component: SecuredFilesComponent },
            { path: 'securedfiles/:folderId', component: SecuredFilesComponent },
            { path: 'shared-folder-maintenance', component: SharedFoldersMaintenanceListComponent },
            { path: 'shared-folder-maintenance/:id', component: SharedFoldersMaintenanceComponent, canActivate: [CanActivateGuard], canDeactivate: [CanDeactivateGuard] },
            { path: 'user-shared-folders', component: UserSharedFoldersComponent },
            { path: 'user-shared-folders/:folderId', component: UserSharedFoldersComponent },
        ]),
    ],
    declarations: [
        SecuredFilesComponent,
        MoveFileComponent,
        MoveSharedFileComponent,
        SharedFoldersMaintenanceComponent,
        SharedFoldersMaintenanceListComponent,
        UserPermissionComponent,
        UserSharedFoldersComponent,
        MoveSharedFileComponent,
        SendFileComponent,
        ShareFileComponent,
        SelectFileTypeDialogComponent,
    ],
    providers: [CanActivateGuard, CanDeactivateGuard]
})
export class FileManagementModule { }
