import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APIS, Configuration, ApiModule } from './rest';
import { HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MedcorHttpInterceptor } from './app.request.Interceptor';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { AppComponent } from './components/app/app.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';

import { AppUserModule } from './appuser/appuser.module';
import { MedcorAlertService } from './services/medcor-alert.service';
import { MedcorPopUpService } from './services/medcor-popup.service';
import { ToastrModule } from 'ngx-toastr';
import { ReportingModule } from './reporting/reporting.module';
import { GroupingModule } from './grouping/grouping.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MedcorSignalRService, SIGNALR_BASE_PATH } from './services/medcor-signalr.service'
import { APISettings, SignalRSettings } from './app.general.constants';
import { ActiveUsersModule } from './active-users/active-users.module';
import { SecuredEmailModule } from './secured-email/secured-email.module';
import { MedcorAuthenticationService } from './services/medcor-authentication.service';
import { ApiConfiguration } from './services/api-configuration.service';

import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { MedcorUserInfoService, initUser } from './services/medcor-user-info.service';
import { HomePageComponent } from './components/home-page/home-page.component';
import { NgChatModule } from './ng-chat/ng-chat';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { ServiceRequestModule } from './service-request/service-request.module';
import { ChangeLogComponent } from './change-log/change-log.component';
import { IchsControlsModule } from './controls/ichs-controls.module';
import { GeneralVariablesService } from './services/general-variables.service';
import { CanDeactivateGuard } from './services/routing-guard.service';
import { InfoModule } from './info-components/info.module';
import { FileManagementModule } from './file-management/file-management.module';
import { RecoverPasswordComponent } from './components/recover-password/recover-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { AvatarModule } from 'ngx-avatars';
import { ExternalServiceModule } from './external-service/external-service.module';
import { SharedFunctionsService } from './services/shared-functions.service';
import { UserNotificationsComponent } from './components/user-notifications/user-notifications.component';
import { CareInstructionComponent } from './components/care-instruction/care-instruction.component';
import { AppLogModule } from './app-log/app-log.module';
import { ExternalCareInstructionComponent } from './components/external-care-instruction/external-care-instruction.component';
import { HideEmailPipe } from './controls/ichs-pipes/hideEmail.pipe';
import { ApplicationsAccessModule } from './applications-access/applications-access.module';
import { NotSupportedBrowserPopupComponent } from './components/not-supported-browser-popup/not-supported-browser-popup.component';
import { AppModuleShared } from './app.shared.module';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalModule } from '@azure/msal-angular';
import { msalConfig, loginRequest } from './auth-config';
import { ZendeskService } from './services/zendesk.service';
import { TriageCallReportModule } from './triage-call-report/triage-call-report.module';
import { ForgetPasswordFormComponent } from './components/forget-password-form/forget-password-form.component';

/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources 
 * added to protectedResourceMap. For more info, visit: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();

    return {
        interactionType: InteractionType.Popup, // not used, set just because it is required
        protectedResourceMap
    };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Popup, // not used, set just because it is required
        authRequest: loginRequest
    };
}


@NgModule({
    exports: [
        HideEmailPipe,
    ],
    declarations: [
        AppComponent,
        HomeComponent,
        LoginComponent,
        UserNotificationsComponent,
        HomePageComponent,
        ChangeLogComponent,
        RecoverPasswordComponent,
        ChangePasswordComponent,
        CareInstructionComponent,
        ExternalCareInstructionComponent,
        HideEmailPipe,
        NotSupportedBrowserPopupComponent,
        ForgetPasswordFormComponent,

    ],
    bootstrap: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        ToastrModule.forRoot(),
        BrowserAnimationsModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            cookieName: 'XSRF-TOKEN',
            headerName: 'X-XSRF-TOKEN'
        }),
        ApiModule,
        NgxPermissionsModule.forRoot(),
        AvatarModule,
        RouterModule.forRoot([
            { path: 'recover-password/:id', component: RecoverPasswordComponent },
            { path: 'change-password/:id', component: ChangePasswordComponent },
            { path: 'care-instruction', component: CareInstructionComponent },
            { path: 'care-instruction/:id', component: ExternalCareInstructionComponent },
            { path: 'medfiles-care-instruction', component: CareInstructionComponent },
            { path: 'medfiles-care-instruction/:id', component: ExternalCareInstructionComponent },
            { path: 'home-page', component: HomePageComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'change-log', component: ChangeLogComponent },
            { path: '', redirectTo: 'home-page', pathMatch: 'full' },
            { path: '**', redirectTo: 'home-page' },
        ], {}),
        AppUserModule,
        FileManagementModule,
        AppLogModule,
        ServiceRequestModule,
        ReportingModule,
        FormsModule,
        GroupingModule,
        InfoModule,
        ActiveUsersModule,
        SecuredEmailModule,
        TriageCallReportModule,
        NgProgressModule,
        NgProgressHttpModule,
        MatMenuModule,
        MatButtonModule,
        MatBadgeModule,
        NgChatModule,
        IchsControlsModule,
        ExternalServiceModule,
        ApplicationsAccessModule,
        AppModuleShared,
        MsalModule,
    ],
    providers: [
        APIS,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MedcorHttpInterceptor,
            multi: true,
        },
        {
            provide: SIGNALR_BASE_PATH,
            useValue: SignalRSettings.SIGNALR_BASE_PATH,
        },
        {
            provide: Configuration,
            useClass: ApiConfiguration,
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initUser,
            deps: [MedcorUserInfoService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory,
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory,
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        MedcorAlertService,
        MedcorAuthenticationService,
        MedcorUserInfoService,
        MedcorSignalRService,
        MedcorPopUpService,
        GeneralVariablesService,
        SharedFunctionsService,
        ZendeskService,
    ]
})
export class AppModule {
}

