<form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
  <div class="display-flex flex-items-centered">
    <div class="flex-child flex-basis-0 flex-grow-1">
      <ichs-advance-search [advanceSearchGridConfig]="userAdvSearchGridConfig" addExistingGridTitle="Select User Email" [(ngModel)]="userId" placeholder="Select User Email"
        required name="email" selectedTextAttribute="email" selectedValueAttribute="id" label="Email" class="fill-width"
        [defaultPopulatedText]="email"></ichs-advance-search>
    </div>
    <div class="flex-col-sep"></div>
    <div class="flex-child flex-basis-0 flex-grow-1" style="align-self:flex-end">
      <input #fileInput type="file" (change)="onFileSelected($event)" style="display:none" multiple>
      <button class="cp-btn cp-main-btn" (click)="fileInput.click()" style="margin-right:8px;">Select Files</button>
    </div>
  </div>

  <div class="files-cont" *ngIf="uploader.queue.length > 0">
    <div class="margin-top-def">
      <div *ngFor="let fileItem of uploader.queue; index as i" class="display-flex flex-items-centered file-item">
        <div class="flex-grow-1 flex-basis-0">{{fileItem._file.name}}</div>
        <div style="width:150px">
          <ichs-select required placeholder="Type" name="type_{{i}}" [items]="fileTypesListItem" [(ngModel)]="fileTypes[i]"></ichs-select>
        </div>
        <div>
          <button title="Remove File" class="cp-btn cp-chip-btn" (click)="removeFile(i)">
            <img class="remove-file-icon" src="assets/images/delete-cross.svg" />
          </button>
        </div>
      </div>
    </div>
  </div>
</form>

<div class="text-right fill-width margin-top-def margin-bottom-def" style="padding-top:20px;">
  <button *ngIf="!generalVariables.userImpersonationInfo.impersonatorRoleInd" mat-button class="cp-btn cp-main-btn"
    (click)="uploadFiles()">Send Files</button>
  <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">Close</button>
</div>