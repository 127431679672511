import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { MfaConstants, ValidationMessages } from 'src/app/app.general.constants';
import { AppGenericLists } from 'src/app/app.general.lists';
import { AccountsService, EnableAuthenticatorViewModel, SelectionItemViewModel } from 'src/app/rest';
import { AlertDismissType, AlertMessageType } from 'src/app/services/medcor-alert.service';
import { MedcorUserInfoService } from 'src/app/services/medcor-user-info.service';
import { MedcorAlertService } from '../../services/medcor-alert.service';
import { MedcorAuthenticationService } from '../../services/medcor-authentication.service';

@Component({
  selector: 'mfa-setup',
  templateUrl: './mfa-setup.component.html',
  styleUrls: ['./mfa-setup.component.css']
})
export class MfaSetupComponent implements OnInit {
  pageNum: number = 1;
  qrDataVM: EnableAuthenticatorViewModel = <EnableAuthenticatorViewModel>{};
  countryCodes: SelectionItemViewModel[] = AppGenericLists.CountryCode;
  cellPhone: string;
  phoneNbrVerficationCode: string;
  dateTobeEnforced: Date;
  emailVerification: boolean = false;
  emailVerificationCode: string = '';
  email: string ='';

  appVerMessage: string =`<br/><p>If the app displays a six-digit code, please enter it in the confirmation box below.</p>`;
  appVerTitle: string = 'Configure Mobile App';

  textVerMessage: string ='';
  textVerTitle: string = 'Cell Phone Number Verification';

  emailVerMessage: string ='';
  emailVerTitle: string = 'Email Verification';

  constructor(
    private accountsService: AccountsService,
    private alertService: MedcorAlertService,
    private authenticationService: MedcorAuthenticationService,
    private medcorUserInfoService: MedcorUserInfoService,
  ) { }

  ngOnInit() {
    this.accountsService.mFAEnforcedDate().pipe(first()).subscribe(resp=>{
      this.dateTobeEnforced = new Date(resp.result);
    });
    
    this.accountsService.getUserCellPhone().pipe(first()).subscribe(resp => {
      this.cellPhone = resp.result;
    });

    let loggedInUserInfo = this.medcorUserInfoService.getLoggedInUserInfo();
    this.emailVerification = (loggedInUserInfo.roleName == MfaConstants.MFA_EMAIL_ROLE) && loggedInUserInfo.sharedInboxEmail;
    if (!loggedInUserInfo.mfaAppEnabled && !this.emailVerification) {
   
      this.accountsService.getAuthenticatorQrData().pipe(first()).subscribe(resp => {
        this.qrDataVM = resp.result;
      });
    }

    if(this.emailVerification){
      this.email = loggedInUserInfo.userEmail;
    }
  }

  verifyAuthenticatorCode() {
    if (!this.qrDataVM.code) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please enter the verification code!"],
      });
      return;
    }

    this.accountsService.enableAuthenticator(this.qrDataVM.code).pipe(first()).subscribe(() => {
      this.setPageNum(6);
    });
  }

  updateCellPhone() {
    if (!this.cellPhone) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please enter your phone number!"],
      });
      return;
    }

    this.accountsService.updateUserCellPhone(this.cellPhone).pipe(first()).subscribe(() => {
      this.textVerMessage = `<p>We've sent a Text/SMS message to your phone at <span class="phone-nbr">(+1) ${this.cellPhone}</span></p>
      <p>When you receive the verification code enter it here</p>`;
      this.nextPage();
    });
  }

  verifySmsCode() {
    if (!this.phoneNbrVerficationCode) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please enter the verification code!"],
      });
      return;
    }

    this.accountsService.verifyCellPhoneByCode(this.phoneNbrVerficationCode).pipe(first()).subscribe(resp => {
      if (resp.result) {
        sessionStorage.clear();
        this.nextPage();
      }
      else {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: ["You failed to enter the correct code for 3 times! Please login again!"],
        });
        this.authenticationService.unAuthenticate();
      }
    });
  }


  resendVerificationCode(forEmail?:boolean) {
    if (forEmail)
      this.accountsService.resendEmailVerificationCode().pipe(first()).subscribe();
    else
      this.accountsService.resendCellPhoneVerificationCode().pipe(first()).subscribe();
  }
  
  sendEmailVerification() {
    this.accountsService.sendEmailVerfication().pipe(first()).subscribe(() => {
      this.emailVerMessage =`<p>We've sent a email message to <span>${this.email}</span></p>
      <p>When you receive the verification code enter it here</p>`
      this.nextPage();
    });
  }

  verifyEmailCode() {
    if (!this.emailVerificationCode) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please enter the verification code!"],
      });
      return;
    }

    this.accountsService.verifyEmailByCode(this.emailVerificationCode).pipe(first()).subscribe(resp => {
      if (resp.result) {
        sessionStorage.clear();
        this.nextPage();
      }
      else {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: ["You failed to enter the correct code for 3 times! Please login again!"],
        });
        this.authenticationService.unAuthenticate();
      }
    });
  }

  nextPage() {
    this.pageNum++;
  }

  previousPage() {
    this.pageNum--;
  }

  setPageNum(pageNum: number) {
    this.pageNum = pageNum;
  }
}
