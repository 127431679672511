import { Component } from '@angular/core';
import { AppUserService, SearchViewModel, UserSearchViewModel } from '../../rest';
import { VoidInfoViewModel } from '../../rest/model/voidInfoViewModel';
import { MedcorUserInfoService } from '../../services/medcor-user-info.service';
import { IchsGridComponentConfig, IchsControlType } from '../../controls/ichs-grid/grid-configs';
import { first } from 'rxjs/operators';
import { AppGenericLists } from '../../app.general.lists';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';

@Component({
  selector: 'app-appuser-list',
  templateUrl: './appuser-list.component.html',
  styleUrls: ['./appuser-list.component.css'],
  host: {
    class: "data-list"
  }
})

/** AppuserList component*/
export class AppuserListComponent {
  gridConfig: IchsGridComponentConfig<UserSearchViewModel>;

  allowAddAppUserInd: boolean;
  appUsersReadOnlyRestriction: boolean;

  /** AppuserList constructor*/
  constructor(
    private appUserService: AppUserService,
    private medcorUserInfoService: MedcorUserInfoService,
    private alertService: MedcorAlertService,
  ) {
    this.medcorUserInfoService.loggedInUserObs.pipe(first()).subscribe(value => {
      this.allowAddAppUserInd = value.allowAddAppUserInd;
      this.appUsersReadOnlyRestriction = value.appUsersReadOnlyRestriction;
    });
    this.gridConfig = this.defineGrid();
    this.appUserService.getRolesAsSelectionList().pipe(first()).subscribe(response => {
      this.gridConfig.headers[4].listData = response.result!;
    });
  }

  private defineGrid(): IchsGridComponentConfig<UserSearchViewModel> {
    let configs: IchsGridComponentConfig<UserSearchViewModel> = {
      primaryId: "id",
      defaultOrder: "lastName",
      title: "Application Users",
      entityController: "appuser",
      hasNew: this.allowAddAppUserInd && !this.appUsersReadOnlyRestriction,
      hasVoid: !this.appUsersReadOnlyRestriction,
      showVoided: true,
      loadOnInit: false,
      entityDataSource: (filter: SearchViewModel) => this.appUserService.searchUsers(filter),
      voidAction: (reason: VoidInfoViewModel) => this.appUserService._void(reason),
      recoverAction: (id: number) => this.appUserService.recover(id),
      secondAction: {
        icon: "assets/images/passwordStatus.svg",
        title: "Password Status",
        function: (user: UserSearchViewModel) => {
          this.appUserService.getUserPasswordStatus(user.id)
            .pipe(first())
            .subscribe(resp => {
              this.alertService.addAlert({
                type: AlertMessageType.info,
                title: 'Password Status:',
                dismiss: AlertDismissType.auto,
                messages: [resp.result],
              });
            });
        }
      },
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
          width: 250
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "First Name",
          propertyName: "firstName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Data Access",
          propertyName: "hasDataAccess",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Boolean
        },
        {
          headerTitle: "User Role",
          propertyName: "roleName",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.StaticSelect
        },
        {
          headerTitle: "Client Name",
          propertyName: "clientName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Status",
          propertyName: "status",
          searchable: true,
          sortable: true,
          listData: AppGenericLists.UserStatus,
          controlType: IchsControlType.StaticSelect,
        },
        {
          headerTitle: "Password Expired",
          propertyName: "passwordExpired",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Boolean,
        },
        {
          headerTitle: "Last Login",
          propertyName: "lastLoginTime",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
          nullable: true,
        }
      ]
    }
    return configs;
  }
}
