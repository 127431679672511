import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppModuleShared } from '../app.shared.module';
import { RouterModule } from '@angular/router';
import { ExternalFileRequestComponent } from './external-file-request/external-file-request.component';
import { TriageSecuredFileComponent } from './triage-secured-file/triage-secured-file.component';
import { TriageSecuredFilePincodeFormComponent } from './triage-secured-file/triage-secured-file-pincode-form/triage-secured-file-pincode-form.component';
import { AvatarModule } from 'ngx-avatars';
import { TriagePortalUserPincodeAccessComponent } from './triage-portal-user-pincode-access/triage-portal-user-pincode-access.component';
import { ExternalTriageSecuredFileComponent } from './external-triage-secured-file/external-triage-secured-file.component';
import { ExternalSignatureRequestComponent } from './external-signature-request/external-signature-request.component';
import { GrantAppAccessComponent } from './grant-app-access/grant-app-access.component';
import { ResponsiveComponent } from './responsive/responsive.component';

@NgModule({
    declarations: [
        TriageSecuredFileComponent,
        TriageSecuredFilePincodeFormComponent,
        ExternalTriageSecuredFileComponent,
        TriagePortalUserPincodeAccessComponent,
        GrantAppAccessComponent,
        ResponsiveComponent,
    ],
    imports: [
        CommonModule,
        AppModuleShared,
        AvatarModule,
        RouterModule.forRoot([
            { path: 'triageSecuredFile', component: TriageSecuredFileComponent },
            { path: 'external-triage-secured-file', component: ExternalTriageSecuredFileComponent },
            { path: 'fileRequests/:id', component: ExternalFileRequestComponent },
            { path: 'signatureRequests/:id', component: ExternalSignatureRequestComponent },
            { path: 'triage-portal-user-pincode-access', component: TriagePortalUserPincodeAccessComponent },
            { path: 'grant-app-access/:enc', component: GrantAppAccessComponent },
            { path: 'responsive', component: ResponsiveComponent },
        ], {}),
    ],
    exports: [
        TriageSecuredFileComponent,
        TriageSecuredFilePincodeFormComponent,
        ExternalTriageSecuredFileComponent,
        TriagePortalUserPincodeAccessComponent,
        GrantAppAccessComponent,
        ResponsiveComponent,
    ]
})
export class ExternalServiceModule { }
