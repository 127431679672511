import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'ichs-dialog',
    templateUrl: './ichs-dialog.component.html',
    styleUrls: ['./ichs-dialog.component.css']
})
//general dialog to be used with Mat-Dialog to show confirmation or messages to the user.
export class IchsDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<IchsDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: IchsDialogConfig
    ) { }

    ngOnInit() { }
}

export class IchsDialogConfig {
    title: string;
    message: string;
    okText?: string;
    cancelText?: string;
    hideOk?: boolean = false;
    hideCancel?: boolean = false;
    constructor(init?: Partial<IchsDialogConfig>) {
        Object.assign(this, init);
    }
}