import { Component, OnInit, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings, ValidationMessages, APISettings } from 'src/app/app.general.constants';
import { IchsControlType, IchsGridComponentConfig } from 'src/app/controls/ichs-grid/grid-configs';
import { AppUserService, MedcorResponse, PaginatedListViewModel, SearchViewModel, ShortUserViewModel, ContactViewModel, SelectionItemViewModel } from 'src/app/rest';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from 'src/app/services/medcor-alert.service';
import { EXTRA_DATA } from 'src/app/controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { FileUploader } from 'ng2-file-upload';
import { AppGenericLists } from '../../app.general.lists';
import { SharedFunctionsService } from '../../services/shared-functions.service';

@Component({
  selector: 'send-file',
  templateUrl: './send-file.component.html',
  styleUrls: ['./send-file.component.scss']
})
/** send-file component*/
export class SendFileComponent implements OnInit {
  email: string;
  userId: number;
  userAdvSearchGridConfig: IchsGridComponentConfig<ShortUserViewModel>;
  uploader: FileUploader;

  fileTypes: string[] = [];
  fileTypesListItem: SelectionItemViewModel[] = AppGenericLists.FileType;

  /** send-file ctor */
  constructor(
    private alertService: MedcorAlertService,
    private userService: AppUserService,
    public generalVariables: GeneralVariablesService,
    private sharedFunctions: SharedFunctionsService,
    @Inject(EXTRA_DATA) private sendTo: ContactViewModel,
  ) {
    if (sendTo) {
      this.email = sendTo.method;
      this.userId = sendTo.id;
    }
  }

  ngOnInit() {
    var URL: string = APISettings.API_BASE_PATH + "/SendFileToUser";
    this.uploader = new FileUploader({
      url: URL,
      authTokenHeader: "Authorization",
      isHTML5: true,
      itemAlias: "file",
      autoUpload: false,
    });

    this.uploader.onBuildItemForm = (item, form) => {
      let idx = this.uploader.queue.indexOf(item);
      form.append("receiverId", this.userId);
      form.append("type", this.fileTypes[idx]);
    }

    this.uploader.onCompleteAll = () => {
      this.uploader.clearQueue();
      this.fileTypes = [];

      this.alertService.addAlert({
        type: AlertMessageType.success,
        title: 'Success!',
        dismiss: AlertDismissType.controlled,
        messages: ["File(s) sent successfully!"]
      });
    }

    this.userAdvSearchGridConfig = this.defineUserAdvSearchGrid();
  }

  private defineUserAdvSearchGrid(): IchsGridComponentConfig<ShortUserViewModel> {
    let configs: IchsGridComponentConfig<ShortUserViewModel> = {
      primaryId: "id",
      defaultOrder: "email",
      title: "",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      entityController: null,
      multiSelectConfig: { allowSelectObjects: true },
      entityDataSource: (filter: SearchViewModel) => this.getUserAdvSearchFunction(filter),
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield
        }
      ]
    }
    return configs;
  }

  private getUserAdvSearchFunction(filter: SearchViewModel): Observable<MedcorResponse<PaginatedListViewModel<ShortUserViewModel>>> | PaginatedListViewModel<ShortUserViewModel> {
    return this.userService.searchShortUsers(filter);
  }


  onFileSelected(event: any) {
    var files = event.srcElement.files as File[];

    let _files: File[] = [];
    Array.prototype.forEach.call(files, (file: File) => {
      if (file.size > AppSettings.MAX_FILE_SIZE) {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.auto,
          messages: [ValidationMessages.FILE_SIZE_LIMIT_EXCEEDED]
        });
      }
      else if (!this.sharedFunctions.isSupportedFileType(file)) {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.auto,
          messages: [ValidationMessages.INVALID_FILETYPE]
        });
      }
      else {
        this.fileTypes.push(undefined);
        _files.push(file);
      }
    });

    if (_files.length > 0) {
      this.uploader.addToQueue(_files);
    }
  }

  uploadFiles() {
    if (!this.userId) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: [ValidationMessages.NO_RECIPIENT]
      });

      return;
    }

    if (this.uploader.queue.length == 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: ["You haven't selected any files to send!"]
      });

      return;
    }

    for (let i in this.fileTypes) {
      if (!this.fileTypes[i]) {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.auto,
          messages: ["Please select a type for each file!"]
        });
        return;
      }
    }

    this.uploader.uploadAll();
  }

  removeFile(index: number) {
    this.uploader.queue.splice(index, 1);
    this.fileTypes.splice(index, 1);
  }
}
