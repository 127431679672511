<div class="form-inline fill-width">
  <div class="impersonate-warning" *ngIf="(loggedInUserObs | async).isImpersonated">
    <span>You are running this report as {{(loggedInUserObs | async).fullUserName}}</span>
  </div>
  <div class="display-flex report-toolbar" [attr.disabled]="reportToolBarDisabled ? true : null">
    <div >
      <div mat-button [matMenuTriggerFor]="menu">
        <div title='Export' class='dropdown-toggle btn btn-default'>
          <img src="assets/images/reports/save.svg" class="report-tool-icon"/>
          <span class='caret'></span>
        </div>
      </div>
      <mat-menu #menu="matMenu">
        <div *ngFor="let option of exportOptions">
          <button mat-menu-item *ngIf="selectedExportOptions.indexOf(option.selectionValue) > -1" (click)="export(option.selectionValue)">{{option.selectionText}}</button>
        </div>
      </mat-menu>
    </div>
    <div >
      <span>
        <a id="refresh" title='Change Parameters' (click)="refresh()" class='btn btn-default Refresh'>
          <img src="assets/images/reports/refresh.svg" class="report-tool-icon"/>
        </a>
      </span>
    </div>
    <div  *ngIf="reportingParametersViewModel.downloadOnViewer == false">
      <span>
        <a title='Print' (click)="printAll()" class='btn btn-default Print'>
          <img src="assets/images/reports/print.svg" class="report-tool-icon"/>
        </a>
      </span>
    </div>
    <div>
      <span>
        <a title='Schedule Report' (click)="scheduleReport()" class='btn btn-default Schedule'>
          <img class="report-tool-icon" src="assets/images/reports/scheduleReportsInViewer.svg" />
        </a>
      </span>
    </div>
    <ng-container *ngIf="reportingParametersViewModel.downloadOnViewer == false">
      <div class="flex-basis-0 flex-grow-1"></div>
      <div class="search-block  display-flex flex-items-centered">
        <input class='form-control' type="text" (keydown)="searchEvent($event)" name="searchTerm" [(ngModel)]="searchTerm" />
        <a [style.display]="hideSearchButton ? 'none': null" class="cp-btn" title='Search (Enter)' (click)="search(true)">Search</a>
        <div [style.display]="!hideSearchButton ? 'none': null" class="display-flex align-items-center gap-s">
          <span style="margin-left: 5px; margin-right: 3px;white-space: nowrap;">{{currentMatchIdx + 1}} / {{searchMatchesCount}}</span>
          <a class="cp-btn search-btn" title='Previous (Shift + Enter)' (click)="search(false)"><img class="search-btn-icon" src="assets/images/newIcons/up-arrow.svg" /></a>
          <a class="cp-btn search-btn" title='Next (Enter)' (click)="search(true)"><img class="search-btn-icon" src="assets/images/newIcons/down-arrow.svg" /></a>
          <a class="cp-btn search-btn" title='Clear' (click)="clearAllSearchResults()"><img class="search-btn-icon" src="assets/images/cross.svg" /></a>
        </div>
      </div>
      <div class="flex-basis-0 flex-grow-1"></div>
      <div class="display-flex align-items-center gap-s">
        <a title='First Page' (click)="gotoFirstPage()" [class.disabled]="firstPageDisabled ? true : null" class='btn btn-default'>
          <img src="assets/images/reports/first.svg" class="report-tool-icon"/>
        </a>
        <a title='Previous Page' (click)="gotoPreviousPage()" [class.disabled]="previousPageDisabled ? true : null" class='btn btn-default'>
          <img src="assets/images/reports/prev.svg" class="report-tool-icon"/>
        </a>
        <div class="display-flex align-items-center gap-s"><input style="width:100px" [(ngModel)]="reportingRenderViewModel.pageNumber" (keydown.enter)="gotoPage()" type='number' class='form-control' /> of <span style="padding-right:5px">{{reportingRenderViewModel.pageCount}} </span></div>
        <a title='Next Page' (click)="gotoNextPage()" [class.disabled]="nextPageDisabled ? true : null" class='btn btn-default'>
          <img src="assets/images/reports/next.svg" class="report-tool-icon"/>
        </a>
        <a title='Last Page' (click)="gotoLastPage()" [class.disabled]="lastPageDisabled ? true : null" class='btn btn-default'>
          <img src="assets/images/reports/last.svg" class="report-tool-icon"/>
        </a>
      </div>
    </ng-container>
  </div>
  <div [hidden]="hideProgressSpinner" class="center" style="padding:15%;">
    <mat-spinner color='warn' style="margin:auto;"></mat-spinner>
    <p class="report-loader-txt">{{isGeneratingReport ? 'Generating Report...' : 'Preparing Parameters...'}}</p>
  </div>
  <div [class]="(loggedInUserObs | async).isImpersonated ? 'report-viewer-content report-viewer-content-impersonate' : 'report-viewer-content'">

    <h1 class="report-content report-downloaded-msg" *ngIf="reportFileDownloaded && hideProgressSpinner">
      Report file has been downloaded successfully
    </h1>

    <div class="report-content iframe-container" *ngIf="viewReportAsPdf && pdfReportUrl" [attr.disabled]="!hideProgressSpinner ? true : null">
      <iframe class="iframe-container" [src]="pdfReportUrl | safeUrl"></iframe>
    </div>

    <div *ngIf="!reportingParametersViewModel.downloadOnViewer" class="report-content" [class.report-content-table-collapse]="generalVariables.clientBrowserInfo.isChrome" [innerHTML]="safeHtml" [attr.disabled]="!hideProgressSpinner ? true : null"></div>
  </div>
</div>
