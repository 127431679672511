/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface LoggedUserViewModel { 
    userEmail?: string;
    fullUserName?: string;
    userInitials?: string;
    clientName?: string;
    lastLoggedInDate?: Date;
    firstLoginInd?: boolean;
    allowAddAppUserInd?: boolean;
    allowResetPasswordInd?: boolean;
    allowMaintainTriageUsers?: boolean;
    allowMaintainCalls?: boolean;
    allowScheduleReports?: boolean;
    appUsersReadOnlyRestriction?: boolean;
    appUserId?: number;
    userImageId?: number;
    roleName?: string;
    impersonatedUserRoleName?: string;
    isImpersonated?: boolean;
    passwordResetMessage?: string;
    impersonaterFullUserName?: string;
    impersonaterInitials?: string;
    impersonaterAppUserId?: number;
    impersonaterImageId?: number;
    acceptedUserAgreement?: boolean;
    pageDesignAccess?: boolean;
    userLookupAccess?: boolean;
    manageUsersAccess?: boolean;
    hasImpersonatePermission?: boolean;
    isAdminImpersonator?: boolean;
    isActiveDirectoryUser?: boolean;
    changePasswordInd?: boolean;
    triageUserMaintenanceAccessible?: boolean;
    hasBaseGroupCheckboxAccess?: boolean;
    hasRequestFileSignAccess?: boolean;
    hasChangeLogAccess?: boolean;
    mfaAppEnabled?: boolean;
    mfaTextEnabled?: boolean;
    mfaSharedEmailEnabled?: boolean;
    sharedInboxEmail?: boolean;
}
