<div class="display-flex flex-items-centered fill-parent email-item-cont"
    [ngClass]="{'opened':email.isRead, 'unread':!email.isRead}">
    <div style="padding-left: 5px;" class="email-to-list flex-basis-0 flex-grow-1 margin-right-def">{{email.senderName}}
    </div>
    <div class="flex-basis-0 flex-grow-1 margin-right-def">{{email.title}}</div>
    <div style="flex-basis:20px;" class="margin-right-def">
        <img src="assets/images/paper-clip.svg" *ngIf="email.hasAttachments" class="email-attchment-icon" />
    </div>
    <div class="flex-basis-0 flex-grow-1 margin-right-def">
        {{email.sentReceiveDate | date: 'MM/dd/yyyy, hh:mm a'}}
    </div>
    <div class="flex-basis-0 flex-grow-1 margin-right-def" *ngIf="mailBox != 'sent'">
        {{email.isSharedInboxMessage ? 'Yes' : 'No'}}
    </div>
    <div class="margin-right-def">
        <a class="cp-grid-btn" (click)="$event.stopPropagation()">
            <img mat-button [matMenuTriggerFor]="additionalActionsMenu" src="assets/images/menu-dots-vertical.svg"
                title="Additional Actions" />
        </a>
        <mat-menu #additionalActionsMenu="matMenu">
            <button mat-menu-item (click)="viewEmail()">View</button>
            <button mat-menu-item *ngIf="mailBox == 'inbox'" (click)="changeEmailFolder('Archive')">Archive</button>
            <button mat-menu-item *ngIf="mailBox == 'archive'" (click)="changeEmailFolder('Inbox')">Restore</button>
            <button mat-menu-item *ngIf="mailBox == 'inbox'" (click)="changeEmailFolder('Saved')">Move to Saved</button>
            <button mat-menu-item *ngIf="mailBox == 'saved'" (click)="changeEmailFolder('Inbox')">Move to Inbox</button>
            <button mat-menu-item (click)="mailBox != 'sent' ? deleteEmailFromInbox($event) : deleteSentEmail($event)">Delete</button>
        </mat-menu>
    </div>
</div>