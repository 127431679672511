<h2 mat-dialog-title *ngIf="data.title">
  {{data.title}}
</h2>
<mat-dialog-content>
  <ng-container *ngComponentOutlet="data.component; injector: ichsInjector"></ng-container>
</mat-dialog-content>
<mat-dialog-actions *ngIf="!data.hideCloseButton">
  <div class="flex-basis-0 flex-grow-1 text-right">
    <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">Close</button>
  </div>
</mat-dialog-actions>
