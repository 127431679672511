import { Component, Inject, OnInit, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { IchsDropDownTreeComponent, DROP_DOWN_TREE_LABEL_SELECT_DATE_RANGE } from 'src/app/controls/ichs-dropdown-tree/ichs-dropdown-tree.component';
import { DashboardSchedulerViewModel, MedcorResponse, SelectionTreeViewModel } from 'src/app/rest';
import { AdvancedDateFilterationAttributes, DashboardFilterationValues, DropdownTreeFilterationValues } from '../dashboard/dashboard.component';
import * as moment from 'moment';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from 'src/app/services/medcor-alert.service';
import { DashboardSchedulerService } from '../../rest/api/dashboardScheduler.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'dashboard-parameters-dialog',
  templateUrl: './dashboard-parameters-dialog.component.html',
  styleUrls: ['./dashboard-parameters-dialog.component.css']
})
export class DashboardParametersDialogComponent implements OnInit {
  config: DashboardParametersDialogConfig;
  advancedDateFilterationAttributes: AdvancedDateFilterationAttributes;
  @ViewChildren(IchsDropDownTreeComponent) allDropDownTrees: IchsDropDownTreeComponent[];
  allDropDownTreesExceptDateTree: IchsDropDownTreeComponent[];
  dateDropDownTree: IchsDropDownTreeComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DashboardParametersDialogConfig,
    public dialogRef: MatDialogRef<DashboardParametersDialogComponent>,
    private alertService: MedcorAlertService,
  ) {
    this.config = data;
    this.advancedDateFilterationAttributes = this.config.dateHierarchy.advancedDateFilterationAttributes;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.dateDropDownTree = this.allDropDownTrees.find(tree => tree.name == this.config.dateHierarchy.name);
    this.allDropDownTreesExceptDateTree = this.allDropDownTrees.filter(tree => tree.name != this.config.dateHierarchy.name);

    setTimeout(() => this.initializeDateHierarchyLabel());
  }

  submit() {
    if (!this.advancedDateFilterationAttributes.dateRange) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: ["Please set date range!"]
      });
      return;
    }

    let _dropdownTreeFilterationValues: DropdownTreeFilterationValues[] = [];

    for (let dropDownTree of this.allDropDownTreesExceptDateTree) {
      let selection = dropDownTree.getSelection();

      _dropdownTreeFilterationValues.push({
        treeName: dropDownTree.name,
        included: selection.included,
        excluded: selection.excluded,
      });
    }

    let dashboardParameters: DashboardFilterationValues;

    if (this.advancedDateFilterationAttributes.dateRange != "Custom") {
      dashboardParameters = <DashboardFilterationValues>{
        dateRange: this.advancedDateFilterationAttributes.dateRange,
        dropdownTreeFilterationValues: _dropdownTreeFilterationValues,
      };
    }
    else {
      dashboardParameters = <DashboardFilterationValues>{
        startDate: moment(this.advancedDateFilterationAttributes.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.advancedDateFilterationAttributes.endDate).format('YYYY-MM-DD'),
        dropdownTreeFilterationValues: _dropdownTreeFilterationValues,
      };
    }

    this.dialogRef.close(dashboardParameters);
  }

  onAdvancedDateFiltersChange(advancedDateFilterationAttributes: AdvancedDateFilterationAttributes) {
    if (advancedDateFilterationAttributes) {
      this.advancedDateFilterationAttributes = advancedDateFilterationAttributes;
    }
    else {
      this.advancedDateFilterationAttributes = <AdvancedDateFilterationAttributes>{};
      this.dateDropDownTree.selectionModel.selectedText = DROP_DOWN_TREE_LABEL_SELECT_DATE_RANGE;
    }
  }

  private initializeDateHierarchyLabel() {
    if (this.advancedDateFilterationAttributes.dateRange) {
      if (this.advancedDateFilterationAttributes.dateRange != "Custom") {
        this.dateDropDownTree.selectionModel.selectedText = this.advancedDateFilterationAttributes.dateRange;
      }
      else {
        this.dateDropDownTree.selectionModel.selectedText =
          moment(this.advancedDateFilterationAttributes.startDate).format('MM/DD/YYYY') +
          " - " +
          moment(this.advancedDateFilterationAttributes.endDate).format('MM/DD/YYYY');
      }
    }
  }
}

export class DashboardParametersDialogConfig {
  dateHierarchy: DashboardHierarchyConfig;
  secondHierarchy?: DashboardHierarchyConfig;
  thirdHierarchy?: DashboardHierarchyConfig;
}

export class DashboardHierarchyConfig {
  label: string;
  name: string;
  data?: SelectionTreeViewModel[];
  isLevel?: boolean;
  advancedDateFilterationAttributes?: AdvancedDateFilterationAttributes;
  loadChildrenFunction?: (node: SelectionTreeViewModel) => Observable<MedcorResponse<SelectionTreeViewModel[]>>;
}