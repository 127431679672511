import { Component, Input, OnInit, Output, EventEmitter, Renderer2 } from '@angular/core';
import { AppUserViewModel, ContactViewModel, FileRequestService, AppUserService, AccountsService, LoggedUserViewModel, UserContactViewModel, MedcorResponse, SecureFileService } from '../../rest/index';
import { APISettings, AppSettings } from '../../app.general.constants';
import { Router } from '@angular/router';
import { UserStatus, StatusDescription } from '../../ng-chat/ng-chat';
import { InputDialogConfig, IchsTextBox, IchsTextArea, IchsInputDialogComponent, IchsControl } from '../../controls/ichs-dialog/ichs-input-dialog/ichs-input-dialog.component';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { MedcorUserInfoService } from '../../services/medcor-user-info.service';
import { IchsDialogComponent } from '../../controls/ichs-dialog/ichs-dialog.component';
import { MedcorAuthenticationService } from '../../services/medcor-authentication.service';
import { Observable } from 'rxjs';
import { UserContactService } from '../../rest/api/userContact.service';
import { AlertMessageType, AlertDismissType, AlertPositionClassType, MedcorAlertService } from '../../services/medcor-alert.service';
import { IchsComponentDialogComponent } from 'src/app/controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { SendFileComponent } from 'src/app/file-management/send-file/send-file.component';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'appuser-card',
  templateUrl: './appuser-card.component.html',
  styleUrls: ['./appuser-card.component.css']
})
export class AppuserCardComponent implements OnInit {
  @Input() userContact: UserContactViewModel;
  @Input() status: UserStatus = UserStatus.Offline;
  @Input() allowSelect: boolean = false;
  @Output() openCard: EventEmitter<any> = new EventEmitter();
  @Output() openChat: EventEmitter<UserContactViewModel> = new EventEmitter();
  public selected: boolean = false;
  public isCardVisible: boolean = false;
  private unregister: Function;
  UserStatus = UserStatus;
  public cardX: number;
  public cardY: number;
  contactInfo: ContactViewModel = {};
  public regularEmail: string;
  publicImageUrl: string = APISettings.API_FILES_PATH;
  userImage: string;

  private readonly statusDescription: StatusDescription = {
    online: 'Online',
    busy: 'Busy',
    away: 'Away',
    offline: 'Offline'
  };

  get loggedInUserObs(): Observable<LoggedUserViewModel> { return this.medcorUserInfoService.loggedInUserObs }

  get userHasImage() {
    return this.userContact.appUserExtension.userImageId != null;
  }

  // get first char from firstname & lastname for avatar lib (this way, spaces in firstname or lastname won't affect the initials)
  get usernameInitials() {
    return this.userContact.firstName.charAt(0) + ' ' + this.userContact.lastName.charAt(0);
  }

  get statusText() {
    if (this.status == UserStatus.Online) {
      return this.statusDescription.online;
    }
    else if (this.status == UserStatus.Away) {
      return this.statusDescription.away;
    }
    else if (this.status == UserStatus.Busy) {
      return this.statusDescription.busy;
    }
    else {
      return this.statusDescription.offline;
    }
  }

  constructor(
    private renderer: Renderer2,
    private router: Router,
    private dialog: MatDialog,
    private fileRequestService: FileRequestService,
    private medcorUserInfoService: MedcorUserInfoService,
    private authenticationService: MedcorAuthenticationService,
    private accountsService: AccountsService,
    private userContactService: UserContactService,
    private alertService: MedcorAlertService,
    private secureFileService: SecureFileService,
  ) {
  }

  ngOnInit() {
    this.contactInfo.id = this.userContact.id;
    this.contactInfo.name = this.userContact.firstName + ' ' + this.userContact.lastName;
    this.contactInfo.method = this.userContact.email;
    this.regularEmail = "mailto:" + this.userContact.email;

    if (this.userContact.appUserExtension.userImageId) {
      this.secureFileService.downloadFile(this.userContact.appUserExtension.userImageId, 'events', true).subscribe(event => {
        if (event.type == HttpEventType.Response) {
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.userImage = event.target.result;
          }
          reader.readAsDataURL(event.body!);
        }
      });
    }
  }

  toggleCardVisibility(event: any): void {
    event.stopPropagation();
    this.isCardVisible = !this.isCardVisible;
    if (this.isCardVisible) {
      this.openCard.emit(this);
      this.unregister = this.renderer.listen('document', 'click', () => {
        this.isCardVisible = false;
        this.unregister();
      });
    }

    this.cardX = event.clientX - event.offsetX - (document.body.clientWidth - document.querySelector(".main-container").clientWidth) / 2 - 400;
    this.cardY = event.clientY - event.offsetY - 6;
  }

  hideCard() {
    this.isCardVisible = false;
  }

  favorite() {
    this.userContactService.favoriteContact(this.userContact.id).pipe(first()).subscribe(
      () => {
        this.userContact.isFavorite = !this.userContact.isFavorite;
        this.medcorUserInfoService.sortRelatedAppUsers();
      },
      err => {
        let errResponse = err.error as MedcorResponse<any>;
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: errResponse.messages,
          positionClass: AlertPositionClassType.topLeft,
        });
      }
    );
  }

  requestFile() {

    var dialogConfig: InputDialogConfig = new InputDialogConfig({
      title: "Request File",
      controls: [
        new IchsTextBox({
          label: "User Email",
          placeholder: "The email of the file owner",
          isEmail: true,
          required: true,
          value: this.userContact.email
        }),
        new IchsTextArea({
          label: "Description",
          placeholder: "Description of the file request"
        })
      ]
    });

    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: dialogConfig,
      width: "800px",
    });
    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result && result.length == 2 && result[0].value.length > 0) {
        this.fileRequestService.post({
          id: 0,
          title: result![1].value,
          email: result![0].value
        }).pipe(first()).subscribe(result => {
          this.router.navigate(['/file-request']);
        });
      }
    });
  }

  sendSecureFile() {
    this.dialog.open(IchsComponentDialogComponent, {
      disableClose: true,
      width: '800px',
      data: { component: SendFileComponent, title: "Send File", hideCloseButton: true, extraData: this.contactInfo }
    });
  }

  sendSecureEmail() {
    this.router.navigate(['/secureMessaging/new'], {
      queryParams: {
        recipient: JSON.stringify(this.contactInfo)
      }
    });
  }

  chat() {
    this.openChat.emit(this.userContact);
  }

  selectContact(event) {
    event.stopPropagation();
    this.selected = !this.selected;
  }

  Impersonate() {
    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Impersonate Contact ", message: "Are you sure about impersonating this contact?" }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.accountsService.impersonate(this.userContact.email).pipe(first()).subscribe(() => {
          this.authenticationService.impersonate();
          location.assign(AppSettings.UI_ORIGIN_PATH); // navigate & refresh
        })
      }
    });
  }

  deleteContact() {
    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Delete Contact", message: "Are you sure you want to delete this contact?" }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(
      result => {
        if (result) {
          this.userContactService.removeContact(this.userContact.id).pipe(first()).subscribe(result => {
            this.medcorUserInfoService.removeRelatedAppUsers(this.userContact.id);
          });
        }
      },
      err => {
        let errResponse = err.error as MedcorResponse<any>;
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: errResponse.messages,
          positionClass: AlertPositionClassType.topLeft,
        });
      }
    );
  }
}
