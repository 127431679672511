import { Injectable } from '@angular/core';
import { UserContactViewModel, UserCountersViewModel, UserSettingsViewModel } from '../rest';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AppuserCardComponent } from '../appuser/appuser-card/appuser-card.component';

@Injectable()
export class GeneralVariablesService {
  public appViewModel: AppComponentViewModel;
  public userAgreementPopupOpened: boolean = false;
  public firstCheck: boolean = true;
  public lineOfBusiness: string;
  public userLineOfBusinessList: string[] = [];
  public clientStatisticsPage: boolean = false;
  public userSettings: UserSettingsViewModel = <UserSettingsViewModel>{};
  public systemCounters: UserCountersViewModel = <UserCountersViewModel>{};
  private _showInjuryTriage = new BehaviorSubject<boolean>(false);
  private _showOnSiteClinic = new BehaviorSubject<boolean>(false);
  public readonly clientBrowserInfo: ClientBrowserInfo;
  public readonly clientDeviceInfo: ClientDeviceInfo = <ClientDeviceInfo>{};
  public userFiscalYearSettings: UserFiscalYearSettings = <UserFiscalYearSettings>{};
  public userImpersonationInfo: UserImpersonationInfo;
  public isActiveDirectoryUser: boolean;

  public inboxEmailsCountChanged: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public notificationEmailsCountChanged: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  get showInjuryTriageObs(): Observable<boolean> { return this._showInjuryTriage.pipe(distinctUntilChanged()); }
  get showOnSiteClinicObs(): Observable<boolean> { return this._showOnSiteClinic.pipe(distinctUntilChanged()); }

  get inboxEmailsCountChangeObs(): Observable<number> { return this.inboxEmailsCountChanged.asObservable(); }
  get notificationEmailsCountChangeObs(): Observable<number> { return this.notificationEmailsCountChanged.asObservable(); }

  constructor(
    private deviceService: DeviceDetectorService,
  ) {
    this._showInjuryTriage.next(false);
    this._showOnSiteClinic.next(false);

    this.appViewModel = new AppComponentViewModel();

    let deviceInfo = this.deviceService.getDeviceInfo();
    this.clientBrowserInfo = <ClientBrowserInfo>{
      browser: deviceInfo.browser,
      isIE: deviceInfo.browser == "IE",
      isChrome: deviceInfo.browser == "Chrome",
      isChromeBase: deviceInfo.browser == "Chrome" || deviceInfo.browser == "MS-Edge-Chromium" || deviceInfo.browser == "Safari" || deviceInfo.browser == "Firefox",
    };

    // call it once on initialization
    this.onWindowResize();

    // keep left menu expaned with no overlay in desktop mode only [CP-1994]
    this.appViewModel.leftIsHidden = !this.clientDeviceInfo.isDesktop;

    // add window resize event listener
    window.addEventListener('resize', () => this.onWindowResize());
  }

  updateInjuryTriage(show: boolean) {
    this._showInjuryTriage.next(show);
  }

  updateOnSiteClinic(show: boolean) {
    this._showOnSiteClinic.next(show);
  }

  updateSystemCounters(newCounters: UserCountersViewModel) {
    this.systemCounters = newCounters;
    
    if (this.systemCounters.inboxCounter != newCounters.inboxCounter) {
      this.inboxEmailsCountChanged.next(newCounters.inboxCounter);
    }
    else if (this.systemCounters.notificationCounter != newCounters.notificationCounter) {
      this.notificationEmailsCountChanged.next(newCounters.notificationCounter);
    }
  }

  onWindowResize() {
    if (window.innerWidth <= 500) {
      this.clientDeviceInfo.isDesktop = false;
      this.clientDeviceInfo.isTablet = false;
      this.clientDeviceInfo.isMobile = true;
    }
    else if (window.innerWidth <= 800) {
      this.clientDeviceInfo.isDesktop = false;
      this.clientDeviceInfo.isTablet = true;
      this.clientDeviceInfo.isMobile = false;
    }
    else {
      this.clientDeviceInfo.isDesktop = true;
      this.clientDeviceInfo.isTablet = false;
      this.clientDeviceInfo.isMobile = false;
    }
  }
}

export class UserImpersonationInfo {
  public readonly isImpersonatedInd: boolean;
  public readonly impersonaterUserRoleName: string;
  public readonly impersonatedUserRoleName: string;
  public readonly impersonatorRoleInd: boolean;
}

export class ClientBrowserInfo {
  public readonly browser: string;
  public readonly isIE: boolean;
  public readonly isChrome: boolean;
  public readonly isChromeBase: boolean;
}

export class ClientDeviceInfo {
  public isDesktop: boolean;
  public isTablet: boolean;
  public isMobile: boolean;
}

export class UserFiscalYearSettings {
  public startingDate?: number = 1;
  public startingMonth?: number = 0;
}

export class AppComponentViewModel {
  public leftIsHidden: boolean = false;
  public rightIsDisabled: boolean = false;
  public contactsIsHidden: boolean = false;
  public adminMenuIsHidden: boolean = false;
  public contactsWasHidden: boolean = false;
  public adminMenuWasHidden: boolean = false;
  public isNotificationsListVisible: boolean;
  public isUserCardOpen: boolean;
  public appuserCardsObs: Observable<UserContactViewModel[]>;
  public selectedCard: AppuserCardComponent;

  public toggleLeft(): void {
    this.leftIsHidden = !this.leftIsHidden;
  }

  public toggleRight(): void {
    if (!this.contactsIsHidden || !this.adminMenuIsHidden) {
      this.contactsWasHidden = this.contactsIsHidden;
      this.adminMenuWasHidden = this.adminMenuIsHidden;
      this.contactsIsHidden = true;
      this.adminMenuIsHidden = true;
    }
    else {
      this.contactsIsHidden = this.contactsWasHidden;
      this.adminMenuIsHidden = this.adminMenuWasHidden;
    }
  }

  public toggleContacts(): void {
    if (this.adminMenuIsHidden && !this.contactsIsHidden) {
      this.contactsWasHidden = this.contactsIsHidden;
      this.adminMenuWasHidden = this.adminMenuIsHidden;
    }
    this.contactsIsHidden = !this.contactsIsHidden;
  }

  public toggleAdminMenu(): void {
    if (this.contactsIsHidden && !this.adminMenuIsHidden) {
      this.contactsWasHidden = this.contactsIsHidden;
      this.adminMenuWasHidden = this.adminMenuIsHidden;
    }
    this.adminMenuIsHidden = !this.adminMenuIsHidden;
  }

  public toggleNotificationsList(): void {
    this.isNotificationsListVisible = !this.isNotificationsListVisible;
  }
}
