import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { HelpCategoryService, HelpCategoryViewModel, CommonQuestionViewModel, SelectionItemViewModel, CommonQuestionService } from '../../rest';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { GeneralVariablesService } from '../../services/general-variables.service';

@Component({
  selector: 'help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
/** help-center component*/
export class HelpCenterComponent implements OnInit {
  categoriesList: HelpCategoryViewModel[] = [];
  selectedCategoryId: number = 0;
  searchText: string = "";
  sub: Subscription;
  typeId: string;
  questionList: CommonQuestionViewModel[] = [];
  categoriesSelectionList: SelectionItemViewModel[] = [{ selectionValue: "0", selectionText: "All" }];
  filterCategoryId: string = "0";

  get filteredQuestionList(): CommonQuestionViewModel[] {
    var lowerSearchText = this.searchText.toLowerCase();
    return this.questionList.filter((question) => {
      if (lowerSearchText.length == 0 || question.question.toLowerCase().indexOf(lowerSearchText) >= 0 || question.answer.toLowerCase().indexOf(lowerSearchText) >= 0) {
        return true;
      }
    });
  }

  /** help-center ctor */
  constructor(
    private helpCatogryService: HelpCategoryService,
    private commonQesService: CommonQuestionService,
    private route: ActivatedRoute,
    public generalVariables: GeneralVariablesService,
  ) {
  }

  ngOnInit() {
    this.helpCatogryService.getUserCategories().pipe(first()).subscribe(res => {
      this.categoriesList = res.result;
      res.result.map(obj => {
        let item: SelectionItemViewModel = { selectionText: obj.name, selectionValue: obj.id.toString() };
        this.categoriesSelectionList.push(item);
      });
    });

    this.sub = this.route.queryParams.subscribe(params => {
      this.typeId = params['typeId'];
      if (this.typeId) {
        this.filterCategoryId = this.typeId;
        this.selectedCategoryId = +this.typeId;
      }

      if (params['searchText']) {
        this.searchText = params['searchText'];
      }

      this.getQuestions();
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  toggleSelectCategory(catId: number) {
    if (this.selectedCategoryId == catId) {
      this.clearFilter();
    }
    else {
      this.selectedCategoryId = catId;
      this.filterCategoryId = catId.toString();
      this.getQuestions();
    }
  }

  usefullAnswer(question: CommonQuestionViewModel, isUsefull: boolean) {
    question.isUseFulAnswer = true;
    this.commonQesService.addAnswerEvaluation({ answerId: question.id, isUsefullAnswer: isUsefull }).pipe(first()).subscribe();
  }

  clearFilter() {
    this.searchText = "";
    this.selectedCategoryId = 0;
    this.filterCategoryId = "0";
    this.getQuestions();
  }

  categoryChanged() {
    this.selectedCategoryId = +this.filterCategoryId;
    this.getQuestions();
  }

  getQuestions() {
    if (this.selectedCategoryId == 0) {
      this.commonQesService.getUserQuestions().pipe(first()).subscribe(res => {
        this.questionList = res.result;
      });
    }
    else {
      this.commonQesService.getQuestionsByCategory(this.selectedCategoryId).pipe(first()).subscribe(res => {
        this.questionList = res.result;
      });
    }
  }
}
