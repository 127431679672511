/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface SelectionTreeViewModel { 
    id?: string;
    label?: string;
    level?: string;
    namedPath?: string;
    selected?: boolean;
    partialSelection?: boolean;
    visible?: boolean;
    parentId?: string;
    leafInd?: boolean;
    visited?: boolean;
    termDate?: Date;
    children?: Array<SelectionTreeViewModel>;
}
