import { NgModule } from '@angular/core';
import { FileRequestComponent } from './file-request/file-request.component';
import { RouterModule } from '@angular/router';
import { AppModuleShared } from '../app.shared.module';
import { DownLoadRequestFileComponent } from './download-request-file/download-request-file.component';
import { NewServiceRequestComponent } from './new-service-request/new-service-request.component';
import { ServiceRequestListComponent } from './service-request-list/service-request-list.component';
import { CareInstructionFormComponent } from './care-instruction-form/care-instruction-form.component';

@NgModule({
    exports: [CareInstructionFormComponent],
    imports: [
        RouterModule.forChild([
            { path: 'file-request', component: FileRequestComponent },
            { path: 'service-request', component: ServiceRequestListComponent },
        ]),
        AppModuleShared
    ],
    declarations: [
        FileRequestComponent,
        ServiceRequestListComponent,
        DownLoadRequestFileComponent,
        NewServiceRequestComponent,
        CareInstructionFormComponent,
    ]
})
export class ServiceRequestModule {

}
