import { Component, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';

@Component({
    selector: 'validation-message',
    templateUrl: './validation-message.component.html',
    styleUrls: ['./validation-message.component.css']
})

//Validation component to show the validation message for the control.
export class ValidationMessageComponent implements OnChanges {  
    @Input() messages: Array<string> = null;
    validationMessage: string = (this.messages ? this.messages.join() : "");

    //Update the validatin message on validation messages changed.
    ngOnChanges(changes: SimpleChanges) {
        const changedMessages: SimpleChange = changes.messages;
        this.validationMessage = (changedMessages.currentValue ? changedMessages.currentValue.join() : "");
    }
}
