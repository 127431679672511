import { Component } from '@angular/core';
import { AlertMessageType, AlertDismissType, MedcorAlertService } from '../../services/medcor-alert.service';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'generate-password',
  templateUrl: './generate-password.component.html',
  styleUrls: ['./generate-password.component.css']
})
/** generate-password component*/
export class GeneratePasswordComponent {
  public password: string;
  /** generate-password ctor */
  constructor(
    private alertService: MedcorAlertService,
    private clipboardService: ClipboardService,
  ) {
    this.generate(12, true, true, true);
  }

  generate(length: number, useUpper: boolean, useNumbers: boolean, userSymbols: boolean) {
    var passwordLength = length || 8;
    var addUpper = useUpper || true;
    var addNumbers = useNumbers || true;
    var addSymbols = userSymbols || true;

    var lowerCharacters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    var upperCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    var numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    var symbols = ['!', '?', '@', '*', '$', '%', '&', '-', '_', '+', '~', '#', '^', '+'];

    var getRandom = function (array) {
      return array[Math.floor(Math.random() * array.length)];
    }

    var finalCharacters = "";

    if (addUpper) {
      finalCharacters = finalCharacters.concat(getRandom(upperCharacters));
    }

    if (addNumbers) {
      finalCharacters = finalCharacters.concat(getRandom(numbers));
    }

    if (addSymbols) {
      finalCharacters = finalCharacters.concat(getRandom(symbols));
    }

    for (var i = 1; i <= passwordLength - 3; i++) {
      finalCharacters = finalCharacters.concat(getRandom(lowerCharacters));
    }

    //shuffle!
    this.password = finalCharacters.split('').sort(function () {
      return 0.5 - Math.random()
    }).join('');
  }

  copyPassword() {
    let succeeded: boolean = this.clipboardService.copyFromContent(this.password);
    if (succeeded) {
      this.copySucceeded();
    }
  }

  copySucceeded() {
    this.alertService.addAlert({
      type: AlertMessageType.success,
      title: 'Coppied!',
      dismiss: AlertDismissType.auto,
      messages: ["Password coppied to clipboard"]
    });
  }
}
