import { Component, OnInit, Inject } from '@angular/core';
import { EXTRA_DATA, DIALOG_REF, IchsComponentDialogComponent } from '../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { SelectionItemViewModel } from '../../rest';
import { AppGenericLists } from '../../app.general.lists';
import { MatDialogRef } from '@angular/material/dialog';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from '../../services/medcor-alert.service';
import { FileItem } from 'ng2-file-upload';

@Component({
  selector: 'select-file-type-dialog',
  templateUrl: './select-file-type-dialog.component.html',
  styleUrls: ['./select-file-type-dialog.component.scss']
})
export class SelectFileTypeDialogComponent {

  fileTypesListItem: SelectionItemViewModel[] = AppGenericLists.FileType;

  constructor(
    @Inject(EXTRA_DATA) public extraData: SelectFileTypeDialogData,
    @Inject(DIALOG_REF) private dialogRef: MatDialogRef<IchsComponentDialogComponent>,
    public generalVariables: GeneralVariablesService,
    private alertService: MedcorAlertService,
  ) { }

  submit() {
    if (this.extraData.fileItems.length == 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: ["You haven't left any files to send!"]
      });

      this.dialogRef.close(false);
      return;
    }

    for (let fileType of this.extraData.fileTypes) {
      if (!fileType) {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.auto,
          messages: ["Please select a type for each file!"]
        });
        return;
      }
    }

    this.dialogRef.close(true);
  }

  removeFile(fileIdx: number) {
    this.extraData.fileItems.splice(fileIdx, 1);
    this.extraData.fileTypes.splice(fileIdx, 1);
  }
}

export class SelectFileTypeDialogData {
  fileItems: FileItem[];
  fileTypes: string[];
}