<h1 class="main-title">Scheduled Reports</h1>

<div *ngIf="userHasScheduleReportFullAccess" class="cont-padding">
  <ichs-select placeholder="Filter by Recipient" name="recipient" label="Recipient" [hasSearch]="true"
    [items]="recipientsItems" [(ngModel)]="recipientId"></ichs-select>
</div>

<div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab"
  [ngClass]="userHasScheduleReportFullAccess ? 'full-access-schedules-list' : 'schedules-list'">
  <ichs-grid [grid-config]="gridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
</div>