import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CdkTableModule } from '@angular/cdk/table';
import { IchsControlsModule } from './controls/ichs-controls.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ClipboardModule } from 'ngx-clipboard';
import { MomentModule } from 'ngx-moment';
import { NgScrollbarModule, NG_SCROLLBAR_OPTIONS } from 'ngx-scrollbar';
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [],
  providers: [
    {
      provide: NG_SCROLLBAR_OPTIONS,
      useValue: {
        visibility: "hover",
        appearance: "standard",
      }
    }],
  imports: [
    CommonModule,
    MomentModule,
    NgScrollbarModule,
    NgOptimizedImage,
  ],
  exports: [
    CommonModule,
    CdkTableModule,
    FormsModule,
    IchsControlsModule,
    MomentModule,
    NgxPermissionsModule,
    ClipboardModule,
    NgScrollbarModule,
    PdfViewerModule,
    NgOptimizedImage,
  ]
})
export class AppModuleShared {
}
