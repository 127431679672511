import { Component, ViewChild } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { first, switchMap } from 'rxjs/operators';
import { AccountsService, LoginViewModel, TriagePortalUserService, TriagePortalUserChangePasswordViewModel, AzureAdLoginViewModel } from 'src/app/rest';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { MedcorAuthenticationService } from '../../services/medcor-authentication.service';
import { NgForm } from '@angular/forms';
import { AlertMessageType, AlertDismissType, MedcorAlertService } from '../../services/medcor-alert.service';
import { IchsComponentDialogComponent } from '../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { UserAgreementComponent } from '../user-agreement/user-agreement.component';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { DeviceType } from '../../rest/model/deviceType';
import { MsalService } from '@azure/msal-angular';
import { ForgetPasswordFormComponent } from '../forget-password-form/forget-password-form.component';
import { IchsTextBoxComponent } from '../../controls/ichs-text-box/ichs-text-box.component';
import { AppSettings } from 'src/app/app.general.constants';
import { MedcorPopUpService, MedcorPopupConfig } from 'src/app/services/medcor-popup.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
/** Login component*/
export class LoginComponent {
  email: string = "";
  masToken: string;

  showTriageUserPinCodeForm: boolean = false;
  triageUserAdminEmail: string;
  triageUserPinCode: string;
  triagePortalUserDefaultAdminEmailUsed: boolean;

  isSharedInboxAccount: boolean = false;
  sharedInboxEmail: string;

  @ViewChild('form') ngForm: NgForm;
  @ViewChild('emailTextBox') emailTextBox: IchsTextBoxComponent;

  private masWindow: Window | null = null;

  /** Login ctor */
  constructor(
    private accountsService: AccountsService,
    private generalVariables: GeneralVariablesService,
    private deviceService: DeviceDetectorService,
    private authenticationService: MedcorAuthenticationService,
    private alertService: MedcorAlertService,
    private triagePortalUserService: TriagePortalUserService,
    private router: Router,
    private dialog: MatDialog,
    private msalService: MsalService,
    private popupservice: MedcorPopUpService,
  ) { }

  loginUsingAzureAd() {

    this.msalService.initialize()
      .pipe(first(), switchMap(() => this.msalService.loginPopup({
        prompt: "login",
        scopes: [],
      })))
      .subscribe(response => {
        let deviceInfo = this.deviceService.getDeviceInfo();

        // set the account used for sign in as active account, which will be used for sign out too
        this.msalService.instance.setActiveAccount(response.account);

        let azureAdLoginViewModel: AzureAdLoginViewModel = {
          azureAdToken: response.idToken,
          device: {
            deviceType: DeviceType.Browser,
            os: deviceInfo.os,
            browser: deviceInfo.browser,
            device: deviceInfo.device,
            osVersion: deviceInfo.os_version,
            browserVersion: deviceInfo.browser_version,
            isPrivateMachine: false,
          }
        }

        this.accountsService.loginUsingAzureAd(azureAdLoginViewModel)
          .pipe(first())
          .subscribe(resp => {
            if (!resp.result) {
              return;
            }

            this.authenticationService.authenticate();

            this.generalVariables.firstCheck = true;
            this.generalVariables.appViewModel.contactsIsHidden = false;
            this.generalVariables.lineOfBusiness = null;

            this.alertService.addAlert({
              type: AlertMessageType.info,
              title: 'Last Logged In Date',
              messages: [moment.utc(resp.result.lastLoggedInDate).local().format('MMMM DD YYYY, H:mm')]
            });

            if (resp.result.roleName == "Client User - Scheduled Reports Only") {
              this.router.navigate(['/securedfiles']);
            }
          });
      });
  }

  next() {
    if (this.ngForm.controls.email.invalid) {
      this.ngForm.controls.email.markAsTouched();

      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: ["Please enter your email"],
      });

      return;
    }

    this.accountsService.isSharedInboxAccount(this.email)
      .pipe(first())
      .subscribe(resp => {
        this.isSharedInboxAccount = resp.result;

        if (this.isSharedInboxAccount) {
          this.sharedInboxEmail = this.email;
          this.email = "";
          this.emailTextBox.focus();
        }
        else {
          this.masLogin();
        }
      });
  }

  masLogin() {
    let _email = this.email.includes("@") ? this.email : this.email + "@medcor.com";

    let _config = <MedcorPopupConfig>{
      fullscreen: false,
      width: 500,
      height: 700,
      additionalQueryParams: <any>{
        username: encodeURIComponent(_email)
      }
    };

    this.masWindow = this.popupservice.openPopup(`MedcorLoginPopup`, AppSettings.MAS_URL, _config);
    window.addEventListener('message', this.handleMedcorLoginPopupResponse.bind(this));
  }

  handleMedcorLoginPopupResponse(event: MessageEvent) {
    if (event.source === this.masWindow && event.data.startsWith("token:")) {
      this.masToken = (<string>event.data).replace("token:", "");
      let deviceInfo = this.deviceService.getDeviceInfo();

      this.accountsService.loginUsingMas({
        masToken: this.masToken,
        useCookieAuth: true,
        device: {
          deviceType: DeviceType.Browser,
          os: deviceInfo.os,
          browser: deviceInfo.browser,
          device: deviceInfo.device,
          osVersion: deviceInfo.os_version,
          browserVersion: deviceInfo.browser_version,
          isPrivateMachine: false,
        }
      }).pipe(first()).subscribe({
        next: (resp) => {
          if (!resp.result) {
            return;
          }

          if (resp.result.triagePortalUserPinCodeRequested) {
            this.triageUserAdminEmail = resp.result.triagePortalUserAdminEmail;
            this.triagePortalUserDefaultAdminEmailUsed = resp.result.triagePortalUserDefaultAdminEmailUsed;
            this.showTriageUserPinCodeForm = true;
            return;
          }

          this.authenticationService.authenticate();

          this.generalVariables.firstCheck = true;
          this.generalVariables.appViewModel.contactsIsHidden = false;
          this.generalVariables.lineOfBusiness = null;

          //show Last Login Data
          if (!(resp.result.firstLoginInd && resp.result.changePasswordInd)) {
            this.alertService.addAlert({
              type: AlertMessageType.info,
              title: 'Last Logged In Date',
              messages: [moment.utc(resp.result.lastLoggedInDate).local().format('MMMM DD YYYY, H:mm')]
            });
          }

          if (resp.result.roleName == "Client User - Scheduled Reports Only") {
            this.router.navigate(['/securedfiles']);
          }

          // popup window closed
          this.removeMedcorLoginEventListener();
        },
        error: () => {
          // popup window closed
          this.removeMedcorLoginEventListener();
        }
      });
    }
  }

  submitTriageUserPinCode() {
    if (this.ngForm.controls.triageUserPinCode.invalid) {
      this.ngForm.controls.triageUserPinCode.markAsTouched();

      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: ["Please enter the PIN Code that has been sent to your supervisor"],
      });

      return;
    }

    let objPost = <TriagePortalUserChangePasswordViewModel>{
      masToken: this.masToken,
      pinCode: this.triageUserPinCode,
    };

    this.triagePortalUserService.submitTriageUserPinCode(objPost).pipe(first()).subscribe(resp => {
      this.router.navigate(['/triage-portal-user-pincode-access'],
        {
          state: {
            triageUserData: {
              masToken: this.masToken,
              pinCode: this.triageUserPinCode,
              hasSecQuestions: resp.result,
            }
          }
        });
    });
  }

  openForgetPasswordForm() {
    this.dialog.open(IchsComponentDialogComponent, {
      disableClose: true,
      width: '600px',
      data: { component: ForgetPasswordFormComponent, hideCloseButton: true }
    });
  }

  showUserAgreement() {
    this.dialog.open(IchsComponentDialogComponent, {
      disableClose: true,
      width: '800px',
      data: { component: UserAgreementComponent, title: "User Agreement", hideCloseButton: false, extraData: true }
    });
  }

  private removeMedcorLoginEventListener() {
    window.removeEventListener('message', this.handleMedcorLoginPopupResponse.bind(this));
    this.masWindow = null;
  }
}
