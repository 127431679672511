import { Component, OnInit, OnDestroy } from '@angular/core';
import { FileItem, FileUploader } from 'ng2-file-upload';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { first } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { UserSecuredFileViewModel, UserFolderViewModel, UserSecuredFileService, MedcorResponse, SearchViewModel } from '../../../rest';
import { InputDialogConfig, IchsControl, IchsTextBox, IchsInputDialogComponent } from '../../../controls/ichs-dialog/ichs-input-dialog/ichs-input-dialog.component';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../../services/medcor-alert.service';
import { APISettings, ValidationMessages, AppSettings, FileTypesEnum } from '../../../app.general.constants';
import { IchsDialogComponent } from '../../../controls/ichs-dialog/ichs-dialog.component';
import { MoveFileComponent } from '../move-file/move-file.component';
import { GeneralVariablesService } from '../../../services/general-variables.service';
import * as moment from 'moment';
import { SharedFunctionsService } from '../../../services/shared-functions.service';
import { AdditionalAction, IchsControlType, IchsGridActionsConfig, IchsGridComponentConfig } from 'src/app/controls/ichs-grid/grid-configs';
import { AppGenericLists } from 'src/app/app.general.lists';
import { MedcorUserInfoService } from 'src/app/services/medcor-user-info.service';
import { LoggedUserViewModel } from 'src/app/rest/model/loggedUserViewModel';

@Component({
  selector: 'secured-files',
  templateUrl: './secured-files.component.html',
  styleUrls: ['./secured-files.component.css']
})
export class SecuredFilesComponent implements OnInit, OnDestroy {

  uploader: FileUploader;
  folderId: number | undefined = 1;
  files: UserSecuredFileViewModel[] = null;
  dropAreaFolderId: number;
  dropInFolderId: number;
  sub: Subscription;
  folder: UserFolderViewModel;
  pathName: string[] = [];
  pathId: string[] = [];
  newReceivedFilesCount: number = 0;
  newCallRecordingFilesCount: number = 0;
  callRecordingFilesCount: number = 0;
  newSecureReportFilesCount: number = 0;
  selectedFiles: number[] = [];
  dialogConfig: InputDialogConfig;
  controls: IchsControl[] = [];
  hasRequestFileSignAccess: boolean;
  clientUserScheduledReportsOnlyRoleInd: boolean;

  gridConfigReceivedFilesFolder: IchsGridComponentConfig<UserSecuredFileViewModel>;
  gridConfigReportsFolder: IchsGridComponentConfig<UserSecuredFileViewModel>;

  get loggedInUserObs(): Observable<LoggedUserViewModel> { return this.medcorUserInfoService.loggedInUserObs }

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private alertService: MedcorAlertService,
    public userSecuredFileService: UserSecuredFileService,
    public generalVariables: GeneralVariablesService,
    private sharedFunctions: SharedFunctionsService,
    private medcorUserInfoService: MedcorUserInfoService,
  ) { }

  ngOnInit() {

    this.loggedInUserObs.pipe(first()).subscribe(value => {
      this.hasRequestFileSignAccess = value.hasRequestFileSignAccess;
      this.clientUserScheduledReportsOnlyRoleInd = value.roleName == "Client User - Scheduled Reports Only";
    });

    this.gridConfigReceivedFilesFolder = this.defineReceivedFilesFolderGrid();
    this.gridConfigReportsFolder = this.defineReportsFolderGrid();

    let folderName: IchsTextBox = new IchsTextBox({
      label: "Folder Name",
      placeholder: "Enter folder name here",
      required: true,
      isEmail: false,
      maxLength: 200,
    });
    this.controls.push(folderName);
    this.dialogConfig = new InputDialogConfig({
      title: 'Create New Folder',
      controls: this.controls
    });

    var URL: string = APISettings.API_BASE_PATH + "/userfiles";
    this.uploader = new FileUploader({
      url: URL, isHTML5: true, itemAlias: "FileData", autoUpload: false, maxFileSize: AppSettings.MAX_FILE_SIZE,
    });

    this.uploader.onBuildItemForm = (item, form) => {
      form.append("IsFavorite", false);
      if (this.dropInFolderId) {
        form.append("folderId", this.dropInFolderId);
      } else if (this.folderId && this.folderId != 1) {
        form.append("folderId", this.folderId);
      }

      this.dropInFolderId = null;
    }

    this.uploader.onAfterAddingFile = ((fileItem: FileItem): any => {
      if (!this.sharedFunctions.isSupportedFileType(fileItem._file)) {
        this.uploader.removeFromQueue(fileItem);

        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.auto,
          messages: [ValidationMessages.INVALID_FILETYPE]
        });
      }
    });

    this.uploader.onCompleteAll = () => {
      if (!this.dropInFolderId) {
        this.getLoadData();
      }
      this.uploader.clearQueue();
    }

    this.uploader.onCompleteItem = ((_item, _response, _status, _headers): any => {
      if (_response) {
        let response: MedcorResponse<any> = JSON.parse(_response);
        this.alertService.handleMedcorResponse(response);
      }
    });

    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      if (filter.name == "fileSize") {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: [ValidationMessages.FILE_SIZE_LIMIT_EXCEEDED]
        });
      }
    }

    this.sub = this.route.paramMap.subscribe(params => {
      this.folderId = +params.get('folderId');
      this.folderId = !this.folderId ? 1 : this.folderId;
      this.getLoadData();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  getLoadData() {
    // reset old values first
    this.files = null;
    switch (this.folderId) {
      case 1:
        this.loadData();
        break;
      case 2:
        this.loadFavoriteFiles();
        break;
      case 3:
        break;
      case 4:
        this.loadCallRecordingFiles();
        break;
      case 5:
        break;
      default:
        this.loadData();
    }
  }

  loadFavoriteFiles() {
    this.userSecuredFileService.getFavoriteFiles().pipe(first()).subscribe(result => {
      this.files = result.result!;
    });
  }

  loadCallRecordingFiles() {
    this.userSecuredFileService.getCallRecordingFiles().pipe(first()).subscribe(result => {
      this.files = result.result!;
    });
  }

  loadData() {
    var obv: Observable<MedcorResponse<UserFolderViewModel>>;
    if (this.folderId && this.folderId != 1) {
      obv = this.userSecuredFileService.getListOfFiles(this.folderId);
    } else {
      obv = this.userSecuredFileService.getListOfFiles();
    }
    obv.pipe(first()).subscribe(result => {
      this.files = result.result!.filesList;

      this.folder = result.result!;
      this.newReceivedFilesCount = result.result!.newReceivedFilesCount;
      this.callRecordingFilesCount = result.result!.callRecordingFilesCount;
      this.newCallRecordingFilesCount = result.result!.newCallRecordingFilesCount;
      this.newSecureReportFilesCount = result.result!.newSecureReportFilesCount;
      if (this.folderId > 5) {
        this.pathId = this.folder.pathUnique.split("/");
        this.pathName = this.folder.path.split("/");
        this.pathId.shift();
        this.pathName.shift();
      } else {
        this.pathName = [];
        this.pathId = [];
      }
    });
  }

  durationFromNow(expirationDate: Date): string {
    var now = moment.utc();
    var end = moment.utc(expirationDate);
    var duration = moment.duration(end.diff(now));

    let _durationStr: string = null;

    if (duration.asDays() > 1) {
      _durationStr = Math.ceil(duration.asDays()) + " days";
    }
    else if (duration.asHours() > 11) {
      _durationStr = "less than a day";
    }
    else if (duration.asHours() > 1) {
      _durationStr = "few hours";
    }
    else {
      _durationStr = "less than an hour";
    }

    return _durationStr;
  }

  areaFileOver(event: boolean, folderId: number) {
    if (this.generalVariables.userImpersonationInfo.impersonatorRoleInd || this.clientUserScheduledReportsOnlyRoleInd) {
      return;
    }

    if (event && (folderId == 1 || folderId > 5)) {
      this.dropAreaFolderId = folderId;
    } else {
      this.dropAreaFolderId = null;
    }
  }

  routeToFolder(id: number) {
    this.router.navigate(["./securedfiles/" + id]);
  }

  dropFolder(folderId: number) {
    if (this.generalVariables.userImpersonationInfo.impersonatorRoleInd || this.clientUserScheduledReportsOnlyRoleInd) {
      return;
    }

    if (folderId > 5) {
      this.dropInFolderId = folderId;
    } else {
      this.dropInFolderId = null;
    }

    this.uploader.uploadAll();
  }

  getFolders() {
    return this.files ? this.files.filter(obj => obj.fileType == "Folder") : [];
  }

  getFiles() {
    return this.files ? this.files.filter(obj => obj.fileType != "Folder") : [];
  }

  downloadFile(file: UserSecuredFileViewModel, isReportFile: boolean = false) {
    let sub = this.userSecuredFileService.downloadFile(file.id, 'response', true)
      .subscribe(
        event => {
          if (event.type == HttpEventType.Response) {
            if (isReportFile) {
              this.saveReportToFileSystem(event, file);
            }
            else {
              this.sharedFunctions.saveToFileSystem(event);
            }

            file.isNew = false;
          }
        },
        () => { sub.unsubscribe() },
        () => { sub.unsubscribe() });
  }

  viewFile(file: UserSecuredFileViewModel) {
    let sub = this.userSecuredFileService.downloadFile(file.id, 'response', true)
      .subscribe(
        event => {
          if (event.type == HttpEventType.Response) {
            if (this.sharedFunctions.isViewableFile(event.body.type)) {
              var fileURL = URL.createObjectURL(event.body);
              window.open(fileURL);
            }
            else {
              this.sharedFunctions.saveToFileSystem(event);
            }
            file.isNew = false;
          }
        },
        () => { sub.unsubscribe() },
        () => { sub.unsubscribe() }
      );
  }

  renameFile(file: UserSecuredFileViewModel) {
    let renameDialogConfig: InputDialogConfig;
    let renameControls: IchsControl[] = [];

    let fileNewName: IchsTextBox = new IchsTextBox({
      required: true,
      value: file.fileName
    });

    renameControls.push(fileNewName);
    renameDialogConfig = new InputDialogConfig({
      hasTitle: false,
      message: "Please type in the new file name",
      controls: renameControls,
    });

    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: renameDialogConfig,
      width: "400px",
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        // copy "file" object so that file name in the ui won't change in case rename failed
        let fileToRename: UserSecuredFileViewModel = Object.assign({}, file)
        fileToRename.fileName = result[0].value!;
        this.userSecuredFileService.renameUserFile(fileToRename).pipe(first()).subscribe(returnVal => {
          if (returnVal && returnVal.result) {
            if (this.folderId == 3) {
              this.gridConfigReceivedFilesFolder.refreshGrid(this.gridConfigReceivedFilesFolder.getPage());
            }
            else if (this.folderId == 5) {
              this.gridConfigReportsFolder.refreshGrid(this.gridConfigReportsFolder.getPage());
            }
            else {
              let index = this.files.indexOf(file);
              this.files.splice(index, 1, returnVal.result);
            }
          }
        })
      }
    });
  }

  deleteFile(file: UserSecuredFileViewModel) {
    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Delete File ", message: "Are you sure you want to delete this file?" }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.userSecuredFileService.deleteUserFile(file.id, file.version).pipe(first()).subscribe(obj => {
          if (obj.result) {
            if (this.folderId == 3) {
              this.gridConfigReceivedFilesFolder.refreshGrid(this.gridConfigReceivedFilesFolder.getPage());
            }
            else if (this.folderId == 5) {
              this.gridConfigReportsFolder.refreshGrid(this.gridConfigReportsFolder.getPage());
            }
            else {
              let index = this.files.indexOf(file);
              this.files.splice(index, 1);
            }
          }
        });
      }
    });
  }

  renameFolder(folder: UserSecuredFileViewModel) {
    let folderRenamingDialogConfig = new InputDialogConfig({
      title: 'Rename Folder',
      controls: [
        new IchsTextBox({
          label: "Folder Name",
          placeholder: "Enter folder name here",
          required: true,
          isEmail: false,
          value: folder.fileName,
          maxLength: 200,
        })
      ],
      allowClose: (controls: IchsControl[]) => this.validateFolderNameForSlashes(controls),
    });

    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: folderRenamingDialogConfig,
      width: "400px",
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result && result.length > 0) {
        // copy "folder" object so that folder name in the ui won't change in case rename failed
        let folderToRename: UserSecuredFileViewModel = Object.assign({}, folder)
        folderToRename.fileName = result[0].value!;
        this.userSecuredFileService.renameUserFolder(folderToRename).pipe(first()).subscribe(res => {
          let index = this.files.indexOf(folder);
          this.files.splice(index, 1, res.result);
        });
      }
    });
  }

  deleteFolder(folder: UserFolderViewModel) {
    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Delete Folder ", message: "Are you sure about deleting this Folder?" }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.userSecuredFileService.deleteUserFolder(folder.id, folder.version).pipe(first()).subscribe(obj => {
          if (obj.result) {
            let index = this.files.indexOf(folder);
            this.files.splice(index, 1);
          }
        });
      }
    });
  }

  favorite(file: UserSecuredFileViewModel) {
    file.isFavorite = !file.isFavorite;
    this.userSecuredFileService.updateFavoriteFiles(file).pipe(first()).subscribe(result => {
      if (this.folderId == 3) {
        this.gridConfigReceivedFilesFolder.refreshGrid(this.gridConfigReceivedFilesFolder.getPage());
      }
      else if (this.folderId == 5) {
        this.gridConfigReportsFolder.refreshGrid(this.gridConfigReportsFolder.getPage());
      }
      else {
        let index = this.files.indexOf(file);
        if (this.folderId != 2) {
          this.files.splice(index, 1, result.result!);
        } else {
          this.files.splice(index, 1);
        }
      }
    });
  }

  requestSignature(file: UserSecuredFileViewModel) {
    let signDialogConfig: InputDialogConfig;
    let signControls: IchsControl[] = [];

    let phoneNumber: IchsTextBox = new IchsTextBox({
      required: true,
      value: '',
      inputMask: 'phone',
    });

    signControls.push(phoneNumber);
    signDialogConfig = new InputDialogConfig({
      hasTitle: false,
      message: "Please type in the phone number",
      controls: signControls,
    });

    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: signDialogConfig,
      width: "400px",
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.userSecuredFileService.sendForSign({ fileId: file.id, phoneNumber: result[0].value! }).pipe(first()).subscribe();
      }
    });
  }

  selectFile(id: number) {
    let index = this.selectedFiles.indexOf(id);
    if (index > -1) {
      this.selectedFiles.splice(index, 1);
    } else {
      this.selectedFiles.push(id);
    }
  }

  uploadSelectedFiles() {
    this.uploader.uploadAll();
  }

  addNewFolder() {
    this.dialogConfig.controls![0].value = "";  //reset the old name
    this.dialogConfig.allowClose = (controls: IchsControl[]) => this.validateFolderNameForSlashes(controls);

    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: this.dialogConfig,
      width: "400px",
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {

      if (result && result.length > 0) {
        let folder: UserFolderViewModel = {
          id: 0,
          name: result[0].value!,
          parentId: this.folderId ? this.folderId : -1,
          version: "",
          path: "",
          pathUnique: ""
        };
        this.userSecuredFileService.createNewFolder(folder).pipe(first()).subscribe(folderResult => {
          if (folderResult && folderResult.result) {
            this.getLoadData();
          }
        });

      }
    });
  }

  moveTo(file: UserSecuredFileViewModel) {

    let dialogRef: MatDialogRef<MoveFileComponent, number> = this.dialog.open(MoveFileComponent, {
      data: file,
      width: "800px"
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result && result > 0) {
        file.folderId = result;
        this.userSecuredFileService.moveFileTo(file).pipe(first()).subscribe(returnVal => {
          if (returnVal && returnVal.result) {
            if (this.folderId == 3) {
              this.gridConfigReceivedFilesFolder.refreshGrid(this.gridConfigReceivedFilesFolder.getPage());
            }
            else if (this.folderId == 5) {
              this.gridConfigReportsFolder.refreshGrid(this.gridConfigReportsFolder.getPage());
            }
            else {
              let index = this.files.indexOf(file);
              if (index > -1) {
                this.files.splice(index, 1);
              }
            }

            // if file moved to "Recorded Calls" Folder
            if (file.folderId == 4) {
              // icrement the count, to make the folder appear in case the count was 0 and the folder was hidden
              this.callRecordingFilesCount++;
            }
          }
        });
      }
    });
  }

  isCallRecording(file: UserSecuredFileViewModel) {
    return file.type == FileTypesEnum.CALL_RECORDING;
  }

  isPhiFile(file: UserSecuredFileViewModel) {
    return file.type == FileTypesEnum.PHI;
  }

  /**
   * Configure Received Files Folder Grid
   */
  private defineReceivedFilesFolderGrid(): IchsGridComponentConfig<UserSecuredFileViewModel> {
    let configs: IchsGridComponentConfig<UserSecuredFileViewModel> = {
      primaryId: "id",
      defaultOrder: "timeStampCreated",
      defaultOrderType: "desc",
      entityDataSource: (filter: SearchViewModel) => this.userSecuredFileService.searchReceivedFiles(filter),
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: true,
      hasVoid: false,
      hasActionColumn: true,
      hasAdditionalActionsMenu: true,
      preprocessData: (files: UserSecuredFileViewModel[]) => this.removeFileExtension(files),
      rowStyle: (file: UserSecuredFileViewModel) => file.isNew ? 'background-color: #f0f0f5; font-weight: bold' : null,
      additionalActions: [
        <AdditionalAction>{
          name: "Download",
          function: (file: UserSecuredFileViewModel) => this.downloadFile(file)
        },
        <AdditionalAction>{
          name: "Rename",
          function: (file: UserSecuredFileViewModel) => this.renameFile(file)
        },
        ...(
          !this.clientUserScheduledReportsOnlyRoleInd ? [<AdditionalAction>{
            name: "Move to",
            function: (file: UserSecuredFileViewModel) => this.moveTo(file)
          }] : []
        ),
        <AdditionalAction>{
          name: "Delete",
          function: (file: UserSecuredFileViewModel) => this.deleteFile(file)
        },
        <AdditionalAction>{
          dynamicName: (file: UserSecuredFileViewModel) => file.isFavorite ? "Remove from Favorite" : "Add to Favorite",
          function: (file: UserSecuredFileViewModel) => this.favorite(file)
        },
      ],
      detailAction: new IchsGridActionsConfig({
        title: "View",
        function: (file: UserSecuredFileViewModel) => this.viewFile(file)
      }),
      headers: [
        {
          headerTitle: "File Name",
          propertyName: "fileName",
          autoSearch: true,
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Type",
          propertyName: "type",
          width: 200,
          searchable: true,
          sortable: true,
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.FileType,
        },
        {
          headerTitle: "Date Received",
          propertyName: "timeStampCreated",
          width: 200,
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
        },
        {
          headerTitle: "Shared Inbox",
          propertyName: "isSharedInbox",
          width: 200,
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Boolean,
        },
      ],
    }
    return configs;
  }


  /**
   * Configure ReportsFolder Grid
   */
  private defineReportsFolderGrid(): IchsGridComponentConfig<UserSecuredFileViewModel> {
    let configs: IchsGridComponentConfig<UserSecuredFileViewModel> = {
      primaryId: "id",
      defaultOrder: "timeStampCreated",
      defaultOrderType: "desc",
      entityDataSource: (filter: SearchViewModel) => this.userSecuredFileService.searchReportsFolder(filter),
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: true,
      hasVoid: false,
      hasActionColumn: true,
      hasAdditionalActionsMenu: true,
      preprocessData: (files: UserSecuredFileViewModel[]) => this.removeFileExtension(files),
      rowStyle: (file: UserSecuredFileViewModel) => file.isNew ? 'background-color: #f0f0f5; font-weight: bold' : null,
      additionalActions: [
        <AdditionalAction>{
          name: "Download",
          function: (file: UserSecuredFileViewModel) => this.downloadFile(file, true)
        },
        <AdditionalAction>{
          name: "Rename",
          function: (file: UserSecuredFileViewModel) => this.renameFile(file)
        },
        ...(
          !this.clientUserScheduledReportsOnlyRoleInd ? [<AdditionalAction>{
            name: "Move to",
            function: (file: UserSecuredFileViewModel) => this.moveTo(file)
          }] : []
        ),
        <AdditionalAction>{
          name: "Delete",
          function: (file: UserSecuredFileViewModel) => this.deleteFile(file)
        },
        <AdditionalAction>{
          dynamicName: (file: UserSecuredFileViewModel) => file.isFavorite ? "Remove from Favorite" : "Add to Favorite",
          function: (file: UserSecuredFileViewModel) => this.favorite(file)
        },
      ],
      detailAction: new IchsGridActionsConfig({
        title: "View",
        function: (file: UserSecuredFileViewModel) => this.viewFile(file)
      }),
      headers: [
        {
          headerTitle: "Client Report Name",
          propertyName: "clientReportTitle",
          autoSearch: true,
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Medcor Report Name",
          propertyName: "fileName",
          autoSearch: true,
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Date Received",
          propertyName: "timeStampCreated",
          width: 200,
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
        }
      ],
    }
    return configs;
  }

  private saveReportToFileSystem(response: HttpResponse<Blob>, file: UserSecuredFileViewModel) {
    if (!response || !response.body) {
      return;
    }

    let fileDownloadName = `${file.fileName}_${new Date(file.timeStampCreated).toLocaleDateString()}`;
    if (file.clientReportTitle && file.clientReportTitle.length > 0) {
      fileDownloadName = `${file.clientReportTitle}_${new Date(file.timeStampCreated).toLocaleDateString()}`;
    }

    saveAs(response.body, fileDownloadName);
  }

  private validateFolderNameForSlashes(controls: IchsControl[]): boolean {
    if (controls![0].value.indexOf('/') > -1) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Folder name cannot contain slashes!"]
      });
      return false;
    }

    return true;
  }

  private removeFileExtension(files: UserSecuredFileViewModel[]) {
    files.forEach(file => {
      let idx = file.fileName.lastIndexOf('.');
      if (idx > -1) {
        file.fileName = file.fileName.substring(0, idx);
      }
    });
  }
}
