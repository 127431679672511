<div>
  <h1 class="main-title">About Client Portal</h1>
  <div class="cont-padding">

    <div>
      <a style="font-weight: bold;" href="https://www.medcor.com/privacy-policy/" target="_blank">Healthcare Privacy
        Policy</a>
    </div>
    <div style="font-weight: bold;">
      <h3 class="about-sub-title">User Agreement</h3>
    </div>
    <div>
      <ol class="about-list">
        <li>
          I am authorized by law to access this data, including applicable laws relating to the confidentiality of
          medical information.
        </li>

        <li>
          If I am accessing this data for worker’s compensation purposes, I certify that it is necessary for me to
          access this data to comply with laws relating to workers compensation or other similar programs, established
          by law, that provide benefits for work-related injuries or illnesses without regard to fault.
        </li>

        <li>
          I agree to view the data before downloading the data and if the data does not meet the requirements of 1-2
          above, I agree not to download the data. By downloading the data I affirmatively state that I have viewed the
          data and it meets the requirements of 1-2 above.
        </li>

        <li>
          I agree to follow all applicable State and Federal laws regulating the confidentiality of the data in this
          database, and I will not improperly disclose or distribute the data.
        </li>
      </ol>
    </div>
    <div style="font-weight: bold;">
      <h3 class="about-sub-title">Release Notes</h3>
    </div>
    <div style="margin-left: 27px">
      To read Client Portal release notes, please click <a (click)="viewReleaseNotes()" class="release-notes-btn">here</a>.
    </div>
    <br />
    <hr class="main-hr" />
    <div>
      Copyright © 2019 Medcor, Inc. All Rights Reserved - Powered by Medcor Technology
    </div>
  </div>

</div>