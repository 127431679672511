<mat-dialog-content>
    <div class="cont-padding email-container">
        <div class="dashboard-filter">{{config.dateHierarchy.label}}</div>
        <ichs-dropdown-tree [name]="config.dateHierarchy.name" class="flex-basis-0 flex-grow-1" [data]="config.dateHierarchy.data"
            [isLevel]="config.dateHierarchy.isLevel" [hideDataTree]="true" [customDateSelectionText]="true" 
            [showAdvanceDate]="true" [advancedDateFilterationAttributes]="advancedDateFilterationAttributes"
            (advancedDateFiltersChanged)="onAdvancedDateFiltersChange($event)"
            [loadChildren]="config.dateHierarchy.loadChildrenFunction">
        </ichs-dropdown-tree>
    </div>

    <div class="cont-padding email-container" *ngIf="config.secondHierarchy">
        <div class="dashboard-filter">{{config.secondHierarchy.label}}</div>
        <ichs-dropdown-tree [name]="config.secondHierarchy.name" class="flex-basis-0 flex-grow-1" [data]="config.secondHierarchy.data"
            [isLevel]="config.secondHierarchy.isLevel" [loadChildren]="config.secondHierarchy.loadChildrenFunction">
        </ichs-dropdown-tree>
    </div>

    <div class="cont-padding email-container" *ngIf="config.thirdHierarchy">
        <div class="dashboard-filter">{{config.thirdHierarchy.label}}</div>
        <ichs-dropdown-tree [name]="config.thirdHierarchy.name" class="flex-basis-0 flex-grow-1" [data]="config.thirdHierarchy.data"
            [isLevel]="config.thirdHierarchy.isLevel" [loadChildren]="config.thirdHierarchy.loadChildrenFunction">
        </ichs-dropdown-tree>
    </div>
</mat-dialog-content>

<mat-dialog-actions style="margin-top: 8px;">
    <div class="flex-basis-0 flex-grow-1 text-right">
        <button mat-button class="cp-btn cp-main-btn" (click)="submit()" cdkFocusInitial>Submit</button>
        <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">Cancel</button>
    </div>
</mat-dialog-actions>