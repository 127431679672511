import { Component, OnInit, Inject } from "@angular/core";
import { ReportViewHistoryViewModel, SearchViewModel, ReportingService, ReportItemViewModel } from "../../rest/index";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from "@angular/material/dialog";
import { HttpEventType } from "@angular/common/http";
import { IchsGridComponentConfig, IchsControlType } from "../../controls/ichs-grid/grid-configs";
import { AppGenericLists } from '../../app.general.lists';

@Component({
  selector: 'app-report-view-history',
  templateUrl: './report-view-history.component.html',
  styleUrls: ['./report-view-history.component.css'],
  host: { class: "fill-width" }
})
export class ReportViewHistoryComponent implements OnInit {

  reportItem: ReportItemViewModel = <ReportItemViewModel>{};
  gridConfig: IchsGridComponentConfig<ReportViewHistoryViewModel>;

  constructor(private _reportingService: ReportingService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ReportViewHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ReportItemViewModel
  ) {
    this.reportItem = data;
  }

  ngOnInit() {
    this.gridConfig = this.defineGrid();
  }

  private defineGrid(): IchsGridComponentConfig<ReportViewHistoryViewModel> {
    let configs: IchsGridComponentConfig<ReportViewHistoryViewModel> = {
      primaryId: "id",
      defaultOrder: "executionTime",
      defaultOrderType: "desc",
      title: "",
      entityController: "reporting",
      hasVoid: false,
      hasDetails: false,
      hasNew: false,
      hasSearch: false,
      entityDataSource: (filter: SearchViewModel) => this._reportingService.searchReportHistory(this.reportItem.id, filter),
      secondAction: {
        function: (obj: ReportViewHistoryViewModel) => {
          let sub = this._reportingService.downloadHistoryReport(obj.id, 'events', true).subscribe(event => {
            if (event.type == HttpEventType.Response) {
              var fileURL = URL.createObjectURL(event.body);
              window.open(fileURL);

            } else if (event.type == HttpEventType.DownloadProgress) {
              //TODO: implement progress.
            }
          }, undefined, () => { sub.unsubscribe() });
        },
        icon: 'assets/images/download.svg',
        title: 'View Report'
      },
      headers: [
        {
          headerTitle: "Date Ran",
          propertyName: "executionTime",
          searchable: false,
          sortable: true,
          dateFormat: 'MM/dd/yyyy, h:mm a',
          controlType: IchsControlType.Date,
        },
        {
          headerTitle: "Ran By",
          propertyName: "ranBy",
          searchable: false,
          sortable: false,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Parameters Used",
          propertyName: "parameters",
          searchable: false,
          sortable: false,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Type of Report",
          propertyName: "typeOfReport",
          searchable: false,
          sortable: true,
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.TypeOfReport,
        }
      ]
    }

    return configs;
  }
}
