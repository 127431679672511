import { Component, OnInit } from '@angular/core';
import { ChangeLogService, JiraSearch } from '../rest';
import { first } from 'rxjs/operators';
import { AppSettings } from '../app.general.constants';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.css']
})
export class ChangeLogComponent implements OnInit {

  changeLog: JiraSearch;
  releaseLabel: string = AppSettings.RELEASE_LABEL;

  constructor(private service: ChangeLogService) { }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.service.get(this.releaseLabel).pipe(first()).subscribe(result => {
      if (result) {
        this.changeLog = result.result;
      }
    });
  }
}
