import { Component, OnInit } from '@angular/core';
import { PageDesignViewModel, PageDesignService } from '../../rest';
import { first } from 'rxjs/operators';
import { LineOfBusiness } from '../../app.general.constants';
import { GeneralVariablesService } from 'src/app/services/general-variables.service';
import { IframeDialogComponent } from '../../controls/ichs-dialog/iframe-dialog/iframe-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'injury-triage-info',
  templateUrl: './injury-triage-info.component.html',
  styleUrls: ['./injury-triage-info.component.css']
})
/** InjuryTriageInfo component*/
export class InjuryTriageInfoComponent implements OnInit {
  triagePage: PageDesignViewModel;

  topLink: string;
  leftLink: string;
  centerLink: string;
  rightLink: string;

  /** InjuryTriageInfo ctor */
  constructor(
    private pageDesignService: PageDesignService,
    private generalVariables: GeneralVariablesService,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit() {

    if (this.generalVariables.userImpersonationInfo.impersonaterUserRoleName != 'Impersonator') {
      if (this.generalVariables.isActiveDirectoryUser && this.generalVariables.userLineOfBusinessList.indexOf('Triage') > -1) {
        this.generalVariables.updateInjuryTriage(true);
      }
    }

    this.generalVariables.updateOnSiteClinic(false);

    this.pageDesignService.getPageDesignByLineOfBusiness(LineOfBusiness.TRIAGE).pipe(first()).subscribe(result => {
      this.triagePage = result.result;

      this.extractLink("top", "topLink");
      this.extractLink("bottomLeft", "leftLink");
      this.extractLink("bottomCentral", "centerLink");
      this.extractLink("bottomRight", "rightLink");
    });
  }

  ngOnDestroy() {
    this.generalVariables.updateInjuryTriage(false);
    this.generalVariables.updateOnSiteClinic(false);
  }

  extractLink(source: string, variable: string) {
    if (this.triagePage[source].startsWith("###")) {
      var lines = this.triagePage[source].split("\n");
      this[variable] = lines.splice(0, 1)[0].split("###")[1];
      this.triagePage[source] = lines.join("\n");
    }
  }

  linkClicked(event, link) {
    event.stopPropagation();
    if (!link || link.length == 0) {
      return;
    }
    this.dialog.open(IframeDialogComponent,
      {
        data: { title: "", link: link },
        height: "780px"
      });
  }
}
