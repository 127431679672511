<div class="cp-dialog">
  <h2 mat-dialog-title>{{title}}</h2>
  <mat-dialog-content >
    <div class="display-flex flex-basis-0 flex-grow-1" style="height:100%">
      <ichs-grid [grid-config]="gridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex-basis-0 flex-grow-1 text-right">
      <button mat-button (click)="AddExisting()" cdkFocusInitial class="cp-btn cp-main-btn">Add Selected</button>
      <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">Close</button>
    </div>

  </mat-dialog-actions>
</div>
