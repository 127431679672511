import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlBase } from '../control-base';
import { NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';

@Component({
  selector: 'code-verification',
  templateUrl: './code-verification.component.html',
  styleUrls: ['./code-verification.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CodeVerificationComponent,
    multi: true,
}],
})
export class CodeVerificationComponent extends ControlBase<string> implements OnInit {
  @ViewChild(NgModel, { static: true }) model: NgModel;

  @Input('title') title: string;
  @Input('message') message: string;
  @Input("hasResendCode") hasResend: boolean;

  @Output() verifyClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancelClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() resendCode: EventEmitter<string> = new EventEmitter<string>();
  
  public identifier = `ichs-text-boxt-${identifier++}`;
  ngOnInit(): void {
    super.ngOnInit();
  }

  verifyButtonClicked() {
    this.verifyClicked.emit(this.model.value);
  }

  cancelButtonClicked(){
    this.cancelClicked.emit(null);
  }

  resendCodeClicked(){
    this.resendCode.emit(null);
  }

}
let identifier = 0;


