<h2 mat-dialog-title>Manage Security Question</h2>
<mat-dialog-content>
  <div >
    <form #form="ngForm" novalidate>
      <div class='display-flex break-la'>
        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
          <ichs-password name="currentPassword" [(ngModel)]="model.currentPassword" required
                          label="Current Password"></ichs-password>
        </div>

        <div class="flex-col-sep"></div>
        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">

        </div>
      </div>
      <div class='display-flex break-la' *ngFor="let secQuestion of model.secQuestionAssociationList,let i = index">
        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
          <ichs-select label="Security Question {{i + 1}}"
                        name="securityQuestionSelect{{i}}"
                        placeholder="empty Item"
                        [items]="getFilteredQuestionsList(secQuestion.question)"
                        [(ngModel)]="secQuestion.question"
                        required></ichs-select>
        </div>

        <div class="flex-col-sep"></div>

        <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
          <ichs-text-box label="Answer" name="securityQuestionAnswer{{i}}" [(ngModel)]="secQuestion.answer" required></ichs-text-box>
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-basis-0 flex-grow-1 text-right">
    <button mat-button class="cp-btn cp-main-btn" (click)="save()">Save</button>
    <button mat-button class="cp-btn cp-mail-btn" (click)="close()">Cancel</button>
  </div>
</mat-dialog-actions>
