<div class="cont-padding flex-basis-0 flex-grow-1">
    <div class="border-bottom">
      <ichs-command-toolbar [model]="model"></ichs-command-toolbar>
    </div>
    <form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1 display-grid tabs-cont">
      <mat-tab-group class="user-details-tabs" [(selectedIndex)]="model.selectedTab">
        <mat-tab label=" User Detail">
          <ichs-collapsable-panel panelTitle="App User Details">
            <div class="collapsableBody">
              <div class="display-flex break-la">
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                  <ichs-text-box [disabled]="true" name="email" [(ngModel)]="model.objModel.email" label="Email">
                  </ichs-text-box>
                </div>
  
                <div class="flex-col-sep"></div>
  
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                  <ichs-text-box [disabled]="true" type="text" name="firstName" [(ngModel)]="model.objModel.firstName"
                    label="First Name">
                  </ichs-text-box>
                </div>
  
                <div class="flex-col-sep"></div>
  
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                  <ichs-text-box [disabled]="true" type="text" name="middleName" [(ngModel)]="model.objModel.middleName"
                    label="Middle Initial"></ichs-text-box>
                </div>
              </div>
              <div class="display-flex break-la">
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                  <ichs-text-box [disabled]="true" name="lastName" [(ngModel)]="model.objModel.lastName"
                    label="Last Name"></ichs-text-box>
                </div>
  
                <div class="flex-col-sep"></div>
  
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                  <ichs-text-box [disabled]="true" name="roleName" label="User Role"
                    [(ngModel)]="model.objModel.roleName"></ichs-text-box>
                </div>
  
                <div class="flex-col-sep"></div>
  
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">

                </div>
              </div>
            </div>
          </ichs-collapsable-panel>
        </mat-tab>  
      </mat-tab-group>
    </form>
  </div>
  