import { Injectable } from '@angular/core';

//a service to handle openning and closing window popups in the system for details
@Injectable()
export class MedcorPopUpService {

  private _lstPopUps: Map<string, Window> = new Map();
  //default configuration in general
  private defaultConfig: MedcorPopupConfig = new MedcorPopupConfig();

  openPopup(key: string, url: string, options: MedcorPopupConfig = new MedcorPopupConfig()): Window {
    let config = { ...this.defaultConfig, ...options }; //merge options, the default with the passed ones

    var winFeature: string =
      'location=' + this.yesNo(config.location) + ',toolbar=' + this.yesNo(config.toolbar) + ',menubar=' + this.yesNo(config.menubar) +
      ',scrollbars=' + this.yesNo(config.scrollbar) + ',status=' + this.yesNo(config.status) + ',resizable=' + this.yesNo(config.resizable) +
      ',top=' + config.top.toString() + ',left=' + config.left.toString() + ',fullscreen=' + this.yesNo(config.fullscreen) + ',' +
      'width=' + (config.fullscreen ? screen.width.toString() : config.width.toString()) +
      ', height=' + (config.fullscreen ? screen.height.toString() : config.height.toString());

    var _wind: Window | undefined = this._lstPopUps.get(key);
    if (_wind == undefined || _wind.closed) {
      var newWindow: Window | null;
      var urlWithParam = url + "?popupmode=true&key=" + key + (config.title != null ? "&title=" + config.title : '');

      for(let key in config.additionalQueryParams){
        urlWithParam += `&${key}=${config.additionalQueryParams[key]}`;
      }

      if (config.inTab)
        newWindow = window.open(urlWithParam, key);
      else
        newWindow = window.open(urlWithParam, key, winFeature);

      if (newWindow) {
        this._lstPopUps.set(key, newWindow);
      }

      return newWindow;
    } else {
      alert("Entity already openned in a popup page");
    }
  }

  //A function to close all the opened windows
  closeAll() {
    this._lstPopUps.forEach((_wind, key) => {
      if (_wind != undefined && !_wind.closed) _wind.close();
    });
    this._lstPopUps.clear();
  }

  //Close a single window.
  closeWindow(key: string) {
    var _wind: Window | undefined = this._lstPopUps.get(key);
    if (_wind != undefined && !_wind.closed)
      _wind.close();
  }

  private yesNo(val: boolean) {
    return val ? "yes" : "no";
  }

}

//A Class to hold all the options related to opening window
export class MedcorPopupConfig {
  public location: boolean = false;
  public toolbar: boolean = false;
  public menubar: boolean = false;
  public scrollbar: boolean = true;
  public status: boolean = false;
  public resizable: boolean = true;
  public top: number = 0;
  public left: number = 0;
  public fullscreen: boolean = true;
  public width: number = 0;
  public height: number = 0;
  public inTab: boolean = false;
  public title: string;
  public additionalQueryParams: { [key: string]: string; } = {};
  public constructor() {

  }
}
