
<mat-dialog-content>
  <iframe *ngIf="!isYouTube" [width]="data.width?data.width:1024" [height]="data.height?data.height:640" [src]="data.link | safeUrl"
          frameborder="0" allowfullscreen></iframe>
  <iframe *ngIf="isYouTube"  [width]="data.width?data.width:1024" [height]="data.height?data.height:640" [src]="data.link | safeUrl"
          frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen></iframe>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-basis-0 flex-grow-1 text-right">
    <button mat-button mat-dialog-close cdkFocusInitial class="cp-btn cp-main-btn">Close</button>
  </div>

</mat-dialog-actions>
