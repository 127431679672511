<div class="cont-padding flex-basis-0 flex-grow-1">
  <div class="border-bottom">
    <ichs-command-toolbar [model]="model"></ichs-command-toolbar>
  </div>
  <form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1 display-grid tabs-cont">
    <mat-tab-group mat-align-tabs="start"  mat-stretch-tabs="false" class="user-details-tabs" [(selectedIndex)]="model.selectedTab" (selectedTabChange)="onSelectedTabChange($event)">
      <mat-tab label="User Detail">
        <ichs-collapsable-panel panelTitle="App User Details">
          <div class="collapsableBody">
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [disabled]="appUsersReadOnlyRestriction" initialFocus="true" name="email" [(ngModel)]="model.objModel.email" maxlength="256" required email label="Email"
                               [hasButton]="true" (buttonClicked)="fetchActiveDirectoryUserInfo($event)" buttonIcon="assets/images/active-directory.svg" iconTitle="Active Directory"></ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>

              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [disabled]="appUsersReadOnlyRestriction" type="text" name="firstName" [(ngModel)]="model.objModel.firstName" maxlength="50" required label="First Name"></ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>

              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [disabled]="appUsersReadOnlyRestriction" type="text" name="middleName" [(ngModel)]="model.objModel.middleName" maxlength="1" label="Middle Initial"></ichs-text-box>
              </div>
            </div>
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [disabled]="appUsersReadOnlyRestriction" name="lastName" [(ngModel)]="model.objModel.lastName" maxlength="50" required label="Last Name"></ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>

              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-select [disabled]="appUsersReadOnlyRestriction" name="roleName" label="Role Name" [(ngModel)]="model.objModel.roleName" required [items]="roleItems" (selectionChanged)="onRoleChange()"></ichs-select>
              </div>

              <div class="flex-col-sep"></div>

              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-check-box [disabled]="appUsersReadOnlyRestriction" name="ActiveDirectoryUserInd" [(ngModel)]="model.objModel.activeDirectoryUserInd" label="Active Directory" style="align-self:flex-end;"></ichs-check-box>
              </div>
            </div>

            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <ichs-select [disabled]="appUsersReadOnlyRestriction" name="status" label="Status" [(ngModel)]="model.objModel.status" [items]="statusList" hideResetLabel="true"
                  (selectionChanged)="statusChanged($event)">
                </ichs-select>
              </div>

              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [disabled]="appUsersReadOnlyRestriction" [(ngModel)]="model.objModel.appUserExtension!.clientName" name="ClientName" label="Client Name" required></ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <ichs-select name="userGroups" label="User Groups" [(ngModel)]="model.objModel.userGroupList" multiple="true" hasSelectAll="true" hasSearch="true"
                             [items]="groupItems" [readOnly]="(loggedInUserObs | async).roleName != 'Admin' || appUsersReadOnlyRestriction" (selectionChanged)="userGroupsChanged = true"
                             class="flex-grow-1">
                </ichs-select>
                <div class="groups-btn">
                  <img src="assets/images/list.svg" (click)="viewUserGroupsGrid()" class="field-icon" title="View Groups" />
                </div>
              </div>
            </div>

            <div class="display-flex align-items-center break-la check-boxes-cont">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <ichs-check-box [disabled]="appUsersReadOnlyRestriction" name="phiDataRestriction" [(ngModel)]="model.objModel.appUserExtension!.phiDataRestrictionInd"
                  label="Block Personal Health Information" class="flex-grow-1"></ichs-check-box>

                <div class="flex-col-sep"></div>

                <ichs-check-box [disabled]="appUsersReadOnlyRestriction" name="appUsersReadOnlyRestriction" [(ngModel)]="model.objModel.appUserExtension!.appUsersReadOnlyRestriction"
                  label="Read Only Access" class="flex-grow-1"></ichs-check-box>
              </div>
              <div class="flex-col-sep"></div>

              <ng-container *ngIf='!model.objModel.activeDirectoryUserInd'>
                <ng-container *ngIf='model.objModelId == -1'>
                  <div class="flex-child flex-basis-0 flex-grow-1 break-sm">
                    <ichs-password #passwordComponent name="password" [(ngModel)]="model.objModel.password" required label="Password"
                      passwordStrength></ichs-password>
                      <div class="position-relative">
                        <div class="password-rules">
                          (At least 12 characters including: capital character, small character, number or symbol.)
                        </div>
                      </div>

                  </div>

                  <div class="flex-col-sep"></div>

                  <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                    <ichs-password name="confirmPassword" [(ngModel)]="confirmPassword" label="Confirm Password" validateEqual
                    [relatedControl]="passwordComponent.model.control" required></ichs-password>
                  </div>
                </ng-container>

                <ng-container *ngIf='model.objModelId != -1'>
                  <div  class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                    <ichs-check-box class="flex-grow-1" disabled="true" name="lockedOut" [ngModel]="model.objModel.lockedOut"
                      label="Locked Out Due to Many Login Failures"></ichs-check-box>
                    <a *ngIf="model.objModel.lockedOut && appUsersReadOnlyRestriction == false" style="align-self: center;"
                      class="btn btn-default" (click)="unlockAccount()">
                      Unlock Account
                    </a>
                  </div>

                  <div class="flex-col-sep"></div>

                  <div  class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                    <ichs-text-box class="flex-grow-1" [disabled]="true" name="passwordStatus" [ngModel]="model.objModel.passwordStatus"
                      label="Password Status"></ichs-text-box>
                    <a *ngIf="model.objModel.passwordExpired && appUsersReadOnlyRestriction == false"
                      class="btn btn-default unexpire-btn" (click)="unexpirePassword()">
                      Unexpire Password
                    </a>
                  </div>
                </ng-container>
              </ng-container>

              <ng-container *ngIf='model.objModel.activeDirectoryUserInd'>
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm"></div>
                <div class="flex-col-sep"></div>
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm"></div>
              </ng-container>
            </div>

            <div class="display-flex break-la">
              <ng-container *ngIf="!model.objModel.activeDirectoryUserInd && (model.objModel.roleName == 'Client User' || model.objModel.roleName == 'Client User - Triage Portal')">
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                  <ichs-check-box name="passwordDoesNotExpire" [(ngModel)]="model.objModel.passwordDoesNotExpire" label="Password Doesn't Expire">
                  </ichs-check-box>
                </div>

                <div class="flex-col-sep"></div>
              </ng-container>

              <ng-container *ngIf="model.objModel.roleName == 'Client User'">
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                  <ichs-check-box name="receiveScheduledReportsOnly" [(ngModel)]="model.objModel.receiveScheduledReportsOnly"
                    label="Receive Scheduled Reports Only">
                  </ichs-check-box>
                </div>

                <div class="flex-col-sep"></div>
              </ng-container>

              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <ichs-check-box name="userLookupAccess" [(ngModel)]="model.objModel.appUserExtension.userLookupAccess"
                  label="User Lookup Access" class="flex-grow-1">
                </ichs-check-box>
                <div class="flex-col-sep"></div>
                <ichs-check-box name="manageUsersAccess" [(ngModel)]="model.objModel.appUserExtension.manageUsersAccess" label="Manage Users"
                   class="flex-grow-1"></ichs-check-box>
              </div>

              <ng-container *ngIf="model.objModel.activeDirectoryUserInd || !(model.objModel.roleName == 'Client User' || model.objModel.roleName == 'Client User - Triage Portal')">
                <div class="flex-col-sep"></div>
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm"></div>
              </ng-container>

              <ng-container *ngIf="model.objModel.roleName != 'Client User'">
                <div class="flex-col-sep"></div>
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm"></div>
              </ng-container>
            </div>

            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <ichs-check-box name="financeDataAccess" [(ngModel)]="model.objModel.appUserExtension.financeDataAccess"
                  label="Finance Data" class="flex-grow-1"></ichs-check-box>
                <div class="flex-col-sep" ></div>
                <ichs-check-box name="hrDataAccess" [(ngModel)]="model.objModel.appUserExtension.hrDataAccess" label="HR Data"
                   class="flex-grow-1"></ichs-check-box>
              </div>

              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-check-box name="sharedInboxEmail" [(ngModel)]="model.objModel.appUserExtension.sharedInboxEmail"
                  label="Shared Inbox/Email"></ichs-check-box>
              </div>

              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm"></div>
            </div>
          </div>
        </ichs-collapsable-panel>

        <ichs-collapsable-panel panelTitle="User Business Details" *ngIf="appUsersReadOnlyRestriction == false">
          <div class="collapsableBody">
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [(ngModel)]="model.objModel.appUserExtension!.title" name="title" label="Title"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [(ngModel)]="model.objModel.appUserExtension!.office" name="office" label="Office Phone Number" inputMask="phone-with-ext"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [(ngModel)]="model.objModel.appUserExtension!.website" name="website" label="Website"></ichs-text-box>
              </div>
            </div>
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [(ngModel)]="model.objModel.appUserExtension!.cellphone" name="cellphone" label="Cell Phone" inputMask="phone"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box [(ngModel)]="model.objModel.appUserExtension!.fax" name="fax" label="Fax" inputMask="phone"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered card-templates">
                <ichs-select name="BusinessCardTemplate" [(ngModel)]="model.objModel.appUserExtension!.businessCardTemplate" label="Business Card" listId="108000000000002" required></ichs-select>
              </div>
            </div>
            <div class="display-flex break-la">

              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered card-templates">
                <div class='flex-cell label-cont'><label>User Image</label></div>
                <ichs-image-cropper [inputImage]="userImage" (imageCropped)="onImageCropped($event)"></ichs-image-cropper>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">

                <div class="upload-img" (click)="logoInput.click()">
                  <div class="uploaded-img-cont"><img [src]="logoImage" title="User Logo" /></div>
                  <input (change)="logoImageSelected($event)" type="file" #logoInput style="display:none" accept="image/x-png,image/gif,image/jpeg" />
                  <div class="position-relative">
                    <div class="change-img position-absolute">
                      <span>Change Image</span>
                    </div>
                  </div>
                </div>
                <div class='flex-cell label-cont'><label class="margin-left-def">Company Logo</label></div>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              </div>
            </div>
            <div class="text-center contact-card-prev">
              <h3 class="text-center card-prev-title">Card Preview</h3>
              <appuser-card-template [loadLogoImage]="false" [logoImage]="logoImage" [appuser]="model.objModel" [previewOnly]="true"></appuser-card-template>
            </div>
          </div>
        </ichs-collapsable-panel>
      </mat-tab>

      <mat-tab label="Applications Access" [disabled]="model.objModelId == -1" *ngIf="appUsersReadOnlyRestriction == false">
        <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab has-command">
          <ichs-grid [grid-config]="applicationsAccessGridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
        </div>
      </mat-tab>

      <ng-container *ngIf="appUsersReadOnlyRestriction == false && showAccessTab">
        <mat-tab label="Access Features" [disabled]="model.objModelId == -1" *ngxPermissionsOnly="['Admin']">

          <ichs-collapsable-panel panelTitle="Access Features">
            <div class="collapsableBody">
              <div class="display-flex break-la">
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                  <ichs-check-box name="pageDesignAccess" [(ngModel)]="model.objModel.appUserExtension!.pageDesignAccess" label="Page Design Access"></ichs-check-box>
                </div>
                <div class="flex-col-sep"></div>
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                  <ichs-check-box name="allowReset" [(ngModel)]="model.objModel.appUserExtension!.allowReset" label="Allow Reset Password"></ichs-check-box>
                </div>
                <div class="flex-col-sep"></div>
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                  <ichs-check-box name="showDataAccess" [(ngModel)]="model.objModel.appUserExtension!.showDataAccess" label="Show Additional Data Access"></ichs-check-box>
                </div>
              </div>

              <div class="display-flex break-la" *ngIf="(loggedInUserObs | async).triageUserMaintenanceAccessible">
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                  <ichs-check-box name="allowMaintainTriageUsers" [(ngModel)]="model.objModel.appUserExtension!.allowMaintainTriageUsers" label="Maintain Telehealth Users"></ichs-check-box>
                </div>
                <div class="flex-col-sep"></div>
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                </div>
                <div class="flex-col-sep"></div>
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                </div>
              </div>
            </div>
          </ichs-collapsable-panel>

          <ichs-collapsable-panel panelTitle="App User Permissions">
            <div class="collapsableBody">
              <div class="display-flex break-la">
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                  <ichs-check-box name="allowMaintainCall" [(ngModel)]="model.objModel.appUserExtension!.allowMaintainCall" label="Process Call Recording"></ichs-check-box>
                </div>
                <div class="flex-col-sep"></div>
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                  <ichs-check-box name="allowAdd" [(ngModel)]="model.objModel.appUserExtension!.allowAdd" label="Allow Add User"></ichs-check-box>
                </div>
                <div class="flex-col-sep"></div>
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                  <ichs-check-box name="allowScheduleReports" [(ngModel)]="model.objModel.appUserExtension!.allowScheduleReports"
                    label="Dashboard & Report Scheduling Full Access"></ichs-check-box>
                </div>
              </div>
            </div>
          </ichs-collapsable-panel>

          <ichs-collapsable-panel panelTitle="App User Impersonation">
            <div class="collapsableBody">
              <div class="display-flex break-la">
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                  <ichs-check-box name="allowNoGroupUsersImpersonation" [(ngModel)]="model.objModel.appUserExtension!.allowNoGroupUsersImpersonation"
                    label="Allow to Impersonate Users without Groups"></ichs-check-box>
                </div>

                <div class="flex-col-sep"></div>
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                  <ichs-select name="userImpersonateGroup" label="Impersonate Group" [(ngModel)]="model.objModel.userImpersonateGroupList"
                    multiple="true" hasSelectAll="true" hasSearch="true" [items]="groupItems"></ichs-select>
                </div>

                <div class="flex-col-sep"></div>
                <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                  <ichs-select name="impersonateBusinessLines" label="Impersonate Line of Business" [(ngModel)]="model.objModel.impersonateBusinessLineList"
                    multiple="true" hasSelectAll="true" listId="108000000000007"></ichs-select>
                </div>
              </div>
            </div>
          </ichs-collapsable-panel>
        </mat-tab>
      </ng-container>

      <mat-tab [label]="userDataAccessTabLabel" [disabled]="model.objModelId == -1"
        *ngIf="appUsersReadOnlyRestriction == false && userGroupsDataAccessGrids.length > 0">
        <mat-tab-group mat-align-tabs="start"  mat-stretch-tabs="false" #innerMatTabGroup class="sub-tabs" (selectedIndexChange)="onSelectedIndexChange($event)">
          <mat-tab *ngFor="let item of userGroupsDataAccessGrids" [label]="item.lineOfBusiness">
            <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab">
              <ichs-grid [grid-config]="item.gridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col">
              </ichs-grid>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-tab>

      <mat-tab label="Additional Data Access" [disabled]="model.objModelId == -1" *ngIf="appUsersReadOnlyRestriction == false && model.objModel.appUserExtension!.showDataAccess">
        <ichs-collapsable-panel panelTitle="User Access">
          <div class="collapsableBody">
            <div style="margin-top:8px;">
              <line-of-business-tree [treeData]="model.objModel.lineOfBusinessList"></line-of-business-tree>
            </div>
          </div>
        </ichs-collapsable-panel>
      </mat-tab>

      <!--<mat-tab label="Data Restrictions" [disabled]="model.objModelId == -1">
          <ichs-collapsable-panel panelTitle="Data Restrictions">
            <div class="collapsableBody">
              <div style="margin-top:8px;">
                <restricted-access-tree *ngIf="model" [accessRestriction]="model.objModel.lineOfBusinessDataAccess"></restricted-access-tree>
              </div>
            </div>
          </ichs-collapsable-panel>
        </mat-tab>-->
      <mat-tab label="Security"
        *ngIf="model.objModelId != -1 && appUsersReadOnlyRestriction == false && !model.objModel.activeDirectoryUserInd && (loggedInUserObs | async).allowResetPasswordInd">
        <reset-password (passwordChanged)="onPasswordReset($event)" [userId]="model.objModelId"></reset-password>

        <ichs-collapsable-panel panelTitle="Reset User Security Questions" *ngIf="!model.objModel.activeDirectoryUserInd && (loggedInUserObs | async).roleName == 'Admin'">
          <div class="collapsableBody">
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <a class="btn btn-default" (click)="resetSecurityQuestions()">
                  <i class="glyphicon"></i> Reset Security Questions
                </a>
                <div class="flex-col-sep"></div>
                <ichs-text-box *ngIf="model.objModel.secQuestionsStatus" class="flex-grow-1" [disabled]="true"
                name="secQuestionDate" [ngModel]="model.objModel.secQuestionsStatus">
                </ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
              </div>
            </div>
          </div>
        </ichs-collapsable-panel>

        <ichs-collapsable-panel panelTitle="Multifactor Authentication" *ngIf="(loggedInUserObs | async).roleName == 'Admin'">
          <div class="collapsableBody">
            <div class="display-flex break-la">
              <div style="align-self: flex-end;" class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <ichs-text-box name="mfaAppStatus" [(ngModel)]="model.objModel.mfaStatus.mfaAppStatus" label="Authentication App"
                  [disabled]="true"></ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>

              <div style="align-self: flex-end;" class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <a class="btn btn-default" (click)="model.objModel.mfaAppEnabled ? resetMfa('App') : setupMfa('App')">
                  <i class="glyphicon"></i> {{ model.objModel.mfaAppEnabled ? 'Reset MFA' : 'Setup MFA'}}
                </a>
              </div>
            </div>
            <div class="display-flex break-la">
              <div style="align-self: flex-end;" class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <ichs-text-box name="mfaTextStatus" [(ngModel)]="model.objModel.mfaStatus.mfaTextStatus" label="Text/SMS"
                  [disabled]="true"></ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>

              <div style="align-self: flex-end;" class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <a class="btn btn-default" (click)="model.objModel.mfaTextEnabled ? resetMfa('Text') : setupMfa('Text')">
                  <i class="glyphicon"></i> {{ model.objModel.mfaTextEnabled ? 'Reset MFA' : 'Setup MFA'}}
                </a>
              </div>
            </div>
            <div class="display-flex break-la">
              <div style="align-self: flex-end;" class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <ichs-text-box name="mfaSharedEmailStatus" [(ngModel)]="model.objModel.mfaStatus.mfaSharedEmailStatus"
                  label="Email" [disabled]="true"></ichs-text-box>
              </div>

              <div class="flex-col-sep"></div>

              <div style="align-self: flex-end;" class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm">
                <a class="btn btn-default" (click)="model.objModel.mfaSharedEmailEnabled ? resetMfa('Email') : setupMfa('Email')">
                  <i class="glyphicon"></i> {{ model.objModel.mfaSharedEmailEnabled ? 'Reset MFA' : 'Setup MFA'}}
                </a>
              </div>
            </div>
          </div>
        </ichs-collapsable-panel>
      </mat-tab>
      <mat-tab label="User Contacts" *ngIf="model.objModelId != -1 && appUsersReadOnlyRestriction == false">
        <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab has-command">
          <ichs-grid [grid-config]="userContactsGridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
        </div>
      </mat-tab>
      <mat-tab label="User App Log" *ngIf="model.objModelId != -1">
        <div class="cont-padding">
          <ichs-select hideResetLabel="true" name="logsType" label="Logs Type" [items]="logsTypeSelectionListItems" [(ngModel)]="logsType"></ichs-select>
        </div>
        <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab has-command app-log">
          <ichs-grid [grid-config]="appLogGridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
        </div>
      </mat-tab>
      <mat-tab label="Memberships" *ngIf="showMembershipTab">
        <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab has-command">
          <ichs-grid [grid-config]="membershipsGridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
      </div>
      </mat-tab>
      <mat-tab label="Manage Users" *ngIf="showManageUsersTab">
        <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab has-command">
          <ichs-grid [grid-config]="manageUsersGridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
      </div>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>
