<div class="cp-dialog">
  <h2 mat-dialog-title>{{data.title}}</h2>
  <mat-dialog-content style="height:60vh">
    <div class="display-flex flex-basis-0 flex-grow-1 border-bottom" style="height:100%">
      <ichs-grid [grid-config]="data.config" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <div class="flex-basis-0 flex-grow-1 text-right">
      <button mat-button *ngIf="data.hasAddExisting" (click)="AddExisting()" cdkFocusInitial class="cp-btn cp-main-btn">
        {{ data.okText ? data.okText : 'Add Selected'}}
      </button>
      <button mat-button class="cp-btn cp-mail-btn" (click)="close()">
        {{ data.cancelText ? data.cancelText : 'Close'}}
      </button>
    </div>
  </mat-dialog-actions>
</div>
