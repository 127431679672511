import { Component, OnInit, Input, ViewChild, Optional, Inject, Output, EventEmitter, Injector, SimpleChanges, OnDestroy } from '@angular/core';
import { SelectionListService, SelectionItemViewModel, GroupSelectionItemViewModel } from '../../rest/index';
import { NG_VALUE_ACCESSOR, NgModel, NG_VALIDATORS, UntypedFormControl } from '@angular/forms';
import { ControlBase } from '../control-base';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { first, takeUntil } from 'rxjs/operators';
import { Subject, BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'ichs-select',
  templateUrl: './ichs-select.component.html',
  styleUrls: ['./ichs-select.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: IchsSelectComponent,
    multi: true,
  }],
})

//Selection list controller that allows multi select, grouping and dynamic loading of list
export class IchsSelectComponent extends ControlBase<string> implements OnInit, OnDestroy {
  public identifier = `ichs-select-${selectIdentifier++}`;
  @Input("label") label: string;
  @Input("placeholder") emptyLabel: string = "Select Item";
  @Input("useGroups") useGroups: boolean = false;
  @Input("listId") selectionListId: number; //the database Id to get the selection list items
  @Input("resetLabel") resetLabel: string = "None" // the text in the single select that let the user clear selection
  @Input("hideResetLabel") hideResetLabel: boolean = false;
  @Input("customLabel") customLabel: string = "Custom" // the text in the single select that let the user clear selection
  @Input("hideCustomLabel") hideCustomLabel: boolean = true;
  @Input("multiple") multiple: boolean = false;// variable to allow multi-select or single select
  @Input("hasCloseButton") hasCloseButton: boolean = true;// variable to add a close button, in case of multi-select
  @Input("groupItems") groupedItems: GroupSelectionItemViewModel[] = [];//static list with grouping
  @Input("items") items: SelectionItemViewModel[] = []; // static list without grouping
  @Input("disabled") isDisabled: boolean;
  @Input("readOnly") isReadOnly: boolean = false;
  @Input("hasSelectAll") hasSelectAll: boolean = false; //to add select all functionality
  @Input("hasSearch") hasSearch: boolean = false; //to add search functionality

  @Output() selectionChanged = new EventEmitter<MatSelectChange | MatCheckboxChange>();
  @Output() openedChange = new EventEmitter<boolean>();

  @ViewChild(NgModel, { static: true }) model: NgModel;

  showResetLabelInSearch: boolean = true;
  showCustomLabelInSearch: boolean = true;

  modelSubscription: Subscription;

  /** control for the MatSelect filter keyword */
  public itemFilterCtrl: UntypedFormControl;

  /** list of items filtered by search keyword */
  public filteredItems: BehaviorSubject<SelectionItemViewModel[]> = new BehaviorSubject<SelectionItemViewModel[]>(this.items);
  public filteredGroupItems: BehaviorSubject<SelectionItemViewModel[]> = new BehaviorSubject<SelectionItemViewModel[]>([]);

  dataList: GroupSelectionItemViewModel[] = [];
  dataListItems: SelectionItemViewModel[] = [];

  protected _onDestroy;

  constructor(@Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Inject(Injector) inj: Injector,
    private selectionService: SelectionListService) {
    super(validators, inj);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['items'] && changes['items'].currentValue) {
      this.filteredItems = new BehaviorSubject<SelectionItemViewModel[]>(this.items);
    }
  }

  ngOnInit() {
    super.ngOnInit();

    //Load the data if the selection list is dynamic
    if (this.selectionListId && this.selectionListId > 0) {
      this.selectionService.getSelectionItems(this.selectionListId)
        .pipe(first())
        .subscribe(lst => {
          this.dataList = lst.result!;

          this.dataList.forEach(obj => {
            this.dataListItems.push(...obj.items);
          });
          this.filteredGroupItems = new BehaviorSubject<SelectionItemViewModel[]>(this.dataListItems);
        });
    }

    if (this.hasSearch) {
      this.itemFilterCtrl = new UntypedFormControl();
      this._onDestroy = new Subject<void>();
      // listen for search field value changes
      this.itemFilterCtrl.valueChanges
        .pipe(takeUntil(this._onDestroy))
        .subscribe(() => {
          this.filterItems();
        });
    }

    if (this.multiple) {
      this.modelSubscription = this.model.valueChanges.subscribe(result => {
        if (result && result.length > 0 && this.items && this.items.length > 0) {
          this.setSelectedValuesAtTop();
        }
      });
    }
  }

  ngOnDestroy(): void {
    if (this.modelSubscription) {
      this.modelSubscription.unsubscribe();
    }
  }

  //expose the onChange event to the outer components
  onChangeEvent(event: MatSelectChange) {
    this.selectionChanged.emit(event);
  }

  onOpenedChange(opened) {
    this.openedChange.emit(opened);

    //if select list is closed, reset the selected values on top.
    if (!opened && this.multiple) {
      this.setSelectedValuesAtTop();
    }
  }

  //Set selected values at top of the list
  setSelectedValuesAtTop() {
    if (this.model.value && !this.useGroups) {
      let intersection = this.items.filter(item => this.model.value.indexOf(item.selectionValue) > -1);
      let unselected = this.items.filter(item => this.model.value.indexOf(item.selectionValue) == -1);
      this.items = [...intersection, ...unselected];
      this.filterItems();
    }
    if (!this.modelSubscription.closed) {
      this.modelSubscription.unsubscribe();
    }
  }

  isAllSelected() {
    if (!this.model.value) {
      return false;
    }

    if (this.items != null && this.items.length > 0) {
      let filteredVals = this.filteredItems.getValue().map(item => item.selectionValue);
      let intersection = filteredVals.filter(item => this.model.value.indexOf(item) > -1);

      if (intersection.length == filteredVals.length) {
        return true
      }
      else if (intersection.length > 0) {
        return null
      }
      else {
        return false
      }
    }

    if (this.dataListItems != null && this.dataListItems.length > 0) {
      let filteredVals = this.filteredGroupItems.getValue().map(item => item.selectionValue);
      let intersection = filteredVals.filter(item => this.model.value.indexOf(item) > -1);

      if (intersection.length == filteredVals.length) {
        return true
      }
      else if (intersection.length > 0) {
        return null
      }
      else {
        return false
      }
    }
  }

  selectAll(event: MatCheckboxChange) {
    if (this.hasSearch) {
      let _selectedValues = this.model.value ? this.model.value as string[] : [];

      if (event.checked) {
        if (this.items != null && this.items.length > 0) {
          let newValues = this.mergeWithoutDuplicates(_selectedValues, this.filteredItems.getValue().map(item => item.selectionValue));
          this.model.update.emit(newValues);
        }
        if (this.dataListItems != null && this.dataListItems.length > 0) {
          let newValues = this.mergeWithoutDuplicates(_selectedValues, this.filteredGroupItems.getValue().map(item => item.selectionValue));
          this.model.update.emit(newValues);
        }
      }
      else {
        if (this.items != null && this.items.length > 0) {
          //if unselect all, sort the items.
          let originalItemsList = this.items.sort((a, b) => (a.selectionText > b.selectionText) ? 1 : -1)
          this.items = [...originalItemsList];
          let valuesToRemove = this.filteredItems.getValue().map(item => item.selectionValue);
          let newValues = _selectedValues.filter(value => valuesToRemove.indexOf(value) == -1);
          this.model.update.emit(newValues);
        }

        if (this.dataListItems != null && this.dataListItems.length > 0) {
          let valuesToRemove = this.filteredGroupItems.getValue().map(item => item.selectionValue);
          let newValues = _selectedValues.filter(value => valuesToRemove.indexOf(value) == -1);
          this.model.update.emit(newValues);
        }
      }
    }
    else {
      if (event.checked) {
        if (this.items != null && this.items.length > 0) {
          this.model.update.emit(this.items.map(item => item.selectionValue));
        }
        if (this.dataListItems != null && this.dataListItems.length > 0) {
          this.model.update.emit(this.dataListItems.map(item => item.selectionValue));
        }
      }
      else {
        this.model.update.emit([]);
      }
    }

    this.selectionChanged.emit(event);
  }

  private mergeWithoutDuplicates(array1: any[], array2: any[]) {
    let combined = array1.concat(array2);
    return combined.filter((item, index) => combined.indexOf(item) === index);
  }

  private filterItems() {
    // get the search keyword
    let search = this.itemFilterCtrl?.value;
    if (!search) {
      this.filteredItems.next(this.items);
      this.filteredGroupItems.next(this.dataListItems);
      this.showResetLabelInSearch = true;
      this.showCustomLabelInSearch = true;
      return;
    } else {
      search = search.toLowerCase();
    }

    // filter the items
    if (this.items && this.items.length > 0) {
      this.filteredItems.next(
        this.items.filter(item => item.selectionText.toLowerCase().indexOf(search) > -1)
      );
    }

    if (this.dataListItems && this.dataListItems.length > 0) {
      this.filteredGroupItems.next(
        this.dataListItems.filter(item => item.selectionText.toLowerCase().indexOf(search) > -1)
      );
    }

    this.showResetLabelInSearch = this.resetLabel.toLowerCase().indexOf(search) > -1;
    this.showCustomLabelInSearch = this.customLabel.toLowerCase().indexOf(search) > -1;
  }
}

let selectIdentifier = 0;
