import { NgModel, NG_VALIDATORS, ValidationErrors, NgControl, Validator } from '@angular/forms';
import { ValueAccessorBase } from './value-accessor-base';
import { ValidationResult, validate } from './validation/validate';
import { Optional, Inject, OnInit, Injector, Directive, Component } from '@angular/core';


// this is the control base, it should be implemnted in custom controls, 
// to activate the value accessor and the validation
@Directive()
export abstract class ControlBase<T> extends ValueAccessorBase<T> implements OnInit {

    public abstract model: NgModel;

    constructor(
        @Optional() @Inject(NG_VALIDATORS) protected validators: Array<Validator>,
        @Inject(Injector) private inj: Injector
    ) {
        super();
    }

    ngOnInit() {
        const control: NgControl = this.inj.get(NgControl);
        const innerControl = this.model.control;
        const originalmarkAsTouched = control.control!.markAsTouched;
        const originalmarkAsUntouched = control.control!.markAsUntouched;
        //Override the markAsTouched function to mark the inner input.
        control.control!.markAsTouched = function () {
            innerControl.markAsTouched();

        };
        //Override the markAsUntouched function to mark the inner input.
        control.control!.markAsUntouched = function () {
            innerControl.markAsUntouched();

        }
    }

    // validate the control.
    public validate(): ValidationErrors | ValidationResult | null {
        return validate
            (this.validators)
            (this.model.control);
    }

    //check if the control is invalid.
    public get invalid(): boolean {
        const erros: ValidationErrors | null = this.validate();
        if (erros)
            return true;
        else
            return false;
    }

    //get the failures messages.
    protected get failures(): Array<string> {
        const erros: ValidationErrors | ValidationResult | null = this.validate();
        const messgesArray: Array<string> = [];
        //Apply the validation if the control touched.
        if (erros) {
            if (erros.email)
                messgesArray.push('Please enter a valid Email pattern');
            if (erros.required)
                messgesArray.push('This field is required');
            if (erros.passwordStrength)
                messgesArray.push(erros.passwordStrength);
            if (erros.hexadecimal)
                messgesArray.push(erros.hexadecimal);
            if (erros.date)
                messgesArray.push(erros.date);
            if (erros.dateMin)
                messgesArray.push(erros.dateMin);
            if (erros.dateMax)
                messgesArray.push(erros.dateMax);
            if (erros.validateEqual)
                messgesArray.push(erros.validateEqual);
            if (erros.pattern)
                messgesArray.push('Value does not match required pattern');
            if (erros.minlength)
                messgesArray.push('Value must have a minimum of ' + erros.minlength.requiredLength + ' characters');
            if (erros.maxlength)
                messgesArray.push('Value must have a maximum of ' + erros.maxlength.requiredLength + ' characters');
        }

        return messgesArray;
    }
}