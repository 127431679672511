<div class='label-cont' *ngIf="label">
  <label [attr.for]="identifier">{{label}}</label>
</div>
<div class="display-flex flex-items-centered">
  <div class='flex-cell input-cont flex-basis-0 flex-grow-1'>
    <input #inputController 
           type="text"
           autocomplete="off"
           [(ngModel)]="value"
           [ngClass]="{invalid: (invalid)}"
           [id]="identifier"
           [placeholder]="textboxPlaceHolder"
           [disabled]="isDisabled"
           (change)="textBoxChangedEvent($event)" />
  </div>
  <div class="flex-cell">
    <img *ngIf="showButton" [src]="icon" class="field-icon" (click)="buttonClickedAction()" [title]="iconTitle" />
  </div>
  <div class="flex-cell">
    <validation-message *ngIf="invalid && model.control.touched" [messages]="failures"></validation-message>
  </div>
</div>
