import { AbstractControl, AsyncValidatorFn, Validator, Validators, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';


export type ValidationResult = { [validator: string]: string | boolean };
export type ValidatorArray = Array<Validator | ValidatorFn>;

//Get the validator function.
const normalizeValidator =
    (validator: Validator | ValidatorFn): ValidatorFn | AsyncValidatorFn => {
        const func = (validator as Validator).validate.bind(validator);
        if (typeof func === 'function') {
            return (c: AbstractControl) => func(c);
        } else {
            return <ValidatorFn | AsyncValidatorFn>validator;
        }
    };

//Apply the validation Rule.
export const composeValidators =
    (validators: ValidatorArray): AsyncValidatorFn | ValidatorFn | null => {
        if (validators == null || validators.length === 0) {
            return null;
        }
        return Validators.compose(validators.map(normalizeValidator));
    };

// Get the unvalid validators
export const validate =
    (validators: ValidatorArray) => {
        return (control: AbstractControl) => {
            
                const synchronousValid = () => composeValidators(validators)!(control);

                if (validators) {
                    return synchronousValid();
                }

            return null;
        };
    };

