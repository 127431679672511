import { Component, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgModel, RequiredValidator } from '@angular/forms';
import { ControlBase } from '../control-base';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'ichs-slide-toggle',
    templateUrl: './ichs-slide-toggle.component.html',
    styleUrls: ['./ichs-slide-toggle.component.css'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: IchsSlideToggleComponent,
        multi: true,
    }],
    host: { class: "display-flex flex-items-centered" }
})
export class IchsSlideToggleComponent extends ControlBase<boolean> {
    @Input('label') label: string;
    @Input("disabled") isDisabled: boolean;
    isRequired: boolean = false;

    @Output() slideToggleChanged: EventEmitter<MatSlideToggleChange> = new EventEmitter<MatSlideToggleChange>();
    @ViewChild(NgModel, { static: true }) model: NgModel;

    // this attribute to give a unique identifier for the textboxs.
    public slideToggleIdentifier = `ichs-slide-toggle-${slideToggleIdentifier++}`;

    ngOnInit() {
        super.ngOnInit();
        if (this.validators) {
            for (var i = 0; i < this.validators.length; i++) {
                if (this.validators[i] instanceof RequiredValidator) {
                    this.isRequired = true;
                }
            }
        }
    }

    slideToggleChangedEvent(event: MatSlideToggleChange) {
        this.slideToggleChanged.emit(event);
    }
}

let slideToggleIdentifier = 0;