import { Component, OnInit } from '@angular/core';
import { ReportItemViewModel, ReportItemService, SearchViewModel } from '../../rest';
import { VoidInfoViewModel } from '../../rest/model/voidInfoViewModel';
import { IchsGridComponentConfig, IchsControlType } from '../../controls/ichs-grid/grid-configs';
@Component({
  selector: 'report-item-list',
  templateUrl: './report-item-list.component.html',
  styleUrls: ['./report-item-list.component.css'],
  host: {
    class: "data-list"
  }
})

/** reportItem component*/
export class ReportItemListComponent implements OnInit {
  gridConfig: IchsGridComponentConfig<ReportItemViewModel>;

  /** reportItem ctor */
  constructor(
    private _reportItemService: ReportItemService,
  ) {
    this.gridConfig = this.defineGrid();
  }

  /** ReportItemList OnInit*/
  ngOnInit(): void {
  }

  private defineGrid(): IchsGridComponentConfig<ReportItemViewModel> {
    let configs: IchsGridComponentConfig<ReportItemViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Report Items",
      entityController: "report-item",
      voidAction: (reason: VoidInfoViewModel) => this._reportItemService._void(reason),
      recoverAction: (id: number) => this._reportItemService.recover(id),
      entityDataSource: (filter: SearchViewModel) => this._reportItemService.search(filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Tag Title",
          propertyName: "tagTitle",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Internal Report",
          propertyName: "internalInd",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Boolean
        },
        {
          headerTitle: "Line Of Business",
          propertyName: "lineOfBusiness",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.DynamicSelect,
          listId: 108000000000007
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ],
    }
    return configs;
  }
}
