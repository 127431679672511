<h2 mat-dialog-title>{{data.title}}</h2>
<mat-dialog-content>
    {{data.message}}
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-basis-0 flex-grow-1 text-right">
    <button mat-button *ngIf="!data.hideOk" [mat-dialog-close]="true" cdkFocusInitial class="cp-btn cp-main-btn">{{data.okText?data.okText:'Ok'}}</button>
    <button mat-button *ngIf="!data.hideCancel" [mat-dialog-close]="false" class="cp-btn cp-mail-btn">{{data.cancelText?data.cancelText:'Cancel'}}</button>
  </div>

</mat-dialog-actions>
