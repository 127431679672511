import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { first } from 'rxjs/operators';
import SignaturePad from 'signature_pad';
import { APISettings } from '../../app.general.constants';
import { UserSecuredFileService } from '../../rest';
import { AlertDismissType, AlertMessageType, MedcorAlertService } from '../../services/medcor-alert.service';

@Component({
  selector: 'external-signature-request',
  templateUrl: './external-signature-request.component.html',
  styleUrls: ['./external-signature-request.component.css']
})
export class ExternalSignatureRequestComponent implements OnInit, AfterViewInit, AfterViewChecked {

  signaturevisible = true;
  submitted = false;
  id = '';
  pdfSrc = '';
  signaturePad: SignaturePad;
  @ViewChild('canvas') canvasEl: ElementRef;
  signatureImg: string;

  constructor(
    private alertService: MedcorAlertService,
    private userSecuredFiles: UserSecuredFileService,
  ) {
    let pathParts: string[] = location.pathname.split("/");
    if (pathParts.length < 3) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Invalid File Request Link!"]
      });
      return;
    }
    this.id = pathParts[pathParts.length - 1];
    this.pdfSrc = APISettings.API_BASE_PATH + '/api/UserSecuredFile/DownloadExternalFile/' + this.id;
  }

  ngOnInit(): void {
    window.onresize = this.resizeCanvas
  }

  ngAfterViewInit(): void {
    this.signaturePad = new SignaturePad(this.canvasEl.nativeElement, { backgroundColor:"rgb(255,255,255)"});
    this.resizeCanvas();
    this.signaturevisible = false;
  }

  ngAfterViewChecked() {
  }

  resizeCanvas() {
    this.canvasEl.nativeElement.width = this.canvasEl.nativeElement.offsetWidth;
    this.canvasEl.nativeElement.height = this.canvasEl.nativeElement.offsetHeight;
    this.signaturePad.clear();
  }

  sign() {
    this.signaturevisible = true;
  }

  cancel() {
    this.signaturevisible = false;
  }

  submit() {
    if (!this.signaturePad.isEmpty()) {
      var data = this.signaturePad.toDataURL("image/svg+xml");
      var encoded_image = data.split(",")[1]
      this.signaturevisible = false;
      this.userSecuredFiles.submitSignature({ eFileId: this.id, signature: encoded_image }).pipe(first()).subscribe(res => {
        this.submitted = true;
      });
    } else {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Sinature is empty"]
      });
    }
  }

  clear() {
    this.signaturePad.clear();
  }

}
