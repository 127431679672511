<div class="cont-padding">
    <div class="display-flex flex-items-centered">
        <div class="flex-basis-0 flex-grow-1">
            <div>
                <span class="grey-color">
                    {{email.sentReceivedDate | date: 'medium'}}
                </span>
            </div>
            <div class="email-cont-title"> {{email.title}}</div>
            <div>
                <span class="grey-color">
                    From:
                </span>
                <span class="link-color">
                    {{email.sender!.name}}
                </span>
            </div>
        </div>
        <div class="" *ngIf="mailBox!='notifications' && generalVariables.clientDeviceInfo.isDesktop">
            <button class="cp-btn cp-mail-btn cp-mail-btn-with-icon" *ngIf="mailBox != 'sent'" (click)="replyAll()">
              <div class="display-flex align-items-center">
                <img class="email-action-icon" src="assets/images/securedMail/replyAll.svg"/>
                <span class="email-action-text">Reply All</span>
              </div>
            </button>
            <button class="cp-btn cp-mail-btn cp-mail-btn-with-icon" *ngIf="mailBox != 'sent'" (click)="reply()">
              <div class="display-flex align-items-center">
                <img class="email-action-icon" src="assets/images/securedMail/reply.svg"/>
                <span class="email-action-text">Reply</span>
              </div>
            </button>
            <button class="cp-btn cp-mail-btn cp-mail-btn-with-icon" (click)="forward()">
              <div class="display-flex align-items-center">
                <img class="email-action-icon" src="assets/images/securedMail/forward.svg" />
                <span class="email-action-text">Forward</span>
              </div>
            </button>
        </div>
    </div>
</div>
<hr class="main-hr"/>
<div class="cont-padding">
    <div class="attachments-area" *ngIf="email.attachments && email.attachments.length>0">
        <span style="vertical-align:middle">Attachments:</span>
        <button class="cp-btn cp-mail-btn" (click)="downloadFile(file.id)" *ngFor="let file of email.attachments">
            {{file.fileName}}
        </button>
        <mat-progress-spinner class="upload-spinner flo" *ngIf="downloadProgress >0" [value]="downloadProgress"
            mode="determinate" diameter="30">
        </mat-progress-spinner>
        <hr class="main-hr" style="margin-top:8px;" />
    </div>

    <div [innerHTML]="email.body" class="margin-top-def"></div>
    <div *ngIf="requestedCallRecordRefNbr">To Send the Call Recording to this User, Please <a
            (click)="openSendCallRecordingDialog()">Click Here</a></div>

    <br/>
    <br/>

    <div class="read-confirm" *ngIf="email.notifyWhenOpen">
        Read confirmation was sent to the sender
    </div>

    <div>
        <div class="msg-recipients">Message Recipients:</div>
        <div *ngFor="let recipientName of recipientsNames">{{recipientName}}</div>
    </div>
</div>
