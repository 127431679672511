<div id="ng-chat" *ngIf="isBootstrapped">
  <ng-container *ngFor="let window of windows; let i = index">
    <div *ngIf="window.isCollapsed" [ngClass]="{'ng-chat-window': true, 'ng-chat-window-collapsed': true}" [ngStyle]="{'right': 20 + windowSizeFactor * i + 'px'}">
      <div class="ng-chat-title" (click)="onChatWindowClicked(window)">
        <div class="display-flex flex-items-centered">
          <span [ngClass]="{'ng-chat-user-status': true, 'online': window.chattingTo.status == UserStatus.Online, 'busy': window.chattingTo.status  == UserStatus.Busy, 'away': window.chattingTo.status == UserStatus.Away, 'offline': window.chattingTo.status == UserStatus.Offline}" title="{{getStatusTitle(UserStatus[window.chattingTo.status])}}"></span>

          <strong title="{{window.chattingTo.displayName}}">
            {{window.chattingTo.displayName}}
          </strong>
          <span *ngIf="unreadMessagesTotal(window).length > 0" class="ng-chat-unread-messages-count">{{unreadMessagesTotal(window)}}</span>
          <div class="flex-grow-1 flex-basis-0"></div>
          <a href="javascript:void(0);" class="ng-chat-close" (click)="onCloseChatWindow(window)">X</a>
        </div>
        
      </div>
    </div>
    <div *ngIf="!window.isCollapsed" [ngClass]="{'ng-chat-window': true, 'ng-chat-window-collapsed': false}" [ngStyle]="{'right': 20 + windowSizeFactor * i + 'px'}">
      <div class="ng-chat-title" (click)="onChatWindowClicked(window)">
        <div class="display-flex flex-items-centered">
          <span [ngClass]="{'ng-chat-user-status': true, 'online': window.chattingTo.status == UserStatus.Online, 'busy': window.chattingTo.status  == UserStatus.Busy, 'away': window.chattingTo.status == UserStatus.Away, 'offline': window.chattingTo.status == UserStatus.Offline}" title="{{getStatusTitle(UserStatus[window.chattingTo.status])}}"></span>
          <strong title="{{window.chattingTo.displayName}}">
            {{window.chattingTo.displayName}}
          </strong>
          <span *ngIf="unreadMessagesTotal(window).length > 0" class="ng-chat-unread-messages-count">{{unreadMessagesTotal(window)}}</span>
          <div class="flex-grow-1 flex-basis-0"></div>
          <a href="javascript:void(0);" class="ng-chat-close" (click)="onCloseChatWindow(window)">X</a>
        </div>
        
      </div>
      <div #chatMessages class="ng-chat-messages">
        <div *ngIf="window.isLoadingHistory" class="ng-chat-loading-wrapper">
          <div class="loader">Loading history...</div>
        </div>
        <div *ngFor="let message of window.messages; let i = index" [ngClass]="{'ng-chat-message': true, 'ng-chat-message-received': message.fromId != userId}">

          <div *ngIf="!window.chattingTo.avatar && isAvatarVisible(window, message, i)" class="icon-wrapper">
            <i class="user-icon"></i>
          </div>
          <img *ngIf="window.chattingTo.avatar && isAvatarVisible(window, message, i)" alt="" class="avatar" height="30" width="30" [src]="window.chattingTo.avatar" />
          <span (click)="showHideMessageTime(message)" [class.not-delivered-msg]="message.isDelivered == false" [innerHtml]="message.message | emojify:emojisEnabled | linkfy:linkfyEnabled"></span>
          <div *ngIf="message.showMessageTime" class="msg-time">
            {{ message.timeStamp | date: 'shortTime' }}
          </div>
          <span *ngIf="message.isDelivered == false" class="not-delivered">Not Delivered</span>
        </div>
      </div>
      <input #chatWindowInput [ngModel]="window.newMessage | emojify:emojisEnabled" (ngModelChange)="window.newMessage=$event" type="text" (keydown)="onChatInputTyped($event, window)" [placeholder]="localization.messagePlaceholder" (blur)="toggleWindowFocus(window)" (focus)="toggleWindowFocus(window)" />
    </div>
  </ng-container>
</div>
