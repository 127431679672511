import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppUserService, EmailSearchViewModel } from '../../rest/index';
import { SecuredEmailsService } from '../../rest/api/securedEmails.service';
import { first, switchMap } from 'rxjs/operators';
import { IchsDialogComponent } from '../../controls/ichs-dialog/ichs-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { GeneralVariablesService } from 'src/app/services/general-variables.service';

@Component({
  selector: 'email-item',
  templateUrl: './email-item.component.html',
  styleUrls: ['./email-item.component.css']
})
export class EmailItemComponent implements OnInit {

  @Input() email: EmailSearchViewModel;
  @Input() mailBox: string;
  @Output() emailChanged: EventEmitter<null> = new EventEmitter<null>();
  @Output() viewEmailEvent: EventEmitter<null> = new EventEmitter<null>();

  constructor(
    private emailService: SecuredEmailsService,
    private dialog: MatDialog,
    private generalVariablesService: GeneralVariablesService,
    private appuserService: AppUserService,
  ) { }

  ngOnInit() {
  }

  deleteSentEmail(event: Event) {
    event.stopPropagation();

    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Delete Sent Email", message: "Are you sure about deleting this email?" }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.emailService.deleteSentEmail(this.email.id)
          .pipe(first())
          .subscribe(() => {
            this.emailChanged.emit();
          });
      }
    });
  }

  deleteEmailFromInbox(event: Event) {
    event.stopPropagation();
    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Delete Email", message: "Are you sure about deleting this email?" }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.emailService.deleteEmail(this.email.id)
          .pipe(first())
          .subscribe(() => {
            this.emailChanged.emit();
          });
      }
    });
  }

  viewEmail() {
    this.viewEmailEvent.emit();
  }

  changeEmailFolder(folder: string) {
    this.emailService.updateReceivedFolder(this.email.id, folder)
      .pipe(first(), switchMap(() => {
        this.emailChanged.emit();
        if (!this.email.isRead) {
          return this.appuserService.getUserCountersSummary().pipe(first());
        }
        else {
          return of(null)
        };
      }))
      .subscribe(response => {
        if (response !== null) {
          this.generalVariablesService.updateSystemCounters(response.result);
        }
      });
  }
}
