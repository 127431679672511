
<div class="tile-img text-center" *ngIf="!tabMode">
  <a (click)="linkClicked($event,imageLink)"><img [src]="imgSrc" /></a>
</div>
<div class="tile-img text-center" *ngIf="tabMode">
  <a [href]="imageLink" target="_blank"><img [src]="imgSrc" /></a>
</div>
<div class="tile-info">
  <a (click)="linkClicked($event,titleLink)" *ngIf="!tabMode">
    <h2>{{label}}</h2>
  </a>

  <a [href]="titleLink" target="_blank" *ngIf="tabMode">
    <h2>{{label}}</h2>
  </a>

  <p>{{description}}</p>
</div>
