import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { UserSecuredFileViewModel, UserFolderViewModel, UserSecuredFileService, MedcorResponse, SearchViewModel } from 'src/app/rest';
import { InputDialogConfig, IchsControl } from 'src/app/controls/ichs-dialog/ichs-input-dialog/ichs-input-dialog.component';
import { FileTypesEnum } from 'src/app/app.general.constants';
import * as moment from 'moment';
import { IchsControlType, IchsGridComponentConfig } from 'src/app/controls/ichs-grid/grid-configs';
import { AppGenericLists } from 'src/app/app.general.lists';
import { MedcorUserInfoService } from 'src/app/services/medcor-user-info.service';
import { LoggedUserViewModel } from 'src/app/rest/model/loggedUserViewModel';
import { GeneralVariablesService } from 'src/app/services/general-variables.service';
import { MedcorAlertService, AlertDismissType, AlertMessageType } from 'src/app/services/medcor-alert.service';
import { DIALOG_REF, IchsComponentDialogComponent } from 'src/app/controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';

@Component({
  selector: 'secured-file-attachment',
  templateUrl: './secured-file-attachment.component.html',
  styleUrls: ['./secured-file-attachment.component.css']
})
export class SecuredFileAttachmentComponent implements OnInit {

  folderId: number = 1;
  files: UserSecuredFileViewModel[] = null;
  folder: UserFolderViewModel;
  pathName: string[] = [];
  pathId: string[] = [];
  newReceivedFilesCount: number = 0;
  newCallRecordingFilesCount: number = 0;
  callRecordingFilesCount: number = 0;
  newSecureReportFilesCount: number = 0;
  dialogConfig: InputDialogConfig;
  controls: IchsControl[] = [];
  hasRequestFileSignAccess: boolean;
  clientUserScheduledReportsOnlyRoleInd: boolean;

  gridConfigReceivedFilesFolder: IchsGridComponentConfig<UserSecuredFileViewModel>;
  gridConfigReportsFolder: IchsGridComponentConfig<UserSecuredFileViewModel>;

  get loggedInUserObs(): Observable<LoggedUserViewModel> { return this.medcorUserInfoService.loggedInUserObs }

  constructor(
    public activatedRoute: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public userSecuredFileService: UserSecuredFileService,
    public generalVariables: GeneralVariablesService,
    private medcorUserInfoService: MedcorUserInfoService,
    private alertService: MedcorAlertService,
    @Inject(DIALOG_REF) private dialogRef: MatDialogRef<IchsComponentDialogComponent>,
  ) { }

  ngOnInit() {

    this.loggedInUserObs.pipe(first()).subscribe(value => {
      this.clientUserScheduledReportsOnlyRoleInd = value.roleName == "Client User - Scheduled Reports Only";
    });

    this.gridConfigReceivedFilesFolder = this.defineReceivedFilesFolderGrid();
    this.gridConfigReportsFolder = this.defineReportsFolderGrid();

    this.getLoadData();
  }

  getLoadData() {
    // reset old values first
    this.files = null;
    switch (this.folderId) {
      case 1:
        this.loadData();
        break;
      case 2:
        this.loadFavoriteFiles();
        break;
      case 3:
        break;
      case 4:
        this.loadCallRecordingFiles();
        break;
      case 5:
        break;
      default:
        this.loadData();
    }
  }

  loadFavoriteFiles() {
    this.userSecuredFileService.getFavoriteFiles().pipe(first()).subscribe(result => {
      this.files = result.result!;
    });
  }

  loadCallRecordingFiles() {
    this.userSecuredFileService.getCallRecordingFiles().pipe(first()).subscribe(result => {
      this.files = result.result!;
    });
  }

  loadData() {
    var obv: Observable<MedcorResponse<UserFolderViewModel>>;
    if (this.folderId && this.folderId != 1) {
      obv = this.userSecuredFileService.getListOfFiles(this.folderId);
    } else {
      obv = this.userSecuredFileService.getListOfFiles();
    }
    obv.pipe(first()).subscribe(result => {
      this.files = result.result!.filesList;

      this.folder = result.result!;
      this.newReceivedFilesCount = result.result!.newReceivedFilesCount;
      this.callRecordingFilesCount = result.result!.callRecordingFilesCount;
      this.newCallRecordingFilesCount = result.result!.newCallRecordingFilesCount;
      this.newSecureReportFilesCount = result.result!.newSecureReportFilesCount;
      if (this.folderId > 5) {
        this.pathId = this.folder.pathUnique.split("/");
        this.pathName = this.folder.path.split("/");
        this.pathId.shift();
        this.pathName.shift();
      } else {
        this.pathName = [];
        this.pathId = [];
      }
    });
  }

  toggleFileSelection(file: UserSecuredFileViewModel) {
    file.selected = !file.selected;
  }

  addAttachments() {
    let selectedFiles: UserSecuredFileViewModel[] = [];
    if (this.folderId == 3) {
      selectedFiles = this.gridConfigReceivedFilesFolder.multiSelectConfig.getSelectedObjects();
    }
    else if (this.folderId == 5) {
      selectedFiles = this.gridConfigReportsFolder.multiSelectConfig.getSelectedObjects();
    }
    else {
      selectedFiles = this.files.filter(file => file.type != "Folder" && file.selected);
    }

    if (selectedFiles.length == 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: ["Please select at least one file!"],
      });
      return;
    }

    this.dialogRef.close(selectedFiles);
  }

  durationFromNow(expirationDate: Date): string {
    var now = moment.utc();
    var end = moment.utc(expirationDate);
    var duration = moment.duration(end.diff(now));

    let _durationStr: string = null;

    if (duration.asDays() > 1) {
      _durationStr = Math.ceil(duration.asDays()) + " days";
    }
    else if (duration.asHours() > 11) {
      _durationStr = "less than a day";
    }
    else if (duration.asHours() > 1) {
      _durationStr = "few hours";
    }
    else {
      _durationStr = "less than an hour";
    }

    return _durationStr;
  }

  routeToFolder(id: number) {
    this.folderId = id;
    this.getLoadData();
  }

  getFolders() {
    return this.files ? this.files.filter(obj => obj.fileType == "Folder") : [];
  }

  getFiles() {
    return this.files ? this.files.filter(obj => obj.fileType != "Folder") : [];
  }

  isCallRecording(file: UserSecuredFileViewModel) {
    return file.type == FileTypesEnum.CALL_RECORDING;
  }

  isPhiFile(file: UserSecuredFileViewModel) {
    return file.type == FileTypesEnum.PHI;
  }

  /**
   * Configure Received Files Folder Grid
   */
  private defineReceivedFilesFolderGrid(): IchsGridComponentConfig<UserSecuredFileViewModel> {
    let configs: IchsGridComponentConfig<UserSecuredFileViewModel> = {
      primaryId: "id",
      defaultOrder: "timeStampCreated",
      defaultOrderType: "desc",
      entityDataSource: (filter: SearchViewModel) => this.userSecuredFileService.searchReceivedFiles(filter),
      pageSize: 100,
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: false,
      hasAdditionalActionsMenu: false,
      multiSelectConfig: {
        allowSelectObjects: true,
        hasSelectAllCheckbox: false,
      },
      preprocessData: (files: UserSecuredFileViewModel[]) => this.removeFileExtension(files),
      rowStyle: (file: UserSecuredFileViewModel) => file.isNew ? 'background-color: #f0f0f5; font-weight: bold' : null,
      headers: [
        {
          headerTitle: "File Name",
          propertyName: "fileName",
          autoSearch: true,
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Type",
          propertyName: "type",
          width: 200,
          searchable: true,
          sortable: true,
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.FileType,
        },
        {
          headerTitle: "Date Received",
          propertyName: "timeStampCreated",
          width: 200,
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
        }
      ],
    }
    return configs;
  }


  /**
   * Configure ReportsFolder Grid
   */
  private defineReportsFolderGrid(): IchsGridComponentConfig<UserSecuredFileViewModel> {
    let configs: IchsGridComponentConfig<UserSecuredFileViewModel> = {
      primaryId: "id",
      defaultOrder: "timeStampCreated",
      defaultOrderType: "desc",
      entityDataSource: (filter: SearchViewModel) => this.userSecuredFileService.searchReportsFolder(filter),
      pageSize: 100,
      hasSearch: true,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: false,
      hasActionColumn: false,
      hasAdditionalActionsMenu: false,
      multiSelectConfig: {
        allowSelectObjects: true,
        hasSelectAllCheckbox: false,
      },
      preprocessData: (files: UserSecuredFileViewModel[]) => this.removeFileExtension(files),
      rowStyle: (file: UserSecuredFileViewModel) => file.isNew ? 'background-color: #f0f0f5; font-weight: bold' : null,
      headers: [
        {
          headerTitle: "Client Report Name",
          propertyName: "clientReportTitle",
          autoSearch: true,
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Medcor Report Name",
          propertyName: "fileName",
          autoSearch: true,
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Date Received",
          propertyName: "timeStampCreated",
          width: 200,
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
        }
      ],
    }
    return configs;
  }

  private removeFileExtension(files: UserSecuredFileViewModel[]) {
    files.forEach(file => {
      let idx = file.fileName.lastIndexOf('.');
      if (idx > -1) {
        file.fileName = file.fileName.substring(0, idx);
      }
    });
  }
}
