<div class="cont-padding">
  <div>
    <h1 class="main-title">Active Users</h1>
  </div>
  <hr class="main-hr" />
  <div class='widget-head widget-padding display-flex flex-items-centered command-toolbar'>
    <div class="flex-basis-0 flex-grow-1">
      <a class='cp-btn cp-grid-head-btn' (click)='sendMessage(null)'>Send Message To All</a>
    </div>
  </div>
  <hr class="main-hr fill-width" />
  <div class="display-flex" style="align-items:flex-start; flex-wrap:wrap;">
    <div *ngFor="let activeUser of activeUsers" class="active-user-item">
      <div class="display-flex flex-items-centered">
        <div>
          <div class="active-user-img">
            <img *ngIf="activeUser.userImage" [src]="activeUser.userImage" />
          </div>
        </div>
        <div class="flex-grow-1">
          <div class="info">
            <h2>{{activeUser.name}}</h2>
            <div> Online Since : {{ activeUser.loginTime | amTimeAgo }} </div>
            <div><a class='cp-btn cp-grid-head-btn' (click)='sendMessage(activeUser)'> Send Message </a></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
