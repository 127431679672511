
import { Component } from '@angular/core';
import { of } from 'rxjs';
import { ReportItemService, ReportItemViewModel, AppUserService, ReportingService } from '../../rest/index';
import { first, switchMap } from 'rxjs/operators';
import { LineOfBusiness } from '../../app.general.constants';
import { AlertMessageType, MedcorAlertService, AlertDismissType } from '../../services/medcor-alert.service';
import { SharedFunctionsService } from '../../services/shared-functions.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'reporting-list-dialog',
  templateUrl: './reporting-list-dialog.component.html',
  styleUrls: ['./reporting-list-dialog.component.css']
})
export class ReportingListDialogComponent {
  public lineOfBusinessList: string[] = [];

  reportList: ReportItemViewModel[] = [];
  searchText: string = '';
  selectedLineOfBusiness: string = LineOfBusiness.TRIAGE;
  newScheduleCreated: boolean = false;
  
  get filteredFavoriteReportList(): ReportItemViewModel[] {
    var lowerSearchText = this.searchText.toLowerCase();
    return this.filteredReportList.filter((reportItem) => {
      if (reportItem.isFavorite &&
        (lowerSearchText.length == 0 || reportItem.name.toLowerCase().indexOf(lowerSearchText) >= 0 ||
          reportItem.subTitle && reportItem.subTitle.toLowerCase().indexOf(lowerSearchText) >= 0 ||
          reportItem.description && reportItem.description.toLowerCase().indexOf(lowerSearchText) >= 0 ||
          reportItem.keyWords && reportItem.keyWords.toLowerCase().indexOf(lowerSearchText) >= 0) &&
        (reportItem.lineOfBusiness == this.selectedLineOfBusiness)) return true;
    });
  }

  get filteredReportList(): ReportItemViewModel[] {
    var lowerSearchText = this.searchText.toLowerCase();
    return this.reportList.filter((reportItem) => {
      if ((lowerSearchText.length == 0 || reportItem.name.toLowerCase().indexOf(lowerSearchText) >= 0 ||
        reportItem.subTitle && reportItem.subTitle.toLowerCase().indexOf(lowerSearchText) >= 0 ||
        reportItem.description && reportItem.description.toLowerCase().indexOf(lowerSearchText) >= 0 ||
        reportItem.keyWords && reportItem.keyWords.toLowerCase().indexOf(lowerSearchText) >= 0) &&
        (reportItem.lineOfBusiness == this.selectedLineOfBusiness)) return true;
    });
  }

  constructor(
    private reportItemService: ReportItemService,
    private appUserService: AppUserService,
    private alertService: MedcorAlertService,
    private _reportingService: ReportingService,
    private _sharedFunctionsService: SharedFunctionsService
  ) {
    this.reportItemService.getForUser().pipe(first()).subscribe(medcorResult => {
      if (!medcorResult || !medcorResult.result) {
        return;
      }

      this.reportList = medcorResult.result;
    });

    this.appUserService.getUserBusinessLines().pipe(first(), switchMap(response => {
      this.lineOfBusinessList = response.result.lineOfBusinessList;
      if (this.lineOfBusinessList.length > 0) {
        if (this.lineOfBusinessList.indexOf(LineOfBusiness.TRIAGE) == -1) {
          this.selectedLineOfBusiness = this.lineOfBusinessList[0];
        }

        return this._reportingService.reportingStatus(this.selectedLineOfBusiness);
      }
      else {
        this.alertService.addAlert({
          type: AlertMessageType.warning,
          title: 'Warning!',
          dismiss: AlertDismissType.controlled,
          messages: ["You don't have access on this data!"]
        });
        return of();
      }
    }))
      .subscribe(() => { },
        (error: HttpErrorResponse) => {
          this._sharedFunctionsService.showReportStatusDialog(error)
        });
  }


  clearFilter() {
    this.searchText = "";
  }

  changeLineOfBusiness(userLine: string) {
    this.selectedLineOfBusiness = userLine;

    this._reportingService.reportingStatus(this.selectedLineOfBusiness).pipe(first()).subscribe(() => { },
      (error: HttpErrorResponse) => {
        this._sharedFunctionsService.showReportStatusDialog(error)
      });
  }
}
