/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';












import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {MedcorResponse,PaginatedListViewModel,GroupSearchViewModel,GroupAppUserAssociationViewModel,SearchViewModel,VoidInfoViewModel,UserSearchViewModel,GroupViewModel} from '../index';


@Injectable()
export class GroupAppUserAssociationService {

    protected basePath = 'https://localhost/ClientPortalAPI';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Delete GroupAppUserAssociation
     * 
     * @param id Search Id
     * @param version object Version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(id?: number, version?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<GroupAppUserAssociationViewModel>>;
    public _delete(id?: number, version?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<GroupAppUserAssociationViewModel>>>;
    public _delete(id?: number, version?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<GroupAppUserAssociationViewModel>>>;
    public _delete(id?: number, version?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (version !== undefined && version !== null) {
            queryParameters = queryParameters.set('version', <any>version);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<MedcorResponse<GroupAppUserAssociationViewModel>>(`${this.basePath}/api/GroupAppUserAssociation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Void GroupAppUserAssociation
     * 
     * @param objVoid Void object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _void(objVoid?: VoidInfoViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<GroupAppUserAssociationViewModel>>;
    public _void(objVoid?: VoidInfoViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<GroupAppUserAssociationViewModel>>>;
    public _void(objVoid?: VoidInfoViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<GroupAppUserAssociationViewModel>>>;
    public _void(objVoid?: VoidInfoViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<GroupAppUserAssociationViewModel>>(`${this.basePath}/api/GroupAppUserAssociation/Void`,
            objVoid,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete By AppUserId and FolderId
     * 
     * @param appUserId 
     * @param groupId 
     * @param isGroup Indicator for the sucess message
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAssociation(appUserId?: number, groupId?: number, isGroup?: boolean, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<GroupAppUserAssociationViewModel>>;
    public deleteAssociation(appUserId?: number, groupId?: number, isGroup?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<GroupAppUserAssociationViewModel>>>;
    public deleteAssociation(appUserId?: number, groupId?: number, isGroup?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<GroupAppUserAssociationViewModel>>>;
    public deleteAssociation(appUserId?: number, groupId?: number, isGroup?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (appUserId !== undefined && appUserId !== null) {
            queryParameters = queryParameters.set('appUserId', <any>appUserId);
        }
        if (groupId !== undefined && groupId !== null) {
            queryParameters = queryParameters.set('groupId', <any>groupId);
        }
        if (isGroup !== undefined && isGroup !== null) {
            queryParameters = queryParameters.set('isGroup', <any>isGroup);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<GroupAppUserAssociationViewModel>>(`${this.basePath}/api/GroupAppUserAssociation/DeleteAssociation`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All GroupAppUserAssociations
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<GroupAppUserAssociationViewModel>>>;
    public get(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<GroupAppUserAssociationViewModel>>>>;
    public get(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<GroupAppUserAssociationViewModel>>>>;
    public get(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<GroupAppUserAssociationViewModel>>>(`${this.basePath}/api/GroupAppUserAssociation`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get a list of the group Ids associated with the user.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAssociatedGroupIds(observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<number>>>;
    public getAssociatedGroupIds(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<number>>>>;
    public getAssociatedGroupIds(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<number>>>>;
    public getAssociatedGroupIds(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<number>>>(`${this.basePath}/api/GroupAppUserAssociation/GetAssociatedGroupIds`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get GroupAppUserAssociation By Id
     * 
     * @param id Search Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(id: number, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<GroupAppUserAssociationViewModel>>;
    public getById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<GroupAppUserAssociationViewModel>>>;
    public getById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<GroupAppUserAssociationViewModel>>>;
    public getById(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getById.');
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<GroupAppUserAssociationViewModel>>(`${this.basePath}/api/GroupAppUserAssociation/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add Or Update GroupAppUserAssociation
     * If Id is 0 Then Add Else Update
     * @param objPost GroupAppUserAssociation To Add or Update
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public post(objPost?: GroupAppUserAssociationViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<GroupAppUserAssociationViewModel>>;
    public post(objPost?: GroupAppUserAssociationViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<GroupAppUserAssociationViewModel>>>;
    public post(objPost?: GroupAppUserAssociationViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<GroupAppUserAssociationViewModel>>>;
    public post(objPost?: GroupAppUserAssociationViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<GroupAppUserAssociationViewModel>>(`${this.basePath}/api/GroupAppUserAssociation`,
            objPost,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add List Of GroupAppUserAssociation
     * 
     * @param isGroup Indicator for the sucess message
     * @param arrPost GroupAppUserAssociations To Add
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postList(isGroup?: boolean, arrPost?: Array<GroupAppUserAssociationViewModel>, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<GroupAppUserAssociationViewModel>>>;
    public postList(isGroup?: boolean, arrPost?: Array<GroupAppUserAssociationViewModel>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<GroupAppUserAssociationViewModel>>>>;
    public postList(isGroup?: boolean, arrPost?: Array<GroupAppUserAssociationViewModel>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<GroupAppUserAssociationViewModel>>>>;
    public postList(isGroup?: boolean, arrPost?: Array<GroupAppUserAssociationViewModel>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isGroup !== undefined && isGroup !== null) {
            queryParameters = queryParameters.set('isGroup', <any>isGroup);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<Array<GroupAppUserAssociationViewModel>>>(`${this.basePath}/api/GroupAppUserAssociation/PostList`,
            arrPost,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recover GroupAppUserAssociation
     * 
     * @param id Search Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recover(id: number, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<GroupAppUserAssociationViewModel>>;
    public recover(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<GroupAppUserAssociationViewModel>>>;
    public recover(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<GroupAppUserAssociationViewModel>>>;
    public recover(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling recover.');
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<GroupAppUserAssociationViewModel>>(`${this.basePath}/api/GroupAppUserAssociation/Recover/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search GroupAppUserAssociation
     * &#x60;&#x60;&#x60;               {                  \&quot;filterExpression\&quot;: \&quot;userName &#x3D;&#x3D; \\\&quot;Medcor\\\&quot;\&quot;,                  \&quot;pageIndex\&quot;: 1,                  \&quot;pageSize\&quot;: 10,                  \&quot;sortExpressions\&quot;: [                   \&quot;userName\&quot;, \&quot;Age Desc\&quot;                  ]               }              &#x60;&#x60;&#x60;
     * @param objSearch Searh object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(objSearch?: SearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<GroupAppUserAssociationViewModel>>>;
    public search(objSearch?: SearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<GroupAppUserAssociationViewModel>>>>;
    public search(objSearch?: SearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<GroupAppUserAssociationViewModel>>>>;
    public search(objSearch?: SearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<GroupAppUserAssociationViewModel>>>(`${this.basePath}/api/GroupAppUserAssociation/Search`,
            objSearch,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search GroupAppUserAssociation
     * 
     * @param groupId Group Id
     * @param invert invert selection
     * @param objSearch Searh object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchAppUsersByGroupId(groupId?: number, invert?: boolean, objSearch?: SearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<UserSearchViewModel>>>;
    public searchAppUsersByGroupId(groupId?: number, invert?: boolean, objSearch?: SearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<UserSearchViewModel>>>>;
    public searchAppUsersByGroupId(groupId?: number, invert?: boolean, objSearch?: SearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<UserSearchViewModel>>>>;
    public searchAppUsersByGroupId(groupId?: number, invert?: boolean, objSearch?: SearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (groupId !== undefined && groupId !== null) {
            queryParameters = queryParameters.set('groupId', <any>groupId);
        }
        if (invert !== undefined && invert !== null) {
            queryParameters = queryParameters.set('invert', <any>invert);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<UserSearchViewModel>>>(`${this.basePath}/api/GroupAppUserAssociation/SearchAppUsers`,
            objSearch,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search GroupAppUserAssociation
     * 
     * @param appUserId AppUser Id
     * @param objSearch Searh object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public searchGroupsByAppUserId(appUserId?: number, objSearch?: SearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<GroupViewModel>>>;
    public searchGroupsByAppUserId(appUserId?: number, objSearch?: SearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<GroupViewModel>>>>;
    public searchGroupsByAppUserId(appUserId?: number, objSearch?: SearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<GroupViewModel>>>>;
    public searchGroupsByAppUserId(appUserId?: number, objSearch?: SearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (appUserId !== undefined && appUserId !== null) {
            queryParameters = queryParameters.set('appUserId', <any>appUserId);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<GroupViewModel>>>(`${this.basePath}/api/GroupAppUserAssociation/SearchGroups`,
            objSearch,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
