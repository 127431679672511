import { Component, ViewChildren, OnInit, AfterViewInit, Output, EventEmitter, Input, ViewChild, QueryList } from '@angular/core';
import { MatDialogConfig, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { IFilter, FilterType } from 'powerbi-models';
import { first } from 'rxjs/operators';
import { IchsPowerBIReport } from 'src/app/controls/ichs-powerbi-report/ichs-powerbi-report.component';
import { DashboardAdvanceFiltersDialogModel, DashboardAdvanceFiltersDialogComponent } from 'src/app/controls/ichs-dialog/dashboard-advance-filters-dialog/dashboard-advance-filters-dialog.component';
import { IchsDropDownTreeComponent } from 'src/app/controls/ichs-dropdown-tree/ichs-dropdown-tree.component';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from 'src/app/services/medcor-alert.service';
import { GeneralVariablesService } from 'src/app/services/general-variables.service';
import { DashboardService, SelectionItemViewModel, PowerBIReportViewModel, SelectionTreeViewModel, AdvancedPracticeService, DashboardItemViewModel, DashboardSchedulerViewModel, DashboardExportViewModel } from 'src/app/rest';
import { DateRangeEnum, SharedFiltersHelper } from 'src/app/controls/ichs-dialog/shared-filters-helper.component';
import { LineOfBusiness, ValidationMessages, DashboardSettings } from 'src/app/app.general.constants';
import { AdvancedDateFilterationAttributes, DashboardFilterationValues, DateFilterControlConfig, DropdownTreeFilterationValues, FilterControlConfig } from '../dashboard.component';
import * as moment from 'moment';
import { SharedFunctionsService } from '../../../services/shared-functions.service';
import { InputDialogConfig, IchsControl, IchsInputDialogComponent, IchsSelect, IchsTextBox } from 'src/app/controls/ichs-dialog/ichs-input-dialog/ichs-input-dialog.component';
import { AppGenericLists } from 'src/app/app.general.lists';
import { HttpEventType } from '@angular/common/http';
import { DashboardSchedulerComponent, DashboardSchedulerConfig } from '../../dashboard-scheduler/dashboard-scheduler.component';
import { UserDashboardBookmarkViewModel } from 'src/app/rest/model/userDashboardBookmarkViewModel';
import { IchsDialogComponent } from 'src/app/controls/ichs-dialog/ichs-dialog.component';

@Component({
  selector: 'advanced-practice-dashboard',
  templateUrl: './advanced-practice-dashboard.component.html',
  styleUrls: ['./advanced-practice-dashboard.component.css'],
})
export class AdvancedPracticeDashboardComponent implements OnInit, AfterViewInit {
  @Input() dashboardScheduler: DashboardSchedulerViewModel;
  @Output() maximized: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild(IchsPowerBIReport) powerBIReport: IchsPowerBIReport;

  //Power BI Control properties
  powerBIResult: PowerBIReportViewModel;
  accessToken: string;
  hasAdvancedFilters: boolean;
  filters: Array<any> = [];
  userDataGroups: SelectionItemViewModel[] = [];
  selectedLineOfBusiness: string = LineOfBusiness.ADVANCED_PRACTICE;
  isMaximized: boolean = false;
  advancedCustomFiltersDialogData: DashboardAdvanceFiltersDialogModel = <DashboardAdvanceFiltersDialogModel>{
    title: "Dashboard Filters",
    baseIds: [],
    filters: [],
    gridFilters: [],
    lineOfBusiness: this.selectedLineOfBusiness,
  };

  //Drop down tree properties 
  advPractices: SelectionTreeViewModel[] = [];
  dates: SelectionTreeViewModel[] = [];

  @ViewChildren(IchsDropDownTreeComponent) allDropDownTreesQueryList: QueryList<IchsDropDownTreeComponent>;
  allDropDownTrees: IchsDropDownTreeComponent[];
  allDropDownTreesExceptDateTree: IchsDropDownTreeComponent[];
  enabledDropDownTrees: IchsDropDownTreeComponent[];
  dateDropDownTree: IchsDropDownTreeComponent;

  filterControlsConfig: AdvancedPracticeDashboardFilterControlsConfig = new AdvancedPracticeDashboardFilterControlsConfig();

  dateRangesItems: SelectionItemViewModel[];
  advancedDateFilters: IFilter[] = [];
  advancedDateFilterationAttributes: AdvancedDateFilterationAttributes = <AdvancedDateFilterationAttributes>{};

  storedFilterationValues: DashboardFilterationValues;

  exportFilter: string;
  isBookmarksPaneVisible: boolean = false;
  bookmarks: UserDashboardBookmarkViewModel[];

  constructor(
    private advPracticeService: AdvancedPracticeService,
    private dashboardService: DashboardService,
    private alertService: MedcorAlertService,
    private dialog: MatDialog,
    private generalVariables: GeneralVariablesService,
    private sharedFilterHelper: SharedFiltersHelper,
    private sharedFunctions: SharedFunctionsService,
  ) {

  }

  ngOnInit() {
    this.dateRangesItems = this.sharedFilterHelper.BuildDateRanges();
    ///get and set the line of business
    this.loadControls();
    this.dashboardService.getUserDataGroups(this.selectedLineOfBusiness).pipe(first()).subscribe(res => {
      this.userDataGroups = res.result;
    });
  }

  ngAfterViewInit() {
    this.allDropDownTreesExceptDateTree = this.allDropDownTreesQueryList.filter(tree => tree.name != this.filterControlsConfig.dateHierarchy.name);
    this.dateDropDownTree = this.allDropDownTreesQueryList.find(tree => tree.name == this.filterControlsConfig.dateHierarchy.name);
    this.allDropDownTrees = this.dateDropDownTree.hideDataTree ? this.allDropDownTreesExceptDateTree : this.allDropDownTreesQueryList.toArray();
    this.enabledDropDownTrees = this.allDropDownTrees;
  }

  loadControls() {
    //Load dashboard related content from API
    this.dashboardService.generatePowerBIDashboard(this.selectedLineOfBusiness).pipe(first()).subscribe(
      returnValue => {
        if (returnValue.result) {
          this.powerBIResult = returnValue.result!;
          this.bookmarks = this.powerBIResult.userDashboardBookmarks;
          this.loadDashbaord();
        }
      }
    );
  }

  //Set dashboard properties
  loadDashbaord() {
    if (!this.powerBIResult.dashboardItem) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: ["You have not selected any dashboard item in Dashboard Maintenance."]
      });
      return;
    }
    this.accessToken = this.powerBIResult.accessTokenDetails!.accessToken!;
    this.initializeFilters();
  }

  //Initialize filters
  initializeFilters() {
    this.populateStoredDashboardFilterationValues();

    this.setAdvPracticeFilter();

    //build filter controls
    this.buildFilterControls();
  }

  //Fill filters controls
  buildFilterControls() {
    //advanced filter dialog
    this.advancedCustomFiltersDialogData = <DashboardAdvanceFiltersDialogModel>{
      title: "Dashboard Filters",
      baseIds: this.powerBIResult.advPracticeIds!,
      filters: [],
      gridFilters: [],
      userDataGroups: this.userDataGroups,
      lineOfBusiness: this.selectedLineOfBusiness
    };

    if (sessionStorage.getItem(this.filterControlsConfig.practiceHierarchy.sessionStorageKey)) {
      let _advPractices = JSON.parse(sessionStorage.getItem(this.filterControlsConfig.practiceHierarchy.sessionStorageKey)) as SelectionTreeViewModel[];

      if (this.storedFilterationValues && this.storedFilterationValues.dropdownTreeFilterationValues) {
        let storedAdvPractices = this.storedFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.practiceHierarchy.name);
        if (storedAdvPractices) {
          this.sharedFunctions.correctTreeNodes(_advPractices[0], this.filterControlsConfig.practiceHierarchy.isLevel, storedAdvPractices.included, storedAdvPractices.excluded);
        }
      }

      this.advPractices = _advPractices;
    }
    else {
      this.advPracticeService.getAdvancedPracticeTree().pipe(first()).subscribe(result => {
        if (result.result) {
          let _advPractices = result.result;

          try {
            sessionStorage.setItem(this.filterControlsConfig.practiceHierarchy.sessionStorageKey, JSON.stringify(_advPractices));
          }
          catch (e) {
            let ex = e as Error;
            if (ex.name === 'QuotaExceededError') {
              console.warn(ex.message);
            }
            else {
              throw e;
            }
          }

          if (this.storedFilterationValues && this.storedFilterationValues.dropdownTreeFilterationValues) {
            let storedAdvPractices = this.storedFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.practiceHierarchy.name);
            if (storedAdvPractices) {
              this.sharedFunctions.correctTreeNodes(_advPractices[0], this.filterControlsConfig.practiceHierarchy.isLevel, storedAdvPractices.included, storedAdvPractices.excluded);
            }
          }

          this.advPractices = _advPractices;
        }
      });
    }

    if (this.dateDropDownTree.hideDataTree) {
      if (this.storedFilterationValues && this.storedFilterationValues.dateRange) {
        this.advancedDateFilterationAttributes.dateRange = this.storedFilterationValues.dateRange;
        setTimeout(() => this.onDateRangeChange());
      }
      else if (this.storedFilterationValues && this.storedFilterationValues.startDate && this.storedFilterationValues.endDate) {
        this.advancedDateFilterationAttributes.startDate = moment(this.storedFilterationValues.startDate).toDate();
        this.advancedDateFilterationAttributes.endDate = moment(this.storedFilterationValues.endDate).toDate();
        setTimeout(() => this.onDatesChange());
      }
      else {
        this.advancedDateFilterationAttributes.dateRange = DateRangeEnum.YearToDate;
        setTimeout(() => this.onDateRangeChange());
      }
    }
    else {
      if (sessionStorage.getItem(this.filterControlsConfig.dateHierarchy.sessionStorageKey)) {
        let _dates = JSON.parse(sessionStorage.getItem(this.filterControlsConfig.dateHierarchy.sessionStorageKey)) as SelectionTreeViewModel[];

        if (this.storedFilterationValues && this.storedFilterationValues.dateRange) {
          this.dates = _dates;
          this.advancedDateFilterationAttributes.dateRange = this.storedFilterationValues.dateRange;
          setTimeout(() => this.onDateRangeChange());
        }
        else if (this.storedFilterationValues && this.storedFilterationValues.startDate && this.storedFilterationValues.endDate) {
          this.dates = _dates;
          this.advancedDateFilterationAttributes.startDate = moment(this.storedFilterationValues.startDate).toDate();
          this.advancedDateFilterationAttributes.endDate = moment(this.storedFilterationValues.endDate).toDate();
          setTimeout(() => this.onDatesChange());
        }
        else if (this.storedFilterationValues && this.storedFilterationValues.dropdownTreeFilterationValues) {
          let storedDates = this.storedFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.dateHierarchy.name);
          if (storedDates) {
            this.sharedFunctions.correctTreeNodes(_dates[0], this.filterControlsConfig.dateHierarchy.isLevel, storedDates.included, storedDates.excluded);
          }
          this.dates = _dates;
        }
        else {
          this.dates = _dates;
          this.advancedDateFilterationAttributes.dateRange = DateRangeEnum.YearToDate;
          setTimeout(() => this.onDateRangeChange());
        }
      }
      else {
        //Fill date dropdown tree
        this.advPracticeService.getAdvancedPracticeHierarchyLevels(
          {
            hierarchyName: '[Dim Date].[Date Hierarchy]',
            isHierarchyLevels: true,
            levelsOrColumns: 4,
            stopOnCompany: false
          }
        ).pipe(first()).subscribe(
          returnValue => {
            if (returnValue.result) {
              let _dates = returnValue.result!.selectionTrees;

              try {
                sessionStorage.setItem(this.filterControlsConfig.dateHierarchy.sessionStorageKey, JSON.stringify(_dates));
              }
              catch (e) {
                let ex = e as Error;
                if (ex.name === 'QuotaExceededError') {
                  console.warn(ex.message);
                }
                else {
                  throw e;
                }
              }

              if (this.storedFilterationValues && this.storedFilterationValues.dateRange) {
                this.dates = _dates;
                this.advancedDateFilterationAttributes.dateRange = this.storedFilterationValues.dateRange;
                setTimeout(() => this.onDateRangeChange());
              }
              else if (this.storedFilterationValues && this.storedFilterationValues.startDate && this.storedFilterationValues.endDate) {
                this.dates = _dates;
                this.advancedDateFilterationAttributes.startDate = moment(this.storedFilterationValues.startDate).toDate();
                this.advancedDateFilterationAttributes.endDate = moment(this.storedFilterationValues.endDate).toDate();
                setTimeout(() => this.onDatesChange());
              }
              else if (this.storedFilterationValues && this.storedFilterationValues.dropdownTreeFilterationValues) {
                let storedDates = this.storedFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.dateHierarchy.name);
                if (storedDates) {
                  this.sharedFunctions.correctTreeNodes(_dates[0], this.filterControlsConfig.dateHierarchy.isLevel, storedDates.included, storedDates.excluded);
                }
                this.dates = _dates;
              }
              else {
                this.dates = _dates;
                this.advancedDateFilterationAttributes.dateRange = DateRangeEnum.YearToDate;
                setTimeout(() => this.onDateRangeChange());
              }
            }
          }
        );
      }
    }
  }

  //Initialize the advanced dashoard filters dialog
  public advanceFilters(): any {
    let dialogConfig: MatDialogConfig<DashboardAdvanceFiltersDialogModel> = {
      data: this.advancedCustomFiltersDialogData,
      width: '600px',
      maxHeight: '750px',
      disableClose: true
    };

    let dialogRef: MatDialogRef<DashboardAdvanceFiltersDialogComponent, DashboardAdvanceFiltersDialogModel> =
      this.dialog.open(DashboardAdvanceFiltersDialogComponent, dialogConfig);

    //Get result data from dialog when close
    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (!result) return;
      this.advancedCustomFiltersDialogData = result;
      this.hasAdvancedFilters = result.filters.length > 0;
      this.setFilters();
    });
  }

  resetDateFilters() {
    this.resetAdvancedDateFilters();
    this.resetDateDropDownTree();
  }

  resetAdvancedDateFilters() {
    this.advancedDateFilterationAttributes = <AdvancedDateFilterationAttributes>{};
    this.advancedDateFilters = [];
    this.enabledDropDownTrees = this.allDropDownTrees;
  }

  onAdvancedDateFiltersChange(advancedDateFilterationAttributes: AdvancedDateFilterationAttributes) {
    if (advancedDateFilterationAttributes) {
      this.advancedDateFilterationAttributes = advancedDateFilterationAttributes;
      this.setAdvancedDateFilters();
    }
    else {
      this.resetAdvancedDateFilters();

      if (this.dateDropDownTree.hideDataTree) {
        // resetting date dropdown tree, will reset its selection text
        this.resetDateDropDownTree();
      }
    }
  }

  onDateRangeChange() {
    var dates = this.sharedFilterHelper.populateDateFilters(this.advancedDateFilterationAttributes.dateRange);
    this.advancedDateFilterationAttributes.startDate = moment(dates.startDate).toDate();
    this.advancedDateFilterationAttributes.endDate = moment(dates.endDate).toDate();
    this.dateDropDownTree.selectionModel.selectedText = this.advancedDateFilterationAttributes.dateRange;
    this.setAdvancedDateFilters();
  }

  onDatesChange() {
    if (this.advancedDateFilterationAttributes.startDate && this.advancedDateFilterationAttributes.endDate) {
      this.advancedDateFilterationAttributes.dateRange = "Custom";
      this.dateDropDownTree.selectionModel.selectedText =
        moment(this.advancedDateFilterationAttributes.startDate).format('MM/DD/YYYY') +
        " - " +
        moment(this.advancedDateFilterationAttributes.endDate).format('MM/DD/YYYY');

      this.setAdvancedDateFilters();
    }
  }

  setAdvancedDateFilters() {
    if (this.advancedDateFilterationAttributes.startDate && this.advancedDateFilterationAttributes.endDate) {
      let filter = {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: this.filterControlsConfig.dateHierarchy.advancedDateFilterTarget,
        logicalOperator: "And",
        conditions: [
          {
            operator: "GreaterThanOrEqual",
            value: this.formatDate(this.advancedDateFilterationAttributes.startDate),
          },
          {
            operator: "LessThanOrEqual",
            value: this.formatDate(this.advancedDateFilterationAttributes.endDate),
          }
        ],
        filterType: FilterType.Advanced
      };

      this.advancedDateFilters = [filter];

      // exclude date tree from filteration
      this.enabledDropDownTrees = this.allDropDownTreesExceptDateTree;

      // clear date filter tree
      this.dateDropDownTree.data.forEach(obj => {
        this.dateDropDownTree.resetNode(obj);
      });

      this.setFilters();
    }
  }

  //Clear dashboard filters
  clearFilter() {
    this.hasAdvancedFilters = false;

    this.advancedDateFilters = [];
    this.advancedDateFilterationAttributes = <AdvancedDateFilterationAttributes>{};

    this.advancedCustomFiltersDialogData = <DashboardAdvanceFiltersDialogModel>{
      title: "Dashboard Filters",
      baseIds: this.powerBIResult.advPracticeIds!,
      filters: [],
      gridFilters: [],
      lineOfBusiness: this.selectedLineOfBusiness
    };

    this.filters.splice(1);

    this.resetDateFilters();
    this.allDropDownTreesExceptDateTree.forEach(dropDownTree => {
      dropDownTree.disabled = false;
      dropDownTree.reset();
    });

    this.powerBIReport.dataSelectionFilters.splice(0);
    this.powerBIReport.reload();
  }

  //Set Dashboard filters
  setFilters() {
    this.filters.splice(1);

    if (this.advancedCustomFiltersDialogData.filters.length > 0) {
      this.advancedCustomFiltersDialogData.filters.forEach(filter => this.filters.push(filter));
    }
    if (this.advancedDateFilters.length > 0) {
      this.advancedDateFilters.forEach(filter => this.filters.push(filter));
    }

    this.enabledDropDownTrees.forEach(dropDownTree => {
      var selection;
      if (dropDownTree.selectedLeaves)
        selection = dropDownTree.getLeafSelection();
      else
        selection = dropDownTree.getSelection();

      if (selection.included.length === 1 && selection.included[0] === '0') {
        // if all selected, then don't set any filters
      } else if (selection.excluded.length === 1 && selection.excluded[0] === '0') {
        // if all excluded, filter on a string that will never occur, to retrive null data
        this.filterControls(dropDownTree.name, ["string_that_will_never_occur_in_data"], true);
      }
      else if (selection.included.length <= selection.excluded.length) {
        if (dropDownTree.selectedLeaves)
          this.filterControls(dropDownTree.name, selection.included, true);
        else
          this.filterControls(dropDownTree.name, selection.included.map(obj => obj.slice(2)), true);
      } else {
        if (dropDownTree.selectedLeaves)
          this.filterControls(dropDownTree.name, selection.excluded, false);
        else
          this.filterControls(dropDownTree.name, selection.excluded.map(obj => obj.slice(2)), false);
      }
    });

    // don't store before ensuring that all drop-down trees are loaded
    if ((this.dateDropDownTree.hideDataTree || this.dates.length > 0) && this.advPractices.length > 0) {
      this.storeDashboardFilterationValues();
    }

    if (this.powerBIReport) {
      this.powerBIReport.setFilters(this.filters);
    }

    this.setExportFilter();
  }

  filterControls(controlName: string, ids: string[], isInclude: boolean) {
    switch (controlName) {
      case this.filterControlsConfig.dateHierarchy.name: {
        this.setDateFilter(ids, isInclude);
        break;
      }
      case this.filterControlsConfig.practiceHierarchy.name: {
        this.setAdvPracticeSelectionFilter(ids, isInclude);
        break;
      }
    }
  }

  showDashboardError() {
    this.alertService.addAlert({
      type: AlertMessageType.warning,
      title: 'Warning!',
      dismiss: AlertDismissType.auto,
      messages: ["Dashboard items were not loaded correctly."]
    });
  }

  setAdvPracticeFilter() {
    if (this.powerBIResult.advPracticeIds) {
      let filter = {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: this.filterControlsConfig.practiceHierarchy.filterTarget,
        filterType: FilterType.Basic,
        operator: 'In',
        values: this.powerBIResult.advPracticeIds!
      };
      this.filters.push(filter);
    }
  }

  //Set Date hierarchy filter 
  setDateFilter(dates: string[], isIncluded: boolean) {
    var conditions: any[] = [];
    if (dates.length > 0) {
      dates.forEach(item => conditions.push({ operator: (!isIncluded ? 'DoesNotStartWith' : 'StartsWith'), value: item.toString() }));
      let filter = {
        $schema: 'http://powerbi.com/product/schema#advanced',
        target: this.filterControlsConfig.dateHierarchy.filterTarget,
        logicalOperator: (!isIncluded ? 'And' : 'Or'),
        filterType: FilterType.Advanced,
        conditions: conditions
      };

      this.filters.push(filter);
    }
  }

  setAdvPracticeSelectionFilter(hierarchies: string[], isIncluded: boolean) {
    let practiceFilter = this.filters.find(filter => filter.target == this.filterControlsConfig.practiceHierarchy.filterTarget)
    if (practiceFilter) {
      practiceFilter.values = [];
      if (hierarchies.length == 0) {
        practiceFilter.values = this.powerBIResult.advPracticeIds!
      }
      else if (!isIncluded) {
        for (var i = 0; i < this.powerBIResult.advPracticeIds!.length; i++) {
          if (hierarchies.indexOf(this.powerBIResult.advPracticeIds[i]) > -1) {
            continue;
          }
          else {
            practiceFilter.values.push(this.powerBIResult.advPracticeIds[i]);
          }
        }
      }
      else {
        practiceFilter.values = hierarchies;
      }
    }
  }

  itemMaximized(isMaximized: boolean) {
    this.isMaximized = isMaximized;
    this.maximized.emit(this.isMaximized);
  }


  viewExportDashboardDialog() {
    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: new InputDialogConfig({
        hasTitle: false,
        message: "Please select exported file format",
        controls: [
          new IchsSelect({
            required: true,
            placeholder: "File format",
            items: AppGenericLists.DashboardExportFormat,
          })
        ],
      }),
      width: "400px",
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        let exportFormat = result[0].value;
        this.exportDashboardToFile(exportFormat);
      }
    });
  }

  scheduleDashboard() {
    if (!this.powerBIResult || !this.powerBIResult.dashboardItem || this.advPractices.length == 0) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.auto,
        messages: [ValidationMessages.WAIT_DASHBOARD_FULL_LOAD]
      });
      return;
    }

    let dialogConfig: MatDialogConfig<DashboardSchedulerConfig> = {
      data: {
        dashboard: <DashboardItemViewModel>{
          id: this.powerBIResult.dashboardItem.dashboardItemId,
          name: this.powerBIResult.dashboardItem.name,
        },
        dateHierarchy: {
          label: this.filterControlsConfig.dateHierarchy.label,
          name: this.filterControlsConfig.dateHierarchy.name,
          advancedDateFilterationAttributes: JSON.parse(JSON.stringify(this.advancedDateFilterationAttributes)),
        },
        secondHierarchy: {
          label: this.filterControlsConfig.practiceHierarchy.label,
          name: this.filterControlsConfig.practiceHierarchy.name,
          data: JSON.parse(JSON.stringify(this.advPractices)),
          isLevel: false,
        },
      },
      width: '800px',
      disableClose: true
    };

    this.dialog.open(DashboardSchedulerComponent, dialogConfig);
  }

  addNewBookmark() {
    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: new InputDialogConfig({
        title: "Save to 'My Views'",
        message: "Enter a name for this view",
        controls: [
          new IchsTextBox({
            required: true,
            placeholder: "Example: December 2019 Sales Profit",
          })
        ],
      }),
      width: "400px",
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        let newBookmarkName = result[0].value;

        this.powerBIReport.captureBookmarkState().then(bookmark => {
          this.dashboardService.addDashboardBookmark({
            name: newBookmarkName,
            data: bookmark.state,
            userDashboardItemAssociationId: this.powerBIResult.dashboardItem.userDashboardItemAssociationId,
            dashboardFiltrationValues: JSON.stringify(this.getCurrentDashboardFilterationValues()),
          }).pipe(first()).subscribe(resp => {
            let newBookmark = resp.result;
            this.bookmarks.push(<UserDashboardBookmarkViewModel>{
              id: newBookmark.id,
              name: newBookmark.name,
            });
          });
        });
      }
    });
  }

  applyBookmark(bookmarkId: number) {
    this.dashboardService.getDashboardBookmarkAndSetAsDefault(bookmarkId).pipe(first()).subscribe(resp => {
      this.powerBIReport.bookmarkAppliedManually = true;
      let bookmark = resp.result;

      this.powerBIReport.applyBookmarkState(bookmark.data).then(() => {
        this.applyBookmarkFilters(bookmark);
      })
    });

    this.hideBookmarksPane();
  }

  deleteBookmark(bookmarkId: number) {
    let dialogRef = this.dialog.open(IchsDialogComponent, {
      width: '450px',
      data: { title: "Delete Bookmark", message: "Are you sure about deleting this bookmark?" }
    });

    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result) {
        this.dashboardService.deleteDashboardBookmark(bookmarkId).pipe(first()).subscribe(() => {
          this.bookmarks = this.bookmarks.filter(bookmark => bookmark.id != bookmarkId);
        });
      }
    });
  }

  showBookmarksPane() {
    this.isBookmarksPaneVisible = true;
  }

  hideBookmarksPane() {
    this.isBookmarksPaneVisible = false;
  }

  private applyBookmarkFilters(bookmark: UserDashboardBookmarkViewModel) {
    let bookmarkStoredFilterationValues = JSON.parse(bookmark.dashboardFiltrationValues) as DashboardFilterationValues;
    let storedPractices = bookmarkStoredFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.practiceHierarchy.name);
    this.sharedFunctions.correctTreeNodes(this.advPractices[0], this.filterControlsConfig.practiceHierarchy.isLevel, storedPractices.included, storedPractices.excluded);

    for (let tree of this.allDropDownTreesExceptDateTree) {
      tree.selectionChanged();
    }

    if (bookmarkStoredFilterationValues.dateRange) {
      this.advancedDateFilterationAttributes.dateRange = bookmarkStoredFilterationValues.dateRange;
      this.onDateRangeChange();
    }
    else if (bookmarkStoredFilterationValues.startDate && bookmarkStoredFilterationValues.endDate) {
      this.advancedDateFilterationAttributes.startDate = new Date(bookmarkStoredFilterationValues.startDate);
      this.advancedDateFilterationAttributes.endDate = new Date(bookmarkStoredFilterationValues.endDate);
      this.onDatesChange();
    }
    else if (!this.dateDropDownTree.hideDataTree) {
      let storedDates = bookmarkStoredFilterationValues.dropdownTreeFilterationValues.find(obj => obj.treeName == this.filterControlsConfig.dateHierarchy.name);
      if (storedDates) {
        this.sharedFunctions.correctTreeNodes(this.dates[0], this.filterControlsConfig.dateHierarchy.isLevel, storedDates.included, storedDates.excluded);
        this.dateDropDownTree.selectionChanged();
      }
    }
  }

  private setExportFilter() {
    let practiceFilter = this.filters.find(filter => filter.target == this.filterControlsConfig.practiceHierarchy.filterTarget);
    let practiceFilterTable = practiceFilter.target.table.replace(new RegExp(' ', 'ig'), DashboardSettings.DASHBOARD_EXPORT_FILTER_SPACE_ESCAPE);
    let practiceFilterColumn = practiceFilter.target.column.replace(new RegExp(' ', 'ig'), DashboardSettings.DASHBOARD_EXPORT_FILTER_SPACE_ESCAPE);

    let _values = practiceFilter.values as string[];
    let valueList = _values.map(val => `'${val}'`).join(',');
    this.exportFilter = `${practiceFilterTable}/${practiceFilterColumn} in (${valueList})`;

    let dateRangeFilter = this.filters.find(filter => filter.target == this.filterControlsConfig.dateHierarchy.advancedDateFilterTarget);
    if (dateRangeFilter) {
      let dateRangeFilterTable = dateRangeFilter.target.table.replace(new RegExp(' ', 'ig'), DashboardSettings.DASHBOARD_EXPORT_FILTER_SPACE_ESCAPE);
      let dateRangeFilterColumn = dateRangeFilter.target.column.replace(new RegExp(' ', 'ig'), DashboardSettings.DASHBOARD_EXPORT_FILTER_SPACE_ESCAPE);

      let dateRangeExportFilter = `${dateRangeFilterTable}/${dateRangeFilterColumn} ge ${this.formatDate(this.advancedDateFilterationAttributes.startDate)} and ${dateRangeFilterTable}/${dateRangeFilterColumn} le ${this.formatDate(this.advancedDateFilterationAttributes.endDate)}`;
      this.exportFilter += ` and ${dateRangeExportFilter}`;
    }
  }

  private exportDashboardToFile(exportFormat: string) {
    let dashboardExportVM: DashboardExportViewModel = {
      userDashboardItemAssociationId: this.powerBIResult.dashboardItem.userDashboardItemAssociationId,
      exportFormat: exportFormat,
      filter: this.exportFilter
    };

    let sub = this.dashboardService.exportDashboardToFile(dashboardExportVM, 'response', true)
      .subscribe(
        event => {
          if (event.type == HttpEventType.Response) {
            this.sharedFunctions.saveToFileSystem(event);
          }
        },
        () => { sub.unsubscribe() },
        () => { sub.unsubscribe() });
  }

  // un-select all years in date drop-down tree except last year
  private resetDateDropDownTree() {
    let numberOfItemsToKeepSelected = 1;

    if (!this.dateDropDownTree.hideDataTree && this.dates[0] && this.dates[0].children.length > numberOfItemsToKeepSelected) {
      let nodesToSelect = this.dates[0].children.slice(-numberOfItemsToKeepSelected);
      let nodesToDeselect = this.dates[0].children.slice(0, this.dates[0].children.length - numberOfItemsToKeepSelected);

      this.dates[0].partialSelection = true;
      nodesToSelect.forEach(node => { node.selected = true; node.partialSelection = false; this.toggleNodeChildren(node); });
      nodesToDeselect.forEach(node => { node.selected = false; node.partialSelection = false; this.toggleNodeChildren(node); });

      this.dateDropDownTree.selectionChanged();
    }
    else {
      this.dateDropDownTree.reset();
    }
  }

  private toggleNodeChildren(node: SelectionTreeViewModel) {
    if (!node || !node.children || node.children.length == 0) {
      return;
    }
    node.children.forEach(child => {
      child.partialSelection = false;
      child.selected = node.selected;
      this.toggleNodeChildren(child);
    });
  }

  private formatDate(date): number {
    let _date = null;

    if (date instanceof Date) {
      _date = date;
    }
    else {  // Moment
      _date = date.toDate();
    }

    let day = _date.getDate();
    let monthIndex = _date.getMonth() + 1;
    let year = _date.getFullYear();

    return (((year * 100) + monthIndex) * 100) + day;
  }

  private storeDashboardFilterationValues() {
    // local storage is disabled in case of impersonation
    if (this.generalVariables.userImpersonationInfo.isImpersonatedInd) {
      return;
    }

    let dashboardFilterValues = this.getCurrentDashboardFilterationValues();

    try {
      localStorage.setItem(this.filterControlsConfig.localStorageKey, JSON.stringify(dashboardFilterValues));
    }
    catch (e) {
      let ex = e as Error;
      if (ex.name === 'QuotaExceededError') {
        console.warn(ex.message);
      }
      else {
        throw e;
      }
    }
  }

  private getCurrentDashboardFilterationValues() {
    let dashboardFilterValues: DashboardFilterationValues = new DashboardFilterationValues();

    if (this.advancedDateFilterationAttributes.dateRange && this.advancedDateFilterationAttributes.dateRange != "Custom") {
      dashboardFilterValues.dateRange = this.advancedDateFilterationAttributes.dateRange;
    }
    else if (this.advancedDateFilterationAttributes.startDate && this.advancedDateFilterationAttributes.endDate) {
      dashboardFilterValues.startDate = moment(this.advancedDateFilterationAttributes.startDate).format('YYYY-MM-DD');
      dashboardFilterValues.endDate = moment(this.advancedDateFilterationAttributes.endDate).format('YYYY-MM-DD');
    }

    this.enabledDropDownTrees.forEach(dropDownTree => {
      var selection = dropDownTree.getSelection();
      let dropdownTreeFilterValues = <DropdownTreeFilterationValues>{
        treeName: dropDownTree.name,
        included: selection.included,
        excluded: selection.excluded,
      };

      dashboardFilterValues.dropdownTreeFilterationValues.push(dropdownTreeFilterValues)
    });

    return dashboardFilterValues;
  }

  private populateStoredDashboardFilterationValues() {
    if (this.dashboardScheduler) {
      this.storedFilterationValues = JSON.parse(this.dashboardScheduler.dashboardParam) as DashboardFilterationValues;
    }
    else {
      // local storage is disabled in case of impersonation
      if (this.generalVariables.userImpersonationInfo.isImpersonatedInd) {
        return;
      }

      let _storedValues = localStorage.getItem(this.filterControlsConfig.localStorageKey);
      this.storedFilterationValues = JSON.parse(_storedValues) as DashboardFilterationValues;
    }
  }
}

export class AdvancedPracticeDashboardFilterControlsConfig {
  public readonly localStorageKey: string = "AdvancedPracticeDashboardFilterationValues";

  public readonly dateHierarchy: DateFilterControlConfig = {
    label: "Date Range",
    name: "DateFilter",
    isLevel: true,
    sessionStorageKey: "advPracticeDates",
    filterTarget: {
      table: "Dim Date",
      column: "Filter",
    },
    advancedDateFilterTarget: {
      table: "Dim Date",
      column: 'Date Integer'
    },
  };
  public readonly practiceHierarchy: FilterControlConfig = {
    label: "Practice",
    name: "AdvancedPracticeFilter",
    isLevel: false,
    sessionStorageKey: "advPracticeIds",
    filterTarget: {
      table: "Dim Location",
      column: "Location ID"
    },
  };
}