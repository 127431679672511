import { Component, OnInit } from '@angular/core';
import { FileRequestViewModel } from '../../rest/model/fileRequestViewModel';
import { SearchViewModel, FileRequestService, UserSecuredFileService } from '../../rest';
import { IchsControl, InputDialogConfig, IchsTextBox, IchsTextArea, IchsInputDialogComponent } from '../../controls/ichs-dialog/ichs-input-dialog/ichs-input-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';
import { IchsDialogComponent } from '../../controls/ichs-dialog/ichs-dialog.component';
import { first } from 'rxjs/operators';
import { IchsGridComponentConfig, IchsGridActionsConfig, IchsControlType } from '../../controls/ichs-grid/grid-configs';
import { DownLoadRequestFileComponent } from '../download-request-file/download-request-file.component';
import { GeneralVariablesService } from '../../services/general-variables.service';

@Component({
  selector: 'file-request',
  templateUrl: './file-request.component.html',
  styleUrls: ['./file-request.component.css']
})
export class FileRequestComponent implements OnInit {

  gridConfig: IchsGridComponentConfig<FileRequestViewModel>;

  dialogConfig: InputDialogConfig = new InputDialogConfig({
    title: "Request File",
    controls: []
  });

  constructor(
    public fileRequestService: FileRequestService,
    public alertService: MedcorAlertService,
    public dialog: MatDialog,
    public userFiles: UserSecuredFileService,
    public generalVariables: GeneralVariablesService,
  ) {
    this.gridConfig = this.defineGrid();
  }

  ngOnInit() {
    this.dialogConfig.controls.push(new IchsTextBox({
      label: "Request File From",
      placeholder: "Enter E-mail",
      required: true,
      isEmail: true,
    }));

    this.dialogConfig.controls.push(new IchsTextBox({
      label: "Title",
      required: true,
    }));

    if (!this.generalVariables.userImpersonationInfo.impersonatorRoleInd) {
      Promise.resolve().then(() => {
        this.openNewRequestDialog();
      });
    }
  }

  openNewRequestDialog() {
    this.dialogConfig.controls![0].value = ""; //reset the email
    this.dialogConfig.controls![1].value = ""; //reset the old title
    let dialogRef: MatDialogRef<IchsInputDialogComponent, IchsControl[]> = this.dialog.open(IchsInputDialogComponent, {
      data: this.dialogConfig,
      width: "800px",
    });
    dialogRef.afterClosed().pipe(first()).subscribe(result => {
      if (result && result.length == 2 && result[0].value.length > 0) {
        this.fileRequestService.post({
          id: 0,
          email: result![0].value,
          title: result![1].value,
        }).pipe(first()).subscribe(result => {
          this.gridConfig.refreshGrid!(0);
          this.dialog.open(IchsDialogComponent, {
            width: '450px',
            data: {
              title: "Request File Created",
              message: "Your file request has been sent successfully to \"" + result.result.email + "\" Please communicate the PIN Code (" + result.result.pinCode + ") security via Phone; so the user can send the file.",
              hideCancel: true,
            }
          });
        });
      }
    });
  }

  private defineGrid(): IchsGridComponentConfig<FileRequestViewModel> {
    let configs: IchsGridComponentConfig<FileRequestViewModel> = {
      primaryId: "id",
      defaultOrder: "TimeStampCreated",
      defaultOrderType: "desc",
      title: "File Requests",
      entityController: "fileRequest",
      entityDataSource: (filter: SearchViewModel) => this.fileRequestService.search(filter),
      hasVoid: false,
      hasNew: false,
      hasDetails: false,
      customNewAction: (!this.generalVariables.userImpersonationInfo.impersonatorRoleInd ? new IchsGridActionsConfig({
        function: () => {
          this.openNewRequestDialog();
        },
        title: "Initiate New Request",
      }) : null),
      secondAction: new IchsGridActionsConfig({
        function: (record: FileRequestViewModel) => {
          if (record.uploadDate == null) {
            this.alertService.addAlert({
              type: AlertMessageType.warning,
              title: 'Warning!',
              dismiss: AlertDismissType.auto,
              messages: ["No File Uploaded yet."]
            });
            return;
          }

          this.dialog.open(DownLoadRequestFileComponent, {
            width: '650px',
            data: { title: "Received Files From " + record.email, requestId: record.id }
          });
        },
        icon: "./assets/images/download.svg",
        title: "Download uploaded file"
      }),
      thirdAction: (!this.generalVariables.userImpersonationInfo.impersonatorRoleInd ? new IchsGridActionsConfig({
        function: (record: FileRequestViewModel) => {
          let dialogRef = this.dialog.open(IchsDialogComponent, {
            width: '450px',
            data: { title: "Delete File", message: "Are you sure about deleting this record?" }
          });

          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.fileRequestService._delete(record.id, record.version).pipe(first()).subscribe(obj => {
                if (obj.result) {
                  this.gridConfig.refreshGrid(0);
                }
              });
            }
          });
        },
        icon: "./assets/images/delete.svg",
        title: 'Delete Request Record'
      }) : null),
      headers: [
        {
          headerTitle: "Request Sent Date",
          propertyName: "timeStampCreated",
          sortable: true,
          searchable: true,
          controlType: IchsControlType.Date,
          dateFormat: "MM/dd/yyyy",
          width: 150
        },
        {
          headerTitle: "Request Sent From",
          propertyName: "email",
          sortable: true,
          searchable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          sortable: true,
          searchable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Pin Code",
          propertyName: "pinCode",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
          width: 80
        },
        {
          headerTitle: "Upload Date",
          propertyName: "uploadDate",
          nullable: true,
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Date,
          dateFormat: "MM/dd/yyyy",
        }
      ]
    }
    return configs;
  }
}
