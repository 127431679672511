<h2 mat-dialog-title>{{ showTree ? selectedNode.label : 'Advanced Date Filters' }}</h2>
<mat-dialog-content>
  <ng-container *ngIf="showTree">
    <div class="display-flex flex-items-centered margin-left-def gap-m">
      <div class="display-flex flex-items-centered position-relative flex-grow-1">
        <ichs-text-box label="Search" name="searchQuery" id="searchQuery"
          [(ngModel)]="query" class="flex-grow-1 flex-basis-0">
        </ichs-text-box>
        <img class="clear-search" src="assets/images/cross.svg" (click)="resetSearchFilter()" />
      </div>
      <div *ngIf="data.showActiveOnlyToggle" class="display-flex break-la active-only-toggle">
        <ichs-slide-toggle class="gap-s" label="Show Active Only" [(ngModel)]="showActiveOnly" (slideToggleChanged)="onShowActiveOnlyChange()" name="showActiveOnly"></ichs-slide-toggle>
      </div>
      <a (click)="resetSelection()" class="cp-btn cp-grid-head-btn margin-left-def clear-selection">
        Clear Selection
      </a>
    </div>

    <div class="margin-top-def margin-left-def">
      <label style="cursor:pointer" *ngFor="let pathNode of path; let last=last" (click)="childNodeOpened(pathNode)"> {{pathNode.label + (!last?" > ":"")}} </label>
    </div>
    <hr class="main-hr" />
    <div class="tree-item" style="display:block;">
      <div class="display-flex flex-items-centered">
        <div (click)="selectParentNode()" class="tree-triangle">
          <img src="assets/images/arrowDown.svg"/>
        </div>
        <div class='flex-cell input-cont margin-left-def'>
          <mat-checkbox [checked]="isAllSelected()"
                        [indeterminate]="isAllSelected() == null"
                        (change)="rootNodeChecked($event)">
          </mat-checkbox>
        </div>
        <div class='flex-cell tree-item-label' (click)="selectParentNode()">
          <label>{{selectedNode.label}}</label>
        </div>
      </div>
      <cdk-virtual-scroll-viewport [style.height.px]="data.showAdvanceDate ? 260 : 440" itemSize="15" *ngIf="selectedNode.children && selectedNode.children.length>0">
        <ichs-tree-item (nodeSelectedEvent)="nodeChecked($event)"
                        (nodeOpened)="childNodeOpened($event)"
                        [popUpMode]="true"
                        *cdkVirtualFor="let child of (selectedNode.children | search:'label':query | filter:'termDate':termedNodesFilter:showActiveOnly)"
                        [node]="child">
        </ichs-tree-item>
      </cdk-virtual-scroll-viewport>
    </div>
  </ng-container>

  <div id="advanceDateFilter" *ngIf="data.showAdvanceDate">
    <div class="display-flex" style="padding:10px;">
      <div class="flex-basis-0 flex-grow-1 bold">Range</div>
      <ichs-select #dateRangeSelect class="flex-basis-0 flex-grow-2" name="dateRange" (selectionChanged)="onDateRangeChange()"
                   [(ngModel)]="data.advanceDateFilter.dateRange" [items]="dateRangesItems" hideResetLabel="true" [hideCustomLabel]="hideDateRangeSelectCustomLabel">
      </ichs-select>
    </div>
    <div class="display-flex" style="padding:10px;">
      <div class="flex-basis-0 flex-grow-1 bold">Start Date</div>
      <ichs-date-picker class="flex-basis-0 flex-grow-2" name="startDate" [(ngModel)]="data.advanceDateFilter.startDate"
                        (dateChanged)="onDatesChange()"></ichs-date-picker>
    </div>
    <div class="display-flex" style="padding:10px;">
      <div class="flex-basis-0 flex-grow-1 bold">End Date</div>
      <ichs-date-picker class="flex-basis-0 flex-grow-2" name="endDate" [(ngModel)]="data.advanceDateFilter.endDate"
                        (dateChanged)="onDatesChange()"></ichs-date-picker>
    </div>
    <div class="text-center" style="padding:10px;">
      <button style="min-width: 150px;" class="cp-btn filter-btn" (click)="resetAdvancedDateFilters()">Reset</button>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <ichs-check-box *ngIf="data.showSaveSelectionForFutureUse" name="saveSelectionForFutureUse"
    [(ngModel)]="returnData.saveSelectionForFutureUse" label="Save Selection for future use">
  </ichs-check-box>
  <div class="flex-grow-1"></div>
  <div >
    <button mat-button [mat-dialog-close]="returnData" class="cp-btn cp-main-btn">Apply Filter</button>
    <button mat-button mat-dialog-close class="cp-btn cp-mail-btn">Cancel</button>
  </div>
</mat-dialog-actions>
