import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { interval, Subscription } from 'rxjs';


@Component({
  selector: 'ichs-carousel',
  templateUrl: './ichs-carousel.component.html',
  styleUrls: ['./ichs-carousel.component.css'],
})

export class IchsCarouselComponent implements OnInit, OnDestroy {

  @Input('items') data: CarouselItem[] = [];
  selectedIndex: number = 0;
  public identifier = `ichs-carousel-${identifier++}`;
  sub: Subscription;


  ngOnInit() {
    this.sub = interval(10000).subscribe(() => this.next());
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  next() {
    this.selectedIndex = (this.selectedIndex + 1) % this.data.length;
  }

  prev() {
    if (this.selectedIndex == 0) {
      this.selectedIndex = this.data.length - 1;
    } else {
      this.selectedIndex = this.selectedIndex - 1;
    }
  }

}
export class CarouselItem {
  img: string;
  title?: string;
  desc?: string;
  link?: string;
}
let identifier = 0;
