/**
 * Client Portal API V1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';










import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import {MedcorResponse,PaginatedListViewModel,ReportViewHistoryViewModel,SearchViewModel,VoidInfoViewModel,ReportItemViewModel,ReportCatalogItemViewModel} from '../index';


@Injectable()
export class ReportItemService {

    protected basePath = 'https://localhost/ClientPortalAPI';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Delete ReportItem
     * 
     * @param id Search Id
     * @param version object Version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _delete(id?: number, version?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<ReportItemViewModel>>;
    public _delete(id?: number, version?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<ReportItemViewModel>>>;
    public _delete(id?: number, version?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<ReportItemViewModel>>>;
    public _delete(id?: number, version?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (version !== undefined && version !== null) {
            queryParameters = queryParameters.set('version', <any>version);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<MedcorResponse<ReportItemViewModel>>(`${this.basePath}/api/ReportItem`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Void ReportItem
     * 
     * @param objVoid Void object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public _void(objVoid?: VoidInfoViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<ReportItemViewModel>>;
    public _void(objVoid?: VoidInfoViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<ReportItemViewModel>>>;
    public _void(objVoid?: VoidInfoViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<ReportItemViewModel>>>;
    public _void(objVoid?: VoidInfoViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<ReportItemViewModel>>(`${this.basePath}/api/ReportItem/Void`,
            objVoid,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add Report Item To Favorite list for logged in user or remove it
     * 
     * @param reportItemId 
     * @param isAdd true then add ; false remove
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public favorite(reportItemId?: number, isAdd?: boolean, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<boolean>>;
    public favorite(reportItemId?: number, isAdd?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<boolean>>>;
    public favorite(reportItemId?: number, isAdd?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<boolean>>>;
    public favorite(reportItemId?: number, isAdd?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (reportItemId !== undefined && reportItemId !== null) {
            queryParameters = queryParameters.set('reportItemId', <any>reportItemId);
        }
        if (isAdd !== undefined && isAdd !== null) {
            queryParameters = queryParameters.set('isAdd', <any>isAdd);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<boolean>>(`${this.basePath}/api/ReportItem/Favorite`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All ReportItems
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public get(observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<ReportItemViewModel>>>;
    public get(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<ReportItemViewModel>>>>;
    public get(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<ReportItemViewModel>>>>;
    public get(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<ReportItemViewModel>>>(`${this.basePath}/api/ReportItem`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get ReportItem By Id without security checks
     * 
     * @param id Search Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getById(id: number, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<ReportItemViewModel>>;
    public getById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<ReportItemViewModel>>>;
    public getById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<ReportItemViewModel>>>;
    public getById(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getById.');
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<ReportItemViewModel>>(`${this.basePath}/api/ReportItem/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get list of items for a given path
     * 
     * @param path 
     * @param isReport is Report Path
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCatalogs(path?: string, isReport?: boolean, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<ReportCatalogItemViewModel>>>;
    public getCatalogs(path?: string, isReport?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<ReportCatalogItemViewModel>>>>;
    public getCatalogs(path?: string, isReport?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<ReportCatalogItemViewModel>>>>;
    public getCatalogs(path?: string, isReport?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (path !== undefined && path !== null) {
            queryParameters = queryParameters.set('path', <any>path);
        }
        if (isReport !== undefined && isReport !== null) {
            queryParameters = queryParameters.set('isReport', <any>isReport);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<ReportCatalogItemViewModel>>>(`${this.basePath}/api/ReportItem/GetCatalogs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All ReportItems for Logged in User
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getForUser(observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<Array<ReportItemViewModel>>>;
    public getForUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<Array<ReportItemViewModel>>>>;
    public getForUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<Array<ReportItemViewModel>>>>;
    public getForUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<Array<ReportItemViewModel>>>(`${this.basePath}/api/ReportItem/GetForUser`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get ReportItem By Id with security checks
     * 
     * @param id Search Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getReportItemDetails(id?: number, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<ReportItemViewModel>>;
    public getReportItemDetails(id?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<ReportItemViewModel>>>;
    public getReportItemDetails(id?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<ReportItemViewModel>>>;
    public getReportItemDetails(id?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<ReportItemViewModel>>(`${this.basePath}/api/ReportItem/GetReportItemDetails`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add Or Update ReportItem
     * If Id is 0 Then Add Else Update
     * @param name 
     * @param reportPath 
     * @param reportPathId 
     * @param lineOfBusiness 
     * @param description 
     * @param subTitle 
     * @param keyWords 
     * @param thumbnail 
     * @param snapshot 
     * @param hasPhiData 
     * @param hasHrData 
     * @param hasFinanceData 
     * @param internalInd 
     * @param tag 
     * @param tagTitle 
     * @param exportOptionsList 
     * @param id 
     * @param voidId 
     * @param version 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postUpload(name: string, reportPath: string, reportPathId: string, lineOfBusiness: string, description?: string, subTitle?: string, keyWords?: string, thumbnail?: Blob, snapshot?: Blob, hasPhiData?: boolean, hasHrData?: boolean, hasFinanceData?: boolean, internalInd?: boolean, tag?: string, tagTitle?: string, exportOptionsList?: Array<string>, id?: number, voidId?: number, version?: string, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<ReportItemViewModel>>;
    public postUpload(name: string, reportPath: string, reportPathId: string, lineOfBusiness: string, description?: string, subTitle?: string, keyWords?: string, thumbnail?: Blob, snapshot?: Blob, hasPhiData?: boolean, hasHrData?: boolean, hasFinanceData?: boolean, internalInd?: boolean, tag?: string, tagTitle?: string, exportOptionsList?: Array<string>, id?: number, voidId?: number, version?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<ReportItemViewModel>>>;
    public postUpload(name: string, reportPath: string, reportPathId: string, lineOfBusiness: string, description?: string, subTitle?: string, keyWords?: string, thumbnail?: Blob, snapshot?: Blob, hasPhiData?: boolean, hasHrData?: boolean, hasFinanceData?: boolean, internalInd?: boolean, tag?: string, tagTitle?: string, exportOptionsList?: Array<string>, id?: number, voidId?: number, version?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<ReportItemViewModel>>>;
    public postUpload(name: string, reportPath: string, reportPathId: string, lineOfBusiness: string, description?: string, subTitle?: string, keyWords?: string, thumbnail?: Blob, snapshot?: Blob, hasPhiData?: boolean, hasHrData?: boolean, hasFinanceData?: boolean, internalInd?: boolean, tag?: string, tagTitle?: string, exportOptionsList?: Array<string>, id?: number, voidId?: number, version?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling postUpload.');
        }

        if (reportPath === null || reportPath === undefined) {
            throw new Error('Required parameter reportPath was null or undefined when calling postUpload.');
        }

        if (reportPathId === null || reportPathId === undefined) {
            throw new Error('Required parameter reportPathId was null or undefined when calling postUpload.');
        }

        if (lineOfBusiness === null || lineOfBusiness === undefined) {
            throw new Error('Required parameter lineOfBusiness was null or undefined when calling postUpload.');
        }
















        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void | HttpParams; };
        let useForm = true;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (name !== undefined) {
            formParams = formParams.append('Name', <any>name) || formParams;
        }
        if (description !== undefined) {
            formParams = formParams.append('Description', <any>description) || formParams;
        }
        if (subTitle !== undefined) {
            formParams = formParams.append('SubTitle', <any>subTitle) || formParams;
        }
        if (keyWords !== undefined) {
            formParams = formParams.append('KeyWords', <any>keyWords) || formParams;
        }
        if (reportPath !== undefined) {
            formParams = formParams.append('ReportPath', <any>reportPath) || formParams;
        }
        if (reportPathId !== undefined) {
            formParams = formParams.append('ReportPathId', <any>reportPathId) || formParams;
        }
        if (thumbnail !== undefined) {
            formParams = formParams.append('Thumbnail', <any>thumbnail) || formParams;
        }
        if (snapshot !== undefined) {
            formParams = formParams.append('Snapshot', <any>snapshot) || formParams;
        }
        if (lineOfBusiness !== undefined) {
            formParams = formParams.append('LineOfBusiness', <any>lineOfBusiness) || formParams;
        }
        if (hasPhiData !== undefined) {
            formParams = formParams.append('HasPhiData', <any>hasPhiData) || formParams;
        }
        if (hasHrData !== undefined) {
            formParams = formParams.append('HasHrData', <any>hasHrData) || formParams;
        }
        if (hasFinanceData !== undefined) {
            formParams = formParams.append('HasFinanceData', <any>hasFinanceData) || formParams;
        }
        if (internalInd !== undefined) {
            formParams = formParams.append('InternalInd', <any>internalInd) || formParams;
        }
        if (tag !== undefined) {
            formParams = formParams.append('Tag', <any>tag) || formParams;
        }
        if (tagTitle !== undefined) {
            formParams = formParams.append('TagTitle', <any>tagTitle) || formParams;
        }
        if (exportOptionsList) {
            formParams = formParams.append('ExportOptionsList', exportOptionsList.join(COLLECTION_FORMATS['csv'])) || formParams;
        }
        if (id !== undefined) {
            formParams = formParams.append('Id', <any>id) || formParams;
        }
        if (voidId !== undefined) {
            formParams = formParams.append('VoidId', <any>voidId) || formParams;
        }
        if (version !== undefined) {
            formParams = formParams.append('Version', <any>version) || formParams;
        }

        return this.httpClient.post<MedcorResponse<ReportItemViewModel>>(`${this.basePath}/api/ReportItem/PostUpload`,
            convertFormParamsToString ? formParams.toString() : formParams,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Recover ReportItem
     * 
     * @param id Search Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recover(id: number, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<ReportItemViewModel>>;
    public recover(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<ReportItemViewModel>>>;
    public recover(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<ReportItemViewModel>>>;
    public recover(id: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling recover.');
        }

        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<MedcorResponse<ReportItemViewModel>>(`${this.basePath}/api/ReportItem/Recover/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Search ReportItem
     * &#x60;&#x60;&#x60;               {                  \&quot;filterExpression\&quot;: \&quot;userName &#x3D;&#x3D; \\\&quot;Medcor\\\&quot;\&quot;,                  \&quot;pageIndex\&quot;: 1,                  \&quot;pageSize\&quot;: 10,                  \&quot;sortExpressions\&quot;: [                   \&quot;userName\&quot;, \&quot;Age Desc\&quot;                  ]               }              &#x60;&#x60;&#x60;
     * @param objSearch Searh object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public search(objSearch?: SearchViewModel, observe?: 'body', reportProgress?: boolean): Observable<MedcorResponse<PaginatedListViewModel<ReportItemViewModel>>>;
    public search(objSearch?: SearchViewModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MedcorResponse<PaginatedListViewModel<ReportItemViewModel>>>>;
    public search(objSearch?: SearchViewModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MedcorResponse<PaginatedListViewModel<ReportItemViewModel>>>>;
    public search(objSearch?: SearchViewModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (medcor_oath) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<MedcorResponse<PaginatedListViewModel<ReportItemViewModel>>>(`${this.basePath}/api/ReportItem/Search`,
            objSearch,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
