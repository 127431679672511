import { Directive } from '@angular/core';
import { NG_VALIDATORS, AbstractControl, ValidationErrors, Validator, } from '@angular/forms';


@Directive({
    selector: '[passwordStrength][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: PasswordStrengthValidator, multi: true }
    ]
})

export class PasswordStrengthValidator implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        //password  expression.
        //password must contain at least 1 lowercase alphabetical character
        //password must contain at least 1 uppercase alphabetical character
        //password must contain at least 1 numeric character or 1 special character, but we are escaping reserved RegEx characters to avoid conflict
        //password must be 12 characters or longer

        let minLength = /^[\s\S]{12,}$/,
            upper = /[A-Z]/,
            lower = /[a-z]/,
            number = /[0-9]/,
            special = /[!@#\$%\^&\*]/

        // the [required] validator if exist will check this part.
        if (!control.value) {
            return null;
        }


        const value = control.value;
        if (minLength.test(value) && upper.test(value) && lower.test(value) && (number.test(value) || special.test(value))) {
            return null;
        }

        const validationMessage = {
            ["passwordStrength"]: 'All passwords must be strong and have a minimum of 12 characters. Passwords must contain a number or a special character, a lowercase letter and an uppercase letter.'
        };

        return validationMessage;
    }
}