<h2 mat-dialog-title>Create New Saved Selection</h2>

<mat-dialog-content>
  <div class="margin-top-def margin-left-def">
    <form #form="ngForm" novalidate>
      <ichs-text-box placeholder="Custom Selection Name" name="selectionName" id="selectionName" [(ngModel)]="selectionName"
        class="flex-grow-1 flex-basis-0" required maxlength="30">
      </ichs-text-box>
    </form>
  </div>

  <div class="margin-top-def margin-left-def">
    <label style="cursor:pointer" *ngFor="let pathNode of path; let last=last" (click)="childNodeOpened(pathNode)"> {{pathNode.label + (!last?" > ":"")}} </label>
  </div>
  <hr class="main-hr" />
  <div class="tree-item" style="display:block;">
    <div class="display-flex flex-items-centered">
      <div (click)="selectParentNode()" class="tree-triangle">
        <img src="assets/images/arrowDown.svg"/>

      </div>
      <div class='flex-cell input-cont margin-left-def'>
        <mat-checkbox [checked]="isAllSelected()"
                      [indeterminate]="isAllSelected() == null"
                      (change)="rootNodeChecked($event)">
        </mat-checkbox>
      </div>
      <div class='flex-cell tree-item-label' (click)="selectParentNode()">
        <label>{{selectedNode.label}}</label>
      </div>
    </div>
    <div [style.height.px]="480" *ngIf="selectedNode.children && selectedNode.children.length > 0">
      <ng-container *ngFor="let child of selectedNode.children">
        <ichs-tree-item *ngIf="child.visible"
                (nodeSelectedEvent)="nodeChecked($event)"
                (nodeOpened)="childNodeOpened($event)"
                [popUpMode]="true"
                [node]="child">
        </ichs-tree-item>
      </ng-container>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-basis-0 flex-grow-1 text-right">
    <button mat-button class="cp-btn cp-main-btn" (click)="onClose(true)">Save Selection</button>
    <button mat-button class="cp-btn cp-mail-btn" (click)="onClose(false)">Cancel</button>
  </div>
</mat-dialog-actions>
