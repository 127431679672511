<div class="display-flex flex-items-centered">
  <div class="flex-basis-0 flex-grow-1">
    <div class="display-flex flex-items-centered margin-left-def">
      <ichs-text-box name="search" [(ngModel)]="searchText" placeholder="Search" class="flex-grow-1 flex-basis-0"></ichs-text-box>
      <a (click)="clearFilter()" class="cp-btn cp-grid-head-btn  margin-left-def" style="align-self:flex-end;">
        Clear
      </a>
    </div>
  </div>
  <div style="flex-basis:100px;">
  </div>
</div>

<div class="flex-basis-0 flex-grow-1">
  <div>
    <div class="display-flex flex-wrap reports-cont">
      <div *ngFor="let dashboardItem of filteredDashboardItemList" class="flex-basis-0 flex-grow-1 report dashboard-item-cont">
        <dashboard-item-card [dashboardItem]="dashboardItem"
                             class="report-item dashboard-manage-item"
                             (DashboardItemCardSelected)="dashboardItemCardSelected($event)">
        </dashboard-item-card>
      </div>
    </div>
  </div>

</div>
