import { Component } from '@angular/core';
import { DashboardItemViewModel, DashboardItemService, VoidInfoViewModel, SearchViewModel } from '../../rest/index';
import { IchsGridComponentConfig, IchsControlType } from '../../controls/ichs-grid/grid-configs';
import { Router } from '@angular/router';

@Component({
  selector: 'dashboard-item-list',
  templateUrl: './dashboard-item-list.component.html',
  styleUrls: ['./dashboard-item-list.component.css'],
  host: {
    class: "data-list"
  }
})
/** dashboard-item-list component*/
export class DashboardItemListComponent {
  dashboardItemsGridConfig: IchsGridComponentConfig<DashboardItemViewModel>;

  /** dashboard-item-list ctor */
  constructor(
    private router: Router,
    private dashboardItemService: DashboardItemService,
  ) {
    this.dashboardItemsGridConfig = this.defineDashboardItemsGrid();
  }

  private defineDashboardItemsGrid(): IchsGridComponentConfig<DashboardItemViewModel> {
    let configs: IchsGridComponentConfig<DashboardItemViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Dashboard Items",
      entityController: "dashboard-item",
      voidAction: (reason: VoidInfoViewModel) => this.dashboardItemService._void(reason),
      recoverAction: (id: number) => this.dashboardItemService.recover(id),
      entityDataSource: (filter: SearchViewModel) => this.dashboardItemService.search(filter),
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Tag Title",
          propertyName: "tagTitle",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Internal Item",
          propertyName: "internalInd",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Boolean
        },
        {
          headerTitle: "Line Of Business",
          propertyName: "lineOfBusiness",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.DynamicSelect,
          listId: 108000000000007
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ],
    }
    return configs;
  }
}
