import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

/*
* default replaceChar = bullet
* do not use same var for pass and stars
* use:
* <input (passChange)="loginModel.password = $event" (starsChange)="stars = $event" replaceChar="*" [(ngModel)]="stars"
*                 appPassword autocomplete="off" matInput name="password" placeholder="password" required type="text">
*/
@Directive({
  selector: '[appPassword]'
})
export class PasswordDirective {
  value: string[] = [];
  @Input() replaceChar = '\u2022';  // bullet
  @Output() passChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() starsChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(private el: ElementRef<HTMLInputElement>) {

    this.el.nativeElement.onkeydown = (ev) => {
      if (ev.key.length === 1) {
        ev.preventDefault();
        this.value.push(ev.key);
      } else {
        if (ev.key === 'Backspace') {
          this.value.pop();
        } else if (ev.key === 'Delete') {
          this.value = [];
        }
      }
      this.drawValue();
    };
    this.el.nativeElement.onpaste = (ev) => {
      ev.preventDefault();
    };
  }

  drawValue() {
    const x = this.value.map(() => this.replaceChar);
    this.starsChange.emit(x.join(''));
    this.passChange.emit(this.value.join(''));
  }
}
