import { Component, OnInit, ViewChild } from '@angular/core';
import { IchsCommandToolbarModel, IchsCommandToolbarDelegate } from '../../controls/ichs-command-toolbar/ichs-command-toolbar.component';
import { NgForm, UntypedFormGroup } from '@angular/forms';
import { DashboardItemViewModel, DashboardItemService, MedcorResponse, PublicFileService, PBIReport, DashboardService, SelectionItemViewModel, GroupViewModel, SearchViewModel, GroupDashboardItemAssociationService, GroupDashboardItemAssociationViewModel, VoidInfoViewModel } from '../../rest/index';
import { MedcorAlertService, AlertMessageType, AlertDismissType } from '../../services/medcor-alert.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { first } from 'rxjs/operators';
import * as pbi from 'powerbi-client';
import { Subject, Observable } from 'rxjs';
import { SharedFunctionsService } from '../../services/shared-functions.service';
import { ValidationMessages, AppSettings } from '../../app.general.constants';
import { IchsGridComponentConfig, IchsControlType } from 'src/app/controls/ichs-grid/grid-configs';

@Component({
  selector: 'dashboard-item',
  templateUrl: './dashboard-item.component.html',
  styleUrls: ['./dashboard-item.component.css']
})
/** dashboard-item component*/
export class DashboardItemComponent implements OnInit, IchsCommandToolbarDelegate<DashboardItemViewModel> {
  ngForm: NgForm = new NgForm([], []);
  @ViewChild('formGroupReport', { static: true }) formGroupReport: NgForm;// bind the Form From view 
  @ViewChild('formGroupImages', { static: true }) formGroupImages: NgForm;// bind the Form From view 
  @ViewChild('formGroupDetails', { static: true }) formGroupDetails: NgForm;// bind the Form From view 

  model: IchsCommandToolbarModel<DashboardItemViewModel>;// 2) define Command Toolbar model and use model.objModel to bing the FORM UI and use model as IchsCommandToolbar [model]
  catalogs: Array<PBIReport> = [];
  pathFolders: String[] = [];
  selectedReport: PBIReport;
  gridConfigGroup: IchsGridComponentConfig<GroupViewModel>;
  tagItems: SelectionItemViewModel[] = [{ selectionText: "Tag 1", selectionValue: "Tag 1" }, { selectionText: "Tag 2", selectionValue: "Tag 2" }];

  get dashboardItemId(): number { return this.model.objModelId }
  image: string;
  imageFile: File;

  get selectedReportPath(): string | null { return this.selectedReport && this.selectedReport.id ? this.selectedReport.id : "" };

  isformGroupReportChecked = false;
  isformGroupImagesChecked = false;
  isformGroupDetailsChecked = false;

  accessToken: string;
  report: pbi.Embed;

  /** reportItem ctor */
  constructor(
    private location: Location,
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private alertService: MedcorAlertService,
    private dashboardItemService: DashboardItemService,
    private dashboardService: DashboardService,
    private filesService: PublicFileService,
    private sharedFunctionsService: SharedFunctionsService,
    private groupDashboardItemAssociationService: GroupDashboardItemAssociationService,
  ) {
    this.model = new IchsCommandToolbarModel<DashboardItemViewModel>(location, router, "/dashboard-item", "DashboardItem", this, dialog, alertService); // 3) Create Command Toolbar Model 
    this.gridConfigGroup = this.defineGroupGrid();
  }

  /** Dashboard Item OnInit*/
  ngOnInit() {
    this.ngForm.form = new UntypedFormGroup({
      formGroupReport: this.formGroupReport.form,
      formGroupImages: this.formGroupImages.form,
      formGroupDetails: this.formGroupDetails.form
    });
    this.model.init(this.route); // 4) Initialize The Model
  }

  // 5) implement IchsCommandToolbarDelegate .
  canDeactivate(): boolean | Observable<boolean> {
    return this.model.confirmCanDeactivate();
  }

  getLoadObjModelObservable(id: number) {
    return this.dashboardItemService.getById(id);
  }

  getVoidObservable(objVoidModel: VoidInfoViewModel): Observable<MedcorResponse<DashboardItemViewModel>> {
    return this.dashboardItemService._void(objVoidModel)
  }

  getRecoverObservable(id: number): Observable<MedcorResponse<DashboardItemViewModel>> {
    return this.dashboardItemService.recover(id);
  }

  getSaveObservable(objModel: DashboardItemViewModel): Observable<MedcorResponse<DashboardItemViewModel>> {
    return this.dashboardItemService.postUpload(
      objModel.name,
      this.model.objModel.lineOfBusiness,
      this.model.objModel.groupId,
      this.selectedReport!.id!,
      objModel.description ? objModel.description : undefined,
      this.imageFile,
      objModel.internalInd,
      objModel.tag,
      objModel.tagTitle,
      objModel.hasPhiData,
      objModel.hasExport,
      objModel.id,
      objModel.voidId,
      objModel.version
    );
  }

  beforeSave(objModel: DashboardItemViewModel) {
    objModel.reportItemId = this.selectedReport.id;
  }

  didLoad(objModel: DashboardItemViewModel) {
    if (objModel.id && objModel.id != -1) {
      this.getCatalogItems(objModel.groupId, true).pipe(first()).subscribe(result => {
        if (!result) return;
        let matchReport = result.find(obj => obj.id == objModel.reportItemId);
        if (matchReport) {
          this.selectedReport = matchReport;

          // translate new line of business name to the old name (i.e. "Triage" to "Afkam")
          let oldLineOfBusinessName = this.sharedFunctionsService.translateLineOfBusinessName(this.model.objModel.lineOfBusiness);
          this.pathFolders.splice(0);
          this.pathFolders.push(oldLineOfBusinessName);
        }
      });

      if (objModel.imageId) {
        let subs = this.filesService.downloadFile(objModel.imageId, 'events', true).subscribe(event => {
          if (event.type == HttpEventType.Response) {
            var reader = new FileReader();
            reader.onload = (event: any) => {
              this.image = event.target.result;
            }

            reader.readAsDataURL(event.body!);
          }
        }, () => { subs.unsubscribe() }, () => { subs.unsubscribe() });
      }
    } else {
      this.getCatalogItems(undefined);
    }
  }

  private defineGroupGrid(): IchsGridComponentConfig<GroupViewModel> {
    let configs: IchsGridComponentConfig<GroupViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Groups",
      entityDataSource: (filter: SearchViewModel) => this.groupDashboardItemAssociationService.searchGroupsByDashboardItemId(this.dashboardItemId, false, filter),
      addExistingConfig: {
        buttonText: "Add Existing",
        gridConfig: this.addExistingGroupGridConfig(),
        addExistingService: (Ids: string[]) => {
          let close = new Subject<MedcorResponse<boolean>>();
          let arrDashboardItemGroupAssociations = Ids.map<GroupDashboardItemAssociationViewModel>((item) => {
            return { dashboardItemId: this.dashboardItemId, groupId: +item }
          });
          this.groupDashboardItemAssociationService.postList(false, arrDashboardItemGroupAssociations).pipe(first()).subscribe(result => {
            if (result) {
              close.next({ result: true, statusCode: 200 });
            } else {
              close.next({ result: false });
            }
          });
          return close.asObservable();
        },
        title: "Add Groups",
      },
      hasSearch: false,
      hasNew: false,
      hasExport: false,
      hasDetails: false,
      hasVoid: true,
      hasActionColumn: true,
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: GroupViewModel) => {
          if (!deletedObj.id) return;
          let subs = this.groupDashboardItemAssociationService.deleteAssociation(this.dashboardItemId, deletedObj.id, false).subscribe(result => {
            if (result) {
              this.gridConfigGroup.refreshGrid!(this.gridConfigGroup.getPage!());
            }
          }, undefined, () => { subs.unsubscribe(); });
        }
      },
      headers: [
        {
          headerTitle: "Group Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ],
    }
    return configs;
  }
  /**
   * Configure Add Existing Group
   */
  private addExistingGroupGridConfig(): IchsGridComponentConfig<GroupViewModel> {
    let addExistingGridConfig: IchsGridComponentConfig<GroupViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Add Groups to DashboardItem",
      entityController: "app-folder",
      entityDataSource: (filter: SearchViewModel) => this.groupDashboardItemAssociationService.searchGroupsByDashboardItemId(this.dashboardItemId, true, filter),
      multiSelectConfig: {
      },
      hasVoid: false,
      rowColor: (obj: GroupViewModel) => obj.voidId != 0 ? 'Pink' : null,
      headers: [
        {
          headerTitle: "Group Name",
          propertyName: "name",
          searchable: true,
          sortable: true,

          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Description",
          propertyName: "description",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }

      ],
    };
    return addExistingGridConfig;
  }

  validatingAllFormFields(formGroup: UntypedFormGroup) {
    if (formGroup == this.formGroupReport.form) {
      this.isformGroupReportChecked = true;
    } else if (formGroup == this.formGroupImages.form) {
      this.isformGroupImagesChecked = true;
    } else if (formGroup == this.formGroupDetails.form) {
      this.isformGroupDetailsChecked = true;
    } else {
      this.isformGroupReportChecked = true;
      this.isformGroupImagesChecked = true;
      this.isformGroupDetailsChecked = true;
    }
  }

  private getCatalogItems(path: string | undefined, isReport: boolean = false): Observable<Array<PBIReport> | null> {
    let completed = new Subject<Array<PBIReport> | null>();
    this.dashboardService.listPowerBIReports(path).pipe(first()).subscribe(medcorResult => {
      if (!medcorResult || !medcorResult.result) return;
      this.catalogs = medcorResult.result;
      completed.next(this.catalogs);
    });
    return completed.asObservable();
  }

  selectFile(report: PBIReport) {
    if (this.selectedReport == report) {
      this.selectedReport = null;
    } else {
      this.selectedReport = report;
      if (report.name) {
        this.model.objModel.name = report.name;
      }
    }
  }

  routeToFolder(folder: PBIReport) {
    this.selectedReport = null;

    if (folder) {
      this.model.objModel.groupId = folder.id;
      this.model.objModel.lineOfBusiness = folder.name;
      this.pathFolders.splice(0);
      this.pathFolders.push(folder.name);
      this.getCatalogItems(folder.id);
    } else {
      this.pathFolders.splice(0);
      this.getCatalogItems(undefined);
    }
  }

  imageSelected(ev: any) {
    if (ev.srcElement.files.length != 1) {
      return;
    }

    let file = ev.srcElement.files[0] as File;

    if (file.size > AppSettings.MAX_FILE_SIZE) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.FILE_SIZE_LIMIT_EXCEEDED]
      });
      return;
    }

    if (file.type.toLowerCase() == "image/svg+xml") {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.INVALID_FILETYPE]
      });
      return;
    }

    if (file.type.toLowerCase().indexOf("image/") == -1) {
      this.alertService.addAlert({
        type: AlertMessageType.warning,
        title: 'Warning!',
        dismiss: AlertDismissType.controlled,
        messages: [ValidationMessages.NOT_IMAGE_FILETYPE]
      });
      return;
    }

    this.imageFile = file;
    var reader = new FileReader();
    reader.onload = (event: any) => {
      this.image = event.target.result;
    }
    reader.readAsDataURL(this.imageFile);
  }

  getFolders() {
    return this.catalogs.filter(obj => obj.embedUrl == undefined);
  }

  getFiles() {
    return this.catalogs.filter(obj => obj.embedUrl != undefined);
  }
}
