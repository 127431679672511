import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHandler,
  HttpEvent
} from '@angular/common/http';

import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MedcorAlertService, AlertMessageType, AlertPositionClassType, AlertDismissType } from './services/medcor-alert.service';
import { MedcorResponse } from '../app/rest/index';
import { MedcorAuthenticationService } from './services/medcor-authentication.service';
import { Observable, throwError } from 'rxjs';
import { tap, catchError, first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import { IchsDialogComponent } from './controls/ichs-dialog/ichs-dialog.component';
import { AccountsService } from './rest/api/accounts.service';
import { SessionSettings } from './app.general.constants';
import { MedcorUserInfoService } from './services/medcor-user-info.service';

@Injectable()
export class MedcorHttpInterceptor implements HttpInterceptor {

  beforeExpTimeout: NodeJS.Timeout;
  expTimeout: NodeJS.Timeout;

  constructor(
    private alertService: MedcorAlertService,
    private authenticationService: MedcorAuthenticationService,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog,
    private accountsService: AccountsService,
    private medcorUserInfoService: MedcorUserInfoService,
  ) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.authenticationService.authenticatedObs.pipe(first()).subscribe(authenticated => {
      if (authenticated) {
        this.resetTimeout();
      }
      else {
        this.clearAllTimeouts();
      }
    });

    request = request.clone({
      headers: request.headers
        .set('Cache-Control', 'no-store')
        .set('Pragma', 'no-cache')
        .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
    });

    return next
      .handle(request).pipe(tap((ev: HttpEvent<any>) => {
        if (ev instanceof HttpResponse) {
          var objMedcorResponse = ev.body as MedcorResponse<any>;
          this.alertService.handleMedcorResponse(objMedcorResponse);
        }
      }), catchError(response => {
        if (response instanceof HttpErrorResponse) {
          if (response.status === 401) {
            // find if this message has been already shown
            let duplicate = this.toastr.findDuplicate(SessionSettings.SESSION_EXPIRATION_TITLE, SessionSettings.SESSION_EXPIRATION_MESSAGE, false, false);

            // prevent this message from being shown twice (in case of 2 requests have been sent in the same time)
            if (!duplicate) {
              this.alertService.addAlert({ // If message received show toast message
                type: AlertMessageType.error,
                title: SessionSettings.SESSION_EXPIRATION_TITLE,
                messages: [SessionSettings.SESSION_EXPIRATION_MESSAGE],
              });
            }

            this.authenticationService.unAuthenticate();
          }
          if (response.status === 403) {
            this.alertService.addAlert({ // If message received show toast message
              type: AlertMessageType.info,
              messages: ["Not Authorized Operation"],
              title: "Admin:",
              positionClass: AlertPositionClassType.bottomRight,
            });

            if (this.medcorUserInfoService.getLoggedInUserInfo().roleName == "Client User - Scheduled Reports Only") {
              this.router.navigate(['/securedfiles']);
            }
            else {
              this.router.navigate(['/home-page']);
            }
          }
          if (response.error instanceof Blob) {
            return this.handleBlobError(response);
          }

          var objMedcorResponse = response.error as MedcorResponse<any>;
          this.alertService.handleMedcorResponse(objMedcorResponse);
        }

        return throwError(response);
      }));
  }

  private handleBlobError(errorResponse: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();
    const obs = Observable.create((observer: any) => {
      reader.onloadend = () => {
        var responseString = reader.result.toString();
        var responseObj = JSON.parse(responseString);
        observer.error(responseObj);
        observer.complete();

        var objMedcorResponse = responseObj as MedcorResponse<any>;
        this.alertService.handleMedcorResponse(objMedcorResponse);
      }
    });
    reader.readAsText(errorResponse.error);
    return obs;
  }

  private resetTimeout() {
    clearTimeout(this.beforeExpTimeout);
    clearTimeout(this.expTimeout);

    this.beforeExpTimeout = setTimeout(() => {
      this.authenticationService.authenticatedObs.pipe(first()).subscribe(authenticated => {
        if (authenticated) {
          let dialogRef = this.dialog.open(IchsDialogComponent, {
            width: '450px',
            data: {
              title: "Session Expiration Approached",
              message: `Your session will expire in ${SessionSettings.SHOW_ALERT_MINS_BEFORE_TIMEOUT} minutes, do you want to extend your session time?`,
            }
          });

          this.expTimeout = setTimeout(() => this.expireSession(), SessionSettings.SHOW_ALERT_MINS_BEFORE_TIMEOUT * 60 * 1000);

          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.accountsService.extendSessionTime().pipe(first()).subscribe();
              clearTimeout(this.expTimeout);
            }
          });
        }
      });
    }, (SessionSettings.SESSION_DURATION - SessionSettings.SHOW_ALERT_MINS_BEFORE_TIMEOUT) * 60 * 1000); // (SessionTime.SESSION_DURATION - SessionTime.SHOW_ALERT_MINS_BEFORE_TIMEOUT) mins
  }

  private clearAllTimeouts() {
    clearTimeout(this.beforeExpTimeout);
    clearTimeout(this.expTimeout);
  }

  private expireSession() {
    this.alertService.addAlert({ // If message received show toast message
      type: AlertMessageType.error,
      title: SessionSettings.SESSION_EXPIRATION_TITLE,
      messages: [SessionSettings.SESSION_EXPIRATION_MESSAGE],
      dismiss: AlertDismissType.controlled,
    });
    this.authenticationService.unAuthenticate();
  }
}
