import { Component, OnInit } from '@angular/core';
import { SecuredEmailsService, EmailViewModel, MedcorResponse, ContactViewModel } from '../../rest/index';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { first } from 'rxjs/operators';
import { IchsComponentDialogComponent } from '../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { SendCallRecordComponent, CallRecordRequestData } from '../send-call-record/send-call-record.component';
import { IchsGridComponentConfig } from '../../controls/ichs-grid/grid-configs';
import { SharedFunctionsService } from 'src/app/services/shared-functions.service';
import { GeneralVariablesService } from 'src/app/services/general-variables.service';

@Component({
  selector: 'email-details',
  templateUrl: './email-details.component.html',
  styleUrls: ['./email-details.component.css']
})
export class EmailDetailsComponent implements OnInit {

  email: EmailViewModel = {
    sender: {},
    notifyWhenOpen: false
  };
  mailBox: string;
  ContactsGridConfigs: IchsGridComponentConfig<ContactViewModel>;
  downloadProgress: number = 0;
  downloadProgressType: string = "determinate";
  requestedCallRecordRefNbr: string;
  recipientsNames: string[] = [];

  constructor(
    private emailService: SecuredEmailsService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private sharedFunctions: SharedFunctionsService,
    public generalVariables: GeneralVariablesService,
  ) {
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.mailBox = this.route.snapshot.url[0].path;
    let obv: Observable<MedcorResponse<EmailViewModel>> | null;
    if (id) {
      switch (this.mailBox) {
        case "inbox":
        case "archive":
        case "notifications":
        case "saved":
          obv = this.emailService.viewInboxEmail(id, false);
          break;
        case "sent":
          obv = this.emailService.viewSentEmail(+id);
          break;
        case "externalLink":
          obv = this.emailService.viewInboxEmail(id, true);
          break;
      }
      obv!.pipe(first()).subscribe(result => {
        this.email = result.result!;
        this.recipientsNames = this.email.recipientsList.map(rec => rec.name);
        if (this.email.body && this.email.body.indexOf("<requestedCallRecordRefNbr>") >= 0) {
          var parts = this.email.body.split("<requestedCallRecordRefNbr>");
          this.email.body = parts[0];
          this.requestedCallRecordRefNbr = parts[1];
        }
      });
    }
  }

  openSendCallRecordingDialog() {

    var callRecordRequestData: CallRecordRequestData = {
      refNumber: this.requestedCallRecordRefNbr,
      email: this.email.sender.method
    }

    this.dialog.open(IchsComponentDialogComponent, {
      disableClose: true,
      width: '800px',
      data: {
        component: SendCallRecordComponent, title: "Send Telehealth Call Recording",
        hideCloseButton: true, extraData: callRecordRequestData
      }
    });
  }

  forward() {
    this.router.navigate(["../../forward/" + this.email.emailId],
      {
        relativeTo: this.route,
        queryParams: {
          mailBox: this.mailBox,
        },
      });
  }

  reply() {
    this.router.navigate(["../../new/" + this.email.emailId],
      {
        relativeTo: this.route
      });
  }

  replyAll() {
    this.router.navigate(["../../new/" + this.email.emailId],
      {
        queryParams: { isReplyAll: true },
        relativeTo: this.route,
      });
  }

  downloadFile(fileId: number) {
    let sub = this.emailService.downloadEmailAttachment(fileId, 'events', true).subscribe(event => {
      if (event.type == HttpEventType.Response) {
        this.downloadProgressType = "determinate";
        this.downloadProgress = 0;
        this.sharedFunctions.saveToFileSystem(event);

      } else if (event.type == HttpEventType.DownloadProgress) {
        if (event.total != undefined) {
          this.downloadProgress = (event.loaded / event.total) * 100;
        }
        else {
          this.downloadProgress = 1;
          this.downloadProgressType = "indeterminate";
        }
      }
    }, () => { sub.unsubscribe() }, () => { sub.unsubscribe() })
  }
}

