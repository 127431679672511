<div class='label-cont' *ngIf="label">
  <label [attr.for]="identifier" [class.disabled]="isDisabled">{{label}}</label>
</div>

<div class="display-flex flex-items-centered">
  <div class='flex-cell input-cont flex-basis-0 flex-grow-1'>
    <mat-select #select [id]="identifier" placeholder="{{emptyLabel}}" [multiple]="multiple" [(ngModel)]="value"
      (selectionChange)="onChangeEvent($event)" (openedChange)="onOpenedChange($event)" disableRipple="true" [disabled]="isDisabled"
      [class.disabled]="isDisabled">

      <mat-option class="display-flex" *ngIf="hasSearch && !useGroups">
        <ngx-mat-select-search [formControl]="itemFilterCtrl" placeholderLabel="Search" noEntriesFoundLabel="No items found">
          <img ngxMatSelectSearchClear src="assets/images/cross.svg" title="Clear" width="10px" />

        </ngx-mat-select-search>
      </mat-option>
      <div [class.options-scroll]="multiple && hasCloseButton">
        <mat-option *ngIf="!hideResetLabel && !multiple" [title]="resetLabel" [style.display]="hasSearch && !showResetLabelInSearch ? 'none' : null">{{resetLabel}}</mat-option>
        <mat-option *ngIf="!hideCustomLabel" value="Custom" [title]="customLabel" [style.display]="hasSearch && !showCustomLabelInSearch ? 'none' : null">{{customLabel}}</mat-option>

        <ng-container *ngIf="useGroups">
          <ng-container *ngFor="let group of dataList">
            <ng-container *ngIf="!group.groupName">
              <mat-option *ngFor="let objL of group.items" [title]="objL.selectionText" [value]="objL.selectionValue" [disabled]="isReadOnly">{{objL.selectionText}}</mat-option>
            </ng-container>
            <mat-optgroup *ngIf="group.groupName" [label]="group.groupName">
              <mat-option *ngFor="let objL of group.items" [title]="objL.selectionText" [value]="objL.selectionValue" [disabled]="isReadOnly">{{objL.selectionText}}</mat-option>
            </mat-optgroup>
          </ng-container>
          <ng-container *ngFor="let group of groupedItems">
            <ng-container *ngIf="!group.groupName">
              <mat-option *ngFor="let objL of group.items" [title]="objL.selectionText" [value]="objL.selectionValue" [disabled]="isReadOnly">{{objL.selectionText}}</mat-option>
            </ng-container>
            <mat-optgroup *ngIf="group.groupName" [label]="group.groupName">
              <mat-option *ngFor="let objL of group.items" [title]="objL.selectionText" [value]="objL.selectionValue" [disabled]="isReadOnly">{{objL.selectionText}}</mat-option>
            </mat-optgroup>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="!useGroups">
          <div class="template-op mat-mdc-option mat-mdc-option-multiple ng-star-inserted mat-selected select-all-cont" *ngIf="multiple && hasSelectAll">
            <mat-checkbox [disabled]="isReadOnly" (change)="selectAll($event)" [checked]="isAllSelected()" [indeterminate]="isAllSelected() == null">All</mat-checkbox>
          </div>

          <mat-option class="template-op" *ngFor="let objL of (filteredGroupItems | async)" [title]="objL.selectionText" [value]="objL.selectionValue"
            [disabled]="isReadOnly">
            <img class="select-img" *ngIf="objL.selectionImgUrl" [src]="objL.selectionImgUrl" />{{objL.selectionText}}
          </mat-option>

          <mat-option class="template-op" *ngFor="let item of (filteredItems | async)" [title]="item.selectionText" [value]="item.selectionValue"
            [disabled]="isReadOnly">
            <img class="select-img" *ngIf="item.selectionImgUrl" [src]="item.selectionImgUrl" />{{item.selectionText}}
          </mat-option>
        </ng-container>
      </div>


      <div *ngIf="multiple && hasCloseButton" class="display-flex align-items-center justify-content-center  margin-s">
        <button (click)="select.close()" class='cp-btn cp-main-btn'>Close</button>
      </div>
    </mat-select>
  </div>

  <div class="flex-cell">
    <validation-message *ngIf="!isDisabled && model.control.touched && invalid" [messages]="failures"></validation-message>
  </div>
</div>
