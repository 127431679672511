import { NgModule } from '@angular/core';
import { AppuserComponent } from './appuser/appuser.component';
import { AppuserListComponent } from './appuser-list/appuser-list.component';
import { AppModuleShared } from '../app.shared.module';
import { RouterModule } from '@angular/router';
import { CanActivateGuard, CanDeactivateGuard } from '../services/routing-guard.service';
import { ManageSecurityQuestionsComponent } from './manage-security-questions/manage-security-questions.component';
import { FirstLoginComponent } from './first-login/first-login.component';
import { AppuserSelfDetailComponent } from './appuser-self-detail/appuser-self-detail.component';
import { LineOfBusinessTreeComponent } from './line-of-business-tree/line-of-business-tree.component';
import { AppuserCardComponent } from './appuser-card/appuser-card.component';
import { AppuserCardTemplateComponent } from './appuser-card-template/appuser-card-template.component';
import { NotificationListComponent } from './notifications/notification-list/notification-list.component';
import { NotificationDetailComponent } from './notifications/notification-detail/notification-detail.component';
import { UserAgreementComponent } from '../components/user-agreement/user-agreement.component';
import { GeneratePasswordComponent } from './generate-password/generate-password.component';
import { RestrictedAccessTreeComponent } from './restricted-access-tree/restricted-access-tree.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { ExternalFileRequestComponent } from '../external-service/external-file-request/external-file-request.component';
import { AppLogDialogComponent } from '../app-log/app-log-dialog/app-log-dialog.component';
import { TriagePortalUserListComponent } from './triage-portal-user-list/triage-portal-user-list.component';
import { TriagePortalUserComponent } from './triage-portal-user/triage-portal-user.component';
import { ShowTriageUserPinCodeComponent } from './show-triage-user-pin-code/show-triage-user-pin-code.component';
import { TriagePortalUserPinCodeListComponent } from './triage-portal-user-pin-code-list/triage-portal-user-pin-code-list.component';
import { MedfilesVirtualCompanyComponent } from './medfiles-virtual-company/medfiles-virtual-company/medfiles-virtual-company.component';
import { MedfilesVirtualCompanyListComponent } from './medfiles-virtual-company/medfiles-virtual-company-list/medfiles-virtual-company-list.component';
import { ExternalSignatureRequestComponent } from '../external-service/external-signature-request/external-signature-request.component';
import { UserLookupComponent } from './user-lookup/user-lookup.component';
import { UserLookupDetailsComponent } from './user-lookup-details/user-lookup-details.component';
import { MfaSetupComponent } from '../components/mfa-setup/mfa-setup.component';
import { QrCodeModule } from 'ng-qrcode';
import { ChangePasswordRequestComponent } from './change-password-request/change-password-request.component';
import { AvatarModule } from 'ngx-avatars';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserManagementDetailsComponent } from './user-management-details/user-management-details.component';

@NgModule({
    exports: [
        FirstLoginComponent,
        AppuserCardComponent,
        AppuserCardTemplateComponent,
        LineOfBusinessTreeComponent,
        ExternalFileRequestComponent,
        ExternalSignatureRequestComponent,
        RestrictedAccessTreeComponent,
    ],
    imports: [
        AvatarModule,
        RouterModule.forChild([
            { path: 'appuser', component: AppuserListComponent },
            { path: 'appuser/manageProfile', component: AppuserSelfDetailComponent },
            { path: 'appuser/:id', canActivate: [CanActivateGuard], canDeactivate: [CanDeactivateGuard], component: AppuserComponent },
            { path: 'triage-portal-user', component: TriagePortalUserListComponent },
            { path: 'triage-portal-user/:id', canActivate: [CanActivateGuard], canDeactivate: [CanDeactivateGuard], component: TriagePortalUserComponent },
            { path: 'triage-portal-user-pin-code', component: TriagePortalUserPinCodeListComponent },
            { path: 'appuser/ManageSecurityQuestions', canDeactivate: [CanDeactivateGuard], component: ManageSecurityQuestionsComponent },
            { path: 'announcements', component: NotificationListComponent },
            { path: 'announcements/:id', component: NotificationDetailComponent },
            { path: 'medfiles-virtual-company', component: MedfilesVirtualCompanyListComponent },
            { path: 'medfiles-virtual-company/:id', canActivate: [CanActivateGuard], canDeactivate: [CanDeactivateGuard], component: MedfilesVirtualCompanyComponent },
            { path: 'user-lookup', component: UserLookupComponent },
            { path: 'user-lookup/:id', canActivate: [CanActivateGuard], canDeactivate: [CanDeactivateGuard], component: UserLookupDetailsComponent },
            { path: 'user-management', component: UserManagementComponent },
            { path: 'user-management/:id', canActivate: [CanActivateGuard], canDeactivate: [CanDeactivateGuard], component: UserManagementDetailsComponent },
        ]),
        AppModuleShared,
        QrCodeModule,
    ],
    declarations: [
        AppuserComponent,
        AppuserListComponent,
        ManageSecurityQuestionsComponent,
        FirstLoginComponent,
        AppuserSelfDetailComponent,
        LineOfBusinessTreeComponent,
        AppuserCardComponent,
        AppuserCardTemplateComponent,
        ExternalFileRequestComponent,
        ExternalSignatureRequestComponent,
        NotificationListComponent,
        NotificationDetailComponent,
        UserAgreementComponent,
        GeneratePasswordComponent,
        RestrictedAccessTreeComponent,
        ResetPasswordComponent,
        ImpersonateComponent,
        TriagePortalUserListComponent,
        TriagePortalUserComponent,
        ShowTriageUserPinCodeComponent,
        TriagePortalUserPinCodeListComponent,
        MedfilesVirtualCompanyComponent,
        MedfilesVirtualCompanyListComponent,
        UserLookupComponent,
        UserManagementComponent,
        UserLookupDetailsComponent,
        MfaSetupComponent,
        ChangePasswordRequestComponent,
        UserManagementDetailsComponent,
    ],
    providers: [CanActivateGuard, CanDeactivateGuard]
})
export class AppUserModule {

}
