<div class="contact-item" *ngIf="userContact" [class.card-selected]="isCardVisible" (click)="toggleCardVisibility($event)">
  <div *ngIf="allowSelect">
    <img style="cursor: pointer;" [src]="selected? 'assets/images/checked.svg': 'assets/images/unchecked.svg'" (click)="selectContact($event)"
      class="user-select" />
  </div>
  <div class="display-flex flex-items-centered">
    <div class="flex-basis-0 flex-grow-1 text-center">
      <div [ngClass]="{'contact-img': true,
                'online': status == UserStatus.Online,
                'busy': status == UserStatus.Busy,
                'away': status == UserStatus.Away,
                'offline': status == UserStatus.Offline}"
           [title]="userContact.name" class="contact-img">

        <img *ngIf="userImage" [src]="userImage" />
        <ngx-avatars *ngIf="!userHasImage" class="user-initials" size="42" [name]="usernameInitials"></ngx-avatars>
      </div>

      <div class="position-relative display-inline-block">
        <span [title]="statusText" [ngClass]="{'ng-chat-user-status': true,
            'online': status == UserStatus.Online,
            'busy': status == UserStatus.Busy,
            'away': status == UserStatus.Away,
            'offline': status == UserStatus.Offline}">
        </span>
      </div>
    </div>
    <div class="display-flex flex-items-centered">
      <div (click)="$event.stopPropagation()">
        <div class="cp-btn cp-icon-btn cp-contact-btn" [title]="userContact.isFavorite ? 'Remove from Favorites' : 'Add To Favorites'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.867 53.867" class="fav-icon"
               [class.fav-icon-not-selected]="!userContact.isFavorite" [class.fav-icon-selected]="userContact.isFavorite"
               (click)="favorite()">
            <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798 10.288,52.549 13.467,34.013 0,20.887 18.611,18.182" />
          </svg>
        </div>
      </div>
      <div (click)="$event.stopPropagation()">
        <button class="cp-btn cp-icon-btn cp-contact-btn" title="chat" (click)="chat()" [disabled]="status == UserStatus.Offline"
                [class.offline]="status == UserStatus.Offline">
          <img [src]="(status == UserStatus.Offline)? 'assets/images/newIcons/chatOffline.svg':'assets/images/newIcons/chat.svg'" />
        </button>
      </div>
      <div (click)="$event.stopPropagation()">
        <button class="cp-btn cp-icon-btn cp-contact-btn" title="send secure email" (click)="sendSecureEmail()">
          <img src="assets/images/newIcons/sendEmail.svg" />
        </button>
      </div>
      <div>
        <button mat-button [matMenuTriggerFor]="menu" class="contact-menu-btn align-center" (click)="$event.stopPropagation()">
          <img class="contact-icon" src="assets/images/down-arrow.svg">
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="requestFile()">Request File</button>
          <button mat-menu-item (click)="sendSecureEmail()">Send Secure Email</button>
          <button mat-menu-item (click)="sendSecureFile()">Send Secure File</button>
          <a mat-menu-item [href]="regularEmail" target="_top">Send Regular Email</a>
          <button mat-menu-item (click)="chat()">Chat</button>
          <button *ngIf="!(loggedInUserObs | async).isImpersonated && (loggedInUserObs | async).hasImpersonatePermission"
                  mat-menu-item (click)="Impersonate()">
            Impersonate
          </button>
          <button mat-menu-item (click)="deleteContact()">Delete</button>
        </mat-menu>
      </div>
    </div>
  </div>
  <div class="">
    <div class="contact-actions flex-items-centered">
      <div class="contact-info flex-grow-1 flex-basis-0">
        {{ userContact.name }}
      </div>
      

    </div>
  </div>


</div>
