<div class="display-flex flex-basis-0 flex-grow-1 flex-col">
  <div class="cont-padding border-bottom">
    <ichs-command-toolbar [model]="model" class="display-flex flex-col"></ichs-command-toolbar>
  </div>

  <mat-tab-group mat-align-tabs="start"  mat-stretch-tabs="false"[(selectedIndex)]="model.selectedTab" class="flex-grow-1">
    <mat-tab label="Group Details">
      <form #form="ngForm" novalidate class="flex-basis-0 flex-grow-1">
        <ichs-collapsable-panel panelTitle="Group Details" style="padding-top:0px">
          <div class="collapsableBody">
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box type="text" name="name" [(ngModel)]="model.objModel.name" required label="Name" maxlength="150" [disabled]="!adminRoleInd"></ichs-text-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-text-box type="text" name="description" [(ngModel)]="model.objModel.description" label="Description" maxlength="255" [disabled]="!adminRoleInd"></ichs-text-box>
              </div>
            </div>
            <div class="display-flex break-la">
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-check-box name="isStandard" [(ngModel)]="model.objModel.isStandard" label="Base Group" [disabled]="!userHasBaseGroupCheckboxAccess"></ichs-check-box>
              </div>
              <div class="flex-col-sep"></div>
              <div class="flex-child display-flex flex-basis-0 flex-grow-1 break-sm flex-items-centered">
                <ichs-select name="parentGroup" label="Parent Group" [(ngModel)]="parentGroupId" (selectionChanged)="parentGroupChanged = true" [items]="groupItems" [disabled]="!adminRoleInd" hasSearch="true"></ichs-select>
              </div>
            </div>
          </div>
        </ichs-collapsable-panel>
      </form>
    </mat-tab>
    <mat-tab label="Group Access" *ngIf="model.objModelId > -1 && !model.objModel.isStandard && fullAccessGroups.indexOf(model.objModelId) == -1">
      <ichs-collapsable-panel panelTitle="Group Access">
        <div class="collapsableBody">
          <div style="margin-top:8px;">
            <line-of-business-tree [treeData]="model.objModel.lineOfBusinessList"></line-of-business-tree>
          </div>
        </div>
      </ichs-collapsable-panel>
    </mat-tab>
    <!--<mat-tab label="Data Restrictions" *ngIf="model.objModelId > -1  && fullAccessGroups.indexOf(model.objModelId) == -1" >
    <ichs-collapsable-panel panelTitle="Data Restrictions">
      <div class="collapsableBody">
        <div style="margin-top:8px;">
          <restricted-access-tree *ngIf="model" [accessRestriction]="model.objModel.lineOfBusinessDataAccess"></restricted-access-tree>
        </div>
      </div>
    </ichs-collapsable-panel>
  </mat-tab>-->
    <mat-tab label="Group Users" *ngIf="model.objModelId > -1">
      <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab has-command">
        <ichs-grid [grid-config]="gridConfigAppUser" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
      </div>
    </mat-tab>
    <mat-tab label="Group Report Items" *ngIf="model.objModelId > -1 && adminRoleInd">
      <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab has-command">
        <ichs-grid [grid-config]="gridConfigReportItem" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
      </div>
    </mat-tab>
    <mat-tab label="Group Dashboard Items" *ngIf="model.objModelId > -1 && adminRoleInd">
      <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab has-command">
        <ichs-grid [grid-config]="gridConfigDashboardItem" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
      </div>
    </mat-tab>
    <mat-tab label="All Report Items" *ngIf="model.objModelId > -1">
      <div class="cont-padding display-flex flex-basis-0 flex-grow-1 grid-content-tab has-command">
        <ichs-grid [grid-config]="gridConfigAllReportItem" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
      </div>
    </mat-tab>
  </mat-tab-group>

</div>
