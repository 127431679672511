import { Component, OnInit } from '@angular/core';
import { SearchViewModel, SharedFolderService, SharedFolderViewModel } from '../../../rest';
import { first } from 'rxjs/operators';
import { IchsGridComponentConfig, IchsControlType } from '../../../controls/ichs-grid/grid-configs';
import { IchsDialogComponent } from '../../../controls/ichs-dialog/ichs-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'shared-folders-maintenance-list',
  templateUrl: './shared-folders-maintenance-list.component.html',
  styleUrls: ['./shared-folders-maintenance-list.component.css'],
  host: {
    class: "data-list"
  }
})
export class SharedFoldersMaintenanceListComponent implements OnInit {

  gridConfig: IchsGridComponentConfig<SharedFolderViewModel>;

  /** ctor */
  constructor(
    private sharedFolderService: SharedFolderService,
    private dialog: MatDialog,
  ) {
    this.gridConfig = this.defineGrid();
  }

  /** OnInit*/
  ngOnInit() { }

  private defineGrid(): IchsGridComponentConfig<SharedFolderViewModel> {
    let configs: IchsGridComponentConfig<SharedFolderViewModel> = {
      primaryId: "id",
      defaultOrder: "name",
      title: "Shared Folder Maintenance",
      entityController: "shared-folder-maintenance",
      hasVoid: false,
      hasSearch: true,
      hasNew: true,
      hasExport: false,
      hasDetails: true,
      hasActionColumn: true,
      entityDataSource: (filter: SearchViewModel) => this.sharedFolderService.search(filter),
      secondAction: {
        icon: "assets/images/delete.svg",
        title: "Delete",
        function: (deletedObj: SharedFolderViewModel) => {
          if (!deletedObj.id) {
            return
          };

          let dialogRef = this.dialog.open(IchsDialogComponent, {
            width: '450px',
            data: { title: "Delete Shared Folder", message: "Are you sure you want to delete this shared folder?" }
          });

          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.sharedFolderService._delete(deletedObj.id, deletedObj.version).pipe(first()).subscribe(result => {
                if (result) {
                  this.gridConfig.refreshGrid!(this.gridConfig.getPage!());
                }
              });
            }
          });
        }
      },
      headers: [
        {
          headerTitle: "Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        }
      ]
    }
    return configs;
  }
}
