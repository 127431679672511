<h2 mat-dialog-title>Impersonate User</h2>
<mat-dialog-content>
  <form #form="ngForm" style="padding:10px;" (keyup.enter)="submit()">
    Please enter a valid email for the user that you want to impersonate.
    <div class="flex-child display-flex  flex-grow-1 break-sm flex-items-centered">
      <input style="width:100%;" type="email" name="email" placeholder="Enter email address here" [(ngModel)]='emailModel'
        (ngModelChange)='emailModelChanged.next($event)' [matAutocomplete]="auto" required email autofocus>

      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let userEmail of userEmails" [value]="userEmail">
          {{userEmail}}
        </mat-option>
      </mat-autocomplete>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="flex-basis-0 flex-grow-1 text-right">
    <button mat-button class="cp-btn cp-main-btn" (click)="submit()">Ok</button>
    <button mat-button class="cp-btn cp-mail-btn" mat-dialog-close>Cancel</button>
  </div>
</mat-dialog-actions>