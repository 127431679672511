import { Component, OnInit, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SelectionTreeViewModel, MedcorResponse, SelectionItemViewModel } from 'src/app/rest';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AdvancedDateFilterationAttributes } from 'src/app/reporting/dashboard/dashboard.component';
import { SharedFiltersHelper } from '../../ichs-dialog/shared-filters-helper.component';
import * as moment from 'moment';

@Component({
  selector: 'ichs-tree-dialog',
  templateUrl: './ichs-tree-dialog.component.html',
  styleUrls: ['./ichs-tree-dialog.component.css']
})
export class IchsTreeDialogComponent implements OnInit {

  private _selectedNode: SelectionTreeViewModel;
  path: SelectionTreeViewModel[] = [];
  query: string = "";
  loadChildren: boolean = true;
  showTree: boolean = true;
  dateRangesItems: SelectionItemViewModel[];
  hideDateRangeSelectCustomLabel: boolean = true;

  returnData: IchsTreeDialogReturnData = new IchsTreeDialogReturnData();
  showActiveOnly: boolean = false;

  termedNodesFilter: (date: Date) => boolean;
  todayDate: Date;

  selectionHash: any = {};

  get selectedNode(): SelectionTreeViewModel {
    return this._selectedNode;
  }

  set selectedNode(node: SelectionTreeViewModel) {
    this._selectedNode = node;
    this.updatePath();
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IchsTreeDialogData,
    private sharedFilterHelper: SharedFiltersHelper,
  ) {
    this.loadChildren = data.loadChildren != undefined;
  }

  ngOnInit(): void {
    this.todayDate = new Date();
    this.todayDate.setHours(0, 0, 0, 0);

    this.termedNodesFilter = (date: Date) => {
      return !date || date >= this.todayDate
    }

    this.dateRangesItems = this.sharedFilterHelper.BuildDateRanges();

    if (!this.data.hideTree) {
      this.selectedNode = this.data.node;
      this.loadNodeChildren(this.selectedNode);
    }

    this.showTree = !this.data.hideTree && !(this.data.advanceDateFilter.dateRange || this.data.advanceDateFilter.startDate && this.data.advanceDateFilter.endDate);
    this.hideDateRangeSelectCustomLabel = this.data.advanceDateFilter.dateRange != "Custom";
  }

  updatePath() {
    let mainNode = this.selectedNode;
    this.path = [];
    while (mainNode.parentId) {
      mainNode = this.data.hash[mainNode.level.substring(0, mainNode.level.lastIndexOf('>')).trim()];
      this.path.splice(0, 0, mainNode);
    }
  }

  loadNodeChildren(node: SelectionTreeViewModel) {
    if (this.loadChildren && !node.visited && node.id != "0") {
      this.data.loadChildren(node).pipe(first()).subscribe(resp => {
        node.visited = true;

        if (node.children && node.children.length > 0) {
          let currentChildrenIds = node.children.map(child => child.id);
          let newChildren = resp.result.filter(_node => !currentChildrenIds.includes(_node.id));
          newChildren.forEach(child => child.selected = false);
          node.children = node.children.concat(newChildren);
          node.selected = this.isAllSelected() == true;
          node.partialSelection = this.isAllSelected() == null;
          this.updateParent(node);
        }
        else {
          node.children = resp.result;
        }

        this.buildDataHierarchy(node.children);
      });
    }
  }

  buildDataHierarchy(data: SelectionTreeViewModel[]) {
    if (data) {
      data.forEach(obj => {
        this.data.hash[obj.level] = obj;
        this.buildDataHierarchy(obj.children);
      });
    }
  }

  rootNodeChecked(event: MatCheckboxChange) {
    // in case of filteration, root node checkbox represents filtered children only
    if (this.query && this.query.length > 0) {
      let filteredChildren = this.selectedNode.children.filter(child => child.label.toLowerCase().indexOf(this.query.toLowerCase()) > -1);

      if (filteredChildren.length > 0) {
        for (let child of filteredChildren) {
          child.selected = event.checked;
          child.partialSelection = false;
          this.toggleChildren(child);
        }

        this.updateParent(filteredChildren[0]);
      }
    }
    else {
      this.selectedNode.selected = event.checked;
      this.selectedNode.partialSelection = false;
      this.nodeChecked(this.selectedNode);
    }
  }

  nodeChecked(node: SelectionTreeViewModel) {
    this.toggleChildren(node);
    this.updateParent(node);
  }

  selectParentNode() {
    if (this.selectedNode.parentId) {
      this.selectedNode = this.data.hash[this.selectedNode.level.substring(0, this.selectedNode.level.lastIndexOf('>')).trim()];
    }
  }

  childNodeOpened(node: SelectionTreeViewModel) {
    this.resetSearchFilter();
    this.selectedNode = node;
    this.loadNodeChildren(this.selectedNode);
  }

  updateParent(node: SelectionTreeViewModel) {
    if (node.parentId) {
      const parentNode: SelectionTreeViewModel = this.data.hash[node.level.substring(0, node.level.lastIndexOf('>')).trim()];

      const siblings = parentNode.children;
      let equalSiblings = siblings.findIndex(sibling => sibling.partialSelection || sibling.selected != node.selected) == -1;

      if (equalSiblings) {
        parentNode.selected = node.selected;
        parentNode.partialSelection = false;
      } else {
        parentNode.partialSelection = true;
        parentNode.selected = false;
      }

      this.updateParent(parentNode);
    }
  }

  toggleChildren(node: SelectionTreeViewModel) {
    if (!node || !node.children || node.children.length == 0) {
      return;
    }
    node.children.forEach(child => {
      child.partialSelection = false;
      child.selected = node.selected;
      this.toggleChildren(child);
    });
  }

  onDatesChange() {
    if (this.data.advanceDateFilter.startDate && this.data.advanceDateFilter.endDate) {
      this.data.advanceDateFilter.dateRange = "Custom";
      this.showTree = false;
      this.hideDateRangeSelectCustomLabel = false;
    } else {
      if (!this.data.hideTree) {
        this.showTree = true;
      }
      this.hideDateRangeSelectCustomLabel = true;
    }
  }

  onDateRangeChange() {
    var dates = this.sharedFilterHelper.populateDateFilters(this.data.advanceDateFilter.dateRange);
    this.data.advanceDateFilter.startDate = moment(dates.startDate).toDate();
    this.data.advanceDateFilter.endDate = moment(dates.endDate).toDate();
    this.showTree = false;
    this.hideDateRangeSelectCustomLabel = true;
  }

  resetAdvancedDateFilters() {
    if (!this.data.hideTree) {
      this.resetDateDropDownTree();
      this.showTree = true;
    }

    this.data.advanceDateFilter.dateRange = null;
    this.data.advanceDateFilter.startDate = null;
    this.data.advanceDateFilter.endDate = null;
    this.hideDateRangeSelectCustomLabel = true;
  }

  resetSearchFilter() {
    this.query = "";
  }

  resetSelection() {
    this.selectedNode.selected = false;
    this.selectedNode.partialSelection = false;
    this.nodeChecked(this.selectedNode);
  }

  isAllSelected() {
    if (!this.selectedNode.children || this.selectedNode.children.length == 0) {
      return false;
    }

    let filteredChildren = this.selectedNode.children.filter(child => child.label.toLowerCase().indexOf(this.query.toLowerCase()) > -1);

    if (filteredChildren.length == 0) {
      return false;
    }

    let indeterminate = filteredChildren.findIndex(child => child.partialSelection || child.selected != filteredChildren[0].selected) > -1;
    if (indeterminate) {
      return null;
    }

    return filteredChildren[0].selected;
  }

  onShowActiveOnlyChange() {
    this.selectedNode.children.forEach(child => {
      if (child.termDate && new Date(child.termDate) < this.todayDate) {
        if (this.showActiveOnly) {
          this.selectionHash[child.id] = child.selected;
          child.selected = false;
        }
        else {
          child.selected = this.selectionHash[child.id];
        }
        child.partialSelection = false;

        this.nodeChecked(child);
      }
    })
  }

  // un-select all years in date drop-down tree except last year
  private resetDateDropDownTree() {
    let numberOfItemsToKeepSelected = 1;

    if (this.data.node && this.data.node.children.length > numberOfItemsToKeepSelected) {
      let nodesToSelect = this.data.node.children.slice(-numberOfItemsToKeepSelected);
      let nodesToDeselect = this.data.node.children.slice(0, this.data.node.children.length - numberOfItemsToKeepSelected);

      this.data.node.partialSelection = true;
      nodesToSelect.forEach(node => { node.selected = true; node.partialSelection = false; this.toggleChildren(node); });
      nodesToDeselect.forEach(node => { node.selected = false; node.partialSelection = false; this.toggleChildren(node); });
    }
    else {
      this.resetNode(this.data.node);
    }
  }

  private resetNode(node: SelectionTreeViewModel) {
    node.selected = true;
    node.partialSelection = false;

    if (node.children) {
      node.children.forEach(obj => {
        this.resetNode(obj);
      });
    }
  }
}


export class IchsTreeDialogData {
  node: SelectionTreeViewModel;
  loadChildren: (node: SelectionTreeViewModel) => Observable<MedcorResponse<Array<SelectionTreeViewModel>>>;
  hash: any;
  hideTree: boolean;
  showAdvanceDate: boolean;
  showSaveSelectionForFutureUse: boolean;
  showActiveOnlyToggle: boolean;
  advanceDateFilter: AdvancedDateFilterationAttributes;
}

export class IchsTreeDialogReturnData {
  saveSelectionForFutureUse: boolean = false;
}
