<div class="cp-dialog">
  <div class="display-flex">
    <div>
      <h2 mat-dialog-title>Report History - {{reportItem.name}}</h2>
    </div>
    <div class="flex-basis-0 flex-grow-1"></div>
    <div><button mat-button mat-dialog-close class="cp-btn cp-mail-btn cp-close-btn"><img src="assets/images/close.svg"
          class="cp-dialog-close" /></button></div>
  </div>
  <mat-dialog-content style="height:60vh">
    <div class="display-flex flex-basis-0 flex-grow-1 border-bottom" style="height:100%">
      <ichs-grid [grid-config]="gridConfig" class="flex-basis-0 flex-grow-1 display-flex flex-col"></ichs-grid>
    </div>
  </mat-dialog-content>
</div>
