import { Component, OnInit } from '@angular/core';
import { SearchViewModel, ReportSchedulerViewModel, ReportSchedulerService } from '../../rest';
import { IchsGridComponentConfig, IchsControlType, IchsGridActionsConfig, AdditionalAction } from '../../controls/ichs-grid/grid-configs';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ReportSchedulerConfig, ReportingSchedulerComponent } from '../reporting-scheduler/reporting-scheduler.component';
import { first } from 'rxjs/operators';
import { IchsDialogComponent } from '../../controls/ichs-dialog/ichs-dialog.component';
import { GeneralVariablesService } from '../../services/general-variables.service';
import { AppGenericLists } from '../../app.general.lists';
import { Observable } from 'rxjs';
import { LoggedUserViewModel } from '../../rest/model/loggedUserViewModel';
import { MedcorUserInfoService } from '../../services/medcor-user-info.service';
import { SelectionItemViewModel } from '../../rest/model/selectionItemViewModel';
import { IchsGridDialogComponent } from 'src/app/controls/ichs-grid-dialog/ichs-grid-dialog.component';
import { AfkamHierarchyNode } from 'src/app/rest';
import { ReportingListDialogComponent } from '../reporting-list-dialog/reporting-list-dialog.component';

@Component({
  selector: 'reporting-scheduler-list',
  templateUrl: './reporting-scheduler-list.component.html',
  styleUrls: ['./reporting-scheduler-list.component.css']
})

export class ReportingSchedulerListComponent implements OnInit {
  gridConfig: IchsGridComponentConfig<ReportSchedulerViewModel>;
  gridAdditionalActions: AdditionalAction[] = [];
  userHasScheduleReportFullAccess: boolean;

  recipientId: number;
  recipientsItems: SelectionItemViewModel[];

  get loggedInUserObs(): Observable<LoggedUserViewModel> { return this.medcorUserInfoService.loggedInUserObs }

  constructor(
    private reportSchedulerService: ReportSchedulerService,
    private dialog: MatDialog,
    private generalVariables: GeneralVariablesService,
    private medcorUserInfoService: MedcorUserInfoService,
  ) {
  }

  ngOnInit() {
    this.loggedInUserObs.pipe(first()).subscribe(loggedInUser => {
      this.userHasScheduleReportFullAccess = loggedInUser.allowScheduleReports;
      if (this.userHasScheduleReportFullAccess) {
        this.reportSchedulerService.getReportSchedulesRecipientsAsSelectionList()
          .pipe(first())
          .subscribe(resp => {
            this.recipientsItems = resp.result;
          });
      }

      this.gridConfig = this.defineGrid();
    });
  }

  private defineGrid(): IchsGridComponentConfig<ReportSchedulerViewModel> {
    this.defineGridAdditionalActions();

    let configs: IchsGridComponentConfig<ReportSchedulerViewModel> = {
      primaryId: "id",
      defaultOrder: "startPeriod",
      defaultOrderType: "desc",
      hasNew: false,
      hasVoid: false,
      hasAdditionalActionsMenu: true,
      customNewAction: new IchsGridActionsConfig({
        function: () => {
          let dialogRef = this.dialog.open(ReportingListDialogComponent, {
            height: '800px',
            width: '1300px',
            disableClose: true,
          });

          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.gridConfig.refreshGrid(0);
            }
          });
        },
        title: "Schedule New Report",
      }),
      additionalActions: this.gridAdditionalActions,
      detailAction: new IchsGridActionsConfig({
        function: (obj: ReportSchedulerViewModel) => {
          let dialogConfig: MatDialogConfig<ReportSchedulerConfig> = {
            data: {
              scheduleId: obj.id
            },
            width: '800px',
            disableClose: true
          };
          let dialogRef = this.dialog.open(ReportingSchedulerComponent, dialogConfig);
          dialogRef.afterClosed().pipe(first()).subscribe(result => {
            if (result) {
              this.gridConfig.refreshGrid(this.gridConfig.getPage());
            }
          });
        }
      }),
      entityDataSource: (filter: SearchViewModel) => this.reportSchedulerService.searchReportSchedulesByRecipient(this.recipientId, filter),
      beforeResetFunction: () => {
        this.recipientId = undefined;
      },
      headers: [
        {
          headerTitle: "Medcor Report Name",
          propertyName: "reportName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Client Report Name",
          propertyName: "clientReportTitle",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Frequency",
          propertyName: "runMode",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.ReportScheduleMode,
          width: 100,
          hideColumn: () => !this.generalVariables.clientDeviceInfo.isDesktop,
        },
        {
          headerTitle: "Start Date",
          propertyName: "startPeriod",
          searchable: true,
          sortable: true,
          nullable: true,
          convertUtcDateToLocal: false,
          dateFormat: 'shortDate',
          controlType: IchsControlType.Date,
          width: 120,
          hideColumn: () => !this.generalVariables.clientDeviceInfo.isDesktop,
        },
        {
          headerTitle: "End Date",
          propertyName: "endPeriod",
          searchable: true,
          sortable: true,
          nullable: true,
          convertUtcDateToLocal: false,
          dateFormat: 'shortDate',
          controlType: IchsControlType.Date,
          width: 120,
          hideColumn: () => !this.generalVariables.clientDeviceInfo.isDesktop,
        },
        {
          headerTitle: "Last Sent",
          propertyName: "lastRunTime",
          searchable: true,
          sortable: true,
          nullable: true,
          convertUtcDateToLocal: false,
          dateFormat: 'short',
          controlType: IchsControlType.Date,
          width: 150,
        },
        {
          headerTitle: "Format",
          propertyName: "reportFormat",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.StaticSelect,
          listData: AppGenericLists.ReportFormat,
          width: 95,
          hideColumn: () => !this.generalVariables.clientDeviceInfo.isDesktop,
        },
        {
          headerTitle: "Scheduled By",
          propertyName: "reportOwnerName",
          titlePropertyName: "reportOwnerEmail",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
      ],
    }
    return configs;
  }

  private defineGridAdditionalActions() {

    let copyReportScheduleAction = <AdditionalAction>{
      name: "Copy Scheduled Report",
      function: (obj: ReportSchedulerViewModel) => {
        if (!obj.id) {
          return
        };

        let dialogConfig: MatDialogConfig<ReportSchedulerConfig> = {
          data: {
            scheduleId: obj.id,
            copySchedule: true,
          },
          width: '800px',
          disableClose: true
        };
        let dialogRef = this.dialog.open(ReportingSchedulerComponent, dialogConfig);
        dialogRef.afterClosed().pipe(first()).subscribe(result => {
          if (result) {
            this.gridConfig.refreshGrid(0);
          }
        });
      }
    };

    let viewSelectedHierarchyAction = <AdditionalAction>{
      name: "View Selected Hierarchy",
      function: (obj: ReportSchedulerViewModel) => {
        if (!obj.id) {
          return
        };

        this.dialog.open(IchsGridDialogComponent, {
          width: '1024px',
          height: '800px',
          data: {
            config: this.defineShowHierarchyGrid(obj.id),
            title: "Selected Hierarchy"
          }
        });
      }
    };

    let viewScheduledReportHistoryAction = <AdditionalAction>{
      name: "View Scheduled Report History",
      function: (obj: ReportSchedulerViewModel) => {
        if (!obj.id) {
          return
        };

        this.dialog.open(IchsGridDialogComponent, {
          width: '1024px',
          height: '800px',
          data: {
            config: this.defineScheduledReportHistoryGrid(obj.id),
            title: "Scheduled Report History"
          }
        });
      }
    };

    let removeSelfFromRecipientsAction = <AdditionalAction>{
      name: "Remove Self from Recipients",
      function: (deletedObj: ReportSchedulerViewModel) => {
        if (!deletedObj.id) {
          return
        };

        let dialogRef = this.dialog.open(IchsDialogComponent, {
          width: '450px',
          data: {
            title: "Remove Self from Recipients",
            message: "Are you sure you want to remove yourself from this report schedule recipients list? You will no more receive this report."
          }
        });

        dialogRef.afterClosed().pipe(first()).subscribe(result => {
          if (result) {
            this.reportSchedulerService.removeSelfFromRecipients(deletedObj.id)
              .pipe(first())
              .subscribe(resp => {
                // if current user is the only recipient, then report schedule will be deleted, refresh the grid
                // also, if user does not has userHasScheduleReportFullAccess, then schedule should be removed from his/her grid
                if (resp.result || !this.userHasScheduleReportFullAccess) {
                  this.gridConfig.refreshGrid!(this.gridConfig.getPage!());
                }
              });
          }
        });
      }
    };

    let deleteAction = <AdditionalAction>{
      name: "Delete",
      function: (deletedObj: ReportSchedulerViewModel) => {
        if (!deletedObj.id) {
          return
        };

        let dialogRef = this.dialog.open(IchsDialogComponent, {
          width: '450px',
          data: {
            title: "Delete Report Schedule",
            message: "Are you sure you want to delete (cancel) this report schedule? This operation will cancel the schedule for all recipients of the schedule. This process cannot be undone.",
          }
        });

        dialogRef.afterClosed().pipe(first()).subscribe(result => {
          if (result) {
            this.reportSchedulerService._delete(deletedObj.id, deletedObj.version)
              .pipe(first())
              .subscribe(() => {
                this.gridConfig.refreshGrid!(this.gridConfig.getPage!());
              });
          }
        });
      }
    };

    this.gridAdditionalActions = [
      copyReportScheduleAction,
      viewSelectedHierarchyAction,
      viewScheduledReportHistoryAction,
    ];

    if (!this.generalVariables.userImpersonationInfo.impersonatorRoleInd) {
      this.gridAdditionalActions.push(removeSelfFromRecipientsAction);

      if (this.userHasScheduleReportFullAccess) {
        this.gridAdditionalActions.push(deleteAction);
      }
    }
  }

  private defineShowHierarchyGrid(reportScheduleId: number): IchsGridComponentConfig<AfkamHierarchyNode> {
    let configs: IchsGridComponentConfig<AfkamHierarchyNode> = {
      primaryId: "pk",
      defaultOrder: "name",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      entityDataSource: (filter: SearchViewModel) => this.reportSchedulerService.searchReportScheduleHierarchyInstances(reportScheduleId, filter),
      headers: [
        {
          headerTitle: "Client Name",
          propertyName: "name",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Sub Client",
          propertyName: "namedPath",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
      ]
    }
    return configs;
  }

  private defineScheduledReportHistoryGrid(reportScheduleId: number): IchsGridComponentConfig<ReportSchedulerViewModel> {
    let configs: IchsGridComponentConfig<ReportSchedulerViewModel> = {
      primaryId: "id",
      defaultOrder: "runTime",
      defaultOrderType: "desc",
      hasVoid: false,
      hasActionColumn: false,
      hasNew: false,
      entityDataSource: (filter: SearchViewModel) => this.reportSchedulerService.searchReportSchedulerHistory(reportScheduleId, filter),
      headers: [
        {
          headerTitle: "Recipient",
          propertyName: "receiver",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "Time Sent",
          propertyName: "runTime",
          searchable: true,
          sortable: true,
          dateFormat: "short",
          controlType: IchsControlType.Date,
        },
      ]
    }
    return configs;
  }
}
