import { Component } from '@angular/core';
import { IchsGridComponentConfig, IchsControlType } from '../../controls/ichs-grid/grid-configs';
import { UserSearchViewModel, SearchViewModel, TriagePortalUserService } from '../../rest';
import { AppGenericLists } from '../../app.general.lists';

@Component({
  selector: 'app-triage-portal-user-list',
  templateUrl: './triage-portal-user-list.component.html',
  styleUrls: ['./triage-portal-user-list.component.css']
})
export class TriagePortalUserListComponent {
  gridConfig: IchsGridComponentConfig<UserSearchViewModel>;

  /** AppuserList constructor*/
  constructor(
    private triagePortalUserService: TriagePortalUserService,
  ) {
    this.gridConfig = this.defineGrid();
  }

  private defineGrid(): IchsGridComponentConfig<UserSearchViewModel> {
    let configs: IchsGridComponentConfig<UserSearchViewModel> = {
      primaryId: "id",
      hasNew: false,
      hasVoid: false,
      defaultOrder: "lastName",
      title: "Telehealth Users",
      entityController: "triage-portal-user",
      entityDataSource: (filter: SearchViewModel) => this.triagePortalUserService.searchTriagePortalUsers(filter),
      headers: [
        {
          headerTitle: "Email",
          propertyName: "email",
          searchable: true,
          sortable: true,
          autoSearch: true,
          controlType: IchsControlType.Textfield,
          width: 250
        },
        {
          headerTitle: "Last Name",
          propertyName: "lastName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield,
        },
        {
          headerTitle: "First Name",
          propertyName: "firstName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Client Name",
          propertyName: "clientName",
          searchable: true,
          sortable: true,
          controlType: IchsControlType.Textfield
        },
        {
          headerTitle: "Status",
          propertyName: "status",
          searchable: true,
          sortable: true,
          listData: AppGenericLists.UserStatus,
          controlType: IchsControlType.StaticSelect,
        }
      ]
    }
    return configs;
  }
}
