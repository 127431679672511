<div class="notification-cont position-absolute">

  <div class="box-arrow">

  </div>
 
  <div style="height:100%; overflow:hidden;" class="rounded-conrner-box">
    <div style="height:40px; justify-content:space-between;" class="display-flex flex-items-centered">
      <div style="margin-left:8px;">Announcements</div>
    </div>
    <hr class="main-hr"/>
    <div class="notification-list">
      <div *ngFor="let notification of notifications">
        <a style="cursor: pointer; display: block;" [style.background-color]="notification.isRead ? 'white' : '#f2fbff'" (click)="openNotification(notification)">
          <div class="notification-item">{{notification.description}}</div>
        </a>
        <hr class="main-hr" />
      </div>
    </div>
  </div>
 
</div>
