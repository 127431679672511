import { Component, OnInit, Inject } from '@angular/core';
import { EXTRA_DATA } from '../../controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { AppLogSearchModel } from '../../rest';

@Component({
  selector: 'app-log-dialog',
  templateUrl: './app-log-dialog.component.html',
  styleUrls: ['./app-log-dialog.component.css']
})
export class AppLogDialogComponent {

  constructor(
    @Inject(EXTRA_DATA) public logData: AppLogSearchModel,
  ) { }
}
