<div *ngIf="defaultView">
  <div *ngIf="reportViewModel" class="fill-parent">
    <div class="text-center shadow-cont">
      <div class="position-relative" *ngIf="reportViewModel.tag==='Tag 1'">
        <div class="position-absolute report-tag tag1">
          {{reportViewModel.tagTitle}}
          <div class="position-absolute tag1-shadow"></div>
        </div>
      </div>
      <div class="position-relative" *ngIf="reportViewModel.tag==='Tag 2'">
        <div class="position-absolute report-tag tag2">
          {{reportViewModel.tagTitle}}
          <div class="position-absolute tag2-shadow"></div>
        </div>
      </div>
      <div class="report-thumbnail-cont">
        <div style="overflow:hidden; height:100%;">
          <img [src]="thumbImage" class="report-thumbnail" (click)="showReport()" />
        </div>
      </div>
      <div class="display-flex flex-items-centered">
        <div class="snapshop-cont" [title]="reportViewModel.isFavorite?'Remove From Favorite':'Add To Favorite'">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.867 53.867" class="fav-icon"
            [class.fav-icon-not-selected]="!reportViewModel.isFavorite"
            [class.fav-icon-selected]="reportViewModel.isFavorite" (click)="toggle()">
            <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798
	10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 " />
          </svg>
        </div>
        <div class="snapshop-cont">

          <img src="assets/images/reports/generate.svg" class="report-snapshot" (click)="showReport()"
            title="Generate Report" />

        </div>
        <div class="snapshop-cont">

          <img src="assets/images/reports/viewSnapshot.svg" class="report-snapshot" (click)="showSnapshot()"
            title="View Snapshot" />

        </div>
        <div class="snapshop-cont">

          <img src="assets/images/reports/reportHistory.svg" class="report-snapshot" (click)="openHistory()"
            title="Report History" />

        </div>
        <div class="snapshop-cont">
          <img src="assets/images/reports/scheduleReports.svg" class="report-snapshot" (click)="scheduleReport()"
            title="Schedule Report" />
        </div>
        <div class="flex-basis-0 flex-grow-1"></div>
        <div class="margin-right-def">
          <button mat-button class="menu-tirgger-btn margin-right-def" [matMenuTriggerFor]="menu">
            <div class="menu-circle"></div>
            <div class="menu-circle"></div>
            <div class="menu-circle"></div>

          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item class="report-action" (click)="showSnapshot()">View Snapshot</button>
            <button mat-menu-item class="report-action" (click)="openHistory()">Report History</button>
            <button mat-menu-item class="report-action" (click)="scheduleReport()">Schedule Report</button>
          </mat-menu>
        </div>
      </div>

    </div>
    <div>
      <h2 class="flex-basis-0 flex-grow-1">{{reportViewModel.name}}</h2>
    </div>
  </div>
</div>
<div *ngIf="!defaultView" class="shadow-cont cont-padding list-view">
  <div *ngIf="reportViewModel">
    <div class="display-flex flex-items-centered">
      <div class="flex-basis-0 flex-grow-1">
        <div class="position-relative">


          <div class="position-absolute" style="top:-13px; left:-16px;">
            <div class="text-center shadow-cont">
              <div class="position-relative" *ngIf="reportViewModel.tag==='Tag 1'">
                <div class="report-tag-h tag1">
                  {{reportViewModel.tagTitle}}
                  <div class="position-absolute tag1-shadow"></div>
                </div>
              </div>
              <div class="position-relative" *ngIf="reportViewModel.tag==='Tag 2'">
                <div class="report-tag-h tag2">
                  {{reportViewModel.tagTitle}}
                  <div class="position-absolute tag2-shadow"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="display-flex flex-items-centered report-hight">
          <div class="snapshop-cont" [title]="reportViewModel.isFavorite?'Remove From Favorite':'Add To Favorite'">
            <svg style="cursor:pointer" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53.867 53.867" class="fav-icon"
              [class.fav-icon-not-selected]="!reportViewModel.isFavorite"
              [class.fav-icon-selected]="reportViewModel.isFavorite" (click)="toggle()">
              <polygon points="26.934,1.318 35.256,18.182 53.867,20.887 40.4,34.013 43.579,52.549 26.934,43.798
	10.288,52.549 13.467,34.013 0,20.887 18.611,18.182 " />
            </svg>
          </div>
          <h2>{{reportViewModel.name}}</h2>

          <div class="flex-basis-0 flex-grow-1"></div>
          <div>
            <button mat-button (click)="showReport()" class="report-action">
              Generate
            </button>
            <button mat-button (click)="showSnapshot()" class="report-action">
              View Snapshot
            </button>
            <button mat-button class="report-action" (click)="openHistory()">Report History</button>
            <button mat-button class="report-action" (click)="scheduleReport()">Schedule Report</button>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>