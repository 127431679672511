import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { DIALOG_REF, IchsComponentDialogComponent } from 'src/app/controls/ichs-dialog/ichs-component-dialog/ichs-component-dialog.component';
import { AccountsService } from 'src/app/rest';

@Component({
  selector: 'change-password-request',
  templateUrl: './change-password-request.component.html',
  styleUrls: ['./change-password-request.component.css']
})
export class ChangePasswordRequestComponent implements OnInit {

  constructor(
    private accService: AccountsService,
    @Inject(DIALOG_REF) private dialogRef: MatDialogRef<IchsComponentDialogComponent>,
  ) { }

  ngOnInit(): void { }

  changePassword() {
    this.accService.sendResetPasswordForm().pipe(first()).subscribe(() => this.dialogRef.close());
  }
}
