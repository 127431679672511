import { SelectionItemViewModel, SearchViewModel, VoidInfoViewModel } from "src/app/rest";
import { Observable } from "rxjs";
import { MedcorResponse } from "src/app/rest/model/medcorResponse";
import { PaginatedListViewModel } from "src/app/rest/model/paginatedListViewModel";

export enum IchsControlType {
  Textfield = 1,
  Boolean = 2,
  Date = 3,
  DynamicSelect = 4,
  StaticSelect = 5,
  Number = 6
}


/*
primaryId: string; //the name of the primary column, will be used to open the details page using the default action. it will call controller/:id
title: string: The title of the Grid
hasNew?: boolean = true; Optional Field, by default true. this will control to show or to hide the new button in the grid header.
hasDetails?: boolean = true; optional field, default to true, show or hides the details button. if false the detail action will not work.
hasAddExisting?: boolean = false; Optional Field, by default false. this will control to show or to hide the (Add Existing)button in the grid header.
hasSearch?: boolean = true; Optional Field, by default true, this will control if to show all the functionality related to search in the grid:
– search button.
– clear filter button.
– search header.
hasVoid?: boolean = true; this optional field will add Void/Recover button and control the void filtration functionality in the grid. by default true.
showVoided?: boolean = true; this optional field will control the void filtration functionality in the grid. by default false. This field can be used to show voided records even if hasVoid = false.
hasPagination?: boolean = true; Optional Field, by default true. this will control to show or to hide the (pagination toolbar)button in the grid header.
pageSize?: number = 10; a number field that will control the number of records per page.
pageSizeOptions?: number[] = [5, 10, 20, 50, 100]; an array of numbers to set the pageSize according to user preferences.
hasExport?: boolean = true; Optional field to control the visibility of the export button in the grid header, by default true.
defaultOrder?: string; The name of the property to do initial sort on.
defaultOrderType?: string = “desc”; Optional param, by default “desc” to control the order type.
entityController: string; The name of the controller that this grid will use to load data. it will also be used in the URL when routing to the detail page.
entityDataSource: Function; The data service function that takes the filter as parameter and return a searchViewModel. this function should return the observable<ViewModel> in order for the grid to load the data. for example (entityDataSource: (filter: SearchViewModel)=>this.appUserService.apiAppUserSearchPost(filter)). The function must take the filter as
voidAction?: Function: Function that calls the void service provided to the grid, it opens the void dialog first to collect void information
recoverAction?: Function: function that calls recover functionality, opens a confirmation dialog first.
headers: IchsGridColumnConfig[]; the List of objects that defines the Grid columns and how to customize each column. described below.
detailAction?: IchsGridActionsConfig; This will override the default icon and function of the detail action for each row. this function will be called when double click the row or hit the details icon in each row.
secondAction?: IchsGridActionsConfig; This property will add a new icon to each row that when clicked will call the provided function.
thirdAction?: IchsGridActionsConfig; This property will also add another icon to each row in the grid and it will call the provided function when clicking on the icon.
beforeResetFunction?: IchsGridActionsConfig; This function will get invoked before reseting grid.
afterResetAction?: IchsGridActionsConfig; This function will get invoked after reseting grid.
doubleClickAction?: IchsGridActionsConfig; This function will override the default double click functionality and it will call the provided function.
addExistingAction?: IchsGridActionsConfig; Optional, will be called when clicking on add existing in the grid
exportingAction?: IchsGridActionsConfig; Optional, will be called when clicking on the exporting action.
allowMultiSelect?: boolean = false; Show or hide the selection column
getSelected?:()=>String[]; return an array of selected Ids in the grid
initialSelection?: string[] = []; initial selected Ids
rowColor?: (obj: T) => string | null; BackGroundColor for Row 
getPage?: () => number; Get the selected page index.
 refreshGrid?: (index: number) => void; Refresh grid with asigning the page index.
*/
export class IchsGridComponentConfig<T> {
  primaryId: string;
  title?: string;
  loadOnInit?: boolean = true;
  hasActionColumn?: boolean = true;
  hasNew?: boolean = true;
  hasAddExisting?: boolean = true;
  hasDetails?: boolean = true;
  hasSearch?: boolean = true;
  hasVoid?: boolean = true;
  hasAdditionalActionsMenu?: boolean = false;
  additionalActions?: AdditionalAction[] = [];
  showVoided?: boolean = false;
  voidAction?: (reason: VoidInfoViewModel) => Observable<MedcorResponse<T>>;
  recoverAction?: (id: number) => Observable<MedcorResponse<T>>;
  hasPagination?: boolean = true;
  pageSize?: number = 10;
  pageSizeOptions?: number[] = [5, 10, 20, 50, 100, 1000];
  hasExport?: boolean = false;
  exportingAction?: IchsGridActionsConfig;
  defaultOrder?: string;
  defaultOrderType?: string = "asc";
  entityController?: string;
  entityDataSource: (filter: SearchViewModel) => Observable<MedcorResponse<PaginatedListViewModel<T>>> | PaginatedListViewModel<T>;
  headers: IchsGridColumnConfig[];
  detailAction?: IchsGridActionsConfig;
  secondAction?: IchsGridActionsConfig;
  thirdAction?: IchsGridActionsConfig;
  fourthAction?: IchsGridActionsConfig;
  fifthAction?: IchsGridActionsConfig;
  preprocessData?: (objs: T[]) => void;
  beforeResetFunction?: Function;
  afterResetFunction?: Function;
  disableDataLoadingOnReset?: boolean = false;
  doubleClickAction?: IchsGridActionsConfig;
  multiSelectConfig?: GridMultiSelectConfig;
  addExistingConfig?: GridAddExistingConfig<any>;
  refreshGrid?: (index: number) => void;
  rowColor?: (obj: T) => string | null;
  rowStyle?: (obj: T) => string | null;
  getPage?: () => number;
  customNewAction?: IchsGridActionsConfig;
  customNewAction2?: IchsGridActionsConfig;
  constructor(init?: Partial<IchsGridComponentConfig<T>>) {
    Object.assign(this, init);
  }

}

export class AdditionalAction {
  name?: string;
  dynamicName?: Function;
  function: Function;
  class?: string;
}

///Grid MultiSelectConfiguration
export class GridMultiSelectConfig {
  getSelected?: () => string[];
  allowSelectObjects?: boolean = false;
  hasSelectAllCheckbox?: boolean = true;
  getSelectedObjects?: () => any[];
  setSelected?: (...Ids: string[]) => void;
  removeSelected?: (...Ids: string[]) => void;
  initialSelection?: string[] = [];
  loadInitialSelection?: () => Observable<MedcorResponse<PaginatedListViewModel<string>>>;
  constructor(init?: Partial<GridMultiSelectConfig>) {
    Object.assign(this, init);
  }
}

///Grid AddExisting Configuration
export class GridAddExistingConfig<T> {
  buttonText: string;
  gridConfig: IchsGridComponentConfig<T>;
  addExistingService?: (Ids: string[]) => Observable<MedcorResponse<boolean>>;
  customAddExisting?: (Ids: string[]) => boolean;
  title: string;
  constructor(init?: Partial<GridAddExistingConfig<T>>) {
    Object.assign(this, init);
  }
}

/*
sortable?: boolean = false; define if the column should be sortable or not.
propertyName: string; the name of the property in the view model to bind this column with.
headerTitle: string; The title of the column in the grid.
isVisible?: boolean = true; optional variable, True by default, this field controls the visibility of a column or not.
searchable?: boolean = true; controls the search header, to show or hide the filtration header.
searchLabel?: string; The placeholder for the text field in the header, the label for the checkbox in the header, or the empty option in the select.
controlType?: ichsControlType; Enum that controls the type of the control to render in the search header for the column.
listID?: number; the Id of the selection list to get from the database. when the control type is DynamicSelect
listData?: [string,string][];a static list of Data to be viewed in the selection list. for example: [[“value”, “label”], [“b”, “b”], [“c”, “c”]]
filterVal?: string; the default value of the filter or a way to set the filters by code in the grid.
width?:number, this number to specify the width of the column in px, at least one column should not have width so that it fills the empty space. if this field was not set to any column, then all columns will have the same width
*/
export class IchsGridColumnConfig {
  sortable?: boolean = false;
  propertyName: string;
  titlePropertyName?: string;
  headerTitle: string;
  isVisible?: boolean = true;
  searchable?: boolean = true;
  searchLabel?: string;
  nullable?: boolean = false;
  autoSearch?: boolean = false;
  controlType?: IchsControlType;
  convertUtcDateToLocal?: boolean = true;
  listId?: number;
  listData?: SelectionItemViewModel[];
  hasSearch?: boolean = false;
  filterVal?: any;
  width?: number;
  flex?: string;
  wordWrap?: boolean;
  dateFormat?: string = "MM/dd/yyyy";
  dateTimeZone?: string;
  hideColumn?: () => boolean;
  constructor(init?: Partial<IchsGridColumnConfig>) {
    Object.assign(this, init);
  }
}


//a class to hold the function and the icon for the grid actions
export class IchsGridActionsConfig {
  function: Function;
  icon?: string;
  title?: string;
  hasMatMenu?: boolean;
  matMenuTitle?: string;
  matMenuItems?: AdditionalAction[];
  centralizeMatMenuLoader?: boolean = false;
  constructor(init?: Partial<IchsGridActionsConfig>) {
    Object.assign(this, init);
  }

}
