import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Subject, ReplaySubject, BehaviorSubject, interval, Observable } from 'rxjs';
import { SignalRSettings } from '../app.general.constants';
export const SIGNALR_BASE_PATH = new InjectionToken<string>('basePath');
import { MedcorAuthenticationService } from './medcor-authentication.service';
import { first } from 'rxjs/operators';
import { Message } from '../ng-chat/ng-chat';
import { GeneralVariablesService } from './general-variables.service';
import { UserCountersViewModel, LoggedUserViewModel } from '../rest';
import { MedcorUserInfoService } from './medcor-user-info.service';

@Injectable()
export class MedcorSignalRService {

    private _hubConnection: HubConnection;

    private _connected = new BehaviorSubject<boolean>(false);
    private _userId = new ReplaySubject<number | null>();
    private _activeUsers = new ReplaySubject<Array<ActiveUserModel>>();

    private _messageRecived = new Subject<string>();
    private _chatMessageRecived = new Subject<SignalRChatMessage>();
    private _chatMessageNotDelivered = new Subject<SignalRChatMessage>();

    private _newNotification = new Subject<void>();

    get loggedInUserObs(): Observable<LoggedUserViewModel> { return this.medcorUserInfoService.loggedInUserObs }

    get connectedObs() { return this._connected.asObservable() };
    get userIdObs() { return this._userId.asObservable() };
    get activeUsersObs() { return this._activeUsers.asObservable() };
    get newNotificationObs() { return this._newNotification.asObservable() };

    get messageRecivedObs() { return this._messageRecived.asObservable() };
    get chatMessageRecivedObs() { return this._chatMessageRecived.asObservable() };
    get chatMessageNotDeliveredObs() { return this._chatMessageNotDelivered.asObservable() };

    constructor(
        @Inject(SIGNALR_BASE_PATH) basePath: string,
        private generalVariables: GeneralVariablesService,
        private authenticationService: MedcorAuthenticationService,
        private medcorUserInfoService: MedcorUserInfoService,
    ) {
        this._hubConnection = new HubConnectionBuilder()
            .withUrl(`${basePath}${SignalRSettings.ACTIVE_USERS_PATH}`, {
                logger: LogLevel.Warning
            })
            .build();

        this.subscribe();
        authenticationService.authenticatedObs.subscribe(authenticated => this.tokenChanged(authenticated));
    }

    private tokenChanged(authenticated: boolean) {
        this.stop();

        if (authenticated) {
            this.loggedInUserObs.pipe(first()).subscribe(loggedInUser => {
                if (loggedInUser.roleName != "Impersonator") {
                    this.start();
                }
            });
        }
    }

    /**
     * Initialize the Connection to SignalR Active Users Hub
     */
    start() {
        this.connectedObs.pipe(first(obj => !obj)).subscribe(result => {
            this.getActiveUsers();
            this._hubConnection
                .start()
                .then(con => {
                    this._connected.next(true);
                })
                .catch(err => {
                    this._connected.next(false);
                    interval(30000).pipe(first()).subscribe(obj => {
                        this.start();
                    });
                })
            this._hubConnection.onclose(error => {
                this._connected.next(false);
                
                if (error) {
                    interval(30000).pipe(first()).subscribe(obj => {
                        this.start();
                    });
                }
            });
        });
    }

    /**
     * Subscribe for Hub Events 
     */
    private subscribe() {
        this._hubConnection.on(SignalRSettings.ACTIVE_USERS_CHANGED_EVENT, (result: Array<ActiveUserModel>) => {
            this._activeUsers.next(result);
        });
        this._hubConnection.on(SignalRSettings.USER_ID_RECEIVED_EVENT, (result: number) => {
            this._userId.next(result);
        });
        this._hubConnection.on(SignalRSettings.MESSAGE_RECEIVED_EVENT, (result: string) => {
            this._messageRecived.next(result);
        });
        this._hubConnection.on(SignalRSettings.CHAT_MESSAGE_RECEIVED_EVENT, (result: SignalRChatMessage) => {
            this._chatMessageRecived.next(result);
        });
        this._hubConnection.on(SignalRSettings.CHAT_MESSAGE_NOT_DELIVERED, (result: SignalRChatMessage) => {
            this._chatMessageNotDelivered.next(result);
        });
        this._hubConnection.on(SignalRSettings.NOTIFICATION_ADDED_EVENT, () => {
            this._newNotification.next();
        });
        this._hubConnection.on(SignalRSettings.UPDATE_SYSTEM_COUNTERS, (result: UserCountersViewModel) => {
            this.generalVariables.updateSystemCounters(result);
        });
        this._hubConnection.on(SignalRSettings.NEW_RELEASE_NOTES_ADDED_EVENT, () => {
            this.generalVariables.systemCounters.newReleaseNoteReadInd = true;
        });
    }

    /**
     * Subscribe for Hub Events 
     */
    private unSubscribe() {
        this._activeUsers.next([]);
        this._userId.next(null);
    }

    /**
     * Stop or close Connection To SignalR Active Users Hub
     */
    stop() {
        this.connectedObs.pipe(first()).subscribe(result => {
            if (result) {
                this._hubConnection.stop();
                this.unSubscribe();
            }
        });
    }

    /**
     * Invoke GetActiveUsers Method on Active Users Hub
     */
    private getActiveUsers() {
        this.connectedObs.pipe(first(obj => obj)).subscribe(result => {
            this._hubConnection.invoke(SignalRSettings.GET_ACTIVE_USERS_METHOD);
        });
    }

    /**
    * Invoke SendMessage Method on Active Users Hub
    */
    sendMessage(activeUser: ActiveUserModel | null, message: string) {
        this.connectedObs.pipe(first(obj => obj)).subscribe(() => {
            this._hubConnection.invoke(SignalRSettings.SEND_MESSAGE_METHOD, activeUser ? activeUser.id : null, message);
        });
    }

    /**
     * Invoke SendChatMessage Method on Active Users Hub
     */
    sendChatMessage(message: Message) {
        this.connectedObs.pipe(first(obj => obj)).subscribe(() => {
            this._hubConnection.invoke(SignalRSettings.SEND_CHAT_MESSAGE_METHOD, message);
        });
    }

    goOnline() {
        this.connectedObs.pipe(first(obj => obj)).subscribe(() => {
            this._hubConnection.invoke(SignalRSettings.GO_ONLINE);
        });
    }

    goOffline() {
        this.connectedObs.pipe(first(obj => obj)).subscribe(() => {
            this._hubConnection.invoke(SignalRSettings.GO_OFFLINE);
        });
    }
}

export interface ActiveUserModel {
    id: number;
    name?: string;
    loginTime?: Date;
    connections?: Array<ActiveUserConnectionModel>;
    userImage?: string;
}

export interface ActiveUserConnectionModel {
    connectionTime?: Date;
    connectionId?: string;
}

export interface SignalRChatMessage {
    fromId: any;
    toId: any;
    message: string;
    seenOn?: Date;
    timeStamp: number;
    displayName: string;
    isDelivered: boolean;
    showMessageTime: boolean;
}
